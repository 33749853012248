import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import Button from "../../../../../ui/Button"
import { CardSkeleton } from "../../../../../ui/Card/Card.skeleton"
import Checkbox from "../../../../../ui/Checkbox"
import Container from "../../../../../ui/Container"
import Divider from "../../../../../ui/Divider"
import EmptyState from "../../../../../ui/EmptyState"
import SimpleInput from "../../../../../ui/Inputs/SimpleInput"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type OrdersFilterContentProps = {
  filterLabel: string
  searchTerm?: string
  onSearchTermChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  loading?: boolean
  filterKey?: string
  results:
    | Array<{ value: string; name: string; render?: React.ReactNode }>
    | undefined
  clearSearch: () => void
}

export const OrdersFilterContent: React.FC<OrdersFilterContentProps> = ({
  filterLabel,
  searchTerm,
  onSearchTermChange,
  loading = false,
  filterKey,
  results,
  clearSearch,
}) => {
  const intl = useIntl()
  const { filters, updateFilters } = useFilters()

  const onChangedCheck = (event: CheckboxChangeEvent, name: string) => {
    if (event.target.checked) {
      const validFilters = filters[filterKey as keyof typeof filters] ?? []

      updateFilters({
        [`${filterKey}`]: [
          ...validFilters,
          { key: event.target.value, name: name },
        ],
      })
    } else {
      const filterValue = filters[filterKey as keyof typeof filters]

      if (Array.isArray(filterValue)) {
        updateFilters({
          [`${filterKey}`]: filterValue?.filter(
            (filter) =>
              filter.key.toLowerCase() !== event.target.value.toLowerCase()
          ),
        })
      } else {
        updateFilters({
          [`${filterKey}`]: filterValue,
        })
      }
    }
  }

  return (
    <Container>
      <StyledTitleWrapper>
        <SimpleInput
          placeholder={intl.formatMessage(
            {
              id: "restaurants.orders.filter.orders.by",
              defaultMessage: "Filter orders by {param}",
            },
            { param: filterLabel }
          )}
          size="middle"
          aria-label="filter-by"
          width="100%"
          value={searchTerm}
          onChange={onSearchTermChange}
          clearIcon
        />
        <Button
          hierarchy="tertiary"
          title={intl.formatMessage({
            id: "components.filter.header.cancel.button",
            defaultMessage: "Cancel",
          })}
          onClick={clearSearch}
        />
      </StyledTitleWrapper>

      <Divider verticalMargin="0px" />
      <Spacer size={16} />

      <StyledContentWrapper>
        {!loading && (
          <Container display="flex" flexDirection="column" gap="24px">
            {results?.map(({ name, render, value }, index) => {
              const filterValue = filters[filterKey as keyof typeof filters]

              const defaultValues =
                Array.isArray(filterValue) &&
                filterValue?.map((filter) => filter.key).includes(value)

              return (
                <Checkbox
                  key={index}
                  classId="order-filter-check"
                  content={
                    render ? (
                      <Text size="s">{render}</Text>
                    ) : (
                      <Text size="s">{name}</Text>
                    )
                  }
                  defaultChecked={defaultValues}
                  value={value}
                  onChange={(event) => onChangedCheck(event, name)}
                />
              )
            })}
          </Container>
        )}

        {loading && <CardSkeleton cards={2} loading />}

        {!results?.length && !loading && (
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <EmptyState
              title=""
              description={intl.formatMessage({
                id: "components.search.items.not.found.title",
                defaultMessage: "Sorry, we did not find any results",
              })}
            />
          </Container>
        )}
      </StyledContentWrapper>
    </Container>
  )
}

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
`

export const StyledContentWrapper = styled.div`
  padding: 0px 16px 12px 16px;
  min-height: 240px;
  max-height: 350px;
  overflow: auto;
`
