import type { RModifierGroupDetail } from "../../../../shared/graphql/generated/types"
import { useModal } from "../../../../shared/hooks/useModal"
import paths from "../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import ModalFull from "../../../../ui/ModalFull"
import { formNotification } from "../../../../ui/notification"
import type { ILocationsParams } from "../../../Settings/Locations/hookforms.interfaces"
import ModifierGroupForm from "../ModifierGroupForm"
import { ModifierGroupResolver } from "../ModifierGroupForm/ModifiersForm/FormModifierGroupModal.yup"
import type { IModifierGroupForm } from "../ModifierGroupForm/hookforms.interfaces"
import DropdownModifiersGroups from "../components/DropdownModifiersGroups"
import ModifierModalHeader from "../components/ModifierModalHeader"
import PublishModifierGroupModal from "../components/modals/PublishModifierGroupModal"
import useModifierGroups from "../hooks/useModifierGroups"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

type EditModifierProps = {
  isCorporate?: boolean
  modifierGroup?: RModifierGroupDetail
}

const EditModifier = ({
  modifierGroup,
  isCorporate = true,
}: EditModifierProps) => {
  const intl = useIntl()

  const { restaurants } = paths
  const { push } = useHistory()
  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const { locationUUID } = useParams<ILocationsParams>()

  const formMethods = useForm<IModifierGroupForm>({
    mode: "all",
    resolver: ModifierGroupResolver,
    defaultValues: {
      name: modifierGroup?.name,
      isPublished: modifierGroup?.isPublished,
      lastPublishedAt: modifierGroup?.publishedAt,
      isMain: isCorporate,
      modifiers: modifierGroup?.modifiers,
      modifierGroupUUID: modifierGroup?.uuid,
      hasSnapshot: modifierGroup?.hasSnapshot,
    },
  })

  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    clearErrors,
    formState: { isDirty, errors },
  } = formMethods

  const { saveModifierGroup, modifierGroupSaved } = useModifierGroups({})

  const [savedModifierName, setSavedModifierName] = useState<string>(() =>
    intl.formatMessage({
      id: "restaurants.modifiers.edit.modifier.screen.title",
      defaultMessage: "Edit Modifier Group",
    })
  )

  const path = isCorporate
    ? restaurants.modifiers
    : restaurants.locations.modifiersByLocation(locationUUID)

  const onClose = () => {
    push(path)
  }

  const modifierGroupUUID = getValues("modifierGroupUUID") ?? ""
  const lastSavedAt = getValues("lastSavedAt") ?? null
  const enabledPreview = !!modifierGroupUUID && !!lastSavedAt
  const hasSnapshot = getValues("hasSnapshot") ?? false
  const lastPublishedAt = getValues("lastPublishedAt") ?? null
  const isPublished = getValues("isPublished") ?? false

  const onSave = async () => {
    const formData = getValues()
    clearErrors("modifiers")
    const isValidName = await trigger("name")

    if (!isValidName) {
      const nameError = errors?.name
        ? {
            message: intl.formatMessage({
              id: "restaurants.modifiers.edit.modifier.save.notification.name.error.message",
              defaultMessage: "Name the modifier group",
            }),
          }
        : undefined

      return formNotification({
        key: "lsm.edit.modifier.save",
        header: intl.formatMessage({
          id: "restaurants.modifiers.edit.modifier.save.notification.error.header",
          defaultMessage: "You can’t save this modifier group",
        }),
        title: intl.formatMessage({
          id: "restaurants.modifiers.edit.modifier.save.notification.error.title",
          defaultMessage: "To save a modifier group you need to:",
        }),
        type: "error",
        error: { ...errors, ...(nameError && { name: nameError }) },
      })
    }

    await saveModifierGroup(
      formData,
      (result) => {
        setSavedModifierName(result?.saveModifierGroup.name)
        setValue("lastSavedAt", new Date())
        setValue("modifierGroupUUID", result?.saveModifierGroup?.uuid, {
          shouldValidate: true,
        })
        setValue("hasSnapshot", result?.saveModifierGroup?.hasSnapshot)

        reset({}, { keepValues: true })
      },
      showGraphqlErrors
    )
  }

  const onPublish = () => {
    handleSubmit(
      () => {
        showPublishConfirmation()
      },
      (error) => {
        const modifierError = error?.modifiers
          ? {
              message: intl.formatMessage({
                id: "restaurants.modifiers.edit.modifier.publish.notification.modifier.error.message",
                defaultMessage: "Add at least 1 modifier",
              }),
            }
          : undefined

        const nameError = errors?.name
          ? {
              message: intl.formatMessage({
                id: "restaurants.modifiers.edit.modifier.publish.notification.name.error.message",
                defaultMessage: "Name the modifier group",
              }),
            }
          : undefined

        formNotification({
          key: "lsm.edit.modifier.publish",
          header: intl.formatMessage({
            id: "restaurants.modifiers.edit.modifier.publish.notification.error.header",
            defaultMessage: "You can’t publish this modifier group",
          }),
          title: intl.formatMessage({
            id: "restaurants.modifiers.edit.modifier.publish.notification.error.title",
            defaultMessage: "To publish a modifier group you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(modifierError && { modifiers: modifierError }),
            ...(nameError && { name: nameError }),
          },
        })
      }
    )()
  }

  const publishCallback = () => {
    dismissPublishConfirmation()
    setValue("lastSavedAt", null)
    setValue("isPublished", true)
    setValue("hasSnapshot", false)
    reset({}, { keepValues: true })
    onClose()
  }

  const onDeleteDropdownOk = () => {
    reset({}, { keepValues: true })

    onClose()
  }

  const getModifierData = () => {
    if (isDirty) {
      const data = getValues()
      const listModifiers = data.modifiersDelete?.length
        ? data.modifiers.concat(data.modifiersDelete)
        : data.modifiers

      const modifiers = listModifiers.map((modifier) => ({
        uuid: modifier.uuid,
        deletedAt: modifier.deletedAt,
        ingredient: { uuid: modifier.ingredient.uuid },
        isSoldOut: modifier.isSoldOut,
        isVisible: modifier.isVisible,
      }))

      return {
        uuid: data.modifierGroupUUID,
        modifiers: modifiers,
        isMain: data.isMain,
        name: data.name,
      }
    }

    return undefined
  }

  return (
    <ModalFull
      id="edit-modifier-modal"
      goBackPath={path}
      headerColor="Neutral2"
      afterClose={onClose}
      title={
        <ModifierModalHeader
          onSave={onSave}
          onPublish={onPublish}
          lastPublishedAt={lastPublishedAt}
          lastSavedAt={lastSavedAt}
          enablePreview={enabledPreview}
          enablePublish={hasSnapshot || !isPublished || isDirty}
          enableSave={isDirty}
          loadingSave={modifierGroupSaved.loading}
          loadingPublish={false}
          actions={
            <DropdownModifiersGroups
              onOk={onDeleteDropdownOk}
              isCorporate={isCorporate}
              modifierGroupUUID={modifierGroupUUID}
              actions={
                isPublished
                  ? ["DELETE", "UNPUBLISH"]
                  : ["DELETE_DRAFT", "UNPUBLISH", "DISABLE_UNPUBLISH"]
              }
            />
          }
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.createModifierGroup}
            pageName={savedModifierName}
          />
        </ModifierModalHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ModifierGroupForm />
      </FormProvider>
      {publishModalVisible && (
        <PublishModifierGroupModal
          modifierGroupUUID={modifierGroupUUID}
          modifierGroupData={getModifierData()}
          isCorporate={isCorporate}
          onOk={publishCallback}
          onCancel={dismissPublishConfirmation}
        />
      )}
    </ModalFull>
  )
}

export default EditModifier
