import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishDraftItemMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type PublishDraftItemMutation = { __typename?: 'Mutation', publishDraftItem: { __typename?: 'RBasicEntityData', name: string, uuid: string } };


export const PublishDraftItemDocument = gql`
    mutation publishDraftItem($uuid: ID!) {
  publishDraftItem(uuid: $uuid) {
    name
    uuid
  }
}
    `;
export type PublishDraftItemMutationFn = Apollo.MutationFunction<PublishDraftItemMutation, PublishDraftItemMutationVariables>;

/**
 * __usePublishDraftItemMutation__
 *
 * To run a mutation, you first call `usePublishDraftItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftItemMutation, { data, loading, error }] = usePublishDraftItemMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishDraftItemMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftItemMutation, PublishDraftItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDraftItemMutation, PublishDraftItemMutationVariables>(PublishDraftItemDocument, options);
      }
export type PublishDraftItemMutationHookResult = ReturnType<typeof usePublishDraftItemMutation>;
export type PublishDraftItemMutationResult = Apollo.MutationResult<PublishDraftItemMutation>;
export type PublishDraftItemMutationOptions = Apollo.BaseMutationOptions<PublishDraftItemMutation, PublishDraftItemMutationVariables>;