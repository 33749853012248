import { useFilters } from "../../../../../../../shared/contexts/FilterProvider"
import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import Text from "../../../../../../../ui/Typography/Text"
import { useCombos } from "../../../hooks/useCombos"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

interface DeleteComboModalProps {
  comboUUID: string
  onOk: () => void
  onCancel: () => void
  isCorporate?: boolean
}

export const DeleteComboModal: React.FC<DeleteComboModalProps> = ({
  comboUUID,
  onOk,
  onCancel,
  isCorporate,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { deleteCombo, comboDeleted } = useCombos({ search })

  const onDelete = async () => {
    deleteCombo(comboUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      centered
      wrapClassName="non__full__modal__wrap"
      visible
      title={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.combo.title",
        defaultMessage: "Delete combo",
      })}
      subtitle={
        <FormattedMessage
          id="restaurants.menu.items.combos.components.modals.delete.combo.subtitle"
          defaultMessage="This combo is currently published, if you delete it, {isCorporate, plural, =0 {customers} other {all your locations}} won’t be able to see it again. {newLine}"
          values={{
            newLine: (
              <Text>
                {intl.formatMessage({
                  id: "restaurants.menu.items.combos.components.modals.delete.combo.new.line",
                  defaultMessage: "This action cannot be undone.",
                })}
              </Text>
            ),
            isCorporate: Number(isCorporate),
          }}
        />
      }
      loading={comboDeleted.loading}
      onConfirm={onDelete}
      onCancel={onCancel}
      type="danger"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.combo.cancel.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.combo.confirm.button",
        defaultMessage: "Delete",
      })}
    />
  )
}
