import { NumberType } from "../../Reports/components/Comparisson/Comparisson"
import { StatisticsCard } from "./StatisticsCard"
import { StatisticsCardSkeleton } from "./StatisticsCard/StatisticsCard.skeleton"
import { Grid } from "@mantine/core"
import { TablerIconsProps } from "@tabler/icons-react"
import { useIntl } from "react-intl"

export interface StatisticsProps {
  amounts: StatisticsValues[]
  loading: boolean
}

export interface StatisticsValues {
  title: { id: string; message: string }
  icon: React.ComponentType<TablerIconsProps>
  type: Exclude<NumberType, "percentage">
  amount: number
}

export const Statistics = (props: StatisticsProps) => {
  const { amounts, loading } = props
  const intl = useIntl()

  return (
    <Grid>
      {amounts.map((value) => {
        return loading ? (
          <StatisticsCardSkeleton key={`${value.title.id}-${value.type}`} />
        ) : (
          <Grid.Col xs={6} sm={3} key={`${value.title.id}-${value.type}`}>
            <StatisticsCard
              icon={value.icon}
              title={intl.formatMessage({
                id: value.title.id,
                defaultMessage: value.title.message,
              })}
              diff={0}
              value={value.amount}
              numberType={value.type}
            />
          </Grid.Col>
        )
      })}
    </Grid>
  )
}
