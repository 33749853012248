import { MILISECONDS_IN_A_SECOND } from "../../../../Settings/Restaurant/Plans/plans.utils"
import { formatTimerDuration } from "../../utils/time-entry-date-format"
import Tag from "../Tag"
import moment from "moment-timezone"
import { useEffect, useState } from "react"

type SecondsProps = {
  kind: "bySeconds"
  secondsPassed: number
}

type StartDateProps = {
  kind: "byDate"
  startDate: string
}

type TagTimerProps = SecondsProps | StartDateProps

export const TagTimer = (props: TagTimerProps) => {
  const { kind } = props

  const [seconds, setSeconds] = useState(() =>
    kind === "bySeconds"
      ? props.secondsPassed
      : moment
          .duration(moment(Date.now()).diff(moment(props.startDate)))
          .asSeconds()
  )

  const totalDuration = formatTimerDuration(seconds)

  useEffect(() => {
    const id = setInterval(() => {
      setSeconds((prev) => prev + MILISECONDS_IN_A_SECOND / 1000)
    }, MILISECONDS_IN_A_SECOND)

    return () => clearInterval(id)
  }, [kind])

  return <Tag type="ELAPSED" text={totalDuration} />
}
