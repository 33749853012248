import { TAB_SEARCH_FILTER_NAME } from "../../../shared/utils/constant/searchParams"
import Combos from "./Combos"
import Items from "./Items"
import { menuItemsTabsEnum } from "./components/MenuItemsTabs/utils/MenuItemsTabsEnum"
import React, { useMemo } from "react"
import { useLocation } from "react-router"

type MenuItemsProps = {
  isCorporate?: boolean
}

export const MenuItems: React.FC<MenuItemsProps> = ({ isCorporate = true }) => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const tab = searchParams.get(TAB_SEARCH_FILTER_NAME)
  const combosSelected = tab === menuItemsTabsEnum.COMBOS

  return combosSelected ? (
    <Combos isCorporate={isCorporate} />
  ) : (
    <Items isCorporate={isCorporate} />
  )
}

export const LocationMenuItems = () => <MenuItems isCorporate={false} />
