import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import { CateringForm, FeeQuantityEnum } from "../../shared/hookforms.intefaces"
import { List, Text } from "@mantine/core"
import { ReactNode } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

export const Payment = () => {
  const intl = useIntl()
  const { control } = useFormContext<CateringForm>()

  const [prePayment] = useWatch({
    control,
    name: ["rules.prePayment"],
  })

  const amountPayment =
    prePayment.paymentMethod === FeeQuantityEnum.PERCENTAGE
      ? safeNumber(prePayment.paymentValue) / 100
      : safeNumber(prePayment.paymentValue)

  return (
    <List>
      <List.Item>
        <Text>
          {intl.formatMessage(
            {
              id: "terms.and.conditions.order.payment.total.amount",
              defaultMessage:
                "To place an order we ask for the <bold>{amount}</bold> of total amount of the order.",
            },
            {
              amount: intl.formatNumber(amountPayment, {
                style:
                  prePayment.paymentMethod === FeeQuantityEnum.AMOUNT
                    ? "currency"
                    : "percent",
                currency: "USD",
              }),
              bold: (chunk: ReactNode) => <b>{chunk}</b>,
            }
          )}
        </Text>
      </List.Item>
      <List.Item>
        <Text>
          {intl.formatMessage({
            id: "terms.and.conditions.order.payment.reserve.full.order",
            defaultMessage:
              "We reserve the full order amount prior to the event to ensure payment.",
          })}
        </Text>
      </List.Item>
      <List.Item>
        <Text>
          {intl.formatMessage({
            id: "terms.and.conditions.order.payment.additional.amount",
            defaultMessage:
              "If there is an additional amount, it will be charged to the customer's card on the day of the event.",
          })}
        </Text>
      </List.Item>
    </List>
  )
}
