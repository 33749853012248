import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteItemAttachmentMutationVariables = Types.Exact<{
  data: Types.DeleteItemAttachmentInput;
}>;


export type DeleteItemAttachmentMutation = { __typename?: 'Mutation', deleteItemAttachment: { __typename?: 'ItemModel', uuid: string, attachments?: Array<{ __typename?: 'AttachmentModel', uuid: string, fileName: string, ext: Types.FileExtensionEnum, contentType: Types.ContentTypeEnum }> | null } };


export const DeleteItemAttachmentDocument = gql`
    mutation deleteItemAttachment($data: DeleteItemAttachmentInput!) {
  deleteItemAttachment(data: $data) {
    uuid
    attachments {
      uuid
      fileName
      ext
      contentType
    }
  }
}
    `;
export type DeleteItemAttachmentMutationFn = Apollo.MutationFunction<DeleteItemAttachmentMutation, DeleteItemAttachmentMutationVariables>;

/**
 * __useDeleteItemAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteItemAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemAttachmentMutation, { data, loading, error }] = useDeleteItemAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteItemAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemAttachmentMutation, DeleteItemAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemAttachmentMutation, DeleteItemAttachmentMutationVariables>(DeleteItemAttachmentDocument, options);
      }
export type DeleteItemAttachmentMutationHookResult = ReturnType<typeof useDeleteItemAttachmentMutation>;
export type DeleteItemAttachmentMutationResult = Apollo.MutationResult<DeleteItemAttachmentMutation>;
export type DeleteItemAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteItemAttachmentMutation, DeleteItemAttachmentMutationVariables>;