import { useBreadcrumb } from "../../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../../shared/routes/paths"
import AddIcon from "../../../../../../ui/AddIcon"
import Button from "../../../../../../ui/Button"
import Container from "../../../../../../ui/Container"
import type { ILocationsParams } from "../../../../../Settings/Locations/hookforms.interfaces"
import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type AddComboButtonProps = {
  asButton?: boolean
  isCorporate?: boolean
}

export const AddComboButton: React.FC<AddComboButtonProps> = ({
  asButton = false,
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { pushBreadcrumb } = useBreadcrumb()
  const { locationUUID } = useParams<ILocationsParams>()

  const createProductPath = isCorporate
    ? paths.restaurants.createCombo
    : paths.restaurants.locations.createComboByLocation(locationUUID)

  const onClickHandler = () => {
    pushBreadcrumb(createProductPath, {
      currentPageName: intl.formatMessage({
        id: "restaurants.menu.items.combos.components.add.combo.button.current.page",
        defaultMessage: "Items",
      }),
    })
  }

  return asButton ? (
    <>
      <Container margin="28px 0px 0px 0px" />
      <Button
        onClick={onClickHandler}
        title={intl.formatMessage({
          id: "restaurants.menu.items.combos.components.add.combo.button.title",
          defaultMessage: "Create Combo",
        })}
      />
    </>
  ) : (
    <AddIcon onClick={onClickHandler} />
  )
}
