const MINUTES_INTERVAL = 30

export const getTimeOptions = (interval: number = MINUTES_INTERVAL) => {
  let minutesAccum = 60
  const times = []
  for (let i = 1; minutesAccum <= 12.5 * 60; i++) {
    const hh = Math.floor(minutesAccum / 60) // getting hours of day
    const mm = minutesAccum % 60 // getting minutes of the hour in 0-55 format
    times[i] = `0${hh}`.slice(-2) + ":" + `0${mm}`.slice(-2)
    minutesAccum = minutesAccum + interval
  }

  return times
    .filter((value) => !!value)
    .map((value) => ({ value, label: value }))
}
