import { IntlMessage } from "../../../../../shared/types"
import { PrimaryCategories, SecondaryCategories } from "../../hooks/useReports"

interface LabelAndTooltip {
  label: IntlMessage
  tooltip: IntlMessage
}

export const financialCardFields: Record<PrimaryCategories, LabelAndTooltip> = {
  grossSales: {
    label: {
      id: "restaurants.reports.financial.cards.gross.sales.label",
      defaultMessage: "GROSS SALES",
    },
    tooltip: {
      id: "restaurants.reports.financial.cards.gross.sales.tooltip",
      defaultMessage:
        "Includes sales of goods or services at their full value without any deductions.",
    },
  },
  netSales: {
    label: {
      id: "restaurants.reports.financial.cards.net.sales.label",
      defaultMessage: "NET SALES",
    },
    tooltip: {
      id: "restaurants.reports.financial.cards.net.sales.tooltip",
      defaultMessage:
        "Represent the total revenue earned after tips, refunds, discounts, and any additional fees.",
    },
  },
  taxes: {
    label: {
      id: "restaurants.reports.financial.cards.taxes.label",
      defaultMessage: "TAXES",
    },
    tooltip: {
      id: "restaurants.reports.financial.cards.taxes.tooltip",
      defaultMessage:
        "Extra charges added to sales transactions, collected by the seller on behalf of the government, and sent to tax authorities.",
    },
  },
  incomingPayout: {
    label: {
      id: "restaurants.reports.financial.cards.incoming.payout.label",
      defaultMessage: "INCOMING PAYOUT",
    },
    tooltip: {
      id: "restaurants.reports.financial.cards.incoming.payout.tooltip",
      defaultMessage: " The amount of money in transit to your bank.",
    },
  },

  transactions: {
    label: {
      id: "restaurants.reports.financial.cards.transctions.label",
      defaultMessage: "TRANSACTIONS",
    },
    tooltip: {
      id: "restaurants.reports.financial.cards.transctions.tooltip",
      defaultMessage: "The number of completed orders.",
    },
  },
  transactionFees: {
    label: {
      id: "restaurants.reports.financial.cards.transction.fees.label",
      defaultMessage: "TRANSACTION FEES",
    },
    tooltip: {
      id: "restaurants.reports.financial.cards.transction.fees.tooltip",
      defaultMessage: "The expenses charged for all payments.",
    },
  },
}

export const simpleCardFields: Record<SecondaryCategories, IntlMessage> = {
  cardOrders: {
    id: "restaurants.reports.financial.cards.card.orders.label",
    defaultMessage: "CARD ORDERS",
  },
  cashOrders: {
    id: "restaurants.reports.financial.cards.cash.orders.label",
    defaultMessage: "CASH ORDERS",
  },
  tips: {
    id: "restaurants.reports.financial.cards.tips.label",
    defaultMessage: "TIPS",
  },
  discounts: {
    id: "restaurants.reports.financial.cards.discounts.label",
    defaultMessage: "DISCOUNTS",
  },
  refund: {
    id: "restaurants.reports.financial.cards.refund.label",
    defaultMessage: "REFUNDS",
  },
}

export const downloadErrorIntl = {
  id: "restaurants.reports.generic.download.error",
  defaultMessage: "Unable to download. Please try again later.",
}
