import { createContext, useContext, useState } from "react"

export interface TimeEntry {
  uuid: string
  startDate: string
  endDate: string | undefined
}
export type EmployeeTimeEntry = Record<string, TimeEntry[]>

const TimeEntriesContext = createContext<{
  entries: EmployeeTimeEntry
  updateData: (data: EmployeeTimeEntry) => void
} | null>(null)

interface TimeEntriesProviderProps {
  children: React.ReactNode
  timeEntries: EmployeeTimeEntry
}

export const TimeEntriesProvider = ({
  children,
  timeEntries,
}: TimeEntriesProviderProps) => {
  const [data, setData] = useState<{ entries: EmployeeTimeEntry }>({
    entries: timeEntries,
  })

  const updateData = (newData: EmployeeTimeEntry) => {
    setData({ entries: newData })
  }

  return (
    <TimeEntriesContext.Provider value={{ entries: data.entries, updateData }}>
      {children}
    </TimeEntriesContext.Provider>
  )
}

export const useTimeEntriesContext = () => {
  const context = useContext(TimeEntriesContext)

  if (context === null) {
    throw new Error(
      "useTimeEntriesContext must be used within a TimeEntriesProvider"
    )
  }
  return context
}
