import { isoStringHoursFormat } from "../../../../../shared/utils/constant/dateFormats"
import Container from "../../../../../ui/Container"
import Timeline from "../../../../../ui/Timeline"
import TimelineItem from "../../../../../ui/Timeline/TimelineItem"
import Text from "../../../../../ui/Typography/Text"
import OrderActionContainer from "../OrderActionContainer"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import OrderTimelineAction from "./OrderTimelineAction"
import moment from "moment-timezone"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const OrderTimeline: React.FC = ({ ...props }) => {
  const intl = useIntl()

  const { watch } = useFormContext<OrderDetailModalForm>()

  const orderActions = watch("orderSummary")?.orderActions?.sort((a, b) =>
    a.actionAt.localeCompare(b.actionAt)
  )
  const orderNumber = watch("orderDetail.orderNumber")

  if (!orderActions?.length) {
    return (
      <StyledEmptyContainer {...props} role="order-detail-timeline-empty">
        <Text size="s">
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.order.timeline.empty.title",
            defaultMessage: "No data to display.",
          })}
        </Text>
      </StyledEmptyContainer>
    )
  }

  return (
    <OrderTimelineContainer {...props} role="order-detail-timeline">
      <Timeline>
        {orderActions?.map((orderAction, key) => (
          <TimelineItem key={key}>
            <Container display="flex" flexDirection="column">
              <OrderTimelineAction
                orderAction={orderAction}
                orderNumber={orderNumber}
              />
              <Text size="s" color="Neutral6">
                {`${moment(orderAction.actionAt).fromNow()} at ${moment(
                  orderAction.actionAt
                ).format(isoStringHoursFormat)}`}
              </Text>
            </Container>
          </TimelineItem>
        ))}
      </Timeline>
    </OrderTimelineContainer>
  )
}

const OrderTimelineContainer = styled(OrderActionContainer)`
  padding-top: 0;
  overflow-y: auto;
  flex-grow: 1;
  min-height: 300px;
  max-height: 480px;
`

const StyledEmptyContainer = styled(Container)`
  padding: 40px;
  padding-top: 0;
`
