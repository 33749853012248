import { TimeEntriesByLocationEmployeeQuery } from "../Graphql/timeEntriesByLocationEmployee.generated"
import styles from "./ListEmployees.module.css"
import RowDetails from "./RowDetails"
import RowEmployee from "./RowEmployee"
import { Center, Table, Text } from "@mantine/core"
import { Fragment, useState } from "react"
import { useIntl } from "react-intl"

type ListEmployeesProps = {
  queryResponse: TimeEntriesByLocationEmployeeQuery["timeEntriesByLocationEmployee"]["employeeTimeEntries"]
}

export const ListEmployees = ({ queryResponse }: ListEmployeesProps) => {
  const intl = useIntl()

  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const rows = queryResponse.map((employee, index) => (
    <Fragment key={employee.uuid}>
      <RowEmployee
        index={index}
        openIndex={openIndex}
        employee={employee}
        setOpenIndex={setOpenIndex}
      />
      {openIndex === index &&
        employee.timeEntries?.map((timeEntry, idx) => (
          <RowDetails
            key={idx}
            timeEntry={{
              ...timeEntry,
              startDate: timeEntry.startDate,
              endDate: timeEntry.isRunning ? undefined : timeEntry.endDate,
              isRunning: timeEntry.isRunning,
              employee: {
                uuid: employee.uuid,
                name: `${employee.firstName} ${employee.lastName}`,
                payRate: employee.payRate ?? undefined,
              },
            }}
          />
        ))}
    </Fragment>
  ))

  return (
    <>
      {queryResponse.length > 0 ? (
        <Table withBorder>
          <thead className={styles["list-employees-thead"]}>
            <tr>
              <th />
              <th>
                <Text ta="center" size="xs">
                  {intl.formatMessage({
                    id: "restaurant.time.entries.table.thead.duration.label",
                    defaultMessage: "Duration",
                  })}
                </Text>
              </th>
              <th>
                <Text ta="center" size="xs">
                  {intl.formatMessage({
                    id: "restaurant.time.entries.table.thead.tips.label",
                    defaultMessage: "Tips",
                  })}
                </Text>
              </th>
              <th>
                <Text ta="center" size="xs">
                  {intl.formatMessage({
                    id: "restaurant.time.entries.table.thead.amount.label",
                    defaultMessage: "Amount",
                  })}
                </Text>
              </th>
              <th />
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <Center h="100%" py={100}>
          <Text size="sm" c="gray.6">
            {intl.formatMessage({
              id: "restaurant.time.entries.table.empty",
              defaultMessage: "No Time Entries",
            })}
          </Text>
        </Center>
      )}
    </>
  )
}
