import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMenuDetailQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetMenuDetailQuery = { __typename?: 'Query', getMenuDetail: { __typename?: 'RMenuDetail', uuid: string, applyToAllLocations?: boolean | null, isVisible?: boolean | null, createdAt: any, hasSnapshot: boolean, isMain: boolean, menuType: Types.MenuTypeEnum, name: string, publishedAt?: any | null, snapshotUpdatedAt?: any | null, status: Types.MenuStatusEnum, updatedAt: any, itemSortBy?: Types.SortingByMenuEnum | null, combos: Array<{ __typename?: 'RDraftMenuCombo', uuid: string, isPublished: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name: string, attachment?: { __typename?: 'AttachmentModel', uuid: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string } | null }>, items: Array<{ __typename?: 'RDraftMenuItem', uuid: string, isPublished: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name: string, createdAt?: any | null, attachment?: { __typename?: 'AttachmentModel', uuid: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string } | null }>, locations?: Array<{ __typename?: 'RBasicEntityData', uuid: string, name: string }> | null } };


export const GetMenuDetailDocument = gql`
    query getMenuDetail($uuid: ID!) {
  getMenuDetail(uuid: $uuid) {
    uuid
    applyToAllLocations
    isVisible
    combos {
      uuid
      attachment {
        uuid
        contentType
        ext
        fileName
        signedUrl
      }
      isPublished
      isSoldOut
      isVisible
      name
    }
    createdAt
    hasSnapshot
    isMain
    items {
      uuid
      attachment {
        uuid
        contentType
        ext
        fileName
        signedUrl
      }
      isPublished
      isSoldOut
      isVisible
      name
      createdAt
    }
    locations {
      uuid
      name
    }
    menuType
    name
    publishedAt
    snapshotUpdatedAt
    status
    updatedAt
    itemSortBy
  }
}
    `;

/**
 * __useGetMenuDetailQuery__
 *
 * To run a query within a React component, call `useGetMenuDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetMenuDetailQuery(baseOptions: Apollo.QueryHookOptions<GetMenuDetailQuery, GetMenuDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuDetailQuery, GetMenuDetailQueryVariables>(GetMenuDetailDocument, options);
      }
export function useGetMenuDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuDetailQuery, GetMenuDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuDetailQuery, GetMenuDetailQueryVariables>(GetMenuDetailDocument, options);
        }
export type GetMenuDetailQueryHookResult = ReturnType<typeof useGetMenuDetailQuery>;
export type GetMenuDetailLazyQueryHookResult = ReturnType<typeof useGetMenuDetailLazyQuery>;
export type GetMenuDetailQueryResult = Apollo.QueryResult<GetMenuDetailQuery, GetMenuDetailQueryVariables>;