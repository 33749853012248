import faker from "faker"
import range from "lodash/range"

export interface Result {
  uuid: string
  name: string
  isPublished: boolean
  hasSnapshot: boolean
  isMain: boolean
  isVisible: boolean
  menus: Array<{ uuid: string; name: string }>
  items: number
}

export interface GetComboList {
  endCursor: string | null
  hasNextPage: boolean
  results: Array<Result>
}

export interface getComboListMockType {
  getComboList: GetComboList
}

export const getComboListMock: getComboListMockType = {
  getComboList: {
    endCursor: null,
    hasNextPage: false,
    results: range(faker.datatype.number({ min: 0, max: 20 })).map(() => ({
      uuid: faker.datatype.uuid(),
      name: faker.commerce.productName(),
      hasSnapshot: faker.datatype.boolean(),
      isPublished: faker.datatype.boolean(),
      isMain: faker.datatype.boolean(),
      isVisible: faker.datatype.boolean(),
      menus: range(faker.datatype.number({ min: 1, max: 3 })).map(() => ({
        uuid: faker.git.commitSha(),
        name: faker.commerce.department(),
      })),
      items: faker.datatype.number({ min: 2, max: 7 }),
    })),
  },
}
