import type {
  RItemDetail,
  RuuidInput,
} from "../../../../../shared/graphql/generated/types"
import { storedBreadcrumbs } from "../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../shared/routes/paths"
import { breadcrumbs } from "../../../../../ui/Breadcrumb"
import Breadcrumb from "../../../../../ui/Breadcrumb/Breadcrumb"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import { ModalFull } from "../../../../../ui/ModalFull/ModalFull"
import { formNotification } from "../../../../../ui/notification"
import type { ILocationsParams } from "../../../../Settings/Locations/hookforms.interfaces"
import CorporateDetailHeader from "../../../components/CorporateDetailHeader/CorporateDetailHeader"
import { CorporateItemFormResolver } from "../forms/corporateView/CorporateItemForm.yup"
import ItemDetailForm from "../forms/corporateView/ItemDetailForm"
import VariantDetailForm from "../forms/corporateView/VariantDetailForm"
import { useItems } from "../hooks/useItems"
import type { ICorporateItemForm } from "../interfaces/hookforms.interfaces"
import { getCorporateVariantValues } from "../utils/getCorporateVariantValues"
import { CorporateItemError } from "../utils/notifications.titles"
import omit from "lodash/omit"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt, useHistory, useParams } from "react-router-dom"

type CorporateItemDetail = {
  item: RItemDetail
}

export const CorporateItemDetail: React.FC<CorporateItemDetail> = ({
  item,
}) => {
  const intl = useIntl()
  const { saveCorporateItem, corporateItemSaved } = useItems({})
  const {
    uuid,
    isSoldOut,
    isVisible,
    variants,
    updatedAt,
    publishedAt,
    menus,
    snapshotUpdatedAt,
  } = item

  const { locationUUID } = useParams<ILocationsParams>()

  const { restaurants } = paths
  const { push } = useHistory()

  const currentMenus = menus?.filter((menu) => !menu.isMain) ?? []

  const pageName =
    item.name ??
    intl.formatMessage({
      id: "restaurants.menu.items.corporate.item.detail.page.name.default",
      defaultMessage: "Corporate Menu Item",
    })

  const formMethods = useForm<ICorporateItemForm>({
    mode: "all",
    resolver: CorporateItemFormResolver,
    defaultValues: {
      uuid,
      updatedAt: updatedAt ?? snapshotUpdatedAt,
      isSoldOut: isSoldOut ?? false,
      isVisible: isVisible ?? true,
      variants: getCorporateVariantValues(variants),
      menus:
        menus?.filter((menu) => !menu.isMain).map((menu) => menu.uuid) ?? [],
    },
  })

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = formMethods

  const itemUpdatedAt = watch("updatedAt")

  const redirectToList = () => {
    const path = restaurants.locations.itemsByLocation(locationUUID)

    reset({}, { keepValues: true })

    if (storedBreadcrumbs().length === 1) {
      push(path)
    }
  }

  const onSave = () => {
    handleSubmit(
      async (formData) => {
        const input = omit(formData, "updatedAt")
        const finalInput = {
          ...input,
          variants: input.variants.map((variant) => omit(variant, "isDefault")),
        }

        const menusUUID: RuuidInput[] | undefined = finalInput.menus?.map(
          (menu) => {
            return {
              uuid: menu,
            }
          }
        )

        await saveCorporateItem(
          { ...finalInput, menus: menusUUID },
          () => {
            setValue("updatedAt", new Date())
            reset({}, { keepValues: true })
          },
          showGraphqlErrors
        )

        redirectToList()
      },
      (error) => {
        const errors = error?.variants?.filter?.((err) => !!err) ?? []

        const priceError = errors.some((fieldError) => !!fieldError?.price)
          ? { message: intl.formatMessage(CorporateItemError.PRICE) }
          : undefined

        const modifierError = errors.some(
          (fieldError) => !!fieldError?.variantModifierGroups
        )
          ? { message: intl.formatMessage(CorporateItemError.MODIFIER) }
          : undefined

        const soldOutError = errors.some(
          (fieldError) => !!fieldError?.isSoldOut
        )
          ? { message: intl.formatMessage(CorporateItemError.SOLDOUT) }
          : undefined

        const visibilityError = errors.some(
          (fieldError) => !!fieldError?.isVisible
        )
          ? { message: intl.formatMessage(CorporateItemError.VISIBILITY) }
          : undefined

        formNotification({
          key: "corporate-item-detail-form-notification",
          header: intl.formatMessage({
            id: "restaurants.menu.items.create.item.notification.error.header",
            defaultMessage: "You can’t save this corporate menu item",
          }),
          title: intl.formatMessage({
            id: "restaurants.menu.items.create.item.notification.error.title",
            defaultMessage: "To save a corporate menu item you need to:",
          }),
          type: "error",
          error: {
            ...(priceError && { price: priceError }),
            ...(modifierError && { variantModifierGroups: modifierError }),
            ...(soldOutError && { isSoldOut: soldOutError }),
            ...(visibilityError && { isVisible: visibilityError }),
          },
        })
      }
    )()
  }

  return (
    <ModalFull
      title={
        <CorporateDetailHeader
          enablePreview={true}
          showPreview={true}
          loadingSave={corporateItemSaved.loading}
          enableSave={isDirty}
          lastSavedAt={itemUpdatedAt}
          lastPublishedAt={publishedAt}
          onSave={onSave}
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.corporateItemDetail}
            pageName={pageName}
          />
        </CorporateDetailHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ItemDetailForm item={item} currentMenus={currentMenus} />
        <VariantDetailForm item={item} />
      </FormProvider>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </ModalFull>
  )
}
