import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { SortingByMenuEnum } from "../../../../../shared/graphql/generated/types"
import {
  SortingMethodsEnum,
  sortingOptions,
} from "../../../../../shared/utils/helpers/sortArrayByMethod"
import Container from "../../../../../ui/Container"
import Icon from "../../../../../ui/Icon"
import Select from "../../../../../ui/Select"
import Text from "../../../../../ui/Typography/Text"
import {
  GetSortingFieldDocument,
  useGetSortingFieldQuery,
} from "../../GraphQL/getMenuSortField.generated"
import { useUpdateSortingFieldMutation } from "../../GraphQL/updateMenuSortField.generated"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const SelectSortBy = () => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { sort, updateSortingBy } = useFilters()

  useGetSortingFieldQuery({
    variables: {
      uuid: restaurantUUID,
    },
    onCompleted: ({ getSortingField: { uuid, sortingType } }) => {
      if (!uuid || !sortingType) return

      updateSortingBy({
        uuid,
        "sorting-by": SortingMethodsEnum[sortingType],
      })
    },
  })

  const [sortFieldMutation] = useUpdateSortingFieldMutation()

  const onChange = async (value: SortingMethodsEnum) => {
    await sortFieldMutation({
      variables: {
        input: {
          sortUUID: sort.uuid,
          sortBy: SortingByMenuEnum[value],
          restaurantUUID,
        },
      },
      onCompleted: ({ updateSortingField: { uuid, sortingType } }) => {
        if (!uuid || !sortingType) return

        updateSortingBy({
          uuid,
          "sorting-by": SortingMethodsEnum[sortingType],
        })
      },
      refetchQueries: [GetSortingFieldDocument],
    })
  }

  const options = sortingOptions.map((option) => ({
    value: option.value,
    label: intl.formatMessage(option.label),
    extra: <Icon remixiconClass="ri-check-line" size={16} color={"Neutral8"} />,
  }))

  return (
    <StyledContainer>
      <Text size="m" textAlign="center">
        {intl.formatMessage({
          id: "restaurants.menu.sort.by",
          defaultMessage: "Sort by",
        })}
      </Text>
      <Select
        key={sort.uuid}
        aria-label="Sort category by"
        value={sort["sorting-by"]}
        optionLabelProp="label"
        options={options}
        onChange={onChange}
        width="128px"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  gap: 8px;
  align-items: center;

  .ant-select-item-option i {
    opacity: 0;
  }

  .ant-select-item-option-selected {
    span {
      font-weight: 900;
    }

    i {
      opacity: 1;
    }
  }
`
