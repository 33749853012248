import { useQRStyleConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import Text from "../../../../../ui/Typography/Text"
import ColorPickerContent from "./ColorPickerContent"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export type ColorPickerProps = {
  onChange: (color: string, name?: string) => void
  color: string
  text?: boolean
  name?: string
  title: string
  secondary?: boolean
  initColor?: string
}

export const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const {
    color,
    onChange,
    text,
    name,
    title,
    secondary,
    initColor = "",
  } = props
  const intl = useIntl()

  const [defaultColor, setDefaultColor] = useState<string>(initColor)
  const [isDefaultColor, setIsDefaultColor] = useState<boolean>(true)
  const [colorPreview, setColorPreviewColor] = useState<string>(color)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false)

  const { config } = useQRStyleConfig()

  const handleColorPickeClose = () => setIsColorPickerOpen(false)

  const handleEditColor = () => {
    setIsColorPickerOpen((prev) => {
      return !prev
    })
  }

  const handleColorSelected = () => {
    onChange(colorPreview, name)
    handleColorPickeClose()
  }

  const restoreDefaultColor = () => {
    if (!isDefaultColor) {
      setColorPreviewColor(defaultColor)
      onChange(defaultColor, name)
    }
  }

  useEffect(() => {
    setIsDefaultColor(color === defaultColor)
  }, [color, defaultColor])

  useEffect(() => {
    if (!initColor) {
      setDefaultColor(secondary ? config.colorLight : config.colorDark)
    }
  }, [secondary, config, initColor])

  return (
    <div>
      <Container
        display="flex"
        flexDirection="row"
        width="130px"
        gap="8px"
        alignItems="center"
      >
        <StyledContainer
          backgroundColor={color}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="53px"
          onClick={handleEditColor}
        >
          {text && (
            <Text size="m">
              {intl.formatMessage({
                id: "settings.white.label.application.color.picker.text",
                defaultMessage: "Aa",
              })}
            </Text>
          )}
        </StyledContainer>

        <Button
          hierarchy="secondary"
          leadingIcon="ri-edit-line"
          shape="square"
          onClick={handleEditColor}
        />

        <Button
          hierarchy="tertiary"
          leadingIcon="ri-delete-bin-7-line"
          shape="square"
          onClick={restoreDefaultColor}
        />

        {isColorPickerOpen && (
          <ColorPickerContent
            color={colorPreview}
            setColor={setColorPreviewColor}
            onOk={handleColorSelected}
            onClose={handleColorPickeClose}
            title={title}
          />
        )}
      </Container>
    </div>
  )
}

interface StyledContainerProps {
  backgroundColor: string
}

const StyledContainer = styled(Container)<StyledContainerProps>`
  ${({ theme, backgroundColor }) =>
    css`
      background-color: ${backgroundColor};
      border: 1px solid ${theme.colors["Neutral4"]};
      border-radius: 4px;
      padding: 0px;
      height: 32px;
      cursor: pointer;

      :hover {
        border: 1px solid ${theme.colors["Primary2"]};
        box-shadow: ${theme.shadows.elevationMedium};
      }

      .text-typography {
        color: ${theme.colors.white};
      }
    `}
`
