import styles from "./Tag.module.css"
import { TagConfig, TagEnum } from "./config"
import {
  DefaultMantineColor,
  Flex,
  SystemProp,
  Text,
  Tooltip,
} from "@mantine/core"

type TimeTagProps = {
  type: `${TagEnum}`
  text: string
  icon?: React.ComponentType
  background?: SystemProp<DefaultMantineColor>
  children?: React.ReactNode
  tooltip?: {
    label: string
  }
}

export const Tag = (props: TimeTagProps) => {
  const { type, text, background, children, tooltip } = props
  const Icon = TagConfig[type].icon

  return (
    <Tooltip
      withArrow
      disabled={tooltip === undefined}
      bg="gray"
      label={tooltip?.label}
    >
      <Flex
        className={styles["flex-content"]}
        gap={8}
        h={24}
        w={100}
        justify="center"
        align="center"
        bg={background || TagConfig[type].background}
      >
        {Icon && <Icon color={TagConfig[type].textColor} size={12} />}
        <Text size="xs" c={TagConfig[type].textColor}>
          {text}
        </Text>
        {children}
      </Flex>
    </Tooltip>
  )
}
