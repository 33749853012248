import type { IAdmin } from "../../shared/types"
import { post } from "../../shared/utils/api/client"
import { endpoints } from "../../shared/utils/api/endpoints"

interface LoginWithLinkRequest {
  email: string
}

export const createMagicLink = async ({ email }: LoginWithLinkRequest) => {
  return post({ path: endpoints.auth.magiclink, data: { email } })
}

interface ValidateLinkRequest {
  token: string
}

export const validateMagicLink = async ({ token }: ValidateLinkRequest) => {
  return post({
    path: endpoints.auth.validateMagiclink,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export type TokenResponseType = {
  accessToken: string
  refreshToken: string
  admin: IAdmin
}

interface ConfirmationCodeRequest {
  code: string
}

export const validateCode = async ({ code }: ConfirmationCodeRequest) => {
  return post({ path: endpoints.auth.validateCode, data: { code } })
}

export type PublicKeyResponseType = {
  data: Array<{ publicKeyResponse: string }>
}

export const validateMemberInviteLink = async ({
  token,
}: ValidateLinkRequest) => {
  return post({
    path: endpoints.auth.validateMemberInviteLink,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const validateMemberCode = async ({ code }: ConfirmationCodeRequest) => {
  return post({ path: endpoints.auth.validateCodeMemberInvite, data: { code } })
}
