import {
  PaymentMethodTypeEnum,
  TimeEntriesReport,
} from "../../../../shared/graphql/generated/types"
import { useBestSellerItemsQuery } from "../GraphQL/BestSellerItems.generated"
import { useLaborCostSummaryQuery } from "../GraphQL/LaborCost.generated"
import { useSalesSummaryQuery } from "../GraphQL/SalesSummary.generated"
import { NumberType } from "../components/Comparisson/Comparisson"
import { useDebouncedValue } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import * as Sentry from "@sentry/react"
import moment from "moment-timezone"
import { useIntl } from "react-intl"

type PeriodComparison = {
  previous: number
  current: number
  type: NumberType
}
type FinancialSections = "primary" | "secondary"
export type PrimaryCategories =
  | "grossSales"
  | "netSales"
  | "taxes"
  | "incomingPayout"
  | "transactions"
  | "transactionFees"
export type SecondaryCategories =
  | "cardOrders"
  | "cashOrders"
  | "tips"
  | "discounts"
  | "refund"

type CustomerSatisfactionFields = "discounts" | "storeCash" | "refunds"

export type CustomerSatisfactionSummary = Record<
  CustomerSatisfactionFields,
  PeriodComparison
>
type LaborCostFields = "total" | "payroll" | "tips"

export type FinancialReportSummary = {
  [K in FinancialSections]: K extends "primary"
    ? Record<PrimaryCategories, PeriodComparison>
    : Record<SecondaryCategories, PeriodComparison>
}

export type LaborCostSummary = Record<LaborCostFields, PeriodComparison> & {
  employees?: Array<TimeEntriesReport> | null
}

type useReportsProps = {
  isCorporate: boolean
  endDate: string
  startDate: string
  locations: Array<string>
}

const paymentTypes: Record<
  PaymentMethodTypeEnum,
  { id: string; defaultMessage: string }
> = {
  [PaymentMethodTypeEnum.CARD]: {
    id: "restaurants.reports.financial.table.card.label",
    defaultMessage: "Card",
  },
  [PaymentMethodTypeEnum.CASH]: {
    id: "restaurants.reports.financial.table.cash.label",
    defaultMessage: "Cash",
  },
  [PaymentMethodTypeEnum.FREE_OF_CHARGE]: {
    id: "restaurants.reports.financial.table.free.of.charge.label",
    defaultMessage: "Free of charge",
  },
}

export const useReports = (props: useReportsProps) => {
  const { endDate, isCorporate, locations, startDate } = props
  const intl = useIntl()
  const [debouncedLocations] = useDebouncedValue(locations, 1000)
  const [debouncedEndDate] = useDebouncedValue(endDate, 1000)
  const [debouncedStartDate] = useDebouncedValue(startDate, 1000)
  const rangeDateValid = moment(debouncedStartDate).isBefore(debouncedEndDate)

  const laborCost = useLaborCostSummaryQuery({
    variables: { endDate, locations, startDate },
    skip: isCorporate || !endDate || !startDate,
    onError: (error) => {
      notifications.show({
        message: intl.formatMessage({
          id: "restaurants.reports.hook.labor.cost.error.message",
          defaultMessage: "Unable to get labor cost data. Contact support.",
        }),
        color: "red",
        autoClose: false,
        withBorder: true,
        id: "error/labor-cost",
      })
      Sentry.captureException(error, { tags: { query: "laborCostSummary" } })
    },
  })

  //TODO: IMPLEMENT THE DEBOUNCED VALUES
  const bestSellerItems = useBestSellerItemsQuery({
    // skip: !endDate || !startDate,
    skip: true,
    variables: { startDate, endDate, locations },
    onError: (error) => {
      notifications.show({
        message: intl.formatMessage({
          id: "restaurants.reports.hook.best.seller.items.error.message",
          defaultMessage:
            "Unable to get best seller items data. Contact support.",
        }),
        color: "red",
        autoClose: false,
        withBorder: true,
        id: "error/best-seller-items",
      })
      Sentry.captureException(error, { tags: { query: "bestSellerItems" } })
    },
  })

  //TODO: Discuss with the team if we'll keep this logic on this component
  // const discountsSummary = useDiscountsSummaryQuery({
  //   skip: !endDate || !startDate,
  //   variables: { startDate, endDate, locations },
  //   onError: (error) => {
  //     notifications.show({
  //       message: intl.formatMessage({
  //         id: "restaurants.reports.hook.discountsSummary.items.error.message",
  //         defaultMessage:
  //           "Unable to get discounts summary data. Contact support.",
  //       }),
  //       color: "red",
  //       autoClose: false,
  //       withBorder: true,
  //       id: "error/best-seller-items",
  //     })
  //     Sentry.captureException(error, { tags: { query: "bestSellerItems" } })
  //   },
  // })

  const salesSummary = useSalesSummaryQuery({
    variables: {
      endDate: debouncedEndDate,
      locations: debouncedLocations,
      startDate: debouncedStartDate,
    },
    skip: !debouncedStartDate || !debouncedEndDate || !rangeDateValid,
    fetchPolicy: "network-only",
    onError: (error) => {
      notifications.show({
        message: intl.formatMessage({
          id: "restaurants.reports.hook.financial.report.error.message",
          defaultMessage:
            "Unable to get financial report data. If the problem persists, contact support.",
        }),
        color: "red",
        autoClose: false,
        withBorder: true,
        id: "error/financial-report",
      })
      Sentry.captureException(error, { tags: { query: "salesSummary" } })
    },
  })

  // const discountsSummaryInfo = discountsSummary.data?.discountsSummary

  const loading = {
    financialReport: salesSummary.loading,
    laborCost: laborCost.loading,
    bestSellerItems: bestSellerItems.loading,
    // discounts: discountsSummary.loading,
  }

  const bestSellerItemsInfo =
    bestSellerItems.data?.bestSellerItems.currentPeriod

  const totalSales = salesSummary.data?.salesSummary.sales.totalSales
  const totalNetPayments = salesSummary.data?.salesSummary.payments.netTotal

  const laborCostInfo: LaborCostSummary = {
    total: {
      previous:
        (totalSales?.tips ?? 0) -
        (laborCost.data?.laborCostSummary?.previousPeriod?.payroll ?? 0),
      current:
        (totalSales?.tips ?? 0) -
        (laborCost.data?.laborCostSummary?.currentPeriod?.payroll ?? 0),
      type: "money",
    },
    payroll: {
      previous: laborCost.data?.laborCostSummary.previousPeriod?.payroll ?? 0,
      current: laborCost.data?.laborCostSummary.currentPeriod?.payroll ?? 0,
      type: "money",
    },
    tips: {
      previous: totalSales?.tips ?? 0,
      current: totalSales?.tips ?? 0,
      type: "money",
    },
    employees: laborCost.data?.laborCostSummary.currentPeriod?.employees,
  }

  const salesSummaryInfo = [
    {
      tableName: intl.formatMessage({
        id: "restaurants.reports.financial.table.sales.label",
        defaultMessage: "Sales",
      }),
      rows: [
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.gross.sales.label",
            defaultMessage: "Gross Sales",
          }),
          highlight: true,
          amount: totalSales?.netSales?.grossSales?.amount ?? 0,
          subRows: [
            {
              name: intl.formatMessage({
                id: "restaurants.reports.financial.table.items.label",
                defaultMessage: "Items",
              }),
              amount: totalSales?.netSales.grossSales.items ?? 0,
            },
          ],
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.discounts.label",
            defaultMessage: "Discounts",
          }),
          amount: -(totalSales?.netSales.discounts ?? 0),
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.store.cash.label",
            defaultMessage: "Store cash",
          }),
          amount: -(totalSales?.netSales.storeCashes ?? 0),
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.net.sales.label",
            defaultMessage: "Net Sales",
          }),
          highlight: true,
          amount: totalSales?.netSales.amount ?? 0,
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.taxes.label",
            defaultMessage: "Taxes",
          }),
          amount: totalSales?.taxes ?? 0,
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.tips.label",
            defaultMessage: "Tips",
          }),
          amount: totalSales?.tips ?? 0,
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.refunds.label",
            defaultMessage: "Refunds",
          }),
          amount: -(totalSales?.returns ?? 0),
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.total.sales.label",
            defaultMessage: "Total sales",
          }),
          amount: totalSales?.amount ?? 0,
          highlight: true,
        },
      ],
    },
    {
      tableName: intl.formatMessage({
        id: "restaurants.reports.financial.table.payments.label",
        defaultMessage: "Payments",
      }),
      rows: [
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.total.collected.label",
            defaultMessage: "Total Collected",
          }),
          amount:
            totalNetPayments?.totalCollects.reduce((a, b) => a + b.amount, 0) ??
            0,
          subRows: totalNetPayments?.totalCollects.map((p) => ({
            name: intl.formatMessage(paymentTypes[p.paymentMethod]),
            amount: p.amount,
          })),
          highlight: true,
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.fees.label",
            defaultMessage: "Fees",
          }),
          amount: -(totalNetPayments?.fees ?? 0),
        },
        {
          name: intl.formatMessage({
            id: "restaurants.reports.financial.table.net.total.label",
            defaultMessage: "Net Total",
          }),
          highlight: true,
          amount: totalNetPayments?.amount ?? 0,
        },
      ],
    },
  ]

  return {
    loading,
    laborCostInfo,
    bestSellerItemsInfo,
    salesSummaryInfo,
    // discountsSummaryInfo,
  }
}
