import { useBreadcrumb } from "../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../shared/routes/paths"
import AddIcon from "../../../../ui/AddIcon"
import Button from "../../../../ui/Button"
import Container from "../../../../ui/Container"
import type { ILocationsParams } from "../../../Settings/Locations/hookforms.interfaces"
import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

interface IAddModifierGroupButton {
  createButton?: boolean
  isCorporate?: boolean
}

export const AddModifiersButton: React.FC<IAddModifierGroupButton> = ({
  isCorporate,
  createButton,
}) => {
  const intl = useIntl()
  const { pushBreadcrumb } = useBreadcrumb()
  const { locationUUID } = useParams<ILocationsParams>()

  const createModifierPath = isCorporate
    ? paths.restaurants.createModifiers
    : paths.restaurants.locations.createModifiersByLocation(locationUUID)

  const onHandleClick = () => {
    pushBreadcrumb(createModifierPath, {
      currentPageName: intl.formatMessage({
        id: "restaurants.modifiers.group.title",
        defaultMessage: "Modifiers",
      }),
    })
  }

  return (
    <Container display="flex" alignItems="center" justifyContent="center">
      {createButton ? (
        <StyleButtonCreate
          onClick={onHandleClick}
          title={intl.formatMessage({
            id: "restaurants.modifiers.group.empty.button.title",
            defaultMessage: "Create Modifier Group",
          })}
        />
      ) : (
        <AddIcon onClick={onHandleClick} />
      )}
    </Container>
  )
}

export const AddCorporateModifiersButton = () => (
  <AddModifiersButton isCorporate />
)

const StyleButtonCreate = styled(Button)`
  margin-top: 24px;
`
