import { ONBOARDING_STATE } from "../../pages/Onboarding/constants"
import type { AttachmentTypeEnum } from "../graphql/generated/types"
import { usePersistedState } from "../hooks/usePersistedState"
import type { IOnboarding } from "../types"
import getImageFilename from "../utils/helpers/getImageFilename"
import getImageMetadata from "../utils/helpers/getImageMetadata"
import type { RcFile } from "antd/lib/upload"
import React, { createContext, useContext } from "react"

const OnboardingContext = createContext<{
  onboarding: IOnboarding
  updateOnboardingState: (data: Partial<IOnboarding>) => void
  upsertLogo: (type: AttachmentTypeEnum, file?: RcFile) => void
  addUuidToLogo: (type: AttachmentTypeEnum, uuid: string) => void
  loadFromSnapshot: (data: Partial<IOnboarding>) => void
  clearOnboardingState: () => void
} | null>(null)

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext)

  if (context === null) {
    throw new Error("Onboarding must be used within a OnboardingProvider")
  }

  return context
}

const initialOnboardingState: IOnboarding = {
  brandColor: "",
  name: "",
  urlIdentifier: "",
  loading: false,
  allowOnboarding: false,
  location: { name: "" },
}

export const OnboardingProvider: React.FC = ({ children }) => {
  const [onboardingState, setOnboardingState] = usePersistedState(
    ONBOARDING_STATE,
    initialOnboardingState
  )

  const loadFromSnapshot = (data: Partial<IOnboarding>) => {
    setOnboardingState((prev) => ({
      ...prev,
      ...data,
      location: { ...prev.location, ...data?.location },
      logos: data.logos,
    }))
  }

  //NOTE: Do not send logos with this function since they won't be saved instead use {upsertLogo}
  const updateOnboardingState = (data: Partial<IOnboarding>) => {
    const logosTemp = onboardingState.logos

    setOnboardingState((prev) => ({
      ...prev,
      ...data,
      location: { ...prev.location, ...data?.location },
      logos: logosTemp,
    }))
  }

  const clearOnboardingState = () => {
    setOnboardingState(initialOnboardingState)
  }

  const upsertLogo = (type: AttachmentTypeEnum, file?: RcFile) => {
    if (!file) return
    const logosArray =
      onboardingState.logos?.filter((logo) => logo.type !== type) ?? []

    logosArray?.push({
      ...getImageMetadata(file.type),
      fileName: getImageFilename(file.name),
      type: type,
      file,
    })

    setOnboardingState((prev) => ({ ...prev, logos: logosArray }))
  }

  const addUuidToLogo = (type: AttachmentTypeEnum, uuid: string) => {
    const logo = onboardingState.logos?.find(
      (onboardingLogo) => onboardingLogo.type === type
    )

    if (!logo) return

    logo.uuid = uuid
  }

  return (
    <OnboardingContext.Provider
      value={{
        onboarding: onboardingState,
        loadFromSnapshot,
        updateOnboardingState,
        upsertLogo,
        addUuidToLogo,
        clearOnboardingState,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
