import type {
  CustomerSupportDetail,
  SalesByPaymentType,
  SalesSummaryDetail,
} from "../../../../shared/graphql/generated/types"
import { Container } from "../../../../ui/Container/Container"
import { Text } from "../../../../ui/Typography/Text/Text"
import ReportsSkeleton from "../ReportsSkeleton"
import reportsTableConfig from "../utils/reports-list.util"
import { Col, Row } from "antd"
import Table from "antd/lib/table"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

interface IReportsTables {
  loading: boolean
  salesSummary?: SalesSummaryDetail
  salesByPaymentType?: SalesByPaymentType
  customerSupportDetail?: CustomerSupportDetail
}

export const ReportsTables: React.FC<IReportsTables> = ({
  salesSummary,
  salesByPaymentType,
  customerSupportDetail,
  loading,
}) => {
  const intl = useIntl()

  const [columns] = reportsTableConfig()

  const card = salesByPaymentType?.card ?? 0
  const netSales = salesByPaymentType?.total ?? 0
  const cash = salesByPaymentType?.cash
  const cardPercentage = card && netSales ? (card / netSales) * 100 : 0
  const cashPercentage = cash && netSales ? (cash / netSales) * 100 : 0

  const salesSummaryDetail = [
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.summary.detail.row.gross.sales.title",
        defaultMessage: "Gross Sales",
      }),
      amount: salesSummary?.grossSales ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.summary.detail.row.net.sales.title",
        defaultMessage: "Net Sales",
      }),
      amount: salesSummary?.netSales ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.summary.detail.row.tips.title",
        defaultMessage: "Tips",
      }),
      amount: salesSummary?.tips ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.summary.detail.row.taxes.title",
        defaultMessage: "Taxes",
      }),
      amount: salesSummary?.taxes ?? 0,
    },

    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.summary.detail.row.delivery.fees.title",
        defaultMessage: "Delivery Fees",
      }),
      amount: salesSummary?.deliveryFees ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.summary.detail.row.service.fees.title",
        defaultMessage: "Service Fees",
      }),
      amount: salesSummary?.serviceFees ?? 0,
    },
  ]

  const salesByPaymentTypes = [
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.sales.by.payment.types.row.total.collected.title",
        defaultMessage: "Total Collected",
      }),
      amount: salesByPaymentType?.total ?? 0,
    },
    {
      name: intl.formatMessage(
        {
          id: "restaurants.reports.table.sales.by.payment.types.row.card.title",
          defaultMessage: "Card ({percentage}%)",
        },
        {
          percentage: Math.round((cardPercentage + Number.EPSILON) * 100) / 100,
        }
      ),
      amount: salesByPaymentType?.card ?? 0,
    },
    {
      name: intl.formatMessage(
        {
          id: "restaurants.reports.table.sales.by.payment.types.row.cash.title",
          defaultMessage: "Cash ({percentage}%)",
        },
        {
          percentage: Math.round((cashPercentage + Number.EPSILON) * 100) / 100,
        }
      ),
      amount: salesByPaymentType?.cash ?? 0,
    },
  ]

  const customerSatisfaction = [
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.customer.satisfaction.row.total.rewards.title",
        defaultMessage: "Total Rewards",
      }),
      amount: customerSupportDetail?.total ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.customer.satisfaction.row.loyalty.title",
        defaultMessage: "Loyalty",
      }),
      amount: customerSupportDetail?.loyalty ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.customer.satisfaction.row.discounts.title",
        defaultMessage: "Discounts",
      }),
      amount: customerSupportDetail?.discounts ?? 0,
    },
    {
      name: intl.formatMessage({
        id: "restaurants.reports.table.customer.satisfaction.row.refunds.title",
        defaultMessage: "Refunds",
      }),
      amount: customerSupportDetail?.refunds ?? 0,
    },
  ]

  return (
    <Container>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 18, lg: 24 },
          { xs: 0, sm: 0, md: 18, lg: 24 },
        ]}
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <StyledContainerTable width="100%">
            <Container padding="16px 32px">
              <Text size="xl" weight="bold">
                <FormattedMessage
                  id="restaurants.reports.table.sales.summary.detail.title"
                  defaultMessage="Sales Summary Detail"
                />
              </Text>
            </Container>
            {loading ? (
              <ReportsSkeleton rows={6} />
            ) : (
              <StyleDataTable
                columns={columns}
                dataSource={salesSummaryDetail}
                showHeader={false}
                pagination={false}
              />
            )}
          </StyledContainerTable>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Container display="flex" flexDirection="column" gap="24px">
            <StyledContainerTable width="100%">
              <Container padding="16px 32px">
                <Text size="xl" weight="bold">
                  <FormattedMessage
                    id="restaurants.reports.table.sales.by.payment.types.title"
                    defaultMessage="Sales by Payment Type"
                  />
                </Text>
              </Container>
              {loading ? (
                <ReportsSkeleton rows={3} />
              ) : (
                <StyleDataTable
                  columns={columns}
                  dataSource={salesByPaymentTypes}
                  showHeader={false}
                  pagination={false}
                  className="table-payment-type"
                />
              )}
            </StyledContainerTable>
            <StyledContainerTable width="100%">
              <Container padding="16px 32px">
                <Text size="xl" weight="bold">
                  <FormattedMessage
                    id="restaurants.reports.table.customer.satisfaction.title"
                    defaultMessage="Customer Satisfaction"
                  />
                </Text>
              </Container>
              {loading ? (
                <ReportsSkeleton rows={4} />
              ) : (
                <StyleDataTable
                  columns={columns}
                  dataSource={customerSatisfaction}
                  showHeader={false}
                  pagination={false}
                  className="table-payment-type"
                />
              )}
            </StyledContainerTable>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

const StyleDataTable = styled(Table)`
  ${({ theme }) => `
    padding: 0 16px;

    tr.ant-table-row {
        &:first-child {
            background: ${theme.colors.Neutral8};
            span {
                color: white;
            }
        }
        &:last-child {
            td {
                border: none;
            }
        }
        &:hover > td{
            background: transparent;
        }
    }

    tr td {
        border-bottom: 1px solid ${theme.colors.Neutral4};
       
        &:last-child {
            width: 110px;
        }
    }

    &.table-payment-type {
        tr.ant-table-row {
            &:last-child {
                span {
                    font-weight: 700;
                }
            }
        }
    }
  `}
`

const StyledContainerTable = styled(Container)`
  ${({ theme }) => `
    background: ${theme.colors.Neutral0};
    border-radius: 4px;
    border: 1px solid ${theme.colors.Neutral4};
  `}
`
