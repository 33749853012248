import Button from "../../../../../../../ui/Button"
import Container from "../../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import Icon from "../../../../../../../ui/Icon"
import { InputNumber } from "../../../../../../../ui/Inputs"
import Input from "../../../../../../../ui/Inputs/Input"
import Tooltip from "../../../../../../../ui/Tooltip"
import HighlightedText from "../../../../../../../ui/Typography/HighlightedText"
import UploadIcon from "../../../../../../../ui/UploadIcon"
import SoldOutIcon from "../../../../../components/SoldOutIcon"
import { useItems } from "../../../hooks/useItems"
import type {
  IItemForm,
  VariantType,
} from "../../../interfaces/hookforms.interfaces"
import type { RcFile } from "antd/lib/upload"
import classNames from "classnames"
import { omit } from "lodash"
import get from "lodash/get"
import React, { useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type CollapseVariantHeaderType = {
  variantIndex: number
  variantId: string
  appendVariant: (variant: VariantType) => void
  setImagesUploading: React.Dispatch<React.SetStateAction<number>>
  isCorporate?: boolean
}

export const CollapseVariantHeader: React.FC<CollapseVariantHeaderType> = ({
  variantIndex,
  variantId,
  appendVariant,
  setImagesUploading,
  isCorporate = true,
}) => {
  const intl = useIntl()

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IItemForm>()

  const [defaultFormVariantId, variantName, variantUrlImage] = useWatch({
    name: [
      "defaultVariantId",
      `variants.${variantIndex}.name`,
      `variants.${variantIndex}.attachment.signedUrl`,
    ],
    control: control,
  })

  const [uploadingImage, setUploadingImage] = useState(false)
  const variantErrors = get(errors, `variants.${variantIndex}`)
  const isDefaultVariant = defaultFormVariantId === variantId
  const { createItemVariantAttachment } = useItems({})

  const onSetDefaultClick = () => {
    setValue("defaultVariantId", variantId)
  }

  const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const onDuplicateClick = () => {
    const variant = getValues(`variants.${variantIndex}`)
    const { name, ...rest } = variant
    const hasName = !!name

    const newName = intl.formatMessage(
      {
        id: "restaurants.menu.items.forms.variants.collapse.duplicate.name",
        defaultMessage: "Copy of {name}",
      },
      { name: name }
    )
    const newVariant = {
      ...rest,
      name: hasName ? newName : undefined,
      isDefault: false,
      uuid: undefined,
      tempId: Date.now().toString(),
    }

    appendVariant(newVariant)
  }

  useEffect(() => {
    if (!defaultFormVariantId) return

    if (!isDefaultVariant) setValue(`variants.${variantIndex}.isDefault`, false)
  }, [
    defaultFormVariantId,
    isDefaultVariant,
    setValue,
    variantId,
    variantIndex,
  ])

  const handleImageUpload = async (image?: RcFile) => {
    if (!image) return

    try {
      setImagesUploading((prev) => prev + 1)
      setUploadingImage(true)

      await createItemVariantAttachment(
        image as File,
        (result) => {
          const attachment = omit(
            result.createTempVariantAttachment,
            "__typename?"
          )
          setValue(`variants.${variantIndex}.attachment`, attachment)
        },
        showGraphqlErrors
      )
    } catch (error) {
      showGraphqlErrors(error)
    } finally {
      setImagesUploading((prev) => prev - 1)
      setUploadingImage(false)
    }
  }

  return (
    <Container
      cursor="default"
      display="flex"
      gap="20px"
      justifyContent="space-between"
      width="100%"
      onClick={stopPropagation}
    >
      <Container display="flex" alignItems="center" gap="11px">
        <Controller
          control={control}
          name={`variants.${variantIndex}.image`}
          render={({ field: { value, onChange } }) => (
            <UploadIcon
              onChange={onChange}
              icon={value}
              url={variantUrlImage}
              accept="image/*"
              shape="square"
              onChangeCallback={handleImageUpload}
              size={30}
              imageFit="cover"
              loading={uploadingImage}
              disabled={uploadingImage}
            />
          )}
        />
        <Controller
          control={control}
          name={`variants.${variantIndex}.name`}
          render={({ field }) => (
            <Input
              {...field}
              hasError={!!get(variantErrors, "name")}
              errorMessage={get(variantErrors, "name.message")}
              placeholder={intl.formatMessage({
                id: "restaurants.menu.items.forms.variants.form.input.name.placeholder",
                defaultMessage: "Enter variant Name",
              })}
            />
          )}
        />
      </Container>
      <Container display="flex" alignItems="center" gap="8px">
        <Controller
          control={control}
          name={`variants.${variantIndex}.isDefault`}
          render={({ field }) => {
            return (
              <Container
                display="flex"
                gap="9px"
                className={classNames({
                  "default-variant-action": !field.value,
                })}
              >
                <HighlightedText
                  cursor="pointer"
                  onClick={() => {
                    onSetDefaultClick()
                    field.onChange(true)
                  }}
                >
                  {intl.formatMessage({
                    id: "restaurants.menu.items.forms.variants.form.default.text",
                    defaultMessage: "Default",
                  })}
                </HighlightedText>
                {field.value && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: "restaurants.menu.items.forms.variants.form.default.tooltip.title",
                      defaultMessage:
                        "This will be the variant selected in the mobile app unless the customer changes it",
                    })}
                  >
                    <Icon
                      color="Primary5"
                      remixiconClass="ri-information-line"
                    />
                  </Tooltip>
                )}
              </Container>
            )
          }}
        />
        <Controller
          control={control}
          name={`variants.${variantIndex}.price`}
          render={({ field }) => (
            <InputNumber
              {...field}
              value={field.value ?? ""}
              maxWidth="96px"
              hasError={!!get(variantErrors, "price")}
              errorMessage={get(variantErrors, "price.message")}
              disabled={!variantName}
              placeholder="0.00"
              step={0.01}
              precision={2}
              prefix={intl.formatMessage({
                id: "generic.symbol.currency",
                defaultMessage: "$",
              })}
            />
          )}
        />
        <Controller
          control={control}
          name={`variants.${variantIndex}.calories`}
          render={({ field }) => (
            <InputNumber
              {...field}
              value={field.value ?? undefined}
              maxWidth="96px"
              hasError={!!get(variantErrors, "calories")}
              errorMessage={get(variantErrors, "calories.message")}
              disabled={!variantName}
              placeholder="0000"
              prefix={intl.formatMessage({
                id: "generic.prefix.calories",
                defaultMessage: "Cal",
              })}
            />
          )}
        />
        <Controller
          control={control}
          name={`variants.${variantIndex}.sku`}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="00000"
              disabled={!variantName}
              prefix={intl.formatMessage({
                id: "generic.prefix.sku",
                defaultMessage: "SKU",
              })}
              maxWidth="106px"
            />
          )}
        />
        <Button
          type="button"
          hierarchy="tertiary"
          onClick={onDuplicateClick}
          leadingIcon="ri-file-copy-line"
          shape="square"
        />
        {!isCorporate && (
          <Controller
            control={control}
            name={`variants.${variantIndex}.isSoldOut`}
            render={({ field }) => {
              const isSoldOut = field.value ?? false

              return (
                <Button
                  type="button"
                  hierarchy="tertiary"
                  onClick={() => field.onChange(!field.value)}
                  shape="square"
                >
                  <SoldOutIcon isSoldOut={isSoldOut} color="Primary5" />
                </Button>
              )
            }}
          />
        )}
      </Container>
    </Container>
  )
}
