import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import { cateringPolicyBeforeHour } from "../../../utils/format-catering-order"
import { CateringForm } from "../../shared/hookforms.intefaces"
import { List } from "@mantine/core"
import { ReactNode } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

const cancelationPolicyItems = [
  {
    id: "terms.and.conditions.cancelation.policy.transactions",
    defaultMessage:
      "Cancelation of a catering order stops the transaction as well as the catering order from being processed or completed.",
  },
  {
    id: "terms.and.conditions.cancelation.policy.available.orders",
    defaultMessage:
      "Cancelation of catering orders is only available for orders with the status of 'Placed'",
  },
  {
    id: "terms.and.conditions.cancelation.policy.request.cancelation",
    defaultMessage:
      "To request a cancelation of a catering order, the customer must contact the restaurant.",
  },
]

export const CancelationPolicy = () => {
  const intl = useIntl()

  const { control } = useFormContext<CateringForm>()

  const [cancelPolicy] = useWatch({
    control,
    name: ["rules.cancelPolicy"],
  })

  const percentageItems = cancelPolicy.cancelPolicies
    .filter((item) => item.percentage && item.timeBeforeEvent)
    .map((item, index) => {
      if (!item.percentage || !item.timeBeforeEvent) return

      return (
        <List.Item key={index}>
          {intl.formatMessage(
            {
              id: `terms.and.conditions.cancelation.policy.request.cancelation.before.${
                cateringPolicyBeforeHour[item.timeBeforeEvent]
              }.hour`,
              defaultMessage:
                "Order cancelation charges: <bold>{amount, number, percent}</bold> of the paid amount will be refunder if the order is canceled at least <bold>{hours}</bold> hours before the event",
            },
            {
              amount: safeNumber(item.percentage) / 100,
              hours: cateringPolicyBeforeHour[item.timeBeforeEvent],
              bold: (chunk: ReactNode) => <b>{chunk}</b>,
            }
          )}
        </List.Item>
      )
    })

  return (
    <List>
      {cancelationPolicyItems.map((item) => (
        <List.Item key={item.id}>{intl.formatMessage(item)}</List.Item>
      ))}
      {percentageItems.length > 0 && percentageItems}
    </List>
  )
}
