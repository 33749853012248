import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import Icon from "../../../../../../ui/Icon"
import ItemGroupsForm from "../../forms/ItemGroupsForm"
import type { IComboForm } from "../../interfaces/hookforms.interfaces"
import CollapseComboItemGroupHeader from "../CollapseComboItemGroupHeader"
import React, { useState } from "react"
import type { UseFieldArrayAppend } from "react-hook-form"

type ComboItemGroupCardType = {
  isCorporate: boolean
  cmbItmGrpIndex: number
  appendCmbItmGrp: UseFieldArrayAppend<IComboForm, "comboItemGroups">
  removeCmbItmGrp: (index: number) => void
}

const ComboItemGroupCard: React.FC<ComboItemGroupCardType> = ({
  cmbItmGrpIndex,
  appendCmbItmGrp,
  removeCmbItmGrp,
  isCorporate = true,
}) => {
  const [isWrapperHover, setIsWrapperHover] = useState(false)

  const handleMouseEnter = () => {
    setIsWrapperHover(true)
  }

  const handleMouseLeave = () => {
    setIsWrapperHover(false)
  }

  const deleteItemGroup = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    removeCmbItmGrp(cmbItmGrpIndex)
  }

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Collapse
        classId="menu-items-combo-item-group-form-collapse"
        contentBorderColor="Neutral4"
        removeHeaderBottomBorder
        contentBordered
        defaultActiveKey="combo-item-groups-form-panel"
      >
        <CollapsePanel
          style={{ margin: "0" }}
          key="combo-item-groups-form-panel"
          extra={
            <Container
              position="absolute"
              right="-30px"
              visibleOnHover={!isWrapperHover}
              onClick={deleteItemGroup}
            >
              <Icon remixiconClass="ri-close-line" color="Primary5" />
            </Container>
          }
          header={
            <CollapseComboItemGroupHeader
              appendCmbItmGrp={appendCmbItmGrp}
              cmbItmGrpIndex={cmbItmGrpIndex}
              isCorporate={isCorporate}
            />
          }
        >
          <ItemGroupsForm
            cmbItmGrpIndex={cmbItmGrpIndex}
            isCorporate={isCorporate}
          />
        </CollapsePanel>
      </Collapse>
    </Container>
  )
}

export default ComboItemGroupCard
