import moment from "moment-timezone"

// example: $1,234.56
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

// example: July 1st, 2023
export const formatDate = (date: Date | string | number): string => {
  return moment(date).format("MMMM Do, YYYY")
}

export function addDays(date: Date, days: number) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const MILISECONDS_IN_A_SECOND = 1000
export const SECONDS_IN_A_DAY = 86_400
export const SUCCESS_QUERY_PARAM = "success"
