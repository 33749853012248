import { IntlFormattersType } from "../../../../../shared/types"

export const CODE_MINIMUM_AMOUNT_VALUE_ID =
  "restaurants.settings.locations.catering.rules.minimum.amount.value.error.description"

export const CODE_MINIMUM_AMOUNT_APPLY_ID =
  "restaurants.settings.locations.catering.rules.minimum.amount.apply.error.description"

export const FIELD_TYPE_REQUIRED_ERROR_ID =
  "generic.number.type.error.description"

export const FIELD_NUMBER_MIN_VALUE_ID = "generic.number.min.error.description"
export const FIELD_NUMBER_MIN_OR_ZERO_VALUE_ID =
  "generic.number.min.or.zero.error.description"

export const FIELD_CHECKBOX_REQUIRED_ID =
  "generic.checkbox.required.error.description"

export const validationCateringTitle: IntlFormattersType = {
  [CODE_MINIMUM_AMOUNT_VALUE_ID]: {
    id: CODE_MINIMUM_AMOUNT_VALUE_ID,
    defaultMessage:
      "This field must be greater or equal to the payment fixed amount rule.",
  },
  [CODE_MINIMUM_AMOUNT_APPLY_ID]: {
    id: CODE_MINIMUM_AMOUNT_APPLY_ID,
    defaultMessage:
      "This field must be checked if the payment fixed amount is greater than zero.",
  },
  [FIELD_TYPE_REQUIRED_ERROR_ID]: {
    id: FIELD_TYPE_REQUIRED_ERROR_ID,
    defaultMessage: "This field is required",
  },
  [FIELD_CHECKBOX_REQUIRED_ID]: {
    id: FIELD_CHECKBOX_REQUIRED_ID,
    defaultMessage: "Select at least one",
  },
  [FIELD_NUMBER_MIN_VALUE_ID]: {
    id: FIELD_NUMBER_MIN_VALUE_ID,
    defaultMessage: "This field must be a positive number",
  },
  [FIELD_NUMBER_MIN_OR_ZERO_VALUE_ID]: {
    id: FIELD_NUMBER_MIN_OR_ZERO_VALUE_ID,
    defaultMessage: "This field must be greater or equal to zero",
  },
}
