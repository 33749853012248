import paths from "../paths"
import React from "react"
import type { SwitchProps } from "react-router-dom"
import { Redirect, Route, Switch } from "react-router-dom"

export const AppSwitch: React.FC<SwitchProps> = ({ children, ...props }) => {
  return (
    <Switch {...props}>
      {children}
      <Route path="*" render={() => <Redirect to={paths.general.notFound} />} />
    </Switch>
  )
}
