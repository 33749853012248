import AccessDenied from "../../../pages/AccessDenied"
import ErrorPage from "../../../pages/ErrorPage"
import ValidateToken from "../../../pages/Login/ValidateToken"
import NotFound from "../../../pages/NotFound"
import AppSwitch from "../AppSwitch"
import GuestRoutes from "../GuestRoutes"
import ProtectedRoutes from "../ProtectedRoutes"
import paths from "../paths"
import React from "react"
import { Route } from "react-router-dom"

const AppRouter: React.FC = () => {
  return (
    <AppSwitch>
      <Route exact path={paths.error} component={ErrorPage} />
      <Route exact path={paths.general.notFound} component={NotFound} />
      <Route exact path={paths.general.accessDenied} component={AccessDenied} />
      <Route exact path={paths.general.validate} component={ValidateToken} />
      <Route
        exact
        path={paths.general.validateMember}
        component={ValidateToken}
      />
      <Route
        exact
        path={paths.general.tableScanner}
        component={() => <div>Welcome to Crmb</div>}
      />
      <Route path={paths.guest.login} component={GuestRoutes} />
      <Route
        exact
        path={paths.account.support}
        component={() => <div>Help & Support</div>}
      />
      <Route
        exact
        path={paths.account.roadmap}
        component={() => <div>Roadmap & Changelog</div>}
      />
      <Route
        exact
        path={paths.account.community}
        component={() => <div>Join Slack Community</div>}
      />
      <ProtectedRoutes />
    </AppSwitch>
  )
}
export default AppRouter
