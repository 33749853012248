import { EMPTY_STRING } from "../../../../shared/utils/constant/values"
import { formatCustomerPhone } from "../../../../shared/utils/helpers/formatCustomerPhone"

interface CustomerNameFormatterArgs {
  firstName?: string | null
  lastName?: string | null
  phone: string
}

export const customerNameFormatter = (args: CustomerNameFormatterArgs) => {
  const { firstName, lastName, phone } = args

  const formattedPhone = formatCustomerPhone(phone)

  const fullName =
    !firstName && !lastName
      ? EMPTY_STRING
      : `/ ${firstName ?? EMPTY_STRING} ${lastName ?? EMPTY_STRING}`

  return `${formattedPhone} ${fullName}`
}
