import type { ItemCardModel } from "../../../../../components/ItemCard"
import ItemCard from "../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { SortingByMenuEnum } from "../../../../../shared/graphql/generated/types"
import { useBreadcrumb } from "../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../shared/routes/paths"
import { reorderArray } from "../../../../../shared/utils/helpers/reorderArray"
import {
  sortArrayAlphabetically,
  sortArrayByDate,
  sortingOptions,
} from "../../../../../shared/utils/helpers/sortArrayByMethod"
import AddButton from "../../../../../ui/AddButton"
import Container from "../../../../../ui/Container"
import DraggableList from "../../../../../ui/DraggableList"
import SectionHeader from "../../../../../ui/SectionHeader"
import { SearchItemsModal } from "../../../MenuItems/components/SearchItemsModal/SearchItemsModal"
import GenericSortBy from "../../../components/GenericSortBy"
import type {
  IMenuForm,
  IMenuItemForm,
} from "../../MenuForm/hookforms.interfaces"
import CardVisibility from "../../components/CardVisibility"
import { useCallback, useMemo, useState } from "react"
import { DropResult } from "react-beautiful-dnd"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export const ItemList = () => {
  const intl = useIntl()
  const { watch, getValues, control, setValue } = useFormContext<IMenuForm>()
  const {
    fields: itemList,
    replace: replaceItems,
    remove: removeItem,
  } = useFieldArray({ control, name: "items" })

  const { pushBreadcrumb } = useBreadcrumb()

  const {
    restaurants: {
      editProduct,
      locations: { editItemByLocation },
    },
  } = paths

  const isMain = watch("isMain")
  const sortItemsBy = watch("sortItemsBy")
  const disableCustomSorting = sortItemsBy !== SortingByMenuEnum.CUSTOM

  const [displayItemSearch, setDisplayItemSearch] = useState<
    boolean | undefined
  >()

  const showItemSearch = () => setDisplayItemSearch(true)
  const dismissItemSearch = useCallback(
    () => setDisplayItemSearch(false),
    [setDisplayItemSearch]
  )

  const {
    state: {
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  const onClickItem = useCallback(
    (itemUUID: string) => {
      const name = getValues("name")

      if (isMain) {
        pushBreadcrumb(editProduct(itemUUID), {
          currentPageName: name,
        })
      } else {
        pushBreadcrumb(editItemByLocation(locationUUID, itemUUID), {
          currentPageName: name,
        })
      }
    },
    [
      editItemByLocation,
      editProduct,
      getValues,
      isMain,
      locationUUID,
      pushBreadcrumb,
    ]
  )

  const onItemsUpdated = (selectedItems: ItemCardModel[]) => {
    selectedItems.length > 0 &&
      replaceItems(selectedItems as Array<IMenuItemForm>)

    setDisplayItemSearch(false)
  }

  const handleDeleteItem = (uuid: string) => {
    const index = itemList.findIndex((item) => item.uuid === uuid)

    removeItem(index)
  }

  const onChangeSorting = (selectedValue: SortingByMenuEnum) => {
    setValue("sortItemsBy", selectedValue, { shouldDirty: true })
  }

  const items = useMemo(() => {
    if (!disableCustomSorting) return itemList

    const sortByName =
      sortItemsBy === SortingByMenuEnum.NAME_ASC ||
      sortItemsBy === SortingByMenuEnum.NAME_DESC

    return sortByName
      ? sortArrayAlphabetically({
          array: itemList,
          key: "name",
          ascending: sortItemsBy === SortingByMenuEnum.NAME_ASC,
        })
      : sortArrayByDate({
          array: itemList,
          key: "createdAt",
          ascending: sortItemsBy === SortingByMenuEnum.DATE_ASC,
        })
  }, [disableCustomSorting, itemList, sortItemsBy])

  const onDragEndHandler = (dropResult: DropResult) => {
    const reorderedItems = reorderArray(
      items,
      dropResult.source.index,
      dropResult.destination?.index ?? 0
    )

    if (!reorderedItems) return

    setValue("items", reorderedItems, { shouldDirty: true })
  }

  return (
    <>
      <SectionHeader
        title={intl.formatMessage({
          id: "restaurants.menu.form.items.label",
          defaultMessage: "Items",
        })}
        description={intl.formatMessage({
          id: "restaurants.menu.form.items.description",
          defaultMessage: "Search an item and add it to your category.",
        })}
        actionButton={
          <Container display="flex" alignItems="center" gap="10px">
            {items.length > 0 && (
              <GenericSortBy
                onChangeSorting={onChangeSorting}
                sortingOptions={sortingOptions}
                value={SortingByMenuEnum[sortItemsBy]}
              />
            )}
            <AddButton data-testid="add-item-button" onClick={showItemSearch} />
          </Container>
        }
      />
      <DraggableList
        droppableId="items"
        onDragEnd={onDragEndHandler}
        data={[...items]}
        isDragDisabled={disableCustomSorting}
        renderContent={(item) => (
          <ItemCard
            enableDnDMenu={!disableCustomSorting}
            key={item.uuid}
            src={item.attachment?.signedUrl}
            title={item.name}
            onClick={() => onClickItem(item.uuid)}
            onDeleteCard={() => handleDeleteItem(item.uuid)}
            imageFit="contain"
            allowDelete
          >
            <CardVisibility
              isSoldOut={!!item.isSoldOut}
              isVisible={item.isVisible ?? true}
            />
          </ItemCard>
        )}
      />
      {displayItemSearch && (
        <SearchItemsModal
          visible={displayItemSearch}
          onCancel={dismissItemSearch}
          selectedItems={items}
          onSave={onItemsUpdated}
          isCorporate={isMain}
        />
      )}
    </>
  )
}
