import type {
  RPublishComboInput,
  RSaveComboInput,
  RSaveCorporateComboInput,
} from "../../../../../shared/graphql/generated/types"
import { putFileWithSignedUrl } from "../../../../../shared/utils/api/client"
import getImageFilename from "../../../../../shared/utils/helpers/getImageFilename"
import getImageMetadata from "../../../../../shared/utils/helpers/getImageMetadata"
import notification from "../../../../../ui/notification"
import type { CreateTempComboAttachmentMutation } from "../GraphQL/createTempComboAttachment.generated"
import { useCreateTempComboAttachmentMutation } from "../GraphQL/createTempComboAttachment.generated"
import type { DeleteComboMutation } from "../GraphQL/deleteCombo.generated"
import { useDeleteComboMutation } from "../GraphQL/deleteCombo.generated"
import type { PublishComboMutation } from "../GraphQL/publishCombo.generated"
import { usePublishComboMutation } from "../GraphQL/publishCombo.generated"
import type { PublishDraftComboMutation } from "../GraphQL/publishDraftCombo.generated"
import { usePublishDraftComboMutation } from "../GraphQL/publishDraftCombo.generated"
import type { SaveComboMutation } from "../GraphQL/saveCombo.generated"
import { useSaveComboMutation } from "../GraphQL/saveCombo.generated"
import type { SaveCorporateComboByLocationMutation } from "../GraphQL/saveCorporateComboByLocation.generated"
import { useSaveCorporateComboByLocationMutation } from "../GraphQL/saveCorporateComboByLocation.generated"
import type { UnpublishComboMutation } from "../GraphQL/unPublishCombo.generated"
import { useUnpublishComboMutation } from "../GraphQL/unPublishCombo.generated"
import {
  deleteComboFromCache,
  saveComboInCache,
} from "../utils/cache-combos/combos-cache-config"
import { useIntl } from "react-intl"

type UseComboProps = {
  search?: string
}

export const useCombos = ({ search }: UseComboProps) => {
  const intl = useIntl()

  const [saveComboMutation, comboSaved] = useSaveComboMutation({
    onCompleted: (result) => {
      const { name, isPublished, hasSnapshot, uuid, isVisible, isMain } =
        result.saveCombo

      saveComboInCache({
        data: {
          uuid,
          name,
          hasSnapshot,
          isMain,
          isPublished,
          isVisible: isVisible ?? true,
        },
        variables: {},
      })
    },
  })

  const saveCombo = async (
    input: RSaveComboInput,
    onSuccess?: (result: SaveComboMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await saveComboMutation({
        variables: { input },
      })

      if (data?.saveCombo.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [saveCorporateComboByLocationMutation, corporateComboSaved] =
    useSaveCorporateComboByLocationMutation({
      onCompleted: (result) => {
        saveComboInCache({
          data: {
            uuid: result.saveCorporateComboByLocation.uuid,
            name: result.saveCorporateComboByLocation.name,
            isVisible: result.saveCorporateComboByLocation.isVisible,
            isPublished: result.saveCorporateComboByLocation.isPublished,
          },
          variables: { name: search },
        })
      },
    })

  const saveCorporateCombo = async (
    input: RSaveCorporateComboInput,
    onSuccess?: (result: SaveCorporateComboByLocationMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await saveCorporateComboByLocationMutation({
        variables: { input },
      })

      if (data?.saveCorporateComboByLocation.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishDraftComboMutation, draftComboPublished] =
    usePublishDraftComboMutation({
      onCompleted: (result) => {
        const combo = result.publishDraftCombo

        saveComboInCache({
          data: {
            uuid: combo.uuid,
            name: combo.name,
            isPublished: true,
            hasSnapshot: false,
          },
          variables: { name: search },
        })
      },
    })

  const publishDraftCombo = async (
    uuid: string,
    onSuccess?: (result: PublishDraftComboMutation) => void,
    onError?: (result: Error) => void
  ) => {
    try {
      const { data } = await publishDraftComboMutation({
        variables: {
          uuid,
        },
      })

      if (data?.publishDraftCombo.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishComboMutation, comboPublished] = usePublishComboMutation({
    onCompleted: (result) => {
      const combo = result.publishCombo

      saveComboInCache({
        data: {
          uuid: combo.uuid,
          name: combo.name,
          isPublished: true,
          hasSnapshot: false,
        },
        variables: { name: search },
      })
    },
  })

  const publishCombo = async (
    input: RPublishComboInput,
    onSuccess?: (result: PublishComboMutation) => void,
    onError?: (result: Error) => void
  ) => {
    try {
      const { data } = await publishComboMutation({
        variables: { input },
      })

      if (data?.publishCombo.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [unpublishComboMutation, comboUnpublished] = useUnpublishComboMutation({
    onCompleted: (result) => {
      const combo = result.unpublishCombo

      saveComboInCache({
        data: {
          name: combo.name,
          uuid: combo.uuid,
          isPublished: false,
          hasSnapshot: true,
        },
        variables: { name: search },
      })
    },
  })

  const unpublishCombo = async (
    uuid: string,
    onSuccess?: (result: UnpublishComboMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await unpublishComboMutation({
        variables: { uuid },
      })

      if (data?.unpublishCombo.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [deleteComboMutation, comboDeleted] = useDeleteComboMutation({
    onCompleted: (result) => {
      deleteComboFromCache({
        uuid: result.deleteCombo.uuid,
        variables: { name: search },
      })
    },
  })

  const deleteCombo = async (
    uuid: string,
    onSuccess?: (result: DeleteComboMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await deleteComboMutation({
        variables: { uuid },
      })

      if (data?.deleteCombo.uuid) {
        notification({
          title: intl.formatMessage({
            id: "restaurants.menu.items.combos.use.combos.delete.combo.success.title",
            defaultMessage: "Combo deleted",
          }),
          description: intl.formatMessage({
            id: "restaurants.menu.items.combos.use.combos.delete.combo.success.description",
            defaultMessage: "Your combo was deleted",
          }),
          type: "success",
        })
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [createTempComboAttachmentMutation] =
    useCreateTempComboAttachmentMutation()

  const createComboAttachment = async (
    comboUUID: string,
    file: File,
    onOk?: (result: CreateTempComboAttachmentMutation) => void,
    onError?: (error: Error) => void
  ) => {
    const fileToUpload = file

    if (fileToUpload) {
      const image = {
        ...getImageMetadata(fileToUpload.type),
        fileName: getImageFilename(fileToUpload.name),
      }

      try {
        const { data } = await createTempComboAttachmentMutation({
          variables: {
            input: {
              fileName: image.fileName,
              contentType: image.contentType,
              ext: image.ext,
            },
          },
        })

        if (data?.createTempComboAttachment.uuid) {
          await putFileWithSignedUrl(
            data.createTempComboAttachment.signedUrl,
            fileToUpload
          )
          onOk?.(data)
        }
      } catch (error) {
        notification({
          description: intl.formatMessage({
            id: "generic.error.image.message",
            defaultMessage: "Your image was unable to save.",
          }),
          type: "error",
        })
        onError?.(error as Error)
      }
    }
  }

  return {
    saveCombo,
    comboSaved,
    saveCorporateCombo,
    corporateComboSaved,
    publishDraftCombo,
    draftComboPublished,
    publishCombo,
    comboPublished,
    unpublishCombo,
    comboUnpublished,
    deleteCombo,
    comboDeleted,
    createComboAttachment,
  }
}
