import { Group, Skeleton, Stack } from "@mantine/core"

export const ReportsTableSkeleton = () => {
  return (
    <Stack w="100%" spacing={0}>
      <Skeleton h={32} w="100%" />

      {Array.from({ length: 3 }).map((_, index) => (
        <Group key={index} position="apart" noWrap>
          <Skeleton h={16} mt={16} mb={16} w="25%" />
          <Group spacing={32}>
            <Skeleton h={24} w={100} />
            <Skeleton h={24} w={100} />
            <Skeleton h={24} w={100} />
          </Group>
        </Group>
      ))}
    </Stack>
  )
}
