import { formatQuantity } from "../../utils/format-quantity.util"
import { Group, Text, useMantineTheme } from "@mantine/core"
import {
  IconArrowDownRight,
  IconArrowNarrowLeft,
  IconArrowUpRight,
} from "@tabler/icons-react"
import { useIntl } from "react-intl"

export type NumberType = "money" | "quantity" | "percentage" | "hours"

type ComparissonProps = {
  value: number
  valueType: NumberType
  isPercentage?: boolean
  size?: "sm" | "md"
  type?: "normal" | "satisfaction"
}

export const Comparisson = (props: ComparissonProps) => {
  const { value, size = "sm", type = "normal", valueType } = props
  const { colors } = useMantineTheme()
  const intl = useIntl()

  const config = {
    positive: {
      prefix: "+",
      icon: type === "satisfaction" ? IconArrowDownRight : IconArrowUpRight,
      color: type === "satisfaction" ? "red" : "green",
      hue: 7,
    },
    negative: {
      prefix: "-",
      icon: type === "satisfaction" ? IconArrowUpRight : IconArrowDownRight,
      color: type === "satisfaction" ? "green" : "red",
      hue: 8,
    },
    neutral: { prefix: "", icon: IconArrowNarrowLeft, color: "gray", hue: 7 },
  }

  const formattedValue = formatQuantity(Math.abs(value), 1)

  let label = formattedValue

  if (valueType === "money") {
    label = `$${formattedValue}`
  }
  if (valueType === "percentage") {
    label = `${formattedValue}%`
  }
  if (valueType === "hours") {
    label = intl.formatMessage(
      {
        id: "restaurants.reports.comparisson.hours.label",
        defaultMessage: "{value} hrs",
      },
      { value: formattedValue }
    )
  }

  const polarity = value > 0 ? "positive" : value < 0 ? "negative" : "neutral"

  const prefixSymbol = config[polarity].prefix
  const Icon = config[polarity].icon
  const color = colors[config[polarity].color][config[polarity].hue]

  return (
    <Group spacing={2} noWrap>
      <Text size={size} color={color}>{`${prefixSymbol}${label}`}</Text>
      <Icon size={16} color={color} />
    </Group>
  )
}
