import { Container } from "../../../../../../ui/Container/Container"
import styled, { css } from "styled-components"

interface StyledRowContainerProps {
  id: string
}

export const StyledRowContainer = styled(Container)<StyledRowContainerProps>`
  ${({ id }) => css`
    &.${id} {
      .remove-row-button {
        transition: 0.4s all;
        visibility: hidden;
        opacity: 0;
        &:focus-visible {
          visibility: hidden;
          opacity: 0;
          transition: 0.5s al;
        }
      }

      &:hover {
        .remove-row-button {
          opacity: 1;
          visibility: visible;
        }
        &:focus-visible {
          opacity: 1;
          visibility: visible;
        }
      }

      .modifier-item {
        flex-grow: 1;
      }
    }
  `}
`
