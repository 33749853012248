import defaultImage from "../../shared/assets/images/ingredient-placeholder.svg"
import Checkbox from "../../ui/Checkbox"
import Container from "../../ui/Container"
import Divider from "../../ui/Divider"
import Icon from "../../ui/Icon"
import ImageIcon from "../../ui/ImageIcon"
import type { TagProps } from "../../ui/Tag"
import Tag from "../../ui/Tag"
import Text from "../../ui/Typography/Text"
import type { CardProps } from "antd"
import { Card, Tooltip } from "antd"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import classnames from "classnames"
import type CSS from "csstype"
import React from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export type CardTagProps = TagProps & {
  uuid?: string
  title: string
  remixiconClass?: string
}

export type ItemCardModel = {
  uuid: string
  name: string
  src?: string
  price?: number
  isPublished?: boolean
  isVisible?: boolean | null
  isSoldOut?: boolean | null
  attachment?: { signedUrl: string } | null
  createdAt?: string
}

export type MenuItemCardProps = CardProps & {
  description?: string
  title: string
  subtitle?: string
  src?: string
  price?: number
  isPublished?: boolean
  tagsToDisplay?: number
  tags?: CardTagProps[]
  tagOwnership?: React.ReactNode
  onCheckCard?: (
    event: CheckboxChangeEvent | React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: ItemCardModel
  ) => void
  checked?: boolean
  checkable?: boolean
  allowDelete?: boolean
  disabledDeleteButton?: boolean
  imageFit?: CSS.Property.ObjectFit
  onDeleteCard?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  toolTipText?: string
  displayHoverExtra?: boolean
  hoverExtra?: React.ReactNode
  enableDnDMenu?: boolean
  textTitle?: React.ReactNode
  attachment?: { signedUrl: string }
  createdAt?: string
}

export const ItemCard: React.FC<MenuItemCardProps> = ({
  id,
  title,
  subtitle,
  tags,
  checked,
  checkable,
  onCheckCard,
  onClick,
  allowDelete,
  disabledDeleteButton,
  onDeleteCard,
  imageFit,
  isPublished,
  toolTipText,
  hoverExtra,
  children,
  price = 0,
  src = defaultImage,
  tagsToDisplay = 3,
  displayHoverExtra = false,
  enableDnDMenu = false,
  tagOwnership,
  textTitle,
  attachment,
  createdAt,
  ...props
}) => {
  const intl = useIntl()

  const getDisplayTags = () => {
    if (!tags?.length) {
      return []
    }

    if (tags.length <= tagsToDisplay) {
      return tags
    }

    const tooltipText = tags
      .slice(3, tags.length)
      .map(({ title: tagTitle }) => tagTitle)
      .join(" , ")

    return [
      ...tags.slice(0, tagsToDisplay),
      {
        title: intl.formatMessage(
          {
            id: "components.card.item.more.label",
            defaultMessage: "+ {count}",
          },
          { count: tags.length - tagsToDisplay }
        ),
        tooltipText,
      },
    ]
  }

  const onCheckChange = (event: CheckboxChangeEvent) => {
    if (id && onCheckCard) {
      onCheckCard(event, {
        uuid: id,
        name: title,
        src,
        price,
        isPublished,
        attachment,
        createdAt,
      })
    }
  }

  const onCardClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (checkable && id && onCheckCard) {
      onCheckCard(event, {
        uuid: id,
        name: title,
        src,
        isPublished,
        attachment,
        createdAt,
      })
    }
    onClick && onClick(event)
  }

  const onDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onDeleteCard?.(event)
  }

  const stopPropagation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => event.stopPropagation()

  return (
    <StyledItemCard {...props} $imageFit={imageFit} onClick={onCardClick}>
      <StyledMenuFill className={classnames({ "menu-fill": enableDnDMenu })}>
        <Icon
          remixiconClass={classnames("ri-menu-fill")}
          aria-label={`item-card-menu-fill-${id}`}
          color="Primary5"
          size={24}
        />
      </StyledMenuFill>
      <div className="card" role="card">
        <StyledCardBody>
          {checkable && (
            <Checkbox
              classId="card__checkbox"
              onChange={onCheckChange}
              checked={checked}
            />
          )}
          <ImageIcon
            data-testid="add-item-checkbox"
            className="card__icon"
            size={32}
            src={src || defaultImage}
            shape="square"
          />
          <StyledCardContent $tagsLength={tags?.length}>
            <div
              className={classnames("card__content__title", {
                ["card__content__title__no-description"]: !props.description,
              })}
            >
              {subtitle && (
                <Container display="flex">
                  <Text className="card__content__subtitle" size="m" ellipsis>
                    {subtitle}
                  </Text>
                  <Divider
                    type="vertical"
                    alignSelf="center"
                    verticalMargin="16"
                  />
                </Container>
              )}
              {textTitle ? (
                textTitle
              ) : (
                <Text
                  className="card__content__title__item"
                  size="m"
                  weight="bold"
                  title={title}
                  ellipsis
                >
                  {title}
                </Text>
              )}
            </div>
            <div className="card__content__title__container">
              {!!props.description && (
                <Text size="s" color="Neutral5" title={props.description}>
                  {props.description}
                </Text>
              )}
            </div>
          </StyledCardContent>
          {children}
          <Container display="flex" alignItems="center" gap="8px">
            {!!tags?.length &&
              getDisplayTags().map(
                ({ title: tagTitle, remixiconClass, ...tagProps }, index) =>
                  (!!tagTitle && (
                    <StyledCardTagWrapper key={index}>
                      <Tag {...tagProps} icon={remixiconClass} withTooltip>
                        {tagTitle}
                      </Tag>
                    </StyledCardTagWrapper>
                  )) ||
                  null
              )}
            {tagOwnership ?? null}
          </Container>
        </StyledCardBody>
      </div>
      {allowDelete && !disabledDeleteButton && (
        <StyledDeleteCard value={id} onClick={onDelete}>
          <Icon
            remixiconClass={classnames("ri-close-line")}
            aria-label={`item-card-remove-icon-${id}`}
            size={18}
            color="transparent"
            cursor="pointer"
          />
        </StyledDeleteCard>
      )}
      {disabledDeleteButton && (
        <StyledDeleteCard value={id}>
          <Tooltip title={toolTipText}>
            <Icon
              remixiconClass="ri-close-line"
              size={16}
              color="transparent"
              cursor="not-allowed"
            />
          </Tooltip>
        </StyledDeleteCard>
      )}
      {hoverExtra && (
        <StyledHoverExtra
          value={id}
          className="hover-extra"
          displayHoverExtra={displayHoverExtra}
          onClick={stopPropagation}
        >
          {hoverExtra}
        </StyledHoverExtra>
      )}
    </StyledItemCard>
  )
}

const StyledItemCard = styled(Card)<{ $imageFit?: CSS.Property.ObjectFit }>`
  padding: 8px 16px;
  margin-bottom: 8px;

  .card {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    .card__icon {
      margin-right: 8px;
      flex-shrink: 0;

      img {
        ${({ $imageFit }) => css`
          object-fit: ${$imageFit ?? "cover"};
        `}
      }
    }

    .card__checkbox {
      height: 100%;
      margin-right: 8px;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-typography {
    height: 20px;
  }

  .hover-children {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    background: ${({ theme }) => theme.colors["Neutral1"]};
  }

  &:hover {
    .ri-close-line {
      ${({ theme }) =>
        css`
          color: ${theme.colors["Primary4"]};
        `}
    }

    .hover-children {
      display: block;
    }

    .hover-extra {
      opacity: 1;
    }

    .menu-fill {
      cursor: move;
      opacity: 1;
    }
  }
`

const StyledCardBody = styled.div`
  display: flex;
  overflow: initial;
  flex-shrink: 1;
  width: 100%;
  align-items: center;
  gap: 8px;
`

const StyledCardContent = styled.div<{ $tagsLength?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  gap: 0 8px;

  .card__content__title {
    display: flex;
    .card__content__title__item {
      ${({ $tagsLength }) => css`
        ${$tagsLength ? "white-space: nowrap;" : ""};
      `}
    }

    .card__content__subtitle {
      overflow: hidden;
    }

    > div {
      overflow: hidden;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.card__content__title__no-description {
      .card__content__title__item {
        line-height: 2.6;
        vertical-align: middle;
        height: 100%;
      }
      .card__content__subtitle {
        line-height: 2.6;
        vertical-align: middle;
        height: 100%;
      }
    }
  }

  .card__content__title__container {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const StyledCardTagWrapper = styled.div`
  transition: all 0.5s ease-in;
  display: flex;
  flex-shrink: 10;
  margin-left: 0;
  align-items: center;

  .ant-tag {
    transition: all 0.3s ease-in;
    margin-right: 0;
    margin-left: 0;
  }

  :nth-of-type(2) {
    margin-left: auto;
  }

  :last-of-type {
    flex-shrink: 3;

    .ant-tag {
      margin-right: 0;
    }
  }
`

const StyledDeleteCard = styled.button`
  ${({ theme }) =>
    css`
      padding: 0;
      background: ${theme.colors.transparent};
      border: none;
      position: absolute;
      top: 0;
      right: -34px;
      height: 56px;
    `}
`

const StyledHoverExtra = styled.button<{ displayHoverExtra?: boolean }>`
  ${({ theme, displayHoverExtra }) =>
    css`
      background: ${theme.colors.transparent};
      opacity: ${displayHoverExtra ? "1" : "0"};
      border: none;
      position: absolute;
      top: 0;
      right: -30px;
      height: 56px;
    `}
`

const StyledMenuFill = styled.div`
  ${({ theme }) =>
    css`
      background: ${theme.colors.transparent};
      opacity: 0;
      border: none;
      position: absolute;
      top: 16px;
      left: -36px;
      height: 18px;
    `}
`
