import type {
  RModifierDetail,
  RModifierGroupDetail,
  RSaveCorporateModifierGroupInput,
} from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import { ModalFull } from "../../../../ui/ModalFull/ModalFull"
import CorporateDetailHeader from "../../components/CorporateDetailHeader/CorporateDetailHeader"
import useModifierGroups from "../hooks/useModifierGroups"
import ModifierDetail from "./components/ModifierDetail"
import isEqual from "lodash/isEqual"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

interface Props {
  modifierGroup: RModifierGroupDetail
  onSuccess?(): void
}

const CorporateModifierDetail = ({ modifierGroup, onSuccess }: Props) => {
  const { push } = useHistory()
  const { locationUUID } = useParams<{ locationUUID: string }>()
  const [isDirty, setIsDirty] = useState(false)
  const [initialModifiers, setInitialModifiers] = useState(
    () => modifierGroup.modifiers
  )
  const [modifiers, setModifiers] = useState(() => modifierGroup.modifiers)

  const lastSavedAt = modifierGroup.snapshotUpdatedAt || modifierGroup.updatedAt

  const { saveCorporateModifierGroup, corporateModifierGroupSaved } =
    useModifierGroups({})

  const updateIngredients = (modifier: RModifierDetail) => {
    setModifiers((currentModifiers) => {
      const currentModifiersCopy = [...currentModifiers]
      const modifiedIngredientIndex = currentModifiersCopy.findIndex(
        (obj) => obj.uuid === modifier.uuid
      )
      currentModifiersCopy[modifiedIngredientIndex] = modifier

      return currentModifiersCopy
    })
  }

  const onSaveSuccess = () => {
    setInitialModifiers(modifiers)
    setIsDirty(false)
    push(paths.restaurants.locations.modifiersByLocation(locationUUID))
    onSuccess?.()
  }

  const onSave = () => {
    const data: RSaveCorporateModifierGroupInput = {
      uuid: modifierGroup.uuid,
      modifiers: modifiers.map((modifier) => {
        return {
          uuid: modifier.uuid,
          isVisible: modifier.isVisible ?? true,
          isSoldOut: modifier.isSoldOut ?? false,
        }
      }),
    }

    saveCorporateModifierGroup(data, onSaveSuccess, showGraphqlErrors)
  }

  useEffect(() => {
    setIsDirty(!isEqual(modifiers, initialModifiers))
  }, [modifiers, initialModifiers])

  return (
    <ModalFull
      headerColor="Neutral2"
      title={
        <CorporateDetailHeader
          loadingSave={corporateModifierGroupSaved.loading}
          enablePreview={true}
          enableSave={isDirty}
          lastSavedAt={lastSavedAt}
          lastPublishedAt={modifierGroup.publishedAt}
          onSave={onSave}
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.CorporateMenuDetail}
            pageName={modifierGroup.name}
          />
        </CorporateDetailHeader>
      }
      visible
    >
      <ModifierDetail
        modifierGroup={modifierGroup}
        modifiers={modifiers}
        updateIngredients={updateIngredients}
        isDirty={isDirty}
      />
    </ModalFull>
  )
}

export default CorporateModifierDetail
