import type {
  GetModifierGroupListQuery,
  GetModifierGroupListQueryVariables,
} from "../../GraphQL/getModiferGroupList.generated"
import { GetModifierGroupListDocument } from "../../GraphQL/getModiferGroupList.generated"
import type { ApolloCache } from "@apollo/client"

type ReadCacheGetModifierGroupListQuery = {
  cache: ApolloCache<unknown>
  variables: GetModifierGroupListQueryVariables
}

const readGetModifierGroupListQueryCache = ({
  cache,
  variables,
}: ReadCacheGetModifierGroupListQuery) =>
  cache.readQuery<
    GetModifierGroupListQuery,
    GetModifierGroupListQueryVariables
  >({
    query: GetModifierGroupListDocument,
    variables,
  })

export default readGetModifierGroupListQueryCache
