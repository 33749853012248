import type {
  GetMenuListQuery,
  GetMenuListQueryVariables,
} from "../../../../../GraphQL/Queries/getMenuList.generated"
import { GetMenuListDocument } from "../../../../../GraphQL/Queries/getMenuList.generated"
import type { ApolloCache } from "@apollo/client"

type WriteCacheGetMenuListQuery = {
  cache: ApolloCache<unknown>
  query: GetMenuListQuery
  variables: GetMenuListQueryVariables
}

const writeGetMenuListQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheGetMenuListQuery) =>
  cache.writeQuery<GetMenuListQuery, GetMenuListQueryVariables>({
    query: GetMenuListDocument,
    data: query,
    variables,
  })

export default writeGetMenuListQueryCache
