import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptCateringOrderMutationVariables = Types.Exact<{
  order: Types.RuuidInput;
}>;


export type AcceptCateringOrderMutation = { __typename?: 'Mutation', acceptCateringOrder: { __typename?: 'AcceptedCateringOrder', uuid: string, status: Types.OrderStatusEnum, paymentStatus: Types.OrderPaymentStatusEnum, paymentMethodType?: Types.PaymentMethodTypeEnum | null } };


export const AcceptCateringOrderDocument = gql`
    mutation acceptCateringOrder($order: RUUIDInput!) {
  acceptCateringOrder(order: $order) {
    uuid
    status
    paymentStatus
    paymentMethodType
  }
}
    `;
export type AcceptCateringOrderMutationFn = Apollo.MutationFunction<AcceptCateringOrderMutation, AcceptCateringOrderMutationVariables>;

/**
 * __useAcceptCateringOrderMutation__
 *
 * To run a mutation, you first call `useAcceptCateringOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCateringOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCateringOrderMutation, { data, loading, error }] = useAcceptCateringOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcceptCateringOrderMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCateringOrderMutation, AcceptCateringOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCateringOrderMutation, AcceptCateringOrderMutationVariables>(AcceptCateringOrderDocument, options);
      }
export type AcceptCateringOrderMutationHookResult = ReturnType<typeof useAcceptCateringOrderMutation>;
export type AcceptCateringOrderMutationResult = Apollo.MutationResult<AcceptCateringOrderMutation>;
export type AcceptCateringOrderMutationOptions = Apollo.BaseMutationOptions<AcceptCateringOrderMutation, AcceptCateringOrderMutationVariables>;