import notification from "../../../../../../ui/notification"
import { useAcceptCateringOrderMutation } from "../../../Graphql/acceptCateringOrder.generated"
import { acceptCateringOrderCacheUpdate } from "../../../utils/cache/order-catering-cache.util"
import { Button, Flex, Modal, Stack, Text } from "@mantine/core"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

interface AcceptOrderModalProps {
  opened: boolean
  onClose: () => void
}

export const AcceptOrderModal = ({
  opened,
  onClose,
}: AcceptOrderModalProps) => {
  const intl = useIntl()

  const { orderUUID } = useParams<{ orderUUID: string }>()

  const [acceptOrderCatering, { loading }] = useAcceptCateringOrderMutation()

  const handleAcceptOrder = async () => {
    try {
      await acceptOrderCatering({
        variables: {
          order: {
            uuid: orderUUID,
          },
        },
        update: (cache, result) => {
          acceptCateringOrderCacheUpdate(cache, result, {})
        },
        onCompleted: () => onClose(),
      })
    } catch {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.catering.detail.accept.order.modal.error.message",
          defaultMessage:
            "Something went wrong. Please try again. If the problem persists, contact support.",
        }),
      })
    }
  }

  return (
    <Modal
      centered
      onClose={onClose}
      opened={opened}
      title={intl.formatMessage({
        id: "restaurants.catering.detail.accept.order.modal.title",
        defaultMessage: "Accept order",
      })}
    >
      <Stack spacing={8}>
        <Text>
          {intl.formatMessage({
            id: "restaurants.catering.detail.accept.order.modal.description",
            defaultMessage:
              "By accepting, your location will be responsible for fulfilling this order",
          })}
        </Text>
        <Flex gap={8} justify="flex-end">
          <Button variant="outline" onClick={onClose} color="gray.6">
            {intl.formatMessage({
              id: "restaurants.catering.detail.accept.order.modal.cancel.button",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button onClick={handleAcceptOrder} loading={loading}>
            {intl.formatMessage({
              id: "restaurants.catering.detail.accept.order.modal.accept.button",
              defaultMessage: "Accept",
            })}
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
