import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import type { CustomerWithLocationModel } from "../../../shared/graphql/generated/types"
import paths from "../../../shared/routes/paths"
import Container from "../../../ui/Container"
import EmptyState from "../../../ui/EmptyState"
import ContainerFixed from "../../../ui/Layouts/MainLayout/ContainerFixed"
import Table from "../../../ui/Table"
import BodyCell from "../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../ui/Table/EmptyStateCell"
import type { ColumProps } from "../../../ui/Table/Table"
import type { GetAllCustomersByRestaurantQuery } from "./graphql/getAllCustomersByRestaurant.generated"
import { useGetAllCustomersByRestaurantQuery } from "./graphql/getAllCustomersByRestaurant.generated"
import type { ICustomersParams } from "./hookforms.interfaces"
import type { ICustomersTableModel } from "./interfaces/customers.interfaces"
import get from "lodash/get"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

export const GET_CUSTOMERS_CRUD_TAKE = 20

export const Customers: React.FC = () => {
  const intl = useIntl()
  const { push } = useHistory()
  const { locationUUID } = useParams<ICustomersParams>()

  const columns: Array<ColumProps> = [
    {
      title: intl.formatMessage({
        id: "restaurants.customers.datatable.header.name",
        defaultMessage: "Customer Name",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.customers.datatable.header.phone",
        defaultMessage: "Phone #",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.customers.datatable.header.email",
        defaultMessage: "Email",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.customers.datatable.header.locations",
        defaultMessage: "Locations",
      }),
    },
  ]

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { data, loading, error, fetchMore, refetch } =
    useGetAllCustomersByRestaurantQuery({
      variables: {
        restaurantUUID,
        locationUUID,
      },
      notifyOnNetworkStatusChange: true,
      returnPartialData: true,
      skip: !restaurantUUID,
    })

  const hasNextPage: boolean = get(
    data,
    "getAllCustomersByRestaurant.hasNextPage",
    false
  )
  const customersList: ICustomersTableModel[] = get(
    data,
    "getAllCustomersByRestaurant.results",
    []
  )
  const endCursor = get(
    data,
    "getAllCustomersByRestaurant.endCursor",
    undefined
  )

  const fetchMoreCustomers = useCallback(async () => {
    await fetchMore({
      variables: {
        restaurantUUID,
        locationUUID,
        after: endCursor,
        take: GET_CUSTOMERS_CRUD_TAKE,
      },
      updateQuery: (
        prev: GetAllCustomersByRestaurantQuery,
        { fetchMoreResult }
      ) => {
        if (
          !fetchMoreResult ||
          prev?.getAllCustomersByRestaurant?.endCursor ===
            fetchMoreResult?.getAllCustomersByRestaurant?.endCursor
        ) {
          return prev
        }

        return {
          getAllCustomersByRestaurant: {
            ...fetchMoreResult.getAllCustomersByRestaurant,
            results: [
              ...(prev.getAllCustomersByRestaurant
                ?.results as Array<CustomerWithLocationModel>),
              ...(fetchMoreResult.getAllCustomersByRestaurant
                ?.results as Array<CustomerWithLocationModel>),
            ],
          },
        }
      },
    })
  }, [restaurantUUID, endCursor, locationUUID, fetchMore])

  const retry = () => refetch()

  const displayFormattedLocations = (locations: Array<{ name: string }>) => {
    return locations.map(({ name }, index) =>
      index === locations.length - 1 ? `${name} ` : `${name}, `
    )
  }

  return (
    <Container
      role="customers-table"
      className="customers-table"
      position="sticky"
    >
      <ContainerFixed
        headerProps={{
          title: intl.formatMessage({
            id: "restaurants.customers.header.title",
            defaultMessage: "Customers",
          }),
        }}
      >
        <Container margin="0 16px 0 24px">
          <Table
            top="48px"
            columns={columns}
            loading={loading}
            error={error}
            errorRefetch={retry}
            fetchMore={fetchMoreCustomers}
            hasNextPage={hasNextPage}
          >
            {!!customersList?.length &&
              customersList.map((record) => {
                const onRowClick = () => {
                  locationUUID
                    ? push(
                        paths.restaurants.locations.customerDetailByLocation(
                          locationUUID,
                          record.userUUID
                        )
                      )
                    : push(paths.restaurants.customerDetail(record.userUUID))
                }

                return (
                  <tr key={record.userUUID} onClick={onRowClick}>
                    <BodyCell>{`${record?.firstName || "-"} ${
                      record?.lastName || ""
                    }`}</BodyCell>
                    <BodyCell>{`${record?.formattedPhone || "-"}`}</BodyCell>
                    <BodyCell>{`${record?.email || "-"}`}</BodyCell>
                    <BodyCell>
                      {record.locations.length > 0
                        ? displayFormattedLocations(record.locations)
                        : null}
                    </BodyCell>
                  </tr>
                )
              })}
            {customersList.length === 0 && !loading && (
              <EmptyStateCell colSpan={columns.length}>
                <EmptyState
                  title={intl.formatMessage({
                    id: "restaurants.customers.empty.title",
                    defaultMessage: "No customers found",
                  })}
                  description={intl.formatMessage({
                    id: "restaurants.customers.empty.description",
                    defaultMessage: "You don't have any customers yet",
                  })}
                  fullScreen
                />
              </EmptyStateCell>
            )}
          </Table>
        </Container>
      </ContainerFixed>
    </Container>
  )
}
