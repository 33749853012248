import Container from "../../../../../ui/Container"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import { OrderAdditionalInfoSkeleton } from "../OrderDetail.skeleton"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type OrderAdditionalInfoProps = {
  loading?: boolean
}

export const OrderAdditionalInfo: React.FC<OrderAdditionalInfoProps> = ({
  loading,
}) => {
  const intl = useIntl()

  const { watch } = useFormContext<OrderDetailModalForm>()

  const notes = watch("orderDetail.notes")

  if (loading) {
    return <OrderAdditionalInfoSkeleton />
  }

  return (
    <Container role="additional-info-section">
      <StyledContentSection>
        <Text color="Neutral6" weight="bold" size="xl">
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.order.additional.info.title",
            defaultMessage: "Additional Info",
          })}
        </Text>
      </StyledContentSection>

      <Container>
        <Text color="Neutral6" size="m">
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.order.additional.info.client.notes",
            defaultMessage: "Client Notes",
          })}
        </Text>
        <Spacer size={8} />
        <Text size="s" weight="bold">
          {notes
            ? notes
            : intl.formatMessage({
                id: "restaurants.orders.order.detail.order.additional.info.empty.title",
                defaultMessage: "The client has no notes.",
              })}
        </Text>
      </Container>
    </Container>
  )
}

const StyledContentSection = styled.div`
  padding-bottom: 16px;
`
