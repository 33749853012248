import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SortMenuMutationVariables = Types.Exact<{
  data: Types.SortInput;
}>;


export type SortMenuMutation = { __typename?: 'Mutation', sortMenu: { __typename?: 'SortModel', uuid: string, index: number } };


export const SortMenuDocument = gql`
    mutation sortMenu($data: SortInput!) {
  sortMenu(data: $data) {
    uuid
    index
  }
}
    `;
export type SortMenuMutationFn = Apollo.MutationFunction<SortMenuMutation, SortMenuMutationVariables>;

/**
 * __useSortMenuMutation__
 *
 * To run a mutation, you first call `useSortMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortMenuMutation, { data, loading, error }] = useSortMenuMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSortMenuMutation(baseOptions?: Apollo.MutationHookOptions<SortMenuMutation, SortMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortMenuMutation, SortMenuMutationVariables>(SortMenuDocument, options);
      }
export type SortMenuMutationHookResult = ReturnType<typeof useSortMenuMutation>;
export type SortMenuMutationResult = Apollo.MutationResult<SortMenuMutation>;
export type SortMenuMutationOptions = Apollo.BaseMutationOptions<SortMenuMutation, SortMenuMutationVariables>;