import { DateQueryParam } from "../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../shared/hooks/useSearchParams"
import {
  FIRST_HOUR,
  FIRST_MILLISECOND,
  FIRST_MINUTE,
  FIRST_SECOND,
  LAST_HOUR,
  LAST_MINUTE,
  LAST_SECOND,
} from "../../../../shared/utils/constant/values"
import { useTimeEntriesByLocationEmployeeQuery } from "../Graphql/timeEntriesByLocationEmployee.generated"
import { StatisticsValues } from "../Statistics/Statistics"
import { parseEmployeesTimeEntry } from "../utils/parse-employees-time-entry.util"
import { IconCurrencyDollar } from "@tabler/icons-react"
import moment from "moment-timezone"
import { useMemo } from "react"

export const useTimeEntries = () => {
  const { getParam } = useSearchParams()
  const startDateParam = getParam(DateQueryParam.start) ?? ""
  const endDateParam = getParam(DateQueryParam.end) ?? ""

  // If is today, we set the end date to the current time since the time entries are not finished yet
  // and it affects the total cost, tips received and total duration.
  const endDate = useMemo(() => {
    const isToday = moment(endDateParam).isSame(moment(), "day")

    return isToday
      ? moment().set({ millisecond: FIRST_MILLISECOND }).toISOString(false)
      : moment(endDateParam)
          .set({ hour: LAST_HOUR, minute: LAST_MINUTE, second: LAST_SECOND })
          .toISOString(false)
  }, [endDateParam])

  const timeEntriesByLocationQuery = useTimeEntriesByLocationEmployeeQuery({
    variables: {
      startDate: moment(startDateParam)
        .set({
          hour: FIRST_HOUR,
          minute: FIRST_MINUTE,
          second: FIRST_SECOND,
          millisecond: FIRST_MILLISECOND,
        })
        .toISOString(false),
      endDate,
    },
    skip: !startDateParam || !endDateParam,
    fetchPolicy: "network-only",
  })
  const { loading } = timeEntriesByLocationQuery

  const { totalCost, tipsReceived, totalDuration, employeesWorked } =
    timeEntriesByLocationQuery.data?.timeEntriesByLocationEmployee
      .payrollReport ?? {}

  const noDistributedTips =
    timeEntriesByLocationQuery.data?.timeEntriesByLocationEmployee
      .notDistributedTips ?? 0
  const refundedTips =
    timeEntriesByLocationQuery.data?.timeEntriesByLocationEmployee.refundedInvoices.reduce(
      (acc, curr) => acc + curr.totalTip,
      0
    ) ?? 0

  const timeEntriesData =
    timeEntriesByLocationQuery.data?.timeEntriesByLocationEmployee
      .employeeTimeEntries

  const getTimeEntries = () => {
    if (!timeEntriesData) {
      return {}
    }

    return parseEmployeesTimeEntry(timeEntriesData)
  }

  const statistics: StatisticsValues[] = [
    {
      title: {
        id: "restaurant.time.entries.statistics.card.total.cost",
        message: "TOTAL COST FOR THE PERIOD",
      },
      icon: IconCurrencyDollar,
      type: "money",
      amount: totalCost ?? 0,
    },
    {
      title: {
        id: "restaurant.time.entries.statistics.card.total.tips",
        message: "TIPS RECEIVED",
      },
      icon: IconCurrencyDollar,
      type: "money",
      amount: tipsReceived ?? 0,
    },
    {
      title: {
        id: "restaurant.time.entries.statistics.card.total.duration",
        message: "TOTAL DURATION",
      },
      icon: IconCurrencyDollar,
      type: "hours",
      amount: totalDuration ?? 0,
    },
    {
      title: {
        id: "restaurant.time.entries.statistics.card.number.employees",
        message: "NUMBER OF EMPLOYEES WORKED",
      },
      icon: IconCurrencyDollar,
      type: "quantity",
      amount: employeesWorked ?? 0,
    },
    {
      title: {
        id: "restaurant.time.entries.statistics.card.no.distributed.tips",
        message: "NO  DISTRIBUTED TIPS",
      },
      icon: IconCurrencyDollar,
      type: "money",
      amount: noDistributedTips,
    },
    {
      title: {
        id: "restaurant.time.entries.statistics.card.refunded.tips",
        message: "REFUNDED TIPS",
      },
      icon: IconCurrencyDollar,
      type: "money",
      amount: refundedTips,
    },
  ]

  const dateRange = [
    startDateParam &&
      moment(startDateParam)
        .set({ hour: FIRST_HOUR, minute: FIRST_MINUTE, second: FIRST_SECOND })
        .local(true)
        .format("MM/DD/YYYY"),

    endDateParam &&
      moment(endDateParam)
        .set({ hour: FIRST_HOUR, minute: FIRST_MINUTE, second: FIRST_SECOND })
        .local(true)
        .format("MM/DD/YYYY"),
  ].join(" - ")

  return {
    getTimeEntries,
    loading,
    entries:
      timeEntriesByLocationQuery.data?.timeEntriesByLocationEmployee
        .employeeTimeEntries ?? [],
    statistics,
    dateRange,
  }
}
