import { GetUsersQuery } from "../../../pages/Restaurants/Discounts/forms/Rules/EligibleCustomer/graphql/getUsers.generated"

interface Options {
  fetchMoreResult: GetUsersQuery
  variables: {
    after: string | null
    take: number
  }
}
export const updateGetUsersQuery = (
  prev: GetUsersQuery,
  options: Options
): GetUsersQuery => {
  if (
    !prev.getUsers.results ||
    !options.fetchMoreResult ||
    !options.fetchMoreResult.getUsers.results ||
    prev?.getUsers?.endCursor === options.fetchMoreResult?.getUsers?.endCursor
  )
    return prev

  return {
    getUsers: {
      ...options.fetchMoreResult.getUsers,
      results: [
        ...prev.getUsers.results,
        ...options.fetchMoreResult.getUsers.results,
      ],
    },
  }
}
