import type { GetComboListQuery } from "../../../../GraphQL/Queries/getComboList.generated"
import { useGetComboListQuery } from "../../../../GraphQL/Queries/getComboList.generated"
import emptyIcon from "../../../../shared/assets/icons/empty_icon.svg"
import { useFilters } from "../../../../shared/contexts/FilterProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import type { RComboList } from "../../../../shared/graphql/generated/types"
import { updateGetComboListQuery } from "../../../../shared/graphql/updateQuery/updateGetComboListQuery"
import { useBreadcrumb } from "../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../shared/routes/paths"
import Container from "../../../../ui/Container"
import EmptyState from "../../../../ui/EmptyState"
import Icon from "../../../../ui/Icon"
import RetryPage from "../../../../ui/RetryPage"
import Table from "../../../../ui/Table"
import BodyCell from "../../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../../ui/Table/EmptyStateCell"
import type { ColumProps } from "../../../../ui/Table/Table"
import Tooltip from "../../../../ui/Tooltip"
import OwnershipTag from "../../components/OwnershipTag"
import StatusBadge from "../../components/StatusBadge"
import { StatusBadgeEnum } from "../../components/StatusBadge/StatusBadge"
import AddComboButton from "./components/AddComboButton"
import DropdownCombo from "./components/DropdownCombo"
import { combosCorporateColumns, combosLocationsColumns } from "./utils/columns"
import get from "lodash/get"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"

type ComboProps = {
  isCorporate?: boolean
}

const Combos: React.FC<ComboProps> = ({ isCorporate = true }) => {
  const intl = useIntl()
  const { pushBreadcrumb } = useBreadcrumb()
  const { getSearchQueryFilter } = useFilters()
  const search = getSearchQueryFilter()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  const columns: Array<ColumProps> = useMemo(
    () =>
      isCorporate
        ? combosCorporateColumns.map((column) => ({
            ...column,
            title:
              column.title &&
              intl.formatMessage({
                id: column?.id,
                defaultMessage: column.title,
              }),
          }))
        : combosLocationsColumns.map((column) => ({
            ...column,
            title:
              column.title &&
              intl.formatMessage({
                id: column?.id,
                defaultMessage: column.title,
              }),
          })),
    [intl, isCorporate]
  )

  const { data, loading, error, refetch, fetchMore } = useGetComboListQuery({
    variables: { ...(search && { name: search }) },
    skip: !currentRestaurantUUID,
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    fetchPolicy: "network-only",
  })

  const allCombos: RComboList[] = get(data, "getComboList.results", [])
  const hasNextPage = get(data, "getComboList.hasNextPage", false)
  const endCursor = get(data, "getComboList.endCursor", undefined)

  const retry = () => refetch()

  const fetchMoreCombos = useCallback(() => {
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prev: GetComboListQuery, { fetchMoreResult }) =>
        updateGetComboListQuery(prev, fetchMoreResult),
    })
  }, [endCursor, fetchMore])

  const onComboClick = (uuid: string) => {
    const path = isCorporate
      ? paths.restaurants.editCombo(uuid)
      : paths.restaurants.locations.editComboByLocation(locationUUID, uuid)

    return pushBreadcrumb(path, {
      currentPageName: intl.formatMessage({
        id: "restaurants.menu.items.combos.list.current.page.name",
        defaultMessage: "Items",
      }),
    })
  }

  if (error) {
    return <RetryPage error={error} reload={retry} />
  }

  return (
    <Container background="Neutral0">
      <Table
        top="48px"
        columns={columns}
        loading={loading}
        error={error}
        errorRefetch={retry}
        fetchMore={fetchMoreCombos}
        hasNextPage={hasNextPage}
        leftSpace={44}
        isArrayEmpty={!allCombos?.length}
        emptyState={
          <EmptyStateCell colSpan={columns.length}>
            <EmptyState
              title={intl.formatMessage({
                id: "restaurants.menu.items.combos.list.empty.title",
                defaultMessage: "You don’t have any combo yet",
              })}
              description={intl.formatMessage({
                id: "restaurants.menu.items.combos.list.empty.description",
                defaultMessage: "What would you want to do next?",
              })}
              button={<AddComboButton isCorporate={isCorporate} asButton />}
              image={emptyIcon}
              fullScreen
            />
          </EmptyStateCell>
        }
      >
        {allCombos?.map((combo) => {
          return (
            <tr
              key={combo.uuid}
              className="styled-data-table-row"
              onClick={() => onComboClick(combo.uuid)}
            >
              <BodyCell>{`${combo?.name || ""}`}</BodyCell>
              <BodyCell>
                <StatusBadge
                  status={
                    combo.isPublished
                      ? StatusBadgeEnum.PUBLISHED
                      : StatusBadgeEnum.DRAFT
                  }
                />
              </BodyCell>
              <BodyCell>
                {combo.hasSnapshot && combo.isPublished ? (
                  <Tooltip
                    width={175}
                    title={intl.formatMessage({
                      id: "restaurants.menu.items.combos.list.datatable.body.tooltip",
                      defaultMessage: "This menu combo has unpublished changes",
                    })}
                    placement="bottom"
                    visible
                  >
                    <Icon
                      remixiconClass="ri-error-warning-line"
                      cursor="pointer"
                      color="Warning4"
                      size={20}
                    />
                  </Tooltip>
                ) : (
                  <Container width="22px" />
                )}
              </BodyCell>
              {!isCorporate && (
                <>
                  <BodyCell>
                    <OwnershipTag isMain={combo.isMain} />
                  </BodyCell>
                  <BodyCell>
                    {combo.isMain && (
                      <Container
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon
                          remixiconClass={
                            combo.isVisible ?? true
                              ? "ri-eye-line"
                              : "ri-eye-off-line"
                          }
                          size={24}
                          color="Neutral5"
                        />
                      </Container>
                    )}
                  </BodyCell>
                </>
              )}
              <BodyCell>
                {(isCorporate || !combo.isMain) && (
                  <DropdownCombo
                    comboUUID={combo.uuid}
                    actions={
                      combo.isPublished
                        ? ["UNPUBLISH", "DELETE"]
                        : ["PUBLISH", "DELETE_DRAFT"]
                    }
                    isCorporate={isCorporate}
                  />
                )}
              </BodyCell>
            </tr>
          )
        })}
      </Table>
    </Container>
  )
}

export default Combos
