import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDeliveryIntegrationInOrderMutationVariables = Types.Exact<{
  data: Types.UpdateDeliveryIntegrationInOrderInput;
}>;


export type UpdateDeliveryIntegrationInOrderMutation = { __typename?: 'Mutation', updateDeliveryIntegrationInOrder: { __typename?: 'DeliveryIntegrationInOrder', trackingURL: string, order: { __typename?: 'RUUID', uuid: string } } };


export const UpdateDeliveryIntegrationInOrderDocument = gql`
    mutation updateDeliveryIntegrationInOrder($data: UpdateDeliveryIntegrationInOrderInput!) {
  updateDeliveryIntegrationInOrder(data: $data) {
    order {
      uuid
    }
    trackingURL
  }
}
    `;
export type UpdateDeliveryIntegrationInOrderMutationFn = Apollo.MutationFunction<UpdateDeliveryIntegrationInOrderMutation, UpdateDeliveryIntegrationInOrderMutationVariables>;

/**
 * __useUpdateDeliveryIntegrationInOrderMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryIntegrationInOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryIntegrationInOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryIntegrationInOrderMutation, { data, loading, error }] = useUpdateDeliveryIntegrationInOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeliveryIntegrationInOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryIntegrationInOrderMutation, UpdateDeliveryIntegrationInOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryIntegrationInOrderMutation, UpdateDeliveryIntegrationInOrderMutationVariables>(UpdateDeliveryIntegrationInOrderDocument, options);
      }
export type UpdateDeliveryIntegrationInOrderMutationHookResult = ReturnType<typeof useUpdateDeliveryIntegrationInOrderMutation>;
export type UpdateDeliveryIntegrationInOrderMutationResult = Apollo.MutationResult<UpdateDeliveryIntegrationInOrderMutation>;
export type UpdateDeliveryIntegrationInOrderMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryIntegrationInOrderMutation, UpdateDeliveryIntegrationInOrderMutationVariables>;