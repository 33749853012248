import { useModal } from "../../../../../../../shared/hooks/useModal"
import Container from "../../../../../../../ui/Container"
import DropDownAction from "../../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../../ui/Icon"
import Tooltip from "../../../../../../../ui/Tooltip"
import Text from "../../../../../../../ui/Typography/Text"
import { Menu } from "antd"
import React, { Suspense, lazy } from "react"
import { useIntl } from "react-intl"

const DeleteVariantModal = lazy(() => import("../DeleteVariantModal"))

type DropdownVariantProps = {
  onOk?: () => void
  disable: boolean
  hasData?: boolean
}

export const DropdownVariant: React.FC<DropdownVariantProps> = ({
  onOk,
  disable,
  hasData = true,
}) => {
  const intl = useIntl()
  const [
    deleteConfirmation,
    showDeleteConfirmation,
    dismissDeleteConfirmation,
  ] = useModal()

  const onDeleteConfirm = () => {
    onOk?.()
  }

  const onClickDropdownContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  return (
    <Container
      className="dropdown-actions"
      display="flex"
      justifyContent="flex-end"
      onClick={onClickDropdownContainer}
    >
      {disable ? (
        <Tooltip
          title={intl.formatMessage({
            id: "restaurants.menu.items.forms.variants.dropdown.remove.tooltip",
            defaultMessage:
              "Please change the default variant before hide or remove it",
          })}
        >
          <Icon
            onClick={onClickDropdownContainer}
            color="Primary5"
            remixiconClass="ri-information-line"
            data-testid="default-info-tooltip"
          />
        </Tooltip>
      ) : (
        <>
          <DropDownAction
            data-testid="dropdown-variants-action"
            placement="bottomRight"
            align={{ offset: [75, 0] }}
            overlay={
              <Container>
                <Menu role="menu-more-options-variants" selectedKeys={[]}>
                  <Menu.Item
                    key="dropdown-item-remove"
                    icon={
                      <Icon
                        remixiconClass="ri-delete-bin-7-line"
                        color="Neutral8"
                      />
                    }
                    onClick={hasData ? showDeleteConfirmation : onDeleteConfirm}
                  >
                    <Text size="s">
                      {intl.formatMessage({
                        id: "restaurants.menu.items.forms.variants.dropdown.remove.delete",
                        defaultMessage: "Delete",
                      })}
                    </Text>
                  </Menu.Item>
                </Menu>
              </Container>
            }
          >
            <Icon
              remixiconClass="ri-more-line"
              size={24}
              color="Primary5"
              cursor="pointer"
              data-testid="more-icon"
            />
          </DropDownAction>
          <Suspense fallback={null}>
            {deleteConfirmation && (
              <DeleteVariantModal
                onConfirm={onDeleteConfirm}
                onCancel={dismissDeleteConfirmation}
              />
            )}
          </Suspense>
        </>
      )}
    </Container>
  )
}
