import { useFilters } from "../../../shared/contexts/FilterProvider"
import type { RModifierGroupList } from "../../../shared/graphql/generated/types"
import { updateGetModifierListQuery } from "../../../shared/graphql/updateQuery/updateGetModiferGroupList"
import { useBreadcrumb } from "../../../shared/hooks/useBreadcrumb"
import paths from "../../../shared/routes/paths"
import Container from "../../../ui/Container"
import Icon from "../../../ui/Icon"
import RetryPage from "../../../ui/RetryPage"
import Table from "../../../ui/Table"
import BodyCell from "../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../ui/Table/EmptyStateCell"
import type { ColumProps } from "../../../ui/Table/Table"
import Tooltip from "../../../ui/Tooltip"
import OwnershipTag from "../components/OwnershipTag"
import StatusBadge from "../components/StatusBadge"
import { getStatus } from "../components/StatusBadge/getStatus"
import { useGetModifierGroupListQuery } from "./GraphQL/getModiferGroupList.generated"
import DropdownModifiersGroups from "./components/DropdownModifiersGroups"
import EmptyStateModifiers from "./components/EmptyState/EmptyStateModifiers"
import { corporateColumns, locationColumns } from "./utils/columns"
import get from "lodash/get"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type ModifiersProps = {
  isCorporate?: boolean
}

export const Modifiers: React.FC<ModifiersProps> = ({
  isCorporate = false,
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<{ locationUUID: string }>()
  const { pushBreadcrumb } = useBreadcrumb()

  const columns: Array<ColumProps> = useMemo(
    () =>
      isCorporate
        ? corporateColumns
        : locationColumns.map((column) => ({
            title:
              column.title &&
              intl.formatMessage({
                id: column?.id,
                defaultMessage: column.title,
              }),
            width: column?.width,
          })),
    [intl, isCorporate]
  )

  const { getSearchQueryFilter } = useFilters()
  const search = getSearchQueryFilter()

  const { data, loading, error, refetch, fetchMore } =
    useGetModifierGroupListQuery({
      variables: { name: search },
      notifyOnNetworkStatusChange: true,
      returnPartialData: true,
      fetchPolicy: "network-only",
    })

  const allModifierGroups: Array<RModifierGroupList> = get(
    data,
    "getModifierGroupList.results",
    []
  )
  const hasNextPage = get(data, "getModifierGroupList.hasNextPage", false)
  const endCursor = get(data, "getModifierGroupList.endCursor", undefined)

  const fetchMoreModifiersGroups = useCallback(() => {
    fetchMore({
      variables: { after: endCursor },
      updateQuery: updateGetModifierListQuery,
    })
  }, [endCursor, fetchMore])

  const retry = () => refetch()

  const onRowClick = useCallback(
    (uuid: string) => {
      const path = isCorporate
        ? paths.restaurants.editModifier(uuid)
        : paths.restaurants.locations.editModifiersByLocation(
            locationUUID,
            uuid
          )

      pushBreadcrumb(path, {
        currentPageName: intl.formatMessage({
          id: "restaurants.modifiers.list.screen.title",
          defaultMessage: "Modifiers",
        }),
      })
    },
    [intl, isCorporate, locationUUID, pushBreadcrumb]
  )

  if (error) {
    return <RetryPage error={error} reload={retry} />
  }

  return (
    <Container background="Neutral0" role="modifiers-table" position="sticky">
      <Table
        top="48.5px"
        columns={columns}
        loading={loading}
        error={error}
        errorRefetch={retry}
        fetchMore={fetchMoreModifiersGroups}
        hasNextPage={hasNextPage}
        leftSpace={44}
        isArrayEmpty={allModifierGroups.length === 0}
        emptyState={
          <EmptyStateCell colSpan={columns.length}>
            <EmptyStateModifiers isCorporate={isCorporate} />
          </EmptyStateCell>
        }
      >
        {!!allModifierGroups?.length &&
          allModifierGroups.map((record) => {
            return (
              <tr
                className="styled-data-table-row"
                key={record.uuid}
                onClick={() => onRowClick(record.uuid)}
              >
                <BodyCell>{`${record.name ?? ""}`}</BodyCell>
                <BodyCell>
                  <Container
                    display="flex"
                    gap="14px"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <StatusBadge
                      status={getStatus({
                        isPublished: record.isPublished,
                        hasSnapshot: record.hasSnapshot,
                        uuidExists: !!record.uuid,
                      })}
                    />
                  </Container>
                </BodyCell>
                <BodyCell>
                  {record.hasSnapshot && record.isPublished ? (
                    <Tooltip
                      width={175}
                      title={intl.formatMessage({
                        id: "restaurants.modifiers.datatable.body.tooltip",
                        defaultMessage:
                          "This modifiers has unpublished changes",
                      })}
                      placement="bottom"
                      visible
                    >
                      <Icon
                        remixiconClass="ri-error-warning-line"
                        cursor="pointer"
                        color="Warning4"
                        size={20}
                      />
                    </Tooltip>
                  ) : (
                    <Container width="22px" />
                  )}
                </BodyCell>
                {!isCorporate && (
                  <>
                    <BodyCell>
                      <OwnershipTag isMain={record.isMain} />
                    </BodyCell>
                  </>
                )}
                <BodyCell textAlign="center">
                  {(isCorporate || !record.isMain) && (
                    <DropdownModifiersGroups
                      modifierGroupUUID={record.uuid}
                      isCorporate={isCorporate}
                      actions={
                        record.isPublished
                          ? ["UNPUBLISH", "DELETE"]
                          : ["DELETE_DRAFT"]
                      }
                    />
                  )}
                </BodyCell>
              </tr>
            )
          })}
      </Table>
    </Container>
  )
}
