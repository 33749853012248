import type {
  GetAllModifiersByRestaurantQuery,
  GetAllModifiersByRestaurantQueryVariables,
} from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import { GetAllModifiersByRestaurantDocument } from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import type { ApolloCache } from "@apollo/client"

type WriteCacheIngredientsByRestaurantQuery = {
  cache: ApolloCache<unknown>
  query: GetAllModifiersByRestaurantQuery
  variables: GetAllModifiersByRestaurantQueryVariables
}

const writeIngredientsByRestaurantQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheIngredientsByRestaurantQuery) =>
  cache.writeQuery<
    GetAllModifiersByRestaurantQuery,
    GetAllModifiersByRestaurantQueryVariables
  >({
    query: GetAllModifiersByRestaurantDocument,
    data: query,
    variables,
  })

export default writeIngredientsByRestaurantQueryCache
