import styles from "./AlertRefund.module.css"
import { Alert, Divider, Flex, Text } from "@mantine/core"
import { ReactNode } from "react"
import { useIntl } from "react-intl"

type Props = {
  amount: number
  coment: string
  storeCash: number
}
export const AlertRefund = ({ amount, coment, storeCash }: Props) => {
  const intl = useIntl()

  return (
    <Alert
      p={"1rem"}
      title={
        <Text c="gray.9" fw={400} size={"md"}>
          {intl.formatMessage({
            id: "restaurants.catering.detail.alert.refund.title",
            defaultMessage: "Order refunded",
          })}
        </Text>
      }
      color="red"
      radius={0}
      className={styles["alert-wrapper"]}
    >
      <Flex gap={"1rem"}>
        <Text size="sm" c="gray.6">
          {intl.formatMessage(
            {
              id: "restaurants.catering.detail.orders.refunded.alert.amount.description",
              defaultMessage:
                "Refunded amount: <bold>{refundedAmount, number, ::currency/USD}</bold>",
            },
            {
              refundedAmount: amount + storeCash,
              bold: (chunks: ReactNode) => (
                <span className={styles["amount-text"]}>{chunks}</span>
              ),
            }
          )}
        </Text>
        <Divider orientation="vertical" color="red.2" />
        <Text>
          {intl.formatMessage(
            {
              id: "restaurants.catering.detail.orders.refunded.alert.notes.refund",
              defaultMessage: "Reason: {reasonDescription} ",
            },
            {
              reasonDescription: (
                <span className={styles["reason-text"]}>{coment}</span>
              ),
            }
          )}
        </Text>
      </Flex>
    </Alert>
  )
}
