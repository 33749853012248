import paths from "../../../../../shared/routes/paths"
import {
  ddd_D,
  hh_mm_A,
} from "../../../../../shared/utils/constant/dateFormats"
import { CateringOrderInformationType } from "../../types"
import styles from "./ModalCateringOrders.module.css"
import {
  Button,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { IconTruckDelivery } from "@tabler/icons-react"
import moment from "moment-timezone"
import { useHistory } from "react-router-dom"

interface ModalCateringOrdersProps {
  data?: CateringOrderInformationType[]
  opened: boolean
  onClose: () => void
  date: Date
}

export const ModalCateringOrders = ({
  data,
  opened,
  onClose,
  date,
}: ModalCateringOrdersProps) => {
  const mantineTheme = useMantineTheme()

  const { push } = useHistory()

  return (
    <Modal
      title={moment(date).format(ddd_D)}
      centered
      onClose={onClose}
      opened={opened}
    >
      <Flex gap={2} direction={"column"}>
        {data?.map((item) => (
          <Button
            className={styles["modal-catering-item"]}
            key={item.uuid}
            p={8}
            color={"gray"}
            h={"auto"}
            onClick={() => push(paths.restaurants.cateringDetail(item.uuid))}
            variant="subtle"
          >
            <Stack spacing={2}>
              <Group spacing={4}>
                <IconTruckDelivery
                  color={mantineTheme.colors.gray[6]}
                  size={16}
                />
                <Text c="gray.9" size="xs">
                  #{item.orderNumber}
                </Text>
              </Group>
              <Text size="xs" c="gray.6">
                {moment(item.createdAt).format(hh_mm_A)}
              </Text>
            </Stack>
          </Button>
        ))}
      </Flex>
    </Modal>
  )
}
