import { graphqlClient } from "../../../../../shared/graphql/client"
import type { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"
import type {
  GetOrdersByRestaurantQuery,
  GetOrdersByRestaurantQueryVariables,
} from "../../GraphQL/getOrdersByRestaurant.generated"
import type {
  GetOrderDetailsQuery,
  GetOrderDetailsQueryVariables,
} from "../../OrderDetail/GraphQL/getOrderDetails.generated"
import {
  readGetOrderDetailsQueryCache,
  readOrdersByRestaurantQueryCache,
} from "./orders-cache-read"
import {
  writeOrderDetailsQueryCache,
  writeOrdersByRestaurantQueryCache,
} from "./orders-cache-write"

const cache = graphqlClient.cache

export const updateOrder = (
  data: { uuid: string; status: OrderStatusEnum },
  variables: GetOrdersByRestaurantQueryVariables
) => {
  const ordersByRestaurantQuery = readOrdersByRestaurantQueryCache({
    cache,
    variables,
  })

  const orders = ordersByRestaurantQuery?.getOrdersByRestaurant.results

  if (orders) {
    const ordersUpdated = orders.map((order) => {
      if (order.uuid === data.uuid) {
        return { ...order, status: data.status }
      }

      return order
    })

    const query: GetOrdersByRestaurantQuery = {
      getOrdersByRestaurant: {
        ...ordersByRestaurantQuery.getOrdersByRestaurant,
        results: ordersUpdated,
      },
    }

    writeOrdersByRestaurantQueryCache({ cache, query, variables })
  }
}

export const updateOrderDetail = (
  data: { uuid: string; status: OrderStatusEnum },
  variables: GetOrderDetailsQueryVariables
) => {
  const orderDetailsQuery = readGetOrderDetailsQueryCache({
    cache,
    variables,
  })

  const order = orderDetailsQuery?.getOrderDetails

  if (order) {
    const orderUpdated =
      order.uuid === data.uuid ? { ...order, status: data.status } : order

    const query: GetOrderDetailsQuery = {
      getOrderDetails: {
        ...orderDetailsQuery.getOrderDetails,
        ...orderUpdated,
      },
    }

    writeOrderDetailsQueryCache({ cache, query, variables })
  }
}
