import type { OrderFilterTypeEnum } from "../../../pages/Restaurants/Orders/OrdersFilter/OrdersFilter"
import { useFilters } from "../../../shared/contexts/FilterProvider"
import { entriesOf } from "../../../shared/utils/helpers/entriesOf"
import { colors } from "../../../styles/global/themes/mainTheme"
import Container from "../../Container"
import Icon from "../../Icon"
import Tag from "../../Tag"
import React from "react"
import { useIntl } from "react-intl"

type FilterProps = {
  onFilterClick: () => void
}

export const Filter: React.FC<FilterProps> = ({ onFilterClick }) => {
  const intl = useIntl()
  const { filters, updateFilters } = useFilters()

  const searchTerms = entriesOf(filters).flatMap(([key, filterParams]) => {
    return filterParams.flatMap((filterParam) => ({
      ...filterParam,
      type: key,
    }))
  })

  const onTagClose = (key: string, type: `${OrderFilterTypeEnum}`) => {
    updateFilters({
      [`${type}`]: filters[type]?.filter((filter) => filter.key !== key),
    })
  }

  return (
    <Container display="flex" gap="8px" flexWrap="wrap">
      {!!searchTerms &&
        searchTerms.map(({ key, name, type }, index) => (
          <Tag
            key={index}
            type="primary"
            onClose={() => onTagClose(key, type)}
            closable
          >
            {intl.formatMessage({ id: name, defaultMessage: name })}
          </Tag>
        ))}
      {searchTerms.length === 0 ? (
        <Tag
          borderStyle="dashed"
          type="secondary"
          onClick={onFilterClick}
          icon="ri-filter-3-fill"
          cursor="pointer"
        >
          {intl.formatMessage({
            id: "components.filter.title",
            defaultMessage: "Filter",
          })}
        </Tag>
      ) : (
        <Tag
          borderStyle="dashed"
          type="secondary"
          color={colors["Neutral1"]}
          onClick={onFilterClick}
          cursor="pointer"
        >
          <Icon remixiconClass="ri-add-line" size={12} color="Neutral7" />
        </Tag>
      )}
    </Container>
  )
}
