import { OrderActionTypeEnum } from "../../../../../../shared/graphql/generated/types"
import { MantineCard } from "../../../../../../ui/MantineCard"
import { mappingActionTypes } from "../../../utils/action-types"
import { formatDate } from "../../../utils/format-date"
import { Timeline as MantineTimeline, Text } from "@mantine/core"
import { IconCircle } from "@tabler/icons-react"
import { useIntl } from "react-intl"

interface TimelineProps {
  orderActions: {
    actionType: OrderActionTypeEnum
    actionAt: string
  }[]
}

export const Timeline = ({ orderActions }: TimelineProps) => {
  const intl = useIntl()

  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.timeline.title",
        defaultMessage: "Timeline",
      })}
    >
      <MantineTimeline
        active={orderActions?.length}
        bulletSize={10}
        lineWidth={2}
        color="green.6"
      >
        {orderActions
          .map((action, index) => (
            <MantineTimeline.Item
              key={index}
              title={intl.formatMessage(
                mappingActionTypes[action.actionType] ??
                  OrderActionTypeEnum.OPEN
              )}
              bullet={<IconCircle size={8} color="green.6" />}
            >
              <Text color="gray.6" size="xs">
                {formatDate(action.actionAt)}
              </Text>
            </MantineTimeline.Item>
          ))
          .reverse()}
      </MantineTimeline>
    </MantineCard>
  )
}
