import type { Action } from "../../shared/contexts/actions"
import type { IAdmin } from "../../shared/types"

export enum ActionTypes {
  Login = "login",
  MagicLinkEmail = "magicLinkEmail",
  ClearMagicLinkEmail = "clearMagicLinkEmail",
  Logout = "logout",
}

export interface LoginAction extends Action<ActionTypes.Login> {
  payload: {
    admin: IAdmin
  }
}

export interface MagicLinkAction extends Action<ActionTypes.MagicLinkEmail> {
  payload: {
    email: string
  }
}

export type ClearMagicLinkAction = Action<ActionTypes.ClearMagicLinkEmail>

export type LogoutAction = Action<ActionTypes.Logout>
