import { Group, List, Text } from "@mantine/core"
import { ReactNode } from "react"
import { useIntl } from "react-intl"

interface ListItemProps {
  label: {
    id: string
    defaultMessage: string
  }
  apply: boolean
  children: ReactNode
}

export const ListItem = ({ children, apply, label }: ListItemProps) => {
  const intl = useIntl()

  return apply ? (
    <List.Item>
      <Group spacing={8}>
        <Text>{intl.formatMessage(label)} :</Text>
        <Text fw={600}>{children}</Text>
      </Group>
    </List.Item>
  ) : null
}
