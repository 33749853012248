import { ScheduleType } from "../../../../../../../shared/types/schedule.types"
import { ALL_DAY_VALUE } from "../../../../../../../shared/utils/constant/dateFormats"
import { DAY_START_HOUR } from "../../../../../../../ui/CustomTimePicker/constants"
import { MeridiemEnum } from "../../../../../../../ui/TimePickerSelect"
import { DateFieldType } from "../../../../interfaces/dateRule.interfaces"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { SET_VALUE_OPTIONS } from "../../constants"
import {
  ADD_END_TIME_NAME,
  END_DATE_NAME,
  START_DATE_NAME,
  START_TIME_NAME,
} from "../../constants"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import type { Moment } from "moment"
import { useFormContext, useWatch } from "react-hook-form"

export const useDateAndTime = () => {
  const {
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<IDiscountForm>()

  const [startDate, endDate, startTime, addEndTime] = useWatch({
    control,
    name: [START_DATE_NAME, END_DATE_NAME, START_TIME_NAME, ADD_END_TIME_NAME],
  })

  const onAddTimeChange = (checkboxChange: CheckboxChangeEvent) => {
    const isChecked = checkboxChange.target.checked

    setValue(ADD_END_TIME_NAME, isChecked, SET_VALUE_OPTIONS)
    setValue(END_DATE_NAME, undefined, SET_VALUE_OPTIONS)

    trigger(END_DATE_NAME)
    trigger(START_DATE_NAME)
  }

  const onTimeChange = (value: ScheduleType, fieldType: DateFieldType) => {
    if (value.time === ALL_DAY_VALUE) {
      setValue(
        `rules.dateAndTime.${fieldType}.hour`,
        {
          time: DAY_START_HOUR,
          meridium: MeridiemEnum.AM,
        },
        SET_VALUE_OPTIONS
      )
    }

    setValue(
      `rules.dateAndTime.${fieldType}.hour`,
      { time: value.time, meridium: value.meridium ?? MeridiemEnum.AM },
      SET_VALUE_OPTIONS
    )

    trigger(END_DATE_NAME)
    trigger(START_DATE_NAME)
  }

  const onDateChange = (selectedDate: Moment, fieldType: DateFieldType) => {
    setValue(
      `rules.dateAndTime.${fieldType}.date`,
      selectedDate.toISOString(),
      SET_VALUE_OPTIONS
    )

    trigger(END_DATE_NAME)
    trigger(START_DATE_NAME)
  }

  return {
    startDate,
    startTime,
    endDate,
    addEndTime,
    onDateChange,
    onTimeChange,
    onAddTimeChange,
    startDateError: errors.rules?.dateAndTime?.startDate,
    endDateError: {
      group: errors.rules?.dateAndTime?.endDate,
      date: errors.rules?.dateAndTime?.endDate?.date,
      hour: errors.rules?.dateAndTime?.endDate?.hour,
    },
  }
}
