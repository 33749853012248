import { SortingByMenuEnum } from "../../../../../shared/graphql/generated/types"
import { useModal } from "../../../../../shared/hooks/useModal"
import paths from "../../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import { formNotification } from "../../../../../ui/notification"
import ItemModalHeader from "../../../components/ModalFullDetailHeader"
import type { GetItemDetailQuery } from "../GraphQL/getItemDetail.generated"
import DropdownItem from "../components/DropdownItem"
import PublishItemModal from "../components/modals/PublishItemModal"
import { ItemFormResolver } from "../forms/ItemForm.yup"
import ItemInfoForm from "../forms/ItemInfoForm"
import VariantsForm from "../forms/VariantsForm"
import { useItems } from "../hooks/useItems"
import type { IItemForm } from "../interfaces/hookforms.interfaces"
import { FieldTypeEnumItem } from "../interfaces/hookforms.interfaces"
import {
  MAX_GREATER_THAN_MODIFIERS,
  MAX_LOWER_THAN_INCLUDED_MODIFIERS,
} from "../utils/constants"
import { formDataToInput } from "../utils/formDataToInput"
import { ItemFormError } from "../utils/notifications.titles"
import moment from "moment-timezone"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type EditItemProps = {
  isCorporate?: boolean
  item: GetItemDetailQuery["getItemDetail"]
}

const getDateSorting = (sorting?: SortingByMenuEnum | null) => {
  if (
    sorting === SortingByMenuEnum.DATE_ASC ||
    sorting === SortingByMenuEnum.DATE_DESC ||
    !sorting
  ) {
    return SortingByMenuEnum.NAME_ASC
  }
  return sorting
}

export const EditItem: React.FC<EditItemProps> = ({ isCorporate, item }) => {
  const intl = useIntl()
  const { saveItem, itemSaved } = useItems({})
  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const [fieldsWithError, setFieldsWithError] = useState<Array<string>>([])
  const [savedItemName, setSavedItemName] = useState<string>(item.name ?? "")

  const currentMenus = item?.menus || []

  const { restaurants } = paths

  const getDefaultVariantId = () => {
    const defaultVariant = item.variants.find((variant) => variant.isDefault)

    return defaultVariant?.uuid
  }

  const formMethods = useForm<IItemForm>({
    mode: "all",
    resolver: ItemFormResolver,
    defaultValues: {
      uuid: item.uuid,
      defaultVariantId: getDefaultVariantId(),
      lastPublishedAt: item.publishedAt,
      lastSavedAt: item.snapshotUpdatedAt,
      attachment: item.attachment ?? undefined,
      isSoldOut: !!item.isSoldOut,
      hasSnapshot: item.hasSnapshot ?? false,
      isPublished: !!item.publishedAt,
      isMain: isCorporate,
      status: item.status,
      name: item.name ?? "",
      description: item.description ?? "",
      menus: item.menus?.map((menu) => menu.uuid) ?? [],
      sortVariantsBy: getDateSorting(item.variantsSortBy),
      serves: item.serves ?? undefined,
      variants: item.variants.map((variant) => ({
        ...variant,
        name: variant.name ?? "",
        price: variant.price ?? null,
        sku: variant.sku ?? "",
        calories: variant.calories ?? null,
        attachment: variant.attachment ?? undefined,
        isSoldOut: variant.isSoldOut ?? undefined,
        image: undefined,
        createdAt: variant.createdAt ?? undefined,
        sortModifierGroupsBy: getDateSorting(
          variant.variantModifierGroupsSortBy
        ),
        variantModifierGroups: variant.variantModifierGroups.map(
          (modifierGroup) => ({
            ...modifierGroup,
            minSelectedOptions: modifierGroup.minSelectedOptions ?? 0,
            maxSelectedOptions: modifierGroup.maxSelectedOptions ?? 1,
            fieldType: modifierGroup.fieldType ?? FieldTypeEnumItem.RADIO,
            isRequired: modifierGroup.isRequired ?? undefined,
            createdAt: modifierGroup.createdAt ?? undefined,
            variantModifiers: modifierGroup.variantModifiers.map(
              (modifier) => ({
                ...modifier,
                price: modifier.price ?? 0,
                calories: modifier.calories ?? 0,
                isVisible: modifier.isVisible ?? undefined,
                isSoldOut: modifier.isSoldOut ?? undefined,
              })
            ),
          })
        ),
      })),
      hasInitialData: true,
      closeForm: false,
    },
  })

  const {
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { isDirty, errors },
  } = formMethods

  const [itemUUID, lastSavedAt, lastPublishedAt, hasSnapshot] = useWatch({
    name: ["uuid", "lastSavedAt", "lastPublishedAt", "hasSnapshot"],
    control: control,
  })

  const [imagesUploading, setImagesUploading] = useState(0)
  const notUploadingImages = imagesUploading === 0
  const enabledPreview = !!itemUUID && !!lastSavedAt

  const onSave = async () => {
    let formData = getValues()
    clearErrors("variants")
    const isValidName = await trigger("name", { shouldFocus: true })

    if (!isValidName) {
      const nameError = errors?.name
        ? { message: intl.formatMessage(ItemFormError.NAME) }
        : undefined

      return formNotification({
        key: "lsm.edit.item.save",
        header: intl.formatMessage({
          id: "restaurants.menu.items.edit.item.save.notification.error.header",
          defaultMessage: "You can’t save this menu item",
        }),
        title: intl.formatMessage({
          id: "restaurants.menu.items.edit.item.save.notification.error.title",
          defaultMessage: "To save a menu item you need to:",
        }),
        type: "error",
        error: { ...errors, ...(nameError && { name: nameError }) },
      })
    }

    if (!formData.attachment && formData.image) {
      formData = { ...formData, attachment: item.attachment }
    }

    await saveItem(
      formDataToInput(formData),
      (result) => {
        setSavedItemName(result.saveItem.name)
        setValue("lastSavedAt", moment().toDate())
        setValue("hasSnapshot", true)
        reset({}, { keepValues: true })
      },
      showGraphqlErrors
    )
  }

  const onPublish = () => {
    handleSubmit(
      () => {
        showPublishConfirmation()
      },
      (error) => {
        const variantErrors = Object.assign({}, error.variants)
        const errorArray = Object.keys(variantErrors) ?? []
        setFieldsWithError(errorArray)

        const modifierGroupIndex = Object.keys(
          Object.assign(
            {},
            variantErrors[Number(errorArray[0])]?.variantModifierGroups
          )
        )[0]

        if (modifierGroupIndex) {
          const selectID = `#variants-${errorArray[0]}-variantModifierGroups-${modifierGroupIndex}-fieldType`
          const select = document.querySelector(selectID)

          setTimeout(() => {
            select?.scrollIntoView({ behavior: "smooth" })
          }, 500)
        }

        const nameError = error?.name
          ? { message: intl.formatMessage(ItemFormError.NAME) }
          : undefined

        const variantError = error?.variants
          ? { message: intl.formatMessage(ItemFormError.VARIANT) }
          : undefined

        let includedModifierError: { message: string } | undefined
        let maxModifiersError: { message: string } | undefined

        error?.variants?.forEach?.((variant) =>
          variant?.variantModifierGroups?.forEach?.((modifierGroup) => {
            if (
              modifierGroup?.maxSelectedOptions?.type ===
              MAX_LOWER_THAN_INCLUDED_MODIFIERS
            ) {
              includedModifierError = {
                message: intl.formatMessage(ItemFormError.INCLUDED_MODIFIERS),
              }
            }
            if (
              modifierGroup?.maxSelectedOptions?.type ===
              MAX_GREATER_THAN_MODIFIERS
            ) {
              maxModifiersError = {
                message: intl.formatMessage(ItemFormError.MAX_MODIFIERS),
              }
            }
          })
        )

        formNotification({
          key: "lsm.edit.item.publish",
          header: intl.formatMessage({
            id: "restaurants.menu.items.edit.item.notification.error.header",
            defaultMessage: "You can’t publish this menu item",
          }),
          title: intl.formatMessage({
            id: "restaurants.menu.items.edit.item.notification.error.title",
            defaultMessage: "To publish a menu item you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(nameError && { name: nameError }),
            ...(!includedModifierError &&
              !maxModifiersError &&
              variantError && { variants: variantError }),
            ...(maxModifiersError && {
              maxModifiersError: maxModifiersError,
            }),
            ...(includedModifierError && {
              maxSelectedOptions: includedModifierError,
            }),
          },
        })
      }
    )()
  }

  const publishCallback = () => {
    dismissPublishConfirmation()

    onClose()
  }

  const onClose = () => {
    setValue("hasSnapshot", false)
    setValue("closeForm", true)
    reset({}, { keepDirty: false, keepValues: true })
  }

  const getItemData = () => {
    if (isDirty) {
      let formData = getValues()

      if (!formData.attachment && formData.image)
        formData = { ...formData, attachment: item.attachment }

      const data = formDataToInput(formData)

      return data
    }

    return undefined
  }

  useEffect(() => {
    setValue("image", item.attachment?.signedUrl)
  }, [item.attachment?.signedUrl, setValue])

  useEffect(() => {
    if (!errors.variants) {
      setFieldsWithError([])
    }
  }, [errors.variants])

  return (
    <ModalFull
      goBackPath={restaurants.items}
      headerColor="Neutral2"
      title={
        <ItemModalHeader
          onSave={onSave}
          onPublish={onPublish}
          lastSavedAt={lastSavedAt}
          lastPublishedAt={lastPublishedAt}
          enablePreview={enabledPreview}
          enablePublish={
            (hasSnapshot || !lastPublishedAt || isDirty) && notUploadingImages
          }
          enableSave={isDirty && !!itemUUID && notUploadingImages}
          loadingPublish={false}
          loadingSave={itemSaved.loading}
          actions={
            <DropdownItem
              isCorporate={isCorporate}
              onOk={onClose}
              itemUUID={itemUUID}
              actions={
                item.publishedAt
                  ? ["DELETE_DRAFT", "UNPUBLISH"]
                  : ["DELETE", "UNPUBLISH", "DISABLE_UNPUBLISH"]
              }
            />
          }
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.editItems}
            pageName={savedItemName}
          />
        </ItemModalHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ItemInfoForm
          currentMenus={currentMenus}
          setImagesUploading={setImagesUploading}
        />
        <VariantsForm
          isCorporate={isCorporate}
          fieldsWithError={fieldsWithError}
          setImagesUploading={setImagesUploading}
        />
      </FormProvider>
      {publishModalVisible && (
        <PublishItemModal
          itemUUID={itemUUID}
          itemData={getItemData()}
          onOk={publishCallback}
          onCancel={dismissPublishConfirmation}
        />
      )}
    </ModalFull>
  )
}
