import { ScheduleType } from "../../../../../../shared/types/schedule.types"
import { ALL_DAY_VALUE } from "../../../../../../shared/utils/constant/dateFormats"
import Container from "../../../../../../ui/Container"
import SimpleDatePicker from "../../../../../../ui/SimpleDatePicker"
import TimeSelect from "../../../../../../ui/TimeSelect"
import Text from "../../../../../../ui/Typography/Text"
import {
  DateFieldType,
  DateRule,
} from "../../../interfaces/dateRule.interfaces"
import type { Moment } from "moment"
import moment from "moment-timezone"

interface DateSelectorProps {
  title: { datePicker: string; timePicker: string }
  allowAllDay?: boolean
  isAllDay?: boolean
  fieldType: DateFieldType
  date?: DateRule
  onTimeChange: (time: ScheduleType, fieldType: DateFieldType) => void
  onDateChange: (date: Moment, fieldType: DateFieldType) => void
  errors?: { date?: string; hour?: string }
  hasGroupError?: boolean
}

export const DateSelector = (props: DateSelectorProps) => {
  const {
    title,
    isAllDay,
    allowAllDay,
    date,
    fieldType,
    onDateChange,
    onTimeChange,
    errors,
    hasGroupError,
  } = props

  const handleDateChange = (selectedDate: Moment | null) => {
    if (!selectedDate) return

    onDateChange(selectedDate, fieldType)
  }

  const handleTimeChange = (selectedTime: ScheduleType) => {
    onTimeChange(selectedTime, fieldType)
  }

  return (
    <>
      <Container display="flex" gap="16px">
        <Container display="flex" flexDirection="column" gap="8px" width="100%">
          <Text>{title.datePicker}</Text>
          <SimpleDatePicker
            allowClear={false}
            value={date?.date ? moment(date.date) : undefined}
            onChange={handleDateChange}
            hasErrors={!!errors?.date || hasGroupError}
            helperText={errors?.date}
          />
        </Container>
        <Container display="flex" flexDirection="column" gap="8px" width="100%">
          <Text>{title.timePicker}</Text>
          <TimeSelect
            wrapperWidth="100%"
            width="100%"
            value={{
              time: isAllDay ? ALL_DAY_VALUE : date?.hour?.time,
              meridium: date?.hour?.meridium,
            }}
            allowAllDay={allowAllDay}
            allowClear={false}
            meridiemVisible={true}
            disableMeridiem={isAllDay}
            onChange={handleTimeChange}
            hasError={!!errors?.hour || hasGroupError}
            helperText={errors?.hour}
          />
        </Container>
      </Container>
    </>
  )
}
