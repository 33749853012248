import type { IComboForm } from "../interfaces/hookforms.interfaces"
import { omit } from "lodash"

export const comboFormDataToInput = (formData: IComboForm) => {
  const {
    uuid,
    name,
    priceOverride,
    menus,
    isSoldOut,
    isMain,
    fullPrice,
    discountPercentage,
    description,
    comboItemGroups,
    deletedComboItemGroups,
    attachment,
  } = formData

  const menusInput = menus?.map((menu) => ({ uuid: menu }))

  const fullComboItemGroups = [...comboItemGroups, ...deletedComboItemGroups]

  const comboItemGroupsInput = fullComboItemGroups.map((cmbItmGrp) => {
    const comboItemsInput = cmbItmGrp.comboItems?.map((comboItem) => ({
      ...(comboItem.uuid && !cmbItmGrp.isDuplicate && { uuid: comboItem.uuid }),
      ...(comboItem.deletedAt && { deletedAt: comboItem.deletedAt }),
      item: { uuid: comboItem.item.uuid },
      isDefault: comboItem.isDefault,
    }))

    return {
      ...(cmbItmGrp.uuid && !cmbItmGrp.isDuplicate && { uuid: cmbItmGrp.uuid }),
      ...(cmbItmGrp.name && { name: cmbItmGrp.name }),
      ...(cmbItmGrp.deletedAt && { deletedAt: cmbItmGrp.deletedAt }),
      comboItems: comboItemsInput || [],
    }
  })

  return {
    uuid,
    name,
    priceOverride,
    menus: menusInput,
    isSoldOut,
    isMain,
    fullPrice,
    discountPercentage,
    ...(!!description && { description }),
    comboItemGroups: comboItemGroupsInput,
    attachment: attachment ? omit(attachment, "signedUrl") : undefined,
  }
}
