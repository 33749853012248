import paths from "../../../shared/routes/paths"
import Button from "../../../ui/Button"
import Container from "../../../ui/Container"
import { Result } from "antd"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const LoginError: React.FC = () => {
  return (
    <Container centered fullScreen>
      <Result
        status="403"
        title={
          <FormattedMessage
            id="login.session.error.message"
            defaultMessage="Session has expired"
          />
        }
        extra={
          <StyledLink to={paths.guest.login}>
            <Button hierarchy="secondary">
              <FormattedMessage
                id="login.error.button"
                defaultMessage="Back Home"
              />
            </Button>
          </StyledLink>
        }
      />
    </Container>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
`
