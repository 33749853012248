import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishMenuMutationVariables = Types.Exact<{
  input: Types.RPublishMenuInput;
}>;


export type PublishMenuMutation = { __typename?: 'Mutation', publishMenu: { __typename?: 'RPublishedMenu', uuid: string, applyToAllLocations?: boolean | null, combosCount: number, hasSnapshot: boolean, isMain: boolean, itemsCount: number, menuType: Types.MenuTypeEnum, name: string, status: Types.MenuStatusEnum } };


export const PublishMenuDocument = gql`
    mutation publishMenu($input: RPublishMenuInput!) {
  publishMenu(input: $input) {
    uuid
    applyToAllLocations
    combosCount
    hasSnapshot
    isMain
    itemsCount
    menuType
    name
    status
  }
}
    `;
export type PublishMenuMutationFn = Apollo.MutationFunction<PublishMenuMutation, PublishMenuMutationVariables>;

/**
 * __usePublishMenuMutation__
 *
 * To run a mutation, you first call `usePublishMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMenuMutation, { data, loading, error }] = usePublishMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishMenuMutation(baseOptions?: Apollo.MutationHookOptions<PublishMenuMutation, PublishMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishMenuMutation, PublishMenuMutationVariables>(PublishMenuDocument, options);
      }
export type PublishMenuMutationHookResult = ReturnType<typeof usePublishMenuMutation>;
export type PublishMenuMutationResult = Apollo.MutationResult<PublishMenuMutation>;
export type PublishMenuMutationOptions = Apollo.BaseMutationOptions<PublishMenuMutation, PublishMenuMutationVariables>;