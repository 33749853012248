import { useModal } from "../../../../../shared/hooks/useModal"
import Container from "../../../../../ui/Container"
import DropDownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import type { MenuActionsType } from "../../interfaces"
import { Menu } from "antd"
import React, { Suspense, lazy } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

const PublishMenuModal = lazy(() => import("../modals/PublishMenuModal"))
const DeleteMenuModal = lazy(() => import("../modals/DeleteMenuModal"))
const DeleteDraftMenuModal = lazy(
  () => import("../modals/DeleteDraftMenuModal")
)
const UnpublishMenuModal = lazy(() => import("../modals/UnpublishMenuModal"))

type DropdownMenusProps = {
  menuUUID: string
  actions: Array<MenuActionsType | null>
  isCorporate?: boolean
  onOk?: () => void
}

const DropdownMenus: React.FC<DropdownMenusProps> = ({
  menuUUID,
  actions,
  isCorporate,
  onOk,
}) => {
  const intl = useIntl()

  const allowPublish = actions.includes("PUBLISH")
  const allowUnpublish = actions.includes("UNPUBLISH")
  const allowDelete = actions.includes("DELETE")
  const allowDeleteDraft = actions.includes("DELETE_DRAFT")
  const disableUnpublish = actions.includes("DISABLE_UNPUBLISH")

  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const [
    deleteModalVisible,
    showDeleteConfirmation,
    dismissDeleteConfirmation,
  ] = useModal()
  const [
    deleteDraftModalVisible,
    showDeleteDraftConfirmation,
    dismissDeleteDraftConfirmation,
  ] = useModal()
  const [
    unpublishModalVisible,
    showUnpublishConfirmation,
    dismissUnpublishConfirmation,
  ] = useModal()

  const onPublishOk = () => {
    dismissPublishConfirmation()
    onOk?.()
  }

  const onDeleteOk = () => {
    dismissDeleteConfirmation()
    onOk?.()
  }

  const onDeleteDraftOk = () => {
    dismissDeleteDraftConfirmation()
    onOk?.()
  }

  const onUnpublishOk = () => {
    dismissUnpublishConfirmation()
    onOk?.()
  }

  const onDeleteDraftClick = () => {
    if (menuUUID) return showDeleteDraftConfirmation()

    notification({
      title: intl.formatMessage({
        id: "restaurants.menus.use.menus.delete.menu.success.title",
        defaultMessage: "Category deleted",
      }),
      description: intl.formatMessage({
        id: "restaurants.menus.use.menus.delete.menu.success.description",
        defaultMessage: "Your Category was deleted",
      }),
      type: "success",
    })

    onOk?.()
  }

  const onClickDropdownContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  return (
    <StyleDropdown
      className="dropdown-actions"
      display="flex"
      justifyContent="flex-end"
      onClick={onClickDropdownContainer}
    >
      <DropDownAction
        placement="bottomRight"
        align={{ offset: [0, 13] }}
        disabled={!menuUUID}
        overlay={
          <Container>
            <Menu role="menu-more-options-menus" selectedKeys={[]}>
              <Menu.Item
                key="dropdown-item-publish-to-locations"
                icon={
                  <Icon
                    remixiconClass="ri-arrow-up-circle-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowPublish}
                onClick={showPublishConfirmation}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.menus.dropdown.publish.menu.to.locations.option",
                    defaultMessage: "Publish",
                  })}
                </Text>
              </Menu.Item>

              <Menu.Item
                key="dropdown-item-unpublish-to-locations"
                icon={
                  <Icon
                    remixiconClass="ri-arrow-down-circle-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowUnpublish}
                disabled={disableUnpublish}
                onClick={showUnpublishConfirmation}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.menus.dropdown.unpublish.menu.to.locations.option",
                    defaultMessage: "Unpublish",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                onClick={showDeleteConfirmation}
                key="dropdown-item-delete"
                icon={
                  <Icon
                    remixiconClass="ri-delete-bin-7-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowDelete}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.menus.dropdown.delete.menu.option",
                    defaultMessage: "Delete",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                onClick={onDeleteDraftClick}
                key="dropdown-item-delete-draft"
                icon={
                  <Icon
                    remixiconClass="ri-delete-bin-7-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowDeleteDraft}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.menus.dropdown.delete.menu.option",
                    defaultMessage: "Delete",
                  })}
                </Text>
              </Menu.Item>
            </Menu>
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-line"
          size={24}
          color={menuUUID ? "Primary5" : "Neutral5"}
          cursor="pointer"
        />
      </DropDownAction>
      <Suspense fallback={null}>
        {publishModalVisible && (
          <PublishMenuModal
            menuUUID={menuUUID}
            onOk={onPublishOk}
            onCancel={dismissPublishConfirmation}
            isCorporate={isCorporate}
          />
        )}
        {deleteModalVisible && (
          <DeleteMenuModal
            menuUUID={menuUUID}
            onCancel={dismissDeleteConfirmation}
            onOk={onDeleteOk}
            isCorporate={isCorporate}
          />
        )}
        {deleteDraftModalVisible && (
          <DeleteDraftMenuModal
            menuUUID={menuUUID}
            onCancel={dismissDeleteDraftConfirmation}
            onOk={onDeleteDraftOk}
          />
        )}
        {unpublishModalVisible && (
          <UnpublishMenuModal
            menuUUID={menuUUID}
            onOk={onUnpublishOk}
            onCancel={dismissUnpublishConfirmation}
            isCorporate={isCorporate}
          />
        )}
      </Suspense>
    </StyleDropdown>
  )
}

export default DropdownMenus

const StyleDropdown = styled(Container)`
  .anticon[disabled] {
    cursor: not-allowed;
  }
`
