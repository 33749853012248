import { toFormat } from "../../../../../../../shared/utils/currency/toFormat.dinero"
import Collapse, { CollapsePanel } from "../../../../../../../ui/Collapse"
import Container from "../../../../../../../ui/Container"
import InputLabel from "../../../../../../../ui/InputLabel"
import Spacer from "../../../../../../../ui/Spacer"
import Text from "../../../../../../../ui/Typography/Text"
import type { GetComboDetailQuery } from "../../../GraphQL/getComboDetail.generated"
import SingleItemCollapse from "../../../components/SingleItemCollapse"
import type { ComboItemType } from "../../../interfaces/hookforms.interfaces"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type ComboItemsDetailFormProps = {
  combo: GetComboDetailQuery["getComboDetail"]
}

const ComboItemsDetailForm: React.FC<ComboItemsDetailFormProps> = ({
  combo,
}) => {
  const intl = useIntl()

  return (
    <Container display="flex" flexDirection="column" gap="24px">
      <Container display="flex" flexDirection="column">
        <Container display="flex">
          <InputLabel
            label={intl.formatMessage({
              id: "restaurants.menu.items.combo.form.combo.items.label",
              defaultMessage: "Combo Items",
            })}
          />
        </Container>
        <Text size="s" weight="regular">
          {intl.formatMessage({
            id: "restaurants.menu.items.combo.form.combo.item.description",
            defaultMessage:
              "Here you could see the combination of the options you created before.",
          })}
        </Text>
      </Container>

      {combo.comboItemGroups.map((cmbItmGrp, index) => {
        const defaultComboItem = cmbItmGrp.comboItems.find(
          (comboItem) => comboItem.isDefault
        )
        const defaultComboItemPrice =
          defaultComboItem?.item.variants.find((variant) => variant.isDefault)
            ?.price ?? 0

        return (
          <StyledContainer key={`${cmbItmGrp.uuid}-${index}`}>
            <Collapse
              classId="menu-items-combo-item-group-form-collapse"
              contentBorderColor="Neutral4"
              removeHeaderBottomBorder
              contentBordered
              defaultActiveKey="combo-item-groups-form-panel"
            >
              <CollapsePanel
                style={{ margin: "0" }}
                key="combo-item-groups-form-panel"
                header={
                  <Container
                    cursor="default"
                    display="flex"
                    gap="20px"
                    justifyContent="space-between"
                    width="100%"
                    margin="-4px -8px"
                  >
                    <Container
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="48px"
                    >
                      <Text weight="regular" size="m" color="Neutral8">
                        {cmbItmGrp.name}
                      </Text>
                      <Container
                        display="flex"
                        alignItems="center"
                        gap="16px"
                        margin="0 20px 0 0"
                      >
                        <Text size="m" weight="regular" color="Neutral5">
                          {toFormat(defaultComboItemPrice)}
                        </Text>
                      </Container>
                    </Container>
                  </Container>
                }
              >
                <SubHeaderSection
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0 -16px"
                  padding="0 40px 16px 40px"
                >
                  <Container display="flex" flexDirection="column">
                    <Text size="m" weight="bold">
                      {intl.formatMessage({
                        id: "restaurants.menu.items.corporate.detail.form.item.groups.form.title",
                        defaultMessage: "Assigned Item",
                      })}
                    </Text>
                    <Spacer size={8} />
                    <Text size="s" weight="regular">
                      {intl.formatMessage({
                        id: "restaurants.menu.items.corporate.detail.form.item.groups.form.description",
                        defaultMessage: "Personalized product settings",
                      })}
                    </Text>
                  </Container>
                </SubHeaderSection>
                <Spacer size={8} />

                {cmbItmGrp.comboItems.map((comboItem, comboItemGroupIndex) => {
                  const title = intl.formatMessage(
                    {
                      id: "restaurants.menu.items.forms.combo.form.name.header",
                      defaultMessage:
                        "{name} ({quantity,plural, =1 {# variant} other {# variants}})",
                    },
                    {
                      name: comboItem.item.name,
                      quantity: Number(comboItem.item.variants?.length),
                    }
                  )

                  return (
                    <SingleItemCollapse
                      key={`${comboItem.uuid}-${comboItemGroupIndex}`}
                      comboItem={comboItem as ComboItemType}
                      isCurrentDefault={comboItem.isDefault}
                      isDetail={true}
                      isCorporate={false}
                      title={title}
                    />
                  )
                })}
              </CollapsePanel>
            </Collapse>
          </StyledContainer>
        )
      })}
    </Container>
  )
}

export default ComboItemsDetailForm

const SubHeaderSection = styled(Container)`
  border-radius: 4px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.Neutral4}`};
`

const StyledContainer = styled(Container)`
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-bottom: 0 !important;
  }
`
