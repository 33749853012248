import { device } from "../../../../shared/breakpoints/responsive"
import { getLocalTimezoneId } from "../../../../shared/utils/helpers/getTimezoneId"
import Button from "../../../../ui/Button"
import Container from "../../../../ui/Container"
import Icon from "../../../../ui/Icon"
import Tooltip from "../../../../ui/Tooltip"
import Text from "../../../../ui/Typography/Text"
import moment from "moment-timezone"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type MenuDetailHeaderProps = {
  lastSavedAt: Date | null
  lastPublishedAt: Date | null
  enablePreview: boolean
  enableSave: boolean
  enablePublish: boolean
  loadingSave: boolean
  loadingPublish: boolean
  actions?: React.ReactNode
  onSave: () => void
  onPublish: () => void
}

const ModalFullDetailHeader: React.FC<MenuDetailHeaderProps> = ({
  enablePreview,
  enablePublish,
  enableSave,
  loadingPublish,
  loadingSave,
  lastPublishedAt,
  lastSavedAt,
  onSave,
  onPublish,
  actions,
  children,
}) => {
  const intl = useIntl()

  const handlePreviewClick = () => {
    // TODO: Open preview in Storefront
    return
  }

  return (
    <Container
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap="16px"
    >
      {children}
      <StyledContainerMediaQuery display="flex" gap="16px" flexDirection="row">
        <Container display="flex" gap="16px" alignItems="center">
          {(!!lastSavedAt || !!lastPublishedAt) && (
            <Tooltip
              placement="bottom"
              title={
                <Container display="flex" flexDirection="column">
                  <div hidden={!lastSavedAt}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "Last saved {savedOn}",
                        id: "restaurants.components.modal.full.header.last.save.tooltip",
                      },
                      {
                        savedOn: moment
                          .tz(lastSavedAt, getLocalTimezoneId())
                          .calendar(),
                      }
                    )}
                  </div>

                  <div hidden={!lastPublishedAt}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "Last published {publishedOn}",
                        id: "restaurants.components.modal.full.header.last.published.tooltip",
                      },
                      { publishedOn: moment(lastPublishedAt).calendar() }
                    )}
                  </div>
                </Container>
              }
            >
              <Text size="m" weight="regular" color="Neutral5">
                {!!lastSavedAt &&
                  intl.formatMessage(
                    {
                      defaultMessage: "Last saved {lastSave}",
                      id: "restaurants.components.modal.full.header.last.save.label",
                    },
                    {
                      lastSave: moment
                        .tz(lastSavedAt, getLocalTimezoneId())
                        .fromNow(),
                    }
                  )}

                {!lastSavedAt &&
                  !!lastPublishedAt &&
                  intl.formatMessage(
                    {
                      defaultMessage: "Last published {lastPublished}",
                      id: "restaurants.components.modal.full.header.last.published.label",
                    },
                    { lastPublished: moment(lastPublishedAt).fromNow() }
                  )}
              </Text>
            </Tooltip>
          )}
        </Container>
        <Container display="flex" alignItems="center" gap="16px">
          {enablePreview ? (
            <Icon
              remixiconClass="ri-computer-line"
              size={24}
              color={"Primary5"}
              onClick={handlePreviewClick}
              cursor="pointer"
            />
          ) : (
            <Icon
              remixiconClass="ri-computer-line"
              size={24}
              color="Neutral5"
            />
          )}
          <Button
            hierarchy="secondary"
            title={intl.formatMessage({
              id: "restaurants.components.modal.full.header.save.button",
              defaultMessage: "Save",
            })}
            onClick={onSave}
            disabled={!enableSave}
            loading={loadingSave}
            data-testid="header-save-button"
          />
          <Button
            hierarchy="primary"
            title={intl.formatMessage({
              id: "restaurants.components.modal.full.header.publish.button",
              defaultMessage: "Publish",
            })}
            onClick={onPublish}
            disabled={!enablePublish}
            loading={loadingPublish}
            data-testid="header-publish-button"
          />
          {actions}
        </Container>
      </StyledContainerMediaQuery>
    </Container>
  )
}

export default ModalFullDetailHeader

const StyledContainerMediaQuery = styled(Container)`
  @media ${device.md} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
  }
`
