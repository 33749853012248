import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnpublishModifierGroupMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type UnpublishModifierGroupMutation = { __typename?: 'Mutation', unpublishModifierGroup: { __typename?: 'RBasicEntityData', name: string, uuid: string } };


export const UnpublishModifierGroupDocument = gql`
    mutation unpublishModifierGroup($uuid: ID!) {
  unpublishModifierGroup(uuid: $uuid) {
    name
    uuid
  }
}
    `;
export type UnpublishModifierGroupMutationFn = Apollo.MutationFunction<UnpublishModifierGroupMutation, UnpublishModifierGroupMutationVariables>;

/**
 * __useUnpublishModifierGroupMutation__
 *
 * To run a mutation, you first call `useUnpublishModifierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishModifierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishModifierGroupMutation, { data, loading, error }] = useUnpublishModifierGroupMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnpublishModifierGroupMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishModifierGroupMutation, UnpublishModifierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishModifierGroupMutation, UnpublishModifierGroupMutationVariables>(UnpublishModifierGroupDocument, options);
      }
export type UnpublishModifierGroupMutationHookResult = ReturnType<typeof useUnpublishModifierGroupMutation>;
export type UnpublishModifierGroupMutationResult = Apollo.MutationResult<UnpublishModifierGroupMutation>;
export type UnpublishModifierGroupMutationOptions = Apollo.BaseMutationOptions<UnpublishModifierGroupMutation, UnpublishModifierGroupMutationVariables>;