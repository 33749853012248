import { setDecimals } from "../../ui/Inputs/InputNumber/utils/get-decimals.util"
import type {
  VariantModifierGroupWithVariantModifierModel,
  VariantModifierWithIngredientModel,
  VariantOptionModel,
} from "./generated/types"
import { ApolloLink } from "@apollo/client"
import isNumber from "lodash/isNumber"

const precision = 2

const queries = {
  GET_VARIANTS_BY_ITEM: "getVariantsByItem",
  ADD_MODIFIER_GROUP_TO_VARIANT: "addModifierGroupToVariant",
  UPDATE_VARIANT: "updateVariant",
  DUPLICATE_VARIANT: "duplicateVariant",
}

const priceFormatted = (price?: number | null) => {
  return isNumber(price) ? setDecimals(price.toString(), precision) : null
}

export const priceFormatLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (operation.operationName === queries.GET_VARIANTS_BY_ITEM) {
      response.data = {
        ...response.data,
        getVariantsByItem: response?.data?.getVariantsByItem.map(
          ({ price, variantModifierGroups, ...rest }: VariantOptionModel) => {
            const variantModifierG = variantModifierGroups?.map(
              ({ variantModifiers, ...variantModifierGroupRest }) => {
                const variantModif = variantModifiers?.map(
                  ({ price: variantModifierPrice, ...variantModifierRest }) => {
                    return {
                      ...variantModifierRest,
                      price: priceFormatted(variantModifierPrice),
                    }
                  }
                )

                return {
                  ...variantModifierGroupRest,
                  variantModifiers: variantModif,
                }
              }
            )

            return {
              ...rest,
              variantModifierGroups: variantModifierG,
              price: priceFormatted(price),
            }
          }
        ),
      }
    }

    if (operation.operationName === queries.ADD_MODIFIER_GROUP_TO_VARIANT) {
      response.data = {
        ...response.data,
        addModifierGroupToVariant: {
          ...response?.data?.addModifierGroupToVariant,
          variantModifiers:
            response?.data?.addModifierGroupToVariant?.variantModifiers.map(
              ({ price, ...rest }: VariantModifierWithIngredientModel) => {
                return {
                  ...rest,
                  price: priceFormatted(price),
                }
              }
            ),
        },
      }
    }

    if (operation.operationName === queries.UPDATE_VARIANT) {
      response.data = {
        ...response.data,
        updateVariant: {
          ...response?.data?.updateVariant,
          price: priceFormatted(response?.data?.updateVariant.price),
        },
      }
    }

    if (operation.operationName === queries.DUPLICATE_VARIANT) {
      response.data = {
        ...response.data,
        duplicateVariant: {
          ...response?.data?.duplicateVariant,
          price: priceFormatted(response?.data?.duplicateVariant.price),
          variantModifierGroups:
            response?.data?.duplicateVariant?.variantModifierGroups?.map(
              ({
                variantModifiers,
                ...rest
              }: VariantModifierGroupWithVariantModifierModel) => {
                const variantModif = variantModifiers?.map(
                  ({ price, ...variantModifierRest }) => {
                    return {
                      ...variantModifierRest,
                      price: priceFormatted(price),
                    }
                  }
                )

                return {
                  ...rest,
                  variantModifiers: variantModif,
                }
              }
            ),
        },
      }
    }

    return response
  })
})
