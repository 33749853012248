import { OrderActionTypeEnum } from "../../../../shared/graphql/generated/types"
import { IconCircle } from "@tabler/icons-react"
import { ReactNode } from "react"

//TODO: Update this mapping when the backend team provides the correct label for each action type
export const mappingActionTypes: Record<
  OrderActionTypeEnum,
  { id: string; defaultMessage: string; icon?: ReactNode; color?: string }
> = {
  [OrderActionTypeEnum.IN_REVISION]: {
    id: "restaurants.catering.detail.card.timeline.item.in.revision.label",
    defaultMessage: "In Revision",
  },
  [OrderActionTypeEnum.PLACED]: {
    id: "restaurants.catering.detail.card.timeline.item.placed.label",
    defaultMessage: "Placed",
    icon: <IconCircle color="#FCC419" fill="#FCC419" size={8} />,
  },
  [OrderActionTypeEnum.COMPLETED]: {
    id: "restaurants.catering.detail.card.timeline.item.completed.label",
    defaultMessage: "Completed",
    color: "green.6",
  },
  [OrderActionTypeEnum.REFUNDED]: {
    id: "restaurants.catering.detail.card.timeline.item.refunded.label",
    defaultMessage: "Refunded",
  },
  [OrderActionTypeEnum.CANCELLED]: {
    id: "restaurants.catering.detail.card.timeline.item.cancelled.label",
    defaultMessage: "Cancelled",
    color: "red.6",
  },
  [OrderActionTypeEnum.PAYMENT_PENDING]: {
    id: "restaurants.catering.detail.card.timeline.item.payment.pending.label",
    defaultMessage: "Payment Pending",
  },
  [OrderActionTypeEnum.PENDING_REASSIGN]: {
    id: "restaurants.catering.detail.card.timeline.item.pending.reassign.label",
    defaultMessage: "Pending Reassign",
  },
  [OrderActionTypeEnum.PENDING]: {
    id: "restaurants.catering.detail.card.timeline.item.pending.label",
    defaultMessage: "Pending",
  },
  [OrderActionTypeEnum.CHECK_OUT]: {
    id: "restaurants.catering.detail.card.timeline.item.checkout.label",
    defaultMessage: "Check Out",
  },
  [OrderActionTypeEnum.PICKED_UP]: {
    id: "restaurants.catering.detail.card.timeline.item.pickedup.label",
    defaultMessage: "Picked Up",
  },
  [OrderActionTypeEnum.READY]: {
    id: "restaurants.catering.detail.card.timeline.item.ready.label",
    defaultMessage: "Ready",
  },
  [OrderActionTypeEnum.PREPARING]: {
    id: "restaurants.catering.detail.card.timeline.item.preparing.label",
    defaultMessage: "Preparing",
  },
  [OrderActionTypeEnum.CLOSED]: {
    id: "restaurants.catering.detail.card.timeline.item.closed.label",
    defaultMessage: "Closed",
  },
  [OrderActionTypeEnum.OPEN]: {
    id: "restaurants.catering.detail.card.timeline.item.open.label",
    defaultMessage: "Open",
  },
  [OrderActionTypeEnum.DELIVERY_FAILED]: {
    id: "restaurants.catering.detail.card.timeline.item.delivery.failed.label",
    defaultMessage: "Delivery Failed",
  },
  [OrderActionTypeEnum.ESTIMATED_TIME_ARRIVAL]: {
    id: "restaurants.catering.detail.card.timeline.item.estimated.time.arrival.label",
    defaultMessage: "Estimated Time Arrival",
  },
  [OrderActionTypeEnum.PENDING_CAPTURE]: {
    id: "restaurants.catering.detail.card.timeline.item.pending.capture.label",
    defaultMessage: "Pending Capture",
  },
  [OrderActionTypeEnum.PAYMENT_SUCCEEDED]: {
    id: "restaurants.catering.detail.card.timeline.item.payment.succeeded.label",
    defaultMessage: "Payment Succeeded",
  },
  [OrderActionTypeEnum.PAYMENT_FAILED]: {
    id: "restaurants.catering.detail.card.timeline.item.payment.failed.label",
    defaultMessage: "Payment Failed",
  },
  [OrderActionTypeEnum.CANCELLED_PAYMENT_INTENT]: {
    id: "restaurants.catering.detail.card.timeline.item.cancelled.payment.intent.label",
    defaultMessage: "Cancelled Payment Intent",
  },
  [OrderActionTypeEnum.COMMENT]: {
    id: "restaurants.catering.detail.card.timeline.item.comment.label",
    defaultMessage: "Comment",
  },
  [OrderActionTypeEnum.PARTIAL_PAID]: {
    id: "restaurants.catering.detail.card.timeline.item.partial.paid.label",
    defaultMessage: "Partial Paid",
  },
  [OrderActionTypeEnum.PAYMENT_AWAITING]: {
    id: "restaurants.catering.detail.card.timeline.item.payment.awaiting.label",
    defaultMessage: "Payment Awaiting",
  },
  [OrderActionTypeEnum.ORDER_REASSIGNED]: {
    id: "restaurants.catering.detail.card.timeline.item.order.reassigned.label",
    defaultMessage: "Order Reassigned",
  },
}
