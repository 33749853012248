import { themeColors } from "../../../../../shared/theme"
import {
  IconClockHour4,
  IconCoins,
  IconCurrencyDollar,
  IconPlayerStop,
} from "@tabler/icons-react"
import { ComponentType } from "react"

export enum TagEnum {
  DEFAULT = "DEFAULT",
  RED = "RED",
  AMOUNT = "AMOUNT",
  TIME = "TIME",
  TIP = "TIP",
  ELAPSED = "ELAPSED",
}

type IconProps = { color: string; size: number }

type TagConfigType = Record<
  keyof typeof TagEnum,
  {
    background: string
    textColor: string
    icon?: ComponentType<IconProps>
  }
>

export const TagConfig: TagConfigType = {
  DEFAULT: {
    background: "gray.1",
    textColor: themeColors.gray[6],
  },
  RED: {
    background: "red.0",
    textColor: themeColors.red[7],
  },
  AMOUNT: {
    background: "primary.0",
    textColor: themeColors.primary[6],
    icon: IconCurrencyDollar,
  },
  TIME: {
    background: "gray.0",
    textColor: themeColors.gray[6],
    icon: IconClockHour4,
  },
  TIP: {
    background: "gray.0",
    textColor: themeColors.gray[6],
    icon: IconCoins,
  },
  ELAPSED: {
    background: "green.0",
    textColor: themeColors.green[6],
    icon: IconPlayerStop,
  },
}
