import Container from "../../Container"
import React from "react"
import styled from "styled-components"

export const PageWrapper: React.FC = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

const StyledWrapper = styled(Container)`
  background-color: ${({ theme }) => ` ${theme.colors["Neutral2"]}`};
  min-height: calc(100vh - 48px);
`
