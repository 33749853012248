import { motion } from "framer-motion"
import type { AnimationEventHandler, ReactElement } from "react"
import React from "react"
import styled from "styled-components"

const containerVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
      when: "afterChildren",
      staggerDirection: -1,
    },
  },
}

const childVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
}

type SliderProps = {
  firstChildProps?: AnimationEventHandler<HTMLDivElement>
  secondChildProps?: AnimationEventHandler<HTMLDivElement>
  children: ReactElement[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  offsetY?: number
  originY?: number
  initial?: "closed" | "open"
}

export const Slider: React.FC<SliderProps> = (props) => {
  const {
    children,
    isOpen,
    setIsOpen,
    firstChildProps,
    secondChildProps,
    originY = 0,
    offsetY = -50,
  } = props
  const [firstChild, secondChild, ...restChildren] = children

  return (
    <StyledMotionDiv
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      variants={containerVariants}
    >
      <StyledMotionDiv
        initial={{ opacity: 0 }}
        variants={childVariants}
        {...firstChildProps}
      >
        {firstChild}
      </StyledMotionDiv>

      <StyledMotionDiv
        initial={{ y: offsetY }}
        animate={{ y: isOpen ? originY : offsetY }}
        onClick={() => setIsOpen(!isOpen)}
        {...secondChildProps}
      >
        {secondChild}
      </StyledMotionDiv>
      <StyledMotionDiv
        initial={{ y: offsetY }}
        animate={{ y: isOpen ? originY : offsetY }}
      >
        {restChildren}
      </StyledMotionDiv>
    </StyledMotionDiv>
  )
}

const StyledMotionDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
