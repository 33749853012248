import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME,
  SET_VALUE_OPTIONS,
} from "../../constants"
import { useFormContext } from "react-hook-form"

export const useLifeTimeSpend = () => {
  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext<IDiscountForm>()

  const isEditMode = getValues(EDIT_MODE_NAME)

  const errorMessage = errors.rules?.eligibleCustomer?.spend?.message

  const onLifeTimeSpendChange = (value: number | null) => {
    setValue(ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME, value, SET_VALUE_OPTIONS)
  }

  const lifeTimeSpendAmount =
    watch(ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME) ?? undefined

  return {
    isEditMode,
    lifeTimeSpendAmount,
    errorMessage,
    onLifeTimeSpendChange,
  }
}
