import { OrderActionTypeEnum } from "../../../../../shared/graphql/generated/types"
import { useGetOrderSummaryQuery } from "../../../Orders/OrderDetail/GraphQL/getOrderSummary.generated"
import { useGetCateringOrderDetailsQuery } from "../../Graphql/getCateringOrderDetail.generated"
import { getOrderSummary } from "../../utils/get-summary"
import { Comments } from "./Comments"
import { DangerZone } from "./DangerZone"
import { OrderManagement } from "./OrderManagement"
import { Status } from "./Status"
import { Timeline } from "./Timeline"
import { Stack } from "@mantine/core"
import { useParams } from "react-router-dom"

export const Summary = () => {
  const { orderUUID, locationUUID } =
    useParams<{ orderUUID: string; locationUUID: string }>()

  const { data: orderSummaryData } = useGetOrderSummaryQuery({
    variables: {
      uuid: orderUUID ?? "",
      ...(!!locationUUID && { locationUUID }),
    },
  })
  const { data } = useGetCateringOrderDetailsQuery({
    variables: {
      orderUUID,
      ...(!!locationUUID && { locationUUID }),
    },
    fetchPolicy: "cache-only",
  })

  const refundable = data?.getOrderDetails.isAvailableRefund
  const cancelable = data?.getOrderDetails.isAvailableCancel

  const orderSummary = getOrderSummary(orderSummaryData?.getOrderSummary)

  if (!orderSummary) {
    return null
  }

  const orderActions = orderSummary.orderActions.map((action) => ({
    actionType: action.actionType,
    actionAt: action.actionAt,
  }))

  const actions = new Set(orderActions.map((action) => action.actionType))

  const isCancelledOrSuccess =
    actions.has(OrderActionTypeEnum.CANCELLED) ||
    actions.has(OrderActionTypeEnum.PLACED) ||
    actions.has(OrderActionTypeEnum.COMPLETED)

  return (
    <Stack spacing={24}>
      <Status orderActions={orderActions} />
      <Comments
        comments={data?.getOrderDetails.comments ?? []}
        orderUUID={orderUUID}
      />
      <Timeline orderActions={orderActions} />
      <DangerZone refundable={refundable} cancelable={cancelable} />
      {!isCancelledOrSuccess && <OrderManagement actions={actions} />}
    </Stack>
  )
}
