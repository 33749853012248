import CopyToClipboardButton from "../../../../../../../ui/CopyToClipboardButton"
import Text from "../../../../../../../ui/Typography/Text"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface TechInfoItemProps {
  title: string
  content: string
}

const TechInfoItem = ({ title, content }: TechInfoItemProps) => {
  const intl = useIntl()

  const genericCopyInfoLabel = intl.formatMessage({
    id: "restaurants.home.tech.info.item.button.label.copy",
    defaultMessage: "Copy info",
  })

  const genericCopiedInfoLabel = intl.formatMessage({
    id: "restaurants.home.tech.info.item.button.label.copied",
    defaultMessage: "info copied",
  })

  return (
    <Container>
      <div>
        <Text size="m" weight="bold">
          {title}
        </Text>
        <Text size="s">{content}</Text>
      </div>
      <CopyToClipboardButton
        content={content}
        copyTextLabel={genericCopyInfoLabel}
        copiedTextLabel={`${title} ${genericCopiedInfoLabel}`}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default TechInfoItem
