import { useGeneralContext } from "../../contexts/StoreProvider"
import RedirectRoute from "../RedirectRoute"
import paths from "../paths"
import React from "react"
import type { RouteProps } from "react-router"
import { Route } from "react-router"

export type ProtectedRouteProps = RouteProps

export const ProtectedOnboardingRoute: React.FC<ProtectedRouteProps> = ({
  children,
  component,
  ...props
}) => {
  const {
    state: {
      auth: {
        admin: { isOnboardingCompleted },
      },
    },
  } = useGeneralContext()

  if (!isOnboardingCompleted) {
    return <RedirectRoute {...props} pathname={paths.onboarding.root} />
  }

  return (
    <Route {...props} component={component}>
      {children}
    </Route>
  )
}
