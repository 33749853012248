import { useFilters } from "../../../../shared/contexts/FilterProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import type { OrderInformationModel } from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import { YYYY_MM_DD } from "../../../../shared/utils/constant/dateFormats"
import safeNumber from "../../../../shared/utils/helpers/safeNumber"
import Button from "../../../../ui/Button"
import Container from "../../../../ui/Container"
import DataTable from "../../../../ui/DataTable"
import EmptyState from "../../../../ui/EmptyState"
import FilterByDate from "../../../../ui/Header/Filter/FilterByDate"
import ModalFull from "../../../../ui/ModalFull"
import type { IModalFullProps } from "../../../../ui/ModalFull/ModalFull"
import PageTitle from "../../../../ui/PageTitle"
import Spacer from "../../../../ui/Spacer"
import Text from "../../../../ui/Typography/Text"
import type { GetOrdersByRestaurantQuery } from "../../Orders/GraphQL/getOrdersByRestaurant.generated"
import { useGetOrdersByRestaurantQuery } from "../../Orders/GraphQL/getOrdersByRestaurant.generated"
import type { IOrderRowModel } from "../../Orders/datatable.interfaces"
import CustomerInformation from "./CustomerInformation"
import { useGetCustomerQuery } from "./graphql/getCustomer.generated"
import type { ICustomerDetailParams } from "./hookforms.interfaces"
import customerOrdersTableConfig from "./utils/customer-orders-list.util"
import { Result } from "antd"
import get from "lodash/get"
import moment from "moment-timezone"
import React, { useCallback, useEffect } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

export const CustomerDetail: React.FC<IModalFullProps> = ({ onCancel }) => {
  const intl = useIntl()
  const { push, goBack } = useHistory()
  const { restaurants } = paths
  const { userUUID, locationUUID } = useParams<ICustomerDetailParams>()

  const { dates, updateDateFilters, getDatesQueryFilters } = useFilters()
  const getDatesQueryFilter = getDatesQueryFilters()

  const [columns] = customerOrdersTableConfig()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const { data: customerInfo, error: customerError } = useGetCustomerQuery({
    variables: {
      restaurantUUID: currentRestaurantUUID,
      userUUID,
    },
    skip: !userUUID,
    fetchPolicy: "no-cache",
  })

  const customerData = get(customerInfo, "getCustomer", undefined)

  const {
    data,
    error: getOrdersError,
    fetchMore,
    loading: orderDetailLoading,
    refetch,
  } = useGetOrdersByRestaurantQuery({
    variables: {
      restaurantUUID: currentRestaurantUUID,
      ...getDatesQueryFilter,
      startDate: moment(getDatesQueryFilters().startDate)
        .set("hour", safeNumber("00"))
        .set("minute", safeNumber("00"))
        .toISOString(true),
      endDate: moment(getDatesQueryFilters().endDate)
        .set("hour", safeNumber("23"))
        .set("minute", safeNumber("59"))
        .toISOString(true),
      ...(!!locationUUID && {
        locationUUIDs: [locationUUID],
      }),
      ...(!!locationUUID && {
        locationUUIDs: [locationUUID],
      }),
      ...(!!userUUID && {
        userUUIDs: [userUUID],
      }),
    },

    skip: !currentRestaurantUUID,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  })

  const customerOrdersList: IOrderRowModel[] = get(
    data,
    "getOrdersByRestaurant.results",
    []
  )
  const hasNextPage: boolean = get(
    data,
    "getOrdersByRestaurant.hasNextPage",
    false
  )
  const endCursor = get(data, "getOrdersByRestaurant.endCursor", undefined)

  const fetchMoreOrders = useCallback(async () => {
    await fetchMore({
      variables: { currentRestaurantUUID, after: endCursor },
      updateQuery: (prev: GetOrdersByRestaurantQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getOrdersByRestaurant?.endCursor ===
            fetchMoreResult?.getOrdersByRestaurant?.endCursor
        ) {
          return prev
        }

        return Object.assign({}, prev, {
          getOrdersByRestaurant: {
            ...fetchMoreResult.getOrdersByRestaurant,
            results: [
              ...(prev.getOrdersByRestaurant
                ?.results as Array<OrderInformationModel>),
              ...(fetchMoreResult.getOrdersByRestaurant
                ?.results as Array<OrderInformationModel>),
            ],
          },
        })
      },
    })
  }, [currentRestaurantUUID, endCursor, fetchMore])

  const onCustomerRowClick = (order: IOrderRowModel) => {
    const { uuid } = order

    return {
      onClick: () => {
        push(paths.restaurants.orderDetail(uuid))
      },
    }
  }

  const retry = () => refetch()

  const setDefaultDate = useCallback(() => {
    const date = [
      moment(getDatesQueryFilter.startDate).format(YYYY_MM_DD),
      moment(getDatesQueryFilter.endDate).format(YYYY_MM_DD),
    ]

    updateDateFilters({ "start-date": date[0], "end-date": date[1] })
  }, [
    getDatesQueryFilter.endDate,
    getDatesQueryFilter.startDate,
    updateDateFilters,
  ])

  useEffect(() => {
    if (!dates["start-date"] && !dates["end-date"]) {
      setDefaultDate()
    }
  }, [dates, setDefaultDate])

  if (customerError) {
    return (
      <Container position="relative" centered fullScreen>
        <Result
          status="403"
          subTitle={customerError?.message}
          extra={
            <Container centered>
              <Button
                hierarchy="secondary"
                title={intl.formatMessage({
                  id: "generic.error.page.retry",
                  defaultMessage: "Retry",
                })}
              />
            </Container>
          }
        />
      </Container>
    )
  }

  return (
    <ModalFull
      id="customer-detail-modal"
      goBackPath={
        locationUUID
          ? restaurants.locations.ordersByLocation(locationUUID)
          : restaurants.orders
      }
      title={
        <Text size="m" weight="bold">
          {customerData?.firstName}
        </Text>
      }
      onCancel={onCancel ?? goBack}
      visible
      closable
    >
      <Container role="customer-detail-table">
        <PageTitle
          title={intl.formatMessage({
            id: "restaurants.customers.customer.detail.seaction.header",
            defaultMessage: "Customer Information",
          })}
          description={intl.formatMessage({
            id: "restaurants.customers.customer.detail.seaction.description",
            defaultMessage:
              "Here you can see all the details of a customer regarding their purchase history and contact info",
          })}
        />

        <CustomerInformation customerInformation={customerData} />

        <Spacer size={12} />

        <Container display="flex" justifyContent="right">
          <FilterByDate />
        </Container>
        <Spacer size={12} />

        <DataTable
          dataSource={customerOrdersList}
          columns={columns}
          loading={orderDetailLoading}
          hasNextPage={hasNextPage}
          fetchMore={fetchMoreOrders}
          onRow={onCustomerRowClick}
          background="Neutral0"
          error={getOrdersError}
          errorRefetch={retry}
          emptyMessage={
            <EmptyState
              title={intl.formatMessage({
                id: "restaurants.customers.customer.detail.empty.title",
                defaultMessage: "No orders found",
              })}
              description={intl.formatMessage({
                id: "restaurants.customers.customer.detail.empty.description",
                defaultMessage: "You will see your customer’s orders here",
              })}
            />
          }
        />
      </Container>
    </ModalFull>
  )
}
