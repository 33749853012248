import moment from "moment-timezone"

export const convertToDate = (date: string, time: string) => {
  const newDate = moment(date)
  const year = newDate.year()
  const month = newDate.month()
  const day = newDate.date()

  return moment([year, month, day, 0, 0, 0]).add(moment.duration(time))
}
