import type { OrderStatusEnum } from "../../../../shared/graphql/generated/types"
import { colors } from "../../../../styles/global/themes/mainTheme"

const getBadgeColor = (status: `${OrderStatusEnum}`) => {
  switch (status) {
    case "CANCELLED": {
      return colors["Danger5"]
    }

    case "CHECK_OUT": {
      return colors["Secondary4"]
    }

    case "CLOSED": {
      return colors["Neutral4"]
    }

    case "COMPLETED": {
      return colors["Success5"]
    }

    case "OPEN": {
      return colors["Primary5"]
    }

    case "PENDING": {
      return colors["Warning5"]
    }

    case "PREPARING": {
      return colors["Warning5"]
    }

    case "PAYMENT_PENDING": {
      return colors["Warning3"]
    }
    default: {
      return colors["Primary3"]
    }
  }
}

export default getBadgeColor
