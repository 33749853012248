import Button from "../../ui/Button"
import classnames from "classnames"
import React from "react"

type FormProps = {
  onSubmit: () => void
  buttonTitle?: string
  buttonWrapperClassName?: string
  withButton?: boolean
  isLoading?: boolean
  isDisabled?: boolean
}

export const Form: React.FC<FormProps> = ({
  children,
  buttonTitle,
  buttonWrapperClassName,
  withButton,
  isLoading,
  isDisabled,
  ...props
}) => {
  return (
    <form {...props}>
      {children}

      {withButton && (
        <div
          className={classnames("form-button-wrapper", buttonWrapperClassName)}
        >
          <Button
            className="form-button"
            type="submit"
            size="large"
            title={buttonTitle}
            disabled={isDisabled}
            loading={isLoading}
            width="400px"
          />
        </div>
      )}
    </form>
  )
}

Form.defaultProps = {
  withButton: true,
}
