import { colors } from "../../styles/global/themes/mainTheme"
import { useState } from "react"

export interface QRConfig {
  colorDark: string
  colorLight: string
  dotScale: number
  PO_TL: string
  PO_TR: string
  PO_BL: string
  PI_TL: string
  PI_TR: string
  PI_BL: string
  dotScaleTiming_V: number
  dotScaleTiming_H: number
  dotScaleAO: number
  dotScaleAI: number
}

export const useQRStyleConfig = () => {
  const initialDark = colors["Neutral9"]
  const initialLight = colors["Neutral1"]

  const [config, setConfig] = useState<QRConfig>({
    colorDark: initialDark.slice(0, 7),
    colorLight: initialLight.slice(0, 7),
    dotScale: 1,
    PO_TL: initialDark.slice(0, 7),
    PO_TR: initialDark.slice(0, 7),
    PO_BL: initialDark.slice(0, 7),
    PI_TL: initialDark.slice(0, 7),
    PI_TR: initialDark.slice(0, 7),
    PI_BL: initialDark.slice(0, 7),
    dotScaleTiming_V: 1,
    dotScaleTiming_H: 1,
    dotScaleAO: 1,
    dotScaleAI: 1,
  })

  return { config, setConfig }
}
