import { IntlFormattersType } from "../../../../../../shared/types"

export const ERROR_USAGE_LIMIT_PER_CUSTOMER_ID =
  "restaurants.discounts.forms.rules.usage.limit.per.customer.exceed.error.message"

export const ERROR_INPUT_SHOULD_BE_POSITIVE_ID =
  "restaurants.discounts.forms.rules.usage.limit.input.should.be.positive.error.message"

export const validationsTitle: IntlFormattersType = {
  [ERROR_USAGE_LIMIT_PER_CUSTOMER_ID]: {
    id: ERROR_USAGE_LIMIT_PER_CUSTOMER_ID,
    defaultMessage: "Must not exceed the limited amount of uses",
  },
  [ERROR_INPUT_SHOULD_BE_POSITIVE_ID]: {
    id: ERROR_INPUT_SHOULD_BE_POSITIVE_ID,
    defaultMessage: "This field must be a positive number",
  },
}
