import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrderMutationVariables = Types.Exact<{
  data: Types.UpdateOrderInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'OrderWithActionsModel', uuid: string, status: Types.OrderStatusEnum, orderNumber: string, orderMethod: Types.OrderMethodEnum, subTotal: number, total: number, notes?: string | null, locationTable?: { __typename?: 'TableBasicModel', uuid: string, name: string } | null, orderActions: Array<{ __typename?: 'OrderActionModel', uuid: string, actionAt: any, actionType: Types.OrderActionTypeEnum, admin?: { __typename?: 'AdminBasicModel', uuid: string, firstName: string, lastName?: string | null } | null }> } };


export const UpdateOrderDocument = gql`
    mutation updateOrder($data: UpdateOrderInput!) {
  updateOrder(data: $data) {
    uuid
    status
    orderNumber
    orderMethod
    subTotal
    total
    notes
    locationTable {
      uuid
      name
    }
    orderActions {
      uuid
      actionAt
      actionType
      admin {
        uuid
        firstName
        lastName
      }
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;