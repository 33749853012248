import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVariantsByItemsQueryVariables = Types.Exact<{
  items: Array<Types.RuuidInput> | Types.RuuidInput;
}>;


export type GetVariantsByItemsQuery = { __typename?: 'Query', getVariantsByItems: Array<{ __typename?: 'RItemWithVariants', isMain: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name?: string | null, price: number, uuid: string, variants: Array<{ __typename?: 'RVariant', extraPrice: number, isDefault: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name: string, price: number, uuid: string, attachment?: { __typename?: 'AttachmentModel', signedUrl: string } | null }> }> };


export const GetVariantsByItemsDocument = gql`
    query getVariantsByItems($items: [RUUIDInput!]!) {
  getVariantsByItems(items: $items) {
    isMain
    isSoldOut
    isVisible
    name
    price
    uuid
    variants {
      attachment {
        signedUrl
      }
      extraPrice
      isDefault
      isSoldOut
      isVisible
      name
      price
      uuid
    }
  }
}
    `;

/**
 * __useGetVariantsByItemsQuery__
 *
 * To run a query within a React component, call `useGetVariantsByItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVariantsByItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVariantsByItemsQuery({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useGetVariantsByItemsQuery(baseOptions: Apollo.QueryHookOptions<GetVariantsByItemsQuery, GetVariantsByItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVariantsByItemsQuery, GetVariantsByItemsQueryVariables>(GetVariantsByItemsDocument, options);
      }
export function useGetVariantsByItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVariantsByItemsQuery, GetVariantsByItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVariantsByItemsQuery, GetVariantsByItemsQueryVariables>(GetVariantsByItemsDocument, options);
        }
export type GetVariantsByItemsQueryHookResult = ReturnType<typeof useGetVariantsByItemsQuery>;
export type GetVariantsByItemsLazyQueryHookResult = ReturnType<typeof useGetVariantsByItemsLazyQuery>;
export type GetVariantsByItemsQueryResult = Apollo.QueryResult<GetVariantsByItemsQuery, GetVariantsByItemsQueryVariables>;