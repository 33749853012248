import type { Library } from "@googlemaps/js-api-loader"
import { useLoadScript } from "@react-google-maps/api"
import { createContext, useContext, useMemo } from "react"

const GoogleMapsContext = createContext<{
  isLoaded: boolean
} | null>(null)

export const useGoogleMapsContext = () => {
  const context = useContext(GoogleMapsContext)
  if (context) return context
  else
    throw new Error(
      "useGoogleMapsContext must be used within a GoogleMapsProvider"
    )
}

type GoogleMapsProviderProps = {
  children: React.ReactNode
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({
  children,
}) => {
  const googleMapsApiKey = useMemo(() => {
    const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    if (!key) throw new Error("No Google Maps API KEY")
    return `${key}`
  }, [])

  const libraries: Library[] = useMemo(
    () => ["drawing", "geometry", "places"],
    []
  )

  const { isLoaded } = useLoadScript({ googleMapsApiKey, libraries })

  return (
    <GoogleMapsContext.Provider value={{ isLoaded }}>
      {children}
    </GoogleMapsContext.Provider>
  )
}
