import type {
  GetOrdersByRestaurantQuery,
  GetOrdersByRestaurantQueryVariables,
} from "../../GraphQL/getOrdersByRestaurant.generated"
import { GetOrdersByRestaurantDocument } from "../../GraphQL/getOrdersByRestaurant.generated"
import type {
  GetOrderDetailsQuery,
  GetOrderDetailsQueryVariables,
} from "../../OrderDetail/GraphQL/getOrderDetails.generated"
import { GetOrderDetailsDocument } from "../../OrderDetail/GraphQL/getOrderDetails.generated"
import { ApolloCache } from "@apollo/client"

type ReadCacheOrdersByRestaurantQuery = {
  cache: ApolloCache<unknown>
  variables: GetOrdersByRestaurantQueryVariables
}

type ReadGetOrderDetailsQuery = {
  cache: ApolloCache<unknown>
  variables: GetOrderDetailsQueryVariables
}

const readOrdersByRestaurantQueryCache = ({
  cache,
  variables,
}: ReadCacheOrdersByRestaurantQuery) =>
  cache.readQuery<
    GetOrdersByRestaurantQuery,
    GetOrdersByRestaurantQueryVariables
  >({
    query: GetOrdersByRestaurantDocument,
    variables,
  })

const readGetOrderDetailsQueryCache = ({
  cache,
  variables,
}: ReadGetOrderDetailsQuery) =>
  cache.readQuery<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>({
    query: GetOrderDetailsDocument,
    variables,
  })

export { readOrdersByRestaurantQueryCache, readGetOrderDetailsQueryCache }
