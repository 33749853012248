import type { GetItemListQuery } from "../../../GraphQL/Queries/getItemList.generated"
import type { ItemList } from "../generated/types"

export const updateGetItemListQuery = (
  prev: GetItemListQuery,
  fetchMoreResult: GetItemListQuery | undefined
) => {
  if (
    !fetchMoreResult ||
    prev?.getItemList?.endCursor === fetchMoreResult?.getItemList?.endCursor
  ) {
    return prev
  }

  return {
    getItemList: {
      ...fetchMoreResult.getItemList,
      results: [
        ...(prev.getItemList.results as Array<ItemList>),
        ...(fetchMoreResult.getItemList.results as Array<ItemList>),
      ],
    },
  }
}
