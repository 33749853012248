import { OrderMethodEnum } from "../../../../../../shared/graphql/generated/types"
import Container from "../../../../../../ui/Container"
import Spacer from "../../../../../../ui/Spacer"
import Text from "../../../../../../ui/Typography/Text"
import OrderActionContainer from "../../OrderActionContainer"
import OrderActionDivider from "../../OrderActionDivider"
import OrderAdditionalInfo from "../../OrderAdditionalInfo"
import OrderComment from "../../OrderComment"
import OrderCustomerInformation from "../../OrderCustomerInformation"
import DangerZone from "../../OrderDangerZone"
import OrderDeliveryService from "../../OrderDeliveryService"
import OrderInformation from "../../OrderInformation"
import { OrderStatus } from "../../OrderStatus/OrderStatus"
import { StatusArray } from "../../OrderStatus/config"
import OrderSummary from "../../OrderSummary"
import OrderTimeline from "../../OrderTimeline"
import type { OrderDetailModalForm } from "../hookforms.interfaces"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

type OrderDetailFormProps = {
  loadingDetail?: boolean
  loadingSummary?: boolean
}

export const OrderDetailForm: React.FC<OrderDetailFormProps> = ({
  loadingDetail,
}) => {
  const intl = useIntl()
  const { getValues, watch } = useFormContext<OrderDetailModalForm>()

  const orderDetail = getValues("orderDetail")
  const orderStatus = watch("orderDetail.status")

  return (
    <StyledMainContainer display="flex" flexDirection="column" width="100%">
      <Container display="flex" justifyContent="space-between">
        <StyledSectionFirstContainer>
          <Container>
            <OrderInformation loading={loadingDetail} />
            <Spacer size={32} />
            <OrderCustomerInformation loading={loadingDetail} />
            <Spacer size={32} />
            <OrderSummary loading={loadingDetail} />
            <Spacer size={32} />
            <OrderAdditionalInfo loading={loadingDetail} />
          </Container>
        </StyledSectionFirstContainer>
        <StyledDivider />
        <StyledSectionSecondContainer
          role="order-detail-content-right"
          display="flex"
          flexDirection="column"
        >
          {StatusArray.includes(orderStatus) && (
            <OrderStatus loading={loadingDetail} />
          )}
          {orderDetail?.orderMethod === OrderMethodEnum.DELIVERY && (
            <OrderActionDivider />
          )}
          {orderDetail?.orderMethod === OrderMethodEnum.DELIVERY && (
            <OrderDeliveryService />
          )}
          <OrderActionDivider />
          <OrderComment />
          <OrderActionDivider />
          <OrderActionContainer>
            <Text size="xl" weight="bold" color="Neutral6">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.order.timeline.title",
                defaultMessage: "Order Timeline",
              })}
            </Text>
          </OrderActionContainer>
          <OrderTimeline />
          <DangerZone />
        </StyledSectionSecondContainer>
      </Container>
    </StyledMainContainer>
  )
}

const StyledMainContainer = styled(Container)`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.colors.Neutral4};
    min-height: 100vh;
  `}
`

const StyledDivider = styled.div`
  ${({ theme }) => css`
    border-right: 2px solid ${theme.colors.Neutral4};
  `}
`

const StyledSectionFirstContainer = styled(Container)`
  width: 100%;
  height: 100%;
  min-width: 500px;
  padding: 24px 40px;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
`

const StyledSectionSecondContainer = styled(Container)`
  max-width: 534px;
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
`
