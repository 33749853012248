import { OrderComment } from "../../../../../../shared/graphql/generated/types"
import {
  isoStringDateFormat,
  isoStringHoursFormat,
} from "../../../../../../shared/utils/constant/dateFormats"
import { MantineCard } from "../../../../../../ui/MantineCard"
import { useCreateCateringCommentMutation } from "../../../Graphql/createCateringComment.generated"
import { CreateCateringCommentForm } from "../../../hookform/hookforms.interface"
import { CreateCateringCommentResolver } from "../../../hookform/schemas"
import { addCommentCateringOrderCacheUpdate } from "../../../utils/cache/order-catering-cache.util"
import styles from "./Comments.module.css"
import {
  Accordion,
  Button,
  Flex,
  Grid,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import moment from "moment-timezone"
import { SubmitHandler, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type Props = {
  orderUUID: string
  comments: OrderComment[]
}
export const Comments = ({ comments, orderUUID }: Props) => {
  const intl = useIntl()

  const {
    register,
    formState: { isValid },
    reset,
    handleSubmit,
  } = useForm<CreateCateringCommentForm>({
    resolver: CreateCateringCommentResolver,
    mode: "all",
  })
  const [createComment] = useCreateCateringCommentMutation()
  const onSubmitComment: SubmitHandler<CreateCateringCommentForm> = (value) => {
    createComment({
      variables: {
        input: {
          comment: value.comment,
          order: {
            uuid: orderUUID,
          },
        },
      },
      update: (cache, result) => {
        if (result.data) {
          addCommentCateringOrderCacheUpdate(cache, result, {
            variables: {
              input: {
                comment: value.comment,
                order: {
                  uuid: orderUUID,
                },
              },
            },
          })
        }
      },
      onCompleted: () => {
        reset()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmitComment)}>
      <MantineCard
        title={intl.formatMessage({
          id: "restaurants.catering.detail.card.comments.title",
          defaultMessage: "Comments",
        })}
      >
        <Stack spacing={24}>
          <Grid>
            <Grid.Col span="auto">
              <TextInput
                {...register("comment")}
                w="100%"
                placeholder={intl.formatMessage({
                  id: "restaurants.catering.detail.card.comments.input.placeholder",
                  defaultMessage: "Type your comments here",
                })}
              />
            </Grid.Col>
            <Grid.Col span="content">
              <Button disabled={!isValid} type="submit">
                {intl.formatMessage({
                  id: "restaurants.catering.detail.card.comments.button.label",
                  defaultMessage: "Add",
                })}
              </Button>
            </Grid.Col>
          </Grid>
          <Accordion
            disableChevronRotation={comments.length === 0}
            classNames={{ content: styles["accordion-content"] }}
            defaultValue="comments"
          >
            <Accordion.Item value="comments" style={{ border: "none" }}>
              <Accordion.Control className={styles["accordion-control"]}>
                {intl.formatMessage(
                  {
                    id: "restaurants.catering.detail.card.comments.accordion.control.title",
                    defaultMessage: "View comments ({quantity})",
                  },
                  {
                    quantity: comments.length,
                  }
                )}
              </Accordion.Control>
              <Accordion.Panel>
                {comments.map((commentItem, index) => (
                  <Flex
                    gap={8}
                    py={8}
                    direction={"column"}
                    key={commentItem.uuid}
                    className={
                      index === comments.length - 1
                        ? undefined
                        : styles["accordion-bordered"]
                    }
                  >
                    <Text c={"gray.7"} size={"sm"}>
                      {commentItem.content}
                    </Text>
                    <Text c={"gray.6"} ta={"end"} size={"xs"}>
                      {moment(commentItem.createdAt).format(
                        `${isoStringDateFormat} ${isoStringHoursFormat}`
                      )}
                    </Text>
                  </Flex>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
      </MantineCard>
    </form>
  )
}
