/* eslint-disable unicorn/no-thenable */
import yup from "../../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const CorporateItemFormSchema = yup.object().shape({
  variants: yup.array().of(
    yup
      .object()
      .shape({
        price: yup.number().min(0).required(),
        variantModifierGroups: yup.array().of(
          yup.object().shape({
            variantModifiers: yup.array().of(
              yup.object().shape({
                price: yup.number().min(0).required(),
              })
            ),
          })
        ),
        isVisible: yup.boolean().when("isDefault", {
          is: true,
          then: yup.boolean().isTrue("Keep default variant visible"),
        }),
        isSoldOut: yup.boolean().when("isDefault", {
          is: true,
          then: yup.boolean().isFalse("Keep default variant in stock"),
        }),
      })
      .required()
  ),
})

export const CorporateItemFormResolver = yupResolver(CorporateItemFormSchema)
