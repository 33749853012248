import { OrderStatusEnum } from "../../shared/graphql/generated/types"
import { CustomOrderStatusTitle } from "../../shared/titles/orderStatus.title"
import { Group, MantineThemeColors, useMantineTheme } from "@mantine/core"
import { IconPoint } from "@tabler/icons-react"
import { useIntl } from "react-intl"

const statusColorMapping = (
  color: MantineThemeColors
): Record<OrderStatusEnum, string> => {
  return {
    [OrderStatusEnum.CANCELLED]: color.red[7],
    [OrderStatusEnum.CHECK_OUT]: color.gray[3],
    [OrderStatusEnum.CLOSED]: color.gray[3],
    [OrderStatusEnum.COMPLETED]: color.green[5],
    [OrderStatusEnum.DELIVERY_FAILED]: color.gray[3],
    [OrderStatusEnum.IN_REVISION]: color.gray[3],
    [OrderStatusEnum.OPEN]: color.primary[6],
    [OrderStatusEnum.PAYMENT_PENDING]: color.gray[3],
    [OrderStatusEnum.PENDING]: color.yellow[9],
    [OrderStatusEnum.PENDING_REASSIGN]: color.gray[3],
    [OrderStatusEnum.PICKED_UP]: color.gray[9],
    [OrderStatusEnum.PLACED]: color.yellow[5],
    [OrderStatusEnum.PREPARING]: color.yellow[9],
    [OrderStatusEnum.READY]: color.primary[2],
    [OrderStatusEnum.REFUNDED]: color.gray[3],
  }
}

interface Props {
  status: OrderStatusEnum
}

export const OrderStatus = ({ status }: Props) => {
  const intl = useIntl()
  const theme = useMantineTheme()
  const pointColor = statusColorMapping(theme.colors)[status]

  return (
    <Group noWrap spacing="xs">
      <IconPoint size={16} color={pointColor} fill={pointColor} />
      {intl.formatMessage(CustomOrderStatusTitle[status])}
    </Group>
  )
}
