import { IntlMessage } from "../../../../../shared/types"
import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import { Comparisson } from "../Comparisson"
import { NumberType } from "../Comparisson/Comparisson"
import { FinancialInfoRow } from "./FinancialInfoRow"
import { Anchor, Card, Divider, Group, Stack, Text, Title } from "@mantine/core"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"

type FinancialCardProps = {
  valueType: NumberType
  fields: Array<{ name: IntlMessage; value: number; dif: number }>
  h: number
  currentValue: number
  previousValue: number
  redirection?: string
  type?: "normal" | "satisfaction"
}

export const FinancialCard = (props: FinancialCardProps) => {
  const {
    fields,
    valueType,
    redirection,
    currentValue,
    previousValue,
    h,
    type = "normal",
  } = props
  const intl = useIntl()

  return (
    <Card withBorder miw={284} h={h} radius={8}>
      <Stack spacing={16}>
        <Group position="apart">
          <Text size="sm" weight={600} color="gray.6" lh="16px">
            {intl.formatMessage({
              id: "restaurants.reports.labor.cost.summary.label",
              defaultMessage: "SUMMARY",
            })}
          </Text>
          {redirection && (
            <Anchor size="sm" component={Link} to={redirection} lh="24px">
              {intl.formatMessage({
                id: "restaurants.reports.labor.cost.report.anchor",
                defaultMessage: "View report",
              })}
            </Anchor>
          )}
        </Group>

        <Stack spacing={0}>
          <Group spacing={0}>
            <Title order={3} mr={8}>
              {toFormat(currentValue)}
            </Title>
            <Comparisson
              valueType={valueType}
              value={currentValue - previousValue}
              type={type}
            />
          </Group>
          <Text size="md" color="gray.6" h={18}>
            {intl.formatMessage({
              id: "restaurants.reports.labor.cost.comparison.label",
              defaultMessage: "Compared to previous period",
            })}
          </Text>
        </Stack>

        <Divider />

        {fields.map((field) => (
          <FinancialInfoRow
            key={field.name.id}
            valueType={valueType}
            label={intl.formatMessage(field.name)}
            value={field.value}
            dif={field.dif}
            type={type}
          />
        ))}
      </Stack>
    </Card>
  )
}
