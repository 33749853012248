import { DiscountsOrderMethodEnum } from "../../../../../../shared/graphql/generated/types"
import Checkbox from "../../../../../../ui/Checkbox"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import Divider from "../../../../../../ui/Divider"
import Select, { OptionSelect } from "../../../../../../ui/Select"
import Tag from "../../../../../../ui/Tag"
import { Header } from "../Components"
import { SELECT_LIST_HEIGHT } from "../constants"
import { useCollapseOpen } from "../hooks/useCollapseOpen"
import { useOrderType } from "./hooks/useOrderType"
import { useState } from "react"
import { useIntl } from "react-intl"

export const OrderType = () => {
  const { open, handleCollapseChange } = useCollapseOpen()
  const intl = useIntl()
  const [isSearching, setIsSearching] = useState(false)

  const {
    onAllOrderTypesClick,
    onOrderTypeSelected,
    selectedOrderTypes,
    orderTypes,
    isEditMode,
  } = useOrderType()

  const applyAllOrderTypes = selectedOrderTypes?.length === 0

  const headerTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.order.type.header.title",
    defaultMessage: "Order Type",
  })

  const pluralTagLabel = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.order.type.tag.label",
      defaultMessage: "{count} Order {count, plural, one {Type} other {Types}}",
    },
    {
      count: Number(selectedOrderTypes?.length),
    }
  )

  const selectTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.order.type.select.title",
    defaultMessage: "Select Order Type",
  })

  const allOptionsLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.order.type.all.types.label",
    defaultMessage: "All Order Types",
  })

  const tagLabel = applyAllOrderTypes ? allOptionsLabel : pluralTagLabel

  const allOptionsKey = DiscountsOrderMethodEnum.ALL

  const handleSearch = (value: string) => {
    if (value) {
      setIsSearching(true)
      return
    }

    setIsSearching(false)
  }

  return (
    <Collapse
      contentBorderColor="Neutral4"
      removeHeaderBottomBorder
      contentBordered
      classId="discount-rule-order-type-collapse"
      onChange={handleCollapseChange}
    >
      <CollapsePanel
        key="discount-rule-order-type-panel"
        header={
          <Header title={headerTitle}>{!open && <Tag>{tagLabel}</Tag>}</Header>
        }
      >
        <Select
          withCheckbox
          allowSearch
          disabled={isEditMode}
          onSearch={handleSearch}
          label={selectTitle}
          mode="multiple"
          optionFilterProp="label"
          listHeight={SELECT_LIST_HEIGHT}
          options={orderTypes}
          value={
            applyAllOrderTypes
              ? [allOptionsKey]
              : selectedOrderTypes?.map((orderType) => orderType)
          }
          onChangeWithCheckbox={onOrderTypeSelected}
          dropdownHeader={
            !isSearching && (
              <OptionSelect
                className="option-select-checkbox no-background"
                key={allOptionsKey}
                value={allOptionsKey}
                label={allOptionsLabel}
              >
                <Container
                  padding="4px 12px"
                  onClick={onAllOrderTypesClick}
                  className="option-select-checkbox-selected"
                >
                  <Checkbox
                    classId="select-all-order-type-checkbox"
                    content={allOptionsLabel}
                    checked={applyAllOrderTypes}
                    indeterminate={!applyAllOrderTypes}
                  />
                </Container>
                <Divider verticalMargin="4px" />
              </OptionSelect>
            )
          }
          closableTags={!applyAllOrderTypes && !isEditMode}
        />
      </CollapsePanel>
    </Collapse>
  )
}
