import SingleItemCollapse from "../../components/SingleItemCollapse"
import type { IComboForm } from "../../interfaces/hookforms.interfaces"
import React, { useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type SingleItemFormType = {
  isCorporate?: boolean
  cmbItmGrpIndex: number
  comboItemIndex: number
}

const SingleItemForm: React.FC<SingleItemFormType> = ({
  cmbItmGrpIndex,
  comboItemIndex,
  isCorporate = true,
}) => {
  const intl = useIntl()

  const { control, setValue, getValues } = useFormContext<IComboForm>()

  const [
    comboItem,
    defaultComboItem,
    defaultComboItemPrice,
    cmbItmGroups,
    comboItems,
  ] = useWatch({
    name: [
      `comboItemGroups.${cmbItmGrpIndex}.comboItems.${comboItemIndex}`,
      `comboItemGroups.${cmbItmGrpIndex}.defaultComboItem`,
      `comboItemGroups.${cmbItmGrpIndex}.defaultComboItemPrice`,
      `comboItemGroups`,
      `comboItemGroups.${cmbItmGrpIndex}.comboItems`,
    ],
    control: control,
  })

  const activeComboItems =
    comboItems?.filter((comboItemToFilter) => !comboItemToFilter.deletedAt) ??
    []

  const title = intl.formatMessage(
    {
      id: "restaurants.menu.items.forms.combo.form.name.header",
      defaultMessage:
        "{name} ({quantity,plural, =1 {# variant} other {# variants}})",
    },
    {
      name: activeComboItems[comboItemIndex].item.name,
      quantity: Number(comboItem.item.variants?.length),
    }
  )

  const isCurrentDefault = defaultComboItem === comboItemIndex

  const onDefaultClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setValue(
      `comboItemGroups.${cmbItmGrpIndex}.defaultComboItem`,
      comboItemIndex,
      { shouldDirty: true }
    )
  }

  useEffect(() => {
    const isCurrentDeleted = getValues(
      `comboItemGroups.${cmbItmGrpIndex}.comboItems.${comboItemIndex}.deletedAt`
    )

    if (isCurrentDefault && !isCurrentDeleted) {
      setValue(
        `comboItemGroups.${cmbItmGrpIndex}.comboItems.${comboItemIndex}.isDefault`,
        true
      )

      const defaultComboItemVariantPrice = getValues(
        `comboItemGroups.${cmbItmGrpIndex}.comboItems.${comboItemIndex}.item`
      ).variants?.find((variant) => variant.isDefault)?.price

      setValue(
        `comboItemGroups.${cmbItmGrpIndex}.defaultComboItemPrice`,
        defaultComboItemVariantPrice
      )
    } else {
      setValue(
        `comboItemGroups.${cmbItmGrpIndex}.comboItems.${comboItemIndex}.isDefault`,
        false
      )
    }
  }, [
    cmbItmGrpIndex,
    comboItem,
    comboItemIndex,
    defaultComboItem,
    getValues,
    isCurrentDefault,
    setValue,
  ])

  useEffect(() => {
    const comboFullPrice = getValues("comboItemGroups").reduce(
      (total, { defaultComboItemPrice: price = 0 }) => total + price,
      0
    )
    setValue("fullPrice", comboFullPrice)
    const discountPercentage = getValues("discountPercentage")

    if (discountPercentage && discountPercentage > 0) {
      setValue(
        "priceOverride",
        Number(((comboFullPrice * (100 - discountPercentage)) / 100).toFixed(2))
      )
    } else {
      setValue("priceOverride", comboFullPrice)
    }
  }, [
    getValues,
    setValue,
    defaultComboItemPrice,
    defaultComboItem,
    cmbItmGroups,
  ])

  return (
    <SingleItemCollapse
      isCorporate={isCorporate}
      title={title}
      onDefaultClick={onDefaultClick}
      isCurrentDefault={isCurrentDefault}
      comboItem={comboItem}
    />
  )
}

export default SingleItemForm
