import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetComboListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  isPublished?: Types.InputMaybe<Types.Scalars['Boolean']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetComboListQuery = { __typename?: 'Query', getComboList: { __typename?: 'PaginatedComboList', hasNextPage: boolean, endCursor?: string | null, results?: Array<{ __typename?: 'RComboList', hasSnapshot: boolean, isMain: boolean, isPublished: boolean, isVisible?: boolean | null, name: string, uuid: string, attachment?: { __typename?: 'AttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string, uuid: string } | null }> | null } };


export const GetComboListDocument = gql`
    query GetComboList($after: String, $isPublished: Boolean, $name: String, $take: Int) {
  getComboList(after: $after, isPublished: $isPublished, name: $name, take: $take) {
    results {
      attachment {
        contentType
        ext
        fileName
        signedUrl
        uuid
      }
      hasSnapshot
      isMain
      isPublished
      isVisible
      name
      uuid
    }
    hasNextPage
    endCursor
  }
}
    `;

/**
 * __useGetComboListQuery__
 *
 * To run a query within a React component, call `useGetComboListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      isPublished: // value for 'isPublished'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetComboListQuery(baseOptions?: Apollo.QueryHookOptions<GetComboListQuery, GetComboListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComboListQuery, GetComboListQueryVariables>(GetComboListDocument, options);
      }
export function useGetComboListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComboListQuery, GetComboListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComboListQuery, GetComboListQueryVariables>(GetComboListDocument, options);
        }
export type GetComboListQueryHookResult = ReturnType<typeof useGetComboListQuery>;
export type GetComboListLazyQueryHookResult = ReturnType<typeof useGetComboListLazyQuery>;
export type GetComboListQueryResult = Apollo.QueryResult<GetComboListQuery, GetComboListQueryVariables>;