import { DateSelector, HeaderDateTag } from "."
import { ALL_DAY_VALUE } from "../../../../../../shared/utils/constant/dateFormats"
import Checkbox from "../../../../../../ui/Checkbox"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import InputHelperText from "../../../../../../ui/Inputs/InputHelper"
import Spacer from "../../../../../../ui/Spacer"
import { ErrorBadge, Header } from "../Components"
import { useCollapseOpen } from "../hooks/useCollapseOpen"
import { useDateAndTime } from "./hooks/useDateAndTime"
import { validationsTitle } from "./validations.title"
import { useIntl } from "react-intl"

export const DateAndTimeRule = () => {
  const intl = useIntl()

  const {
    startDate,
    startTime,
    endDateError,
    endDate,
    addEndTime,
    onDateChange,
    onTimeChange,
    onAddTimeChange,
  } = useDateAndTime()

  const hasEndDateError =
    !!endDateError.date || !!endDateError.group || !!endDateError.hour?.time

  const { open, handleCollapseChange } = useCollapseOpen()

  const startDateTitle = {
    datePicker: intl.formatMessage({
      id: "restaurants.discounts.forms.generic.rules.date.start.date.title",
      defaultMessage: "Start Date",
    }),
    timePicker: intl.formatMessage({
      id: "restaurants.discounts.forms.generic.rules.date.start.time.title",
      defaultMessage: "Start Time",
    }),
  }

  const endDateTitle = {
    datePicker: intl.formatMessage({
      id: "restaurants.discounts.forms.generic.rules.date.end.date.title",
      defaultMessage: "End Date",
    }),
    timePicker: intl.formatMessage({
      id: "restaurants.discounts.forms.generic.rules.date.end.time.title",
      defaultMessage: "End Time",
    }),
  }

  const addEndTimeTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.generic.rules.date.add.end.time.title",
    defaultMessage: "Add end time",
  })

  const headerTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.date.and.time.header.title",
    defaultMessage: "Date and time",
  })

  const hourErrorMessage =
    endDateError?.hour?.time?.message &&
    intl.formatMessage(validationsTitle[endDateError?.hour?.time?.message])

  const endDateErrorMessage =
    endDateError?.date?.message &&
    intl.formatMessage(validationsTitle[endDateError?.date?.message])

  return (
    <Collapse
      contentBorderColor="Neutral4"
      removeHeaderBottomBorder
      contentBordered
      classId="discount-rule-date-collapse"
      onChange={handleCollapseChange}
    >
      <CollapsePanel
        key="discount-rule-date-panel"
        header={
          <Header title={headerTitle}>
            {!open && (
              <Container display="flex" gap="8px">
                <HeaderDateTag date={startDate} />
                <HeaderDateTag date={endDate} />
              </Container>
            )}
          </Header>
        }
        extra={<ErrorBadge hidden={!hasEndDateError} />}
      >
        <DateSelector
          fieldType="startDate"
          date={startDate}
          title={startDateTitle}
          isAllDay={startTime?.time === ALL_DAY_VALUE}
          onDateChange={onDateChange}
          onTimeChange={onTimeChange}
          allowAllDay
        />
        <Spacer size={20} />
        <Checkbox
          classId="discount-rule-end-date-checkbox"
          checked={addEndTime}
          content={addEndTimeTitle}
          onChange={onAddTimeChange}
        />
        <Spacer size={20} />
        {addEndTime && (
          <DateSelector
            fieldType="endDate"
            date={endDate}
            title={endDateTitle}
            onDateChange={onDateChange}
            onTimeChange={onTimeChange}
            errors={{
              hour: hourErrorMessage,
              date: endDateErrorMessage,
            }}
            hasGroupError={!!endDateError?.group?.message}
          />
        )}
        {endDateError?.group?.message && (
          <Container position="relative">
            <InputHelperText $hasError size="s">
              {intl.formatMessage(
                validationsTitle[endDateError.group?.message]
              )}
            </InputHelperText>
          </Container>
        )}
        <Spacer size={10} />
      </CollapsePanel>
    </Collapse>
  )
}
