import type { GetItemListQuery } from "../../../../../GraphQL/Queries/getItemList.generated"
import { useGetItemListQuery } from "../../../../../GraphQL/Queries/getItemList.generated"
import type { ItemCardModel } from "../../../../../components/ItemCard"
import ItemCard from "../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { ItemProgressStatusEnum } from "../../../../../shared/graphql/generated/types"
import paths from "../../../../../shared/routes/paths"
import { CardSkeleton } from "../../../../../ui/Card/Card.skeleton"
import Container from "../../../../../ui/Container"
import EmptyState from "../../../../../ui/EmptyState"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Spacer from "../../../../../ui/Spacer"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import { Text } from "../../../../../ui/Typography/Text/Text"
import OwnershipTag from "../../../components/OwnershipTag"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

export interface ISearchItemsModal extends Omit<IModalProps, "onOk"> {
  onSave: (
    selectedItems: Array<ItemCardModel>,
    removedItems?: Array<ItemCardModel>
  ) => void
  selectedItems: Array<ItemCardModel>
  isCorporate?: boolean
}

export const SearchItemsModal: React.FC<ISearchItemsModal> = (props) => {
  const {
    onSave,
    afterClose,
    selectedItems = [],
    isCorporate = true,
    ...rest
  } = props
  const intl = useIntl()
  const { push } = useHistory()
  const [value, setValue] = useState<string>()
  const [searching, setSearching] = useState<boolean>(false)
  const [selectedCards, setSelectedCards] = useState<Array<ItemCardModel>>([
    ...selectedItems,
  ])
  const [removedCards, setRemovedCards] = useState<Array<ItemCardModel>>([])

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const {
    data,
    called,
    loading,
    refetch: getItems,
    fetchMore,
  } = useGetItemListQuery({
    variables: { take: 5, hasBeenPublished: true },
    skip: !restaurantUUID,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    onError: showGraphqlErrors,
  })

  const items = data?.getItemList?.results ?? []

  const hasNextPage = data?.getItemList?.hasNextPage ?? false
  const endCursor = data?.getItemList?.endCursor

  const fetchMoreItems = useCallback(() => {
    fetchMore({
      variables: { after: endCursor, take: 5 },
      updateQuery: (prev: GetItemListQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getItemList?.endCursor ===
            fetchMoreResult?.getItemList?.endCursor
        ) {
          return prev
        }

        return {
          getItemList: {
            ...fetchMoreResult.getItemList,
            results: [
              ...(prev.getItemList?.results ?? []),
              ...(fetchMoreResult.getItemList?.results ?? []),
            ],
          },
        }
      },
    })
  }, [endCursor, fetchMore])

  const handleInputChange = useCallback(
    async (valueChanged: string, callback: () => void) => {
      try {
        await getItems({
          name: valueChanged === "" ? undefined : valueChanged,
          take: 20,
        })
      } catch (error) {
        showGraphqlErrors(error)
      } finally {
        callback()
      }
    },
    [getItems]
  )

  const searchItemsDebounce = useMemo(
    () => debounce(handleInputChange, 1000),
    [handleInputChange]
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setSearching(true)

      searchItemsDebounce("", () => {
        setSearching(false)
      })

      return
    }

    setValue(event.target.value)
  }

  const onCheckCard = (
    _event: CheckboxChangeEvent | React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemCardModel: ItemCardModel
  ) => {
    if (!itemCardModel.uuid) return

    const isItemPresent = selectedCards.some(
      (item) => item.uuid === itemCardModel.uuid
    )
    const isPreSelectedItem = selectedItems.some(
      (item) => item.uuid === itemCardModel.uuid
    )

    if (isItemPresent) {
      setSelectedCards((prev) => {
        return prev.filter(({ uuid }) => uuid !== itemCardModel.uuid)
      })

      if (isPreSelectedItem) {
        setRemovedCards((prev) => {
          return [
            ...prev.filter(({ uuid }) => uuid !== itemCardModel.uuid),
            itemCardModel,
          ]
        })
      }
    } else {
      setSelectedCards((prev) => {
        return [
          ...prev.filter(({ uuid }) => uuid !== itemCardModel.uuid),
          itemCardModel,
        ]
      })

      if (isPreSelectedItem) {
        setRemovedCards((prev) => {
          return prev.filter(({ uuid }) => uuid !== itemCardModel.uuid)
        })
      }
    }
  }

  const onSaveData = () => {
    const currentState = selectedCards.filter((item) => {
      return !removedCards.some(({ uuid }) => uuid === item.uuid)
    })

    onSave(currentState, removedCards)
    setSelectedCards([])
    setRemovedCards([])
  }

  const redirectToNewItem = () => push(paths.restaurants.createProduct)

  const handleAfterClose = () => {
    setValue(undefined)
    afterClose?.()
  }

  useEffect(() => {
    if (value) {
      setSearching(true)

      searchItemsDebounce(value, () => {
        setSearching(false)
      })
    }
  }, [searchItemsDebounce, value])

  const skeleton = <CardSkeleton cards={2} loading />

  return (
    <StyledModal
      {...rest}
      afterClose={handleAfterClose}
      closable={false}
      onOk={onSaveData}
      destroyOnClose
      width="563px"
    >
      <StyledInput
        aria-label="search-item-input"
        placeholder={intl.formatMessage({
          id: "restaurants.menu.placeholder.item.label",
          defaultMessage: "Search Item",
        })}
        onChange={handleChange}
        loading={searching}
        disabled={!items && !loading}
        bordered={false}
        size="middle"
        searchIcon
      />

      <Spacer size={24} />
      <StyledModalBody id="search-items-modal">
        {items.length === 0 && called && !loading && (
          <Container
            centered
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <EmptyState
              title=""
              description={intl.formatMessage({
                id: "components.search.items.not.found.title",
                defaultMessage: "Sorry, we did not find any results",
              })}
            />

            <Text size="s">
              {intl.formatMessage({
                id: "components.search.items.not.found.description",
                defaultMessage: "You can create a new item ",
              })}
              <HighlightedText
                size="s"
                cursor="pointer"
                onClick={redirectToNewItem}
              >
                {intl.formatMessage({
                  id: "components.search.items.not.found.link",
                  defaultMessage: "here",
                })}
              </HighlightedText>
            </Text>
          </Container>
        )}
        {items.length > 0 && (
          <Container height="100%" width="100%">
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreItems}
              hasMore={loading || hasNextPage}
              loader={skeleton}
              scrollableTarget="search-items-modal"
              height={256}
              className="infinite-scroll"
            >
              {items.map((item) => {
                return (
                  <ItemCard
                    key={item.uuid}
                    id={item.uuid}
                    title={item.name ?? ""}
                    textTitle={
                      <Text
                        className="card__content__title__item"
                        size="m"
                        title={item.name ? item.name : ""}
                        ellipsis
                      >
                        {item.name}
                      </Text>
                    }
                    isPublished={item.publishedAt ?? undefined}
                    src={item.attachment?.signedUrl}
                    bordered={false}
                    checked={selectedCards.some(
                      ({ uuid }) => uuid === item.uuid
                    )}
                    onCheckCard={onCheckCard}
                    createdAt={item.createdAt}
                    attachment={{ signedUrl: item.attachment?.signedUrl ?? "" }}
                    tags={
                      item.status === ItemProgressStatusEnum.UNPUBLISHED
                        ? [
                            {
                              uuid: item.uuid,
                              title: intl.formatMessage({
                                id: "restaurants.items.tag.unpublished.label",
                                defaultMessage: "Unpublished",
                              }),
                              type: "secondary",
                              borderStyle: "dashed",
                              size: "small",
                            },
                          ]
                        : []
                    }
                    tagOwnership={
                      !isCorporate && (
                        <OwnershipTag isMain={item.isMain} size="small" />
                      )
                    }
                    imageFit="contain"
                    className="card-item"
                    checkable
                  />
                )
              })}
            </InfiniteScroll>
          </Container>
        )}
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 32px 40px;
  }
`

const StyledInput = styled(Input)`
  ${({ theme }) => `.input-inner-wrapper {
    background: ${theme.colors.Neutral2};
  }`}
`

const StyledModalBody = styled.div`
  max-height: 256px;
  min-height: 200px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .infinite-scroll::-webkit-scrollbar {
    display: none;
  }

  .card-item {
    padding: 3px 0;
    &:last-child {
      margin: 0;
    }
  }
`
