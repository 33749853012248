import {
  GetItemListQuery,
  useGetItemListQuery,
} from "../../../../../../../GraphQL/Queries/getItemList.generated"
import { updateGetItemListQuery } from "../../../../../../../shared/graphql/updateQuery/updateGetItemListQuery"
import {
  DEBOUNCE_MILLISECONDS,
  EMPTY_ARRAY,
  EMPTY_STRING,
  WHITE_SPACE,
} from "../../../../../../../shared/utils/constant/values"
import { canFetchMore } from "../../../../../../../shared/utils/helpers/canFetchMore"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { onPopupScroll } from "../../../../../../../ui/Select/helpers/onPopupScroll"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  APPLY_ALL_ITEMS_NAME,
  ENTIRE_ORDER_NAME,
  ITEMS_FIELD_NAME,
  SET_VALUE_OPTIONS,
  TAKE_QUANTITY,
} from "../../constants"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

interface SelectOption {
  value: string
  label: string
}

export const useItemSelector = () => {
  const [search, setSearch] = useState<string | undefined>()

  const { watch, setValue, trigger, getValues } =
    useFormContext<IDiscountForm>()

  const isEditMode = getValues(EDIT_MODE_NAME)

  const {
    data,
    loading,
    refetch: refetchItems,
    fetchMore,
  } = useGetItemListQuery({
    variables: { take: TAKE_QUANTITY },
    skip: isEditMode,
    fetchPolicy: "cache-and-network",
  })

  const selectedItems = watch(ITEMS_FIELD_NAME)
  const applyToAllItems = watch(APPLY_ALL_ITEMS_NAME)

  const hasNextPage = data?.getItemList?.hasNextPage ?? false
  const endCursor = data?.getItemList?.endCursor
  const canFetchMoreLocations = canFetchMore(loading, hasNextPage, endCursor)

  const isOption = (item: {
    value: string
    label: string | null | undefined
  }): item is SelectOption => {
    return !!item?.label
  }

  const items =
    data?.getItemList?.results
      ?.map((item) => ({
        value: item.uuid,
        label: item.name,
      }))
      // Needed to apply type predicate to filter out null and undefined
      // eslint-disable-next-line unicorn/no-array-callback-reference
      .filter(isOption) ?? []

  selectedItems?.forEach((currentItem) => {
    if (!items?.map((item) => item.value).includes(currentItem.uuid))
      items?.push({
        value: currentItem.uuid,
        label: currentItem.name,
      })
  })

  const onItemSelected = (itemOption: string[]) => {
    const formattedItems = items
      ?.filter((item) => itemOption.includes(item.value))
      .map((filteredItem) => ({
        uuid: filteredItem.value,
        name: filteredItem.label,
      }))

    if (!formattedItems) return

    setValue(ITEMS_FIELD_NAME, formattedItems, SET_VALUE_OPTIONS)
    setValue(APPLY_ALL_ITEMS_NAME, false, SET_VALUE_OPTIONS)
    trigger(ENTIRE_ORDER_NAME)
  }

  const searchItems = async (itemName: string) => {
    try {
      await refetchItems({
        name: itemName === EMPTY_STRING ? undefined : itemName,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onAllItemsClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
    event.preventDefault()

    const isChecked = applyToAllItems

    if (!isChecked) {
      setValue(ITEMS_FIELD_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
    }

    setSearch(EMPTY_STRING)
    setValue(APPLY_ALL_ITEMS_NAME, !isChecked, SET_VALUE_OPTIONS)
    searchItems(EMPTY_STRING)
    trigger(ENTIRE_ORDER_NAME)
  }

  const onDebounceSearchItems = React.useMemo(() => {
    return debounce(searchItems, DEBOUNCE_MILLISECONDS)
  }, [])

  const handleSearchItems = (itemName: string) => {
    if (itemName === WHITE_SPACE) return

    setSearch(itemName)

    if (!itemName) {
      onDebounceSearchItems.cancel()
      searchItems(itemName)
      return
    }
    onDebounceSearchItems(itemName)
  }

  const fetchMoreItems = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          after: endCursor,
          take: TAKE_QUANTITY,
        },
        updateQuery: (prev: GetItemListQuery, { fetchMoreResult }) =>
          updateGetItemListQuery(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor])

  const onScrollToFetchMore = (
    scrollEvent: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    onPopupScroll(scrollEvent, canFetchMoreLocations, fetchMoreItems)
  }

  useEffect(() => {
    return () => {
      onDebounceSearchItems.cancel()
    }
  }, [])

  return {
    items,
    loading,
    onItemSelected,
    onAllItemsClick,
    handleSearchItems,
    onScrollToFetchMore,
    search,
    selectedItems,
    applyToAllItems,
    isEditMode,
  }
}
