import React from "react"
import type { RouteProps } from "react-router"
import { Route, useLocation } from "react-router"
import { Redirect } from "react-router-dom"

export type RedirecRouteProps = RouteProps & {
  pathname: string
}

export const RedirectRoute: React.FC<RedirecRouteProps> = ({
  pathname,
  ...props
}) => {
  const currentLocation = useLocation()

  return (
    <Route
      {...props}
      render={() => (
        <Redirect to={{ pathname }} from={currentLocation.pathname} />
      )}
    />
  )
}
