import { OrderActionTypeEnum } from "../../../../shared/graphql/generated/types"

export const CustomOrderTimelineTitle: Record<
  OrderActionTypeEnum,
  { id: string; defaultMessage: string }
> = {
  REFUNDED: {
    id: "restaurants.orders.order.timeline.action.refunded",
    defaultMessage: "Order Refunded",
  },
  CANCELLED_PAYMENT_INTENT: {
    id: "restaurants.orders.order.timeline.action.cancelled.payment.intent",
    defaultMessage: "Cancelled Payment Intent",
  },
  PAYMENT_AWAITING: {
    id: "restaurants.orders.order.timeline.action.payment.awaiting",
    defaultMessage: "Payment Awaiting",
  },
  PAYMENT_FAILED: {
    id: "restaurants.orders.order.timeline.action.payment.failed",
    defaultMessage: "Payment Failed",
  },
  PAYMENT_SUCCEEDED: {
    id: "restaurants.orders.order.timeline.action.payment.succeeded",
    defaultMessage: "Payment Succeeded",
  },
  CANCELLED: {
    id: "restaurants.orders.order.timeline.action.cancelled",
    defaultMessage: "Order Cancelled",
  },
  CHECK_OUT: {
    id: "restaurants.orders.order.timeline.action.checkout",
    defaultMessage: "Order Checkout",
  },
  CLOSED: {
    id: "restaurants.orders.order.timeline.action.closed",
    defaultMessage: "Order {orderNumber} closed",
  },
  COMMENT: {
    id: "restaurants.orders.order.timeline.action.comment",
    defaultMessage:
      "{hasUserName,plural,=0 {New {comment} Added} other {{userName} added a {comment} to order }}",
  },
  COMPLETED: {
    id: "restaurants.orders.order.timeline.action.completed",
    defaultMessage: "Order Completed and Ready To Deliver",
  },
  OPEN: {
    id: "restaurants.orders.order.timeline.action.open",
    defaultMessage: "Order Open",
  },
  PAYMENT_PENDING: {
    id: "restaurants.orders.order.timeline.action.payment.pending",
    defaultMessage: "Order Payment Pending",
  },
  PENDING: {
    id: "restaurants.orders.order.timeline.action.pending",
    defaultMessage: "Ready For Kitchen",
  },
  PREPARING: {
    id: "restaurants.orders.order.timeline.action.preparing",
    defaultMessage: "Kitchen started preparing order",
  },
  READY: {
    id: "restaurants.orders.order.timeline.action.ready",
    defaultMessage: "Order Ready to serve",
  },
  DELIVERY_FAILED: {
    id: "restaurants.orders.order.timeline.action.delivery.failed",
    defaultMessage: "Delivery failed",
  },
  ESTIMATED_TIME_ARRIVAL: {
    id: "restaurants.orders.order.timeline.action.estimated.time.arrival",
    defaultMessage: "Time to arrive is approx {time}",
  },
  PICKED_UP: {
    id: "restaurants.orders.order.timeline.action.picked.up",
    defaultMessage: "Delivery picked up",
  },
  IN_REVISION: {
    id: "restaurants.orders.order.timeline.action.in.revision",
    defaultMessage: "In revision",
  },
  PARTIAL_PAID: {
    id: "restaurants.orders.order.timeline.action.partial.paid",
    defaultMessage: "Partial paid",
  },
  PENDING_REASSIGN: {
    id: "restaurants.orders.order.timeline.action.pending.reassign",
    defaultMessage: "Pending reassign",
  },
  PLACED: {
    id: "restaurants.orders.order.timeline.action.placed",
    defaultMessage: "Placed",
  },
  PENDING_CAPTURE: {
    id: "restaurants.orders.order.timeline.action.pending.capture",
    defaultMessage: "Pending Capture",
  },
  [OrderActionTypeEnum.ORDER_REASSIGNED]: {
    id: "restaurants.orders.order.timeline.action.order.reassign",
    defaultMessage: "Order Reassigned",
  },
}
