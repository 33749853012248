import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSortingFieldQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetSortingFieldQuery = { __typename?: 'Query', getSortingField: { __typename?: 'SortingFieldMenu', uuid?: string | null, sortingType?: Types.SortingByMenuEnum | null, sortBy: { __typename?: 'SortingByMenu', createdAt?: string | null, itemOrder?: string | null } } };


export const GetSortingFieldDocument = gql`
    query getSortingField($uuid: ID!) {
  getSortingField(uuid: $uuid) {
    uuid
    sortingType
    sortBy {
      createdAt
      itemOrder
    }
  }
}
    `;

/**
 * __useGetSortingFieldQuery__
 *
 * To run a query within a React component, call `useGetSortingFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSortingFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSortingFieldQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSortingFieldQuery(baseOptions: Apollo.QueryHookOptions<GetSortingFieldQuery, GetSortingFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSortingFieldQuery, GetSortingFieldQueryVariables>(GetSortingFieldDocument, options);
      }
export function useGetSortingFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSortingFieldQuery, GetSortingFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSortingFieldQuery, GetSortingFieldQueryVariables>(GetSortingFieldDocument, options);
        }
export type GetSortingFieldQueryHookResult = ReturnType<typeof useGetSortingFieldQuery>;
export type GetSortingFieldLazyQueryHookResult = ReturnType<typeof useGetSortingFieldLazyQuery>;
export type GetSortingFieldQueryResult = Apollo.QueryResult<GetSortingFieldQuery, GetSortingFieldQueryVariables>;