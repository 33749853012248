import { ActionTypes } from "../../../pages/Login/actions"
import { useUpsertRestaurantSnapshotMutation } from "../../../pages/Onboarding/GraphQL/UpsertRestaurantSnapshot.generated"
import { useOnboardingContext } from "../../../shared/contexts/OnboardingProvider"
import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import paths from "../../../shared/routes/paths"
import storage from "../../../shared/utils/storage"
import Button from "../../Button"
import QuestionButton from "../../QuestionButton"
import Text from "../../Typography/Text"
import LayoutBase from "../LayoutBase"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router-dom"
import styled, { css } from "styled-components"

export const Onboarding: React.FC = ({ children }) => {
  const history = useHistory()
  const { state, dispatch } = useGeneralContext()
  const { clearOnboardingState, updateOnboardingState, onboarding } =
    useOnboardingContext()
  const intl = useIntl()

  const { pathname } = useLocation()

  const isOnboarding = pathname === paths.onboarding.root

  const [upsertRestaurantSnapshot, { loading }] =
    useUpsertRestaurantSnapshotMutation()

  const allowOnboarding = onboarding.allowOnboarding
  const userUUID = state.auth.admin.uuid

  const onClickLogout = () => {
    storage.clear()

    dispatch({ type: ActionTypes.Logout })

    history.push(paths.guest.login)
  }

  const onClickGoBack = async () => {
    await upsertRestaurantSnapshot({
      variables: { data: { snapshot: { currentStep: 1 }, userUUID } },
    })

    clearOnboardingState()

    updateOnboardingState({ allowOnboarding: false })

    history.push(paths.restaurants.menu)
  }

  useEffect(() => {
    if (isOnboarding) return

    updateOnboardingState({ allowOnboarding: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutBase flexDirection="column">
      <StyledHeader>
        <div>
          <Text size="s">
            {intl.formatMessage({
              id: "onboarding.location.logged.label",
              defaultMessage: "Logged in as:",
            })}
          </Text>

          <Text size="s" weight="bold">
            {state.auth.admin?.email}
          </Text>
        </div>

        {allowOnboarding ? (
          <Button
            loading={loading}
            hierarchy="tertiary"
            transparent
            title={intl.formatMessage({
              id: "generic.go.back",
              defaultMessage: "Go Back",
            })}
            onClick={onClickGoBack}
          />
        ) : (
          <Button
            hierarchy="tertiary"
            transparent
            title={intl.formatMessage({
              id: "generic.logout",
              defaultMessage: "Log Out",
            })}
            onClick={onClickLogout}
          />
        )}
      </StyledHeader>
      <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      <div hidden>
        <QuestionButton />
      </div>
    </LayoutBase>
  )
}

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  > div {
    display: flex;
    flex-direction: column;

    span {
      ${({ theme }) => css`
        &:first-child {
          color: ${theme.colors.Neutral4};
        }

        &:last-child {
          color: ${theme.colors.Neutral5};
        }
      `}
    }
  }
`

const StyledChildrenWrapper = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`
