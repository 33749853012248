import { CalendarPickerModal } from "../../../components/Modals/CalendarPickerModal"
import { dateRanges } from "../../../components/Modals/CalendarPickerModal/utils/date-ranges.util"
import {
  DateQueryParam,
  useDateRange,
} from "../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../shared/hooks/useSearchParams"
import { DateRangeEnum } from "../../../shared/v2/utils/dateRange.enum"
import { LocationButtonDropdown } from "./components/LocationButtonDropdown"
import { LOCATIONS_QUERY_PARAM } from "./constants"
import { useReports } from "./hooks/useReports"
// import { BestSellersSection } from "./sections/BestSellersSection"
// import CustomerSatisfactionSection from "./sections/CustomerSatisfactionSection"
import LaborCostSection from "./sections/LaborCostSection"
import { LaborCostSkeleton } from "./sections/LaborCostSection/LaborCostSkeleton"
import { SalesSummary } from "./sections/SalesSummary"
import {
  Badge,
  Button,
  Container,
  Divider,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { IconCalendarEvent, IconDownload } from "@tabler/icons-react"
import { useFlags } from "launchdarkly-react-client-sdk"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

export const Reports = () => {
  const intl = useIntl()
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const { locationUUID } = useParams<{ locationUUID: string }>()
  const isCorporate = !locationUUID
  const { getParam } = useSearchParams()
  const laborCostLocation = isCorporate
    ? getParam(LOCATIONS_QUERY_PARAM)?.split(",")
    : [locationUUID]
  const { apEnablePayrollSection } = useFlags()
  const { updateDateRangeFilters, updateRangeTypeFilter } = useDateRange()
  const startDateParam = getParam(DateQueryParam.start) ?? ""
  const endDateParam = getParam(DateQueryParam.end) ?? ""
  const {
    laborCostInfo,
    // bestSellerItemsInfo,
    // customerSatisfactionInfo,
    // discountsSummaryInfo,
    salesSummaryInfo,
    loading,
  } = useReports({
    isCorporate,
    startDate: moment(startDateParam)
      .set("hour", 0)
      .set("minute", 0)
      .toISOString(false),
    endDate: moment(endDateParam)
      .set("hour", 23)
      .set("minute", 59)
      .toISOString(false),
    locations: laborCostLocation || [],
  })

  useEffect(() => {
    if (!startDateParam || !endDateParam) {
      updateDateRangeFilters(dateRanges.today())
      updateRangeTypeFilter(DateRangeEnum.TODAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationUUID])

  useEffect(() => {
    if (!startDateParam && !endDateParam) {
      updateDateRangeFilters(dateRanges.today())
      updateRangeTypeFilter(DateRangeEnum.TODAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateParam, endDateParam, locationUUID])

  return (
    <Container fluid p={0} mb={40}>
      <Stack id="header" px={32} py={8} h={48} justify="center">
        <Group position="apart" noWrap>
          <Title order={1} size="h5">
            {intl.formatMessage({
              id: "restaurants.reports.header",
              defaultMessage: "Reports",
            })}
          </Title>
          <Group style={{ overflow: "auto" }} spacing={8} noWrap>
            <Button
              px={16}
              variant="outline"
              leftIcon={<IconCalendarEvent size={16} />}
              onClick={() => setOpenDatePicker(true)}
            >
              <Text ml={8} size="sm" weight={400}>
                {intl.formatMessage({
                  id: "restaurant.reports.header.date.range.button",
                  defaultMessage: "Date Range",
                })}
              </Text>
            </Button>
            {isCorporate && <LocationButtonDropdown />}
            <Button
              //TODO: remove hidden when export is ready
              hidden
              px={16}
              variant="outline"
              leftIcon={<IconDownload size={16} />}
            >
              <Text ml={8} size="sm" weight={400}>
                {intl.formatMessage({
                  id: "restaurant.reports.header.export.button",
                  defaultMessage: "Export",
                })}
              </Text>
            </Button>
          </Group>
        </Group>
      </Stack>
      <Divider w="100%" color="gray.2" />
      <Stack
        hidden={!startDateParam && !endDateParam}
        align="end"
        mx={32}
        mt={32}
      >
        <Badge size="xl" radius="md" variant="light">
          <Text>
            {`
            ${
              startDateParam &&
              moment(startDateParam)
                .set("hour", 0)
                .set("minute", 0)
                .local(true)
                .format("MM/DD/YYYY")
            }
            -
            ${
              endDateParam &&
              moment(endDateParam)
                .set("hour", 0)
                .set("minute", 0)
                .local(true)
                .format("MM/DD/YYYY")
            }
            `}
          </Text>
        </Badge>
      </Stack>
      <Stack mx={32} spacing={88} mt={40}>
        <SalesSummary
          data={salesSummaryInfo}
          loading={loading.financialReport}
        />
        {/*<BestSellersSection*/}
        {/*  items={bestSellerItemsInfo || []}*/}
        {/*  loading={loading.bestSellerItems}*/}
        {/*/>*/}
        {!isCorporate && apEnablePayrollSection && (
          <>
            {loading.laborCost ? (
              <LaborCostSkeleton height={208} />
            ) : (
              <LaborCostSection contentHeight={208} summary={laborCostInfo} />
            )}
          </>
        )}
        {/*<CustomerSatisfactionSection*/}
        {/*  loading={{ card: loading.financialReport, table: loading.discounts }}*/}
        {/*  contentHeight={234}*/}
        {/*  summary={customerSatisfactionInfo}*/}
        {/*  discountsSummary={discountsSummaryInfo}*/}
        {/*/>*/}
      </Stack>
      {openDatePicker && (
        <CalendarPickerModal
          opened={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
        />
      )}
    </Container>
  )
}
