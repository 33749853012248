import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  userUUID: Types.Scalars['ID'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', getCustomer: { __typename?: 'CustomerInformationModel', uuid: string, email?: string | null, firstName?: string | null, formattedPhone?: string | null, lastName?: string | null, lifeTimeSpend?: number | null, loyaltyPoints?: number | null, phone?: string | null, totalOrders?: number | null, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> } };


export const GetCustomerDocument = gql`
    query getCustomer($restaurantUUID: ID!, $userUUID: ID!) {
  getCustomer(restaurantUUID: $restaurantUUID, userUUID: $userUUID) {
    uuid
    email
    firstName
    formattedPhone
    lastName
    lifeTimeSpend
    locations {
      uuid
      name
    }
    loyaltyPoints
    phone
    formattedPhone
    totalOrders
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      userUUID: // value for 'userUUID'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;