import ListEmployees from "./ListEmployees"
import { TableSkeleton } from "./ListEmployees/TableSkeleton"
import Statistics from "./Statistics"
import styles from "./TimeEntries.module.css"
import { TimeEntriesProvider } from "./TimeEntriesContext"
import { TimeHeader } from "./TimeHeader"
import { useTimeEntries } from "./hooks/useTimeEntries"
import { Badge, Container, ScrollArea, Stack, Text } from "@mantine/core"

export const TimeEntries = () => {
  const { getTimeEntries, loading, entries, statistics, dateRange } =
    useTimeEntries()

  return (
    <TimeEntriesProvider timeEntries={getTimeEntries()}>
      <TimeHeader />
      <ScrollArea
        classNames={{
          root: styles["scroll-area"],
        }}
      >
        <Container size="md" py={32}>
          <Stack align="end">
            <Badge mb={32} size="xl" radius="md" variant="light">
              <Text>{dateRange}</Text>
            </Badge>
          </Stack>
          <Stack spacing={40}>
            <Statistics loading={loading} amounts={statistics} />
            {loading ? (
              <TableSkeleton />
            ) : (
              <ListEmployees queryResponse={entries} />
            )}
          </Stack>
        </Container>
      </ScrollArea>
    </TimeEntriesProvider>
  )
}
