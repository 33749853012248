import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import {
  DateQueryParam,
  useDateRange,
} from "../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../shared/hooks/useSearchParams"
import {
  GetCateringOrdersByRestaurantQuery,
  useGetCateringOrdersByRestaurantQuery,
} from "../Graphql/getCateringOrdersByRestaurant.generated"
import { OrderMethodEnum } from "../types"
import { CalendarDay } from "./CalendarDay"
import styles from "./CateringCalendar.module.css"
import { Box } from "@mantine/core"
import { Calendar } from "@mantine/dates"
import { useDebouncedValue } from "@mantine/hooks"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import moment from "moment-timezone"
import { useEffect, useRef } from "react"
import { useParams } from "react-router-dom"

export const CateringCalendar = () => {
  const calendarRef = useRef<HTMLDivElement>(null)
  const { locationUUID } = useParams<{ locationUUID: string }>()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()
  const { getParam } = useSearchParams()
  const { updateDateRangeFilters } = useDateRange()

  const startDateParam = getParam(DateQueryParam.start) ?? ""
  const endDateParam = getParam(DateQueryParam.end) ?? ""

  const [debouncedStartDate] = useDebouncedValue(startDateParam, 1000)
  const [debouncedEndDate] = useDebouncedValue(endDateParam, 1000)

  const { data, fetchMore, loading } = useGetCateringOrdersByRestaurantQuery({
    variables: {
      restaurantUUID: currentRestaurantUUID,
      startDate: moment(debouncedStartDate)
        .set("hour", 0)
        .set("minute", 0)
        .toISOString(false),
      endDate: moment(debouncedEndDate)
        .set("hour", 23)
        .set("minute", 59)
        .toISOString(false),
      orderMethods: [OrderMethodEnum.CATERING],
      ...(!!locationUUID && {
        locationUUIDs: [locationUUID],
      }),
    },
    skip: !currentRestaurantUUID || !startDateParam || !endDateParam,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const cateringOrders = data?.getOrdersByRestaurant.results ?? []
  const endCursor = data?.getOrdersByRestaurant.endCursor ?? undefined

  // Disable calendar header button
  useEffect(() => {
    const headerButton = calendarRef.current?.querySelector(
      ".mantine-CalendarHeader-calendarHeaderLevel"
    )

    if (headerButton) {
      headerButton.setAttribute("disabled", "true")
    }
  }, [])

  useEffect(() => {
    const fetchMoreAllData = async () => {
      await fetchMore({
        variables: { currentRestaurantUUID, after: endCursor, take: 20 },
        updateQuery: (
          prev: GetCateringOrdersByRestaurantQuery,
          { fetchMoreResult }
        ) => {
          if (
            !fetchMoreResult ||
            prev?.getOrdersByRestaurant?.endCursor ===
              fetchMoreResult?.getOrdersByRestaurant?.endCursor
          ) {
            return prev
          }

          const newResults =
            fetchMoreResult?.getOrdersByRestaurant?.results || []
          const currentResults = prev?.getOrdersByRestaurant?.results || []

          return {
            ...prev,
            getOrdersByRestaurant: {
              ...prev.getOrdersByRestaurant,
              endCursor: fetchMoreResult?.getOrdersByRestaurant?.endCursor,
              hasNextPage: fetchMoreResult?.getOrdersByRestaurant?.hasNextPage,
              results: [...currentResults, ...newResults],
            },
          }
        },
      })
    }

    if (!endCursor) return
    fetchMoreAllData()
  }, [currentRestaurantUUID, endCursor, fetchMore])

  return (
    <Box px={24} pb={24} w="100%">
      <Calendar
        static
        ref={calendarRef}
        date={moment(startDateParam).toDate()}
        withCellSpacing={false}
        classNames={{
          month: styles["calendar-container"],
          day: styles["calendar-day"],
          weekday: styles["calendar-weekday"],
          calendarHeaderLevel: styles["calendar-header-level"],
          calendarHeaderControl: styles["calendar-header-control"],
          calendarHeader: styles["calendar-header"],
          monthLevel: styles["calendar-month-level"],
        }}
        previousIcon={
          <IconChevronLeft
            size={"1.875rem"}
            color="var(--mantine-color-gray-6)"
          />
        }
        nextIcon={
          <IconChevronRight
            size={"1.875rem"}
            color="var(--mantine-color-gray-6)"
          />
        }
        hideOutsideDates
        onNextMonth={(date) =>
          updateDateRangeFilters({
            startDate: moment(date),
            endDate: moment(date).add(1, "month").subtract(1, "day"),
          })
        }
        onPreviousMonth={(date) =>
          updateDateRangeFilters({
            startDate: moment(date),
            endDate: moment(date).add(1, "month").subtract(1, "day"),
          })
        }
        renderDay={(date) => (
          <CalendarDay
            ordersData={cateringOrders}
            date={date}
            loading={loading}
          />
        )}
      />
    </Box>
  )
}
