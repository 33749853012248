import type { HeaderProps } from "../../../Header"
import Header from "../../../Header"
import PageHeader from "../../../PageHeader"
import React from "react"
import styled from "styled-components"

export const ContainerFixed: React.FC<{
  headerProps?: HeaderProps
}> = ({ headerProps, children }) => {
  return (
    <div>
      <StyledHeader>
        <PageHeader>
          <Header {...headerProps} />
        </PageHeader>
      </StyledHeader>
      {children}
    </div>
  )
}

const HEADER_Z_INDEX = 3

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: ${HEADER_Z_INDEX};
`
