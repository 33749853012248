import TimelineItem from "antd/lib/timeline/TimelineItem"
import React from "react"
import styled from "styled-components"

export const CustomTimelineItem: React.FC = (props) => {
  return <StyledTimelineItem {...props} />
}

const StyledTimelineItem = styled(TimelineItem)`
  margin-bottom: 20px;
  &.ant-timeline-item-last {
    padding-bottom: 0px;
    margin-bottom: 0;
  }
`
