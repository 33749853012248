import { toFormat } from "../../../../../../shared/utils/currency/toFormat.dinero"
import Container from "../../../../../../ui/Container"
import Text from "../../../../../../ui/Typography/Text"
import type { OrderDetailModalForm } from "../hookforms.interfaces"
import { Alert } from "antd"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export const RefundedOrderSummary: React.FC = () => {
  const intl = useIntl()

  const { watch } = useFormContext<OrderDetailModalForm>()
  const orderDetail = watch("orderDetail")

  return orderDetail?.refundInformation ? (
    <Container>
      <Alert
        type="error"
        message={intl.formatMessage({
          id: "restaurants.orders.order.detail.order.refunded.alert.order.refunded.message",
          defaultMessage: "This order was refunded!",
        })}
      />
      <Container display="flex" width="100%">
        <StyledRefundDetail display="flex" width="100%">
          <Container width="33%">
            <Text weight="bold" color="Neutral6" size="xl">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.order.refunded.alert.message",
                defaultMessage: "Total Amount Refunded",
              })}
            </Text>
            <Container width="100%">
              <Text weight="bold" color="Neutral9" size="xl">
                {toFormat(orderDetail?.refundInformation.amount)}
              </Text>
              <Text weight="bold" color="Neutral9">
                {`${toFormat(
                  orderDetail?.refundInformation.storeCash
                )} ${intl.formatMessage({
                  id: "restaurants.orders.order.detail.order.refunded.store.cash",
                  defaultMessage: "Store Cash",
                })}`}
              </Text>
            </Container>
          </Container>
          <Container>
            <Text weight="bold" color="Neutral6" size="xl">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.order.refunded.comment.label",
                defaultMessage: "Comment",
              })}
            </Text>
            <Text size="m" color="Neutral9">
              {orderDetail?.refundInformation.comment}
            </Text>
          </Container>
        </StyledRefundDetail>
      </Container>
    </Container>
  ) : null
}

const StyledRefundDetail = styled(Container)`
  padding: 16px 40px;
  ${({ theme }) => css`
    background-color: ${theme.colors["Neutral2"]};
  `}
`
