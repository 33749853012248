import type { GetAllLocationsByUserQuery } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetAllLocationsByUserQuery } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { MenuTypeEnum } from "../../../../shared/graphql/generated/types"
import type {
  LocationModel,
  RBasicEntityData,
} from "../../../../shared/graphql/generated/types"
import Alert from "../../../../ui/Alert"
import Checkbox from "../../../../ui/Checkbox"
import Container from "../../../../ui/Container"
import Divider from "../../../../ui/Divider"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import InputInlineText from "../../../../ui/Inputs/InputInlineText"
import Select, { OptionSelect } from "../../../../ui/Select"
import Spacer from "../../../../ui/Spacer"
import Tooltip from "../../../../ui/Tooltip"
import OwnershipTag from "../../components/OwnershipTag"
import StatusBadge from "../../components/StatusBadge"
import { getStatus } from "../../components/StatusBadge/getStatus"
import { CustomMenuCategoryTypeTitle } from "../titles/menuType.title"
import { ComboList } from "./ComboList.tsx/ComboList"
import ItemList from "./ItemList"
import type { IMenuForm } from "./hookforms.interfaces"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useFlags } from "launchdarkly-react-client-sdk"
import debounce from "lodash/debounce"
import get from "lodash/get"
import React, { useCallback, useMemo, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt } from "react-router-dom"

const GET_LOCATIONS_TAKE = 10

interface IMenuFormProps {
  currentLocations?: RBasicEntityData[]
}

const MenuForm: React.FC<IMenuFormProps> = ({ currentLocations = [] }) => {
  const intl = useIntl()

  const { combos } = useFlags()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const {
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useFormContext<IMenuForm>()

  const hasSnapshot = watch("hasSnapshot")
  const publishedAt = watch("lastPublishedAt")
  const menuUUID = watch("uuid")

  const labelAllLocations = intl.formatMessage({
    id: "restaurants.menus.dropdown.locations.checkbox.all.locations",
    defaultMessage: "All locations",
  })

  const valueAllLocations = "all-locations"

  const [searchValue, setSearchValue] = useState<string>()

  const {
    data: locationData,
    loading: loadingLocations,
    refetch: refetchLocations,
    fetchMore,
  } = useGetAllLocationsByUserQuery({
    variables: { userUUID, restaurantUUID, take: GET_LOCATIONS_TAKE },
    skip: !restaurantUUID,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
    onError: showGraphqlErrors,
  })

  const isAllLocations = watch().applyToAllLocations
  const isMain = watch("isMain")

  const locations =
    locationData?.getAllLocationsByUser.results?.map((location) => ({
      value: location.uuid,
      label: location.name,
    })) || []

  currentLocations?.map((currentLocation) => {
    if (
      !locations
        .map((location) => location.value)
        .includes(currentLocation.uuid)
    )
      locations?.push({
        value: currentLocation.uuid,
        label: currentLocation.name,
      })
  })

  const hasNextPage = get(
    locationData,
    "getAllLocationsByUser.hasNextPage",
    false
  )
  const endCursor = get(locationData, "getAllLocationsByUser.endCursor", "")

  const tooltipDefaultMessage = isMain
    ? "{isPublished,plural,=0 {Hidden from locations} other {Visible to locations}} "
    : "{isPublished,plural,=0 {Hidden from customers} other {Visible to customers}} "

  const menuTypeOptions = useMemo(
    () =>
      (Object.keys(MenuTypeEnum) as Array<`${MenuTypeEnum}`>).map((value) => {
        return {
          label: intl.formatMessage(CustomMenuCategoryTypeTitle[value]),
          value,
        }
      }),
    [intl]
  )

  const fetchMoreLocations = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          restaurantUUID,
          after: endCursor,
          take: GET_LOCATIONS_TAKE,
        },
        updateQuery: (
          prev: GetAllLocationsByUserQuery,
          { fetchMoreResult }
        ) => {
          if (
            !fetchMoreResult ||
            prev?.getAllLocationsByUser?.endCursor ===
              fetchMoreResult?.getAllLocationsByUser?.endCursor
          ) {
            return prev
          }

          return {
            getAllLocationsByUser: {
              ...fetchMoreResult?.getAllLocationsByUser,
              results: [
                ...(prev.getAllLocationsByUser.results as Array<LocationModel>),
                ...(fetchMoreResult?.getAllLocationsByUser
                  .results as Array<LocationModel>),
              ],
            },
          }
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [restaurantUUID, endCursor, fetchMore])

  const onChangeAllLocations = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setValue("applyToAllLocations", checked, {
      shouldDirty: true,
      shouldValidate: true,
    })

    if (checked) {
      setValue("locations", [])
    }
  }

  const onSearchLocations = React.useMemo(() => {
    const onSearch = async (value: string) => {
      try {
        await refetchLocations({
          restaurantUUID,
          ...(!!value && { name: value }),
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    return debounce(onSearch, 600)
  }, [refetchLocations, restaurantUUID])

  const onSelectClear = () => {
    setSearchValue(undefined)
    onSearchLocations("")
  }

  const onPopupScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLElement

    if (
      !loadingLocations &&
      hasNextPage &&
      !!endCursor &&
      target?.scrollTop + target?.offsetHeight === target?.scrollHeight
    ) {
      fetchMoreLocations()
    }
  }

  const onSearch = (value: string) => {
    setSearchValue(value)

    if (!value) {
      onSearchLocations(value)
    }

    if (value) {
      onSelectClear()
    }
  }

  return (
    <>
      {hasSnapshot && !!publishedAt && (
        <Container padding="0 0 64px 0">
          <Alert
            type="warning"
            message={intl.formatMessage({
              id: "restaurants.menus.menu.form.alert.message",
              defaultMessage: "You’ve unpublished changes",
            })}
            description={intl.formatMessage({
              id: "restaurants.menus.menu.form.alert.description",
              defaultMessage:
                "Your changes have been saved but are not published yet. Click the “Publish” button so changes become visible to your selected locations.",
            })}
            showIcon
          />
        </Container>
      )}

      <Container>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <InputInlineText
              {...field}
              data-testid="menu-name-input"
              placeholder={intl.formatMessage({
                id: "restaurants.menus.menu.form.name.placeholder",
                defaultMessage: "Name",
              })}
              typeText="title"
              weight="bold"
              hasError={!!errors.name}
            />
          )}
        />
        <Spacer size={12} />
        <Container width="fit-content">
          <Controller
            name="isPublished"
            control={control}
            render={({ field }) => {
              return (
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage(
                    {
                      id: "restaurants.menus.menu.form.published.tooltip",
                      defaultMessage: tooltipDefaultMessage,
                    },
                    {
                      isPublished: Number(field.value),
                    }
                  )}
                >
                  <StatusBadge
                    status={getStatus({
                      isPublished: !!publishedAt,
                      hasSnapshot: !!hasSnapshot,
                      uuidExists: !!menuUUID,
                    })}
                  />
                </Tooltip>
              )
            }}
          />
        </Container>
        {!isMain && (
          <>
            <Spacer size={8} />
            <OwnershipTag isMain={isMain} />
          </>
        )}
      </Container>
      <Spacer size={64} />
      <Container display="flex" flexDirection="column" gap="64px">
        <Controller
          control={control}
          name="menuType"
          render={({ field }) => (
            <Select
              label={intl.formatMessage({
                defaultMessage: "Type",
                id: "restaurants.menus.menu.form.type.label",
              })}
              value={field.value}
              onChange={field.onChange}
              options={menuTypeOptions}
            />
          )}
        />
        {isMain && (
          <Controller
            control={control}
            name="locations"
            render={({ field }) => (
              <Select
                label={intl.formatMessage({
                  defaultMessage: "Who can view my category",
                  id: "restaurants.menus.menu.form.locations.label",
                })}
                mode="multiple"
                optionFilterProp="label"
                searchValue={searchValue}
                loading={loadingLocations}
                onSearch={onSearch}
                options={locations}
                onPopupScroll={onPopupScroll}
                onChangeWithCheckbox={(locationOptions) => {
                  field.onChange(
                    locationOptions.length > 0
                      ? locationOptions.filter(
                          (location) => location !== valueAllLocations
                        )
                      : [valueAllLocations]
                  )

                  const result =
                    locations
                      ?.filter((item) => locationOptions.includes(item.value))
                      .map((item) => item.label) ?? []

                  setValue("locationLabels", result)
                  const applyToAllLocations = !locationOptions?.length
                  setValue("applyToAllLocations", applyToAllLocations)
                }}
                value={isAllLocations ? [valueAllLocations] : field.value}
                dropdownHeader={
                  <OptionSelect
                    className="option-select-checkbox no-background"
                    key={valueAllLocations}
                    value={valueAllLocations}
                    label={labelAllLocations}
                  >
                    <Container
                      padding="4px 12px"
                      className="option-select-checkbox-selected"
                    >
                      <Checkbox
                        classId="select-all-locations-checkbox"
                        content={labelAllLocations}
                        onChange={onChangeAllLocations}
                        checked={isAllLocations}
                        indeterminate={!isAllLocations}
                        disabled={isAllLocations}
                      />
                    </Container>

                    <Divider verticalMargin="4px" />
                  </OptionSelect>
                }
                withCheckbox
                allowSearch
              />
            )}
          />
        )}
        <Container>
          <ItemList />
        </Container>
        {combos && (
          <Container>
            <ComboList />
          </Container>
        )}
      </Container>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </>
  )
}

export default MenuForm
