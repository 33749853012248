import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesSummaryQueryVariables = Types.Exact<{
  endDate: Types.Scalars['String'];
  startDate: Types.Scalars['String'];
  locations: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type SalesSummaryQuery = { __typename?: 'Query', salesSummary: { __typename?: 'SalesSummary', sales: { __typename?: 'Sales', totalSales: { __typename?: 'TotalSales', amount: number, returns: number, taxes: number, tips: number, netSales: { __typename?: 'NetSales', amount: number, discounts: number, storeCashes: number, grossSales: { __typename?: 'GrossSales', amount: number, items: number } } } }, payments: { __typename?: 'Payments', netTotal: { __typename?: 'NetTotal', amount: number, fees: number, totalCollects: Array<{ __typename?: 'TotalCollects', amount: number, paymentMethod: Types.PaymentMethodTypeEnum }> } } } };


export const SalesSummaryDocument = gql`
    query SalesSummary($endDate: String!, $startDate: String!, $locations: [String!]!) {
  salesSummary(endDate: $endDate, startDate: $startDate, locations: $locations) {
    sales {
      totalSales {
        amount
        netSales {
          amount
          discounts
          grossSales {
            amount
            items
          }
          storeCashes
        }
        returns
        taxes
        tips
      }
    }
    payments {
      netTotal {
        amount
        fees
        totalCollects {
          amount
          paymentMethod
        }
      }
    }
  }
}
    `;

/**
 * __useSalesSummaryQuery__
 *
 * To run a query within a React component, call `useSalesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesSummaryQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      locations: // value for 'locations'
 *   },
 * });
 */
export function useSalesSummaryQuery(baseOptions: Apollo.QueryHookOptions<SalesSummaryQuery, SalesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesSummaryQuery, SalesSummaryQueryVariables>(SalesSummaryDocument, options);
      }
export function useSalesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesSummaryQuery, SalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesSummaryQuery, SalesSummaryQueryVariables>(SalesSummaryDocument, options);
        }
export type SalesSummaryQueryHookResult = ReturnType<typeof useSalesSummaryQuery>;
export type SalesSummaryLazyQueryHookResult = ReturnType<typeof useSalesSummaryLazyQuery>;
export type SalesSummaryQueryResult = Apollo.QueryResult<SalesSummaryQuery, SalesSummaryQueryVariables>;