import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveCorporateModifierGroupByLocationMutationVariables = Types.Exact<{
  input: Types.RSaveCorporateModifierGroupInput;
}>;


export type SaveCorporateModifierGroupByLocationMutation = { __typename?: 'Mutation', saveCorporateModifierGroupByLocation: { __typename?: 'RSavedCorporateModifierGroup', hasSnapshot: boolean, isMain: boolean, isPublished: boolean, name: string, uuid: string } };


export const SaveCorporateModifierGroupByLocationDocument = gql`
    mutation saveCorporateModifierGroupByLocation($input: RSaveCorporateModifierGroupInput!) {
  saveCorporateModifierGroupByLocation(input: $input) {
    hasSnapshot
    isMain
    isPublished
    name
    uuid
  }
}
    `;
export type SaveCorporateModifierGroupByLocationMutationFn = Apollo.MutationFunction<SaveCorporateModifierGroupByLocationMutation, SaveCorporateModifierGroupByLocationMutationVariables>;

/**
 * __useSaveCorporateModifierGroupByLocationMutation__
 *
 * To run a mutation, you first call `useSaveCorporateModifierGroupByLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCorporateModifierGroupByLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCorporateModifierGroupByLocationMutation, { data, loading, error }] = useSaveCorporateModifierGroupByLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCorporateModifierGroupByLocationMutation(baseOptions?: Apollo.MutationHookOptions<SaveCorporateModifierGroupByLocationMutation, SaveCorporateModifierGroupByLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCorporateModifierGroupByLocationMutation, SaveCorporateModifierGroupByLocationMutationVariables>(SaveCorporateModifierGroupByLocationDocument, options);
      }
export type SaveCorporateModifierGroupByLocationMutationHookResult = ReturnType<typeof useSaveCorporateModifierGroupByLocationMutation>;
export type SaveCorporateModifierGroupByLocationMutationResult = Apollo.MutationResult<SaveCorporateModifierGroupByLocationMutation>;
export type SaveCorporateModifierGroupByLocationMutationOptions = Apollo.BaseMutationOptions<SaveCorporateModifierGroupByLocationMutation, SaveCorporateModifierGroupByLocationMutationVariables>;