import deburr from "lodash/deburr"
import toLower from "lodash/toLower"

const formatUrlIdentifier = (value: string) =>
  deburr(toLower(value))
    .replaceAll(/( +|_+)|[^\w ]/g, "-")
    .replaceAll(/-{2,}/g, "-")
    .replaceAll(/^-+|-+$/g, "")

export default formatUrlIdentifier
