import React, { useContext, useState } from "react"

interface FullModalProviderProps {
  modalId: string | undefined
  setModalId: React.Dispatch<React.SetStateAction<string | undefined>>
  clearModal: () => void
}

const FullModalContext = React.createContext<FullModalProviderProps | null>(
  null
)

export const FullModalProvider: React.FC = ({ children }) => {
  const [modalId, setModalId] = useState<string | undefined>()

  const clearModal = () => {
    setModalId(undefined)
  }

  const value: FullModalProviderProps = {
    modalId,
    setModalId,
    clearModal,
  }

  return (
    <FullModalContext.Provider value={value}>
      {children}
    </FullModalContext.Provider>
  )
}

export const useFullModalContext = () => {
  const context = useContext(FullModalContext)

  if (context === null) {
    throw new Error(
      "useFullModalContext must be used within a FullModalProvider"
    )
  }

  return context
}
