import { GetModifierGroupDetailDocument } from "../../../../GraphQL/Queries/getModifierGroupDetail.generated"
import type {
  RPublishModifierGroupInput,
  RSaveCorporateModifierGroupInput,
} from "../../../../shared/graphql/generated/types"
import notification from "../../../../ui/notification"
import type { DeleteModifierGroupMutation } from "../GraphQL/deleteModifierGroup.generated"
import { useDeleteModifierGroupMutation } from "../GraphQL/deleteModifierGroup.generated"
import type { PublishDraftModifierGroupMutation } from "../GraphQL/publishDraftModifierGroup.generated"
import { usePublishDraftModifierGroupMutation } from "../GraphQL/publishDraftModifierGroup.generated"
import type { PublishModifierGroupMutation } from "../GraphQL/publishModifierGroup.generated"
import { usePublishModifierGroupMutation } from "../GraphQL/publishModifierGroup.generated"
import type { SaveCorporateModifierGroupByLocationMutation } from "../GraphQL/saveCoorporateModifierGroupByLocation.generated"
import { useSaveCorporateModifierGroupByLocationMutation } from "../GraphQL/saveCoorporateModifierGroupByLocation.generated"
import type { SaveModifierGroupMutation } from "../GraphQL/saveModifierGroup.generated"
import { useSaveModifierGroupMutation } from "../GraphQL/saveModifierGroup.generated"
import type {
  UnpublishModifierGroupMutation,
  UnpublishModifierGroupMutationVariables,
} from "../GraphQL/unpublishModifierGroup.generated"
import { useUnpublishModifierGroupMutation } from "../GraphQL/unpublishModifierGroup.generated"
import type { IModifierGroupForm } from "../ModifierGroupForm/hookforms.interfaces"
import {
  deleteModifierGroupFromCache,
  saveModifierGroupInCache,
} from "../utils/cache-modifier-groups/modifier-group-cache-config"
import { omit } from "lodash"
import { useIntl } from "react-intl"

type UseModifierProps = { search?: string }

const useModifierGroups = ({ search }: UseModifierProps) => {
  const intl = useIntl()

  const [saveModifierGroupMutation, modifierGroupSaved] =
    useSaveModifierGroupMutation({
      onCompleted: (result: SaveModifierGroupMutation) => {
        const { uuid, name, isPublished, hasSnapshot, isMain } =
          result.saveModifierGroup

        const saveModifierGroup = {
          uuid,
          name,
          isPublished,
          hasSnapshot,
          isMain,
        }

        saveModifierGroupInCache({
          data: saveModifierGroup,
          variables: { name: search },
        })
      },
    })

  const saveModifierGroup = async (
    formData: IModifierGroupForm,
    onSuccess?: (result: SaveModifierGroupMutation) => void,
    onError?: (error: Error) => void
  ) => {
    const { isMain, name, modifierGroupUUID, modifiers, modifiersDelete } =
      formData

    const listModifiers = modifiersDelete?.length
      ? modifiers.concat(modifiersDelete)
      : modifiers

    const modifiersToSave = listModifiers?.[0]?.ingredient.uuid
      ? listModifiers.map((modifier) => ({
          uuid: modifier.uuid ?? null,
          deletedAt: modifier.deletedAt,
          ingredient: {
            uuid: modifier.ingredient.uuid,
          },
          isSoldOut: modifier.isSoldOut ?? true,
          isVisible: modifier.isVisible ?? true,
        }))
      : []

    try {
      const { data } = await saveModifierGroupMutation({
        variables: {
          input: {
            uuid: modifierGroupUUID,
            name,
            isMain,
            modifiers: modifiersToSave,
          },
        },
      })

      if (data?.saveModifierGroup.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishDraftModifierGroupMutation, draftModifierGroupPublished] =
    usePublishDraftModifierGroupMutation({
      onCompleted: (result) => {
        saveModifierGroupInCache({
          data: {
            uuid: result.publishDraftModifierGroup.uuid,
            isPublished: true,
            hasSnapshot: false,
          },
          variables: { name: search },
        })
      },
    })

  const publishDraftModifierGroup = async (
    modifierUUID?: string,
    onSuccess?: (result: PublishDraftModifierGroupMutation) => void,
    onError?: (eror: Error) => void
  ) => {
    try {
      if (!modifierUUID) {
        throw new Error(
          intl.formatMessage({
            id: "restaurants.modifiers.use.modifiers.publish.modifier.error.message",
            defaultMessage:
              "Modifier UUID is required to publish a draft modifier",
          })
        )
      }

      const { data } = await publishDraftModifierGroupMutation({
        variables: { uuid: modifierUUID },
      })

      if (data?.publishDraftModifierGroup.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishModifierGroupMutation, modifierGroupPublished] =
    usePublishModifierGroupMutation({
      onCompleted: (result) => {
        saveModifierGroupInCache({
          data: omit(result.publishModifierGroup, "__typename"),
          variables: { name: search },
        })
      },
    })

  const publishModifierGroup = async (
    publishData: RPublishModifierGroupInput,
    onSuccess?: (result: PublishModifierGroupMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await publishModifierGroupMutation({
        variables: {
          input: { ...publishData },
        },
      })

      if (data?.publishModifierGroup.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [deleteModifierGroupMutation, modifierGroupDeleted] =
    useDeleteModifierGroupMutation({
      onCompleted: (result: DeleteModifierGroupMutation) => {
        deleteModifierGroupFromCache({
          data: { uuid: result.deleteModifierGroup.uuid },
          variables: { name: search },
        })
      },
    })

  const [unpublishModifierGroupMutation, modifierGroupUnpublished] =
    useUnpublishModifierGroupMutation({
      onCompleted: (result: UnpublishModifierGroupMutation) => {
        saveModifierGroupInCache({
          data: {
            uuid: result.unpublishModifierGroup.uuid,
            isPublished: false,
          },
          variables: { name: search },
        })
      },
    })

  const unpublishModifierGroup = async (
    uuid: UnpublishModifierGroupMutationVariables["uuid"],
    onSuccess?: (result: UnpublishModifierGroupMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await unpublishModifierGroupMutation({
        variables: { uuid },
      })

      if (data?.unpublishModifierGroup.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const deleteModifierGroup = async (
    uuid: string,
    onSuccess?: (result: DeleteModifierGroupMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await deleteModifierGroupMutation({
        variables: { uuid },
      })

      if (data?.deleteModifierGroup.uuid) {
        notification({
          title: intl.formatMessage({
            id: "restaurants.modifiers.use.modifiers.delete.modifier.success.title",
            defaultMessage: "Modifier group deleted",
          }),
          description: intl.formatMessage({
            id: "restaurants.modifiers.use.modifiers.delete.modifier.success.description",
            defaultMessage: "Your modifier group was deleted",
          }),
          type: "success",
        })
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [
    saveCorporateModifierGroupByLocationMutation,
    corporateModifierGroupSaved,
  ] = useSaveCorporateModifierGroupByLocationMutation({
    refetchQueries: [GetModifierGroupDetailDocument],
  })

  const saveCorporateModifierGroup = async (
    input: RSaveCorporateModifierGroupInput,
    onSuccess?: (result: SaveCorporateModifierGroupByLocationMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await saveCorporateModifierGroupByLocationMutation({
        variables: { input },
      })

      if (data?.saveCorporateModifierGroupByLocation.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  return {
    saveModifierGroup,
    modifierGroupSaved,
    publishDraftModifierGroup,
    draftModifierGroupPublished,
    publishModifierGroup,
    modifierGroupPublished,
    deleteModifierGroup,
    modifierGroupDeleted,
    unpublishModifierGroup,
    modifierGroupUnpublished,
    saveCorporateModifierGroup,
    corporateModifierGroupSaved,
  }
}

export default useModifierGroups
