import { useFilters } from "../../../../../../../shared/contexts/FilterProvider"
import type { RPublishItemInput } from "../../../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { useItems } from "../../../hooks/useItems"
import React from "react"
import { useIntl } from "react-intl"

interface PublishItemModalProps {
  itemUUID: string
  itemData?: RPublishItemInput
  onOk: () => void
  onCancel: () => void
}

export const PublishItemModal: React.FC<PublishItemModalProps> = ({
  itemData,
  itemUUID,
  onOk,
  onCancel,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { publishItem, publishDraftItem } = useItems({ search })

  const onPublish = async () => {
    if (itemData) {
      await publishItem(itemData, onOk, showGraphqlErrors)

      return
    }

    await publishDraftItem(itemUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.menu.items.components.modals.publish.item.title",
        defaultMessage: "Publish",
      })}
      subtitle={intl.formatMessage({
        id: "restaurants.menu.items.components.modals.publish.item.subtitle",
        defaultMessage:
          "This menu item is about to become visible to all your locations.",
      })}
      loading={false}
      onConfirm={onPublish}
      onCancel={onCancel}
      type="info"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.menu.items.components.modals.publish.item.confirm.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.menu.items.components.modals.publish.item.cancel.button",
        defaultMessage: "Publish",
      })}
      visible
      centered
    />
  )
}
