import type { LocationBasicModel } from "../../../../../shared/graphql/generated/types"
import { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"
import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import formatIsoStringDate from "../../../../../shared/utils/helpers/formatIsoStringDate"
import Text from "../../../../../ui/Typography/Text"
import OrderStatusBadge from "../../../Orders/OrderStatusBadge"
import type { IOrderRowModel } from "../../../Orders/datatable.interfaces"
import { FormattedMessage } from "react-intl"

const tableColumns = <T extends IOrderRowModel>() => {
  const columns = [
    {
      dataIndex: "orderNumber",
      title: (
        <FormattedMessage
          id="restaurants.customers.customer.detail.datatable.header.order.number"
          defaultMessage="Order number"
        />
      ),
      width: "22%",
      render: (orderNumber: T) => {
        return `#${orderNumber}`
      },
    },
    {
      dataIndex: "createdAt",
      title: (
        <FormattedMessage
          id="restaurants.customers.customer.detail.datatable.header.date"
          defaultMessage="Date"
        />
      ),
      width: "22%",
      render: (createdAt: string) => {
        return formatIsoStringDate(createdAt)
      },
    },
    {
      dataIndex: "status",
      title: (
        <FormattedMessage
          id="restaurants.customers.customer.detail.datatable.header.status"
          defaultMessage="Status"
        />
      ),
      width: "22%",
      render: (status: string) => {
        const orderStatus = Object.keys(OrderStatusEnum).find(
          (key) =>
            OrderStatusEnum[key as keyof typeof OrderStatusEnum] === status
        ) as keyof typeof OrderStatusEnum

        return status ? <OrderStatusBadge status={orderStatus} /> : null
      },
    },
    {
      dataIndex: "location",
      title: (
        <FormattedMessage
          id="restaurants.customers.customer.detail.datatable.header.location"
          defaultMessage="Location"
        />
      ),
      width: "22%",
      render: (location: LocationBasicModel) => {
        return location.name
      },
    },
    {
      dataIndex: "total",
      //TODO: Review ellipsis
      title: (
        <Text size="s" weight="bold" textAlign="right" className="ellipsis">
          <FormattedMessage
            id="restaurants.customers.customer.detail.datatable.header.total"
            defaultMessage="Total"
          />
        </Text>
      ),
      width: "12%",
      render: (total: T) => {
        return (
          <Text size="s" textAlign="right">
            {toFormat(Number(total))}
          </Text>
        )
      },
    },
  ]

  return [columns]
}

export default tableColumns
