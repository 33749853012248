import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LaborCostSummaryQueryVariables = Types.Exact<{
  endDate: Types.Scalars['String'];
  locations: Array<Types.Scalars['String']> | Types.Scalars['String'];
  startDate: Types.Scalars['String'];
}>;


export type LaborCostSummaryQuery = { __typename?: 'Query', laborCostSummary: { __typename?: 'LaborCostSummary', currentPeriod?: { __typename?: 'LaborCostReport', payroll: number, tips: number, employees?: Array<{ __typename?: 'TimeEntriesReport', duration: number, id: string, laborCost: number, name: string, payRate: number, tip: number }> | null } | null, previousPeriod?: { __typename?: 'LaborCostReport', payroll: number, tips: number, employees?: Array<{ __typename?: 'TimeEntriesReport', duration: number, id: string, laborCost: number, name: string, payRate: number, tip: number }> | null } | null } };


export const LaborCostSummaryDocument = gql`
    query laborCostSummary($endDate: String!, $locations: [String!]!, $startDate: String!) {
  laborCostSummary(
    endDate: $endDate
    locations: $locations
    startDate: $startDate
  ) {
    currentPeriod {
      employees {
        duration
        id
        laborCost
        name
        payRate
        tip
      }
      payroll
      tips
    }
    previousPeriod {
      payroll
      employees {
        duration
        id
        laborCost
        name
        payRate
        tip
      }
      tips
    }
  }
}
    `;

/**
 * __useLaborCostSummaryQuery__
 *
 * To run a query within a React component, call `useLaborCostSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborCostSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborCostSummaryQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      locations: // value for 'locations'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useLaborCostSummaryQuery(baseOptions: Apollo.QueryHookOptions<LaborCostSummaryQuery, LaborCostSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LaborCostSummaryQuery, LaborCostSummaryQueryVariables>(LaborCostSummaryDocument, options);
      }
export function useLaborCostSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LaborCostSummaryQuery, LaborCostSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LaborCostSummaryQuery, LaborCostSummaryQueryVariables>(LaborCostSummaryDocument, options);
        }
export type LaborCostSummaryQueryHookResult = ReturnType<typeof useLaborCostSummaryQuery>;
export type LaborCostSummaryLazyQueryHookResult = ReturnType<typeof useLaborCostSummaryLazyQuery>;
export type LaborCostSummaryQueryResult = Apollo.QueryResult<LaborCostSummaryQuery, LaborCostSummaryQueryVariables>;