import {
  ALL_DAY_VALUE,
  HH_mm,
  hh_mm,
} from "../../../../shared/utils/constant/dateFormats"
import { getMeridium } from "../../../../shared/utils/helpers/getMeridiem"
import { DAY_START_HOUR } from "../../../../ui/CustomTimePicker/constants"
import { MeridiemEnum } from "../../../../ui/TimePickerSelect"
import { Meridiem } from "../interfaces/hookforms.interfaces"
import moment from "moment-timezone"

export const getDateRule = (
  fetchedStartDate: string,
  fetchedEndDate: string | undefined
) => {
  const parsedStartDate = moment(fetchedStartDate)
  const isAllDay = parsedStartDate.format(HH_mm) === DAY_START_HOUR

  const parsedEndDate = fetchedEndDate ? moment(fetchedEndDate) : undefined
  const addEndTime = Boolean(parsedEndDate)

  const startTimeMeridiem: Meridiem | undefined = getMeridium(
    parsedStartDate.format(HH_mm)
  )
  const endTimeMeridiem: Meridiem | undefined = parsedEndDate
    ? getMeridium(parsedEndDate.format(HH_mm))
    : MeridiemEnum.AM

  const startDate = {
    date: parsedStartDate.toISOString(),
    hour: {
      time: isAllDay ? ALL_DAY_VALUE : parsedStartDate.format(hh_mm),
      meridium: startTimeMeridiem,
    },
  }

  const endDate = parsedEndDate
    ? {
        date: parsedEndDate.toISOString(),
        hour: {
          time: parsedEndDate.format(hh_mm),
          meridium: endTimeMeridiem,
        },
      }
    : undefined

  return { startDate, endDate, addEndTime }
}
