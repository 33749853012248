import type {
  IngredientModel,
  PaginatedIngredientModel,
} from "../../../../../shared/graphql/generated/types"
import type { GetAllModifiersByRestaurantQueryVariables } from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import {
  createIngredient,
  deleteIngredient,
  updateIngredient,
  updateIngredientList,
} from "./ingredients-cache-methods"

type GetAllIngredientsByRestaurantVariables = Omit<
  GetAllModifiersByRestaurantQueryVariables,
  "restaurantUUID"
>

export const GET_MODIFIERS_CRUD_TAKE = 8

export const variablesModifiers: GetAllIngredientsByRestaurantVariables = {
  take: GET_MODIFIERS_CRUD_TAKE,
}

const createIngredientInCache = ({
  data,
  variables,
}: {
  data: IngredientModel
  variables: GetAllModifiersByRestaurantQueryVariables
}) => {
  createIngredient(data, variables)
}

const updateIngredientInCache = ({
  data,
  variables,
}: {
  data: IngredientModel
  variables: GetAllModifiersByRestaurantQueryVariables
}) => {
  updateIngredient(data, variables)
}

const updateIngredientListToCache = ({
  data,
  variables,
}: {
  data: PaginatedIngredientModel
  variables: GetAllModifiersByRestaurantQueryVariables
}) => {
  updateIngredientList(data, variables)
}

const deleteIngredientInCache = ({
  data,
  variables,
}: {
  data: { uuid: string }
  variables: GetAllModifiersByRestaurantQueryVariables
}) => {
  deleteIngredient(data, variables)
}

export {
  createIngredientInCache,
  updateIngredientInCache,
  updateIngredientListToCache,
  deleteIngredientInCache,
}
