import { useFilters } from "../../../../../../../shared/contexts/FilterProvider"
import type { RPublishComboInput } from "../../../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { useCombos } from "../../../hooks/useCombos"
import React from "react"
import { useIntl } from "react-intl"

interface PublishComboModalProps {
  comboUUID: string
  comboData?: RPublishComboInput
  onOk: () => void
  onCancel: () => void
}

export const PublishComboModal: React.FC<PublishComboModalProps> = ({
  comboData,
  comboUUID,
  onOk,
  onCancel,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const {
    publishDraftCombo,
    draftComboPublished,
    publishCombo,
    comboPublished,
  } = useCombos({ search })

  const onPublish = async () => {
    if (comboData) {
      await publishCombo(comboData, onOk, showGraphqlErrors)

      return
    }

    await publishDraftCombo(comboUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.publish.combo.title",
        defaultMessage: "Publish",
      })}
      subtitle={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.publish.combo.subtitle",
        defaultMessage:
          "This combo is about to become visible to all your locations.",
      })}
      loading={draftComboPublished.loading || comboPublished.loading}
      onConfirm={onPublish}
      onCancel={onCancel}
      type="info"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.publish.combo.confirm.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.publish.combo.cancel.button",
        defaultMessage: "Publish",
      })}
      visible
      centered
    />
  )
}
