import { OrderActionTypeEnum } from "../../../../shared/graphql/generated/types"
import { GetOrderSummaryQuery } from "../../Orders/OrderDetail/GraphQL/getOrderSummary.generated"

type OrderSummary = {
  uuid: string
  orderActions: Array<{
    uuid: string
    actionType: OrderActionTypeEnum
    actionAt: string
    comment?: string
    admin?: {
      uuid: string
      firstName: string
      lastName?: string
    }
  }>
  orderNumber: string
}

export const getOrderSummary = (
  data: GetOrderSummaryQuery["getOrderSummary"] | undefined
): OrderSummary | undefined => {
  if (!data) {
    return
  }

  return {
    uuid: data.uuid,
    orderActions: data.orderActions.map((action) => ({
      uuid: action.uuid,
      actionType: action.actionType,
      actionAt: action.actionAt,
      comment: action.comment ?? undefined,
      admin: action.admin
        ? {
            uuid: action.admin.uuid,
            firstName: action.admin.firstName,
            lastName: action.admin.lastName ?? undefined,
          }
        : undefined,
    })),
    orderNumber: data.orderNumber,
  }
}
