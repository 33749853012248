import * as Types from '../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUrlIdentifierQueryVariables = Types.Exact<{
  urlIdentifier: Types.Scalars['String'];
  userUuid: Types.Scalars['ID'];
}>;


export type GetUrlIdentifierQuery = { __typename?: 'Query', getUrlIdentifier: { __typename?: 'RestaurantUrlIdentifierModel', urlIdentifierReturnMessage?: string | null, isUsed: boolean } };


export const GetUrlIdentifierDocument = gql`
    query getUrlIdentifier($urlIdentifier: String!, $userUuid: ID!) {
  getUrlIdentifier(urlIdentifier: $urlIdentifier, userUUID: $userUuid) {
    urlIdentifierReturnMessage
    isUsed
  }
}
    `;

/**
 * __useGetUrlIdentifierQuery__
 *
 * To run a query within a React component, call `useGetUrlIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlIdentifierQuery({
 *   variables: {
 *      urlIdentifier: // value for 'urlIdentifier'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useGetUrlIdentifierQuery(baseOptions: Apollo.QueryHookOptions<GetUrlIdentifierQuery, GetUrlIdentifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlIdentifierQuery, GetUrlIdentifierQueryVariables>(GetUrlIdentifierDocument, options);
      }
export function useGetUrlIdentifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlIdentifierQuery, GetUrlIdentifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlIdentifierQuery, GetUrlIdentifierQueryVariables>(GetUrlIdentifierDocument, options);
        }
export type GetUrlIdentifierQueryHookResult = ReturnType<typeof useGetUrlIdentifierQuery>;
export type GetUrlIdentifierLazyQueryHookResult = ReturnType<typeof useGetUrlIdentifierLazyQuery>;
export type GetUrlIdentifierQueryResult = Apollo.QueryResult<GetUrlIdentifierQuery, GetUrlIdentifierQueryVariables>;