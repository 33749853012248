import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBankingAccountByRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetBankingAccountByRestaurantQuery = { __typename?: 'Query', getBankingAccountsByRestaurant: Array<{ __typename?: 'BankingAccountModel', uuid: string, name: string, isActive: boolean, stIsConnected: boolean, stHasExternalAccount: boolean }> };


export const GetBankingAccountByRestaurantDocument = gql`
    query getBankingAccountByRestaurant($restaurantUUID: ID!, $locationUUID: ID) {
  getBankingAccountsByRestaurant(
    restaurantUUID: $restaurantUUID
    locationUUID: $locationUUID
  ) {
    uuid
    name
    isActive
    stIsConnected
    stHasExternalAccount
  }
}
    `;

/**
 * __useGetBankingAccountByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetBankingAccountByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankingAccountByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankingAccountByRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetBankingAccountByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetBankingAccountByRestaurantQuery, GetBankingAccountByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankingAccountByRestaurantQuery, GetBankingAccountByRestaurantQueryVariables>(GetBankingAccountByRestaurantDocument, options);
      }
export function useGetBankingAccountByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankingAccountByRestaurantQuery, GetBankingAccountByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankingAccountByRestaurantQuery, GetBankingAccountByRestaurantQueryVariables>(GetBankingAccountByRestaurantDocument, options);
        }
export type GetBankingAccountByRestaurantQueryHookResult = ReturnType<typeof useGetBankingAccountByRestaurantQuery>;
export type GetBankingAccountByRestaurantLazyQueryHookResult = ReturnType<typeof useGetBankingAccountByRestaurantLazyQuery>;
export type GetBankingAccountByRestaurantQueryResult = Apollo.QueryResult<GetBankingAccountByRestaurantQuery, GetBankingAccountByRestaurantQueryVariables>;