import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateItemMutationVariables = Types.Exact<{
  data: Types.DuplicateItemInput;
}>;


export type DuplicateItemMutation = { __typename?: 'Mutation', duplicateItem: { __typename?: 'DuplicatedItem', uuid: string } };


export const DuplicateItemDocument = gql`
    mutation DuplicateItem($data: DuplicateItemInput!) {
  duplicateItem(data: $data) {
    uuid
  }
}
    `;
export type DuplicateItemMutationFn = Apollo.MutationFunction<DuplicateItemMutation, DuplicateItemMutationVariables>;

/**
 * __useDuplicateItemMutation__
 *
 * To run a mutation, you first call `useDuplicateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateItemMutation, { data, loading, error }] = useDuplicateItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDuplicateItemMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateItemMutation, DuplicateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateItemMutation, DuplicateItemMutationVariables>(DuplicateItemDocument, options);
      }
export type DuplicateItemMutationHookResult = ReturnType<typeof useDuplicateItemMutation>;
export type DuplicateItemMutationResult = Apollo.MutationResult<DuplicateItemMutation>;
export type DuplicateItemMutationOptions = Apollo.BaseMutationOptions<DuplicateItemMutation, DuplicateItemMutationVariables>;