import { CateringLabels } from "../../shared/config"
import { CateringForm } from "../../shared/hookforms.intefaces"
import { ListItem } from "../ListItem"
import { List } from "@mantine/core"
import { useFormContext, useWatch } from "react-hook-form"
import { IntlShape, useIntl } from "react-intl"

const formatAmount = (intl: IntlShape, amount?: number) => {
  const noAmount = intl.formatMessage({
    id: "terms.and.conditions.no.amount",
    defaultMessage: "None",
  })

  return amount === 0
    ? noAmount
    : intl.formatNumber(amount ?? 0, { style: "currency", currency: "USD" })
}

export const MinimumAmount = () => {
  const intl = useIntl()
  const { control } = useFormContext<CateringForm>()
  const [minimumAmount] = useWatch({
    control,
    name: ["rules.minimumAmount"],
  })

  return (
    <List>
      <ListItem
        label={CateringLabels.delivery}
        apply={minimumAmount.delivery.apply}
      >
        {formatAmount(intl, minimumAmount.delivery.amount)}
      </ListItem>
      <ListItem
        label={CateringLabels.pickup}
        apply={minimumAmount.pickup.apply}
      >
        {formatAmount(intl, minimumAmount.pickup.amount)}
      </ListItem>
      <ListItem
        label={CateringLabels.foodTruckExperience}
        apply={minimumAmount.foodTruckExperience.apply}
      >
        {formatAmount(intl, minimumAmount.foodTruckExperience.amount)}
      </ListItem>
      <ListItem
        label={CateringLabels.insideVenue}
        apply={minimumAmount.insideVenue.apply}
      >
        {formatAmount(intl, minimumAmount.insideVenue.amount)}
      </ListItem>
    </List>
  )
}
