import { CateringForm } from "../shared/hookforms.intefaces"
import { CancelationPolicy } from "./CancelationPolicy"
import { CateringFee } from "./CateringFee"
import { MinimumAmount } from "./MinimumAmount"
import { Payment } from "./Payment"
import { List, Modal, Stack, Text } from "@mantine/core"
import { ReactNode } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

const refundPolicyItems = [
  {
    id: "terms.and.conditions.refund.policy.status",
    defaultMessage:
      "Only orders with the status of 'Complete' are eligible for refund.",
  },
  {
    id: "terms.and.conditions.refund.policy.request.refund",
    defaultMessage:
      "To request a refund the customer must contact the restaurant.",
  },
  {
    id: "terms.and.conditions.refund.request.refund.and.granted",
    defaultMessage:
      "Refund requests are granted at the sole discretion of the restaurant owner or manager.",
  },
]
interface TermsAndConditionsModalProps {
  opened: boolean
  onClose: () => void
}

export const TermsAndConditionsModal = ({
  opened,
  onClose,
}: TermsAndConditionsModalProps) => {
  const intl = useIntl()

  const { control } = useFormContext<CateringForm>()

  const [orderScheduling] = useWatch({
    control,
    name: ["rules.orderScheduling.daysInAdvance"],
  })

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size="xl"
      title={intl.formatMessage({
        id: "terms.and.service.modal.title",
        defaultMessage: "Catering terms & conditions",
      })}
    >
      <Stack p={24} spacing={24}>
        <Stack spacing={4}>
          <Text fw={600}>
            {intl.formatMessage({
              id: "terms.and.conditions.order.scheduling.title",
              defaultMessage: "Order scheduling",
            })}
          </Text>
          <List>
            <List.Item>
              {intl.formatMessage({
                id: "terms.and.conditions.order.scheduling.catering.schedule.hours.of.operations",
                defaultMessage:
                  "Catering schedule follows the location’s operating hours (standard and holidays).",
              })}
            </List.Item>
            <List.Item>
              {intl.formatMessage(
                {
                  id: "terms.and.conditions.order.scheduling.catering.in.advance.notice",
                  defaultMessage:
                    "Catering orders require advance notice of <bold>{days, plural, =1 {# day} other {# days}}</bold>.",
                },
                {
                  days: orderScheduling,
                  bold: (chunks: ReactNode) => <b>{chunks}</b>,
                }
              )}
            </List.Item>
          </List>
        </Stack>
        <Stack spacing={4}>
          <Text fw={600}>
            {intl.formatMessage({
              id: "terms.and.conditions.order.minimum.order.amount.title",
              defaultMessage: "Minimum order amount",
            })}
          </Text>
          <MinimumAmount />
        </Stack>
        <Stack spacing={4}>
          <Text fw={600}>
            {intl.formatMessage({
              id: "terms.and.conditions.order.catering.fee.title",
              defaultMessage: "Catering fee",
            })}
          </Text>
          <CateringFee />
        </Stack>
        <Stack spacing={4}>
          <Text fw={600}>
            {intl.formatMessage({
              id: "terms.and.conditions.order.cancelation.policy.title",
              defaultMessage: "Cancelation Policy",
            })}
          </Text>
          <CancelationPolicy />
        </Stack>
        <Stack spacing={4}>
          <Text fw={600}>
            {intl.formatMessage({
              id: "terms.and.conditions.order.refund.policy.title",
              defaultMessage: "Refund Policy",
            })}
          </Text>
          <List>
            {refundPolicyItems.map((refundPolicy, idx) => (
              <List.Item key={idx}>
                {intl.formatMessage(refundPolicy)}
              </List.Item>
            ))}
          </List>
        </Stack>
        <Stack spacing={4}>
          <Text fw={600}>
            {intl.formatMessage({
              id: "terms.and.conditions.order.payment.title",
              defaultMessage: "Payment",
            })}
          </Text>
          <Payment />
        </Stack>
      </Stack>
    </Modal>
  )
}
