import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import useModifierGroups from "../../hooks/useModifierGroups"
import React from "react"
import { useIntl } from "react-intl"

interface DeleteModifierModalProps {
  modifierGroupUUID: string
  onOk: () => void
  onCancel: () => void
  isCorporate?: boolean
}

const DeleteDraftModifierGroupModal: React.FC<DeleteModifierModalProps> = ({
  modifierGroupUUID,
  onOk,
  onCancel,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { deleteModifierGroup, modifierGroupDeleted } = useModifierGroups({
    search,
  })

  const onDelete = async () => {
    await deleteModifierGroup(modifierGroupUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.group.title",
        defaultMessage: "Delete modifier group",
      })}
      subtitle={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.group.subtitle",
        defaultMessage: "This action cannot be undone. ",
      })}
      loading={modifierGroupDeleted.loading}
      onConfirm={onDelete}
      onCancel={onCancel}
      type="danger"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.group.cancel.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.group.confirm.button",
        defaultMessage: "Delete",
      })}
      visible
      centered
    />
  )
}

export default DeleteDraftModifierGroupModal
