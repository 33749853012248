import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateItemAttachmentMutationVariables = Types.Exact<{
  data: Types.UpdateItemAttachmentInput;
}>;


export type UpdateItemAttachmentMutation = { __typename?: 'Mutation', updateItemAttachment: { __typename?: 'AttachmentModel', uuid: string, fileName: string, signedUrl: string, ext: Types.FileExtensionEnum, contentType: Types.ContentTypeEnum } };


export const UpdateItemAttachmentDocument = gql`
    mutation updateItemAttachment($data: UpdateItemAttachmentInput!) {
  updateItemAttachment(data: $data) {
    uuid
    fileName
    signedUrl
    ext
    contentType
  }
}
    `;
export type UpdateItemAttachmentMutationFn = Apollo.MutationFunction<UpdateItemAttachmentMutation, UpdateItemAttachmentMutationVariables>;

/**
 * __useUpdateItemAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateItemAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemAttachmentMutation, { data, loading, error }] = useUpdateItemAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateItemAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemAttachmentMutation, UpdateItemAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemAttachmentMutation, UpdateItemAttachmentMutationVariables>(UpdateItemAttachmentDocument, options);
      }
export type UpdateItemAttachmentMutationHookResult = ReturnType<typeof useUpdateItemAttachmentMutation>;
export type UpdateItemAttachmentMutationResult = Apollo.MutationResult<UpdateItemAttachmentMutation>;
export type UpdateItemAttachmentMutationOptions = Apollo.BaseMutationOptions<UpdateItemAttachmentMutation, UpdateItemAttachmentMutationVariables>;