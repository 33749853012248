import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMenuListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  isMain?: Types.InputMaybe<Types.Scalars['Boolean']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.MenuStatusEnum>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  sort?: Types.InputMaybe<Types.SortingByMenuEnum>;
}>;


export type GetMenuListQuery = { __typename?: 'Query', getMenuList: { __typename?: 'PaginatedMenuList', endCursor?: string | null, hasNextPage: boolean, results?: Array<{ __typename?: 'MenuList', uuid: string, applyToAllLocations?: boolean | null, isVisible?: boolean | null, combosCount: number, hasSnapshot: boolean, isMain: boolean, itemsCount: number, menuType: Types.MenuTypeEnum, name: string, status: Types.MenuStatusEnum }> | null } };


export const GetMenuListDocument = gql`
    query getMenuList($after: String, $isMain: Boolean, $name: String, $status: MenuStatusEnum, $take: Int, $sort: SortingByMenuEnum) {
  getMenuList(
    after: $after
    isMain: $isMain
    name: $name
    status: $status
    take: $take
    sort: $sort
  ) {
    endCursor
    hasNextPage
    results {
      uuid
      applyToAllLocations
      isVisible
      combosCount
      hasSnapshot
      isMain
      itemsCount
      menuType
      name
      status
    }
  }
}
    `;

/**
 * __useGetMenuListQuery__
 *
 * To run a query within a React component, call `useGetMenuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      isMain: // value for 'isMain'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      take: // value for 'take'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetMenuListQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuListQuery, GetMenuListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuListQuery, GetMenuListQueryVariables>(GetMenuListDocument, options);
      }
export function useGetMenuListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuListQuery, GetMenuListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuListQuery, GetMenuListQueryVariables>(GetMenuListDocument, options);
        }
export type GetMenuListQueryHookResult = ReturnType<typeof useGetMenuListQuery>;
export type GetMenuListLazyQueryHookResult = ReturnType<typeof useGetMenuListLazyQuery>;
export type GetMenuListQueryResult = Apollo.QueryResult<GetMenuListQuery, GetMenuListQueryVariables>;