import { useState } from "react"

export const useModal = (): [boolean, () => void, () => void] => {
  const [isVisible, setIsVisible] = useState(false)

  const showModalConfirmation = () => setIsVisible(true)

  const dismissModalConfirmation = () => setIsVisible(false)

  return [isVisible, showModalConfirmation, dismissModalConfirmation]
}
