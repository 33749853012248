import { GetOneDiscountQuery } from "../forms/graphql/getOneDiscount.generated"

export const getAppliesTo = (
  getOneDiscount: GetOneDiscountQuery["getOneDiscount"]
) => {
  const items = getOneDiscount?.rule?.items
  const allItems = getOneDiscount?.rule?.allItems ?? false

  const combos = getOneDiscount?.rule?.combos
  const allCombos = getOneDiscount?.rule?.allCombos ?? false

  const entireOrder = allItems && allCombos

  return {
    items,
    allItems,
    combos,
    allCombos,
    entireOrder,
  }
}
