import type {
  GetItemListQuery,
  GetItemListQueryVariables,
} from "../../../../../../GraphQL/Queries/getItemList.generated"
import { GetItemListDocument } from "../../../../../../GraphQL/Queries/getItemList.generated"
import type { ApolloCache } from "@apollo/client"

type WriteCacheGetItemListQuery = {
  cache: ApolloCache<unknown>
  query: GetItemListQuery
  variables: GetItemListQueryVariables
}

const writeGetItemListQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheGetItemListQuery) =>
  cache.writeQuery<GetItemListQuery, GetItemListQueryVariables>({
    query: GetItemListDocument,
    data: query,
    variables,
  })

export default writeGetItemListQueryCache
