import { graphqlClient } from "../../../../../shared/graphql/client"
import type {
  IngredientModel,
  PaginatedIngredientModel,
} from "../../../../../shared/graphql/generated/types"
import type {
  GetAllModifiersByRestaurantQuery,
  GetAllModifiersByRestaurantQueryVariables,
} from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import readIngredientsByRestaurantQueryCache from "./ingredients-cache-read"
import writeIngredientsByRestaurantQueryCache from "./ingredients-cache-write"
import { uniqBy } from "lodash"

const cache = graphqlClient.cache

export const createIngredient = (
  data: IngredientModel,
  variables: GetAllModifiersByRestaurantQueryVariables
) => {
  const allIngredientsByRestaurantQuery = readIngredientsByRestaurantQueryCache(
    {
      cache,
      variables,
    }
  )

  const ingredients =
    allIngredientsByRestaurantQuery?.getAllIngredientsByRestaurant?.results

  if (ingredients) {
    const ingredientsUpdated = [data, ...ingredients]

    const query: GetAllModifiersByRestaurantQuery = {
      getAllIngredientsByRestaurant: {
        ...allIngredientsByRestaurantQuery.getAllIngredientsByRestaurant,
        results: ingredientsUpdated,
      },
    }

    writeIngredientsByRestaurantQueryCache({ cache, query, variables })
  }
}

export const updateIngredient = (
  data: IngredientModel,
  variables: GetAllModifiersByRestaurantQueryVariables
) => {
  const allIngredientsByRestaurantQuery = readIngredientsByRestaurantQueryCache(
    {
      cache,
      variables,
    }
  )

  const modifiers =
    allIngredientsByRestaurantQuery?.getAllIngredientsByRestaurant?.results

  if (modifiers) {
    const modifiersUpdated = modifiers.map((modifier) => {
      if (modifier.uuid === data.uuid) {
        return data
      }

      return modifier
    })

    const query: GetAllModifiersByRestaurantQuery = {
      getAllIngredientsByRestaurant: {
        ...allIngredientsByRestaurantQuery.getAllIngredientsByRestaurant,
        results: modifiersUpdated,
      },
    }

    writeIngredientsByRestaurantQueryCache({ cache, query, variables })
  }
}

export const deleteIngredient = (
  data: { uuid: string },
  variables: GetAllModifiersByRestaurantQueryVariables
) => {
  const allIngredientsByRestaurantQuery = readIngredientsByRestaurantQueryCache(
    {
      cache,
      variables,
    }
  )

  const listIngredients =
    allIngredientsByRestaurantQuery?.getAllIngredientsByRestaurant?.results

  if (listIngredients) {
    const filteredIngredients = listIngredients.filter(
      (ingredient) => ingredient.uuid !== data.uuid
    )

    const query: GetAllModifiersByRestaurantQuery = {
      getAllIngredientsByRestaurant: {
        ...allIngredientsByRestaurantQuery.getAllIngredientsByRestaurant,
        results: filteredIngredients,
      },
    }

    writeIngredientsByRestaurantQueryCache({ cache, query, variables })
  }
}

export const updateIngredientList = (
  data: PaginatedIngredientModel,
  variables: GetAllModifiersByRestaurantQueryVariables
) => {
  const allIngredientsByRestaurantQuery = readIngredientsByRestaurantQueryCache(
    {
      cache,
      variables,
    }
  )?.getAllIngredientsByRestaurant.results

  if (data.results) {
    const ingredients: IngredientModel[] = []

    allIngredientsByRestaurantQuery?.forEach((ingredient) => {
      ingredients.push({
        uuid: ingredient.uuid,
        name: ingredient.name,
        attachment: ingredient.attachment,
        containsNuts: false,
        description: "",
        iconName: "",
        imagePath: "",
        isGlutenFree: false,
        tags: [],
        isMain: ingredient.isMain,
      })
    })

    const updatedIngredients = uniqBy([...data.results, ...ingredients], "uuid")

    const query: GetAllModifiersByRestaurantQuery = {
      getAllIngredientsByRestaurant: {
        ...data,
        results: updatedIngredients,
      },
    }

    writeIngredientsByRestaurantQueryCache({ cache, query, variables })
  }
}
