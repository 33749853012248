import Alert from "../../../../ui/Alert"
import Container from "../../../../ui/Container"
import InputInlineText from "../../../../ui/Inputs/InputInlineText"
import Spacer from "../../../../ui/Spacer"
import Tooltip from "../../../../ui/Tooltip"
import StatusBadge from "../../components/StatusBadge"
import { getStatus } from "../../components/StatusBadge/getStatus"
import { ModifiersForm } from "./ModifiersForm/ModifiersForm"
import type { IModifierGroupForm } from "./hookforms.interfaces"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt } from "react-router-dom"

const ModifierGroupForm: React.FC = () => {
  const intl = useIntl()

  const {
    control,
    formState: { errors, isDirty },
    watch,
  } = useFormContext<IModifierGroupForm>()

  const modifierGroupUUID = watch("modifierGroupUUID")
  const isMain = watch("isMain")
  const hasSnapshot = watch("hasSnapshot")
  const lastPublishedAt = watch("lastPublishedAt")

  const tooltipDefaultMessage = isMain
    ? "{isPublished,plural,=0 {Hidden from locations} other {Visible to locations}} "
    : "{isPublished,plural,=0 {Hidden from customers} other {Visible to customers}} "

  return (
    <>
      {hasSnapshot && !!lastPublishedAt && (
        <Container padding="0 0 64px 0">
          <Alert
            type="warning"
            message={intl.formatMessage({
              id: "restaurants.modifiers.modifier.group.form.alert.message",
              defaultMessage: "Unpublished changes",
            })}
            description={intl.formatMessage({
              id: "restaurants.modifiers.modifier.group.form.alert.description",
              defaultMessage:
                "Your changes have been saved but are not published yet. Click the “Publish” button so changes become visible to all locations.",
            })}
            showIcon
          />
        </Container>
      )}

      <Container>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <InputInlineText
              {...field}
              placeholder={intl.formatMessage({
                id: "restaurants.modifiers.modifier.group.form.name.placeholder",
                defaultMessage: "Name",
              })}
              typeText="title"
              weight="bold"
              hasError={!!errors.name}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={8} />
        <Container width="fit-content">
          <Controller
            name="isPublished"
            control={control}
            render={({ field }) => {
              return (
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage(
                    {
                      id: "restaurants.modifiers.modifier.group.form.published.tooltip",
                      defaultMessage: tooltipDefaultMessage,
                    },
                    {
                      isPublished: Number(field.value),
                    }
                  )}
                >
                  <StatusBadge
                    status={getStatus({
                      isPublished: field.value,
                      hasSnapshot: !!hasSnapshot,
                      uuidExists: !!modifierGroupUUID,
                    })}
                  />
                </Tooltip>
              )
            }}
          />
        </Container>
        <Spacer size={64} />
        <ModifiersForm />
      </Container>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </>
  )
}

export default ModifierGroupForm
