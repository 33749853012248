import type { GetComboListQuery } from "../../../GraphQL/Queries/getComboList.generated"
import type { RComboList } from "../generated/types"

export const updateGetComboListQuery = (
  prev: GetComboListQuery,
  fetchMoreResult: GetComboListQuery | undefined
) => {
  if (
    !fetchMoreResult ||
    prev?.getComboList?.endCursor === fetchMoreResult?.getComboList?.endCursor
  ) {
    return prev
  }

  return {
    getComboList: {
      ...fetchMoreResult.getComboList,
      results: [
        ...(prev.getComboList.results as Array<RComboList>),
        ...(fetchMoreResult.getComboList.results as Array<RComboList>),
      ],
    },
  }
}
