import { YYYY_MM_DD } from "../utils/constant/dateFormats"
import {
  FIRST_HOUR,
  FIRST_MINUTE,
  FIRST_SECOND,
} from "../utils/constant/values"
import { DateRangeEnum } from "../v2/utils/dateRange.enum"
import { useSearchParams } from "./useSearchParams"
import moment, { Moment } from "moment-timezone"
import { useState } from "react"

export const DateQueryParam = {
  start: "start-date",
  end: "end-date",
  range: "range",
}

type RangeDatesType = {
  startDate: Moment | null
  endDate: Moment | null
}

export const useDateRange = () => {
  const { setParam, getParam, deleteParam } = useSearchParams()
  const paramsDate = {
    start: getParam(DateQueryParam.start),
    end: getParam(DateQueryParam.end),
  }
  const [dates, setDates] = useState<RangeDatesType>({
    startDate: moment(paramsDate.start).set({
      hour: FIRST_HOUR,
      minute: FIRST_MINUTE,
      second: FIRST_SECOND,
    }),
    endDate: moment(paramsDate.end).set({
      hour: FIRST_HOUR,
      minute: FIRST_MINUTE,
      second: FIRST_SECOND,
    }),
  })
  const [rangeType, setRangeType] = useState<DateRangeEnum>(
    (getParam(DateQueryParam.range) as DateRangeEnum) ||
      DateRangeEnum.PREVIOUS_30_DAYS
  )

  const updateDateRangeFilters = ({ startDate, endDate }: RangeDatesType) => {
    const start = startDate
      ? moment(startDate?.toISOString()).format(YYYY_MM_DD)
      : null

    const end = endDate
      ? moment(endDate?.toISOString()).format(YYYY_MM_DD)
      : null

    start && deleteParam(DateQueryParam.end)
    start && setParam("push", DateQueryParam.start, start)
    end && setParam("replace", DateQueryParam.end, end)

    setDates({ startDate, endDate })
  }

  const updateRangeTypeFilter = (dateRangeType: DateRangeEnum) => {
    setParam("replace", DateQueryParam.range, dateRangeType)
    setRangeType(dateRangeType)
  }

  return { dates, rangeType, updateDateRangeFilters, updateRangeTypeFilter }
}
