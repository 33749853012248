import Container from "../../../../../ui/Container"
import type { IContainerProps } from "../../../../../ui/Container/Container"
import React from "react"
import styled from "styled-components"

type OrderActionContainerProps = IContainerProps

export const OrderActionContainer: React.FC<OrderActionContainerProps> = (
  props
) => {
  return <StyledContainer {...props} />
}

const StyledContainer = styled(Container)`
  padding: 32px 40px;
  width: 100%;
`
