import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTempComboAttachmentMutationVariables = Types.Exact<{
  input: Types.CreateAttachmentInput;
}>;


export type CreateTempComboAttachmentMutation = { __typename?: 'Mutation', createTempComboAttachment: { __typename?: 'AttachmentModel', uuid: string, signedUrl: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string } };


export const CreateTempComboAttachmentDocument = gql`
    mutation createTempComboAttachment($input: CreateAttachmentInput!) {
  createTempComboAttachment(input: $input) {
    uuid
    signedUrl
    contentType
    ext
    fileName
  }
}
    `;
export type CreateTempComboAttachmentMutationFn = Apollo.MutationFunction<CreateTempComboAttachmentMutation, CreateTempComboAttachmentMutationVariables>;

/**
 * __useCreateTempComboAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateTempComboAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTempComboAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTempComboAttachmentMutation, { data, loading, error }] = useCreateTempComboAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTempComboAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTempComboAttachmentMutation, CreateTempComboAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTempComboAttachmentMutation, CreateTempComboAttachmentMutationVariables>(CreateTempComboAttachmentDocument, options);
      }
export type CreateTempComboAttachmentMutationHookResult = ReturnType<typeof useCreateTempComboAttachmentMutation>;
export type CreateTempComboAttachmentMutationResult = Apollo.MutationResult<CreateTempComboAttachmentMutation>;
export type CreateTempComboAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateTempComboAttachmentMutation, CreateTempComboAttachmentMutationVariables>;