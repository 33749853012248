import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import { colors } from "../../../../../styles/global/themes/mainTheme"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import type {
  OrderDetailCardType,
  OrderDetailExtraType,
} from "../OrderSummary/types/order-summary.types"
import React from "react"
import styled, { css } from "styled-components"

type OrderSummaryCardProps = {
  detail: OrderDetailCardType
}

export const OrderSummaryCard: React.FC<OrderSummaryCardProps> = ({
  detail,
}) => {
  return (
    <StyledContainer>
      <CardHeader detail={detail} />
      <CardBody detail={detail} />
      <CardExtras extras={detail.extras} />
      <Spacer size={12} />
      <CardNotes notes={detail.notes} />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors["Neutral1"]};

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.Neutral4};
    }
  `}
`

type CardHeaderProps = {
  detail: OrderDetailCardType
}

const CardHeader: React.FC<CardHeaderProps> = ({ detail }) => {
  return (
    <StyledCardHeader>
      <Text size="m" weight="bold" color="Neutral9">
        {`${detail.name}`}
      </Text>

      <Text size="m" textAlign="right">
        {`${detail.quantity} x ${toFormat(detail.price ?? 0)}`}
      </Text>
    </StyledCardHeader>
  )
}

const StyledCardHeader = styled.div`
  padding: 12px 16px 0px 16px;
  display: flex;
  justify-content: space-between;
`

type CardBodyProps = {
  detail: OrderDetailCardType
}

const CardBody: React.FC<CardBodyProps> = ({ detail }) => {
  return (
    <div>
      {detail.details.map((innerDetail, innerIndex) => {
        return (
          <div key={innerIndex}>
            <StyledCardBody>
              <Text size="s" weight="bold" color="Neutral7">
                {`${innerDetail.name}`}
              </Text>
            </StyledCardBody>

            <CardExtras extras={innerDetail.extras} />

            <StyledCardBodyNotes hidden={!innerDetail.notes}>
              <Text size="s" color="Neutral5">
                {innerDetail.notes}
              </Text>
            </StyledCardBodyNotes>
          </div>
        )
      })}
    </div>
  )
}

const StyledCardBody = styled.div`
  padding: 4px 16px 0px 16px;
  display: flex;
  justify-content: flex-start;
`

const StyledCardBodyNotes = styled.div`
  padding: 0px 16px 0px 16px;
`

type CardNotesProps = { notes?: string | null }

const CardNotes: React.FC<CardNotesProps> = (props) => {
  return (
    <StyledCardNotes hidden={!props.notes}>
      <Text size="s">{props.notes}</Text>
    </StyledCardNotes>
  )
}

const StyledCardNotes = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors["Primary1"]};
    padding: 8px 12px;
    border-top: 1px solid ${theme.colors.Neutral4};
  `}
`

type CardExtrasProps = {
  extras: Array<OrderDetailExtraType>
}

const CardExtras: React.FC<CardExtrasProps> = ({ extras }) => {
  return (
    <div>
      {extras.map((extra, extraIndex) => {
        return (
          <StyledCardExtras key={extraIndex}>
            <Text size="s" color="Neutral5">
              {`${extra.name}`}
            </Text>

            <Text size="s" color="Neutral5" textAlign="right">
              {`${extra.quantity} x ${toFormat(extra.price ?? 0)}`}
            </Text>
          </StyledCardExtras>
        )
      })}
    </div>
  )
}

const StyledCardExtras = styled.div`
  padding: 4px 16px 0px 16px;
  display: flex;
  justify-content: space-between;
`
