export const HEADER_DATA = [
  {
    id: "restaurants.menu.items.forms.modifier.groups.modifiers.content.name",
    defaultMessage: "Name",
  },
  {
    id: "restaurants.menu.items.forms.modifier.groups.modifiers.content.calories",
    defaultMessage: "Calories",
  },
  {
    id: "restaurants.menu.items.forms.modifier.groups.modifiers.content.price",
    defaultMessage: "Price",
  },
  {
    id: "restaurants.menu.items.forms.modifier.groups.modifiers.content.included",
    defaultMessage: "Included",
    justifySelf: "center",
    alignSelf: "center",
  },
]
