import {
  FIRST_HOUR,
  FIRST_MINUTE,
  FIRST_SECOND,
} from "../../../../shared/utils/constant/values"
import moment, { Moment } from "moment-timezone"

type DateRangesType = {
  startDate: Moment
  endDate: Moment
}

const withStartTime = (date: Moment) => {
  return date.set({
    hour: FIRST_HOUR,
    minute: FIRST_MINUTE,
    second: FIRST_SECOND,
  })
}

export const dateRanges = {
  today: (): DateRangesType => ({
    startDate: withStartTime(moment()),
    endDate: withStartTime(moment()),
  }),
  yesterday: (): DateRangesType => ({
    startDate: withStartTime(moment().subtract(1, "day")),
    endDate: withStartTime(
      moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, "day")
    ),
  }),
  thisWeek: (): DateRangesType => ({
    startDate: withStartTime(moment().startOf("week")),
    endDate: withStartTime(moment().endOf("week")),
  }),
  lastWeek: (): DateRangesType => ({
    startDate: withStartTime(moment().startOf("week").subtract(1, "week")),
    endDate: withStartTime(moment().endOf("week").subtract(1, "week")),
  }),
  thisMonth: (): DateRangesType => ({
    startDate: withStartTime(moment().startOf("month")),
    endDate: withStartTime(moment().endOf("month")),
  }),
  lastMonth: (): DateRangesType => ({
    startDate: withStartTime(moment().startOf("month").subtract(1, "month")),
    endDate: withStartTime(moment().endOf("month").subtract(1, "month")),
  }),
  prev30days: (): DateRangesType => ({
    startDate: withStartTime(moment().subtract(29, "days")),
    endDate: withStartTime(moment()),
  }),
  thisYear: (): DateRangesType => ({
    startDate: withStartTime(moment().startOf("year")),
    endDate: withStartTime(moment().endOf("year")),
  }),
  lastYear: (): DateRangesType => ({
    startDate: withStartTime(moment().startOf("year").subtract(1, "year")),
    endDate: withStartTime(moment().endOf("year").subtract(1, "year")),
  }),
}
