export const combosCorporateColumns = [
  {
    id: "restaurants.menu.items.combos.datatable.header.name",
    title: "Combo Name",
    width: "60%",
  },
  {
    id: "restaurants.menu.items.combos.datatable.header.status",
    title: "Status",
    width: "15%",
  },
  {
    title: "",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
]

export const combosLocationsColumns = [
  {
    id: "restaurants.menu.items.combos.datatable.header.name",
    title: "Combo Name",
    width: "60%",
  },
  {
    id: "restaurants.menu.items.combos.datatable.header.status",
    title: "Status",
    width: "15%",
  },
  {
    title: "",
    width: "44px",
  },
  {
    id: "restaurants.menu.items.combos.datatable.header.ownership",
    title: "Ownership",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
]
