//TODO: remove eslint-disable when SearchItemsModal is going to be implemented again
import type { ItemCardModel } from "../../../../../components/ItemCard"
import ItemCard from "../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { useBreadcrumb } from "../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../shared/routes/paths"
import AddButton from "../../../../../ui/AddButton"
import SectionHeader from "../../../../../ui/SectionHeader"
import { SearchCombosModal } from "../../../MenuItems/components/SearchCombosModal/SearchCombosModal"
import type {
  IMenuComboForm,
  IMenuForm,
} from "../../MenuForm/hookforms.interfaces"
import CardVisibility from "../../components/CardVisibility"
import { useCallback, useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export const ComboList = () => {
  const intl = useIntl()

  const { watch, getValues, control } = useFormContext<IMenuForm>()
  const {
    fields: comboList,
    replace: replaceCombos,
    remove: removedCombo,
  } = useFieldArray({ control, name: "combos" })

  const { pushBreadcrumb } = useBreadcrumb()

  const {
    restaurants: {
      editCombo,
      locations: { editComboByLocation },
    },
  } = paths

  const isMain = watch("isMain")

  const [displayComboSearch, setDisplayComboSearch] = useState<
    boolean | undefined
  >()

  const showComboSearch = () => setDisplayComboSearch(true)
  const dismissComboSearch = useCallback(
    () => setDisplayComboSearch(false),
    [setDisplayComboSearch]
  )

  const {
    state: {
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  const onComboClick = useCallback(
    (comboUUID: string) => {
      const name = getValues("name")

      if (isMain) {
        pushBreadcrumb(editCombo(comboUUID), {
          currentPageName: name,
        })
      } else {
        pushBreadcrumb(editComboByLocation(locationUUID, comboUUID), {
          currentPageName: name,
        })
      }
    },
    [
      editCombo,
      editComboByLocation,
      getValues,
      isMain,
      locationUUID,
      pushBreadcrumb,
    ]
  )

  const onCombosUpdated = (selectedCombos: ItemCardModel[]) => {
    selectedCombos.length > 0 &&
      replaceCombos(selectedCombos as Array<IMenuComboForm>)

    setDisplayComboSearch(false)
  }

  const handleDeleteItem = (indexCombo: number) => {
    removedCombo(indexCombo)
  }

  return (
    <>
      <SectionHeader
        title={intl.formatMessage({
          id: "restaurants.menu.form.combos.label",
          defaultMessage: "Combos",
        })}
        description={intl.formatMessage({
          id: "restaurants.menu.form.combos.description",
          defaultMessage: "Search a combo and add it to your category.",
        })}
        actionButton={<AddButton onClick={showComboSearch} />}
      />

      {comboList.map((combo, indexCombo) => {
        return (
          <ItemCard
            key={combo.uuid}
            src={combo.attachment?.signedUrl}
            title={combo.name}
            onClick={() => onComboClick(combo.uuid)}
            onDeleteCard={() => handleDeleteItem(indexCombo)}
            imageFit="contain"
            enableDnDMenu={false}
            allowDelete
          >
            <CardVisibility
              isSoldOut={!!combo.isSoldOut}
              isVisible={combo.isVisible ?? true}
            />
          </ItemCard>
        )
      })}
      {displayComboSearch && (
        <SearchCombosModal
          visible={displayComboSearch}
          onCancel={dismissComboSearch}
          selectedCombos={comboList}
          onSave={onCombosUpdated}
          isCorporate={isMain}
        />
      )}
    </>
  )
}
