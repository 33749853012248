import type { GetMenuListQuery } from "../../../../../../GraphQL/Queries/getMenuList.generated"
import { useGetMenuListQuery } from "../../../../../../GraphQL/Queries/getMenuList.generated"
import { useGeneralContext } from "../../../../../../shared/contexts/StoreProvider"
import type { RBasicMenuDetail } from "../../../../../../shared/graphql/generated/types"
import { MenuStatusEnum } from "../../../../../../shared/graphql/generated/types"
import { updateGetMenuListQuery } from "../../../../../../shared/graphql/updateQuery/updateGetMenuListQuery"
import { storedBreadcrumbs } from "../../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../../shared/routes/paths"
import { canFetchMore } from "../../../../../../shared/utils/helpers/canFetchMore"
import validateImageFileType from "../../../../../../shared/utils/helpers/validateImageFileType"
import Alert from "../../../../../../ui/Alert"
import Container from "../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import InputLabel from "../../../../../../ui/InputLabel"
import Input from "../../../../../../ui/Inputs"
import InputInlineText from "../../../../../../ui/Inputs/InputInlineText"
import Select from "../../../../../../ui/Select"
import { onPopupScroll } from "../../../../../../ui/Select/helpers/onPopupScroll"
import Spacer from "../../../../../../ui/Spacer"
import Tooltip from "../../../../../../ui/Tooltip"
import Text from "../../../../../../ui/Typography/Text"
import UploadAvatar from "../../../../../../ui/UploadAvatar"
import notification from "../../../../../../ui/notification"
import OwnershipTag from "../../../../components/OwnershipTag"
import StatusBadge from "../../../../components/StatusBadge"
import { StatusBadgeEnum } from "../../../../components/StatusBadge/StatusBadge"
import AvailableButton from "../../../components/AvailableButton"
import { useCombos } from "../../hooks/useCombos"
import type { IComboForm } from "../../interfaces/hookforms.interfaces"
import { COMBO_FILTER } from "../SingleItemForm/utils/constants"
import { corporateTitle, locationTitle } from "./tooltip.title"
import type { RcFile } from "antd/lib/upload"
import { debounce, get, omit } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt, useHistory } from "react-router-dom"

type ItemInfoFormProps = {
  currentMenus?: RBasicMenuDetail[]
  uploadingImageState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const ComboInfoForm: React.FC<ItemInfoFormProps> = ({
  uploadingImageState,
  currentMenus = [],
}) => {
  const intl = useIntl()
  const [uploadingImage, setUploadingImage] = uploadingImageState
  const { push } = useHistory()
  const { restaurants } = paths
  const [searchValue, setSearchValue] = useState<string>()

  const {
    control,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext<IComboForm>()

  const [lastPublishedAt, hasSnapshot, itemUUID, isMain, closeForm] = useWatch({
    name: ["lastPublishedAt", "hasSnapshot", "uuid", "isMain", "closeForm"],
    control: control,
  })

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  const redirectPath = isMain
    ? restaurants.items
    : restaurants.locations.itemsByLocation(locationUUID)
  const { createComboAttachment } = useCombos({})

  const {
    data,
    loading,
    refetch: refetchMenus,
    fetchMore,
  } = useGetMenuListQuery({
    variables: { status: MenuStatusEnum.PUBLISHED },
    skip: !restaurantUUID,
    fetchPolicy: "network-only",
  })

  const menus =
    data?.getMenuList.results?.reduce<{ value: string; label: string }[]>(
      (accumulator, menu) => {
        if (menu.isMain === isMain) {
          accumulator.push({
            value: menu.uuid,
            label: menu.name,
          })
        }

        return accumulator
      },
      []
    ) || []

  currentMenus?.map((currentMenu) => {
    if (!menus.map((menu) => menu.value).includes(currentMenu.uuid))
      menus?.push({ value: currentMenu.uuid, label: currentMenu.name })
  })

  const hasNextPage = get(data, "getMenuList.hasNextPage", false)
  const endCursor = get(data, "getMenuList.endCursor", null)
  const canFetchMoreMenus = canFetchMore(loading, hasNextPage, endCursor)

  const onSearchLocations = React.useMemo(() => {
    const onSearch = async (value: string) => {
      try {
        await refetchMenus({
          name: value ? value : "",
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    return debounce(onSearch, 600)
  }, [refetchMenus])

  const fetchMoreMenus = useCallback(async () => {
    await fetchMore({
      variables: { after: endCursor },
      updateQuery: (prev: GetMenuListQuery, { fetchMoreResult }) =>
        updateGetMenuListQuery(prev, fetchMoreResult),
    })
  }, [endCursor, fetchMore])

  const onSelectClear = () => {
    setSearchValue(undefined)
    onSearchLocations("")
  }

  const onSearch = (value: string) => {
    setSearchValue(value)

    if (value !== "") {
      onSearchLocations(value)
    }

    if (value === "") {
      onSelectClear()
    }
  }

  const handleImageUpload = async (file: RcFile) => {
    const isValid = validateImageFileType(file.type)

    if (!isValid) {
      notification({
        description: intl.formatMessage({
          id: "components.upload.images.wrong.format.message",
          defaultMessage:
            "You can only upload JPG, JPEG, AVIF, WEBP, or PNG pictures.",
        }),
        type: "error",
      })

      return false
    }
    setUploadingImage(true)
    setValue("image", file)

    try {
      await createComboAttachment(
        itemUUID,
        file,
        (result) => {
          const attachment = omit(
            result.createTempComboAttachment,
            "__typename?"
          )
          setValue("attachment", attachment)
        },
        showGraphqlErrors
      )
    } catch (error) {
      showGraphqlErrors(error)
    } finally {
      setUploadingImage(false)
    }
  }

  useEffect(() => {
    if (closeForm && !isDirty && storedBreadcrumbs().length === 1) {
      push(redirectPath + COMBO_FILTER)
    }
  }, [closeForm, isDirty, redirectPath, push])

  return (
    <Container>
      {hasSnapshot && !!lastPublishedAt && (
        <Container padding="0 0 64px 0">
          <Alert
            type="warning"
            message={intl.formatMessage({
              id: "restaurants.menu.items.combo.form.alert.unpublished.changes.message",
              defaultMessage: "You've unpublished changes",
            })}
            description={intl.formatMessage({
              id: "restaurants.menu.items.combo.form.alert.unpublished.changes.description",
              defaultMessage:
                "Your changes have been saved but are not published yet. Click the “Publish” button so changes become visible to your customers.",
            })}
            showIcon
          />
        </Container>
      )}
      <Container display="flex" gap="20px" alignItems="center">
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <InputInlineText
              {...field}
              placeholder={intl.formatMessage({
                id: "restaurants.menu.items.combo.form.placeholder",
                defaultMessage: "Untitled combo",
              })}
              typeText="title"
              weight="bold"
              hasError={!!errors.name}
            />
          )}
        />
        {!isMain && (
          <Controller
            control={control}
            name="isSoldOut"
            render={({ field }) => (
              <AvailableButton
                onClick={() => field.onChange(!field.value)}
                soldOut={field.value}
              />
            )}
          />
        )}
      </Container>
      <Controller
        name="isPublished"
        control={control}
        render={({ field }) => {
          return (
            <Tooltip
              placement="bottom"
              title={intl.formatMessage(
                isMain ? corporateTitle : locationTitle,
                {
                  isPublished: Number(field.value),
                }
              )}
            >
              <StatusBadge
                status={
                  field.value
                    ? StatusBadgeEnum.PUBLISHED
                    : StatusBadgeEnum.DRAFT
                }
              />
            </Tooltip>
          )
        }}
      />
      {!isMain && (
        <>
          <Spacer size={8} />
          <OwnershipTag isMain={isMain} />
        </>
      )}
      <Spacer size={65} />
      <Text weight="bold">
        {intl.formatMessage({
          id: "restaurants.menu.items.combo.form.upload.picture.title",
          defaultMessage: "Image",
        })}
      </Text>
      <Spacer size={12} />
      <Text>
        {intl.formatMessage({
          id: "restaurants.menu.items.combo.form.upload.picture.description",
          defaultMessage: "Add your items image.",
        })}
      </Text>
      <Spacer size={16} />
      <Controller
        name="image"
        control={control}
        render={({ field: { value, onChange } }) => (
          <UploadAvatar
            avatar={value}
            beforeUpload={handleImageUpload}
            onChange={(avatar) => {
              const actualAvatar = value
              onChange(avatar)
              !!actualAvatar && !avatar && onChange(actualAvatar)
            }}
            onDelete={() => {
              setValue("attachment", undefined)
              setValue("image", undefined)
              onChange()
            }}
            hint={intl.formatMessage({
              id: "restaurants.menu.items.combo.form.upload.image.hint",
              defaultMessage: "Upload",
            })}
            height={128}
            width={128}
            deletable
            loading={uploadingImage}
          />
        )}
      />
      <Spacer size={64} />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Input
            description={
              <Container
                display="flex"
                justifyContent="start"
                alignItems="center"
                gap="8px"
              >
                <InputLabel
                  label={intl.formatMessage({
                    id: "restaurants.menu.items.combo.form.input.description",
                    defaultMessage: "Description",
                  })}
                  requirement="optional"
                />
              </Container>
            }
            value={field.value}
            onChange={field.onChange}
            useEllipsis
          />
        )}
      />
      <Spacer size={64} />
      <Controller
        control={control}
        name="menus"
        render={({ field }) => (
          <Select
            loading={loading}
            tooltipTitle={intl.formatMessage({
              defaultMessage:
                "Help your customers to find quickly this Item by classifying it by Categories",
              id: "restaurants.menu.items.combo.form.select.tooltip.title",
            })}
            label={intl.formatMessage({
              defaultMessage: "Category",
              id: "restaurants.menu.items.combo.form.select.label",
            })}
            mode="multiple"
            optionFilterProp="label"
            searchValue={searchValue}
            onSearch={onSearch}
            value={field.value}
            onChange={field.onChange}
            options={menus}
            listHeight={150}
            onPopupScroll={(e) =>
              onPopupScroll(e, canFetchMoreMenus, fetchMoreMenus)
            }
            allowSearch
          />
        )}
      />
      <Spacer size={64} />
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </Container>
  )
}

export default ComboInfoForm
