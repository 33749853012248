import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import paths from "../../../shared/routes/paths"
import { get } from "../../../shared/utils/api/client"
import { endpoints } from "../../../shared/utils/api/endpoints"
import storage from "../../../shared/utils/storage"
import Container from "../../../ui/Container"
import Loader from "../../../ui/Loader"
import type { TokenResponseType } from "../api"
import React, { useEffect } from "react"
import { useLocation } from "react-router"

export const GoogleCallback: React.FC = () => {
  const { search } = useLocation()
  const { dispatch, initRestaurants } = useGeneralContext()

  useEffect(() => {
    const sendGoogleCallback = (searchParams: string) => {
      get<unknown, TokenResponseType>({
        path: endpoints.auth.googleBackendCallback(searchParams),
      })
        .then(async (result) => {
          if (result) {
            await initRestaurants(result)
            return
          } else {
            storage.clear()
            window.location.pathname = paths.guest.loginError
            return
          }
        })
        .catch(() => {
          storage.clear()
          window.location.pathname = paths.guest.loginError
        })
    }

    if (search) {
      sendGoogleCallback(search)
    }
  }, [search, dispatch, initRestaurants])

  return (
    <Container centered fullScreen>
      <Loader size="large" />
    </Container>
  )
}
