import type { RPublishComboInput } from "../../../../../shared/graphql/generated/types"
import { useModal } from "../../../../../shared/hooks/useModal"
import paths from "../../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import { formNotification } from "../../../../../ui/notification"
import ComboModalHeader from "../../../components/ModalFullDetailHeader"
import type { GetComboDetailQuery } from "../GraphQL/getComboDetail.generated"
import DropdownCombo from "../components/DropdownCombo"
import PublishComboModal from "../components/modals/PublishComboModal"
import ComboCreationForm from "../forms/ComboCreationForm"
import { ComboFormResolver } from "../forms/ComboForm.yup"
import ComboForm from "../forms/ComboInfoForm"
import { useCombos } from "../hooks/useCombos"
import type { IComboForm } from "../interfaces/hookforms.interfaces"
import { comboFormDataToInput } from "../utils/combo-form-to-input"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type EditComboProps = {
  isCorporate?: boolean
  combo: GetComboDetailQuery["getComboDetail"]
}

export const EditCombo: React.FC<EditComboProps> = ({ isCorporate, combo }) => {
  const intl = useIntl()
  const { restaurants } = paths
  const uploadingImageState = useState(false)
  const [uploadingImage] = uploadingImageState
  const { saveCombo, comboSaved } = useCombos({})
  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()

  const [savedComboName, setSavedComboName] = useState(combo.name ?? "")

  const formMethods = useForm<IComboForm>({
    mode: "all",
    defaultValues: {
      uuid: combo.uuid,
      name: combo.name,
      description: combo.description ?? "",
      menus: combo.menus.map((menu) => menu.uuid) ?? [],
      attachment: combo.attachment ?? undefined,
      comboItemGroups: combo.comboItemGroups.map((cmbItmGrp) => {
        const indexDefaultComboItem = cmbItmGrp.comboItems.findIndex(
          (comboItem) => comboItem.isDefault
        )
        return {
          uuid: cmbItmGrp.uuid ?? "",
          name: cmbItmGrp.name ?? "",
          image: undefined,
          defaultComboItem:
            indexDefaultComboItem === -1 ? undefined : indexDefaultComboItem,
          comboItems: cmbItmGrp.comboItems.map((comboItem) => ({
            uuid: comboItem.uuid,
            isDefault: comboItem.isDefault,
            item: {
              isMain: comboItem.item.isMain,
              isSoldOut: comboItem.item.isSoldOut ?? false,
              isVisible: comboItem.item.isVisible ?? true,
              name: comboItem.item.name,
              uuid: comboItem.item.uuid,
              variants: comboItem.item.variants.map((variant) => ({
                name: variant.name,
                uuid: variant.uuid,
                attachment: { signedUrl: variant.attachment?.signedUrl },
                extraPrice: variant.extraPrice,
                isDefault: variant.isDefault,
                isSoldOut: variant.isSoldOut ?? false,
                isVisible: variant.isVisible ?? true,
                price: variant.price,
              })),
            },
          })),
        }
      }),
      isPublished: !!combo.isPublished,
      isSoldOut: !!combo.isSoldOut,
      isVisible: !!combo.isVisible,
      isMain: isCorporate,
      discountPercentage: combo.discountPercentage ?? 0,
      priceOverride: combo.priceOverride ?? 0,
      closeForm: false,
      fullPrice: combo.fullPrice ?? 0,
      hasSnapshot: !!combo.hasSnapshot,
      lastPublishedAt: combo.publishedAt,
      lastSavedAt: combo.snapshotUpdatedAt,
    },
    resolver: ComboFormResolver,
  })

  const {
    control,
    getValues,
    setValue,
    clearErrors,
    trigger,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = formMethods

  const [lastSavedAt, lastPublishedAt, hasSnapshot, comboUUID] = useWatch({
    name: ["lastSavedAt", "lastPublishedAt", "hasSnapshot", "uuid"],
    control: control,
  })

  const onSave = async () => {
    const formData = getValues()
    clearErrors("comboItemGroups")
    const isValidName = await trigger("name")

    if (!isValidName) {
      const nameErrorMessage = intl.formatMessage({
        id: "restaurants.menu.items.edit.combo.publish.notification.name.error.message",
        defaultMessage: "Name the combo",
      })
      const nameError = errors.name ? { message: nameErrorMessage } : undefined

      return formNotification({
        key: "lsm.create.combo.save",
        header: intl.formatMessage({
          id: "restaurants.menu.items.create.combo.save.notification.error.header",
          defaultMessage: "You can't save this combo",
        }),
        title: intl.formatMessage({
          id: "restaurants.menu.items.create.combo.save.notification.error.title",
          defaultMessage: "To save a combo you need to:",
        }),
        type: "error",
        error: { ...errors, ...(nameError && { name: nameError }) },
      })
    }

    await saveCombo(
      comboFormDataToInput(formData),
      (result) => {
        setSavedComboName(result.saveCombo.name)
        setValue("uuid", result.saveCombo.uuid)
        setValue("lastSavedAt", new Date())
        setValue("hasSnapshot", true)

        reset({}, { keepValues: true })
      },
      showGraphqlErrors
    )

    return
  }

  const onPublish = () => {
    handleSubmit(
      () => showPublishConfirmation(),
      (error) => {
        const nameErrorMessage = intl.formatMessage({
          id: "restaurants.menu.items.edit.combo.publish.notification.name.error.message",
          defaultMessage: "Name the combo",
        })
        const nameError = error.name ? { message: nameErrorMessage } : undefined

        const cmbItmGrpErrorMessage = intl.formatMessage({
          id: "restaurants.menu.items.edit.combo.publish.notification.combo.item.group.error.message",
          defaultMessage: "At least add one combo item",
        })
        const errorCmbItmGrps = error.comboItemGroups
          ? { message: cmbItmGrpErrorMessage }
          : undefined

        formNotification({
          key: "lsm.create.combo.publish",
          header: intl.formatMessage({
            id: "restaurants.menu.items.create.combo.publish.notification.error.header",
            defaultMessage: "You can't publish this combo",
          }),
          title: intl.formatMessage({
            id: "restaurants.menu.items.create.combo.publish.notification.error.title",
            defaultMessage: "To publish a combo you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(nameError && { name: nameError }),
            ...(errorCmbItmGrps && { comboItemGroups: errorCmbItmGrps }),
          },
        })
      }
    )()
  }

  const publishCallback = () => {
    dismissPublishConfirmation()
    onClose()
  }

  const onClose = () => {
    setValue("hasSnapshot", false)
    setValue("closeForm", true)
    reset({}, { keepDirty: false, keepValues: true })
  }

  const getComboData = () => {
    if (isDirty) {
      const comboData = getValues()

      return comboFormDataToInput(comboData)
    }
  }

  useEffect(() => {
    setValue("image", combo.attachment?.signedUrl)
  }, [combo.attachment?.signedUrl, setValue])

  return (
    <ModalFull
      goBackPath={restaurants.items}
      headerColor="Neutral2"
      title={
        <ComboModalHeader
          onSave={onSave}
          onPublish={onPublish}
          lastSavedAt={lastSavedAt}
          lastPublishedAt={lastPublishedAt}
          enablePreview={!!comboUUID && !!lastSavedAt}
          enablePublish={
            (hasSnapshot || isDirty || !combo.isPublished) && !uploadingImage
          }
          enableSave={isDirty && !uploadingImage}
          actions={
            <DropdownCombo
              isCorporate={isCorporate}
              onOk={onClose}
              comboUUID={comboUUID}
              actions={
                combo.publishedAt
                  ? ["DELETE_DRAFT", "UNPUBLISH"]
                  : ["DELETE", "UNPUBLISH", "DISABLE_UNPUBLISH"]
              }
            />
          }
          loadingPublish={false}
          loadingSave={comboSaved.loading}
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.editCombos}
            pageName={savedComboName}
          />
        </ComboModalHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ComboForm uploadingImageState={uploadingImageState} />
        <ComboCreationForm isCorporate={isCorporate} />
      </FormProvider>
      {publishModalVisible && (
        <PublishComboModal
          comboUUID={comboUUID}
          comboData={getComboData() as RPublishComboInput}
          onOk={publishCallback}
          onCancel={dismissPublishConfirmation}
        />
      )}
    </ModalFull>
  )
}
