import type { OrderActionModel } from "../../../../../../shared/graphql/generated/types"
import { OrderActionTypeEnum } from "../../../../../../shared/graphql/generated/types"
import HighlightedText from "../../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../../ui/Typography/Text"
import { CustomOrderTimelineTitle } from "../../../titles/orderOrderTimeline.title"
import OrderTimelineComment from "../OrderTimelineCommentModal"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"

type OrderTimelineActionProps = {
  orderAction: OrderActionModel
  orderNumber: string | undefined
}

export const OrderTimelineAction: React.FC<OrderTimelineActionProps> = ({
  orderAction,
  orderNumber,
}) => {
  const intl = useIntl()

  const [displayMessage, setDisplayMessage] = useState<boolean>(false)

  const onShowMessage = () => {
    setDisplayMessage(true)
  }

  const onClose = () => {
    setDisplayMessage(false)
  }

  if (orderAction.actionType === OrderActionTypeEnum.COMMENT) {
    return (
      <Text size="l" color="Neutral9">
        <FormattedMessage
          id="restaurants.orders.order.timeline.action.comment"
          defaultMessage="{hasUserName,plural,=0 {New {comment} Added} other {{userName} added a {comment} to order }}"
          values={{
            comment: (
              <HighlightedText
                aria-label="show-comment-button"
                role="button"
                size="l"
                onClick={onShowMessage}
                cursor="pointer"
              >
                {intl.formatMessage({
                  id: "restaurants.orders.order.status.comment",
                  defaultMessage: "Comment",
                })}
              </HighlightedText>
            ),
            userName: orderAction.admin?.firstName ?? "",
            hasUserName: Number(!!orderAction.admin?.firstName),
          }}
        />

        <OrderTimelineComment
          visible={displayMessage}
          onClose={onClose}
          orderAction={orderAction}
        />
      </Text>
    )
  }

  if (orderAction.actionType === OrderActionTypeEnum.CLOSED) {
    return (
      <Text size="l" color="Neutral9" aria-label="action-type-closed">
        {intl.formatMessage(
          {
            id: "restaurants.orders.order.timeline.action.closed",
            defaultMessage: "Order {orderNumber} closed",
          },
          {
            orderNumber: orderNumber ? `#${orderNumber}` : "",
          }
        )}
      </Text>
    )
  }

  return (
    <Text size="l" color="Neutral9" aria-label="action-type-others">
      {intl.formatMessage(CustomOrderTimelineTitle[orderAction.actionType])}
    </Text>
  )
}
