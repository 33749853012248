import {
  OrderPaymentStatusEnum,
  OrderStatusEnum,
} from "../../../../../shared/graphql/generated/types"
import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import Container from "../../../../../ui/Container"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import { OrderSummarySkeleton } from "../OrderDetail.skeleton"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import OrderSummaryCard from "../OrderSummaryCard"
import type { OrderDetailCardType } from "./types/order-summary.types"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

type OrderSummaryProps = {
  loading?: boolean
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({ loading }) => {
  const intl = useIntl()
  const { watch } = useFormContext<OrderDetailModalForm>()

  const orderMethod = watch("orderDetail.orderMethod")
  const orderDetails = watch("orderDetail.orderDetails")
  const invoice = watch("orderDetail.invoice")
  const pendingOrderTotal = watch("orderDetail.total")
  const pendingOrderSubTotal = watch("orderDetail.subTotal")
  const pendingOrderTotalTaxes = watch("orderDetail.totalTaxes")
  const orderStatus = watch("orderDetail.status")
  const orderPaymentStatus = watch("orderDetail.paymentStatus")

  const details: Array<OrderDetailCardType> = orderDetails?.flatMap(
    ({ item, combo }) => {
      if (combo) {
        return {
          name: combo.name,
          price: combo.unitPrice,
          quantity: combo.quantity,
          notes: null,
          details: combo.items.map((comboItem) => ({
            itemUUID: comboItem.uuid,
            name: `${comboItem?.variant.name} ${comboItem?.name}`,
            price: comboItem.unitPrice,
            quantity: comboItem.quantity,
            notes: comboItem.notes,
            extras: (comboItem?.variant?.orderVariantModifiers ?? []).map(
              (modfiiers) => ({
                name: modfiiers.ingredient.name,
                ingredientUUID: modfiiers.ingredient.uuid,
                uuid: modfiiers.uuid,
                price: modfiiers.unitPrice,
                quantity: modfiiers.quantity,
              })
            ),
          })),
          extras: [],
        }
      }

      return {
        name: `${item?.variant.name} ${item?.name}`,
        price: item?.unitPrice,
        quantity: item?.quantity,
        details: [],
        notes: item?.notes,
        extras: (item?.variant?.orderVariantModifiers ?? []).map(
          (modfiiers) => ({
            name: modfiiers.ingredient.name,
            ingredientUUID: modfiiers.ingredient.uuid,
            uuid: modfiiers.uuid,
            price: modfiiers.unitPrice,
            quantity: modfiiers?.quantity,
          })
        ),
      }
    }
  )

  if (loading) {
    return <OrderSummarySkeleton />
  }

  return (
    <Container role="order-summary-section">
      <StyledContentSection>
        <Text color="Neutral6" weight="bold" size="xl">
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.summary.section.header",
            defaultMessage: "Summary",
          })}
        </Text>
      </StyledContentSection>
      <StyledSummaryData display="flex" flexDirection="column" gap="1px">
        {details?.length ? (
          details.map((detail, index) => {
            return <OrderSummaryCard key={index} detail={detail} />
          })
        ) : (
          <StyledEmptySummary>
            <Text size="m" weight="bold" color="Neutral9">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.no.items",
                defaultMessage: "There are no order items",
              })}
            </Text>
          </StyledEmptySummary>
        )}
      </StyledSummaryData>

      <Spacer size={24} />

      <Container>
        <StyledPriceDetailSection>
          <Text size="l">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.price.detail",
              defaultMessage: "Price Detail",
            })}
          </Text>
        </StyledPriceDetailSection>
        <StyledPriceDetailContainer
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text size="l">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.price.detail.subtotal",
                defaultMessage: "Subtotal",
              })}
            </Text>
            <Text size="l" textAlign="right">
              {orderStatus === OrderStatusEnum.OPEN ||
              orderPaymentStatus === OrderPaymentStatusEnum.AWAITING
                ? toFormat(Number(pendingOrderSubTotal))
                : toFormat(Number(invoice?.subTotal))}
            </Text>
          </Container>
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text size="l">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.price.detail.taxes",
                defaultMessage: "Taxes",
              })}
            </Text>
            <Text size="l" textAlign="right">
              {orderStatus === OrderStatusEnum.OPEN ||
              orderPaymentStatus === OrderPaymentStatusEnum.AWAITING
                ? toFormat(Number(pendingOrderTotalTaxes))
                : toFormat(Number(invoice?.totalTaxes))}
            </Text>
          </Container>
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text size="l">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.price.detail.tips",
                defaultMessage: "Tips",
              })}
            </Text>
            <Text size="l" textAlign="right">
              {toFormat(Number(invoice?.tip))}
            </Text>
          </Container>
          {orderMethod === "DELIVERY" && (
            <Container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text size="l">
                {intl.formatMessage({
                  id: "restaurants.orders.order.detail.price.detail.delivery.fees",
                  defaultMessage: "Delivery Fees",
                })}
              </Text>
              <Text size="l" textAlign="right">
                {toFormat(Number(invoice?.deliveryFee))}
              </Text>
            </Container>
          )}

          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text size="l">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.price.detail.fees",
                defaultMessage: "Fees",
              })}
            </Text>
            <Text size="l" textAlign="right">
              {toFormat(Number(invoice?.serviceFee))}
            </Text>
          </Container>

          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text size="l">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.price.detail.store.credit",
                defaultMessage: "Store Credit",
              })}
            </Text>
            <Text size="l" textAlign="right">
              {toFormat(Number(invoice?.storeCash))}
            </Text>
          </Container>
          {invoice?.discountDetails?.map((discountDetail) => (
            <Container
              key={discountDetail.code}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text size="l">
                {intl.formatMessage(
                  {
                    id: "restaurants.orders.order.detail.price.detail.discount.code",
                    defaultMessage: "Discount {code}",
                  },
                  {
                    code: discountDetail.code,
                  }
                )}
              </Text>
              <Text size="l" textAlign="right">
                -{toFormat(Number(discountDetail.amountApplied))}
              </Text>
            </Container>
          ))}
        </StyledPriceDetailContainer>

        <StyledTotalContainer
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Text weight="bold" size="l">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.price.detail.total",
              defaultMessage: "Total",
            })}
          </Text>
          <Text weight="bold" textAlign="right" size="l">
            {orderStatus === OrderStatusEnum.OPEN ||
            orderPaymentStatus === OrderPaymentStatusEnum.AWAITING
              ? toFormat(Number(pendingOrderTotal))
              : toFormat(Number(invoice?.total))}
          </Text>
        </StyledTotalContainer>
      </Container>
    </Container>
  )
}

const StyledSummaryData = styled(Container)`
  ${({ theme }) => css`
    overflow: auto;
    border: 1px solid ${theme.colors.Neutral4};
    border-radius: 4px;
  `}
`

const StyledEmptySummary = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors["Neutral1"]};

    padding: 12px 16px;
  `}
`

const StyledPriceDetailContainer = styled(Container)`
  margin-top: 16px;
`

const StyledContentSection = styled.div`
  padding-bottom: 24px;
`

const StyledPriceDetailSection = styled.div`
  padding-bottom: 8px;
  ${({ theme }) => css`
    border-bottom: 1.5px solid ${theme.colors.Neutral4};
  `}
`

const StyledTotalContainer = styled(Container)`
  ${({ theme }) => css`
    border-top: 1.5px solid ${theme.colors.Neutral4};
  `}
  padding-top: 16px;
  margin-top: 16px;
`
