import type {
  GetComboListQuery,
  GetComboListQueryVariables,
} from "../../../../../../GraphQL/Queries/getComboList.generated"
import { GetComboListDocument } from "../../../../../../GraphQL/Queries/getComboList.generated"
import type { ApolloCache } from "@apollo/client"

type WriteCacheGetComboListQuery = {
  cache: ApolloCache<unknown>
  query: GetComboListQuery
  variables: GetComboListQueryVariables
}

const writeGetComboListQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheGetComboListQuery) =>
  cache.writeQuery<GetComboListQuery, GetComboListQueryVariables>({
    query: GetComboListDocument,
    data: query,
    variables,
  })

export default writeGetComboListQueryCache
