export enum EntryMethodEnum {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC",
}

const entryMethodOptions = [
  {
    value: EntryMethodEnum.MANUAL,
    labelId:
      "restaurants.discount.forms.generic.entry.method.button.manual.title",
    defaultMessage: "Manual",
  },
  {
    value: EntryMethodEnum.AUTOMATIC,
    labelId:
      "restaurants.discount.forms.generic.entry.method.button.automatic.title",
    defaultMessage: "Automatic",
  },
]
export default entryMethodOptions
