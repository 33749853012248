import type {
  GetItemListQuery,
  GetItemListQueryVariables,
} from "../../../../../../GraphQL/Queries/getItemList.generated"
import { graphqlClient } from "../../../../../../shared/graphql/client"
import { ItemProgressStatusEnum } from "../../../../../../shared/graphql/generated/types"
import type { SaveItemCacheType } from "./interfaces"
import readGetItemListQueryCache from "./items-cache-read"
import writeGetItemListQueryCache from "./items-cache-write"

const cache = graphqlClient.cache

//INFO: This method validates whether or not the item exists in the cache and accordingly adds the item to the cache or updates it.
export const saveItem = (
  data: SaveItemCacheType,
  variables: GetItemListQueryVariables
) => {
  const itemListQuery = readGetItemListQueryCache({
    cache,
    variables,
  })

  const items = itemListQuery?.getItemList.results

  if (items) {
    const itemListUpdated = items.map((item) =>
      data.uuid === item.uuid ? { ...item, ...data } : item
    )
    const itemExists = itemListUpdated.some(
      (updatedItem) => updatedItem.uuid === data.uuid
    )

    if (!itemExists) {
      itemListUpdated.push({
        createdAt: data.createdAt,
        uuid: data.uuid,
        name: data.name,
        isVisible: data.isVisible,
        hasSnapshot: data.hasSnapshot ?? false,
        isMain: data.isMain ?? true,
        status: data.status ?? ItemProgressStatusEnum.DRAFT,
      })
    }

    const query: GetItemListQuery = {
      getItemList: {
        ...itemListQuery.getItemList,
        results: itemListUpdated,
      },
    }

    writeGetItemListQueryCache({ cache, query, variables })
  }
}

//INFO: This method removes the item from the cache
export const deleteItem = (
  itemUUID: string,
  variables: GetItemListQueryVariables
) => {
  const itemListQuery = readGetItemListQueryCache({
    cache,
    variables,
  })

  const items = itemListQuery?.getItemList.results

  if (items) {
    const filteredItems = items.filter((item) => item.uuid !== itemUUID)

    const query: GetItemListQuery = {
      getItemList: {
        ...itemListQuery.getItemList,
        results: filteredItems,
      },
    }

    writeGetItemListQueryCache({ cache, query, variables })
  }
}
