import Container from "../Container"
import Icon from "../Icon"
import Text from "../Typography/Text"
import notification from "../notification"
import {
  getFileTypes,
  getHumanizedFileTypes,
} from "./utils/FileDraggerValidatorUtils"
import Dragger from "antd/lib/upload/Dragger"
import type {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface"
import type { UploadRequestOption } from "rc-upload/lib/interface"
import React, { useState } from "react"
import { useIntl } from "react-intl"

type FileDraggerProps = {
  maxSize: number
  maxFileQuantity: number
  value: Array<UploadFile<RcFile>>
  acceptedMimeTypes: string[]
  onChange: (file: UploadFile<RcFile>[]) => void
}

const FileDragger: React.FC<FileDraggerProps> = (props) => {
  const { maxSize, maxFileQuantity, onChange, value, acceptedMimeTypes } = props
  const intl = useIntl()
  const [files, setFiles] = useState<UploadFile<RcFile>[]>(value ?? [])
  const allowedTypes = getFileTypes(acceptedMimeTypes).join(",")
  const humanizedFileTypes = getHumanizedFileTypes(acceptedMimeTypes)

  const beforeUpload = async (file: RcFile) => {
    const validFormat = acceptedMimeTypes.includes(file.type)
    const validSize = file.size < maxSize * 1024 * 1024

    if (!validFormat) {
      notification({
        description: intl.formatMessage(
          {
            id: "file.dragger.wrong.format.message",
            defaultMessage: "You can only upload {fileTypes} files.",
          },
          { fileTypes: humanizedFileTypes }
        ),
        type: "error",
      })

      return false
    }

    if (!validSize) {
      notification({
        description: intl.formatMessage(
          {
            id: "file.dragger.wrong.size.message",
            defaultMessage:
              "{fileName} size exceeds the maximum limit of {maxFileSize}MB.",
          },
          { maxFileSize: maxSize, fileName: file.name }
        ),
        type: "error",
      })

      return false
    }

    return validFormat && validSize
  }

  const handleFileUpload = async ({
    file,
    onSuccess,
  }: UploadRequestOption<unknown>) => {
    if (files.length >= maxFileQuantity) {
      return notification({
        type: "error",
        description: intl.formatMessage({
          id: "file.dragger.max.quantity.reached.message",
          defaultMessage: "Maximum file quantity reached",
        }),
        key: "files.max.reached",
      })
    }

    setFiles((prev) => [...prev, file as RcFile])
    onSuccess?.(null)
  }

  const handleRemove = (removed: UploadFile<RcFile>) => {
    setFiles((prev) => {
      const next = prev.filter((file) => file.uid !== removed.uid)

      return next
    })
  }

  const handleFileChange = ({
    fileList,
  }: UploadChangeParam<UploadFile<RcFile>>) => {
    onChange(fileList)
  }

  return (
    <Dragger
      action="api/upload"
      multiple={true}
      accept={allowedTypes}
      maxCount={maxFileQuantity}
      height={128}
      customRequest={handleFileUpload}
      beforeUpload={beforeUpload}
      onChange={handleFileChange}
      onRemove={handleRemove}
      listType="text"
      fileList={files}
      showUploadList={{ showRemoveIcon: true }}
      openFileDialogOnClick={files.length < maxFileQuantity}
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="16px"
      >
        <Icon remixiconClass="ri-inbox-line" size={24} color="Primary5" />
        <Container>
          <Text color="Neutral6" size="m" weight="bold" textAlign="center">
            {intl.formatMessage({
              id: "file.dragger.content.title",
              defaultMessage: "Drag and drop files here or click to upload",
            })}
          </Text>
          <Text color="Neutral5" size="s" textAlign="center">
            {intl.formatMessage(
              {
                id: "file.dragger.content.description",
                defaultMessage: `Max {maxFileQuantity} files. Max file size is ${maxSize}MB. Only {fileTypes} files are supported.`,
              },
              {
                fileTypes: humanizedFileTypes,
                maxFileQuantity: maxFileQuantity,
              }
            )}
          </Text>
        </Container>
      </Container>
    </Dragger>
  )
}

export default FileDragger
