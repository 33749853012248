import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertTimeEntryMutationVariables = Types.Exact<{
  input: Types.UpsertTimEntryInput;
}>;


export type UpsertTimeEntryMutation = { __typename?: 'Mutation', upsertTimeEntry: { __typename?: 'UpsertedTimeEntry', endDate?: any | null, notes?: string | null, startDate: any, uuid: string } };


export const UpsertTimeEntryDocument = gql`
    mutation upsertTimeEntry($input: UpsertTimEntryInput!) {
  upsertTimeEntry(input: $input) {
    endDate
    notes
    startDate
    uuid
  }
}
    `;
export type UpsertTimeEntryMutationFn = Apollo.MutationFunction<UpsertTimeEntryMutation, UpsertTimeEntryMutationVariables>;

/**
 * __useUpsertTimeEntryMutation__
 *
 * To run a mutation, you first call `useUpsertTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTimeEntryMutation, { data, loading, error }] = useUpsertTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTimeEntryMutation, UpsertTimeEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTimeEntryMutation, UpsertTimeEntryMutationVariables>(UpsertTimeEntryDocument, options);
      }
export type UpsertTimeEntryMutationHookResult = ReturnType<typeof useUpsertTimeEntryMutation>;
export type UpsertTimeEntryMutationResult = Apollo.MutationResult<UpsertTimeEntryMutation>;
export type UpsertTimeEntryMutationOptions = Apollo.BaseMutationOptions<UpsertTimeEntryMutation, UpsertTimeEntryMutationVariables>;