import { useBreadcrumb } from "../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../shared/routes/paths"
import AddIcon from "../../../../ui/AddIcon"
import Button from "../../../../ui/Button"
import Container from "../../../../ui/Container"
import type { ILocationsParams } from "../../../Settings/Locations/hookforms.interfaces"
import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

interface IAddMenusButton {
  createButton?: boolean
  isCorporate?: boolean
}

export const AddMenusButton: React.FC<IAddMenusButton> = ({
  isCorporate,
  createButton,
}) => {
  const intl = useIntl()
  const { pushBreadcrumb } = useBreadcrumb()
  const { locationUUID } = useParams<ILocationsParams>()

  const createMenuPath = isCorporate
    ? paths.restaurants.createMenu
    : paths.restaurants.locations.createMenu(locationUUID)

  const onHandleClick = () => {
    pushBreadcrumb(createMenuPath, {
      currentPageName: intl.formatMessage({
        id: "restaurants.menu.list.screen.title",
        defaultMessage: "Categories",
      }),
    })
  }

  return (
    <>
      {createButton ? (
        <Container display="flex" alignItems="center" justifyContent="center">
          <StyleButtonCreate
            onClick={onHandleClick}
            title={intl.formatMessage(
              {
                id: "restaurants.menu.empty.button.title",
                defaultMessage:
                  "{isCorporate,plural,=0 {Add Category} other {Create Category}}",
              },
              { isCorporate: Number(isCorporate) }
            )}
          />
        </Container>
      ) : (
        <AddIcon onClick={onHandleClick} />
      )}
    </>
  )
}

export const AddCorporateMenusButton = () => <AddMenusButton isCorporate />

const StyleButtonCreate = styled(Button)`
  margin-top: 24px;
`
