export const payrollTableHeaders = [
  {
    id: "restaurants.reports.labor.cost.duration.table.header",
    defaultMessage: "Duration",
  },
  {
    id: "restaurants.reports.labor.cost.payroll.table.header",
    defaultMessage: "Payroll",
  },
  {
    id: "restaurants.reports.labor.cost.tips.table.header",
    defaultMessage: "Tips",
  },
  {
    id: "restaurants.reports.labor.cost.total.table.header",
    defaultMessage: "Total amount",
  },
]
