import paths from "../../../../shared/routes/paths"
import { AddIcon } from "../../../../ui/AddIcon/AddIcon"
import Button from "../../../../ui/Button/Button"
import { Container } from "../../../../ui/Container/Container"
import React from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

interface IAddDiscountButton {
  createButton?: boolean
  isCorporate?: boolean
}

export const AddDiscountButton: React.FC<IAddDiscountButton> = ({
  createButton,
}) => {
  const intl = useIntl()
  const { push } = useHistory()

  const createDiscountPath = paths.restaurants.createDiscounts

  const onHandleClick = () => {
    push(createDiscountPath)
  }

  return (
    <Container display="flex" alignItems="center" justifyContent="center">
      {createButton ? (
        <StyleButtonCreate
          onClick={onHandleClick}
          title={intl.formatMessage({
            id: "restaurants.discounts.group.empty.button.title",
            defaultMessage: "Create Discount",
          })}
        />
      ) : (
        <AddIcon onClick={onHandleClick} />
      )}
    </Container>
  )
}

const StyleButtonCreate = styled(Button)`
  margin-top: 24px;
`
