import { useModal } from "../../../../shared/hooks/useModal"
import Container from "../../../../ui/Container"
import DropDownAction from "../../../../ui/Header/DropdownAction"
import Icon from "../../../../ui/Icon"
import Text from "../../../../ui/Typography/Text"
import { Menu } from "antd"
import React, { Suspense, lazy } from "react"
import { useIntl } from "react-intl"

const DeleteDiscountModal = lazy(() =>
  import("./modals/DeleteDiscountModal").then((module) => ({
    default: module.DeleteDiscountModal,
  }))
)

type DropdownDiscountProps = {
  discountUUID: string
  code: string
  onOk?: () => void
}

export const DropdownDiscount: React.FC<DropdownDiscountProps> = ({
  discountUUID,
  code,
  onOk,
}) => {
  const intl = useIntl()

  const [
    deleteModalVisible,
    showDeleteConfirmation,
    dismissDeleteConfirmation,
  ] = useModal()

  const onClickDropdownContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  const onDeleteOk = () => {
    dismissDeleteConfirmation()
    onOk?.()
  }

  return (
    <Container
      className="dropdown-actions"
      display="flex"
      justifyContent="flex-end"
      onClick={onClickDropdownContainer}
    >
      <DropDownAction
        placement="bottomRight"
        overlay={
          <Container>
            <Menu role="menu-more-options-discount" selectedKeys={[]}>
              <Menu.Item
                key="delete-discount"
                icon={
                  <Icon remixiconClass="ri-delete-bin-line" color="Neutral7" />
                }
                onClick={showDeleteConfirmation}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.discount.menu.delete",
                    defaultMessage: "Delete Discount",
                  })}
                </Text>
              </Menu.Item>
            </Menu>
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-line"
          size={24}
          color="Neutral5"
          cursor="pointer"
          data-testid="more-icon"
        />
      </DropDownAction>
      <Suspense fallback={null}>
        {deleteModalVisible && (
          <DeleteDiscountModal
            discountUUID={discountUUID}
            code={code}
            onCancel={dismissDeleteConfirmation}
            onOk={onDeleteOk}
          />
        )}
      </Suspense>
    </Container>
  )
}
