import type { ItemCardModel } from "../../../../../../components/ItemCard"
import AddButton from "../../../../../../ui/AddButton"
import Container from "../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import Spacer from "../../../../../../ui/Spacer"
import Text from "../../../../../../ui/Typography/Text"
import { SearchItemsModal } from "../../../components/SearchItemsModal/SearchItemsModal"
import { useGetVariantsByItemsLazyQuery } from "../../GraphQL/getVariantsbyItems.generated"
import type { IComboForm } from "../../interfaces/hookforms.interfaces"
import SingleItemForm from "../SingleItemForm"
import React, { useCallback, useState } from "react"
import { useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type ItemGroupsFormType = {
  cmbItmGrpIndex: number
  isCorporate?: boolean
}

const ItemGroupsForm: React.FC<ItemGroupsFormType> = ({
  cmbItmGrpIndex,
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { control, setValue } = useFormContext<IComboForm>()
  const { fields } = useFieldArray({
    control,
    name: `comboItemGroups.${cmbItmGrpIndex}.comboItems`,
  })
  const [displayItemSearch, setDisplayItemSearch] = useState<boolean>()
  const [getVariantByItems] = useGetVariantsByItemsLazyQuery({
    onError: showGraphqlErrors,
  })

  const [comboItems, comboItemGroup] = useWatch({
    name: [
      `comboItemGroups.${cmbItmGrpIndex}.comboItems`,
      `comboItemGroups.${cmbItmGrpIndex}`,
    ],
    control: control,
  })

  const defaultSelected = comboItems?.some(
    (comboItem) => comboItem.isDefault === true
  )

  const activeFields = fields.filter((field) => !field.deletedAt)

  const onAssignItems = () => {
    setDisplayItemSearch(true)
  }

  const dismissItemSearch = useCallback(
    () => setDisplayItemSearch(false),
    [setDisplayItemSearch]
  )

  const handleSelectItems: (
    selectedItems: Array<ItemCardModel>,
    removedItems?: Array<ItemCardModel>
  ) => void = async (selectedItems, removedItems = []) => {
    const itemsUUIDArray = selectedItems
      .concat(removedItems)
      .map((selected) => ({ uuid: selected.uuid }))

    await getVariantByItems({
      variables: { items: itemsUUIDArray },
      onCompleted: (result) => {
        const itemsInfo = result?.getVariantsByItems

        setValue(
          `comboItemGroups.${cmbItmGrpIndex}.comboItems`,
          itemsInfo?.map((item) => {
            const isItemDeleted = !!removedItems?.find(
              (removedItem) => removedItem.uuid === item.uuid
            )
            const isOnlyItem =
              !isItemDeleted && itemsInfo.length - removedItems.length === 1

            return {
              item: {
                isMain: item.isMain,
                name: item.name ?? "",
                uuid: item.uuid,
                isSoldOut: item.isSoldOut ?? false,
                isVisible: item.isVisible ?? true,
                variants: item.variants.map((variant) => ({
                  name: variant.name,
                  uuid: variant.uuid,
                  attachment: { signedUrl: variant.attachment?.signedUrl },
                  extraPrice: variant.extraPrice,
                  price: variant.price,
                  isDefault: variant.isDefault,
                  isSoldOut: variant.isSoldOut ?? false,
                  isVisible: variant.isVisible ?? true,
                })),
              },
              uuid: comboItems?.find(
                (comboItem) => comboItem.item.uuid === item.uuid
              )?.uuid,
              isDefault: isOnlyItem,
              ...(isItemDeleted && { deletedAt: new Date() }),
            }
          }),
          { shouldDirty: true }
        )

        if (selectedItems.length === 1) {
          setValue(`comboItemGroups.${cmbItmGrpIndex}.defaultComboItem`, 0)
        } else if (selectedItems.length > 1) {
          setValue(
            `comboItemGroups.${cmbItmGrpIndex}.defaultComboItem`,
            undefined
          )
        }
      },
    })

    setDisplayItemSearch(false)
  }

  return (
    <ContainerWrapper hasItems={fields.length > 0}>
      <SubHeaderSection
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="0 -16px"
        padding="0 40px 16px 40px"
      >
        <Container display="flex" flexDirection="column">
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.menu.items.combo.form.item.groups.form.title",
              defaultMessage: "Assign Item",
            })}
          </Text>
          <Spacer size={8} />
          <Text size="s" weight="regular">
            {intl.formatMessage({
              id: "restaurants.menu.items.combo.form.item.groups.form.title",
              defaultMessage: "Add and personalize your product settings",
            })}
          </Text>
        </Container>
        <AddButton onClick={onAssignItems} disabled={!comboItemGroup.name} />
      </SubHeaderSection>
      {activeFields.length > 0 && (
        <>
          {!defaultSelected && (
            <WarningContainer
              height="40px"
              display="flex"
              alignItems="center"
              margin="8px 24px 0 24px"
            >
              <Text size="m" weight="regular" color="Neutral8">
                {intl.formatMessage({
                  id: "restaurants.menu.items.forms.combo.form.item.groups.default.warning",
                  defaultMessage: "You need to set a default item.",
                })}
              </Text>
            </WarningContainer>
          )}
          <Spacer size={8} />

          {activeFields.map((comboItem, index) => (
            <SingleItemForm
              key={`${comboItem.uuid}-${comboItem.id}-${index}`}
              isCorporate={isCorporate}
              cmbItmGrpIndex={cmbItmGrpIndex}
              comboItemIndex={index}
            />
          ))}
        </>
      )}
      {displayItemSearch && (
        <SearchItemsModal
          visible={displayItemSearch}
          onCancel={dismissItemSearch}
          selectedItems={activeFields.map(({ item: { name = "", uuid } }) => ({
            name,
            uuid,
          }))}
          onSave={handleSelectItems}
          isCorporate={isCorporate}
        />
      )}
    </ContainerWrapper>
  )
}

export default ItemGroupsForm

const ContainerWrapper = styled(Container)<{ hasItems: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: ${({ hasItems }) => (hasItems ? "-8px" : "-13px")};
`

const SubHeaderSection = styled(Container)`
  border-radius: 4px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.Neutral4}`};
`

const WarningContainer = styled(Container)`
  padding-left: 16px;
  border: 1px solid ${({ theme }) => theme.colors.Warning2};
  background: ${({ theme }) => theme.colors.Warning1};
  border-radius: 4px;
`
