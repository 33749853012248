import {
  DiscountStatusesEnum,
  DiscountsEntryMethodsEnum,
  DiscountsOrderMethodEnum,
} from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import firstLetterToUpperCase from "../../../../shared/utils/helpers/firstLetterToUpperCase"
import Badge from "../../../../ui/Badge"
import Container from "../../../../ui/Container"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import ModalFull, { ModalFullSkeleton } from "../../../../ui/ModalFull"
import { Text } from "../../../../ui/Typography/Text/Text"
import GenericForm from "../forms/GenericForm"
import { TypeEnum } from "../forms/RadioGroupOptions/typeOptions"
import { discountFormResolver } from "../forms/discountForm.yup"
import { useGetOneDiscountQuery } from "../forms/graphql/getOneDiscount.generated"
import { useUpdateDiscountMutation } from "../forms/graphql/updateDiscount.generated"
import type { IDiscountForm } from "../interfaces/hookforms.interfaces"
import { customerNameFormatter } from "../utils/customerNameFormatter"
import { generateTimeFromForm } from "../utils/generateTimeFromForm"
import { getAppliesTo } from "../utils/getAppliesTo"
import { getDateRule } from "../utils/getDateRule"
import { getEligibleCustomerType } from "../utils/getEligibleCustomerType"
import { getUsageLimit } from "../utils/getUsageLimit"
import { isValidUser } from "../utils/isUser"
import { get } from "lodash"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

interface IDiscountParam {
  discountUUID: string
}

export const EditDiscount: React.FC = () => {
  const intl = useIntl()
  const { discountUUID } = useParams<IDiscountParam>()

  const history = useHistory()
  const { restaurants } = paths

  const formMethods = useForm<IDiscountForm>({
    mode: "all",
    resolver: discountFormResolver,
  })

  const { reset } = formMethods

  const { data, loading: loadingDiscount } = useGetOneDiscountQuery({
    variables: {
      uuid: discountUUID,
    },
    fetchPolicy: "network-only",
    onCompleted: ({ getOneDiscount }) => {
      const { startDate, endDate, addEndTime } = getDateRule(
        getOneDiscount.rule?.startDate as string,
        getOneDiscount.rule?.endDate as string | undefined
      )

      const { items, allCombos, allItems, combos, entireOrder } =
        getAppliesTo(getOneDiscount)

      const { limitPerCustomer, limitedTo } = getUsageLimit(getOneDiscount)

      if (getOneDiscount) {
        const allOrderTypes = getOneDiscount.rule?.orderTypes?.includes(
          DiscountsOrderMethodEnum.ALL
        )

        reset({
          editMode: true,
          code: getOneDiscount?.code ?? "",
          type: getOneDiscount?.percentage
            ? TypeEnum.PERCENTAGE
            : TypeEnum.FIXED_AMOUNT,
          percentOrAmount:
            getOneDiscount?.percentage ??
            getOneDiscount?.fixedAmount ??
            undefined,
          entryMethod:
            getOneDiscount?.entryMethod ?? DiscountsEntryMethodsEnum.MANUAL,
          stacking: getOneDiscount.rule?.stackingAvailable ?? false,
          rules: {
            dateAndTime: {
              startDate,
              addEndTime,
              endDate,
            },
            locationAvailability: {
              locations: getOneDiscount.rule?.locations ?? [],
            },
            appliesTo: {
              entireOrder,
              items,
              allCombos,
              combos,
              allItems,
            },
            orderType: {
              orderTypes: allOrderTypes
                ? []
                : getOneDiscount.rule?.orderTypes ?? [],
            },
            usageLimit: {
              limitedTo,
              limitPerCustomer,
            },
            eligibleCustomer: {
              type: getEligibleCustomerType({
                users: getOneDiscount.rule?.users,
                lifetimeSpend: getOneDiscount.rule?.lifetimeSpend,
              }),
              spend: getOneDiscount.rule?.lifetimeSpend ?? null,
              users:
                // eslint-disable-next-line unicorn/no-array-callback-reference
                getOneDiscount.rule?.users.filter(isValidUser).map((user) => {
                  const formattedName = customerNameFormatter({
                    firstName: user.firstName,
                    phone: user.phone,
                    lastName: user.lastName,
                  })

                  return {
                    uuid: user.uuid,
                    label: formattedName,
                  }
                }) ?? [],
            },
          },
        })
      }
    },
    onError: showGraphqlErrors,
  })

  const discountData = get(data, "getOneDiscount")

  const [updateDiscount, { loading }] = useUpdateDiscountMutation()

  const onUpdateDiscount = async (formData: IDiscountForm) => {
    try {
      const startDate = generateTimeFromForm({
        date: formData.rules.dateAndTime.startDate.date,
        hour: formData.rules.dateAndTime.startDate.hour,
      })

      if (!startDate)
        throw new Error(
          intl.formatMessage({
            id: "generic.start.date.required",
            defaultMessage: "Start date is required",
          })
        )

      const endDate =
        formData.rules.dateAndTime.endDate?.date &&
        generateTimeFromForm({
          date: formData.rules.dateAndTime.endDate?.date,
          hour: formData.rules.dateAndTime.endDate?.hour,
        })

      if (!startDate) throw new Error("Start date is required")

      const discountUpdated = await updateDiscount({
        variables: {
          input: {
            uuid: discountUUID,
            code: formData.code,
            entryMethod: formData.entryMethod,
            percentage:
              formData.type === TypeEnum.PERCENTAGE
                ? formData.percentOrAmount
                : null,
            fixedAmount:
              formData.type === TypeEnum.FIXED_AMOUNT
                ? formData.percentOrAmount
                : null,
            rule: {
              stackingAvailable: formData.stacking,
              startDate: startDate.toISOString(),
              endDate: endDate ? endDate.toISOString() : null,
              generatedQuantity: formData.rules.usageLimit.limitedTo,
              limitPerCustomer: formData.rules.usageLimit.limitPerCustomer,
            },
          },
        },
      })

      const uuid = get(discountUpdated, "data.updateDiscount.uuid")

      if (uuid) {
        reset({}, { keepValues: true })
        history.push(restaurants.discounts)
      }
    } catch (discountError) {
      showGraphqlErrors(discountError)
    }
  }

  const closeModal = () => {
    return history.push(restaurants.discounts)
  }

  return loadingDiscount ? (
    <ModalFullSkeleton />
  ) : (
    <ModalFull
      visible
      closable
      onCancel={closeModal}
      title={
        <Container display="flex" gap="16px" alignItems="center">
          {intl.formatMessage({
            id: "restaurants.discount.header.title",
            defaultMessage: "Edit Discount",
          })}
          <StyledText size="s">
            <Badge
              color={
                discountData?.status === DiscountStatusesEnum.ACTIVE
                  ? "Success5"
                  : "Neutral5"
              }
            />
            {firstLetterToUpperCase(discountData?.status)}
          </StyledText>
        </Container>
      }
    >
      <FormProvider {...formMethods}>
        <GenericForm
          loading={loading}
          onSubmit={onUpdateDiscount}
          showDiscardButton={true}
        />
      </FormProvider>
    </ModalFull>
  )
}

const StyledText = styled(Text)`
  border: 1px solid ${({ theme }) => theme.colors.Neutral4};
  border-radius: 2px;
  border-style: dashed;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colors.Neutral6};
`
