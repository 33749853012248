import { DiscountsEntryMethodsEnum } from "../../../../../shared/graphql/generated/types"
import paths from "../../../../../shared/routes/paths"
import { DEBOUNCE_MILLISECONDS } from "../../../../../shared/utils/constant/values"
import Button from "../../../../../ui/Button/Button"
import Container from "../../../../../ui/Container"
import InputLabel from "../../../../../ui/InputLabel"
import Input, { InputNumber } from "../../../../../ui/Inputs"
import RadioGroupButton from "../../../../../ui/Radio/RadioGroupButton"
import Spacer from "../../../../../ui/Spacer"
import Switch from "../../../../../ui/Switch"
import Text from "../../../../../ui/Typography/Text"
import { notification } from "../../../../../ui/notification/notification"
import type { IDiscountForm } from "../../interfaces/hookforms.interfaces"
import entryMethodOptions from "../RadioGroupOptions/entryMethodOptions"
import typeOptions, { TypeEnum } from "../RadioGroupOptions/typeOptions"
import {
  AppliesTo,
  DateAndTimeRule,
  DiscountRules,
  EligibleCustomer,
  LocationAvailability,
  OrderType,
  UsageLimit,
} from "../Rules"
import { SET_VALUE_OPTIONS } from "../Rules/constants"
import { useGenerateDiscountCodeLazyQuery } from "../graphql/generateDiscountCode.generated"
import { useGetDiscountByCodeLazyQuery } from "../graphql/getDiscountByCode.generated"
import {
  CODE_EXISTS_NAME,
  CODE_NAME,
  MAX_INPUT_VALUE,
  PERCENT_OR_AMOUNT_NAME,
} from "./constants"
import { validationsTitle } from "./validations.title"
import debounce from "lodash/debounce"
import { useEffect, useMemo, useRef } from "react"
import type { ChangeEvent } from "react"
import { useFormContext } from "react-hook-form"
import { Controller } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt, useHistory } from "react-router-dom"
import styled, { css } from "styled-components"

type GenericFormProps = {
  loading: boolean
  onSubmit: (data: IDiscountForm) => Promise<void>
  showDiscardButton?: boolean
}

const GenericForm = ({
  loading,
  onSubmit,
  showDiscardButton,
}: GenericFormProps) => {
  const intl = useIntl()
  const codeRef = useRef("")

  const history = useHistory()

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    watch,
    setFocus,
    getValues,
    trigger,
    reset,
  } = useFormContext<IDiscountForm>()

  const { errors, isDirty } = formState

  const [generateDiscountCode, { loading: generateDiscountLoading }] =
    useGenerateDiscountCodeLazyQuery({
      fetchPolicy: "network-only",
    })

  const [discountCountExist] = useGetDiscountByCodeLazyQuery({
    fetchPolicy: "network-only",
  })

  const code = watch("code")
  codeRef.current = codeRef.current || code

  const entryMethod = watch("entryMethod")
  const automaticModeDescription = intl.formatMessage({
    id: "restaurants.discount.forms.generic.radio.button.automatic.entry.mode.description",
    defaultMessage:
      "The discount code is applied automatically in the checkout.",
  })
  const manualModeDescription = intl.formatMessage({
    id: "restaurants.discount.forms.generic.radio.button.manual.entry.mode.description",
    defaultMessage: "Customer must enter the discount code manually",
  })

  const onAutoGenerateCode = async () => {
    const { data } = await generateDiscountCode()
    if (!data?.generateDiscountCode) {
      return notification({
        title: intl.formatMessage({
          id: "restaurants.discount.forms.generic.button.auto.generate.error",
          defaultMessage: "Couldn't generate code. Try again",
        }),
        type: "error",
      })
    }

    setValue("code", data?.generateDiscountCode, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const handleOnSubmit = (formData: IDiscountForm) => {
    if (code && !isDirty) {
      history.push(paths.restaurants.discounts)
      return
    }

    onSubmit(formData)
  }

  const handleDiscardChanges = () => {
    reset()
  }

  const onCodeChange = async (value: string, initialValue: string) => {
    if (value.length > 3 && value !== initialValue) {
      const result = await discountCountExist({ variables: { code: value } })
      const codes = result.data?.getDiscountByCode?.uuid

      const codeExists = (codes?.length ?? 0) > 0

      setValue(CODE_EXISTS_NAME, codeExists)
      return
    }

    if (value.length < 4) {
      setValue(CODE_EXISTS_NAME, false)
    }

    trigger(CODE_NAME)
  }

  const percentOrAmountFieldError = errors?.percentOrAmount?.message
    ? intl.formatMessage(validationsTitle[errors?.percentOrAmount?.message], {
        maxValue: MAX_INPUT_VALUE,
      })
    : undefined

  const isFixedAmount = getValues("type") === TypeEnum.FIXED_AMOUNT

  const onCodeChangeDebounce = useMemo(
    () => debounce(onCodeChange, DEBOUNCE_MILLISECONDS),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    return () => onCodeChangeDebounce.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <StyleContainer gap="24px">
        <div style={{ width: "100%" }}>
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <Input
                {...field}
                aria-label="name"
                label={intl.formatMessage({
                  id: "restaurants.discount.forms.generic.input.code.title",
                  defaultMessage: "Discount Code",
                })}
                requirement="required"
                hasError={!!errors.code}
                value={field.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e)
                  onCodeChangeDebounce(e.target.value, codeRef.current)
                }}
                maxLength={50}
                useEllipsis
              />
            )}
          />

          <StyleContainer gap="16px" $hasError={!!errors.code}>
            <Text className="helper__error__message" size="s">
              {errors.code?.message
                ? intl.formatMessage(validationsTitle[errors.code?.message])
                : intl.formatMessage({
                    id: "restaurants.discount.forms.generic.input.code.description",
                    defaultMessage:
                      "Minimum 4 characters. Letters and numbers allowed, no spaces or special characters.",
                  })}
            </Text>
            <Text className="helper__error__message" size="s">
              {intl.formatMessage({
                id: "restaurants.discount.forms.generic.input.counter",
                defaultMessage: `${code?.length}/50`,
              })}
            </Text>
          </StyleContainer>
        </div>

        <StyleButtonAutoGenerateCode
          onClick={onAutoGenerateCode}
          loading={generateDiscountLoading}
          hierarchy="secondary"
        >
          {intl.formatMessage({
            id: "restaurants.discount.forms.generic.button.auto.generate.title",
            defaultMessage: "Auto Generate Code",
          })}
        </StyleButtonAutoGenerateCode>
      </StyleContainer>

      <Spacer size={64} />
      <Container>
        <Text size="m" color="Neutral8">
          {intl.formatMessage({
            id: "restaurants.discount.forms.generic.radio.button.entry.mode.title",
            defaultMessage: "Entry Method",
          })}
        </Text>
        <Spacer size={8} />
        <Controller
          control={control}
          name="entryMethod"
          render={({ field }) => (
            <RadioGroupButton
              type="secondary"
              value={field.value}
              items={entryMethodOptions.map(
                ({ value, labelId, defaultMessage }) => ({
                  value,
                  label: intl.formatMessage({ id: labelId, defaultMessage }),
                })
              )}
              onChange={field.onChange}
            />
          )}
        />
        <Text size="s" color="Neutral5">
          {entryMethod === DiscountsEntryMethodsEnum.MANUAL
            ? manualModeDescription
            : automaticModeDescription}
        </Text>
      </Container>
      <Spacer size={64} />
      <TypeContainer>
        <InputLabel
          label={intl.formatMessage({
            id: "restaurants.discount.forms.generic.input.type.title",
            defaultMessage: "Type",
          })}
          requirement="required"
        />
        <Spacer size={8} />

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Container display="flex" gap="8px">
              <RadioGroupButton
                type="secondary"
                value={field.value}
                items={typeOptions.map(
                  ({ value, labelId, defaultMessage }) => ({
                    value,
                    label: intl.formatMessage({ id: labelId, defaultMessage }),
                  })
                )}
                onChange={(option) => {
                  setValue(PERCENT_OR_AMOUNT_NAME, null, SET_VALUE_OPTIONS)
                  setFocus(PERCENT_OR_AMOUNT_NAME)
                  field.onChange(option)
                }}
              />

              <Controller
                control={control}
                name={PERCENT_OR_AMOUNT_NAME}
                render={({ field: percentOrAmountField }) => (
                  <InputNumber
                    {...percentOrAmountField}
                    hasError={!!errors.percentOrAmount}
                    helperText={percentOrAmountFieldError}
                    aria-label="percentOrAmount"
                    value={percentOrAmountField.value ?? undefined}
                    onChange={percentOrAmountField.onChange}
                    precision={2}
                    useEllipsis
                    suffix={isFixedAmount ? null : "%"}
                    prefix={isFixedAmount ? "$" : null}
                  />
                )}
              />
            </Container>
          )}
        />
      </TypeContainer>
      <Spacer size={64} />
      <Text size="m">
        {intl.formatMessage({
          id: "restaurants.discount.forms.generic.switch.stacking.title",
          defaultMessage: "Stacking",
        })}
      </Text>

      <Container display="flex" gap="8px" justifyContent="space-between">
        <Text size="s" color="Neutral8">
          {intl.formatMessage({
            id: "restaurants.discount.forms.generic.switch.stacking.description",
            defaultMessage:
              "The discount code can be used simultaneously with other discount codes",
          })}
        </Text>
        <Controller
          name="stacking"
          control={control}
          render={({ field }) => {
            return (
              <Switch
                size="default"
                checked={!!field.value}
                onChange={(checked: boolean) => {
                  field.onChange({ target: { value: checked } })
                }}
              />
            )
          }}
        />
      </Container>
      <Spacer size={64} />
      <DiscountRules>
        <DateAndTimeRule />
        <UsageLimit />
        <LocationAvailability />
        <AppliesTo />
        <OrderType />
        <EligibleCustomer />
      </DiscountRules>
      <Spacer size={64} />
      <Container display="flex" justifyContent="flex-start" gap="16px">
        <Button
          loading={loading}
          title={intl.formatMessage({
            id: "restaurants.discount.forms.generic.button.save.title",
            defaultMessage: "Save changes",
          })}
          disabled={loading}
          onClick={handleSubmit(handleOnSubmit)}
        />

        {!!showDiscardButton && (
          <Button
            hierarchy="secondary"
            title={intl.formatMessage({
              id: "restaurants.discount.forms.generic.button.discard.changes.title",
              defaultMessage: "Discard Changes",
            })}
            disabled={!isDirty}
            onClick={handleDiscardChanges}
          />
        )}
      </Container>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "If you leave the discount creation before saving, your progress will be lost.",
        })}
      />
    </Container>
  )
}

export default GenericForm

const StyleContainer = styled(Container)<{ $hasError?: boolean }>`
  display: flex;
  justify-content: space-between;

  .helper__error__message {
    ${({ theme, $hasError }) => css`
      color: ${$hasError ? theme.colors.Danger5 : theme.colors.Neutral5};
    `}
  }
`

const StyleButtonAutoGenerateCode = styled(Button)`
  transform: translateY(110%);
`

const TypeContainer = styled(Container)`
  .input-helper span {
    font-size: 13px;
  }
`
