import PageTitle from "../../../../../../ui/PageTitle"
import Spacer from "../../../../../../ui/Spacer"
import HighlightedText from "../../../../../../ui/Typography/HighlightedText"
import TechInfoItem from "./TechInfoItem"
import { useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface TechInfoProps {
  whiteLabelConfig: {
    uuid?: string | null
    ios?: string | null
    android?: string | null
  }
}

export const TechInfo = ({ whiteLabelConfig }: TechInfoProps) => {
  const intl = useIntl()
  const techInfoSectionRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (techInfoSectionRef.current) {
      techInfoSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [])

  return (
    <section ref={techInfoSectionRef}>
      <PageTitle
        border
        title={intl.formatMessage({
          id: "restaurants.home.tech.info.title",
          defaultMessage: "Tech Info",
        })}
        description={
          <HighlightedText color="Neutral9" size="s">
            {intl.formatMessage({
              id: "restaurants.home.tech.info.description",
              defaultMessage:
                "Data that is specifically utilized by the technical teams to ensure efficient operation and maintenance of the apps.",
            })}
          </HighlightedText>
        }
      />
      <Spacer size={10} />
      <TechInfoContainer>
        {whiteLabelConfig.uuid && (
          <TechInfoItem
            title={intl.formatMessage({
              id: "restaurants.home.tech.info.uuid.title",
              defaultMessage: "UUID",
            })}
            content={whiteLabelConfig.uuid}
          />
        )}
        {whiteLabelConfig.ios && (
          <TechInfoItem
            title={intl.formatMessage({
              id: "restaurants.home.tech.info.ios.title",
              defaultMessage: "iOS",
            })}
            content={whiteLabelConfig.ios}
          />
        )}
        {whiteLabelConfig.android && (
          <TechInfoItem
            title={intl.formatMessage({
              id: "restaurants.home.tech.info.android.title",
              defaultMessage: "Android",
            })}
            content={whiteLabelConfig.android}
          />
        )}
      </TechInfoContainer>
    </section>
  )
}

const TechInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
