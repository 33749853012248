import paths from "../../../../shared/routes/paths"
import storage from "../../../../shared/utils/storage"
import { ORDERS_FILTERS } from "../../../../shared/utils/storage/constants"
import AddIcon from "../../../../ui/AddIcon"
import Container from "../../../../ui/Container"
import FilterByDate from "../../../../ui/Header/Filter/FilterByDate"
import { useHistory } from "react-router-dom"

export const OrdersHeaderActions = () => {
  const { push } = useHistory()

  const redirectToCreateOrder = () => {
    storage.set(ORDERS_FILTERS, window.location.search)
    push(paths.restaurants.createOrder)
  }

  return (
    <Container width="100%" display="flex" alignItems="center" gap="24px">
      <FilterByDate />
      <AddIcon hidden onClick={redirectToCreateOrder} />
    </Container>
  )
}
