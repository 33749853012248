import Background from "../../components/Background"
import logo from "../../shared/assets/images/logo.png"
import { useLoginContext } from "../../shared/contexts/LoginProvider"
import { requestState, useAsync } from "../../shared/hooks/useAsync"
import paths from "../../shared/routes/paths"
import { endpoints } from "../../shared/utils/api/endpoints"
import Button from "../../ui/Button"
import Container from "../../ui/Container"
import Input from "../../ui/Inputs/SimpleInput"
import QuestionButton from "../../ui/QuestionButton"
import Spacer from "../../ui/Spacer"
import Slider from "../../ui/animations/Slider"
import { LoginWithEmailResolver } from "./Login.yup"
import { ActionTypes } from "./actions"
import { createMagicLink } from "./api"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

interface ILoginFields {
  email: string
}

export const Login: React.FC = () => {
  const { push } = useHistory()
  const intl = useIntl()
  const [displayEmailInput, setDisplayEmailInput] = useState<boolean>(false)

  const {
    handleSubmit,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm<ILoginFields>({ resolver: LoginWithEmailResolver, mode: "all" })
  const emailWatcher = watch("email")
  const { dispatch } = useLoginContext()

  const { execute, status, error } = useAsync(() =>
    createMagicLink({
      email: emailWatcher,
    })
  )

  const showEmailInput = () => {
    setDisplayEmailInput(true)
  }

  const onContinueWithEmail = async ({ email }: ILoginFields) => {
    if (displayEmailInput && email) {
      dispatch({
        type: ActionTypes.MagicLinkEmail,
        payload: { email },
      })

      execute()
    }
  }

  const onContinueWithGoogle = () => {
    window.location.replace(
      `${process.env.REACT_APP_API_URL}/${endpoints.auth.google}`
    )
  }

  useEffect(() => {
    if (status === requestState.FULFILLED && !error) {
      push(paths.guest.loginConfirmation)
    }
  }, [status, push, error])

  return (
    <Container centered fullScreen display="flex" flexDirection="column">
      <Background />
      <StyledLogo src={logo} alt="Crmb logo" />

      <Spacer size={24} />

      <StyledButton
        size="large"
        type="button"
        leadingIcon="ri-google-line"
        onClick={onContinueWithGoogle}
        title={intl.formatMessage({
          id: "login.google",
          defaultMessage: "Continue with Google",
        })}
      />

      <Spacer size={24} />

      <form
        onSubmit={handleSubmit(onContinueWithEmail)}
        style={{ width: "100%" }}
      >
        <Slider
          isOpen={displayEmailInput}
          setIsOpen={showEmailInput}
          offsetY={-62}
        >
          <>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  aria-label="email"
                  size="large"
                  hasError={!!errors.email}
                  width="90%"
                  maxWidth="400px"
                />
              )}
            />
            <Spacer size={24} />
          </>
          <StyledButton
            size="large"
            loading={status === requestState.LOADING}
            type={displayEmailInput ? "submit" : "button"}
            disabled={displayEmailInput && !isValid}
            title={intl.formatMessage({
              id: "login.email",
              defaultMessage: "Continue with Email",
            })}
          />
        </Slider>
      </form>
      <QuestionButton />
    </Container>
  )
}

const StyledLogo = styled.img`
  max-width: 200px;
  margin-bottom: 20px;
`

const StyledButton = styled(Button)`
  width: 90%;
  max-width: 400px;
`
