import paths from "../../shared/routes/paths"
import Button from "../../ui/Button"
import Container from "../../ui/Container"
import type { ResultProps } from "antd"
import { Result } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import styled from "styled-components"

interface ErrorProps extends ResultProps {
  error?: { message: string }
  onBackHome?: () => void
}

export const ErrorPage: React.FC<ErrorProps> = (props) => {
  const intl = useIntl()

  return (
    <Container position="relative" centered fullScreen>
      <Result
        status="500"
        {...props}
        title={intl.formatMessage({
          id: "generic.error.page.message",
          defaultMessage: "Something went wrong",
        })}
        subTitle={props?.error?.message}
        extra={
          <StyledLink to={paths.root}>
            <Button
              onClick={() => {
                props.onBackHome?.()
              }}
              hierarchy="secondary"
              title={intl.formatMessage({
                id: "generic.error.page.button",
                defaultMessage: "Back Home",
              })}
            />
          </StyledLink>
        }
      />
    </Container>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
`
