import { GetListDiscountsQuery } from "../../../pages/Restaurants/Discounts/forms/graphql/getAllDiscounts.generated"
import { Discount } from "../generated/types"

export const updateGetDiscountsListQuery = (
  prev: GetListDiscountsQuery,
  { fetchMoreResult }: { fetchMoreResult?: GetListDiscountsQuery }
) => {
  if (
    !fetchMoreResult ||
    prev?.getListDiscounts.endCursor ===
      fetchMoreResult?.getListDiscounts?.endCursor
  ) {
    return prev
  }

  return {
    getListDiscounts: {
      ...fetchMoreResult.getListDiscounts,
      results: [
        ...(prev.getListDiscounts.results as Discount[]),
        ...(fetchMoreResult.getListDiscounts.results as Discount[]),
      ],
    },
  }
}
