import type { Action } from "../../shared/contexts/actions"
import type {
  OnboardingLocationPayload,
  OnboardingRestaurantPayload,
} from "./actions.types"

export enum ActionTypes {
  UpdateOnboardingFlag = "updateOnboardingFlag",
  AddRestaurant = "addRestaurant",
  AddLocation = "addLocation",
}

export interface OnboardingAction
  extends Action<ActionTypes.UpdateOnboardingFlag> {
  payload: {
    isOnboardingCompleted?: boolean
    allowOnboarding?: boolean
  }
}

export interface AddLocationAction extends Action<ActionTypes.AddLocation> {
  payload: OnboardingLocationPayload
}

export interface AddRestaurantAction extends Action<ActionTypes.AddRestaurant> {
  payload: OnboardingRestaurantPayload
}
