import emptyIcon from "../../../../../shared/assets/icons/empty_icon.svg"
import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import EmptyState from "../../../../../ui/EmptyState"
import AddModifiersButton from "../../AddModifiersButton"
import React from "react"
import { useIntl } from "react-intl"

type EmptyStateModifierProps = {
  isCorporate?: boolean
}

const EmptyStateModifiers: React.FC<EmptyStateModifierProps> = ({
  isCorporate,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  return (
    <EmptyState
      title={intl.formatMessage(
        {
          id: "restaurants.modifiers.empty.title",
          defaultMessage: "You don’t have any modifiers yet",
        },
        {
          hasSearch: Number(!!search),
          search,
        }
      )}
      description={intl.formatMessage(
        {
          id: "restaurants.modifiers.empty.description",
          defaultMessage: "Let’s start by creating your first modifier group",
        },
        {
          hasSearch: Number(!!search),
        }
      )}
      button={<AddModifiersButton isCorporate={isCorporate} createButton />}
      image={emptyIcon}
      fullScreen
    />
  )
}

export default EmptyStateModifiers
