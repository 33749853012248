import { IntlMessage } from "../../../../../shared/types"
import {
  FeeQuantityEnum,
  OrderMethodAcceptanceEnum,
} from "./hookforms.intefaces"

export type OrderAcceptanceMethods = Record<
  OrderMethodAcceptanceEnum,
  IntlMessage
>

export type CateringFeeTypes = Record<FeeQuantityEnum, IntlMessage>

export const orderAcceptanceMethods: OrderAcceptanceMethods = {
  AUTOMATIC: {
    defaultMessage: "Automatic",
    id: "restaurants.settings.location.catering.segmented.control.automatic.label",
  },
  MANUAL: {
    defaultMessage: "Manual",
    id: "restaurants.settings.location.catering.segmented.control.manual.label",
  },
}

export const cateringFeeTypes: CateringFeeTypes = {
  FIXED_AMOUNT: {
    defaultMessage: "Fixed amount",
    id: "restaurants.settings.location.catering.segmented.control.fixed.amount.label",
  },
  PERCENTAGE: {
    defaultMessage: "Percentage",
    id: "restaurants.settings.location.catering.segmented.control.percentage.label",
  },
}

export const RulesMessage = {
  typeOfService: {
    title: {
      defaultMessage: "Type of service",
      id: "restaurants.settings.location.catering.rules.service.type.title",
    },
    description: {
      defaultMessage:
        "Which catering type of service can your location handle?",
      id: "restaurants.settings.location.catering.rules.service.type.description",
    },
  },
  orderScheduling: {
    title: {
      defaultMessage: "Order scheduling",
      id: "restaurants.settings.location.catering.rules.order.scheduling.title",
    },
    description: {
      defaultMessage:
        "Catering schedule follows the location’s operating hours (standard and holidays). Any updates to the schedule should be made from the Hours of Operation section.",
      id: "restaurants.settings.location.catering.rules.order.scheduling.description",
    },
    label: {
      defaultMessage: "Days in advance to place a catering order",
      id: "restaurants.settings.location.catering.rules.order.scheduling.input.label",
    },
    caption: {
      defaultMessage: "Only numbers are allowed",
      id: "restaurants.settings.location.catering.rules.order.scheduling.input.caption",
    },
  },
  minimumAmount: {
    title: {
      defaultMessage: "Minimum order amount",
      id: "restaurants.settings.location.catering.rules.minimum.amount.title",
    },
    deliveryCheckbox: {
      defaultMessage: "Apply minimum for delivery",
      id: "restaurants.settings.location.catering.rules.minimum.amount.delivery.checkbox",
    },
    pickupCheckbox: {
      defaultMessage: "Apply minimum for pickup",
      id: "restaurants.settings.location.catering.rules.minimum.amount.pickup.checkbox",
    },
    foodTruckExperienceCheckbox: {
      defaultMessage: "Apply minimum for food truck experience",
      id: "restaurants.settings.location.catering.rules.minimum.amount.food.truck.experience.checkbox",
    },
    insideVenueCheckbox: {
      defaultMessage: "Apply minimum for inside venue",
      id: "restaurants.settings.location.catering.rules.minimum.amount.inside.venue.checkbox",
    },
  },
  cateringFee: {
    title: {
      defaultMessage: "Catering fee",
      id: "restaurants.settings.location.catering.rules.catering.fee.title",
    },
    delivery: {
      defaultMessage: "Apply minimum for delivery",
      id: "restaurants.settings.location.catering.rules.catering.fee.delivery",
    },
    pickup: {
      defaultMessage: "Apply minimum for pickup",
      id: "restaurants.settings.location.catering.rules.catering.fee.pickup",
    },
    foodTruckExperience: {
      defaultMessage: "Apply minimum for food truck experience",
      id: "restaurants.settings.location.catering.rules.catering.fee.food.truck.experience",
    },
    insideVenue: {
      defaultMessage: "Apply minimum for inside venue",
      id: "restaurants.settings.location.catering.rules.catering.fee.inside.venue",
    },
    amount: {
      defaultMessage: "Fixed amount",
      id: "restaurants.settings.location.catering.rules.catering.fee.amount",
    },
    percentage: {
      defaultMessage: "Percentage",
      id: "restaurants.settings.location.catering.rules.catering.fee.percentage",
    },
  },
  cancelationPolicy: {
    title: {
      defaultMessage: "Cancelation policy",
      id: "restaurants.settings.location.catering.rules.cancelation.policy.title",
    },
    list: [
      {
        id: "restaurants.settings.location.catering.rules.cancelation.policy.list.processed.or.completed",
        defaultMessage:
          "Cancelation of a catering order stops the transaction as well as the catering order from being processed or completed.",
      },
      {
        id: "restaurants.settings.location.catering.rules.cancelation.policy.list.status",
        defaultMessage:
          "Cancelation of catering orders is only available for orders with the status of 'Placed'",
      },
      {
        id: "restaurants.settings.location.catering.rules.cancelation.policy.list.contact.to.restaurant",
        defaultMessage:
          "To request a cancelation of a catering order, the customer must contact the restaurant.",
      },
    ],
    checkbox: {
      id: "restaurants.settings.location.catering.rules.cancelation.policy.checkbox.label",
      defaultMessage: "Apply charges when canceling a catering order",
    },
  },
  refundPolicy: {
    title: {
      defaultMessage: "Refund Policy",
      id: "restaurants.settings.location.catering.rules.refund.policy.title",
    },
    list: [
      {
        id: "restaurants.settings.location.catering.rules.refund.policy.list.item.complete.label",
        defaultMessage:
          "Only orders with the status of 'Complete' are eligible for refund.",
      },
      {
        id: "restaurants.settings.location.catering.rules.refund.policy.list.item.customer.contact.label",
        defaultMessage:
          "To request a refund the customer must contact the restaurant.",
      },
      {
        id: "restaurants.settings.location.catering.rules.refund.policy.list.item.owner.or.manager.label",
        defaultMessage:
          "Refund requests are granted at the sole discretion of the restaurant owner or manager.",
      },
    ],
  },
  payment: {
    title: {
      defaultMessage: "Payment",
      id: "restaurants.settings.location.catering.rules.payment.title",
    },
    description: {
      defaultMessage:
        "Determine the amount or percentage you'd like to request upfront to confirm the event.",
      id: "restaurants.settings.location.catering.rules.payment.description",
    },
  },
}

export const CateringLabels = {
  pickup: {
    id: "restaurants.settings.location.catering.pickup.label",
    defaultMessage: "Pickup",
  },
  delivery: {
    id: "restaurants.settings.location.catering.delivery.label",
    defaultMessage: "Delivery",
  },
  foodTruckExperience: {
    id: "restaurants.settings.location.catering.food.truck.experience.label",
    defaultMessage: "Food Truck Experience",
  },
  insideVenue: {
    id: "restaurants.settings.location.catering.inside.venue.label",
    defaultMessage: "Inside Venue",
  },
}
