import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import type { RPublishModifierGroupInput } from "../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { ModifierType } from "../../ModifierGroupForm/hookforms.interfaces"
import useModifierGroups from "../../hooks/useModifierGroups"
import React from "react"
import { useIntl } from "react-intl"

interface PublishModifierGroupModalProps {
  modifierGroupUUID: string
  modifierGroupData?: Omit<RPublishModifierGroupInput, "modifiers"> & {
    modifiers: ModifierType[]
  }
  onOk: () => void
  onCancel: () => void
  isCorporate?: boolean
}

const PublishModifierGroupModal: React.FC<PublishModifierGroupModalProps> = ({
  modifierGroupUUID,
  modifierGroupData,
  onOk,
  onCancel,
  isCorporate,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()
  const search = getSearchQueryFilter()

  const {
    publishDraftModifierGroup,
    draftModifierGroupPublished,
    publishModifierGroup,
    modifierGroupPublished,
  } = useModifierGroups({
    search,
  })

  const onPublish = async () => {
    if (modifierGroupData) {
      return publishModifierGroup(modifierGroupData, onOk, showGraphqlErrors)
    }

    await publishDraftModifierGroup(modifierGroupUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.modifiers.modals.publish.modifier.group.title",
        defaultMessage: "Publish",
      })}
      subtitle={intl.formatMessage(
        {
          id: "restaurants.modifiers.modals.publish.modifier.group.subtitle",
          defaultMessage:
            "This modifier group is about to become {isCorporate, plural, =0 {accessible} other {visible to all your locations}}.",
        },
        { isCorporate: Number(isCorporate) }
      )}
      loading={
        draftModifierGroupPublished.loading || modifierGroupPublished.loading
      }
      onConfirm={onPublish}
      onCancel={onCancel}
      type="info"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.publish.modifier.group.confirm.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.publish.modifier.group.cancel.button",
        defaultMessage: "Publish",
      })}
      visible
      centered
    />
  )
}

export default PublishModifierGroupModal
