import { TAB_SEARCH_FILTER_NAME } from "../../../../../shared/utils/constant/searchParams"
import RadioGroupButton from "../../../../../ui/Radio/RadioGroupButton"
import { menuItemsTabsEnum } from "./utils/MenuItemsTabsEnum"
import { menuItemsOptions } from "./utils/menuItemsOptions"
import type { RadioChangeEvent } from "antd"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router"

const MenuItemsTabs = () => {
  const intl = useIntl()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const param = searchParams.get(TAB_SEARCH_FILTER_NAME)

  const setTab = (e: RadioChangeEvent) => {
    const params = new URLSearchParams()
    params.set(TAB_SEARCH_FILTER_NAME, e.target.value)
    history.replace({ search: params.toString() })
  }

  return (
    <RadioGroupButton
      items={menuItemsOptions.map((option) => ({
        label: intl.formatMessage({
          id: option.labelId,
          defaultMessage: option.label,
        }),
        value: option.value,
      }))}
      type="secondary"
      value={
        param === menuItemsTabsEnum.ITEMS || param === menuItemsTabsEnum.COMBOS
          ? param
          : menuItemsTabsEnum.ITEMS
      }
      onChange={setTab}
    />
  )
}

export default MenuItemsTabs
