import type {
  RModifierDetail,
  RModifierGroupDetail,
} from "../../../../../../shared/graphql/generated/types"
import { Container } from "../../../../../../ui/Container/Container"
import { Spacer } from "../../../../../../ui/Spacer/Spacer"
import { Tooltip } from "../../../../../../ui/Tooltip/Tooltip"
import { Text } from "../../../../../../ui/Typography/Text/Text"
import { Title } from "../../../../../../ui/Typography/Title/Title"
import { OwnershipTag } from "../../../../components/OwnershipTag/OwrnershipTag"
import ModifierStatusBadge from "../../../../components/StatusBadge"
import { StatusBadgeEnum } from "../../../../components/StatusBadge/StatusBadge"
import IngredientRow from "../IngredientRow/IngredientRow"
import { useIntl } from "react-intl"
import { Prompt } from "react-router-dom"

interface Props {
  modifierGroup: RModifierGroupDetail
  modifiers: RModifierDetail[]
  isDirty: boolean
  updateIngredients(modifier: RModifierDetail): void
}

const ModifierDetail = ({
  modifierGroup,
  modifiers,
  updateIngredients,
  isDirty,
}: Props) => {
  const intl = useIntl()

  return (
    <>
      <Container display="flex" gap="12px" alignItems="center">
        <Title weight="bold" size="s">
          {modifierGroup.name}
        </Title>
      </Container>
      <Spacer size={12} />
      <Container>
        <Tooltip
          title={intl.formatMessage({
            id: "restaurants.modifiers.modifier.group.modifier.detail.published.tooltip",
            defaultMessage: "Visible to locations",
          })}
          placement="bottom"
        >
          <ModifierStatusBadge
            status={
              modifierGroup.isPublished
                ? StatusBadgeEnum.PUBLISHED
                : StatusBadgeEnum.DRAFT
            }
          />
        </Tooltip>
      </Container>
      <Spacer size={8} />
      <OwnershipTag isMain={modifierGroup.isMain} />
      <Spacer size={64} />
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text size="m" weight="bold">
          {intl.formatMessage({
            id: "restaurants.modifiers.modifier.group.modifier.detail.title",
            defaultMessage: "Modifiers",
          })}
        </Text>
      </Container>
      <Spacer size={48} />
      {modifiers.length === 0 && (
        <Text>
          {intl.formatMessage({
            id: "restaurants.modifiers.modifier.group.modifier.detail.no.items",
            defaultMessage: "You have no modifiers in your group.",
          })}
        </Text>
      )}
      <Container display="flex" flexDirection="column" gap="28px 0">
        {modifiers.map((modifier, modifierIndex) => {
          return (
            <IngredientRow
              key={modifier.uuid}
              modifier={modifier}
              index={modifierIndex}
              updateIngredients={updateIngredients}
            />
          )
        })}
      </Container>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </>
  )
}

export default ModifierDetail
