import { DiscountsOrderMethodEnum } from "../../../../shared/graphql/generated/types"
import { IDiscountForm } from "../interfaces/hookforms.interfaces"

export const getOrderTypes = (formData: IDiscountForm) => {
  const allOrderTypes = formData.rules.orderType.orderTypes.length === 0

  const orderTypes = allOrderTypes
    ? [DiscountsOrderMethodEnum.ALL]
    : formData.rules.orderType.orderTypes.map(
        (orderType) =>
          DiscountsOrderMethodEnum[
            orderType as keyof typeof DiscountsOrderMethodEnum
          ]
      )

  return orderTypes
}
