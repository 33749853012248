import {
  GetAllLocationsByUserQuery,
  useGetAllLocationsByUserQuery,
} from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { updateGetAllLocationsByUser } from "../../../../../shared/graphql/updateQuery/updateGetAllLocationsByUser"
import { useSearchParams } from "../../../../../shared/hooks/useSearchParams"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import { LOCATIONS_QUERY_PARAM } from "../../constants"
import styles from "./LocationButtonDropdown.module.css"
import {
  Button,
  Center,
  Checkbox,
  Group,
  Popover,
  Skeleton,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { IconMapPin, IconSearch } from "@tabler/icons-react"
import { debounce } from "lodash"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

export const LocationButtonDropdown = () => {
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      selectedLocation: { uuid: locationUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const { setParam, getParam, deleteParam } = useSearchParams()

  const {
    data,
    loading,
    fetchMore,

    refetch: refetchLocations,
  } = useGetAllLocationsByUserQuery({
    fetchPolicy: "cache-first",
    variables: { restaurantUUID, userUUID, isActive: true },
    skip: !restaurantUUID || !userUUID || !!locationUUID,
    notifyOnNetworkStatusChange: true,
  })

  const locations =
    data?.getAllLocationsByUser.results?.map((location) => ({
      value: location.uuid,
      label: location.name,
    })) ?? []

  const [opened, setOpened] = useState(false)
  const intl = useIntl()

  const hasNextPage = data?.getAllLocationsByUser?.hasNextPage ?? false
  const endCursor = data?.getAllLocationsByUser?.endCursor ?? null
  const canFetchMore = !loading && hasNextPage && endCursor

  const fetchMoreLocationsByUser = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          restaurantUUID,
          userUUID,
          isActive: true,
          after: endCursor,
        },
        updateQuery: (prev: GetAllLocationsByUserQuery, { fetchMoreResult }) =>
          updateGetAllLocationsByUser(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor, fetchMore, restaurantUUID, userUUID])

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLElement
    const scrolledToBottom =
      Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1

    if (canFetchMore && scrolledToBottom) {
      fetchMoreLocationsByUser()
    }
  }

  const handleCheckboxesChange = (values: Array<string>) => {
    if (values.length === 0) {
      return deleteParam(LOCATIONS_QUERY_PARAM)
    }

    setParam("replace", LOCATIONS_QUERY_PARAM, values.join(","))
  }

  const onSearchLocations = useMemo(() => {
    const onSearch = async (value: string) => {
      try {
        await refetchLocations({
          restaurantUUID,
          name: value || undefined,
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    return debounce(onSearch, 600)
  }, [refetchLocations, restaurantUUID])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchLocations(event.target.value)
  }

  useEffect(() => {
    return () => onSearchLocations.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Popover opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Button
          px={16}
          variant="outline"
          onClick={() => setOpened((o) => !o)}
          leftIcon={<IconMapPin size={16} />}
        >
          <Text ml={8} size="sm" weight={400}>
            {intl.formatMessage({
              id: "restaurant.reports.header.location.button",
              defaultMessage: "Location",
            })}
          </Text>
        </Button>
      </Popover.Target>
      <Popover.Dropdown
        p={0}
        m={0}
        miw={306}
        mah={208}
        className={styles["popover-dropdown"]}
        onScroll={handleScroll}
      >
        <Stack spacing={0} m={0} pos="relative">
          <TextInput
            bg="white"
            onChange={handleSearchChange}
            className={styles["search-input"]}
            icon={<IconSearch size={20} />}
            placeholder={intl.formatMessage({
              id: "restaurant.reports.header.location.popover.placeholder",
              defaultMessage: "Search location",
            })}
          />
          <Checkbox.Group
            withAsterisk
            px={8}
            m={0}
            onChange={handleCheckboxesChange}
            defaultValue={getParam(LOCATIONS_QUERY_PARAM)?.split(",")}
          >
            <Stack spacing={0} justify="center">
              {locations.map((location, index) => (
                <Checkbox
                  key={index}
                  pl={4}
                  py={10}
                  value={location.value}
                  label={<Text size="md">{location.label}</Text>}
                  size="lg"
                />
              ))}

              {loading
                ? Array.from({ length: 4 }).map((_, index) => (
                    <Group key={index} spacing={8} noWrap ml={4}>
                      <Skeleton h={20} w={20} my={12} />
                      <Skeleton h={16} w={72} />
                    </Group>
                  ))
                : locations.length === 0 && (
                    <Center h={40}>
                      <Text size="md">
                        {intl.formatMessage({
                          id: "restaurants.reports.header.location.popober.empty.label",
                          defaultMessage: "No results found",
                        })}
                      </Text>
                    </Center>
                  )}
            </Stack>
          </Checkbox.Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
