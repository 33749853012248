import moment from "moment-timezone"

export const get24hoursTimeFormat = (date?: string | null) => {
  if (!date) return undefined
  const newDate = moment(date)

  const hours = newDate.hours().toString()
  const minutes = newDate.minutes().toString()

  const fixedHours = hours.length === 1 ? `0${hours}` : hours
  const fixedMinutes = minutes.length === 1 ? `0${minutes}` : minutes
  return `${fixedHours}:${fixedMinutes}`
}
