import type {
  GetMenuListQuery,
  GetMenuListQueryVariables,
} from "../../../../../GraphQL/Queries/getMenuList.generated"
import { GetMenuListDocument } from "../../../../../GraphQL/Queries/getMenuList.generated"
import type { ApolloCache } from "@apollo/client"

type ReadCacheGetMenuListQuery = {
  cache: ApolloCache<unknown>
  variables: GetMenuListQueryVariables
}

export const readGetMenuListQueryCache = ({
  cache,
  variables,
}: ReadCacheGetMenuListQuery) =>
  cache.readQuery<GetMenuListQuery, GetMenuListQueryVariables>({
    query: GetMenuListDocument,
    variables,
  })
