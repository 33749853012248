import type { GetMenuListQuery } from "../../../GraphQL/Queries/getMenuList.generated"
import { useGetMenuListQuery } from "../../../GraphQL/Queries/getMenuList.generated"
import emptyIcon from "../../../shared/assets/icons/empty_icon.svg"
import { useFilters } from "../../../shared/contexts/FilterProvider"
import { useRestaurantCompletionStepsContext } from "../../../shared/contexts/RestaurantCompletionStepsProvider"
import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import {
  MenuList,
  SortingByMenuEnum,
} from "../../../shared/graphql/generated/types"
import { MenuStatusEnum } from "../../../shared/graphql/generated/types"
import { useBreadcrumb } from "../../../shared/hooks/useBreadcrumb"
import paths from "../../../shared/routes/paths"
import firstLetterToUpperCase from "../../../shared/utils/helpers/firstLetterToUpperCase"
import { reorderArray } from "../../../shared/utils/helpers/reorderArray"
import { SortingMethodsEnum } from "../../../shared/utils/helpers/sortArrayByMethod"
import Container from "../../../ui/Container"
import EmptyState from "../../../ui/EmptyState"
import { showGraphqlErrors } from "../../../ui/ErrorList"
import Icon from "../../../ui/Icon"
import RetryPage from "../../../ui/RetryPage"
import Table from "../../../ui/Table"
import BodyCell from "../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../ui/Table/EmptyStateCell"
import type { ColumProps } from "../../../ui/Table/Table"
import Tooltip from "../../../ui/Tooltip"
import notification from "../../../ui/notification"
import { useSortMenuMutation } from "../Menu/GraphQL/sortMenu.generated"
import OwnershipTag from "../components/OwnershipTag"
import StatusBadge from "../components/StatusBadge"
import { StatusBadgeEnum } from "../components/StatusBadge/StatusBadge"
import AddMenusButton from "./AddMenusButton"
import type { HideOrShowMenuMutation } from "./GraphQL/hideOrShowMenu.generated"
import { useHideOrShowMenuMutation } from "./GraphQL/hideOrShowMenu.generated"
import DropdownMenus from "./components/DropdownMenus"
import { CustomMenuCategoryTypeTitle } from "./titles/menuType.title"
import { reorderMenuListFromCache } from "./utils/cache-menus/menus-cache-config"
import { corporateColumns, locationColumns } from "./utils/columns"
import get from "lodash/get"
import React, { useCallback, useMemo } from "react"
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

type MenuProps = {
  isCorporate?: boolean
}

const Menus: React.FC<MenuProps> = ({ isCorporate = false }) => {
  const intl = useIntl()
  const { pushBreadcrumb } = useBreadcrumb()
  const { locationUUID } = useParams<{ locationUUID: string }>()
  const { hasMenuSetupCompleted, updateRestaurantCompletionSteps } =
    useRestaurantCompletionStepsContext()

  const columns: Array<ColumProps> = useMemo(
    () =>
      isCorporate
        ? corporateColumns
        : locationColumns.map((column) => ({
            title:
              column.title &&
              intl.formatMessage({
                id: column?.id,
                defaultMessage: column.title,
              }),
            width: column?.width,
            textAlign: column?.textAlign,
          })),
    [intl, isCorporate]
  )

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const { sort, getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { data, loading, error, refetch, fetchMore } = useGetMenuListQuery({
    variables: {
      name: search,
      sort: SortingByMenuEnum[sort["sorting-by"]],
    },
    skip: !currentRestaurantUUID,
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    fetchPolicy: "network-only",
    onCompleted: ({ getMenuList }) => {
      const hasPublishedMenus = getMenuList?.results?.some(
        ({ status }) => status === MenuStatusEnum.PUBLISHED
      )

      if (hasMenuSetupCompleted !== hasPublishedMenus) {
        updateRestaurantCompletionSteps({
          hasMenuSetupCompleted: hasPublishedMenus,
        })
      }
    },
  })

  const [showOrHideMenuMutation] = useHideOrShowMenuMutation()
  const [sortingMenuMutation] = useSortMenuMutation()
  const allMenus: MenuList[] = get(data, "getMenuList.results", [])
  const hasNextPage = get(data, "getMenuList.hasNextPage", false)
  const endCursor = get(data, "getMenuList.endCursor", undefined)

  const fetchMoreMenus = useCallback(() => {
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prev: GetMenuListQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getMenuList?.endCursor ===
            fetchMoreResult?.getMenuList?.endCursor
        ) {
          return prev
        }

        return {
          getMenuList: {
            ...fetchMoreResult.getMenuList,
            results: [
              ...(prev.getMenuList.results as MenuList[]),
              ...(fetchMoreResult.getMenuList.results as MenuList[]),
            ],
          },
        }
      },
    })
  }, [endCursor, fetchMore])

  const retry = () => refetch()

  if (error) {
    return <RetryPage error={error} reload={retry} />
  }

  // Enable Disable Menu into the menu list
  const showOrHideMenu = async (
    menuUUID: string,
    isVisible: boolean,
    onSuccess?: (result: HideOrShowMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data: response } = await showOrHideMenuMutation({
        variables: { uuid: menuUUID },
      })

      if (response?.hideOrShowMenu.uuid) {
        notification({
          title: intl.formatMessage(
            {
              id: "restaurants.menus.use.menus.hide.or.show.menu.success.title",
              defaultMessage:
                "Category {isVisible,plural,=0 {Visible} other {Hidden}}",
            },
            { isVisible: Number(isVisible) }
          ),
          description: intl.formatMessage(
            {
              id: "restaurants.menus.use.menus.hide.or.show.menu.success.description",
              defaultMessage:
                "This corporate category is {isVisible,plural,=0 {now visible} other {no longer visible}} to customers in your location.",
            },
            { isVisible: Number(isVisible) }
          ),
          type: "success",
        })
        onSuccess?.(response)
      }
    } catch (updateError) {
      onError?.(updateError as Error)
    }
  }

  const onClickDropdownContainer = async (
    menuUUID: string,
    isVisible: boolean,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    await showOrHideMenu(menuUUID, isVisible, () => {}, showGraphqlErrors)
    await refetch()
  }

  const onDragFinish = async (result: DropResult) => {
    const dragItem = allMenus[result.source.index]

    return onDragEnd(result, dragItem)
  }

  const onDragEnd = async (result: DropResult, item: MenuList) => {
    const dragStartIndex = result.source.index
    const dropIndex = result?.destination?.index ?? 0

    if (dragStartIndex === dropIndex) return

    const reorderedMenus = reorderArray(allMenus, dragStartIndex, dropIndex)

    if (!reorderedMenus) return

    reorderMenuListFromCache({
      data: reorderedMenus,
      variables: { name: search, sort: SortingByMenuEnum[sort["sorting-by"]] },
    })

    try {
      await sortingMenuMutation({
        variables: {
          data: {
            index: dropIndex + 1,
            uuid: item.uuid,
          },
        },
      })
    } catch (sortError) {
      showGraphqlErrors(sortError)
    }
  }

  return (
    <Container
      background="Neutral0"
      role="menus-table"
      className="menus-table"
      position="sticky"
    >
      <DragDropContext onDragEnd={onDragFinish} key="drag-sorting">
        <Droppable droppableId="menus">
          {(droppableProvided) => (
            <Table
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              top="48.5px"
              columns={columns}
              loading={loading}
              error={error}
              errorRefetch={retry}
              fetchMore={fetchMoreMenus}
              hasNextPage={hasNextPage}
              leftSpace={44}
              isArrayEmpty={allMenus.length === 0}
              iconVisibility={
                sort["sorting-by"] === SortingMethodsEnum.CUSTOM ? 1 : 0
              }
              emptyState={
                <EmptyStateCell colSpan={columns.length}>
                  <EmptyState
                    title={intl.formatMessage(
                      {
                        id: "restaurants.menus.empty.title",
                        defaultMessage:
                          '{hasSearch, plural, =0 {You don’t have any categories yet} other {We couldn’t find any category matching "{search}"}}',
                      },
                      {
                        hasSearch: Number(!!search),
                        search,
                      }
                    )}
                    description={intl.formatMessage(
                      {
                        id: "restaurants.menus.empty.description",
                        defaultMessage:
                          "Let’s {hasSearch,plural, =0 {start by creating your first} other {create a new}} category",
                      },
                      {
                        hasSearch: Number(!!search),
                      }
                    )}
                    button={
                      <AddMenusButton isCorporate={isCorporate} createButton />
                    }
                    image={emptyIcon}
                    fullScreen
                  />
                </EmptyStateCell>
              }
            >
              {!!allMenus?.length &&
                allMenus.map((record, index) => {
                  const onRowClick = () => {
                    const path = isCorporate
                      ? paths.restaurants.editMenu(record.uuid)
                      : paths.restaurants.locations.editMenuByLocation(
                          locationUUID,
                          record.uuid
                        )

                    pushBreadcrumb(path, {
                      currentPageName: intl.formatMessage({
                        id: "restaurants.menus.list.screen.title",
                        defaultMessage: "Categories",
                      }),
                    })
                  }

                  return (
                    <Draggable
                      key={`item-${index}`}
                      index={index}
                      isDragDisabled={
                        isCorporate &&
                        sort["sorting-by"] === SortingMethodsEnum.CUSTOM
                          ? false
                          : true
                      }
                      draggableId={`item-${index}`}
                    >
                      {(draggableProvided) => (
                        <tr
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          className="styled-data-table-row"
                          key={record.uuid}
                          onClick={onRowClick}
                        >
                          {isCorporate && (
                            <BodyCell>
                              <StyledIcon className="ri-menu-line" />
                            </BodyCell>
                          )}
                          <BodyCell>{`${record.name || ""}`}</BodyCell>
                          <BodyCell>
                            {CustomMenuCategoryTypeTitle[record.menuType]
                              ? intl.formatMessage(
                                  CustomMenuCategoryTypeTitle[record.menuType]
                                )
                              : firstLetterToUpperCase(record.menuType)}
                          </BodyCell>
                          <BodyCell textAlign="right">{`${
                            record?.itemsCount || "-"
                          }`}</BodyCell>
                          <BodyCell textAlign="right">{`${
                            record?.combosCount || "-"
                          }`}</BodyCell>
                          <BodyCell>
                            <Container
                              display="flex"
                              gap="14px"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <StatusBadge
                                status={getStatus(
                                  record.status,
                                  record.hasSnapshot
                                )}
                              />
                            </Container>
                          </BodyCell>
                          <BodyCell>
                            {record.hasSnapshot &&
                            record.status === MenuStatusEnum.PUBLISHED ? (
                              <Tooltip
                                width={175}
                                title={intl.formatMessage({
                                  id: "restaurants.menus.datatable.body.tooltip",
                                  defaultMessage:
                                    "This category has unpublished changes",
                                })}
                                placement="bottom"
                                visible
                              >
                                <Icon
                                  remixiconClass="ri-error-warning-line"
                                  cursor="pointer"
                                  color="Warning4"
                                  size={20}
                                />
                              </Tooltip>
                            ) : (
                              <Container width="22px" />
                            )}
                          </BodyCell>
                          {!isCorporate && (
                            <>
                              <BodyCell>
                                <OwnershipTag isMain={record.isMain} />
                              </BodyCell>
                              <BodyCell>
                                {record.isMain && (
                                  <Container
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={(event) =>
                                      onClickDropdownContainer(
                                        record.uuid,
                                        record.isVisible ?? false,
                                        event
                                      )
                                    }
                                  >
                                    <Icon
                                      remixiconClass={
                                        record.isVisible
                                          ? "ri-eye-line"
                                          : "ri-eye-off-line"
                                      }
                                      size={24}
                                      color="Neutral5"
                                    />
                                  </Container>
                                )}
                              </BodyCell>
                            </>
                          )}
                          <BodyCell textAlign="center">
                            {(isCorporate || !record.isMain) && (
                              <DropdownMenus
                                menuUUID={record.uuid}
                                actions={
                                  record.status === MenuStatusEnum.PUBLISHED
                                    ? ["UNPUBLISH", "DELETE"]
                                    : ["PUBLISH", "DELETE_DRAFT"]
                                }
                                isCorporate={isCorporate}
                              />
                            )}
                          </BodyCell>
                        </tr>
                      )}
                    </Draggable>
                  )
                })}
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}

export default Menus

const StyledIcon = styled.i`
  font-size: 24px;
  color: ${({ theme }) => theme.colors["Primary5"]};
  position: absolute;
  top: 16px;
  left: 16px;
  opacity: 0;
`

const getStatus = (
  status: MenuStatusEnum,
  hasSnapshot: boolean
): StatusBadgeEnum => {
  if (status === MenuStatusEnum.DRAFT) {
    return hasSnapshot ? StatusBadgeEnum.DRAFT : StatusBadgeEnum.UNPUBLISHED
  }

  return StatusBadgeEnum.PUBLISHED
}
