import { ReactComponent as SoldOutIcon } from "../../../../../shared/assets/icons/custom-sold-out.svg"
import Container from "../../../../../ui/Container"
import Icon from "../../../../../ui/Icon"
import styled from "styled-components"

type CardVisibilityProps = {
  isVisible: boolean
  isSoldOut: boolean
}

const CardVisibility = ({ isVisible, isSoldOut }: CardVisibilityProps) => {
  return (
    <Container display="flex" flexDirection="row" gap="8px" alignItems="center">
      {!isVisible && (
        <Icon remixiconClass="ri-eye-off-line" size={24} color="Neutral5" />
      )}
      {isSoldOut && <StyledSoldOutIcon width="24" height="24" />}
    </Container>
  )
}

const StyledSoldOutIcon = styled(SoldOutIcon)`
  fill: ${({ theme }) => theme.colors["Neutral5"]};
`

export default CardVisibility
