import { OrderStatus } from "../../../components/OrderStatus"
import { PaymentStatus } from "../../../components/PaymentStatus"
import { useFilters } from "../../../shared/contexts/FilterProvider"
import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import type { OrderInformationModel } from "../../../shared/graphql/generated/types"
import { isoStringHoursFormat } from "../../../shared/utils/constant/dateFormats"
import { CustomOrderMethodTitle } from "../../../shared/utils/constant/orderMethod.title"
import { toFormat } from "../../../shared/utils/currency/toFormat.dinero"
import formatIsoStringDate from "../../../shared/utils/helpers/formatIsoStringDate"
import safeNumber from "../../../shared/utils/helpers/safeNumber"
import Container from "../../../ui/Container"
import Divider from "../../../ui/Divider"
import EmptyState from "../../../ui/EmptyState"
import { showGraphqlErrors } from "../../../ui/ErrorList/ErrorList"
import Header from "../../../ui/Header"
import Icon from "../../../ui/Icon"
import PageHeader from "../../../ui/PageHeader"
import Table from "../../../ui/Table"
import BodyCell from "../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../ui/Table/EmptyStateCell"
import type { ColumProps } from "../../../ui/Table/Table"
import { OrderMethodEnum } from "../Catering/types"
import type { GetOrdersByRestaurantQuery } from "./GraphQL/getOrdersByRestaurant.generated"
import { useGetOrdersByRestaurantQuery } from "./GraphQL/getOrdersByRestaurant.generated"
import OrderDetailModal from "./OrderDetail/OrderDetailModal"
import OrdersFilter from "./OrdersFilter"
import OrdersHeaderActions from "./OrdersHeaderActions"
import type { IOrdersParams } from "./hookforms.interfaces"
import { CustomOrderPaymentMethodTitle } from "./titles/orderPaymentMethod.title"
import { Tooltip } from "antd"
import moment from "moment-timezone"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled, { css } from "styled-components"

export const Orders: React.FC = () => {
  const intl = useIntl()
  const { locationUUID } = useParams<IOrdersParams>()
  const { order, getOrdersQueryFilters, setOrdersQueryFilters, updateOrder } =
    useFilters()

  const columns: Array<ColumProps> = [
    { title: "" },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.number",
        defaultMessage: "Order #",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.date",
        defaultMessage: "Date",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.customer",
        defaultMessage: "Customer Name",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.phone",
        defaultMessage: "Phone #",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.status",
        defaultMessage: "Status",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.payment.status",
        defaultMessage: "Payment status",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.locations",
        defaultMessage: "Location",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.payment",
        defaultMessage: "Payment Method",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.delivery",
        defaultMessage: "Delivery Method",
      }),
    },
    {
      title: intl.formatMessage({
        id: "restaurants.orders.datatable.header.order.total",
        defaultMessage: "Total",
      }),
      textAlign: "right",
    },
  ]

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const { data, error, fetchMore, loading, refetch } =
    useGetOrdersByRestaurantQuery({
      variables: {
        restaurantUUID: currentRestaurantUUID,
        ...getOrdersQueryFilters(),
        startDate: moment(getOrdersQueryFilters().startDate)
          .set("hour", safeNumber("00"))
          .set("minute", safeNumber("00"))
          .toISOString(true),
        endDate: moment(getOrdersQueryFilters().endDate)
          .set("hour", safeNumber("23"))
          .set("minute", safeNumber("59"))
          .toISOString(true),
        ...(!!locationUUID && {
          locationUUIDs: [locationUUID],
        }),
        orderMethods: [
          OrderMethodEnum.CURBSIDE,
          OrderMethodEnum.DELIVERY,
          OrderMethodEnum.DINE_IN,
          OrderMethodEnum.DRIVE_THRU,
          OrderMethodEnum.TAKE_OUT,
        ],
      },
      skip: !currentRestaurantUUID,
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: ({ getOrdersByRestaurant }) => {
        try {
          if (locationUUID) {
            setOrdersQueryFilters({
              ...getOrdersByRestaurant.filters,
              locations: [],
            })

            return
          }
          setOrdersQueryFilters({ ...getOrdersByRestaurant.filters })
        } catch (filterError) {
          showGraphqlErrors(filterError)
        }
      },
    })

  const ordersList = data?.getOrdersByRestaurant?.results ?? []
  const hasNextPage = data?.getOrdersByRestaurant?.hasNextPage ?? false
  const endCursor = data?.getOrdersByRestaurant?.endCursor ?? undefined

  const fetchMoreOrders = useCallback(async () => {
    await fetchMore({
      variables: { currentRestaurantUUID, after: endCursor },
      updateQuery: (prev: GetOrdersByRestaurantQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getOrdersByRestaurant?.endCursor ===
            fetchMoreResult?.getOrdersByRestaurant?.endCursor
        ) {
          return prev
        }

        return Object.assign({}, prev, {
          getOrdersByRestaurant: {
            ...fetchMoreResult.getOrdersByRestaurant,
            results: [
              ...(prev.getOrdersByRestaurant
                .results as Array<OrderInformationModel>),
              ...(fetchMoreResult.getOrdersByRestaurant
                .results as Array<OrderInformationModel>),
            ],
          },
        })
      },
    })
  }, [currentRestaurantUUID, endCursor, fetchMore])

  const retry = () => refetch()

  const hideModal = () => updateOrder({ "order-uuid": undefined })

  return (
    <Container role="orders-table" className="orders-table">
      <StyledHeader>
        <PageHeader>
          <Header
            title={intl.formatMessage({
              id: "restaurants.orders.title",
              defaultMessage: "Orders",
            })}
            actions={OrdersHeaderActions}
            actionsOverflow="visible"
          />
        </PageHeader>
      </StyledHeader>

      <TagsContainer display="flex" flexWrap="wrap">
        <OrdersFilter />
      </TagsContainer>
      <StyledDivider verticalMargin="0px" />

      <Table
        top="105px"
        columns={columns}
        loading={loading}
        error={error}
        errorRefetch={retry}
        fetchMore={fetchMoreOrders}
        hasNextPage={hasNextPage}
        leftSpace={28}
        rightSpace={60}
      >
        {!!ordersList?.length &&
          ordersList.map(
            ({
              uuid,
              orderNumber,
              isReported,
              createdAt,
              customer,
              status,
              location,
              paymentMethodType,
              orderMethod,
              total,
              paymentStatus,
            }) => {
              const onRowClick = () => {
                updateOrder({ "order-uuid": uuid })
              }

              return (
                <tr key={uuid} onClick={onRowClick}>
                  <BodyCell>
                    {isReported && (
                      <Tooltip
                        title={intl.formatMessage({
                          id: "restaurants.orders.datatable.report.alert",
                          defaultMessage: "This order has a report issued",
                        })}
                      >
                        <Icon remixiconClass="ri-alert-line" color="Danger4" />
                      </Tooltip>
                    )}
                  </BodyCell>
                  <BodyCell>#{orderNumber}</BodyCell>
                  <BodyCell>{`${formatIsoStringDate(createdAt)} - ${moment(
                    createdAt
                  ).format(isoStringHoursFormat)}`}</BodyCell>
                  <BodyCell>{`${customer?.user?.firstName || "-"} ${
                    customer?.user?.lastName || ""
                  }`}</BodyCell>
                  <BodyCell>{`${customer?.user?.phone || "-"}`}</BodyCell>
                  <BodyCell>
                    <OrderStatus status={status} />
                  </BodyCell>
                  <BodyCell>
                    <PaymentStatus status={paymentStatus} />
                  </BodyCell>
                  <BodyCell>{location.name}</BodyCell>
                  <BodyCell>
                    {paymentMethodType
                      ? CustomOrderPaymentMethodTitle[paymentMethodType]
                          .defaultMessage
                      : "-"}
                  </BodyCell>
                  <BodyCell textTransform="capitalize">
                    {intl.formatMessage({
                      id: CustomOrderMethodTitle[orderMethod].id,
                      defaultMessage:
                        CustomOrderMethodTitle[orderMethod].defaultMessage,
                    })}
                  </BodyCell>
                  <BodyCell textAlign="right">{toFormat(total)}</BodyCell>
                </tr>
              )
            }
          )}

        {!ordersList?.length && !loading && (
          <EmptyStateCell colSpan={columns.length}>
            <EmptyState
              title={intl.formatMessage({
                id: "restaurants.orders.empty.title",
                defaultMessage: "No orders found",
              })}
              description={intl.formatMessage({
                id: "restaurants.orders.empty.description",
                defaultMessage:
                  "You will see orders as soon as a customer places one",
              })}
              fullScreen
            />
          </EmptyStateCell>
        )}
      </Table>
      {!!order["order-uuid"] && (
        <OrderDetailModal
          onCancel={hideModal}
          orderUUID={order["order-uuid"]}
          locationUUID={locationUUID}
        />
      )}
    </Container>
  )
}

const HEADER_Z_INDEX = 3
const TABLE_HEADER_Z_INDEX = 2

const TAGS_CONTAINER_OFFSET_TOP = "48px"
const DIVIDER_OFFSET_TOP = "103px"

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: ${HEADER_Z_INDEX};
`

const TagsContainer = styled(Container)`
  ${({ theme: { colors } }) => css`
    padding: 16px;
    position: sticky;
    top: ${TAGS_CONTAINER_OFFSET_TOP};
    z-index: ${TABLE_HEADER_Z_INDEX};
    background-color: ${colors["Neutral1"]};
  `}
`

const StyledDivider = styled(Divider)`
  position: sticky;
  top: ${DIVIDER_OFFSET_TOP};
  z-index: ${TABLE_HEADER_Z_INDEX};
`
