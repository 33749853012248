import yup from "../../../../../../shared/yup"
import { ENTIRE_ORDER_NAME } from "../constants"

export const AppliesToSchema = yup
  .object()
  .shape({
    entireOrder: yup.bool(),
    allItems: yup.bool(),
    allCombos: yup.bool(),
    items: yup.array().of(
      yup.object().shape({
        uuid: yup.string(),
        name: yup.string(),
      })
    ),
    combos: yup.array().of(
      yup.object().shape({
        uuid: yup.string(),
        name: yup.string(),
      })
    ),
  })
  .test("AtLeastOneItemOrCombo", (value) => {
    const { entireOrder, items, combos, allCombos, allItems } = value
    if (entireOrder || allCombos || allItems) {
      return true
    }

    if (items?.length === 0 && combos?.length === 0) {
      return new yup.ValidationError(
        "restaurants.discounts.forms.rules.applies.to.select.items.or.combos.error.message",
        value,
        ENTIRE_ORDER_NAME
      )
    }

    return true
  })
