import Checkbox from "../../../../../../ui/Checkbox"
import Container from "../../../../../../ui/Container"
import Divider from "../../../../../../ui/Divider"
import Select, { OptionSelect } from "../../../../../../ui/Select"
import { SELECT_LIST_HEIGHT } from "../constants"
import { useCombosSelector } from "./hooks/useCombosSelector"
import { useIntl } from "react-intl"

export const CombosSelector = () => {
  const intl = useIntl()

  const {
    onAllCombosClick,
    onComboSelected,
    onScrollToFetchMore,
    handleSearchCombos,
    loading,
    combos,
    search,
    selectedCombos,
    applyToAllCombos,
    isEditMode,
  } = useCombosSelector()

  const selectTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.applies.to.combos.select.title",
    defaultMessage: "Combos",
  })

  const allOptionsLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.applies.to.all.combos.label",
    defaultMessage: "All Combos",
  })

  const allOptionsKey = "all-combos"

  return (
    <Select
      disabled={isEditMode}
      withCheckbox
      allowSearch
      searchValue={search}
      label={selectTitle}
      mode="multiple"
      optionFilterProp="label"
      loading={loading}
      listHeight={SELECT_LIST_HEIGHT}
      onSearch={handleSearchCombos}
      options={combos}
      closableTags={!isEditMode}
      value={
        applyToAllCombos
          ? [allOptionsKey]
          : selectedCombos?.map((combo) => combo.uuid)
      }
      onChangeWithCheckbox={onComboSelected}
      dropdownHeader={
        <OptionSelect
          className="option-select-checkbox no-background"
          key={allOptionsKey}
          value={allOptionsKey}
          label={allOptionsLabel}
        >
          <Container
            padding="4px 12px"
            onClick={onAllCombosClick}
            className="option-select-checkbox-selected"
          >
            <Checkbox
              classId="select-all-combos-checkbox"
              content={allOptionsLabel}
              checked={applyToAllCombos}
              indeterminate={!applyToAllCombos}
            />
          </Container>
          <Divider verticalMargin="4px" />
        </OptionSelect>
      }
      onPopupScroll={onScrollToFetchMore}
    />
  )
}
