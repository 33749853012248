import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListDiscountsQueryVariables = Types.Exact<{
  discountCodes?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  locations?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  orderTypes?: Types.InputMaybe<Array<Types.DiscountsOrderMethodEnum> | Types.DiscountsOrderMethodEnum>;
  status?: Types.InputMaybe<Array<Types.DiscountStatusesEnum> | Types.DiscountStatusesEnum>;
  code?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate: Types.Scalars['DateTime'];
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type GetListDiscountsQuery = { __typename?: 'Query', getListDiscounts: { __typename?: 'PaginatedListDiscounts', hasNextPage: boolean, endCursor?: string | null, results?: Array<{ __typename?: 'Discount', uuid: string, code: string, status: Types.DiscountStatusesEnum, entryMethod: Types.DiscountsEntryMethodsEnum, percentage?: number | null, fixedAmount?: number | null, subStatus?: Types.DiscountSubStatusEnum | null, rule?: { __typename?: 'Rule', timesUsed: number } | null }> | null } };


export const GetListDiscountsDocument = gql`
    query getListDiscounts($discountCodes: [String!], $locations: [String!], $orderTypes: [DiscountsOrderMethodEnum!], $status: [DiscountStatusesEnum!], $code: String, $after: String, $take: Int, $startDate: DateTime!, $endDate: DateTime) {
  getListDiscounts(
    discountCodes: $discountCodes
    locations: $locations
    orderTypes: $orderTypes
    status: $status
    code: $code
    after: $after
    take: $take
    startDate: $startDate
    endDate: $endDate
  ) {
    hasNextPage
    endCursor
    results {
      uuid
      code
      status
      entryMethod
      percentage
      fixedAmount
      rule {
        timesUsed
      }
      subStatus
    }
  }
}
    `;

/**
 * __useGetListDiscountsQuery__
 *
 * To run a query within a React component, call `useGetListDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListDiscountsQuery({
 *   variables: {
 *      discountCodes: // value for 'discountCodes'
 *      locations: // value for 'locations'
 *      orderTypes: // value for 'orderTypes'
 *      status: // value for 'status'
 *      code: // value for 'code'
 *      after: // value for 'after'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetListDiscountsQuery(baseOptions: Apollo.QueryHookOptions<GetListDiscountsQuery, GetListDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListDiscountsQuery, GetListDiscountsQueryVariables>(GetListDiscountsDocument, options);
      }
export function useGetListDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListDiscountsQuery, GetListDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListDiscountsQuery, GetListDiscountsQueryVariables>(GetListDiscountsDocument, options);
        }
export type GetListDiscountsQueryHookResult = ReturnType<typeof useGetListDiscountsQuery>;
export type GetListDiscountsLazyQueryHookResult = ReturnType<typeof useGetListDiscountsLazyQuery>;
export type GetListDiscountsQueryResult = Apollo.QueryResult<GetListDiscountsQuery, GetListDiscountsQueryVariables>;