import Container from "../../../../../../ui/Container"
import Icon from "../../../../../../ui/Icon"
import { LEFT_ICON_POSITION } from "../constants"

interface ErrorBadgeProps {
  hidden: boolean
}

export const ErrorBadge = (props: ErrorBadgeProps) => {
  const { hidden } = props

  return (
    <Container hidden={hidden} position="absolute" left={LEFT_ICON_POSITION}>
      <Icon remixiconClass="ri-error-warning-line" color="Danger5" size={24} />
    </Container>
  )
}
