export const itemsCorporateColumns = [
  {
    id: "restaurants.items.datatable.header.name",
    title: "Item Name",
    width: "80%",
  },
  {
    id: "restaurants.items.datatable.header.status",
    title: "Status",
    width: "15%",
  },
  {
    title: "",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
]

export const itemsLocationColumns = [
  {
    id: "restaurants.items.datatable.header.name",
    title: "Item Name",
    width: "60%",
  },
  {
    id: "restaurants.items.datatable.header.status",
    title: "Status",
    width: "15%",
  },
  {
    title: "",
    width: "44px",
  },
  {
    id: "restaurants.items.datatable.header.ownership",
    title: "Ownership",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
]
