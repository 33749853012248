import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"
import { CustomOrderStatusTitle } from "../../../../../shared/titles/orderStatus.title"
import safeNumber from "../../../../../shared/utils/helpers/safeNumber"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import { InputFieldSkeleton } from "../../../../../ui/Inputs"
import Select from "../../../../../ui/Select"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import type { IOrdersParams } from "../../hookforms.interfaces"
import {
  updateOrderDetailToCache,
  updateOrdersToCache,
} from "../../utils/cache-orders/orders-cache-config"
import { GetOrderSummaryDocument } from "../GraphQL/getOrderSummary.generated"
import { useUpdateOrderMutation } from "../GraphQL/updateOrder.generated"
import OrderActionContainer from "../OrderActionContainer"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import { FlowFilter } from "./config"
import keys from "lodash/keys"
import moment from "moment-timezone"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

type OrderStatusProps = {
  loading?: boolean
}

export const OrderStatus: React.FC<OrderStatusProps> = ({
  loading = false,
}) => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()
  const { locationUUID } = useParams<IOrdersParams>()
  const { getOrdersQueryFilters } = useFilters()

  const { watch, setValue, getValues } = useFormContext<OrderDetailModalForm>()

  const orderUUID = watch("orderDetail.uuid")
  const orderStatus = watch("orderDetail.status")

  const [updateOrderMutation, { loading: loadingUpdatOrder }] =
    useUpdateOrderMutation({
      onCompleted: (result) => {
        updateOrdersToCache({
          data: {
            uuid: result.updateOrder.uuid,
            status: result.updateOrder.status,
          },
          variables: {
            restaurantUUID: currentRestaurantUUID,
            ...getOrdersQueryFilters(),
            startDate: moment(getOrdersQueryFilters().startDate)
              .set("hour", safeNumber("00"))
              .set("minute", safeNumber("00"))
              .toISOString(true),
            endDate: moment(getOrdersQueryFilters().endDate)
              .set("hour", safeNumber("23"))
              .set("minute", safeNumber("59"))
              .toISOString(true),
            ...(!!locationUUID && {
              locationUUIDs: [locationUUID],
            }),
            ...(!!locationUUID && {
              locationUUIDs: [locationUUID],
            }),
          },
        })

        updateOrderDetailToCache({
          data: {
            uuid: result.updateOrder.uuid,
            status: result.updateOrder.status,
          },
          variables: {
            orderUUID: result.updateOrder.uuid,
            ...(!!locationUUID && {
              locationUUIDs: [locationUUID],
            }),
          },
        })
      },
    })

  const onStatusChange = async (status: OrderStatusEnum) => {
    try {
      await updateOrderMutation({
        variables: {
          data: {
            uuid: orderUUID,
            status: status,
            locationUUID,
          },
        },
        refetchQueries: [GetOrderSummaryDocument],
      })

      setValue("orderDetail", {
        ...getValues("orderDetail"),
        status,
      })

      notification({
        description: intl.formatMessage({
          id: "restaurants.orders.order.detail.order.status.updated.message.success",
          defaultMessage: "Your order status was updated",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const orderStatusOptions = (
    keys(CustomOrderStatusTitle) as Array<`${OrderStatusEnum}`>
  )
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce<
      {
        label: string
        value: string
      }[]
    >((accumulator, current) => {
      if (
        FlowFilter.find((field) => field.key === orderStatus)?.value.includes(
          current
        )
      ) {
        accumulator.push({
          label: intl.formatMessage(CustomOrderStatusTitle[current]),
          value: current,
        })
      }

      return accumulator
    }, [])

  return (
    <StyledOrderActionContainer role="order-status-section">
      <Text size="xl" weight="bold" color="Neutral6">
        {intl.formatMessage({
          id: "restaurants.orders.order.detail.order.status.title",
          defaultMessage: "Order Status",
        })}
      </Text>

      <Spacer size={16} />

      {loading ? (
        <InputFieldSkeleton size="small" width="100%" />
      ) : (
        <Select
          aria-label="select-order-status"
          className="select-whitout-margin"
          value={orderStatus}
          options={orderStatusOptions}
          onChange={onStatusChange}
          loading={loadingUpdatOrder}
        />
      )}
    </StyledOrderActionContainer>
  )
}

const StyledOrderActionContainer = styled(OrderActionContainer)`
  .ant-select-selection-item {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`
