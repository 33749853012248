import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BestSellerItemsQueryVariables = Types.Exact<{
  endDate: Types.Scalars['String'];
  locations: Array<Types.Scalars['String']> | Types.Scalars['String'];
  startDate: Types.Scalars['String'];
}>;


export type BestSellerItemsQuery = { __typename?: 'Query', bestSellerItems: { __typename?: 'ItemSalesSummary', currentPeriod: Array<{ __typename?: 'BestSellerItemsReport', count: number, name: string }>, previousPeriod: Array<{ __typename?: 'BestSellerItemsReport', count: number, name: string }> } };


export const BestSellerItemsDocument = gql`
    query bestSellerItems($endDate: String!, $locations: [String!]!, $startDate: String!) {
  bestSellerItems(endDate: $endDate, locations: $locations, startDate: $startDate) {
    currentPeriod {
      count
      name
    }
    previousPeriod {
      count
      name
    }
  }
}
    `;

/**
 * __useBestSellerItemsQuery__
 *
 * To run a query within a React component, call `useBestSellerItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestSellerItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestSellerItemsQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      locations: // value for 'locations'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useBestSellerItemsQuery(baseOptions: Apollo.QueryHookOptions<BestSellerItemsQuery, BestSellerItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BestSellerItemsQuery, BestSellerItemsQueryVariables>(BestSellerItemsDocument, options);
      }
export function useBestSellerItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BestSellerItemsQuery, BestSellerItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BestSellerItemsQuery, BestSellerItemsQueryVariables>(BestSellerItemsDocument, options);
        }
export type BestSellerItemsQueryHookResult = ReturnType<typeof useBestSellerItemsQuery>;
export type BestSellerItemsLazyQueryHookResult = ReturnType<typeof useBestSellerItemsLazyQuery>;
export type BestSellerItemsQueryResult = Apollo.QueryResult<BestSellerItemsQuery, BestSellerItemsQueryVariables>;