import type {
  AddLocationAction,
  AddRestaurantAction,
  OnboardingAction,
} from "../../pages/CreateZone/actions"
import { ActionTypes as OnboardingActionTypes } from "../../pages/CreateZone/actions"
import type {
  OnboardingLocationPayload,
  OnboardingRestaurantPayload,
} from "../../pages/CreateZone/actions.types"
import type {
  ClearMagicLinkAction,
  LoginAction,
  LogoutAction,
  MagicLinkAction,
} from "../../pages/Login/actions"
import { ActionTypes as LoginActionTypes } from "../../pages/Login/actions"
import { CateringModeEnum } from "../../pages/Restaurants/Catering/utils/time-options"
import type {
  AddAllRestaurantsAction,
  ClearRedirect,
  CreateRestaurantAction,
  SetCurrentState,
} from "../../pages/Restaurants/actions"
import { ActionTypes as RestaurantsActionTypes } from "../../pages/Restaurants/actions"
import { SidebarToggleAction, UIActionTypes } from "../../ui/actions"
import type { AdminRoleNameEnum } from "../graphql/generated/types"
import paths from "../routes/paths"
import type { IAdmin, IRestaurant } from "../types"
import storage from "../utils/storage"
import { getAuthToken } from "../utils/storage/auth"

export type ReducerActions =
  | LoginAction
  | MagicLinkAction
  | ClearMagicLinkAction
  | LogoutAction
  | OnboardingAction
  | AddRestaurantAction
  | AddLocationAction
  | CreateRestaurantAction
  | AddAllRestaurantsAction
  | SetCurrentState
  | ClearRedirect
  | SidebarToggleAction
export interface IAuth {
  isAuthenticated: boolean
  admin: IAdmin
}

export interface AppState {
  auth: IAuth
  onboarding?: {
    restaurant?: OnboardingRestaurantPayload
    location?: OnboardingLocationPayload
  }
  restaurants: IRestaurant[]
  currentRestaurant: {
    uuid: string
    name: string
    root: string
    settings: string
    urlIdentifier: string
    isCateringActive: boolean
    icon?: string
    refirectToRoot?: boolean
    refirectToSettings?: boolean
  }
  currentRestaurantUserRole?: { name: `${AdminRoleNameEnum}` }
  selectedLocation: {
    uuid: string
    root: string
    settings: string
    timezone?: string
    name: string
  }
  cateringMode: CateringModeEnum
  ui: { hideSideBar: boolean }
}

export const appStateKey = "app_state"

const defaultState: AppState = {
  auth: {
    isAuthenticated: false,
    admin: {
      uuid: "",
      isOnboardingCompleted: false,
      adminRoleRestaurants: [],
    },
  },
  restaurants: [],
  currentRestaurant: {
    settings: "",
    uuid: "",
    name: "",
    urlIdentifier: "",
    root: "",
    isCateringActive: false,
  },
  selectedLocation: {
    uuid: "",
    root: "",
    settings: "",
    name: "",
  },
  cateringMode: CateringModeEnum.TABLE,
  ui: {
    hideSideBar: false,
  },
}

export const loadInitialState = (): AppState => {
  const token = getAuthToken()
  const loadedAppState = storage.get<AppState>(appStateKey)

  if (!token || !loadedAppState?.auth.admin) {
    return defaultState
  }

  loadedAppState.auth.isAuthenticated = !!loadedAppState.auth.admin

  loadedAppState.ui = { hideSideBar: false }

  return loadedAppState
}

export const initialAppState: AppState = loadInitialState()

export const appReducer = (
  state: AppState = initialAppState,
  action: ReducerActions
): AppState => {
  switch (action.type) {
    case LoginActionTypes.Login: {
      const admin = action?.payload?.admin

      return {
        ...state,
        auth: {
          ...state.auth,
          isAuthenticated: !!admin,
          admin,
        },
      }
    }

    case LoginActionTypes.Logout: {
      return defaultState
    }

    case OnboardingActionTypes.UpdateOnboardingFlag: {
      const isOnboardingCompleted = !!action?.payload?.isOnboardingCompleted

      return {
        ...state,
        auth: {
          ...state.auth,
          admin: {
            ...state.auth.admin,
            isOnboardingCompleted,
          },
        },
      }
    }

    case OnboardingActionTypes.AddLocation: {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          location: {
            ...state.onboarding?.location,
            ...action.payload,
          },
        },
      }
    }

    case OnboardingActionTypes.AddRestaurant: {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          restaurant: {
            ...state.onboarding?.restaurant,
            ...action.payload,
          },
        },
      }
    }

    case RestaurantsActionTypes.CreateRestaurant: {
      const restaurant = action?.payload
      const access = state.auth.admin.adminRoleRestaurants?.find(
        (role) => role.restaurant.uuid === restaurant.uuid
      )

      const data = {
        restaurant: {
          uuid: restaurant.uuid,
          name: restaurant.name,
          root: paths.restaurants.orders,
          urlIdentifier: restaurant.urlIdentifier,
          isCateringActive: restaurant.isCateringActive,
          settings: paths.settings.restaurant.restaurantInfo,
        },
        role: {
          name: access?.role.name as `${AdminRoleNameEnum}`,
        },
      }

      return {
        ...state,
        restaurants: [...state.restaurants, { ...action?.payload }],
        currentRestaurant: data.restaurant,
        currentRestaurantUserRole: data.role,
      }
    }

    case RestaurantsActionTypes.AddAllRestaurants: {
      return {
        ...state,
        restaurants: [...action?.payload],
      }
    }

    case RestaurantsActionTypes.SetCurrentState: {
      const access = state.auth.admin.adminRoleRestaurants?.find(
        (role) => role.restaurant.uuid === action.payload.restaurantUUID
      )

      const restaurant =
        state.restaurants?.find(
          (r) => r.uuid === action.payload.restaurantUUID
        ) || state.restaurants[0]
      const location = access?.locations.find(
        (l) => l.uuid === action.payload.locationUUID
      )

      const data = {
        restaurant: {
          uuid: restaurant.uuid,
          name: restaurant.name,
          root: paths.restaurants.orders,
          urlIdentifier: restaurant.urlIdentifier,
          isCateringActive:
            action.payload.isCateringActive ?? restaurant.isCateringActive,
          icon: restaurant.attachments?.find((a) => a.type === "LOGO")
            ?.signedUrl,
          settings: paths.settings.restaurant.restaurantInfo,
          refirectToRoot: action.payload.refirectToRoot,
          refirectToSettings: action.payload.refirectToSettings,
        },
        location: {
          uuid: location?.uuid ?? "",
          name: location?.name ?? "",
          root: location?.uuid
            ? paths.restaurants.locations.ordersByLocation(location.uuid)
            : "",
          settings: location?.uuid
            ? paths.settings.locations.locationInfo(location?.uuid)
            : "",
          timezone: location?.timezone,
        },
        role: {
          name: access?.role.name as `${AdminRoleNameEnum}`,
        },
      }

      return {
        ...state,
        currentRestaurant: data.restaurant,
        currentRestaurantUserRole: data.role,
        selectedLocation: data.location,
      }
    }

    case RestaurantsActionTypes.ClearRedirect: {
      return {
        ...state,
        currentRestaurant: {
          ...state.currentRestaurant,
          refirectToRoot: false,
          refirectToSettings: false,
        },
      }
    }

    case UIActionTypes.SidebarToggle: {
      return {
        ...state,
        ui: {
          ...state.ui,
          hideSideBar: action.payload.active,
        },
      }
    }

    default: {
      return state
    }
  }
}
