import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDiscountMutationVariables = Types.Exact<{
  input: Types.UpdateDiscountInput;
}>;


export type UpdateDiscountMutation = { __typename?: 'Mutation', updateDiscount: { __typename?: 'Discount', uuid: string, code: string, status: Types.DiscountStatusesEnum, entryMethod: Types.DiscountsEntryMethodsEnum, percentage?: number | null, fixedAmount?: number | null } };


export const UpdateDiscountDocument = gql`
    mutation updateDiscount($input: UpdateDiscountInput!) {
  updateDiscount(input: $input) {
    uuid
    code
    status
    entryMethod
    percentage
    fixedAmount
  }
}
    `;
export type UpdateDiscountMutationFn = Apollo.MutationFunction<UpdateDiscountMutation, UpdateDiscountMutationVariables>;

/**
 * __useUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscountMutation, { data, loading, error }] = useUpdateDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UpdateDiscountDocument, options);
      }
export type UpdateDiscountMutationHookResult = ReturnType<typeof useUpdateDiscountMutation>;
export type UpdateDiscountMutationResult = Apollo.MutationResult<UpdateDiscountMutation>;
export type UpdateDiscountMutationOptions = Apollo.BaseMutationOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>;