import { useGetSingleLocationQuery } from "../../../../../GraphQL/Queries/getSingleLocation.generated"
import { DEFAULT_GOOGLE_LOCATION_REDIRECT } from "../../../../../components/LocationMap/constants"
import { Map } from "../../../../../components/Map"
import { CateringServiceEnum } from "../../../../../shared/graphql/generated/types"
import {
  isoStringDateFormat,
  isoStringHoursFormat,
} from "../../../../../shared/utils/constant/dateFormats"
import { cateringOrderMethodMapping } from "../../../../../shared/utils/helpers/formatToOrderMethod"
import { LabelInformation } from "../../../../../ui/LabelInformation"
import { MantineCard } from "../../../../../ui/MantineCard"
import styles from "./OrderInformation.module.css"
import { Box, Grid } from "@mantine/core"
import { useJsApiLoader } from "@react-google-maps/api"
import { IconMapPinFilled } from "@tabler/icons-react"
import moment from "moment-timezone"
import { useIntl } from "react-intl"

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

interface OrderInformationProps {
  catering: {
    eventEndDate?: string
    deliveryDate?: string
    headCount?: number
    serviceType?: CateringServiceEnum
    eventName?: string
    placedAt: string
  }
  locationUUID?: string
}

export const OrderInformation = ({
  catering,
  locationUUID,
}: OrderInformationProps) => {
  const intl = useIntl()

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey ?? "",
  })

  const { data } = useGetSingleLocationQuery({
    variables: {
      locationUUID: locationUUID ?? "",
    },
  })

  const deliveryServices = new Set([
    CateringServiceEnum.DELIVERY,
    CateringServiceEnum.FOOD_TRUCK_EXPERIENCE,
  ])

  const showMap = catering.serviceType
    ? deliveryServices.has(catering.serviceType)
    : false

  const locationDetail = data?.getSingleLocation

  const handleMapClick = () => {
    if (!locationDetail?.latitude || !locationDetail?.longitude) {
      return
    }
    const url = `${DEFAULT_GOOGLE_LOCATION_REDIRECT}${Number(
      locationDetail.latitude
    )},${Number(locationDetail.longitude)}`
    window.open(url, "_blank")
  }

  const cateringDeliveryDate = catering.deliveryDate
    ? moment(catering.deliveryDate).format(
        `${isoStringDateFormat} ${isoStringHoursFormat}`
      )
    : "-"

  const eventDateDescription = catering.eventEndDate
    ? intl.formatMessage(
        {
          id: "restaurants.catering.detail.card.date.description.message",
          defaultMessage: "{start} To {end}",
        },
        {
          start: cateringDeliveryDate,
          end: moment(catering.eventEndDate).format(isoStringHoursFormat),
        }
      )
    : cateringDeliveryDate

  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.order.information.title",
        defaultMessage: "Order information",
      })}
    >
      <Grid gutter={24}>
        <Grid.Col span={6}>
          <LabelInformation
            label={intl.formatMessage({
              id: "restaurants.catering.detail.card.order.information.placed.label",
              defaultMessage: "Order placed",
            })}
            description={moment(catering.placedAt).format(
              `${isoStringDateFormat} ${isoStringHoursFormat}`
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          {catering.eventEndDate && (
            <LabelInformation
              label={intl.formatMessage({
                id: "restaurants.catering.detail.card.order.information.event.date.label",
                defaultMessage: "Event date",
              })}
              description={eventDateDescription}
            />
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          {catering.serviceType && (
            <LabelInformation
              label={intl.formatMessage({
                id: "restaurants.catering.detail.card.order.information.order.method.label",
                defaultMessage: "Order method",
              })}
              description={
                cateringOrderMethodMapping[catering.serviceType].defaultMessage
              }
            />
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          {catering.headCount && (
            <LabelInformation
              label={intl.formatMessage({
                id: "restaurants.catering.detail.card.order.information.headcount.label",
                defaultMessage: "Headcount",
              })}
              description={catering.headCount}
            />
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          <LabelInformation
            label={intl.formatMessage({
              id: "restaurants.catering.detail.card.order.information.event.address.label",
              defaultMessage: "Event address",
            })}
            description={showMap ? "-" : locationDetail?.address}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <LabelInformation
            label={intl.formatMessage({
              id: "restaurants.catering.detail.card.order.information.assign.location.label",
              defaultMessage: "Assign Location",
            })}
            description={locationDetail?.name}
          />
        </Grid.Col>
        {showMap && (
          <Grid.Col span={12}>
            <Box className={styles["map-container"]} onClick={handleMapClick}>
              {isLoaded && (
                <Map
                  zoom={14}
                  center={{
                    lat: Number(locationDetail?.latitude),
                    lng: Number(locationDetail?.longitude),
                  }}
                />
              )}
              <Box className={styles["map-icon"]}>
                <IconMapPinFilled />
              </Box>
            </Box>
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <LabelInformation
            label={intl.formatMessage({
              id: "restaurants.catering.detail.card.order.information.event.name.label",
              defaultMessage: "Event name",
            })}
            description={catering.eventName ?? "-"}
          />
        </Grid.Col>
      </Grid>
    </MantineCard>
  )
}
