import { IconCalendar, IconList } from "@tabler/icons-react"

export enum CateringModeEnum {
  TABLE = "TABLE",
  CALENDAR = "CALENDAR",
}

export const segmentedOptionsTime = [
  {
    value: CateringModeEnum.TABLE,
    label: <IconList size={12} />,
  },
  {
    value: CateringModeEnum.CALENDAR,
    label: <IconCalendar size={12} />,
  },
]
