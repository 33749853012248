import { useRef, useState } from "react"

export const useCollapseOpen = () => {
  const [open, setOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [collapseKey, setCollapseKey] = useState<string | undefined>(undefined)
  const timesClicked = useRef(0)
  const oldValidState = useRef(true)

  const handleCollapseChange = (collapseIds: string[] | string) => {
    if (collapseIds.length > 0) {
      setOpen(true)
      return
    }

    setOpen(false)
  }

  const handleValidationCollapse = (
    collapseIds: string[] | string,
    isValid?: boolean
  ) => {
    if (oldValidState.current === isValid) {
      timesClicked.current = 0
      setShowError(false)
    }

    if (!isValid && collapseKey && timesClicked.current === 0) {
      timesClicked.current += 1
      setShowError(true)
      return
    }

    setCollapseKey(collapseIds[0])
  }

  return {
    open,
    handleCollapseChange,
    handleValidationCollapse,
    collapseKey,
    showError,
  }
}
