import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOneDiscountQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetOneDiscountQuery = { __typename?: 'Query', getOneDiscount: { __typename?: 'Discount', uuid: string, code: string, status: Types.DiscountStatusesEnum, entryMethod: Types.DiscountsEntryMethodsEnum, percentage?: number | null, fixedAmount?: number | null, rule?: { __typename?: 'Rule', stackingAvailable?: boolean | null, uuid: string, startDate?: any | null, endDate?: any | null, allItems: boolean, allCombos: boolean, orderTypes?: Array<Types.DiscountsOrderMethodEnum> | null, limitPerCustomer?: number | null, generatedQuantity?: number | null, lifetimeSpend?: number | null, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }>, items: Array<{ __typename?: 'ItemBasicModel', uuid: string, name: string }>, combos: Array<{ __typename?: 'ComboBasicModel', name: string, uuid: string }>, users: Array<{ __typename?: 'UserBasicModel', uuid: string, phone?: string | null, lastName?: string | null, firstName?: string | null }> } | null } };


export const GetOneDiscountDocument = gql`
    query getOneDiscount($uuid: ID!) {
  getOneDiscount(uuid: $uuid) {
    uuid
    code
    status
    entryMethod
    percentage
    fixedAmount
    rule {
      stackingAvailable
      uuid
      startDate
      endDate
      locations {
        uuid
        name
      }
      items {
        uuid
        name
      }
      combos {
        name
        uuid
      }
      allItems
      allCombos
      orderTypes
      limitPerCustomer
      generatedQuantity
      lifetimeSpend
      users {
        uuid
        phone
        lastName
        firstName
      }
    }
  }
}
    `;

/**
 * __useGetOneDiscountQuery__
 *
 * To run a query within a React component, call `useGetOneDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneDiscountQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetOneDiscountQuery(baseOptions: Apollo.QueryHookOptions<GetOneDiscountQuery, GetOneDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneDiscountQuery, GetOneDiscountQueryVariables>(GetOneDiscountDocument, options);
      }
export function useGetOneDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneDiscountQuery, GetOneDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneDiscountQuery, GetOneDiscountQueryVariables>(GetOneDiscountDocument, options);
        }
export type GetOneDiscountQueryHookResult = ReturnType<typeof useGetOneDiscountQuery>;
export type GetOneDiscountLazyQueryHookResult = ReturnType<typeof useGetOneDiscountLazyQuery>;
export type GetOneDiscountQueryResult = Apollo.QueryResult<GetOneDiscountQuery, GetOneDiscountQueryVariables>;