import {
  GetAllLocationsByUserQuery,
  useGetAllLocationsByUserQuery,
} from "../../../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../../../shared/contexts/StoreProvider"
import { updateGetAllLocationsByUser } from "../../../../../../../shared/graphql/updateQuery/updateGetAllLocationsByUser"
import {
  DEBOUNCE_MILLISECONDS,
  EMPTY_ARRAY,
  EMPTY_STRING,
  WHITE_SPACE,
} from "../../../../../../../shared/utils/constant/values"
import { canFetchMore } from "../../../../../../../shared/utils/helpers/canFetchMore"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { onPopupScroll } from "../../../../../../../ui/Select/helpers/onPopupScroll"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  LOCATIONS_FIELD_NAME,
  SET_VALUE_OPTIONS,
  TAKE_QUANTITY,
} from "../../constants"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

export const useLocationAvailability = () => {
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const [search, setSearch] = useState<string | undefined>()

  const { setValue, watch, getValues } = useFormContext<IDiscountForm>()

  const selectedLocations = watch(LOCATIONS_FIELD_NAME)

  const isEditMode = getValues(EDIT_MODE_NAME)

  const {
    data,
    loading,
    refetch: refetchLocations,
    fetchMore,
  } = useGetAllLocationsByUserQuery({
    variables: { userUUID, restaurantUUID, take: TAKE_QUANTITY },
    skip: isEditMode,
    onError: showGraphqlErrors,
    fetchPolicy: "cache-and-network",
  })

  const hasNextPage = data?.getAllLocationsByUser?.hasNextPage ?? false
  const endCursor = data?.getAllLocationsByUser?.endCursor
  const canFetchMoreLocations = canFetchMore(loading, hasNextPage, endCursor)

  const fetchMoreLocations = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          after: endCursor,
          take: TAKE_QUANTITY,
        },
        updateQuery: (prev: GetAllLocationsByUserQuery, { fetchMoreResult }) =>
          updateGetAllLocationsByUser(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor])

  const locations =
    data?.getAllLocationsByUser.results?.map((location) => ({
      value: location.uuid,
      label: location.name,
    })) ?? []

  selectedLocations?.forEach((currentLocation) => {
    if (
      !locations
        ?.map((location) => location.value)
        .includes(currentLocation.uuid)
    )
      locations?.push({
        value: currentLocation.uuid,
        label: currentLocation.name,
      })
  })

  const onLocationSelected = (locationOptions: string[]) => {
    const formattedLocations = locations
      ?.filter((location) => locationOptions.includes(location.value))
      .map((filteredLocation) => ({
        uuid: filteredLocation.value,
        name: filteredLocation.label,
      }))

    if (!formattedLocations) return

    setValue(LOCATIONS_FIELD_NAME, formattedLocations, SET_VALUE_OPTIONS)
  }

  const onAllLocationsClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()

    const isChecked = selectedLocations?.length === 0

    if (!isChecked) {
      setValue(LOCATIONS_FIELD_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
    }

    setSearch(EMPTY_STRING)
    searchLocations(EMPTY_STRING)
  }

  const searchLocations = async (locationName: string) => {
    try {
      await refetchLocations({
        restaurantUUID,
        name: locationName === EMPTY_STRING ? undefined : locationName,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onDebounceSearchLocations = React.useMemo(() => {
    return debounce(searchLocations, DEBOUNCE_MILLISECONDS)
  }, [])

  const handleSearchLocations = (locationName: string) => {
    if (locationName === WHITE_SPACE) return

    setSearch(locationName)

    if (!locationName) {
      onDebounceSearchLocations.cancel()
      searchLocations(locationName)
      return
    }
    onDebounceSearchLocations(locationName)
  }

  const onScrollToFetchMore = (
    scrollEvent: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    onPopupScroll(scrollEvent, canFetchMoreLocations, fetchMoreLocations)
  }

  useEffect(() => {
    return () => onDebounceSearchLocations.cancel()
  }, [])

  return {
    locations,
    loading,
    onLocationSelected,
    selectedLocations,
    onAllLocationsClick,
    handleSearchLocations,
    onScrollToFetchMore,
    search,
    isEditMode,
  }
}
