import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import { CateringLabels } from "../../shared/config"
import { CateringForm, FeeQuantityEnum } from "../../shared/hookforms.intefaces"
import { ListItem } from "../ListItem"
import { List } from "@mantine/core"
import { useFormContext, useWatch } from "react-hook-form"
import { IntlShape, useIntl } from "react-intl"

const formatFee = (
  intl: IntlShape,
  fee: { type?: FeeQuantityEnum; value?: number }
) => {
  const amount =
    fee.type === FeeQuantityEnum.PERCENTAGE
      ? safeNumber(fee.value) / 100
      : safeNumber(fee.value)

  return fee.value === 0
    ? intl.formatMessage({
        id: "terms.and.conditions.no.amount",
        defaultMessage: "None",
      })
    : intl.formatNumber(amount ?? 0, {
        style: fee.type === FeeQuantityEnum.AMOUNT ? "currency" : "percent",
        currency: fee.type === FeeQuantityEnum.AMOUNT ? "USD" : undefined,
      })
}

export const CateringFee = () => {
  const { control } = useFormContext<CateringForm>()
  const [cateringFee] = useWatch({
    control,
    name: ["rules.cateringFee"],
  })

  const intl = useIntl()

  return (
    <List>
      <ListItem label={CateringLabels.pickup} apply={cateringFee.pickup.apply}>
        {formatFee(intl, cateringFee.pickup)}
      </ListItem>
      <ListItem
        label={CateringLabels.delivery}
        apply={cateringFee.delivery.apply}
      >
        {formatFee(intl, cateringFee.delivery)}
      </ListItem>
      <ListItem
        label={CateringLabels.foodTruckExperience}
        apply={cateringFee.foodTruckExperience.apply}
      >
        {formatFee(intl, cateringFee.foodTruckExperience)}
      </ListItem>
      <ListItem
        label={CateringLabels.insideVenue}
        apply={cateringFee.insideVenue.apply}
      >
        {formatFee(intl, cateringFee.insideVenue)}
      </ListItem>
    </List>
  )
}
