import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTempItemAttachmentMutationVariables = Types.Exact<{
  input: Types.CreateAttachmentInput;
}>;


export type CreateTempItemAttachmentMutation = { __typename?: 'Mutation', createTempItemAttachment: { __typename?: 'AttachmentModel', uuid: string, signedUrl: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string } };


export const CreateTempItemAttachmentDocument = gql`
    mutation createTempItemAttachment($input: CreateAttachmentInput!) {
  createTempItemAttachment(input: $input) {
    uuid
    signedUrl
    contentType
    ext
    fileName
  }
}
    `;
export type CreateTempItemAttachmentMutationFn = Apollo.MutationFunction<CreateTempItemAttachmentMutation, CreateTempItemAttachmentMutationVariables>;

/**
 * __useCreateTempItemAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateTempItemAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTempItemAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTempItemAttachmentMutation, { data, loading, error }] = useCreateTempItemAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTempItemAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTempItemAttachmentMutation, CreateTempItemAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTempItemAttachmentMutation, CreateTempItemAttachmentMutationVariables>(CreateTempItemAttachmentDocument, options);
      }
export type CreateTempItemAttachmentMutationHookResult = ReturnType<typeof useCreateTempItemAttachmentMutation>;
export type CreateTempItemAttachmentMutationResult = Apollo.MutationResult<CreateTempItemAttachmentMutation>;
export type CreateTempItemAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateTempItemAttachmentMutation, CreateTempItemAttachmentMutationVariables>;