/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmployeeRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
} from "@apollo/client"

export const EmployeeRole = {
  [EmployeeRoleNameEnum.BARISTA]: "Barista",
  [EmployeeRoleNameEnum.BUSSER]: "Busser",
  [EmployeeRoleNameEnum.CASH]: "Chas",
  [EmployeeRoleNameEnum.DISWASHER]: "Diswasher",
  [EmployeeRoleNameEnum.KITCHEN_MANAGER]: "Kitchen Manager",
  [EmployeeRoleNameEnum.LINE_COOK]: "Line Cook",
  [EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE]: "Restaurant Employee",
  [EmployeeRoleNameEnum.RUNNER]: "Runner",
  [EmployeeRoleNameEnum.SERVER]: "Server",
} as const

export type CacheUpdateFunction<Data, Vars> = MutationUpdaterFunction<
  Data,
  Vars,
  DefaultContext,
  ApolloCache<any>
>
