import type { TextAlignType } from "./../../../../styles/global/types"

export const corporateColumns = [
  {
    id: "restaurants.discounts.datatable.header.discountCode",
    title: "Discount Code",
    width: "20%",
  },
  {
    id: "restaurants.discounts.datatable.header.status",
    title: "Status",
    width: "20%",
  },
  {
    id: "restaurants.discounts.datatable.header.entryMethod",
    title: "Entry Method",
    width: "20%",
  },
  {
    id: "restaurants.discounts.datatable.header.type",
    title: "Amount",
    width: "20%",
    textAlign: "right" as TextAlignType,
  },
  {
    id: "restaurants.discounts.datatable.header.timesUsed",
    title: "Times Used",
    width: "20%",
    textAlign: "right" as TextAlignType,
  },
  {
    title: "",
    width: "44px",
  },
]
