import { FieldTypeEnumItem } from "../interfaces/hookforms.interfaces"

type ModifierGroupOptions = {
  [key: string]: { id: string; defaultMessage: string; icon: string }
}

export const modifierGroupOptions: ModifierGroupOptions = {
  [FieldTypeEnumItem.RADIO]: {
    id: "restaurants.menu.items.forms.modifier.groups.select.option.radio",
    defaultMessage: "Single select",
    icon: "ri-radio-button-line",
  },
  [FieldTypeEnumItem.CHECKBOX]: {
    id: "restaurants.menu.items.forms.modifier.groups.select.option.checkbox",
    defaultMessage: "Multi-select",
    icon: "ri-checkbox-fill",
  },
  [FieldTypeEnumItem.COUNTER]: {
    id: "restaurants.menu.items.forms.modifier.groups.select.option.counter",
    defaultMessage: "Range",
    icon: "ri-arrow-up-down-fill",
  },
}
