import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCateringLocationQueryVariables = Types.Exact<{
  locationUUID: Types.Scalars['ID'];
}>;


export type GetCateringLocationQuery = { __typename?: 'Query', getSingleLocation: { __typename?: 'LocationWithSchedulesModel', isCateringActive: boolean, orderTypes: Array<Types.OrderMethodEnum> } };


export const GetCateringLocationDocument = gql`
    query getCateringLocation($locationUUID: ID!) {
  getSingleLocation(locationUUID: $locationUUID) {
    isCateringActive
    orderTypes
  }
}
    `;

/**
 * __useGetCateringLocationQuery__
 *
 * To run a query within a React component, call `useGetCateringLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCateringLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCateringLocationQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetCateringLocationQuery(baseOptions: Apollo.QueryHookOptions<GetCateringLocationQuery, GetCateringLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCateringLocationQuery, GetCateringLocationQueryVariables>(GetCateringLocationDocument, options);
      }
export function useGetCateringLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCateringLocationQuery, GetCateringLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCateringLocationQuery, GetCateringLocationQueryVariables>(GetCateringLocationDocument, options);
        }
export type GetCateringLocationQueryHookResult = ReturnType<typeof useGetCateringLocationQuery>;
export type GetCateringLocationLazyQueryHookResult = ReturnType<typeof useGetCateringLocationLazyQuery>;
export type GetCateringLocationQueryResult = Apollo.QueryResult<GetCateringLocationQuery, GetCateringLocationQueryVariables>;