import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDiscountByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type GetDiscountByCodeQuery = { __typename?: 'Query', getDiscountByCode?: { __typename?: 'BasicDiscount', uuid: string } | null };


export const GetDiscountByCodeDocument = gql`
    query GetDiscountByCode($code: String!) {
  getDiscountByCode(code: $code) {
    uuid
  }
}
    `;

/**
 * __useGetDiscountByCodeQuery__
 *
 * To run a query within a React component, call `useGetDiscountByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetDiscountByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetDiscountByCodeQuery, GetDiscountByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscountByCodeQuery, GetDiscountByCodeQueryVariables>(GetDiscountByCodeDocument, options);
      }
export function useGetDiscountByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscountByCodeQuery, GetDiscountByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscountByCodeQuery, GetDiscountByCodeQueryVariables>(GetDiscountByCodeDocument, options);
        }
export type GetDiscountByCodeQueryHookResult = ReturnType<typeof useGetDiscountByCodeQuery>;
export type GetDiscountByCodeLazyQueryHookResult = ReturnType<typeof useGetDiscountByCodeLazyQuery>;
export type GetDiscountByCodeQueryResult = Apollo.QueryResult<GetDiscountByCodeQuery, GetDiscountByCodeQueryVariables>;