export const TAKE_QUANTITY = 20
export const SET_VALUE_OPTIONS = {
  shouldDirty: true,
  shouldValidate: true,
}
export const SELECT_LIST_HEIGHT = 170
export const LEFT_ICON_POSITION = "-40px"
export const MAX_INPUT_LENGTH = 6

//Date and Time Rule
export const START_TIME_NAME = "rules.dateAndTime.startDate.hour"
export const ADD_END_TIME_NAME = "rules.dateAndTime.addEndTime"
export const START_DATE_NAME = "rules.dateAndTime.startDate"
export const END_DATE_NAME = "rules.dateAndTime.endDate"
export const END_TIME_FIELD_NAME = "rules.dateAndTime.endDate.hour.time"
export const END_DATE_FIELD_NAME = "rules.dateAndTime.endDate.date"

//Location Availability Rule
export const LOCATIONS_FIELD_NAME = "rules.locationAvailability.locations"

//Applies To Rule
export const ITEMS_FIELD_NAME = "rules.appliesTo.items"
export const ENTIRE_ORDER_NAME = "rules.appliesTo.entireOrder"
export const APPLY_ALL_ITEMS_NAME = "rules.appliesTo.allItems"
export const COMBOS_FIELD_NAME = "rules.appliesTo.combos"
export const APPLY_ALL_COMBOS_NAME = "rules.appliesTo.allCombos"

//Order Type Rule
export const ORDER_TYPES_NAME = "rules.orderType.orderTypes"

//Usage Limit Rule
export const LIMIT_PER_CUSTOMER_NAME = "rules.usageLimit.limitPerCustomer"
export const LIMIT_TO_NAME = "rules.usageLimit.limitedTo"

//Eligible Customer Rule
export const ELIGIBLE_CUSTOMER_USERS_FIELD_NAME = "rules.eligibleCustomer.users"
export const ELIGIBLE_CUSTOMER_TYPE_FIELD_NAME = "rules.eligibleCustomer.type"
export const ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME = "rules.eligibleCustomer.spend"
