import AddButton from "../../../../../../ui/AddButton"
import Container from "../../../../../../ui/Container"
import InputLabel from "../../../../../../ui/InputLabel"
import Text from "../../../../../../ui/Typography/Text"
import ComboItemGroupCard from "../../components/ComboItemGroupCard"
import type { IComboForm } from "../../interfaces/hookforms.interfaces"
import ComboPriceForm from "../ComboPriceForm/ComboPriceForm"
import React from "react"
import { useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type ComboCreationFormProps = {
  isCorporate?: boolean
}

const ComboCreationForm: React.FC<ComboCreationFormProps> = ({
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { control, getValues } = useFormContext<IComboForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "comboItemGroups",
  })
  const { append: appendToDeleted } = useFieldArray({
    control,
    name: "deletedComboItemGroups",
  })

  const [comboItemGroups, fullPrice, priceOverride] = useWatch({
    control,
    name: ["comboItemGroups", "fullPrice", "priceOverride"],
  })

  const disablePriceChange = !comboItemGroups.some(
    ({ comboItems }) => comboItems && comboItems?.length > 0
  )

  const addComboItemGroup = () => {
    append({
      name: "",
      uuid: "",
    })
  }

  const handleCardRemove = (index: number) => {
    const removedField = getValues(`comboItemGroups.${index}`)
    const { uuid, isDuplicate } = removedField

    if (!isDuplicate && uuid) {
      appendToDeleted({ ...removedField, deletedAt: new Date() })
    }

    remove(index)
  }

  return (
    <Container display="flex" flexDirection="column" gap="24px">
      <Container display="flex" flexDirection="column">
        <Container display="flex" justifyContent="space-between">
          <InputLabel
            label={intl.formatMessage({
              id: "restaurants.menu.items.forms.combo.form.combo.items.label",
              defaultMessage: "Combo Items",
            })}
          />
          <AddButton onClick={addComboItemGroup} />
        </Container>
        <Text size="s" weight="regular">
          {intl.formatMessage({
            id: "restaurants.menu.items.forms.combo.form.combo.item.description",
            defaultMessage:
              "Here you could see the combination of the options you created before.",
          })}
        </Text>
      </Container>

      {fields.map((comboItemGroup, index) => (
        <ComboItemGroupCard
          key={`${comboItemGroup.id}`}
          cmbItmGrpIndex={index}
          isCorporate={isCorporate}
          appendCmbItmGrp={append}
          removeCmbItmGrp={handleCardRemove}
        />
      ))}
      <ComboPriceForm
        disablePriceChange={disablePriceChange}
        fullPrice={fullPrice}
        priceOverride={priceOverride}
      />
    </Container>
  )
}

export default ComboCreationForm
