import * as Types from '../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTempRestaurantAttachmentMutationVariables = Types.Exact<{
  input: Types.CreateTempAttachmentInput;
  uuid: Types.Scalars['ID'];
}>;


export type CreateTempRestaurantAttachmentMutation = { __typename?: 'Mutation', createTempRestaurantAttachment: Array<{ __typename?: 'TempAttachmentModel', contentType: Types.AttachmentContentTypeEnum, ext: Types.AttachmentExtensionEnum, fileName: string, signedUrl: string, type: string, fileId?: string | null, uuid: string }> };


export const CreateTempRestaurantAttachmentDocument = gql`
    mutation createTempRestaurantAttachment($input: CreateTempAttachmentInput!, $uuid: ID!) {
  createTempRestaurantAttachment(input: $input, uuid: $uuid) {
    contentType
    ext
    fileName
    signedUrl
    type
    fileId
    uuid
  }
}
    `;
export type CreateTempRestaurantAttachmentMutationFn = Apollo.MutationFunction<CreateTempRestaurantAttachmentMutation, CreateTempRestaurantAttachmentMutationVariables>;

/**
 * __useCreateTempRestaurantAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateTempRestaurantAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTempRestaurantAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTempRestaurantAttachmentMutation, { data, loading, error }] = useCreateTempRestaurantAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCreateTempRestaurantAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTempRestaurantAttachmentMutation, CreateTempRestaurantAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTempRestaurantAttachmentMutation, CreateTempRestaurantAttachmentMutationVariables>(CreateTempRestaurantAttachmentDocument, options);
      }
export type CreateTempRestaurantAttachmentMutationHookResult = ReturnType<typeof useCreateTempRestaurantAttachmentMutation>;
export type CreateTempRestaurantAttachmentMutationResult = Apollo.MutationResult<CreateTempRestaurantAttachmentMutation>;
export type CreateTempRestaurantAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateTempRestaurantAttachmentMutation, CreateTempRestaurantAttachmentMutationVariables>;