import paths from "../../../../../shared/routes/paths"
import Container from "../../../../../ui/Container"
import CopyToClipboard from "../../../../../ui/CopyToClipboard"
import { InputFieldSkeleton } from "../../../../../ui/Inputs"
import Text from "../../../../../ui/Typography/Text"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface IOrderDetailHeader {
  loading: boolean
}

export const OrderDetailHeader: React.FC<IOrderDetailHeader> = ({
  loading,
}) => {
  const intl = useIntl()

  const { watch } = useFormContext<OrderDetailModalForm>()

  const orderUUID = watch("orderDetail.uuid")
  const orderDetail = watch("orderDetail")
  const orderNumber = orderDetail?.orderNumber

  const orderDetailLink = `${
    window.location.origin
  }${paths.restaurants.orderDetail(orderUUID ?? "")}`

  return (
    <StyledOrderDetailHeader
      role="order-detail-header"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="left"
    >
      {loading ? (
        <InputFieldSkeleton width="150px" size="small" />
      ) : (
        <Container display="flex" gap="8px">
          <Text
            aria-label="order-detail-header-title"
            size="m"
            weight="bold"
            color="Neutral6"
          >
            {intl.formatMessage(
              {
                id: "restaurants.orders.order.detail.order.number.title",
                defaultMessage: "Order detail {orderNumber}",
              },
              {
                orderNumber: orderNumber ? `#${orderNumber}` : "",
              }
            )}
          </Text>
          {orderUUID && (
            <CopyToClipboard
              content={orderDetailLink}
              copyTextLabel={intl.formatMessage({
                id: "generic.copy.link.to.clipboard",
                defaultMessage: "Copy URL to Clipboard",
              })}
              copiedTextLabel={intl.formatMessage({
                id: "generic.link.copied.to.clipboard",
                defaultMessage: "URL Copied",
              })}
            />
          )}
        </Container>
      )}
    </StyledOrderDetailHeader>
  )
}

const StyledOrderDetailHeader = styled(Container)`
  position: sticky;
  top: 16px;
  z-index: 10;
`
