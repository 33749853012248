import type { SizeType } from "../../../../styles/global/types"
import Tag from "../../../../ui/Tag"
import React from "react"
import { useIntl } from "react-intl"

type OwnershipTag = {
  isMain: boolean
  size?: SizeType
}

export const OwnershipTag: React.FC<OwnershipTag> = ({ isMain, size }) => {
  const intl = useIntl()

  return isMain ? (
    <Tag type="warning" size={size} margin={false}>
      {intl.formatMessage({
        id: "restaurants.locations.menus.tag.corporate.label",
        defaultMessage: "Corporate",
      })}
    </Tag>
  ) : (
    <Tag type="secondary" size={size} margin={false}>
      {intl.formatMessage({
        id: "restaurants.locations.menus.tag.location.label",
        defaultMessage: "Location",
      })}
    </Tag>
  )
}
