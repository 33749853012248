import { useGetCateringOrderDetailsQuery } from "../Graphql/getCateringOrderDetail.generated"
import { CateringParams } from "../types"
import { getCateringOrderInfo } from "../utils/get-catering-info"
import { AlertRefund } from "./AlertRefund"
import styles from "./CateringDetail.module.css"
import { CateringDetailSkeleton } from "./CateringDetailSkeleton"
import { ContactInformation } from "./ContactInformation"
import { OrderInformation } from "./OrderInformation"
import { Receipt } from "./Receipt"
import { Summary } from "./Summary"
import {
  Box,
  Card,
  Grid,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { IconX } from "@tabler/icons-react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

export const CateringDetail = () => {
  const intl = useIntl()
  const mantineTheme = useMantineTheme()

  const { goBack } = useHistory()

  const { orderUUID, locationUUID } = useParams<CateringParams>()

  const { data, loading } = useGetCateringOrderDetailsQuery({
    variables: {
      orderUUID,
      ...(!!locationUUID && { locationUUID }),
    },
    fetchPolicy: "cache-and-network",
  })

  const orderDetail = getCateringOrderInfo(data?.getOrderDetails)

  if (loading) {
    return (
      <Stack p={24}>
        <CateringDetailSkeleton />
      </Stack>
    )
  }

  if (!orderDetail) {
    return null
  }

  return (
    <Stack p={24}>
      <Card shadow="sm" withBorder bg="gray.1">
        <Card.Section
          px={24}
          py={16}
          bg="white"
          className={styles["card-section"]}
        >
          <Group position="apart">
            <Text fw={600}>
              {intl.formatMessage(
                {
                  id: "restaurants.catering.detail.order.number",
                  defaultMessage: "Order detail #{orderNumber}",
                },
                {
                  orderNumber: orderDetail.orderNumber,
                }
              )}
            </Text>
            <IconX
              size={32}
              color={mantineTheme.colors.gray[6]}
              onClick={goBack}
            />
          </Group>
        </Card.Section>
        {orderDetail?.refundInformation && (
          <Card.Section>
            <AlertRefund
              amount={orderDetail?.refundInformation.amount}
              coment={orderDetail?.refundInformation.comment ?? ""}
              storeCash={orderDetail?.refundInformation.storeCash}
            />
          </Card.Section>
        )}

        <Box pt={16}>
          <Grid gutter={24}>
            <Grid.Col lg={8}>
              <Stack spacing={24}>
                <OrderInformation
                  catering={orderDetail.catering}
                  locationUUID={orderDetail.location.uuid}
                />
                <ContactInformation
                  customer={orderDetail.customer}
                  locationUUID={orderDetail.location.uuid}
                />
                <Receipt
                  status={orderDetail.status}
                  uuid={orderDetail.uuid}
                  items={orderDetail.orderDetails}
                  deposit={orderDetail.deposits}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col lg={4}>
              <Summary />
            </Grid.Col>
          </Grid>
        </Box>
      </Card>
    </Stack>
  )
}
