import { CustomOrderPaymentMethodTitle } from "../../pages/Restaurants/Orders/titles/orderPaymentMethod.title"
import type { OrderFilterModel } from "../graphql/generated/types"
import {
  OrderStatusEnum,
  PaymentMethodTypeEnum,
} from "../graphql/generated/types"
import { CustomOrderStatusTitle } from "../titles/orderStatus.title"
import { YYYY_MM_DD } from "../utils/constant/dateFormats"
import { CustomOrderMethodTitle } from "../utils/constant/orderMethod.title"
import { SortingMethodsEnum } from "../utils/helpers/sortArrayByMethod"
import { DateRangeEnum } from "../v2/utils/dateRange.enum"
import moment from "moment-timezone"
import queryString from "query-string"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

enum OrderMethodEnum {
  CURBSIDE = "CURBSIDE",
  DELIVERY = "DELIVERY",
  DINE_IN = "DINE_IN",
  DRIVE_THRU = "DRIVE_THRU",
  TAKE_OUT = "TAKE_OUT",
}

interface DeliveryMethodParam {
  key: OrderMethodEnum
  name: string
}
interface IFilterParam {
  key: string
  name: string
}

interface IFilterDateState {
  "start-date"?: string
  "end-date"?: string
}

interface IFilterOrderState {
  "order-uuid"?: string
}

interface IFilterSearch {
  search?: string
}

export interface ISortingState {
  uuid?: string
  "sorting-by": SortingMethodsEnum
}

export interface IFilterState {
  location?: Array<IFilterParam>
  customer?: Array<IFilterParam>
  "order-status"?: Array<IFilterParam>
  "delivery-method"?: Array<DeliveryMethodParam>
  "report-group-by"?: Array<IFilterParam>
  "payment-method"?: Array<IFilterParam>
}

export type Filters = IFilterState & IFilterDateState & IFilterOrderState

export enum ReportGroupByEnum {
  MenuType = "MenuType",
  Menu = "Menu",
  Product = "Product",
}

export type QueryParamFilters = IFilterDateState &
  IFilterOrderState & {
    location?: Array<string> | string
    customer?: Array<string> | string
    "order-status"?: Array<`${OrderStatusEnum}`> | `${OrderStatusEnum}`
    "delivery-method"?: Array<`${OrderMethodEnum}`> | `${OrderMethodEnum}`
    "report-group-by"?: Array<`${ReportGroupByEnum}`>
    "payment-method"?:
      | Array<`${PaymentMethodTypeEnum}`>
      | `${PaymentMethodTypeEnum}`
  }

type OrdersQueryVariables = {
  startDate?: string
  endDate?: string
  customerUUIDs?: Array<string>
  locationUUIDs?: Array<string>
  orderMethods?: Array<OrderMethodEnum>
  status?: Array<OrderStatusEnum>
  orderUUID?: string
}

type SearchQueryVariables = {
  search?: string
}

type ReportsQueryVariables = {
  reportGroupBy?: ReportGroupByEnum
}

type DatesQueryVariables = {
  startDate?: string
  endDate?: string
}

interface IFilterContextFilters {
  filters: IFilterState
  updateFilters: (data: IFilterState) => void
  getReportsQueryFilters: () => ReportsQueryVariables
  clearFilters: () => void
}

interface IFilterContextDates {
  dates: IFilterDateState
  updateDateFilters: (data: IFilterDateState) => void
  getDatesQueryFilters: () => DatesQueryVariables
}

type RangeDatesType = {
  startDate: Date | null
  endDate: Date | null
}

interface FilterContextRangeDates {
  rangeDates: RangeDatesType
  rangeDateStatus: DateRangeEnum
  updateRangeDateFilters: (
    rangeDate: RangeDatesType,
    status?: DateRangeEnum
  ) => void
}

interface IFilterContextOrders {
  order: IFilterOrderState
  updateOrder: (data: IFilterOrderState) => void
  getOrdersQueryFilters: () => OrdersQueryVariables
  setOrdersQueryFilters: (filters: OrderFilterModel) => void
}

interface IFilterContextSearch {
  search: IFilterSearch
  updateSearch: (data: IFilterSearch) => void
  getSearchQueryFilter: () => string | undefined
}

interface IFilterContextSort {
  sort: ISortingState
  updateSortingBy: (data: ISortingState) => void
}

interface IFilterContext
  extends IFilterContextFilters,
    IFilterContextDates,
    IFilterContextOrders,
    IFilterContextSearch,
    FilterContextRangeDates,
    IFilterContextSort {}

const transformFilters = (
  param: Array<string> | string | undefined,
  filterKey: string,
  parseValue: (value: string) => string
) => {
  if (Array.isArray(param)) {
    return { [`${filterKey}`]: param.map((value) => parseValue(value)) }
  }

  return { [`${filterKey}`]: param ? parseValue(param) : undefined }
}

export const FilterContext = createContext<IFilterContext | null>(null)

export const FilterProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const intl = useIntl()
  const [filters, setFilters] = useState<IFilterState>({})
  const [dates, setDates] = useState<IFilterDateState>({})
  const [rangeDates, setRangeDates] = useState<RangeDatesType>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [rangeDateStatus, setRangeDateStatus] = useState<DateRangeEnum>(
    DateRangeEnum.TODAY
  )
  const [order, setOrder] = useState<IFilterOrderState>({})
  const [search, setSearch] = useState<IFilterSearch>({})
  const [sort, setSorting] = useState<ISortingState>({
    "sorting-by": SortingMethodsEnum.DATE_DESC,
  })

  const updateFilters = (data: IFilterState) => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as Filters

    const {
      "start-date": startDate,
      "end-date": endDate,
      "order-uuid": orderUUID,
    } = searchParams

    setFilters((prev) => {
      const newSearch = {
        ...prev,
        ...data,
      }

      const query = queryString.stringify(
        {
          "order-uuid": orderUUID,
          "start-date": startDate,
          "end-date": endDate,
          location: newSearch.location?.length
            ? newSearch.location?.map(({ key }) => key)
            : undefined,
          customer: newSearch.customer?.length
            ? newSearch.customer?.map(({ key }) => key)
            : undefined,
          ["order-status"]: newSearch["order-status"]?.length
            ? newSearch["order-status"]?.map(({ key }) => key)
            : undefined,
          ["delivery-method"]: newSearch["delivery-method"]?.length
            ? newSearch["delivery-method"]?.map(({ key }) => key)
            : undefined,
          ["report-group-by"]: newSearch["report-group-by"]?.length
            ? newSearch["report-group-by"]?.map(({ key }) => key)
            : undefined,
          ["payment-method"]: newSearch["payment-method"]?.length
            ? newSearch["payment-method"]?.map(({ key }) => key)
            : undefined,
        },
        { arrayFormat: "comma" }
      )

      window.history.replaceState(null, "", `?${query}`)

      return newSearch
    })
  }

  const updateOrder = (data: IFilterOrderState) => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as Filters

    setOrder(() => {
      const query = queryString.stringify(
        {
          ...searchParams,
          ...data,
        },
        { arrayFormat: "comma" }
      )

      window.history.replaceState(null, "", `?${query}`)

      return data
    })
  }

  const updateSearch = (data: IFilterSearch) => {
    setSearch(() => {
      const searchParams = queryString.parse(window.location.search, {
        arrayFormat: "comma",
      })

      const newQuery = {
        ...(data["search"]
          ? { search: data["search"] }
          : { search: undefined }),
      }

      const query = queryString.stringify({ ...searchParams, ...newQuery })

      window.history.replaceState(null, "", `?${query}`)

      return data.search?.length ? data : {}
    })
  }

  const updateDateFilters = (data: IFilterDateState) => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as Filters

    setDates(() => {
      const query = queryString.stringify(
        {
          ...searchParams,
          ...data,
        },
        { arrayFormat: "comma" }
      )

      window.history.replaceState(null, "", `?${query}`)

      return data
    })
  }

  const updateRangeDateFilters = (
    data: RangeDatesType,
    status?: DateRangeEnum
  ) => {
    setRangeDates(data)
    if (status) {
      setRangeDateStatus(status)
    }
  }

  const getOrdersQueryFilters = () => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as QueryParamFilters

    return {
      ...(searchParams["end-date"]
        ? { endDate: searchParams["end-date"] }
        : { endDate: moment().format(YYYY_MM_DD) }),
      ...(searchParams["start-date"]
        ? { startDate: searchParams["start-date"] }
        : { startDate: moment().format(YYYY_MM_DD) }),
      ...transformFilters(searchParams.customer, "userUUIDs", (value) => value),
      ...transformFilters(
        searchParams.location,
        "locationUUIDs",
        (value) => value
      ),
      ...transformFilters(
        searchParams["delivery-method"],
        "orderMethods",
        (value) => OrderMethodEnum[value as keyof typeof OrderMethodEnum]
      ),
      ...transformFilters(
        searchParams["order-status"],
        "status",
        (value) => OrderStatusEnum[value as keyof typeof OrderStatusEnum]
      ),
      ...transformFilters(
        searchParams["payment-method"],
        "paymentMethodTypes",
        (value) =>
          PaymentMethodTypeEnum[value as keyof typeof PaymentMethodTypeEnum]
      ),
    }
  }

  const setOrdersQueryFilters = (queryFilter: OrderFilterModel) => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as QueryParamFilters
    const { orderMethods, customers, locations, status, paymentMethodTypes } =
      queryFilter

    const newFilters = {
      location: locations.map(({ uuid, name }) => ({ key: uuid, name })),
      customer: customers.map(
        ({ user: { uuid, firstName, lastName, phone } }) => ({
          key: uuid,
          name: `${firstName || "-"} ${lastName || ""} (${phone}) `,
        })
      ),
      "order-status": status.map((key) => ({
        key,
        name: intl.formatMessage(CustomOrderStatusTitle[key]),
      })),
      "delivery-method": orderMethods.map((key) => ({
        key,
        name: intl.formatMessage(CustomOrderMethodTitle[key]),
      })),
      "payment-method": paymentMethodTypes.map((key) => ({
        key,
        name: intl.formatMessage(CustomOrderPaymentMethodTitle[key]),
      })),
    }

    updateFilters({
      ...newFilters,
      "delivery-method": newFilters["delivery-method"].map(({ key, name }) => {
        return {
          key: OrderMethodEnum[key as keyof typeof OrderMethodEnum],
          name,
        }
      }),
    })

    //TODO: Review this

    updateDateFilters({
      "start-date": searchParams["start-date"],
      "end-date": searchParams["end-date"],
    })

    updateOrder({
      "order-uuid": searchParams["order-uuid"],
    })
  }

  const getReportsQueryFilters = () => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as QueryParamFilters

    return {
      ...transformFilters(
        searchParams["report-group-by"],
        "reportGroupBy",
        (value) => ReportGroupByEnum[value as keyof typeof ReportGroupByEnum]
      ),
    }
  }

  const getDatesQueryFilters = () => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as QueryParamFilters

    return {
      ...(searchParams["end-date"]
        ? { endDate: searchParams["end-date"] }
        : { endDate: moment().format(YYYY_MM_DD) }),
      ...(searchParams["start-date"]
        ? { startDate: searchParams["start-date"] }
        : { startDate: moment().format(YYYY_MM_DD) }),
    }
  }

  const getSearchQueryFilter = () => {
    const searchParams = queryString.parse(
      window.location.search
    ) as SearchQueryVariables

    return searchParams["search"] ? searchParams["search"] : undefined
  }

  const updateSortingBy = (data: ISortingState) => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    }) as Filters

    setSorting(() => {
      const query = queryString.stringify(
        {
          ...searchParams,
          ...data,
        },
        { arrayFormat: "comma" }
      )

      window.history.replaceState(null, "", `?${query}`)

      return data
    })
  }

  const clearFilters = () => {
    history.push({ search: "" })
    setFilters({})
    setDates({})
  }

  useEffect(() => {
    return history.listen(() => {
      setFilters({})
      setDates({})
      setSearch({})
    })
  }, [history, setFilters, setDates])

  return (
    <FilterContext.Provider
      value={{
        filters,
        dates,
        rangeDates,
        rangeDateStatus,
        order,
        search,
        sort,
        updateFilters,
        updateOrder,
        updateSearch,
        updateDateFilters,
        updateRangeDateFilters,
        getDatesQueryFilters,
        getSearchQueryFilter,
        clearFilters,
        getOrdersQueryFilters,
        setOrdersQueryFilters,
        getReportsQueryFilters,
        updateSortingBy,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilters = () => {
  const context = useContext(FilterContext)

  if (context === null) {
    throw new Error("useFilterContext must be used within a FilterProvider")
  }

  return context
}
