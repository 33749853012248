export const HEADER_DATA = [
  {
    id: "restaurants.menu.items.forms.combo.form.combo.items.field.content.name",
    defaultMessage: "Name",
  },
  {
    id: "restaurants.menu.items.forms.combo.form.combo.items.field.content.price",
    defaultMessage: "Extra Price",
    justifySelf: "center",
  },
]

export const COMBO_FILTER = "?filter=combos"
