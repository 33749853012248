export const CorporateItemError = {
  SOLDOUT: {
    id: "restaurants.menu.itemscorporate.item.notification.variant.stock.error.message",
    defaultMessage: "Keep default variant in stock",
  },
  VISIBILITY: {
    id: "restaurants.menu.itemscorporate.item.notification.variant.visibility.error.message",
    defaultMessage: "Keep default variant visible",
  },
  PRICE: {
    id: "restaurants.menu.itemscorporate.item.notification.variant.price.error.message",
    defaultMessage: "Complete variants price",
  },
  MODIFIER: {
    id: "restaurants.menu.itemscorporate.item.notification.variant.modifier.error.message",
    defaultMessage: "Complete modifiers price",
  },
}

export const ItemFormError = {
  NAME: {
    id: "restaurants.menu.items.item.notification.name.error.message",
    defaultMessage: "Name it",
  },
  VARIANT: {
    id: "restaurants.menu.items.item.notification.variant.error.message",
    defaultMessage: "Complete its required fields",
  },
  INCLUDED_MODIFIERS: {
    id: "restaurants.menu.items.item.notification.included.modifiers.error.message",
    defaultMessage:
      "Make sure Max is equal to or greater than the number of included modifiers.",
  },
  MAX_MODIFIERS: {
    id: "restaurants.menu.items.item.notification.max.modifiers.error.message",
    defaultMessage: "Make sure Max is lower than the amount of modifiers.",
  },
}
