import { PaymentMethodTypeEnum } from "../../../../../shared/graphql/generated/types"
import { CustomOrderPaymentMethodTitle } from "../../titles/orderPaymentMethod.title"
import OrdersFilterContent from "../OrdersFilterContent"
import React, { useState } from "react"
import { useIntl } from "react-intl"

interface FilterByPaymentMethodProps {
  filterLabel: string
  clearFilterOptions: () => void
}

export const FilterByPaymentMethod: React.FC<FilterByPaymentMethodProps> = ({
  filterLabel,
  clearFilterOptions,
}) => {
  const intl = useIntl()

  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<
    Array<`${PaymentMethodTypeEnum}`>
  >(Object.keys(PaymentMethodTypeEnum) as Array<`${PaymentMethodTypeEnum}`>)
  const [paymentMethodName, setPaymentMethodName] = useState<
    string | undefined
  >()

  const searchPaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredPaymentMethodName = event.target.value

    if (filteredPaymentMethodName === "") {
      setFilteredPaymentMethods(
        Object.keys(PaymentMethodTypeEnum) as Array<`${PaymentMethodTypeEnum}`>
      )
    } else {
      const results = (
        Object.keys(PaymentMethodTypeEnum) as Array<`${PaymentMethodTypeEnum}`>
      ).filter((paymentMethod) => {
        return paymentMethod
          .toLocaleLowerCase()
          .includes(filteredPaymentMethodName.toLowerCase())
      })
      setFilteredPaymentMethods(results)
    }
    setPaymentMethodName(filteredPaymentMethodName)
  }

  return (
    <OrdersFilterContent
      filterLabel={filterLabel}
      searchTerm={paymentMethodName}
      onSearchTermChange={searchPaymentMethod}
      filterKey="payment-method"
      results={filteredPaymentMethods.map((paymentMethod) => ({
        value: paymentMethod,
        render: intl.formatMessage(
          CustomOrderPaymentMethodTitle[paymentMethod]
        ),
        name: intl.formatMessage(CustomOrderPaymentMethodTitle[paymentMethod]),
      }))}
      clearSearch={clearFilterOptions}
    />
  )
}
