import { useGeneralContext } from "../shared/contexts/StoreProvider"
import { setTags, setUser } from "@sentry/react"
import { useEffect } from "react"

export const EnrichSentryEvents = () => {
  const {
    state: {
      currentRestaurant,
      selectedLocation,
      currentRestaurantUserRole,
      auth: {
        admin: {
          isOnboardingCompleted,
          uuid: adminUUID,
          email,
          firstName,
          lastName,
        },
        isAuthenticated,
      },
    },
  } = useGeneralContext()

  useEffect(
    function ApplyTagsToAllContexts() {
      if (!isAuthenticated) return

      if (!isOnboardingCompleted) {
        setTags({
          onboardingCompleted: !!isOnboardingCompleted,
        })
        return
      }

      setTags({
        onboardingCompleted: !!isOnboardingCompleted,
        restaurant: currentRestaurant.uuid,
        userRole: currentRestaurantUserRole?.name,
        urlIdentifier: currentRestaurant.urlIdentifier,
        restaurantName: currentRestaurant.name,
        location: selectedLocation.uuid || "corporate",
      })
    },
    [
      isOnboardingCompleted,
      isAuthenticated,
      currentRestaurant.name,
      currentRestaurant.urlIdentifier,
      currentRestaurant.uuid,
      currentRestaurantUserRole?.name,
      selectedLocation.uuid,
    ]
  )

  useEffect(
    function setUserToAllContexts() {
      if (!isAuthenticated) {
        setUser({
          id: "not-logged-in",
        })
        return
      }

      setUser({
        id: adminUUID,
        email,
        name: getName(firstName, lastName),
      })
    },
    [adminUUID, email, firstName, isAuthenticated, lastName]
  )

  return null
}

const getName = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) {
    return "unnamed"
  }

  return `${firstName ?? ""} ${lastName ?? ""}`.trim()
}
