import ConfirmationModal from "../../../../ui/ConfirmationModal"
import React from "react"
import { useIntl } from "react-intl"

type ConfirmationModalMenuFormProps = {
  visible: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

const ConfirmationModalMenuForm: React.FC<ConfirmationModalMenuFormProps> = ({
  visible,

  onConfirm,
  onCancel,
}) => {
  const intl = useIntl()

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.title",
        defaultMessage: "You haven't uploaded your restaurant menu.",
      })}
      subtitle={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.confirm.button",
        defaultMessage: "Are you sure you want send just these details?",
      })}
      visible={visible}
      centered
      confirmButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.confirm.button.ok",
        defaultMessage: "Finish",
      })}
      cancelButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.draft.modifier.confirm.button.cancel",
        defaultMessage: "Stay",
      })}
      onCancel={onCancel}
      onConfirm={onConfirm}
      type="warning"
    />
  )
}

export default ConfirmationModalMenuForm
