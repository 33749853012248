import { useModal } from "../../../../../../shared/hooks/useModal"
import Container from "../../../../../../ui/Container"
import DropdownAction from "../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../ui/Icon"
import Text from "../../../../../../ui/Typography/Text"
import notification from "../../../../../../ui/notification"
import type { ItemActionsType } from "../../../interfaces/interfaces"
import { Menu } from "antd"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { Suspense, lazy } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

const DeleteItemModal = lazy(() => import("../modals/DeleteItemModal"))
const DeleteDraftItemModal = lazy(
  () => import("../modals/DeleteDraftItemModal")
)
const UnpublishItemModal = lazy(() => import("../modals/UnpublishItemModal"))
const PublishItemModal = lazy(() => import("../modals/PublishItemModal"))
const DuplicateItemModal = lazy(() => import("../modals/DuplicateItemModal"))

type DropdownItemProps = {
  itemUUID: string
  itemName?: string
  actions: Array<ItemActionsType>
  isCorporate?: boolean
  onOk?: () => void
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  actions,
  itemUUID,
  itemName,
  isCorporate,
  onOk,
}) => {
  const intl = useIntl()
  const { duplicateItems } = useFlags()

  const allowPublish = actions.includes("PUBLISH")
  const allowUnpublish = actions.includes("UNPUBLISH")
  const allowDuplicate = duplicateItems && actions.includes("DUPLICATE")
  const allowDelete = actions.includes("DELETE")
  const allowDeleteDraft = actions.includes("DELETE_DRAFT")
  const disableUnpublish = actions.includes("DISABLE_UNPUBLISH")

  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const [
    deleteModalVisible,
    showDeleteConfirmation,
    dismissDeleteConfirmation,
  ] = useModal()
  const [
    deleteDraftModalVisible,
    showDeleteDraftConfirmation,
    dismissDeleteDraftConfirmation,
  ] = useModal()
  const [
    unpublishModalVisible,
    showUnpublishConfirmation,
    dismissUnpublishConfirmation,
  ] = useModal()
  const [
    duplicateModalVisible,
    showDuplicateConfirmation,
    dismissDuplicateConfirmation,
  ] = useModal()

  const onPublishOk = () => {
    dismissPublishConfirmation()
    onOk?.()
  }

  const onDeleteOk = () => {
    dismissDeleteConfirmation()
    onOk?.()
  }

  const onDeleteDraftOk = () => {
    dismissDeleteDraftConfirmation()
    onOk?.()
  }

  const onUnpublishOk = () => {
    dismissUnpublishConfirmation()
    onOk?.()
  }

  const onDuplicateOk = () => {
    dismissDuplicateConfirmation()
    onOk?.()
  }

  const onDeleteDraftClick = () => {
    if (itemUUID) return showDeleteDraftConfirmation()

    notification({
      title: intl.formatMessage({
        id: "restaurants.menu.items.components.dropdown.item.delete.draft.success.title",
        defaultMessage: "Menu item deleted",
      }),
      description: intl.formatMessage({
        id: "restaurants.menu.items.components.dropdown.item.delete.draft.success.description",
        defaultMessage: "Your menu item was deleted",
      }),
      type: "success",
    })

    onOk?.()
  }

  const onClickDropdownContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  return (
    <StyleDropdown
      className="dropdown-actions"
      display="flex"
      justifyContent="flex-end"
      onClick={onClickDropdownContainer}
    >
      <DropdownAction
        align={{ offset: [0, 13] }}
        placement="bottomRight"
        disabled={!itemUUID}
        overlay={
          <Menu role="menu-more-options-item" selectedKeys={[]}>
            <Menu.Item
              key="dropdown-item-publish"
              icon={
                <Icon
                  remixiconClass="ri-arrow-up-circle-line"
                  color="Neutral8"
                />
              }
              hidden={!allowPublish}
              onClick={showPublishConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.components.dropdown.publish.item.option",
                  defaultMessage: "Publish",
                })}
              </Text>
            </Menu.Item>

            <Menu.Item
              key="dropdown-item-unpublish"
              icon={
                <Icon
                  remixiconClass="ri-arrow-down-circle-line"
                  color="Neutral8"
                />
              }
              hidden={!allowUnpublish}
              disabled={disableUnpublish}
              onClick={showUnpublishConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.components.dropdown.unpublish.item.option",
                  defaultMessage: "Unpublish",
                })}
              </Text>
            </Menu.Item>
            <Menu.Item
              key="dropdown-item-duplicate"
              icon={
                <Icon remixiconClass="ri-file-copy-line" color="Neutral8" />
              }
              hidden={!allowDuplicate}
              onClick={showDuplicateConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.components.dropdown.duplicate.item.option",
                  defaultMessage: "Duplicate",
                })}
              </Text>
            </Menu.Item>
            <Menu.Item
              key="dropdown-item-delete"
              icon={
                <Icon remixiconClass="ri-delete-bin-7-line" color="Neutral8" />
              }
              hidden={!allowDelete}
              onClick={showDeleteConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.components.dropdown.delete.item.option",
                  defaultMessage: "Delete",
                })}
              </Text>
            </Menu.Item>
            <Menu.Item
              onClick={onDeleteDraftClick}
              key="dropdown-item-delete-draft"
              icon={
                <Icon remixiconClass="ri-delete-bin-7-line" color="Neutral8" />
              }
              hidden={!allowDeleteDraft}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.components.dropdown.delete.item.option",
                  defaultMessage: "Delete",
                })}
              </Text>
            </Menu.Item>
          </Menu>
        }
      >
        <Icon
          remixiconClass="ri-more-line"
          size={24}
          color={itemUUID ? "Primary5" : "Neutral5"}
          cursor="pointer"
        />
      </DropdownAction>
      <Suspense fallback={null}>
        {publishModalVisible && (
          <PublishItemModal
            itemUUID={itemUUID}
            onOk={onPublishOk}
            onCancel={dismissPublishConfirmation}
          />
        )}
        {deleteModalVisible && (
          <DeleteItemModal
            itemUUID={itemUUID}
            onCancel={dismissDeleteConfirmation}
            onOk={onDeleteOk}
            isCorporate={isCorporate}
          />
        )}
        {deleteDraftModalVisible && (
          <DeleteDraftItemModal
            itemUUID={itemUUID}
            onCancel={dismissDeleteDraftConfirmation}
            onOk={onDeleteDraftOk}
          />
        )}
        {unpublishModalVisible && (
          <UnpublishItemModal
            itemUUID={itemUUID}
            onOk={onUnpublishOk}
            onCancel={dismissUnpublishConfirmation}
            isCorporate={isCorporate}
          />
        )}
        {duplicateModalVisible && itemName && (
          <DuplicateItemModal
            itemName={itemName}
            itemUUID={itemUUID}
            onOk={onDuplicateOk}
            onCancel={dismissDuplicateConfirmation}
          />
        )}
      </Suspense>
    </StyleDropdown>
  )
}

export default DropdownItem

const StyleDropdown = styled(Container)`
  .anticon[disabled] {
    cursor: not-allowed;
  }
`
