import { useSearchParams } from "../../../shared/hooks/useSearchParams"
import { CateringCalendar } from "./CateringCalendar"
import { CateringHeader } from "./CateringHeader"
import { CateringTable } from "./CateringTable"
import { viewModeType } from "./utils/view-mode"
import { Stack } from "@mantine/core"

const viewModeParam = "view-mode"

export const Catering = () => {
  const { getParam } = useSearchParams()

  const viewMode = getParam(viewModeParam) ?? ""

  return (
    <Stack spacing={24} pos="relative">
      <CateringHeader />
      {viewModeType(viewMode) === "CALENDAR" ? (
        <CateringCalendar />
      ) : (
        <CateringTable />
      )}
    </Stack>
  )
}
