import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import CustomPrompt from "./shared/v2/ui/CustomPrompt"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

const getUserConfirmation = (
  message: string,
  callback: (arg0: boolean) => void
) => {
  const container = document.createElement("div")
  container.setAttribute("custom-confirmation-navigation", "")
  document.body.append(container)

  const cleanUp = (answer: boolean) => {
    if (container) {
      ReactDOM.unmountComponentAtNode(container)
      container.remove()
      callback(answer)
    }
  }
  ReactDOM.render(
    <CustomPrompt message={message} answerCallback={cleanUp} />,
    container
  )
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.querySelector("#root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
