import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteModifierModalGetModifierQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type DeleteModifierModalGetModifierQuery = { __typename?: 'Query', getModifierGroupDetail: { __typename?: 'RModifierGroupDetail', uuid: string, name: string } };


export const DeleteModifierModalGetModifierDocument = gql`
    query DeleteModifierModalGetModifier($uuid: ID!) {
  getModifierGroupDetail(uuid: $uuid) {
    uuid
    name
  }
}
    `;

/**
 * __useDeleteModifierModalGetModifierQuery__
 *
 * To run a query within a React component, call `useDeleteModifierModalGetModifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteModifierModalGetModifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteModifierModalGetModifierQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteModifierModalGetModifierQuery(baseOptions: Apollo.QueryHookOptions<DeleteModifierModalGetModifierQuery, DeleteModifierModalGetModifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteModifierModalGetModifierQuery, DeleteModifierModalGetModifierQueryVariables>(DeleteModifierModalGetModifierDocument, options);
      }
export function useDeleteModifierModalGetModifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteModifierModalGetModifierQuery, DeleteModifierModalGetModifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteModifierModalGetModifierQuery, DeleteModifierModalGetModifierQueryVariables>(DeleteModifierModalGetModifierDocument, options);
        }
export type DeleteModifierModalGetModifierQueryHookResult = ReturnType<typeof useDeleteModifierModalGetModifierQuery>;
export type DeleteModifierModalGetModifierLazyQueryHookResult = ReturnType<typeof useDeleteModifierModalGetModifierLazyQuery>;
export type DeleteModifierModalGetModifierQueryResult = Apollo.QueryResult<DeleteModifierModalGetModifierQuery, DeleteModifierModalGetModifierQueryVariables>;