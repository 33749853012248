import Container from "../../../../ui/Container"
import type { StepsType } from "../../interfaces"
import StepIndicator from "../StepIndicator"
import { IndicatorsStateType } from "./config"
import React from "react"

type StepsFooterProps = {
  step: StepsType
  indicatorsStates: IndicatorsStateType[]
}

const StepsFooter: React.FC<StepsFooterProps> = ({
  step,
  indicatorsStates,
}) => {
  return (
    <Container justifyContent="space-between" display="flex">
      <Container display="flex" gap="16px">
        {indicatorsStates.map((indicator, index) => (
          <StepIndicator
            key={`${index}{indicator.id}`}
            state={indicator[`step${step}`]}
            number={indicator.id}
          />
        ))}
      </Container>
    </Container>
  )
}

export default StepsFooter
