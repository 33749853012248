import emptyIcon from "../../../../shared/assets/icons/empty_icon.svg"
import Button from "../../../../ui/Button"
import EmptyState from "../../../../ui/EmptyState"
import ModalFull, { ModalFullSkeleton } from "../../../../ui/ModalFull"
import ErrorPage from "../../../ErrorPage"
import CorporateMenuDetail from "../CorporateMenuDetail"
import { useGetMenuDetailQuery } from "../GraphQL/getMenuDetail.generated"
import EditMenu from "./EditMenu"
import get from "lodash/get"
import React from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

type MenuContainerProps = {
  isCorporate: boolean
}

const EditMenuContainer: React.FC<MenuContainerProps> = ({
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { menuUUID } = useParams<{ menuUUID: string }>()
  const history = useHistory()

  const { data, loading, error } = useGetMenuDetailQuery({
    variables: { uuid: menuUUID },
    fetchPolicy: "network-only",
  })

  const isMain = get(data, "getMenuDetail.isMain")
  const menu = get(data, "getMenuDetail")

  const onClickHandler = () => {
    history.goBack()
  }

  if (loading) return <ModalFullSkeleton />

  if (error) return <ErrorPage error={error} />

  if (!menu)
    return (
      <ModalFull visible>
        <EmptyState
          title={intl.formatMessage({
            id: "restaurants.menus.edit.menu.container.empty.title",
            defaultMessage: "Category not found",
          })}
          button={
            <StyledButton
              hierarchy="secondary"
              onClick={onClickHandler}
              title="Go Back"
            />
          }
          image={emptyIcon}
          fullScreen
        />
      </ModalFull>
    )

  if (!isCorporate && isMain) return <CorporateMenuDetail menu={menu} />

  return <EditMenu menu={menu} isCorporate={isCorporate} />
}

export default EditMenuContainer

export const EditMenuContainerLocation = () => (
  <EditMenuContainer isCorporate={false} />
)

const StyledButton = styled(Button)`
  margin-top: 24px;
`
