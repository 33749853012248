import { useCallback, useMemo, useRef } from "react"

const { GoogleMap } = await import("@react-google-maps/api")

type CoordinateProps = {
  lng: number
  lat: number
}

interface MapProps {
  center: CoordinateProps
  zoom: number
}

export const Map = ({ center, zoom }: MapProps) => {
  const mapRef = useRef<google.maps.Map>()

  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: true,
      scrollwheel: true,
      keyboardShortcuts: false,
      streetViewControl: false,
      draggable: false,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    }),
    []
  )

  const handleLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
  }, [])

  return (
    <GoogleMap
      id="google-map-centered"
      options={mapOptions}
      onLoad={handleLoad}
      zoom={zoom}
      center={center}
      mapContainerStyle={{ width: "100%", height: "100%", borderRadius: 8 }}
    />
  )
}
