import type { GetComboListQuery } from "../../../../../GraphQL/Queries/getComboList.generated"
import { useGetComboListQuery } from "../../../../../GraphQL/Queries/getComboList.generated"
import type { ItemCardModel } from "../../../../../components/ItemCard"
import ItemCard from "../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { RComboList } from "../../../../../shared/graphql/generated/types"
import { useMenuItems } from "../../../../../shared/hooks/useMenuItems"
import paths from "../../../../../shared/routes/paths"
import { CardSkeleton } from "../../../../../ui/Card/Card.skeleton"
import Container from "../../../../../ui/Container"
import EmptyState from "../../../../../ui/EmptyState"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Spacer from "../../../../../ui/Spacer"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import { Text } from "../../../../../ui/Typography/Text/Text"
import OwnershipTag from "../../../components/OwnershipTag"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import debounce from "lodash/debounce"
import get from "lodash/get"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

export interface ISearchCombosModal extends Omit<IModalProps, "onOk"> {
  onSave: (
    selectedCombos: Array<ItemCardModel>,
    removedCombos?: Array<ItemCardModel>
  ) => void
  selectedCombos: Array<ItemCardModel>
  isCorporate?: boolean
}

export const SearchCombosModal: React.FC<ISearchCombosModal> = (props) => {
  const {
    onSave,
    afterClose,
    selectedCombos = [],
    isCorporate = true,
    ...rest
  } = props
  const intl = useIntl()
  const { push } = useHistory()
  const [value, setValue] = useState<string>()
  const { hasCombos } = useMenuItems(["combos"])
  const [searching, setSearching] = useState<boolean>(false)
  const [selectedCards, setSelectedCards] = useState<Array<ItemCardModel>>([
    ...selectedCombos,
  ])
  const [removedCards, setRemovedCards] = useState<Array<ItemCardModel>>([])

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const {
    data,
    refetch: getCombos,
    loading,
    called,
    fetchMore,
  } = useGetComboListQuery({
    variables: { take: 5, isPublished: true },
    skip: !restaurantUUID,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: showGraphqlErrors,
  })

  const comboList: RComboList[] = get(data, "getComboList.results", [])
  const hasNextPage = get(data, "getComboList.hasNextPage", false)
  const endCursor = get(data, "getComboList.endCursor", undefined)

  const fetchMoreCombos = useCallback(() => {
    fetchMore({
      variables: { after: endCursor, take: 5, isPublished: true },
      updateQuery: (prev: GetComboListQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getComboList?.endCursor ===
            fetchMoreResult?.getComboList?.endCursor
        ) {
          return prev
        }

        return {
          getComboList: {
            ...fetchMoreResult.getComboList,
            results: [
              ...(prev.getComboList.results as RComboList[]),
              ...(fetchMoreResult.getComboList.results as RComboList[]),
            ],
          },
        }
      },
    })
  }, [endCursor, fetchMore])

  const handleInputChange = useCallback(
    async (valueChanged: string, callback: () => void) => {
      try {
        getCombos({
          name: valueChanged === "" ? undefined : valueChanged,
          take: 20,
        })
      } catch (error) {
        showGraphqlErrors(error)
      } finally {
        callback()
      }
    },
    [getCombos]
  )

  const searchCombosDebounce = useMemo(
    () => debounce(handleInputChange, 1000),
    [handleInputChange]
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setSearching(true)

      searchCombosDebounce("", () => {
        setSearching(false)
      })

      return
    }

    setValue(event.target.value)
  }

  const onCheckCard = (
    _event: CheckboxChangeEvent | React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemCardModel: ItemCardModel
  ) => {
    if (!itemCardModel.uuid) return

    const isComboPresent = selectedCards.some(
      (combo) => combo.uuid === itemCardModel.uuid
    )
    const isPreSelectedCombo = selectedCombos.some(
      (combo) => combo.uuid === itemCardModel.uuid
    )

    if (isComboPresent) {
      setSelectedCards((prev) => {
        return prev.filter(({ uuid }) => uuid !== itemCardModel.uuid)
      })

      if (isPreSelectedCombo) {
        setRemovedCards((prev) => {
          return [
            ...prev.filter(({ uuid }) => uuid !== itemCardModel.uuid),
            itemCardModel,
          ]
        })
      }
    } else {
      setSelectedCards((prev) => {
        return [
          ...prev.filter(({ uuid }) => uuid !== itemCardModel.uuid),
          itemCardModel,
        ]
      })

      if (isPreSelectedCombo) {
        setRemovedCards((prev) => {
          return prev.filter(({ uuid }) => uuid !== itemCardModel.uuid)
        })
      }
    }
  }

  const onSaveData = () => {
    const currentState = selectedCards.filter((defaultCombo) => {
      return !removedCards.some(({ uuid }) => uuid === defaultCombo.uuid)
    })
    onSave(currentState, removedCards)
    setSelectedCards([])
    setRemovedCards([])
  }

  const redirectToNewCombo = () => push(paths.restaurants.createCombo)

  const handleAfterClose = () => {
    setValue(undefined)
    afterClose?.()
  }

  useEffect(() => {
    if (value) {
      setSearching(true)

      searchCombosDebounce(value, () => {
        setSearching(false)
      })
    }
  }, [searchCombosDebounce, value])

  const skeleton = <CardSkeleton cards={2} loading />

  return (
    <StyledModal
      {...rest}
      afterClose={handleAfterClose}
      closable={false}
      onOk={onSaveData}
      destroyOnClose
      width="563px"
    >
      <StyledInput
        aria-label="search-combo-input"
        placeholder={intl.formatMessage({
          id: "restaurants.menu.placeholder.combo.label",
          defaultMessage: "Search Combo",
        })}
        onChange={handleChange}
        loading={searching}
        disabled={!hasCombos && !loading}
        bordered={false}
        size="middle"
        searchIcon
      />

      <Spacer size={24} />
      <StyledModalBody id="search-combos-modal">
        {comboList.length === 0 && called && !loading && (
          <Container
            centered
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <EmptyState
              title=""
              description={intl.formatMessage({
                id: "components.search.combos.not.found.title",
                defaultMessage: "Sorry, we did not find any results",
              })}
            />
            <Text size="s">
              {intl.formatMessage({
                id: "components.search.combos.not.found.description",
                defaultMessage: "You can create a new combo ",
              })}
              <HighlightedText
                size="s"
                cursor="pointer"
                onClick={redirectToNewCombo}
              >
                {intl.formatMessage({
                  id: "components.search.combos.not.found.link",
                  defaultMessage: "here",
                })}
              </HighlightedText>
            </Text>
          </Container>
        )}

        {comboList.length > 0 && (
          <Container height="100%" width="100%">
            <InfiniteScroll
              dataLength={comboList.length}
              next={fetchMoreCombos}
              hasMore={loading || hasNextPage}
              loader={skeleton}
              scrollableTarget="search-combos-modal"
              className="infinite-scroll"
              height={256}
            >
              {comboList.map((combo) => (
                <ItemCard
                  key={combo.uuid}
                  id={combo.uuid}
                  title={combo.name ?? ""}
                  textTitle={
                    <Text
                      className="card__content__title__item"
                      size="m"
                      title={combo.name ? combo.name : ""}
                      ellipsis
                    >
                      {combo.name}
                    </Text>
                  }
                  src={combo.attachment?.signedUrl}
                  checked={selectedCards.some(
                    ({ uuid }) => uuid === combo.uuid
                  )}
                  onCheckCard={onCheckCard}
                  tagOwnership={
                    !isCorporate && (
                      <OwnershipTag isMain={combo.isMain} size="small" />
                    )
                  }
                  imageFit="contain"
                  bordered={false}
                  attachment={combo.attachment ?? undefined}
                  className="card-combo"
                  checkable
                />
              ))}
            </InfiniteScroll>
          </Container>
        )}
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 32px 40px;
  }
`

const StyledInput = styled(Input)`
  ${({ theme }) => `.input-inner-wrapper {
    background: ${theme.colors.Neutral2};
  }`}
`

const StyledModalBody = styled.div`
  max-height: 256px;
  min-height: 200px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .infinite-scroll::-webkit-scrollbar {
    display: none;
  }

  .card-combo {
    padding: 3px 0;
    &:last-child {
      margin: 0;
    }
  }
`
