import paths from "../../../../../shared/routes/paths"
import {
  UNIX_TIMESTAMP_ID,
  YYYY_MM_DD,
  hh_mm_A,
} from "../../../../../shared/utils/constant/dateFormats"
import { CateringOrderInformationType } from "../../types"
import { ModalCateringOrders } from "../ModalCateringOrders"
import {
  Button,
  Group,
  Skeleton,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { IconTruckDelivery } from "@tabler/icons-react"
import moment from "moment-timezone"
import { useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

interface CalendarDayProps {
  ordersData: CateringOrderInformationType[]
  date: Date
  loading: boolean
}

export const CalendarDay = ({
  date,
  ordersData,
  loading,
}: CalendarDayProps) => {
  const intl = useIntl()
  const mantineTheme = useMantineTheme()
  const { push } = useHistory()

  const [opened, { open, close }] = useDisclosure()
  const [remainingCateringOrders, setRemainingCateringOrders] = useState<
    CateringOrderInformationType[]
  >([])
  const [selectedDate, setSelectedDate] = useState<Date>(() => new Date())

  const dayOfMonth = date.getDate()
  const formattedDate = moment(date).format(YYYY_MM_DD)
  const filteredOrders = ordersData.filter(
    (order) => moment(order.createdAt).format(YYYY_MM_DD) === formattedDate
  )

  const isScreenSizeDesktop = useMediaQuery(
    `(min-width: ${mantineTheme.breakpoints.lg})`
  )

  const sortedOrders = filteredOrders.sort(
    (a, b) =>
      Number(moment(a.createdAt).format(UNIX_TIMESTAMP_ID)) -
      Number(moment(b.createdAt).format(UNIX_TIMESTAMP_ID))
  )

  const remainingOrders = sortedOrders.slice(1)
  const initialOrders = sortedOrders.slice(0, 1)

  const displayedOrders = isScreenSizeDesktop ? remainingOrders : sortedOrders

  const hasOrders = displayedOrders.some(
    (order) =>
      moment(order.createdAt).format(YYYY_MM_DD) ===
      moment(date).format(YYYY_MM_DD)
  )

  const handleDisplayOrders = (
    orders: CateringOrderInformationType[],
    day: Date
  ) => {
    open()
    setRemainingCateringOrders(orders)
    setSelectedDate(day)
  }

  return (
    <Stack w="100%" h="100%" spacing={8}>
      <Text fw={600} c={"black"}>
        {dayOfMonth}
      </Text>
      {loading && !hasOrders ? (
        <Stack>
          <Skeleton w="100%" h={15} />
          <Skeleton w="100%" h={15} />
        </Stack>
      ) : (
        <>
          {isScreenSizeDesktop && initialOrders.length > 0 && (
            <Stack spacing={4}>
              {initialOrders.map((item) => (
                <Stack
                  spacing={2}
                  key={item.uuid}
                  onClick={() =>
                    push(paths.restaurants.cateringDetail(item.uuid))
                  }
                >
                  <Group spacing={4}>
                    <IconTruckDelivery
                      color={mantineTheme.colors.gray[6]}
                      size={16}
                    />
                    <Text c="gray.9" size="xs">
                      #{item.orderNumber}
                    </Text>
                  </Group>
                  <Text size="xs" c="gray.6">
                    {moment(item.createdAt).format(hh_mm_A)}
                  </Text>
                </Stack>
              ))}
            </Stack>
          )}

          {displayedOrders.length > 0 && (
            <Button
              bg="gray.0"
              size="xs"
              variant="light"
              styles={{
                inner: {
                  padding: "4px 0px",
                },
              }}
              onClick={() => handleDisplayOrders(displayedOrders, date)}
            >
              {intl.formatMessage(
                {
                  id: "restaurants.catering.calendar.day.button.more",
                  defaultMessage:
                    "{isDesktop, select, true {+ {length} More} other {+ {length}}}",
                },
                {
                  length: displayedOrders.length,
                  isDesktop: isScreenSizeDesktop,
                }
              )}
            </Button>
          )}
          <ModalCateringOrders
            opened={opened}
            onClose={close}
            data={remainingCateringOrders}
            date={selectedDate}
          />
        </>
      )}
    </Stack>
  )
}
