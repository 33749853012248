import { toFormat } from "../../../../../../shared/utils/currency/toFormat.dinero"
import Button from "../../../../../../ui/Button"
import Container from "../../../../../../ui/Container"
import Input from "../../../../../../ui/Inputs/Input"
import Text from "../../../../../../ui/Typography/Text"
import type { IComboForm } from "../../interfaces/hookforms.interfaces"
import React from "react"
import type { UseFieldArrayAppend } from "react-hook-form"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type CollapseComboItemGroupHeaderProps = {
  cmbItmGrpIndex: number
  appendCmbItmGrp: UseFieldArrayAppend<IComboForm, "comboItemGroups">
  isCorporate?: boolean
}

const CollapseComboItemGroupHeader: React.FC<CollapseComboItemGroupHeaderProps> =
  (props) => {
    const { cmbItmGrpIndex, appendCmbItmGrp, isCorporate = true } = props
    const intl = useIntl()

    const {
      control,
      formState: { errors },
    } = useFormContext<IComboForm>()

    const [cmbItmGrp, defaultComboItemPrice] = useWatch({
      name: [
        `comboItemGroups.${cmbItmGrpIndex}`,
        `comboItemGroups.${cmbItmGrpIndex}.defaultComboItemPrice`,
      ],
      control: control,
    })

    const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
    }

    const onDuplicateClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      const { name, ...rest } = cmbItmGrp

      const newName =
        name &&
        intl.formatMessage(
          {
            id: "restaurants.menu.items.forms.combo.form.combo.creation.duplicate.name",
            defaultMessage: "Copy of {name}",
          },
          { name }
        )
      appendCmbItmGrp({
        ...rest,
        name: newName ? newName : "",
        isDuplicate: true,
      })
    }

    return (
      <Container
        cursor="default"
        display="flex"
        gap="20px"
        justifyContent="space-between"
        width="100%"
      >
        <HeaderContainer
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="48px"
        >
          <Controller
            control={control}
            name={`comboItemGroups.${cmbItmGrpIndex}.name`}
            render={({ field }) => (
              <Input
                {...field}
                width="100%"
                onClick={stopPropagation}
                hasError={!!errors.comboItemGroups?.[cmbItmGrpIndex]?.name}
                errorMessage={
                  errors.comboItemGroups?.[cmbItmGrpIndex]?.name?.message
                }
                placeholder={intl.formatMessage({
                  id: "restaurants.menu.items.forms.combo.form.combo.creation.item.group.placeholder",
                  defaultMessage: "Item Group",
                })}
                inputWrapperClassName="combo-item-group__name-input"
              />
            )}
          />
          <Container display="flex" alignItems="center" gap="16px">
            {isCorporate && (
              <Button
                type="button"
                hierarchy="tertiary"
                onClick={onDuplicateClick}
                leadingIcon="ri-checkbox-multiple-blank-line"
                shape="square"
              />
            )}
            <Text size="m" weight="regular" color="Neutral5">
              {toFormat(defaultComboItemPrice ?? 0)}
            </Text>
          </Container>
        </HeaderContainer>
      </Container>
    )
  }

export default CollapseComboItemGroupHeader

const HeaderContainer = styled(Container)`
  .combo-item-group__name-input {
    width: 100%;
  }
`
