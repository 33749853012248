import { EMPTY_ARRAY } from "../../../../../../../shared/utils/constant/values"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  APPLY_ALL_COMBOS_NAME,
  APPLY_ALL_ITEMS_NAME,
  COMBOS_FIELD_NAME,
  ENTIRE_ORDER_NAME,
  ITEMS_FIELD_NAME,
  SET_VALUE_OPTIONS,
} from "../../constants"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useFormContext, useWatch } from "react-hook-form"

export const useAppliesTo = () => {
  const { control, setValue, formState, trigger, getValues } =
    useFormContext<IDiscountForm>()

  const error = formState.errors.rules?.appliesTo?.entireOrder?.message

  const isEditMode = getValues(EDIT_MODE_NAME)

  const [entireOrder, allItems, allCombos, items, combos] = useWatch({
    control,
    name: [
      ENTIRE_ORDER_NAME,
      APPLY_ALL_ITEMS_NAME,
      APPLY_ALL_COMBOS_NAME,
      ITEMS_FIELD_NAME,
      COMBOS_FIELD_NAME,
    ],
  })

  const onCheckboxChange = (checkboxChange: CheckboxChangeEvent) => {
    const checked = checkboxChange.target.checked

    setValue(ENTIRE_ORDER_NAME, checked, SET_VALUE_OPTIONS)

    if (!checked) {
      setValue(APPLY_ALL_ITEMS_NAME, false, SET_VALUE_OPTIONS)
      setValue(APPLY_ALL_COMBOS_NAME, false, SET_VALUE_OPTIONS)
      return
    }

    setValue(ITEMS_FIELD_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
    setValue(APPLY_ALL_ITEMS_NAME, true, SET_VALUE_OPTIONS)
    setValue(COMBOS_FIELD_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
    setValue(APPLY_ALL_COMBOS_NAME, true, SET_VALUE_OPTIONS)
  }

  return {
    error,
    entireOrder,
    allItems,
    allCombos,
    items,
    combos,
    onCheckboxChange,
    trigger,
    isEditMode,
  }
}
