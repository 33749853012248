import { useSendRefundPaymentMutation } from "../../../../GraphQL/Queries/sendRefundPayment.mutation.generated"
import { toFormat } from "../../../../shared/utils/currency/toFormat.dinero"
import { roundUp } from "../../../../shared/utils/helpers/roundUp"
import { GetOrderDetailsDocument } from "../OrderDetail/GraphQL/getOrderDetails.generated"
import { GetOrderSummaryDocument } from "../OrderDetail/GraphQL/getOrderSummary.generated"
import styles from "./RefundOrderModal.module.css"
import {
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  NumberInputHandlers,
  Space,
  Stack,
  Text,
  Textarea,
} from "@mantine/core"
import * as Sentry from "@sentry/react"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { useRef, useState } from "react"
import { useIntl } from "react-intl"

interface Item {
  quantity: number
  name: string
  price: number
}

interface Invoice {
  total: number
  tip: number
  storecash: number
  orderUUID: string
  locationUUID: string
  deliveryFee: number
  serviceFees: number
  taxes: number
  discounts: number
  subTotal: number
}

interface RefundOrderModalProps {
  items: Item[]
  invoice: Invoice
  close: () => void
}

interface RefundAmountsData {
  tip: number
  storecash: number
  total: number
  comment: string
}

enum RefundStep {
  Feedback,
  Confirmation,
}

export const RefundOrderModal = (props: RefundOrderModalProps) => {
  const { close } = props
  const [refundData, setRefundData] = useState<RefundAmountsData>({
    total: 0,
    tip: 0,
    storecash: 0,
    comment: "",
  })
  const [refundStep, setRefundStep] = useState(RefundStep.Feedback)

  const intl = useIntl()
  const [sendRefundPayment, { loading }] = useSendRefundPaymentMutation()

  const changeRefundData = (data: RefundAmountsData) => {
    setRefundData(data)
  }

  const changeComment = (value: string) => {
    setRefundData({ ...refundData, comment: value })
  }

  const handleRefund = async () => {
    try {
      const result = await sendRefundPayment({
        variables: {
          data: {
            amountToRefund: refundData.total,
            tipToRefund: refundData.tip,
            storeCashToRefund: refundData.storecash,
            comment: refundData.comment,
            order: { uuid: props.invoice.orderUUID },
            location: { uuid: props.invoice.locationUUID },
          },
        },
        refetchQueries: [GetOrderSummaryDocument, GetOrderDetailsDocument],
      })

      if (result.errors) {
        throw new Error(result.errors[0].message)
      }

      close()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleNext = () => {
    setRefundStep(RefundStep.Confirmation)
  }

  const handleBack = () => {
    setRefundStep(RefundStep.Feedback)
  }

  return (
    <Modal
      zIndex={1001}
      title={intl.formatMessage({
        id: "restaurants.orders.order.detail.refund.order.confirmation.modal.title",
        defaultMessage: "Refund",
      })}
      centered
      opened={true}
      onClose={close}
    >
      {refundStep === RefundStep.Feedback && (
        <FeedbackContent
          changeComment={changeComment}
          allowNext={refundData.comment !== ""}
          nextStep={handleNext}
        />
      )}

      {refundStep === RefundStep.Confirmation && (
        <ConfirmationContent
          {...props}
          loading={loading}
          previousStep={handleBack}
          refundData={refundData}
          sendRefund={handleRefund}
          changeRefundData={changeRefundData}
        />
      )}
    </Modal>
  )
}

const FeedbackContent = ({
  nextStep,
  allowNext,
  changeComment,
}: {
  nextStep: () => void
  allowNext: boolean
  changeComment: (value: string) => void
}) => {
  const intl = useIntl()

  return (
    <>
      <Textarea
        onChange={(event) => changeComment(event.currentTarget.value)}
        label={intl.formatMessage({
          id: "restaurants.orders.order.detail.refund.order.confirmation.textarea.label",
          defaultMessage: "Note",
        })}
        description={intl.formatMessage({
          id: "restaurants.orders.order.detail.refund.order.confirmation.textarea.description",
          defaultMessage:
            "Leave a comment here with more information about what happened.",
        })}
        placeholder={intl.formatMessage({
          id: "restaurants.orders.order.detail.refund.order.confirmation.textarea.placeholder",
          defaultMessage: "Type your note here",
        })}
      />
      <Space h={20} />
      <Group position="right" spacing="xs">
        <Button disabled={!allowNext} color="red.7" onClick={nextStep}>
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.refund.order.confirmation.next.button.label",
            defaultMessage: "Next",
          })}
        </Button>
      </Group>
    </>
  )
}

interface ConfirmationContentProps {
  items: Item[]
  invoice: Invoice
  refundData: RefundAmountsData
  changeRefundData: (data: RefundAmountsData) => void
  sendRefund: () => void
  previousStep: () => void
  loading: boolean
}

const ConfirmationContent = (props: ConfirmationContentProps) => {
  const {
    items,
    loading,
    invoice,
    refundData,
    sendRefund,
    changeRefundData,
    previousStep,
  } = props
  const {
    serviceFees,
    subTotal,
    storecash,
    taxes,
    discounts,
    tip,
    total,
    deliveryFee,
  } = invoice
  const intl = useIntl()
  const allowedTotalAmount = roundUp(
    subTotal + taxes + serviceFees + deliveryFee
  )
  const totalHandler = useRef<NumberInputHandlers>()
  const tipHandler = useRef<NumberInputHandlers>()
  const storecashHandler = useRef<NumberInputHandlers>()
  const totalRefund = roundUp(
    refundData.total + refundData.tip + refundData.storecash
  )

  return (
    <>
      <Text weight="bold">
        {intl.formatMessage({
          id: "restaurants.orders.order.detail.refund.order.confirmation.checkbox.total.label",
          defaultMessage: "Total amount",
        })}
      </Text>
      <Space h={8} />
      <Stack spacing={0}>
        {items.map((item, index) => (
          <AmountRow key={index} {...item} />
        ))}
        {[
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.subtotal.label",
              defaultMessage: "Subtotal",
            }),
            price: subTotal,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.taxes.label",
              defaultMessage: "Taxes",
            }),
            price: taxes,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.tips.label",
              defaultMessage: "Tips",
            }),
            price: tip,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.service.fees.label",
              defaultMessage: "Service fees",
            }),
            hidden: serviceFees === 0,
            price: serviceFees,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.delivery.fee.label",
              defaultMessage: "Delivery fee",
            }),
            hidden: deliveryFee === 0,
            price: deliveryFee,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.store.credit.label",
              defaultMessage: "Store Credit",
            }),
            hidden: storecash === 0,
            price: storecash,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.discounts.label",
              defaultMessage: "Discounts",
            }),
            hidden: discounts === 0,
            price: discounts,
          },
          {
            name: intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.total.label",
              defaultMessage: "Total",
            }),
            price: total,
            highlight: true,
          },
        ].map((item, index) => (
          <AmountRow key={index} {...item} />
        ))}
      </Stack>
      <Space h={16} />
      <Stack spacing="md">
        <Checkbox
          onChange={(value) => {
            if (value.target.checked) {
              changeRefundData({
                ...refundData,
                total: allowedTotalAmount,
                tip,
                storecash,
              })
            } else changeRefundData({ ...refundData, total: 0 })
          }}
          label={intl.formatMessage({
            id: "restaurants.orders.order.detail.refund.order.confirmation.checkbox.total.label",
            defaultMessage: "Total amount",
          })}
        />
        <NumberInput
          hideControls
          max={allowedTotalAmount}
          min={0}
          value={refundData.total}
          onChange={(value) => {
            if (value !== "") changeRefundData({ ...refundData, total: value })
          }}
          handlersRef={totalHandler}
          precision={2}
          label={intl.formatMessage({
            id: "restaurants.orders.order.detail.refund.order.confirmation.input.total.label",
            defaultMessage: "How much you want refund?",
          })}
          description={intl.formatMessage(
            {
              id: "restaurants.orders.order.detail.refund.order.confirmation.input.total.description",
              defaultMessage:
                "Enter the exact price to refund. (max amount {maxAmount})",
            },
            { maxAmount: allowedTotalAmount }
          )}
          icon={<IconCurrencyDollar size={16} />}
        />
        {tip > 0 && (
          <NumberInput
            onChange={(value) => {
              if (value !== "") changeRefundData({ ...refundData, tip: value })
            }}
            hideControls
            max={tip}
            precision={2}
            defaultValue={0}
            handlersRef={tipHandler}
            min={0}
            value={refundData.tip}
            label={intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.input.tip.label",
              defaultMessage: "Do you want to refund tip?",
            })}
            description={intl.formatMessage(
              {
                id: "restaurants.orders.order.detail.refund.order.confirmation.input.tip.description",
                defaultMessage:
                  "Enter the exact price to refund. (max amount ${maxAmount})",
              },
              { maxAmount: tip }
            )}
            icon={<IconCurrencyDollar size={16} />}
          />
        )}
        {storecash > 0 && (
          <NumberInput
            hideControls
            onChange={(value) => {
              if (value !== "")
                changeRefundData({ ...refundData, storecash: value })
            }}
            max={storecash}
            precision={2}
            defaultValue={0}
            handlersRef={storecashHandler}
            min={0}
            value={refundData.storecash}
            label={intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.input.storecash.label",
              defaultMessage: "Do you want to refund storecash?",
            })}
            description={intl.formatMessage(
              {
                id: "restaurants.orders.order.detail.refund.order.confirmation.input.storecash.description",
                defaultMessage:
                  "Enter the exact price to refund. (max amount ${maxAmount})",
              },
              { maxAmount: storecash }
            )}
            icon={<IconCurrencyDollar size={16} />}
          />
        )}
        <Group position="right" spacing="xs">
          <Button variant="outline" color="gray.7" onClick={previousStep}>
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.refund.order.confirmation.cancel.button.label",
              defaultMessage: "Back",
            })}
          </Button>
          <Button
            loading={loading}
            disabled={totalRefund === 0}
            color="red.7"
            onClick={sendRefund}
          >
            {intl.formatMessage(
              {
                id: "restaurants.orders.order.detail.refund.order.confirmation.button.confirm.label",
                defaultMessage: "Refund (${total})",
              },
              {
                total: totalRefund,
              }
            )}
          </Button>
        </Group>
      </Stack>
    </>
  )
}

const AmountRow = ({
  quantity,
  name,
  price,
  hidden,
  highlight,
}: {
  quantity?: number
  name: string
  price: number
  hidden?: boolean
  highlight?: boolean
}) => {
  return (
    <Group
      hidden={hidden}
      py={12}
      px={24}
      className={styles.summary}
      position="apart"
    >
      <Group>
        {quantity && <Text weight="bold">x{quantity}</Text>}
        <Text weight={highlight ? "bold" : undefined}>{name}</Text>
      </Group>
      <Text weight={highlight ? "bold" : undefined}>{toFormat(price)}</Text>
    </Group>
  )
}
