import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalculateCateringPaymentAmountQueryVariables = Types.Exact<{
  order: Types.RuuidInput;
  serviceTypes?: Types.InputMaybe<Array<Types.PaymentServiceTypeEnum> | Types.PaymentServiceTypeEnum>;
  customAmount?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type CalculateCateringPaymentAmountQuery = { __typename?: 'Query', calculateCateringPaymentAmount: { __typename?: 'CalculatedCateringPaymentAmount', payment?: { __typename?: 'PaymentDetailByMode', remaining: { __typename?: 'PaymentDetailsByMethod', total: number }, general: { __typename?: 'GeneralDetail', cateringFee: number, serviceFee: number, subtotal: number, taxes: number, total: number } } | null } };


export const CalculateCateringPaymentAmountDocument = gql`
    query CalculateCateringPaymentAmount($order: RUUIDInput!, $serviceTypes: [PaymentServiceTypeEnum!], $customAmount: Float) {
  calculateCateringPaymentAmount(
    order: $order
    serviceTypes: $serviceTypes
    customAmount: $customAmount
  ) {
    payment {
      remaining {
        total
      }
      general {
        cateringFee
        serviceFee
        subtotal
        taxes
        total
      }
    }
  }
}
    `;

/**
 * __useCalculateCateringPaymentAmountQuery__
 *
 * To run a query within a React component, call `useCalculateCateringPaymentAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateCateringPaymentAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateCateringPaymentAmountQuery({
 *   variables: {
 *      order: // value for 'order'
 *      serviceTypes: // value for 'serviceTypes'
 *      customAmount: // value for 'customAmount'
 *   },
 * });
 */
export function useCalculateCateringPaymentAmountQuery(baseOptions: Apollo.QueryHookOptions<CalculateCateringPaymentAmountQuery, CalculateCateringPaymentAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateCateringPaymentAmountQuery, CalculateCateringPaymentAmountQueryVariables>(CalculateCateringPaymentAmountDocument, options);
      }
export function useCalculateCateringPaymentAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateCateringPaymentAmountQuery, CalculateCateringPaymentAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateCateringPaymentAmountQuery, CalculateCateringPaymentAmountQueryVariables>(CalculateCateringPaymentAmountDocument, options);
        }
export type CalculateCateringPaymentAmountQueryHookResult = ReturnType<typeof useCalculateCateringPaymentAmountQuery>;
export type CalculateCateringPaymentAmountLazyQueryHookResult = ReturnType<typeof useCalculateCateringPaymentAmountLazyQuery>;
export type CalculateCateringPaymentAmountQueryResult = Apollo.QueryResult<CalculateCateringPaymentAmountQuery, CalculateCateringPaymentAmountQueryVariables>;