//TODO: REMOVE THIS COMMENTS WHEN THE FEATURE IS READY
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TimeEntriesReport } from "../../../../../shared/graphql/generated/types"
import paths from "../../../../../shared/routes/paths"
import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import Tag from "../../../TimeEntries/ListEmployees/Tag"
import { ExportCSV } from "../../components/ExportCSV"
import { FinancialCard } from "../../components/FinancialCard"
import { ReportsTable } from "../../components/ReportsTable"
import styles from "../../components/ReportsTable/ReportsTable.module.css"
import { LaborCostSummary } from "../../hooks/useReports"
import { payrollTableHeaders } from "./config"
import { Grid, Group, Stack, Title } from "@mantine/core"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type LaborCostSectionProps = {
  contentHeight: number
  summary: LaborCostSummary
}

const SECONDS_IN_AN_HOUR = 3600

export const LaborCostSection = (props: LaborCostSectionProps) => {
  const { contentHeight, summary } = props
  const { locations } = paths.restaurants
  const intl = useIntl()
  const { locationUUID } = useParams<{ locationUUID: string }>()

  const financialCardRowFields = useMemo(
    () => [
      {
        name: {
          defaultMessage: "Payroll",
          id: "restaurants.reports.labor.cost.payroll.label",
        },
        value: summary.payroll.current,
        dif: summary.payroll.current - summary.payroll.previous,
      },
      {
        name: {
          defaultMessage: "Tips",
          id: "restaurants.reports.labor.cost.tips.label",
        },
        value: summary.tips.current,
        dif: summary.tips.current - summary.tips.previous,
      },
    ],
    [summary.payroll, summary.tips]
  )

  return (
    <Stack>
      <Group position="apart">
        <Title order={4}>
          {intl.formatMessage({
            id: "restaurants.reports.labor.cost.title",
            defaultMessage: "Labor cost",
          })}
        </Title>

        <ExportCSV
          onClick={async () => await Promise.resolve()}
          type="LaborCost"
        />
      </Group>

      <Grid gutter={24}>
        <Grid.Col xl={3}>
          <FinancialCard
            h={contentHeight}
            fields={financialCardRowFields}
            redirection={locations.payrollByLocation(locationUUID)}
            currentValue={summary.payroll.current + summary.tips.current}
            previousValue={summary.payroll.previous + summary.tips.previous}
          />
        </Grid.Col>
        <Grid.Col xl={9}>
          <ReportsTable
            h={contentHeight}
            tableData={getLaborCostRows(summary.employees)}
            headers={payrollTableHeaders}
            firstHeaderEmpty
          />
        </Grid.Col>
      </Grid>
    </Stack>
  )
}

const getLaborCostRows = (
  employees: Array<TimeEntriesReport> | null | undefined
) => {
  if (!employees || employees.length === 0) {
    return []
  }
  const list = employees.map((employee) => (
    <tr key={employee.id} className={styles["tbody-tr"]}>
      <td id="name" width="100%" className={styles["td-tag"]}>
        {employee.name}
      </td>

      <td id="duration" className={styles["td-tag"]}>
        <Tag
          type="TIME"
          background="gray.1"
          text={`${(employee.duration / SECONDS_IN_AN_HOUR).toFixed(2)}`}
        />
      </td>

      <td id="payroll" className={styles["td-tag"]}>
        <Tag
          type="DEFAULT"
          background="gray.1"
          icon={IconCurrencyDollar}
          text={`${(
            employee.payRate *
            (employee.duration / SECONDS_IN_AN_HOUR)
          ).toFixed(2)}`}
        />
      </td>

      <td id="tips" className={styles["td-tag"]}>
        <Tag
          type="DEFAULT"
          background="gray.1"
          icon={IconCurrencyDollar}
          text={toFormat(employee.tip)}
        />
      </td>

      <td id="total" className={styles["td-tag"]}>
        <Tag type="AMOUNT" text={`${employee.laborCost.toFixed(2)}`} />
      </td>
    </tr>
  ))

  return list
}
