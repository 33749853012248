import emptyIcon from "../../../../../shared/assets/icons/empty_icon.svg"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import EmptyState from "../../../../../ui/EmptyState"
import ModalFull, { ModalFullSkeleton } from "../../../../../ui/ModalFull"
import ErrorPage from "../../../../ErrorPage"
import CorporateItemDetail from "../CorporateItemDetail"
import { useGetItemDetailQuery } from "../GraphQL/getItemDetail.generated"
import { EditItem } from "./EditItem"
import get from "lodash/get"
import React from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

type MenuContainerProps = {
  isCorporate: boolean
}

export const EditItemContainer: React.FC<MenuContainerProps> = ({
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { itemUUID } = useParams<{ itemUUID: string }>()
  const history = useHistory()

  const { data, loading, error } = useGetItemDetailQuery({
    variables: { uuid: itemUUID },
    fetchPolicy: "network-only",
  })

  const isMain = get(data, "getItemDetail.isMain")
  const item = get(data, "getItemDetail")

  const onClickHandler = () => {
    history.goBack()
  }

  if (loading) return <ModalFullSkeleton />

  if (error) return <ErrorPage error={error} />

  if (!item)
    return (
      <ModalFull headerColor="Neutral2" visible>
        <EmptyState
          title={intl.formatMessage({
            id: "restaurants.menu.items.edit.item.container.empty.title",
            defaultMessage: "Item not found",
          })}
          button={
            <Container margin="24px 0px 0px 0px">
              <Button
                hierarchy="secondary"
                onClick={onClickHandler}
                title={intl.formatMessage({
                  id: "restaurants.menu.items.edit.item.container.back.title",
                  defaultMessage: "Go Back",
                })}
              />
            </Container>
          }
          image={emptyIcon}
          fullScreen
        />
      </ModalFull>
    )

  if (!isCorporate && isMain) return <CorporateItemDetail item={item} />

  return <EditItem item={item} isCorporate={isCorporate} />
}

export const EditCorporateItemContainer = () => (
  <EditItemContainer isCorporate={false} />
)
