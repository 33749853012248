import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetItemListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  isPublished?: Types.InputMaybe<Types.Scalars['Boolean']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  hasBeenPublished?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetItemListQuery = { __typename?: 'Query', getItemList: { __typename?: 'PaginatedItemList', endCursor?: string | null, hasNextPage: boolean, results?: Array<{ __typename?: 'ItemList', hasSnapshot: boolean, isMain: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name?: string | null, publishedAt?: any | null, status: Types.ItemProgressStatusEnum, uuid: string, createdAt: any, attachment?: { __typename?: 'AttachmentModel', signedUrl: string } | null }> | null } };


export const GetItemListDocument = gql`
    query getItemList($after: String, $isPublished: Boolean, $name: String, $take: Int, $hasBeenPublished: Boolean) {
  getItemList(
    after: $after
    isPublished: $isPublished
    name: $name
    take: $take
    hasBeenPublished: $hasBeenPublished
  ) {
    endCursor
    hasNextPage
    results {
      attachment {
        signedUrl
      }
      hasSnapshot
      isMain
      isSoldOut
      isVisible
      name
      publishedAt
      status
      uuid
      createdAt
    }
  }
}
    `;

/**
 * __useGetItemListQuery__
 *
 * To run a query within a React component, call `useGetItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      isPublished: // value for 'isPublished'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *      hasBeenPublished: // value for 'hasBeenPublished'
 *   },
 * });
 */
export function useGetItemListQuery(baseOptions?: Apollo.QueryHookOptions<GetItemListQuery, GetItemListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemListQuery, GetItemListQueryVariables>(GetItemListDocument, options);
      }
export function useGetItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemListQuery, GetItemListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemListQuery, GetItemListQueryVariables>(GetItemListDocument, options);
        }
export type GetItemListQueryHookResult = ReturnType<typeof useGetItemListQuery>;
export type GetItemListLazyQueryHookResult = ReturnType<typeof useGetItemListLazyQuery>;
export type GetItemListQueryResult = Apollo.QueryResult<GetItemListQuery, GetItemListQueryVariables>;