import { device } from "../../../../shared/breakpoints/responsive"
import { useOnboardingContext } from "../../../../shared/contexts/OnboardingProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import {
  AttachmentTypeEnum,
  ContentTypeEnum,
  FileExtensionEnum,
} from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import { ContextLogoType } from "../../../../shared/types"
import { putFileWithSignedUrl } from "../../../../shared/utils/api/client"
import { postRefreshToken } from "../../../../shared/utils/api/refresh-token"
import { getImageFile } from "../../../../shared/utils/helpers/getImageFile"
import {
  ANDROID_DOMAIN,
  IOS_DOMAIN,
  getWhiteLabelConfig,
} from "../../../../shared/utils/helpers/getWhiteLabelConfig"
import Container from "../../../../ui/Container"
import Divider from "../../../../ui/Divider"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import OnboardingLayout from "../../../../ui/Layouts/Onboarding"
import Loader from "../../../../ui/Loader"
import { UIActionTypes } from "../../../../ui/actions"
import notification from "../../../../ui/notification"
import { ActionTypes as OnboardingActionTypes } from "../../../CreateZone/actions"
import { ActionTypes } from "../../../Restaurants/actions"
import { useSaveWhiteLabelConfigMutation } from "../../../Settings/Restaurant/WhiteLabelApplication/Graphql/saveWhiteLabelConfig.generated"
import { useGetRestaurantSnapshotQuery } from "../../GraphQL/GetRestaurantSnapshot.generated"
import { usePublishRestaurantMutation } from "../../GraphQL/PublishRestaurant.generated"
import { useSetOnboardingCompletedMutation } from "../../GraphQL/setCompletedOnboarding.generated"
import OnboardingLocationForm from "../../forms/OnboardingLocationForm"
import OnboardingMenuForm from "../../forms/OnboardingMenuForm"
import OnboardingRestaurantForm from "../../forms/OnboardingRestaurantForm"
import type { LocationsQuantityType, StepsType } from "../../interfaces"
import LeftSideView from "../LeftSideView"
import { indicatorsStates } from "../StepsFooter/config"
import * as Sentry from "@sentry/react"
import { RcFile } from "antd/lib/upload"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import SwipeableViews from "react-swipeable-views"
import styled from "styled-components"

const SwipeableSteps = () => {
  const {
    state: {
      auth: {
        admin: { uuid: userUUID },
      },
    },
    dispatch,
    initRestaurants,
  } = useGeneralContext()

  const intl = useIntl()
  const [setOnboardingCompleted] = useSetOnboardingCompletedMutation()
  const history = useHistory()
  const {
    onboarding,
    loadFromSnapshot,
    updateOnboardingState,
    clearOnboardingState,
  } = useOnboardingContext()

  const { data, loading } = useGetRestaurantSnapshotQuery({
    variables: { userUUID },
    fetchPolicy: "network-only",
  })

  const [currentStep, setCurrentStep] = useState<StepsType>(1)
  const snapshot = data?.getRestaurantSnapshot?.snapshot
  const [publishRestaurant] = usePublishRestaurantMutation({
    onError: (e) => showGraphqlErrors(e),
  })

  const handlePublishRestaurant = async () => {
    try {
      const publishedResult = await publishRestaurant({
        variables: { uuid: userUUID },
      })

      const restaurantUUID = publishedResult.data?.publishRestaurant.uuid

      if (publishedResult?.errors || !restaurantUUID) {
        throw new Error(
          intl.formatMessage({
            id: "onboarding.error.publish.response.message",
            defaultMessage: "Unable to publish restaurant.",
          })
        )
      }
      const refreshTokenData = await postRefreshToken()
      await initRestaurants(refreshTokenData)

      return restaurantUUID
    } catch (error) {
      notification({
        type: "error",
        description: (error as Error).message,
        key: "publish.restaurant.error",
      })
    }
  }

  const [saveWhiteLabelConfig] = useSaveWhiteLabelConfigMutation()

  const handleWhiteLabelConfig = async (
    restaurantUUID: string,
    logos?: ContextLogoType[]
  ) => {
    const logo = logos?.find((value) => value.type === AttachmentTypeEnum.LOGO)

    if (!logo?.file) return

    const isFile = (argument: string | RcFile): argument is RcFile => {
      return (argument as RcFile).name !== undefined
    }

    const whiteLabelConfig = getWhiteLabelConfig({
      restaurantUUID,
      restaurantName: onboarding.name,
      urlIdentifier: onboarding.urlIdentifier,
      iosDomain: IOS_DOMAIN,
      androidDomain: ANDROID_DOMAIN,
      primaryBrandColor: onboarding.brandColor,
    })

    try {
      const result = await saveWhiteLabelConfig({
        variables: {
          data: {
            restaurantUUID,
            isShouldPublish: false,
            whiteLabelConfig,
            attachment: {
              contentType: logo.contentType as ContentTypeEnum,
              ext: logo.ext as FileExtensionEnum,
              fileName: logo.fileName,
              type: AttachmentTypeEnum.APP_LOGO,
            },
          },
        },
      })

      const signedUrl =
        result.data?.createOrUpdateWhiteLabelConfigToRestaurant.attachment
          ?.signedUrl

      if (!signedUrl) throw new Error("Unable to get signed url")

      if (typeof logo.file === "string" && typeof logo.ext === "string") {
        const logoFile = await getImageFile({
          fileName: logo.fileName,
          ext: logo.ext,
          signedUrl: logo.file,
        })

        if (logoFile instanceof File) {
          await putFileWithSignedUrl(signedUrl, logoFile)
        }

        return
      }

      if (isFile(logo.file)) await putFileWithSignedUrl(signedUrl, logo.file)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleFinishOnboarding = async () => {
    updateOnboardingState({ loading: true })

    const restaurantUUID = await handlePublishRestaurant()
    if (!restaurantUUID) return

    await handleWhiteLabelConfig(restaurantUUID, onboarding.logos)

    dispatch({
      type: ActionTypes.SetCurrentState,
      payload: { restaurantUUID },
    })

    await setOnboardingCompleted({ variables: { uuid: userUUID } })

    dispatch({
      type: OnboardingActionTypes.UpdateOnboardingFlag,
      payload: { isOnboardingCompleted: true },
    })
  }

  const onNextClick = async () => {
    if (currentStep === 2 && onboarding.allowOnboarding) {
      await handleFinishOnboarding()
      clearOnboardingState()
      history.push(paths.restaurants.root)
    }

    if (currentStep === 3) {
      await handleFinishOnboarding()
      updateOnboardingState({ loading: false })

      history.push(paths.onboarding.done)
    }

    setCurrentStep((prev) => (prev === 1 ? 2 : 3))
  }

  const onPreviousClick = () => setCurrentStep((prev) => (prev === 3 ? 2 : 1))

  const isStepsType = (
    argument: number | undefined | null
  ): argument is StepsType => {
    return argument === 1 || argument === 2 || argument === 3
  }

  useEffect(() => {
    if (snapshot) {
      if (!isStepsType(snapshot?.currentStep)) return

      setCurrentStep(snapshot.currentStep)

      loadFromSnapshot({
        currentStep: snapshot?.currentStep ?? undefined,
        brandColor: snapshot?.brandColor ?? undefined,
        locationsQuantity: snapshot?.locationsQuantity as LocationsQuantityType,
        name: snapshot?.name ?? undefined,
        urlIdentifier: snapshot?.urlIdentifier ?? undefined,
        notes: snapshot?.notes ?? undefined,
        logos: snapshot?.logos?.map((logo) => ({
          ...logo,
          type: logo.type as AttachmentTypeEnum,
          file: logo.signedUrl,
        })),
        location: {
          address: snapshot?.location?.address ?? undefined,
          addressLine1: snapshot?.location?.addressLine1 ?? undefined,
          addressLine2: snapshot?.location?.addressLine2 ?? undefined,
          city: snapshot?.location?.city ?? undefined,
          postalCode: snapshot?.location?.postalCode ?? undefined,
          state: snapshot.location?.state ?? undefined,
          name: snapshot?.location?.name ?? undefined,
          latitude: snapshot?.location?.latitude ?? undefined,
          longitude: snapshot?.location?.longitude ?? undefined,
        },
        menuAttachments: snapshot?.menuAttachments?.map((attachment) => ({
          ...attachment,
          type: AttachmentTypeEnum.MENU,
        })),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    dispatch({ type: UIActionTypes.SidebarToggle, payload: { active: true } })

    return () => {
      dispatch({
        type: UIActionTypes.SidebarToggle,
        payload: { active: false },
      })
    }
  }, [dispatch])

  if (loading) {
    return (
      <OnboardingLayout>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Loader />
        </Container>
      </OnboardingLayout>
    )
  }

  const indicators = onboarding.allowOnboarding
    ? indicatorsStates.slice(0, 2)
    : indicatorsStates

  return (
    <OnboardingLayout>
      <DetailOnboarding>
        <ContainerForm>
          <ContainerLeft>
            <LeftSideView
              allowOnboarding={onboarding.allowOnboarding}
              step={currentStep}
            />
          </ContainerLeft>
          <ContainerDivider>
            <Divider
              type="vertical"
              verticalMargin="0"
              style={{ height: "550px", margin: "0" }}
            />
          </ContainerDivider>
          <SwipeableContainer>
            <SwipeableViews
              disabled
              index={currentStep - 1}
              containerStyle={{ height: "100%", width: "100%" }}
              style={{ height: "100%", width: "100%" }}
            >
              <OnboardingRestaurantForm
                indicators={indicators}
                stepFooter={currentStep}
                onNext={onNextClick}
              />
              <OnboardingLocationForm
                indicators={indicators}
                stepFooter={currentStep}
                onPrevious={onPreviousClick}
                onNext={onNextClick}
              />
              <OnboardingMenuForm
                indicators={indicators}
                stepFooter={currentStep}
                onPrevious={onPreviousClick}
                onNext={onNextClick}
              />
            </SwipeableViews>
          </SwipeableContainer>
        </ContainerForm>
      </DetailOnboarding>
    </OnboardingLayout>
  )
}

export default SwipeableSteps

const DetailOnboarding = styled.div`
  position: relative;
  max-width: 1200px;
  display: inline-table;

  @media ${device.lg} {
    width: 100%;
    padding: 20px;
  }
`

const ContainerForm = styled.div`
  position: relative;
  display: grid;
  gap: 48px;
  grid-template-columns: 1fr 2px 1.5fr;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.Neutral0};
  border-radius: 16px;
  padding: 40px 48px;

  @media ${device.lg} {
    grid-template-columns: 1fr;
    padding: 35px;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
    padding: 15px;
  }
`

const ContainerLeft = styled.div`
  height: 600px;
  width: 100%;

  @media ${device.lg} {
    height: 100%;
  }
`

const ContainerDivider = styled.div`
  display: block;
  @media ${device.lg} {
    display: none;
  }
`

const SwipeableContainer = styled.div`
  width: 100%;
  overflow: hidden;
`
