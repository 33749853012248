import { isSomeEnum } from "../../../Restaurants/TimeEntries/utils/is-some-enum.util"
import {
  FeeQuantityEnum,
  TimeBeforeEventEnum,
} from "../Catering/shared/hookforms.intefaces"

export const cateringPolicyTimeBefore: Record<
  TimeBeforeEventEnum,
  {
    id: string
    label: string
    value: TimeBeforeEventEnum
  }
> = {
  [TimeBeforeEventEnum._72H]: {
    id: "catering.cancelation.policy.72.label",
    value: TimeBeforeEventEnum._72H,
    label: "72 hrs before the event",
  },
  [TimeBeforeEventEnum._48H]: {
    id: "catering.cancelation.policy.48.label",
    value: TimeBeforeEventEnum._48H,
    label: "48 hrs before the event",
  },
  [TimeBeforeEventEnum._24H]: {
    id: "catering.cancelation.policy.24.label",
    value: TimeBeforeEventEnum._24H,
    label: "24 hrs before the event",
  },
}

export const paymentTypeMethods: Record<
  FeeQuantityEnum,
  {
    id: string
    label: string
    value: FeeQuantityEnum
  }
> = {
  [FeeQuantityEnum.AMOUNT]: {
    id: "catering.payment.method.amount",
    value: FeeQuantityEnum.AMOUNT,
    label: "Fixed amount",
  },
  [FeeQuantityEnum.PERCENTAGE]: {
    id: "catering.payment.method.percentage",
    value: FeeQuantityEnum.PERCENTAGE,
    label: "Percentage",
  },
}

export const cateringPolicyBeforeHour: Record<TimeBeforeEventEnum, string> = {
  [TimeBeforeEventEnum._24H]: "24",
  [TimeBeforeEventEnum._48H]: "48",
  [TimeBeforeEventEnum._72H]: "72",
}
export const sortOrderCateringPolicies = (
  timeEvents: TimeBeforeEventEnum[]
) => {
  return Object.keys(cateringPolicyTimeBefore).filter((key) => {
    if (isSomeEnum(TimeBeforeEventEnum)(key)) return timeEvents?.includes(key)
  })
}

export const sorOrderPaymentType = (paymentMethod: FeeQuantityEnum[]) => {
  return Object.keys(paymentTypeMethods).filter((key) => {
    if (isSomeEnum(FeeQuantityEnum)(key)) return paymentMethod?.includes(key)
  })
}

export const getOrderCateringPolicies = (timeEvents: TimeBeforeEventEnum[]) => {
  const times: {
    enum: TimeBeforeEventEnum
    value: string
    label: { id: string; defaultMessage: string }
  }[] = []

  sortOrderCateringPolicies(timeEvents).forEach((key) => {
    if (isSomeEnum(TimeBeforeEventEnum)(key))
      times.push({
        enum: key,
        value: cateringPolicyTimeBefore[key].value.toString(),
        label: {
          id: cateringPolicyTimeBefore[key].id,
          defaultMessage: cateringPolicyTimeBefore[key].label,
        },
      })
  })

  return times
}

export const getPaymentMethods = (paymentMethods: FeeQuantityEnum[]) => {
  const times: {
    enum: FeeQuantityEnum
    value: string
    label: { id: string; defaultMessage: string }
  }[] = []

  sorOrderPaymentType(paymentMethods).forEach((key) => {
    if (isSomeEnum(FeeQuantityEnum)(key))
      times.push({
        enum: key,
        value: paymentTypeMethods[key].value.toString(),
        label: {
          id: paymentTypeMethods[key].id,
          defaultMessage: paymentTypeMethods[key].label,
        },
      })
  })

  return times
}
