import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishDraftMenuMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type PublishDraftMenuMutation = { __typename?: 'Mutation', publishDraftMenu: { __typename?: 'RPublishedDraftMenu', uuid: string } };


export const PublishDraftMenuDocument = gql`
    mutation publishDraftMenu($uuid: ID!) {
  publishDraftMenu(uuid: $uuid) {
    uuid
  }
}
    `;
export type PublishDraftMenuMutationFn = Apollo.MutationFunction<PublishDraftMenuMutation, PublishDraftMenuMutationVariables>;

/**
 * __usePublishDraftMenuMutation__
 *
 * To run a mutation, you first call `usePublishDraftMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftMenuMutation, { data, loading, error }] = usePublishDraftMenuMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishDraftMenuMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftMenuMutation, PublishDraftMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDraftMenuMutation, PublishDraftMenuMutationVariables>(PublishDraftMenuDocument, options);
      }
export type PublishDraftMenuMutationHookResult = ReturnType<typeof usePublishDraftMenuMutation>;
export type PublishDraftMenuMutationResult = Apollo.MutationResult<PublishDraftMenuMutation>;
export type PublishDraftMenuMutationOptions = Apollo.BaseMutationOptions<PublishDraftMenuMutation, PublishDraftMenuMutationVariables>;