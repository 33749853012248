import { CalendarPickerModal } from "../../../../components/Modals/CalendarPickerModal"
import { dateRanges } from "../../../../components/Modals/CalendarPickerModal/utils/date-ranges.util"
import {
  DateQueryParam,
  useDateRange,
} from "../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../shared/hooks/useSearchParams"
import { DateRangeEnum } from "../../../../shared/v2/utils/dateRange.enum"
import { CreateEntryModal } from "../Modals/CreateEntryModal"
import styles from "./TimeHeader.module.css"
import { ActionIcon, Button, Flex, Group, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconCalendarEvent, IconPlus } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

export const TimeHeader = () => {
  const intl = useIntl()

  const [opened, { open, close }] = useDisclosure()
  const { getParam } = useSearchParams()
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const { updateDateRangeFilters, updateRangeTypeFilter } = useDateRange()

  const startDateParam = getParam(DateQueryParam.start) ?? ""
  const endDateParam = getParam(DateQueryParam.end) ?? ""

  useEffect(() => {
    if (!startDateParam || !endDateParam) {
      updateDateRangeFilters(dateRanges.today())
      updateRangeTypeFilter(DateRangeEnum.TODAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!startDateParam && !endDateParam) {
      updateDateRangeFilters(dateRanges.today())
      updateRangeTypeFilter(DateRangeEnum.TODAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateParam, endDateParam])

  return (
    <Flex
      h={48}
      pl={32}
      pr={8}
      py={8}
      align="center"
      justify="space-between"
      className={styles.container}
    >
      <Text fw={600}>
        {intl.formatMessage({
          id: "restaurant.locations.payroll.header.title",
          defaultMessage: "Payroll",
        })}
      </Text>
      <Group spacing={8}>
        <Button
          px={16}
          variant="outline"
          leftIcon={<IconCalendarEvent size={16} />}
          onClick={() => setOpenDatePicker(true)}
          classNames={{
            leftIcon: styles["left-icon"],
          }}
        >
          <Text size="sm" weight={400}>
            {intl.formatMessage({
              id: "restaurant.locations.payroll.button.option.date.range",
              defaultMessage: "Date Range",
            })}
          </Text>
        </Button>
        <ActionIcon
          w={36}
          h={36}
          variant="outline"
          color="primary.6"
          onClick={open}
        >
          <IconPlus size={24} />
        </ActionIcon>
        <CreateEntryModal opened={opened} onClose={close} />
        {openDatePicker && (
          <CalendarPickerModal
            opened={openDatePicker}
            onClose={() => setOpenDatePicker(false)}
          />
        )}
      </Group>
    </Flex>
  )
}
