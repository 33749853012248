import { OrderMethodEnum } from "../../../../../shared/graphql/generated/types"
import { CustomOrderMethodTitle } from "../../../../../shared/utils/constant/orderMethod.title"
import { validOrdersMethod } from "../../utils/valid-orders-method"
import OrdersFilterContent from "../OrdersFilterContent"
import React, { useState } from "react"
import { useIntl } from "react-intl"

interface FilterByDeliveryMethodProps {
  filterLabel: string
  clearFilterOptions: () => void
}

export const FilterByDeliveryMethod: React.FC<FilterByDeliveryMethodProps> = ({
  filterLabel,
  clearFilterOptions,
}) => {
  const [filteredDeliveryMethod, setFilteredDeliveryMethod] =
    useState<OrderMethodEnum[]>(validOrdersMethod)
  const [deliveryMethodName, setDeliveryMethodName] = useState<
    OrderMethodEnum | undefined
  >()
  const intl = useIntl()

  const searchDeliveryMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredDeliveryMethodName = event.target.value as OrderMethodEnum

    if (filteredDeliveryMethodName) {
      const results = validOrdersMethod.filter((deliveryMethod) =>
        CustomOrderMethodTitle[deliveryMethod].defaultMessage
          .toLocaleLowerCase()
          .includes(filteredDeliveryMethodName.toLocaleLowerCase())
      )

      results?.length
        ? setFilteredDeliveryMethod(results)
        : setFilteredDeliveryMethod([])
    } else {
      setFilteredDeliveryMethod(validOrdersMethod)
    }
    setDeliveryMethodName(filteredDeliveryMethodName)
  }

  return (
    <OrdersFilterContent
      filterLabel={filterLabel}
      searchTerm={deliveryMethodName}
      onSearchTermChange={searchDeliveryMethod}
      filterKey="delivery-method"
      results={filteredDeliveryMethod.map((deliveryMethod) => ({
        value: OrderMethodEnum[deliveryMethod],
        render: intl.formatMessage(CustomOrderMethodTitle[deliveryMethod]),
        name: intl.formatMessage(CustomOrderMethodTitle[deliveryMethod]),
      }))}
      clearSearch={clearFilterOptions}
    />
  )
}
