import { useModal } from "../../../../../../shared/hooks/useModal"
import Container from "../../../../../../ui/Container"
import DropdownAction from "../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../ui/Icon"
import Text from "../../../../../../ui/Typography/Text"
import notification from "../../../../../../ui/notification"
import type { ItemActionsType } from "../../../interfaces/interfaces"
import DeleteComboModal from "../modals/DeleteComboModal"
import DeleteDraftComboModal from "../modals/DeleteDraftComboModal"
import PublishComboModal from "../modals/PublishComboModal"
import { UnpublishComboModal } from "../modals/UnpublishComboModal/UnpublishComboModal"
import { Menu } from "antd"
import React, { Suspense } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type DropdawnComboProps = {
  comboUUID: string
  actions: Array<ItemActionsType>
  isCorporate?: boolean
  onOk?: () => void
}

const DropdownCombo: React.FC<DropdawnComboProps> = ({
  actions,
  comboUUID,
  isCorporate,
  onOk,
}) => {
  const intl = useIntl()

  const allowPublish = actions.includes("PUBLISH")
  const allowUnpublish = actions.includes("UNPUBLISH")
  const allowDelete = actions.includes("DELETE")
  const allowDeleteDraft = actions.includes("DELETE_DRAFT")
  const disableUnpublish = actions.includes("DISABLE_UNPUBLISH")

  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const [
    deleteModalVisible,
    showDeleteConfirmation,
    dismissDeleteConfirmation,
  ] = useModal()
  const [
    deleteDraftModalVisible,
    showDeleteDraftConfirmation,
    dismissDeleteDraftConfirmation,
  ] = useModal()
  const [
    unpublishModalVisible,
    showUnpublishConfirmation,
    dismissUnpublishConfirmation,
  ] = useModal()

  const onPublishOk = () => {
    dismissPublishConfirmation()
    onOk?.()
  }

  const onUnpublishOk = () => {
    dismissUnpublishConfirmation()
    onOk?.()
  }

  const onDeleteOk = () => {
    dismissDeleteConfirmation()
    onOk?.()
  }

  const onDeleteDraftOk = () => {
    dismissDeleteDraftConfirmation()
    onOk?.()
  }

  const onDeleteDraftClick = () => {
    if (comboUUID) return showDeleteDraftConfirmation()

    notification({
      title: intl.formatMessage({
        id: "restaurants.menu.items.combos.components.dropdown.combo.delete.draft.success.title",
        defaultMessage: "Combo deleted",
      }),
      description: intl.formatMessage({
        id: "restaurants.menu.items.combos.components.dropdown.combo.delete.draft.success.description",
        defaultMessage: "Your combo was deleted",
      }),
      type: "success",
    })

    onOk?.()
  }

  const onClickDropdownContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  return (
    <StyleDropdown
      className="dropdown-actions"
      display="flex"
      justifyContent="flex-end"
      onClick={onClickDropdownContainer}
    >
      <DropdownAction
        align={{ offset: [0, 13] }}
        placement="bottomRight"
        disabled={!comboUUID}
        overlay={
          <Menu role="menu-more-options-item" selectedKeys={[]}>
            <Menu.Item
              key="dropdown-combo-publish"
              icon={
                <Icon
                  remixiconClass="ri-arrow-up-circle-line"
                  color="Neutral8"
                />
              }
              hidden={!allowPublish}
              onClick={showPublishConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.combos.components.dropdown.publish.combo.option",
                  defaultMessage: "Publish",
                })}
              </Text>
            </Menu.Item>

            <Menu.Item
              key="dropdown-combo-unpublish"
              icon={
                <Icon
                  remixiconClass="ri-arrow-down-circle-line"
                  color="Neutral8"
                />
              }
              hidden={!allowUnpublish}
              disabled={disableUnpublish}
              onClick={showUnpublishConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.combos.components.dropdown.unpublish.combo.option",
                  defaultMessage: "Unpublish",
                })}
              </Text>
            </Menu.Item>
            <Menu.Item
              key="dropdown-combo-delete"
              icon={
                <Icon remixiconClass="ri-delete-bin-7-line" color="Neutral8" />
              }
              hidden={!allowDelete}
              onClick={showDeleteConfirmation}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.combos.components.dropdown.delete.combo.option",
                  defaultMessage: "Delete",
                })}
              </Text>
            </Menu.Item>
            <Menu.Item
              onClick={onDeleteDraftClick}
              key="dropdown-combo-delete-draft"
              icon={
                <Icon remixiconClass="ri-delete-bin-7-line" color="Neutral8" />
              }
              hidden={!allowDeleteDraft}
            >
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.menu.items.combos.components.dropdown.delete.combo.option",
                  defaultMessage: "Delete",
                })}
              </Text>
            </Menu.Item>
          </Menu>
        }
      >
        <Icon
          remixiconClass="ri-more-line"
          size={24}
          color={comboUUID ? "Primary5" : "Neutral5"}
          cursor="pointer"
        />
      </DropdownAction>
      <Suspense fallback={null}>
        {publishModalVisible && (
          <PublishComboModal
            comboUUID={comboUUID}
            onOk={onPublishOk}
            onCancel={dismissPublishConfirmation}
          />
        )}
        {unpublishModalVisible && (
          <UnpublishComboModal
            comboUUID={comboUUID}
            onOk={onUnpublishOk}
            onCancel={dismissUnpublishConfirmation}
            isCorporate={isCorporate}
          />
        )}
        {deleteModalVisible && (
          <DeleteComboModal
            comboUUID={comboUUID}
            onCancel={dismissDeleteConfirmation}
            onOk={onDeleteOk}
            isCorporate={isCorporate}
          />
        )}
        {deleteDraftModalVisible && (
          <DeleteDraftComboModal
            comboUUID={comboUUID}
            onCancel={dismissDeleteDraftConfirmation}
            onOk={onDeleteDraftOk}
          />
        )}
      </Suspense>
    </StyleDropdown>
  )
}

export default DropdownCombo

const StyleDropdown = styled(Container)`
  .anticon[disabled] {
    cursor: not-allowed;
  }
`
