import { LabelInformation } from "../../../../../../../ui/LabelInformation"
import { AcceptOrderModal } from "../../../Modals/AcceptOrderModal"
import { Button, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useIntl } from "react-intl"

interface AcceptCateringOrderProps {
  isPlacedAndRevision: boolean
}
export const AcceptCateringOrder = ({
  isPlacedAndRevision,
}: AcceptCateringOrderProps) => {
  const intl = useIntl()

  const [openedAcceptOrder, { close, open }] = useDisclosure()

  return (
    <Stack spacing={16}>
      <LabelInformation
        label={intl.formatMessage({
          id: "restaurants.catering.detail.card.order.acceptance.label",
          defaultMessage: "Order acceptance",
        })}
        labelWeight={600}
        description={intl.formatMessage(
          {
            id: "restaurants.catering.detail.card.order.management.description",
            defaultMessage:
              "Order pending until you either accept or decline it. If accepted, your location will be in charge of fulfilling it; if declined, corporate will reassign it to a different location. {br} This action cannot be undone, so please be certain.",
          },
          {
            br: <br />,
          }
        )}
        descriptionSize="sm"
      />
      <Button
        variant="outline"
        color="violet.6"
        onClick={open}
        disabled={!isPlacedAndRevision}
      >
        {intl.formatMessage({
          id: "restaurants.catering.detail.card.order.management.accept.order.button",
          defaultMessage: "Accept order",
        })}
      </Button>
      <AcceptOrderModal opened={openedAcceptOrder} onClose={close} />
    </Stack>
  )
}
