import { IntlFormattersType } from "../../../../../shared/types"

export const REQUIRED_FIELD_ERROR_ID = "generic.error.required.field"
export const MAX_VALUE_100_ERROR_ID = "generic.error.max.value.100"
export const MORE_THAN_ZERO_ERROR_ID = "generic.error.more.than.0"
export const MAX_VALUE_INPUT_ERROR_ID = "generic.error.max.value.input"

export const CODE_INPUT_DESCRIPTION_ID =
  "restaurants.discount.forms.generic.input.code.description"
export const CODE_EXISTS_ERROR_ID =
  "restaurants.discount.forms.generic.input.code.error.exists"

export const validationsTitle: IntlFormattersType = {
  [REQUIRED_FIELD_ERROR_ID]: {
    id: REQUIRED_FIELD_ERROR_ID,
    defaultMessage: "This field is required",
  },
  [MAX_VALUE_100_ERROR_ID]: {
    id: MAX_VALUE_100_ERROR_ID,
    defaultMessage: "Max value is 100",
  },
  [MORE_THAN_ZERO_ERROR_ID]: {
    id: MORE_THAN_ZERO_ERROR_ID,
    defaultMessage: "Min value is 0",
  },
  [CODE_INPUT_DESCRIPTION_ID]: {
    id: CODE_INPUT_DESCRIPTION_ID,
    defaultMessage:
      "Minimum 4 characters. Letters and numbers allowed, no spaces or special characters.",
  },
  [CODE_EXISTS_ERROR_ID]: {
    id: CODE_EXISTS_ERROR_ID,
    defaultMessage: "This discount code has already been used. Try another.",
  },
  [MAX_VALUE_INPUT_ERROR_ID]: {
    id: MAX_VALUE_INPUT_ERROR_ID,
    defaultMessage: "Max value is {maxValue}",
  },
}
