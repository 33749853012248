import { EMPTY_STRING } from "../../../../../../shared/utils/constant/values"
import { InputNumber } from "../../../../../../ui/Inputs"
import Spacer from "../../../../../../ui/Spacer"
import { MAX_INPUT_VALUE } from "../../GenericForm/constants"
import { validationsTitle } from "../../GenericForm/validations.title"
import { useLifeTimeSpend } from "./hooks/useLifeTimeSpend"
import { useIntl } from "react-intl"

export const LifeTimeSpend = () => {
  const {
    onLifeTimeSpendChange,
    errorMessage,
    lifeTimeSpendAmount,
    isEditMode,
  } = useLifeTimeSpend()

  const intl = useIntl()

  const bySpendingLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.eligible.customer.by.spend.input.label",
    defaultMessage: "By Spending",
  })

  const tooltipLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.eligible.customer.by.spend.tooltip.label",
    defaultMessage: "Total spent at the restaurant",
  })

  const currencySymbol = intl.formatMessage({
    id: "generic.symbol.currency",
    defaultMessage: "$",
  })

  return (
    <>
      <InputNumber
        helperText={
          errorMessage
            ? intl.formatMessage(validationsTitle[errorMessage], {
                maxValue: MAX_INPUT_VALUE,
              })
            : EMPTY_STRING
        }
        hasError={!!errorMessage}
        disabled={isEditMode}
        onChange={onLifeTimeSpendChange}
        label={bySpendingLabel}
        precision={2}
        requirement="required"
        tooltipTitle={tooltipLabel}
        prefix={currencySymbol}
        value={lifeTimeSpendAmount}
      />
      {errorMessage && <Spacer size={26} />}
    </>
  )
}
