import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const ComboFormSchema = yup.object().shape({
  name: yup.string().required("Name it"),
  comboItemGroups: yup
    .array()
    .min(1, "At least add one combo item")
    .of(
      yup.object().shape({
        name: yup.string().required("Name your combo item"),
        defaultComboItem: yup.number().required("Set a default item"),
      })
    ),
})

export const ComboFormResolver = yupResolver(ComboFormSchema)
