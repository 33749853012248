import type { IAddress } from "../../../../components/AutocompleteAddress"
import AutocompleteAddress from "../../../../components/AutocompleteAddress"
import LocationMap from "../../../../components/LocationMap/LocationMap"
import { device } from "../../../../shared/breakpoints/responsive"
import { GoogleMapsProvider } from "../../../../shared/contexts/GoogleMapsProvider"
import { useOnboardingContext } from "../../../../shared/contexts/OnboardingProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import type { UpdateRestaurantAttachmentInput } from "../../../../shared/graphql/generated/types"
import { validationsTitle } from "../../../../shared/titles/validations.title"
import { EMPTY_STRING } from "../../../../shared/utils/constant/values"
import formatPhoneNumber from "../../../../shared/utils/helpers/formatPhoneNumber"
import { parsePhoneNumber } from "../../../../shared/utils/helpers/parsePhoneNumber"
import Container from "../../../../ui/Container"
import InputLabel from "../../../../ui/InputLabel"
import Input from "../../../../ui/Inputs"
import InputHelper from "../../../../ui/Inputs/InputHelper"
import Radio, { RadioGroup } from "../../../../ui/Radio"
import Select, { OptionSelect } from "../../../../ui/Select"
import Spacer from "../../../../ui/Spacer"
import notification from "../../../../ui/notification"
import { useUpsertRestaurantSnapshotMutation } from "../../GraphQL/UpsertRestaurantSnapshot.generated"
import CheckableTagGroup from "../../components/CheckableTagGroup"
import StepperHandler from "../../components/StepperHandler"
import StepsFooter from "../../components/StepsFooter"
import { IndicatorsStateType } from "../../components/StepsFooter/config"
import { StepsType } from "../../interfaces"
import { OnboardingRestaurantResolver } from "./OnboardingLocationForm.yup"
import StateAndCities from "./states.json"
import { formatAddress } from "./utils/format-address.util"
import type { LocationFormType } from "./utils/hookforms.interfaces"
import { FormProvider } from "antd/lib/form/context"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { useMemo, useState } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import styled from "styled-components"

const LocationsQuantityOptions = [
  { value: "1-9" },
  { value: "10+" },
  { value: "50+" },
]

type OnboardingLocationFormProps = {
  onPrevious: () => void
  onNext: () => void
  stepFooter: StepsType
  indicators: IndicatorsStateType[]
}

const OnboardingLocationForm: React.FC<OnboardingLocationFormProps> = ({
  onPrevious,
  onNext,
  stepFooter,
  indicators,
}) => {
  const [selectedState, setSelectedState] = useState<string>()
  const intl = useIntl()
  const { onboarding, updateOnboardingState } = useOnboardingContext()
  const [upsertRestaurantSnapshot, { loading }] =
    useUpsertRestaurantSnapshotMutation()
  const { mapWithPin } = useFlags()

  const {
    state: {
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const locationFormMethods = useForm<LocationFormType>({
    resolver: OnboardingRestaurantResolver,
    defaultValues: {
      autocompleteAddress: true,
      locationsQuantity: onboarding?.locationsQuantity,
      name: onboarding.location.name,
      searchAddress: {
        latitude: onboarding.location.latitude,
        longitude: onboarding.location.longitude,
        address: onboarding.location.address,
      },
      manualAddress: {
        line1: onboarding.location.addressLine1,
        line2: onboarding.location.addressLine2,
        city: onboarding.location.city,
        state: onboarding.location.state,
        zipCode: onboarding.location.postalCode,
      },
    },
  })

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = locationFormMethods

  const [autocompleteAddress, latitude, longitude] = useWatch({
    control,
    name: [
      "autocompleteAddress",
      "searchAddress.latitude",
      "searchAddress.longitude",
    ],
  })

  const cityOptions = useMemo<Array<{ name: string; stateName?: string }>>(
    () =>
      selectedState
        ? StateAndCities.find((state) => state.name === selectedState)
            ?.cities ?? []
        : StateAndCities.flatMap((state) =>
            state.cities.map((city) => ({ ...city, stateName: state.name }))
          ),
    [selectedState]
  )

  const onSelectAddress = (value: IAddress) => {
    setValue("searchAddress.address", value?.address ?? "")
    setValue("searchAddress.longitude", value.longitude)
    setValue("searchAddress.latitude", value.latitude)
    setValue("searchAddress.addressComponents", value.addressComponents)
    updateOnboardingState({ location: { ...value } })
  }

  const validateAddress = () => {
    trigger(["searchAddress.address", "searchAddress.addressComponents"])
  }

  const onNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (T: string) => void
  ) => {
    onChange(event.target.value)
    updateOnboardingState({ location: { name: event.target.value } })
  }

  const onFormSubmit = async (data: LocationFormType) => {
    if (!isDirty) {
      return onNext()
    }

    try {
      let manualLatitude = ""
      let manualLongitude = ""
      const manualAddress = !data.autocompleteAddress

      if (manualAddress) {
        const locationInfo = await geocodeByAddress(
          formatAddress(data.manualAddress)
        )

        if (locationInfo[0].geometry.location_type !== "ROOFTOP") {
          throw new Error(
            intl.formatMessage({
              id: "onboarding.error.inaccurate.location.message",
              defaultMessage:
                "Couldn't get precise location, please review your data or try with the search address option.",
            })
          )
        }
        const latAndLng = await getLatLng(locationInfo[0])
        manualLatitude = latAndLng.lat.toString()
        manualLongitude = latAndLng.lng.toString()
      }

      const autocompleteComponents =
        getValues("searchAddress").addressComponents

      const state = autocompleteComponents?.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name

      const locality = autocompleteComponents?.find((component) =>
        component.types.includes("locality")
      )?.short_name

      const sublocality = autocompleteComponents?.find((component) =>
        component.types.includes("sublocality")
      )?.short_name
      const city = sublocality ?? locality

      const postalCode = autocompleteComponents?.find((component) =>
        component.types.includes("postal_code")
      )?.short_name

      const locationFormData = {
        locationsQuantity: data.locationsQuantity,
        currentStep: 3,
        location: {
          address: manualAddress
            ? formatAddress(data.manualAddress)
            : data.searchAddress.address,
          ...(manualAddress && { addressLine1: data.manualAddress.line1 }),
          ...(data.manualAddress.line2 &&
            manualAddress && { addressLine2: data.manualAddress.line2 }),
          phone: parsePhoneNumber(data.phone).number,
          city: manualAddress ? data.manualAddress.city : city,
          name: data.name,
          postalCode: manualAddress ? data.manualAddress.zipCode : postalCode,
          state: manualAddress ? data.manualAddress.state : state,
          latitude: manualAddress
            ? manualLatitude
            : data.searchAddress.latitude,
          longitude: manualAddress
            ? manualLongitude
            : data.searchAddress.longitude,
        },
      }

      await upsertRestaurantSnapshot({
        variables: {
          data: {
            snapshot: {
              ...locationFormData,
              brandColor: onboarding.brandColor,
              logos: onboarding.logos?.map((logo) => ({
                contentType: logo.contentType,
                ext: logo.ext,
                fileName: logo.fileName,
                type: logo.type,
                uuid: logo.uuid,
              })) as UpdateRestaurantAttachmentInput[],
              menuAttachments: onboarding.menuAttachments,
              name: onboarding.name,
              notes: onboarding.notes,
              urlIdentifier: onboarding.urlIdentifier,
            },
            userUUID,
          },
        },
      })

      updateOnboardingState({ ...locationFormData })
      reset({}, { keepValues: true })
    } catch (error) {
      return notification({
        type: "error",
        key: "address.unaccuracy",
        description: (error as Error).message,
      })
    }
    onNext()
  }
  const setLatitude = (lat: number) =>
    setValue("searchAddress.latitude", `${lat}`, { shouldTouch: true })

  const setLongitude = (lng: number) =>
    setValue("searchAddress.longitude", `${lng}`, { shouldTouch: true })

  return (
    <FormProvider {...locationFormMethods}>
      <Container
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Container>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                {...rest}
                onChange={(event) => onNameChange(event, onChange)}
                label={intl.formatMessage({
                  id: "onboarding.forms.location.form.name.label",
                  defaultMessage: "Location Name",
                })}
                requirement="required"
                hasError={!!errors.name}
                helperText={errors.name?.message}
                helperSize="s"
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            control={control}
            name="phone"
            render={({ field: phoneNumberField }) => (
              <Input
                {...phoneNumberField}
                label={intl.formatMessage({
                  id: "restaurants.locations.overview.phone.number.label",
                  defaultMessage: "Location Phone Number",
                })}
                requirement="required"
                aria-label="phone-number"
                value={formatPhoneNumber(phoneNumberField.value)}
                hasError={!!errors?.phone}
                helperText={
                  errors?.phone?.message
                    ? intl.formatMessage(validationsTitle[errors.phone.message])
                    : EMPTY_STRING
                }
                helperSize="s"
                prefix="+"
                onlyInteger
              />
            )}
          />
          <Spacer size={45} />
          <Controller
            name="autocompleteAddress"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <RadioGroup flexFlow="row" {...field}>
                <Radio
                  name="test"
                  $classId="address-input-type-radio"
                  value={true}
                  size="m"
                  content={intl.formatMessage({
                    id: "onboarding.forms.location.form.search.address.radio.label",
                    defaultMessage: "Search address",
                  })}
                />
                <Radio
                  name="test"
                  $classId="address-input-type-radio"
                  value={false}
                  size="m"
                  content={intl.formatMessage({
                    id: "onboarding.forms.location.form.manual.address.radio.label",
                    defaultMessage: "Enter address manually",
                  })}
                />
              </RadioGroup>
            )}
          />

          <Spacer size={5} />
          {autocompleteAddress ? (
            <>
              <Spacer size={16} />
              <GoogleMapsProvider>
                {mapWithPin && (
                  <>
                    <LocationMap
                      position={{ lat: latitude, lng: longitude }}
                      coordinatesSetters={{ setLatitude, setLongitude }}
                      validateAddress={() => trigger("searchAddress.address")}
                    />
                    <Spacer size={8} />
                  </>
                )}
                <Controller
                  key="searchAddress.address"
                  name="searchAddress.address"
                  control={control}
                  render={({ field }) => (
                    <Container width="100%" padding="0 1px">
                      <AutocompleteAddress
                        name="searchAddress.address"
                        inputProps={{
                          ...field,
                          name: "address",
                          maxTagTextLength: 48,
                          placeholder: intl.formatMessage({
                            id: "onboarding.forms.location.form.search.address.placeholder",
                            defaultMessage: "Type your address",
                          }),
                          hasError: !!errors.searchAddress?.address,
                          helperText: errors.searchAddress?.address?.message,
                        }}
                        onSelect={onSelectAddress}
                        validateAddress={validateAddress}
                        error={errors.searchAddress?.address}
                      />
                    </Container>
                  )}
                />
                <Spacer size={4} />
                <Container position="relative" width="100%" height="20px">
                  {!errors.searchAddress?.address && (
                    <InputHelper size="s" weight="regular">
                      {intl.formatMessage({
                        defaultMessage: "This field is required",
                        id: "onboarding.form.location.form.search.address.helper",
                      })}
                    </InputHelper>
                  )}
                </Container>
              </GoogleMapsProvider>
            </>
          ) : (
            <>
              <Spacer size={16} />
              <Controller
                key="manualAddress"
                name="manualAddress.line1"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={intl.formatMessage({
                      id: "onboarding.forms.location.form.address.line1.label",
                      defaultMessage: "Address line 1",
                    })}
                    requirement="required"
                    hasError={!!errors.manualAddress?.line1}
                    helperText={errors.manualAddress?.line1?.message}
                    helperSize="s"
                  />
                )}
              />
              <Spacer size={24} />
              <Controller
                name="manualAddress.line2"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={intl.formatMessage({
                      id: "onboarding.forms.location.form.address.line2.label",
                      defaultMessage: "Address line 2",
                    })}
                    requirement="optional"
                    hasError={!!errors.manualAddress?.line2}
                    helperText={errors.manualAddress?.line2?.message}
                    helperSize="s"
                  />
                )}
              />
              <Spacer size={24} />
              <AddressCompsContainer>
                <SelectWrapper>
                  <InputLabel
                    label={intl.formatMessage({
                      id: "onboarding.forms.location.form.address.state.label",
                      defaultMessage: "State",
                    })}
                    requirement="required"
                  />
                  <Controller
                    name="manualAddress.state"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        width="160px"
                        requirement="required"
                        hideSearchIcon
                        hasError={!!errors.manualAddress?.state}
                        helperText={errors.manualAddress?.state?.message}
                        onChange={(value: string) => {
                          field.onChange(value)
                          setValue("manualAddress.city", "")
                        }}
                        onSelect={(value: string) => setSelectedState(value)}
                        listHeight={150}
                        allowSearch
                      >
                        {StateAndCities.map((state) => {
                          return (
                            <OptionSelect key={state.name} value={state.name}>
                              {state.name}
                            </OptionSelect>
                          )
                        })}
                      </Select>
                    )}
                  />
                </SelectWrapper>
                <SelectWrapper>
                  <InputLabel
                    label={intl.formatMessage({
                      id: "onboarding.forms.location.form.address.city.label",
                      defaultMessage: "City",
                    })}
                    requirement="required"
                  />
                  <Controller
                    name="manualAddress.city"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        width="160px"
                        hideSearchIcon
                        requirement="required"
                        hasError={!!errors.manualAddress?.city}
                        helperText={errors.manualAddress?.city?.message}
                        onChange={(value: string) => {
                          field.onChange(value)
                          if (!selectedState) {
                            const state = cityOptions.find(
                              (city) => city.name === value
                            )
                            setValue(
                              "manualAddress.state",
                              state?.stateName ?? ""
                            )
                          }
                        }}
                        listHeight={150}
                        allowSearch
                      >
                        {cityOptions.map((state) => {
                          return (
                            <OptionSelect key={state.name} value={state.name}>
                              {state.name}
                            </OptionSelect>
                          )
                        })}
                      </Select>
                    )}
                  />
                </SelectWrapper>
                <Controller
                  name="manualAddress.zipCode"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={intl.formatMessage({
                        id: "onboarding.forms.location.form.address.zip.code.label",
                        defaultMessage: "Zip code",
                      })}
                      requirement="required"
                      hasError={!!errors.manualAddress?.zipCode}
                      helperText={errors.manualAddress?.zipCode?.message}
                      helperSize="s"
                    />
                  )}
                />
              </AddressCompsContainer>
            </>
          )}
          <Spacer size={40} />
          <InputLabel
            requirement="required"
            label={intl.formatMessage({
              id: "onboarding.form.location.form.locations.quantity.label",
              defaultMessage: "How many locations does your restaurant have?",
            })}
          />
          <Controller
            control={control}
            name="locationsQuantity"
            render={({ field: { onChange, value } }) => (
              <CheckableTagGroup
                items={LocationsQuantityOptions}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Spacer size={4} />
          <Container position="relative" width="100%" height="20px">
            <InputHelper
              hidden={!errors.locationsQuantity}
              size="s"
              weight="regular"
              $hasError={!!errors.locationsQuantity}
            >
              {intl.formatMessage({
                defaultMessage: "Required value",
                id: "onboarding.form.location.form.location.quantity.helper",
              })}
            </InputHelper>
          </Container>
        </Container>
        <Container>
          <StyledSteps>
            <StepFooterContent>
              <StepsFooter indicatorsStates={indicators} step={stepFooter} />
            </StepFooterContent>
            <StepperHandler
              onPrevious={onPrevious}
              onNext={handleSubmit(onFormSubmit)}
              loading={loading || onboarding.loading}
            />
          </StyledSteps>
        </Container>
      </Container>
    </FormProvider>
  )
}

export default OnboardingLocationForm

const AddressCompsContainer = styled.div`
  display: flex;
  padding: 1px;
  gap: 24px;
  @media ${device.lg} {
    flex-wrap: wrap;
  }
`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${device.lg} {
    justify-content: space-between;
    margin-top: 32px;
  }
`

const StepFooterContent = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`
