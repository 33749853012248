import Container from "../../../../ui/Container"
import { InputFieldSkeleton } from "../../../../ui/Inputs"
import Spacer from "../../../../ui/Spacer"
import React from "react"
import styled, { css } from "styled-components"

export const OrderDetailSkeleton: React.FC = () => {
  return (
    <StyledOrderDeatilContainer role="order-detail-skeleton">
      {/* Order Information */}
      <OrderInformationSkeleton />
      <Spacer size={32} />
      {/* Order Customer */}
      <OrderCustomerInformationSkeleton />
      <Spacer size={32} />
      {/* Order Summary */}
      <OrderSummarySkeleton />
      <Spacer size={32} />
      {/* Order Additional Info*/}
      <OrderAdditionalInfoSkeleton />
    </StyledOrderDeatilContainer>
  )
}

export const OrderInformationSkeleton = () => {
  return (
    <Container role="order-detail-information-skeleton">
      <InputFieldSkeleton size="default" />
      <Spacer size={24} />
      <StyledSummaryGrid>
        {Array.from({ length: 3 })
          .fill({})
          .map((_, rowIndex) => (
            <StyledSummaryRow key={rowIndex}>
              <Container
                display="flex"
                gap="8px"
                flexDirection="column"
                width="50%"
              >
                <InputFieldSkeleton size="small" width="60px" />
                <InputFieldSkeleton size="small" width="150px" />
              </Container>

              <Container
                display="flex"
                gap="8px"
                flexDirection="column"
                width="50%"
              >
                <InputFieldSkeleton size="small" width="60px" />
                <InputFieldSkeleton size="small" width="150px" />
              </Container>
            </StyledSummaryRow>
          ))}
      </StyledSummaryGrid>
      <Spacer size={24} />
      <Container display="flex" gap="8px" flexDirection="column" width="50%">
        <InputFieldSkeleton size="small" width="60px" />
        <InputFieldSkeleton size="small" width="150px" />
      </Container>
    </Container>
  )
}

export const OrderCustomerInformationSkeleton = () => {
  return (
    <Container role="order-detail-customer-skeleton">
      <InputFieldSkeleton size="default" />
      <Spacer size={16} />
      <Container display="flex" flexDirection="column" gap="32px">
        {Array.from({ length: 1 })
          .fill({})
          .map((_, rowIndex) => (
            <StyledSummaryRow key={rowIndex}>
              <Container
                display="flex"
                gap="8px"
                flexDirection="column"
                width="50%"
              >
                <InputFieldSkeleton size="small" width="60px" />
                <InputFieldSkeleton size="small" width="150px" />
              </Container>

              <Container
                display="flex"
                gap="8px"
                flexDirection="column"
                width="50%"
              >
                <InputFieldSkeleton size="small" width="60px" />
                <InputFieldSkeleton size="small" width="150px" />
              </Container>
            </StyledSummaryRow>
          ))}
      </Container>
    </Container>
  )
}

export const OrderSummarySkeleton = () => {
  return (
    <Container
      display="flex"
      flexDirection="column"
      role="order-detail-summary-skeleton"
    >
      <InputFieldSkeleton size="default" />
      <Spacer size={24} />
      <Container display="flex" flexDirection="column" gap="8px">
        {Array.from({ length: 3 })
          .fill({})
          .map((_, containerIndex) => (
            <StyledOrderSummary key={containerIndex}>
              <InputFieldSkeleton size="small" width="65%" />
            </StyledOrderSummary>
          ))}
      </Container>
    </Container>
  )
}

export const OrderAdditionalInfoSkeleton = () => {
  return (
    <Container
      display="flex"
      flexDirection="column"
      role="order-detail-additional-info-skeleton"
    >
      <InputFieldSkeleton size="default" />
      <Spacer size={24} />
      <InputFieldSkeleton size="small" width="55%" />
      <Spacer size={8} />
      <InputFieldSkeleton size="small" width="65%" />
    </Container>
  )
}

const StyledOrderDeatilContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  overflow: auto;
`

const StyledSummaryGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const StyledSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: hidden;
`

const StyledOrderSummary = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.Neutral1};
    border: 1px solid ${theme.colors.Neutral4};
    border-radius: 4px;
    padding: 12px 16px;
  `}
`
