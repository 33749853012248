import { CateringServiceEnum } from "../../../Settings/Locations/Catering/shared/hookforms.intefaces"

export enum DeliveryIntegrationEnum {
  DOORDASH = "DOORDASH",
  ONFLEET = "ONFLEET",
}

export enum OrderMethodEnum {
  CATERING = "CATERING",
  CURBSIDE = "CURBSIDE",
  DELIVERY = "DELIVERY",
  DINE_IN = "DINE_IN",
  DRIVE_THRU = "DRIVE_THRU",
  TAKE_OUT = "TAKE_OUT",
}

export enum OrderStatusEnum {
  CANCELLED = "CANCELLED",
  CHECK_OUT = "CHECK_OUT",
  CLOSED = "CLOSED",
  COMPLETED = "COMPLETED",
  DELIVERY_FAILED = "DELIVERY_FAILED",
  IN_REVISION = "IN_REVISION",
  OPEN = "OPEN",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PENDING = "PENDING",
  PENDING_REASSIGN = "PENDING_REASSIGN",
  PICKED_UP = "PICKED_UP",
  PLACED = "PLACED",
  PREPARING = "PREPARING",
  READY = "READY",
  REFUNDED = "REFUNDED",
}

export type CateringParams = {
  orderUUID: string
  locationUUID: string
}

export type CateringOrderInformationType = {
  uuid: string
  expiresAt?: Date | null
  notes?: string | null
  createdAt: Date
  orderMethod: OrderMethodEnum
  orderNumber: string
  isReported: boolean
  status: OrderStatusEnum
  subTotal: number
  total: number
  totalTaxes: number
  catering?: {
    headCount?: number | null
    placedAt?: Date | null
    serviceType?: CateringServiceEnum | null
  } | null
  customer: {
    user: {
      uuid: string
      firstName?: string | null
      lastName?: string | null
      phone?: string | null
    }
  }
  deliveryIntegration?: {
    uuid: string
    integrationLabel: string
    integrationName: DeliveryIntegrationEnum
  } | null
}
