import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrdersByRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate: Types.Scalars['DateTime'];
  endDate: Types.Scalars['DateTime'];
  userUUIDs?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  locationUUIDs?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  orderMethods?: Types.InputMaybe<Array<Types.OrderMethodEnum> | Types.OrderMethodEnum>;
  status?: Types.InputMaybe<Array<Types.OrderStatusEnum> | Types.OrderStatusEnum>;
  paymentMethodTypes?: Types.InputMaybe<Array<Types.PaymentMethodTypeEnum> | Types.PaymentMethodTypeEnum>;
}>;


export type GetOrdersByRestaurantQuery = { __typename?: 'Query', getOrdersByRestaurant: { __typename?: 'PaginatedOrderInformationModel', hasNextPage: boolean, endCursor?: string | null, filters: { __typename?: 'OrderFilterModel', orderMethods: Array<Types.OrderMethodEnum>, status: Array<Types.OrderStatusEnum>, paymentMethodTypes: Array<Types.PaymentMethodTypeEnum>, customers: Array<{ __typename?: 'CustomerWithUserBasicModel', uuid: string, user: { __typename?: 'UserBasicModel', uuid: string, firstName?: string | null, lastName?: string | null, phone?: string | null } }>, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> }, results?: Array<{ __typename?: 'OrderInformationModel', uuid: string, expiresAt?: any | null, notes?: string | null, createdAt: any, orderMethod: Types.OrderMethodEnum, orderNumber: string, isReported: boolean, status: Types.OrderStatusEnum, subTotal: number, total: number, totalTaxes: number, paymentMethodType?: Types.PaymentMethodTypeEnum | null, paymentStatus: Types.OrderPaymentStatusEnum, customer: { __typename?: 'CustomerWithUserBasicModel', uuid: string, user: { __typename?: 'UserBasicModel', uuid: string, firstName?: string | null, lastName?: string | null, phone?: string | null } }, deliveryIntegration?: { __typename?: 'DeliveryIntegrationBasicModel', uuid: string, integrationLabel: string, integrationName: Types.DeliveryIntegrationEnum } | null, location: { __typename?: 'LocationBasicModel', uuid: string, name: string }, locationTable?: { __typename?: 'TableBasicModel', uuid: string, name: string } | null }> | null } };


export const GetOrdersByRestaurantDocument = gql`
    query getOrdersByRestaurant($restaurantUUID: ID!, $after: String, $take: Int, $startDate: DateTime!, $endDate: DateTime!, $userUUIDs: [ID!], $locationUUIDs: [ID!], $orderMethods: [OrderMethodEnum!], $status: [OrderStatusEnum!], $paymentMethodTypes: [PaymentMethodTypeEnum!]) {
  getOrdersByRestaurant(
    restaurantUUID: $restaurantUUID
    after: $after
    take: $take
    startDate: $startDate
    endDate: $endDate
    userUUIDs: $userUUIDs
    locationUUIDs: $locationUUIDs
    orderMethods: $orderMethods
    status: $status
    paymentMethodTypes: $paymentMethodTypes
  ) {
    hasNextPage
    endCursor
    filters {
      customers {
        uuid
        user {
          uuid
          firstName
          lastName
          phone
        }
      }
      locations {
        uuid
        name
      }
      orderMethods
      status
      paymentMethodTypes
    }
    results {
      uuid
      customer {
        uuid
        user {
          uuid
          firstName
          lastName
          phone
        }
      }
      deliveryIntegration {
        uuid
        integrationLabel
        integrationName
      }
      expiresAt
      notes
      location {
        uuid
        name
      }
      locationTable {
        uuid
        name
      }
      createdAt
      orderMethod
      orderNumber
      isReported
      status
      subTotal
      total
      totalTaxes
      paymentMethodType
      paymentStatus
    }
  }
}
    `;

/**
 * __useGetOrdersByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetOrdersByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      after: // value for 'after'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      userUUIDs: // value for 'userUUIDs'
 *      locationUUIDs: // value for 'locationUUIDs'
 *      orderMethods: // value for 'orderMethods'
 *      status: // value for 'status'
 *      paymentMethodTypes: // value for 'paymentMethodTypes'
 *   },
 * });
 */
export function useGetOrdersByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersByRestaurantQuery, GetOrdersByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersByRestaurantQuery, GetOrdersByRestaurantQueryVariables>(GetOrdersByRestaurantDocument, options);
      }
export function useGetOrdersByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersByRestaurantQuery, GetOrdersByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersByRestaurantQuery, GetOrdersByRestaurantQueryVariables>(GetOrdersByRestaurantDocument, options);
        }
export type GetOrdersByRestaurantQueryHookResult = ReturnType<typeof useGetOrdersByRestaurantQuery>;
export type GetOrdersByRestaurantLazyQueryHookResult = ReturnType<typeof useGetOrdersByRestaurantLazyQuery>;
export type GetOrdersByRestaurantQueryResult = Apollo.QueryResult<GetOrdersByRestaurantQuery, GetOrdersByRestaurantQueryVariables>;