import Button from "../Button"
import React from "react"

type CopyToClipboardButtonProps = {
  content: string
  copyTextLabel: string
  copiedTextLabel?: string
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  content,
  copyTextLabel,
  copiedTextLabel,
}) => {
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false)

  const onCopy = async () => {
    await navigator.clipboard.writeText(content)

    setLinkCopied(true)

    setTimeout(() => {
      setLinkCopied(false)
    }, 1000)
  }

  return (
    <Button
      transparent
      hierarchy="tertiary"
      onClick={onCopy}
      style={linkCopied ? { pointerEvents: "none" } : undefined}
      leadingIcon={linkCopied ? "ri-checkbox-circle-fill" : "ri-file-copy-line"}
    >
      {linkCopied && copiedTextLabel ? copiedTextLabel : copyTextLabel}
    </Button>
  )
}
