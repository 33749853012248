import { MantineCard } from "../../../../../../ui/MantineCard"
import { CancelCatering } from "./CancelCatering"
import { RefundCatering } from "./RefundCatering"
import { Stack } from "@mantine/core"
import { useIntl } from "react-intl"

interface DangerZoneProps {
  refundable?: boolean
  cancelable?: boolean
}

export const DangerZone = ({ refundable, cancelable }: DangerZoneProps) => {
  const intl = useIntl()

  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.danger.zone.title",
        defaultMessage: "Danger zone",
      })}
    >
      <Stack spacing={16}>
        <CancelCatering isCancelable={!!cancelable} />
        {refundable && <RefundCatering />}
      </Stack>
    </MantineCard>
  )
}
