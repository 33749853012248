import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSummaryReportQueryVariables = Types.Exact<{
  endDate: Types.Scalars['DateTime'];
  startDate: Types.Scalars['DateTime'];
}>;


export type GetSummaryReportQuery = { __typename?: 'Query', getSummaryReport: { __typename?: 'SummaryReport', customerSupportDetail: { __typename?: 'CustomerSupportDetail', discounts: number, loyalty: number, refunds: number, total: number }, salesByPaymentType: { __typename?: 'SalesByPaymentType', card: number, cash: number, total: number }, salesSummaryDetail: { __typename?: 'SalesSummaryDetail', deliveryFees: number, grossSales: number, netSales: number, serviceFees: number, taxes: number, tips: number } } };


export const GetSummaryReportDocument = gql`
    query GetSummaryReport($endDate: DateTime!, $startDate: DateTime!) {
  getSummaryReport(endDate: $endDate, startDate: $startDate) {
    customerSupportDetail {
      discounts
      loyalty
      refunds
      total
    }
    salesByPaymentType {
      card
      cash
      total
    }
    salesSummaryDetail {
      deliveryFees
      grossSales
      netSales
      serviceFees
      taxes
      tips
    }
  }
}
    `;

/**
 * __useGetSummaryReportQuery__
 *
 * To run a query within a React component, call `useGetSummaryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryReportQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetSummaryReportQuery(baseOptions: Apollo.QueryHookOptions<GetSummaryReportQuery, GetSummaryReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSummaryReportQuery, GetSummaryReportQueryVariables>(GetSummaryReportDocument, options);
      }
export function useGetSummaryReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSummaryReportQuery, GetSummaryReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSummaryReportQuery, GetSummaryReportQueryVariables>(GetSummaryReportDocument, options);
        }
export type GetSummaryReportQueryHookResult = ReturnType<typeof useGetSummaryReportQuery>;
export type GetSummaryReportLazyQueryHookResult = ReturnType<typeof useGetSummaryReportLazyQuery>;
export type GetSummaryReportQueryResult = Apollo.QueryResult<GetSummaryReportQuery, GetSummaryReportQueryVariables>;