interface UserBase {
  firstName?: string | null
  lastName?: string | null
  uuid: string
}

interface User extends UserBase {
  phone: string
}

interface IsValidUser extends UserBase {
  phone?: string | null
}

export const isValidUser = (user: IsValidUser): user is User => {
  return typeof user.phone === "string" && !!user.uuid
}
