import type { RMenuDetail } from "../../../../shared/graphql/generated/types"
import Breadcrumb, { breadcrumbs } from "../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import ModalFull from "../../../../ui/ModalFull"
import CorporateDetailHeader from "../../components/CorporateDetailHeader"
import { useMenus } from "../hooks/useMenus"
import MenuDetail from "./MenuDetail"
import { useState } from "react"

type CorporateMenuDetailProps = {
  menu: RMenuDetail
}

const CorporateMenuDetail = ({ menu }: CorporateMenuDetailProps) => {
  const [isVisible, setIsVisible] = useState(!!menu.isVisible)
  const [hidden, setHidden] = useState(!!menu.isVisible)
  const { showOrHideMenu, menuHiddenOrShown } = useMenus({})

  const isDirty = hidden !== isVisible

  const onSave = () => {
    showOrHideMenu(
      menu.uuid,
      isVisible,
      () => {
        setIsVisible((prev) => !prev)
      },
      showGraphqlErrors
    )
  }

  const onHideClick = () => {
    setHidden((prev) => !prev)
  }

  return (
    <ModalFull
      headerColor="Neutral2"
      title={
        <CorporateDetailHeader
          loadingSave={menuHiddenOrShown.loading}
          enablePreview={true}
          enableSave={isDirty}
          lastSavedAt={menu.updatedAt}
          lastPublishedAt={menu.publishedAt}
          onSave={onSave}
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.CorporateMenuDetail}
            pageName={menu.name}
          />
        </CorporateDetailHeader>
      }
      visible
    >
      <MenuDetail
        hidden={hidden}
        isDirty={isDirty}
        menu={menu}
        onHideClick={onHideClick}
      />
    </ModalFull>
  )
}

export default CorporateMenuDetail
