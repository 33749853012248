import type { OrderStatusEnum } from "../../../../shared/graphql/generated/types"
import { CustomOrderStatusTitle } from "../../../../shared/titles/orderStatus.title"
import firstLetterToUpperCase from "../../../../shared/utils/helpers/firstLetterToUpperCase"
import Container from "../../../../ui/Container"
import Text from "../../../../ui/Typography/Text"
import getBadgeColor from "../utils/get-badge-color.util"
import { Badge } from "antd"
import React from "react"
import { useIntl } from "react-intl"

interface OrderStatusFieldProps {
  status: `${OrderStatusEnum}`
}

export const OrderStatusBadge: React.FC<OrderStatusFieldProps> = ({
  status,
}) => {
  const intl = useIntl()

  if (!status) {
    return null
  }

  return (
    <Container
      aria-label="badge-component"
      display="flex"
      flexDirection="row"
      gap="8px"
    >
      <Text size="s">
        <Badge color={getBadgeColor(status)} />
        {CustomOrderStatusTitle[status]
          ? intl.formatMessage(CustomOrderStatusTitle[status])
          : firstLetterToUpperCase(status)}
      </Text>
    </Container>
  )
}
