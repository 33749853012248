import Container from "../../../../ui/Container"
import CheckableTag from "../CheckableTag/CheckableTag"
import React, { useState } from "react"
import styled from "styled-components"

type ItemType = { title?: string; value: string }

type CheckableTagGroupProps = {
  items: Array<ItemType>
  value: string
  onChange: (data: string) => void
}

const CheckableTagGroup: React.FC<CheckableTagGroupProps> = ({
  items,
  value,
  onChange,
}) => {
  const [checked, setChecked] = useState(value)

  const handleClick = (tag: string) => {
    setChecked(tag)
    onChange(tag)
  }

  return (
    <StyledContainer display="flex" gap="16px">
      {items.map((tag, index) => (
        <CheckableTag
          key={`${index}-${tag.value}`}
          checked={tag.value === checked}
          title={tag.title ?? tag.value}
          value={tag.value}
          onclick={handleClick}
        />
      ))}
    </StyledContainer>
  )
}

export default CheckableTagGroup

const StyledContainer = styled(Container)`
  .ant-tag-checkable:active {
    background-color: ${({ theme }) => theme.colors.Primary2};
  }
`
