import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Icon from "../../../../../ui/Icon"
import Select from "../../../../../ui/Select"
import Spacer from "../../../../../ui/Spacer"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../ui/Typography/Text"
import { DeliveryProviders } from "../../../../Settings/Restaurant/DeliveryIntegration/DeliveryIntegrationForm/delivery-integrations.util"
import { useGetDeliveryIntegrationsByRestaurantQuery } from "../../../../Settings/Restaurant/DeliveryIntegration/GraphQL/getDeliveryIntegrationsByRestaurant.generated"
import { useUpdateDeliveryIntegrationInOrderMutation } from "../GraphQL/updateDeliveryIntegrationInOrder.generated"
import OrderActionContainer from "../OrderActionContainer"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import type { OrderDeliveryServiceForm } from "./hookforms.interfaces"
import get from "lodash/get"
import React from "react"
import { Controller, useForm, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const OrderDeliveryService: React.FC = () => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { getValues } = useFormContext<OrderDetailModalForm>()

  const orderDetail = getValues("orderDetail")

  const { control } = useForm<OrderDeliveryServiceForm>({
    defaultValues: { deliveryService: orderDetail?.deliveryIntegration?.uuid },
  })

  const { data, loading } = useGetDeliveryIntegrationsByRestaurantQuery({
    variables: {
      restaurantUUID,
    },
    skip: !restaurantUUID,
    fetchPolicy: "no-cache",
  })

  const [updateDeliveryIntegrationInOrderMutation] =
    useUpdateDeliveryIntegrationInOrderMutation()

  const getDeliveryOptions = (locationUUID?: string) => {
    if (!locationUUID) return []

    const orderDeliveryOptions: Array<{ value: string; label: string }> = []

    data?.getDeliveryIntegrationsByRestaurant?.forEach((delivery) => {
      const relatedLocations = delivery.relatedLocations ?? []

      const location = relatedLocations.find(({ uuid }) => {
        return uuid === locationUUID
      })

      const deliveryOption = {
        label: delivery.integrationLabel,
        value: delivery.uuid,
      }

      !!location?.uuid && orderDeliveryOptions.push(deliveryOption)
    })

    return orderDeliveryOptions
  }

  const deliveryOptions = getDeliveryOptions(orderDetail?.location?.uuid)

  const deliveryService: DeliveryIntegrationEnum = get(
    orderDetail,
    "orderDetail?.deliveryIntegration?.integrationName"
  )
  const selectedDelivery = deliveryService && DeliveryProviders[deliveryService]

  const handleGoToDeliveryService = () => {
    selectedDelivery &&
      window.open(selectedDelivery.termnsAndConditionsLink, "_blank")
  }

  const handleTermsAndConditionsService = () => {
    selectedDelivery &&
      window.open(selectedDelivery.termnsAndConditionsLink, "_blank")
  }

  const handleChange = async (value: string) => {
    try {
      await updateDeliveryIntegrationInOrderMutation({
        variables: {
          data: {
            order: {
              uuid: orderDetail.uuid,
            },
            deliveryIntegration: {
              uuid: value,
            },
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <OrderActionContainer role="order-delivery-service-section">
      <Text size="xl" weight="bold" color="Neutral6">
        {intl.formatMessage({
          id: "restaurants.orders.order.detail.order.delivery.service.title",
          defaultMessage: "Delivery Service",
        })}
      </Text>
      <Spacer size={16} />
      <Container display="flex" gap="24px">
        <Container width="100%">
          <Controller
            control={control}
            name="deliveryService"
            render={({ field }) => {
              return (
                <Select
                  value={field.value}
                  className="select-whitout-margin"
                  loading={loading}
                  options={deliveryOptions}
                  onChange={(field.onChange, handleChange)}
                  disabled
                />
              )
            }}
          />
        </Container>
        {selectedDelivery && (
          <Button
            title={intl.formatMessage(selectedDelivery.goToButton)}
            onClick={handleGoToDeliveryService}
            hierarchy="tertiary"
            hidden
          />
        )}
      </Container>
      <Spacer size={8} />
      <Container display="flex" alignItems="center" gap="9px">
        <StyledContainer>
          {selectedDelivery && (
            <Text size="s">
              <HighlightedText
                size="s"
                cursor="pointer"
                onClick={handleTermsAndConditionsService}
              >
                <Icon
                  classes="order-delivery-service__icon"
                  remixiconClass="ri-error-warning-line"
                  color="Primary5"
                  size={12}
                />

                {intl.formatMessage(selectedDelivery.termnsAndConditionsLabel)}
              </HighlightedText>
              {intl.formatMessage(selectedDelivery.termnsAndConditions)}
            </Text>
          )}
        </StyledContainer>
      </Container>
    </OrderActionContainer>
  )
}

const StyledContainer = styled(Container)`
  display: none;
  .order-delivery-service__icon {
    margin-right: 2px;
    margin-bottom: 2px;
  }
`
