import { DEFAULT_ID } from "../utils/constant/breadcrumb"
import storage from "../utils/storage"
import { useHistory, useLocation } from "react-router-dom"

type PushRouteType = {
  id?: string
  currentPageName: string
  replaceActualPath?: string
}

export const useBreadcrumb = () => {
  const { push } = useHistory()
  const location = useLocation()

  const pushBreadcrumb = (to: string, props: PushRouteType) => {
    const { id = DEFAULT_ID, currentPageName, replaceActualPath } = props
    const storedPaths =
      (storage.get(id) as Array<{ path: string; title: string }>) ?? []
    const state = location.state as { [key: string]: boolean }
    const pathToSave = replaceActualPath ?? location.pathname

    if (state?.[id]) {
      storedPaths?.push({ path: pathToSave, title: currentPageName })
      storage.set(id, storedPaths)
    } else {
      storage.set(id, [{ path: pathToSave, title: currentPageName }])
    }

    return push(to, { [id]: true })
  }

  return { pushBreadcrumb }
}

export const storedBreadcrumbs = (id: string = DEFAULT_ID) =>
  (storage.get(id) as Array<{ path: string; title: string }>) ?? []
