import yup from "../../../../../../shared/yup"
import { LIMIT_PER_CUSTOMER_NAME } from "../constants"
import {
  ERROR_INPUT_SHOULD_BE_POSITIVE_ID,
  ERROR_USAGE_LIMIT_PER_CUSTOMER_ID,
} from "./validation.title"

export const UsageLimitSchema = yup
  .object()
  .shape({
    limitedTo: yup
      .number()
      .min(0, ERROR_INPUT_SHOULD_BE_POSITIVE_ID)
      .nullable(),
    limitPerCustomer: yup
      .number()
      .min(0, ERROR_INPUT_SHOULD_BE_POSITIVE_ID)
      .nullable(),
  })
  .test("ifLimitedToShouldBeGreaterThanLimitPerCustomer", (value) => {
    const { limitPerCustomer, limitedTo } = value

    if (!limitedTo) return true

    if (!limitPerCustomer || limitPerCustomer > limitedTo) {
      return new yup.ValidationError(
        ERROR_USAGE_LIMIT_PER_CUSTOMER_ID,
        value,
        LIMIT_PER_CUSTOMER_NAME
      )
    }
    return true
  })
