import { graphqlClient } from "../../../../../shared/graphql/client"
import type {
  GetModifierGroupListQuery,
  GetModifierGroupListQueryVariables,
} from "../../GraphQL/getModiferGroupList.generated"
import type { SaveModifierGroupCacheType } from "./interfaces"
import readGetModifierGroupListQueryCache from "./modifier-group-cache-read"
import writeGetModifierGroupListQueryCache from "./modifier-group-cache-write"

const cache = graphqlClient.cache

export const saveModifierGroup = (
  data: SaveModifierGroupCacheType,
  variables: GetModifierGroupListQueryVariables
) => {
  const modifierListQuery = readGetModifierGroupListQueryCache({
    cache,
    variables,
  })

  const modifierGroups = modifierListQuery?.getModifierGroupList.results

  if (modifierGroups) {
    const modifierGroupListUpdated = modifierGroups.map((modifier) =>
      data.uuid === modifier.uuid ? { ...modifier, ...data } : modifier
    )
    const modifierExists = modifierGroupListUpdated.some(
      (updatedModifier) => updatedModifier.uuid === data.uuid
    )

    if (!modifierExists) {
      modifierGroupListUpdated.push({
        uuid: data.uuid,
        isPublished: data.isPublished ?? false,
        hasSnapshot: data.hasSnapshot ?? false,
        isMain: data.isMain ?? false,
        name: data.name ?? "",
      })
    }

    const query: GetModifierGroupListQuery = {
      getModifierGroupList: {
        ...modifierListQuery.getModifierGroupList,
        results: modifierGroupListUpdated,
      },
    }

    writeGetModifierGroupListQueryCache({ cache, query, variables })
  }
}

export const deleteModifierGroup = (
  data: { uuid: string },
  variables: GetModifierGroupListQueryVariables
) => {
  const modifierGroupListQuery = readGetModifierGroupListQueryCache({
    cache,
    variables,
  })

  const modifierGroups = modifierGroupListQuery?.getModifierGroupList.results

  if (modifierGroups) {
    const filteredModifierGroup = modifierGroups.filter(
      (modifier) => modifier.uuid !== data.uuid
    )

    const query: GetModifierGroupListQuery = {
      getModifierGroupList: {
        ...modifierGroupListQuery.getModifierGroupList,
        results: filteredModifierGroup,
      },
    }
    writeGetModifierGroupListQueryCache({ cache, query, variables })
  }
}
