import { TimeEntriesByLocationEmployeeQuery } from "../Graphql/timeEntriesByLocationEmployee.generated"
import { EmployeeTimeEntry, TimeEntry } from "../TimeEntriesContext"

export const parseEmployeesTimeEntry = (
  data: TimeEntriesByLocationEmployeeQuery["timeEntriesByLocationEmployee"]["employeeTimeEntries"]
) => {
  const timeEntries: EmployeeTimeEntry = {}

  data.forEach((employee) => {
    const timeEntry: TimeEntry[] = []

    employee.timeEntries.forEach((entry) => {
      if (!entry?.startDate) {
        return
      }

      const val = {
        uuid: entry.uuid,
        startDate: entry.startDate as string,
        endDate: (entry.endDate as string | null) ?? undefined,
      }

      timeEntry.push(val)
    })
    timeEntries[employee.uuid] = timeEntry
  })

  return timeEntries
}
