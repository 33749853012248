import { useGeneralContext } from "../../contexts/StoreProvider"
import storage from "../../utils/storage"
import RedirectRoute from "../RedirectRoute"
import React from "react"
import type { RouteProps } from "react-router"

export type ProtectedRouteProps = RouteProps & {
  authenticationPath: string
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  ...props
}) => {
  const {
    state: {
      auth: { isAuthenticated },
    },
  } = useGeneralContext()

  if (!isAuthenticated) {
    storage.clear()

    return <RedirectRoute {...props} pathname={props.authenticationPath} />
  }

  return <>{children}</>
}
