import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const EditRestaurantSchema = yup.object().shape({
  name: yup.string().required(),
  logo: yup.mixed().notRequired(),
  cover: yup.mixed().notRequired(),
})

export const EditRestaurantResolver = yupResolver(EditRestaurantSchema)
