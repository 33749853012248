import { IntlMessage } from "../../../../../shared/types"
import styles from "./ReportsTable.module.css"
import { TableContentEmptyState } from "./TableContentEmptyState"
import { Table, Text } from "@mantine/core"
import { ReactNode } from "react"
import { useIntl } from "react-intl"

type ReportsTableProps = {
  tableData: Array<ReactNode>
  headers: Array<IntlMessage>
  h: number
  firstHeaderEmpty?: boolean
}

export const ReportsTable = (props: ReportsTableProps) => {
  const { tableData, headers, h, firstHeaderEmpty = false } = props
  const intl = useIntl()

  let headersLength = headers.length
  firstHeaderEmpty && headersLength++

  const rows =
    tableData.length > 0 ? (
      tableData
    ) : (
      <TableContentEmptyState length={headersLength} />
    )

  return (
    <Table
      withBorder
      display="block"
      mah={h}
      className={styles["payroll-table"]}
    >
      <thead className={styles["payroll-thead"]}>
        <tr>
          {firstHeaderEmpty && <th />}
          {headers.map((header, index) => (
            <th key={`${index}-${header.id}`}>
              <Text weight={400} size="sm">
                {intl.formatMessage(header)}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}
