import moment from "moment-timezone"

export function formatTimerDuration(seconds: number) {
  const formatHours = seconds / 3600
  const hours = Math.floor(formatHours)
  const remainingMinutes = (formatHours - hours) * 60
  const minutes = Math.floor(remainingMinutes)
  const formatSecond = Math.floor((remainingMinutes - minutes) * 60)

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(formatSecond).padStart(2, "0")}`
}

export function dateRangeHours(startDate: string, endDate?: string | null) {
  const startDay = moment(startDate).format("YYYY-MM-DD")
  const endDay = endDate ? moment(endDate).format("YYYY-MM-DD") : null

  const startHour = moment(startDate).format("hh:mm A")
  const endHour = moment(endDate).format("hh:mm A")

  const monthAndDay = moment(endDate).format("MMM DD")

  const sameDate = startDay === endDay

  if (!sameDate && endDate) {
    return (
      <>
        {startHour} - <strong>{monthAndDay}</strong> {endHour}
      </>
    )
  }

  if (endDate) {
    return `${startHour} - ${endHour}`
  }
  return startHour
}

export function formatMonthAndDay(date: string) {
  const monthAndDay = moment(date).format("MMM DD")

  return monthAndDay
}
