import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCustomersByRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  phone?: Types.InputMaybe<Types.Scalars['String']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAllCustomersByRestaurantQuery = { __typename?: 'Query', getAllCustomersByRestaurant: { __typename?: 'PaginatedCustomerWithLocationModel', hasNextPage: boolean, endCursor?: string | null, results?: Array<{ __typename?: 'CustomerWithLocationModel', userUUID: string, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, formattedPhone?: string | null, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> }> | null } };


export const GetAllCustomersByRestaurantDocument = gql`
    query getAllCustomersByRestaurant($restaurantUUID: ID!, $locationUUID: ID, $take: Int, $after: String, $name: String, $phone: String, $email: String) {
  getAllCustomersByRestaurant(
    restaurantUUID: $restaurantUUID
    locationUUID: $locationUUID
    take: $take
    after: $after
    name: $name
    phone: $phone
    email: $email
  ) {
    hasNextPage
    endCursor
    results {
      userUUID
      firstName
      lastName
      email
      phone
      formattedPhone
      locations {
        uuid
        name
      }
    }
  }
}
    `;

/**
 * __useGetAllCustomersByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetAllCustomersByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCustomersByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCustomersByRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      locationUUID: // value for 'locationUUID'
 *      take: // value for 'take'
 *      after: // value for 'after'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAllCustomersByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetAllCustomersByRestaurantQuery, GetAllCustomersByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCustomersByRestaurantQuery, GetAllCustomersByRestaurantQueryVariables>(GetAllCustomersByRestaurantDocument, options);
      }
export function useGetAllCustomersByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCustomersByRestaurantQuery, GetAllCustomersByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCustomersByRestaurantQuery, GetAllCustomersByRestaurantQueryVariables>(GetAllCustomersByRestaurantDocument, options);
        }
export type GetAllCustomersByRestaurantQueryHookResult = ReturnType<typeof useGetAllCustomersByRestaurantQuery>;
export type GetAllCustomersByRestaurantLazyQueryHookResult = ReturnType<typeof useGetAllCustomersByRestaurantLazyQuery>;
export type GetAllCustomersByRestaurantQueryResult = Apollo.QueryResult<GetAllCustomersByRestaurantQuery, GetAllCustomersByRestaurantQueryVariables>;