import paths from "../../../../../shared/routes/paths"
import AddIcon from "../../../../../ui/AddIcon"
import DropdownAction from "../../../../../ui/Header/DropdownAction"
import Text from "../../../../../ui/Typography/Text"
import { Menu } from "antd"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

export const AddLocationButton = () => {
  const intl = useIntl()
  const { push } = useHistory()

  const goToCreateLocation = () => {
    push(paths.settings.locations.create)
  }

  const AddLocationMenu = () => (
    <Menu role="menu-add-location">
      <Menu.Item key="dropdown-create-location" onClick={goToCreateLocation}>
        <Text size="s">
          {intl.formatMessage({
            id: "restaurants.locations.add.location.button",
            defaultMessage: "Add Location",
          })}
        </Text>
      </Menu.Item>
    </Menu>
  )

  return (
    <DropdownAction overlay={<AddLocationMenu />}>
      <AddIcon />
    </DropdownAction>
  )
}
