import { isoStringHoursFormat } from "../../../../../shared/utils/constant/dateFormats"
import { CustomOrderMethodTitle } from "../../../../../shared/utils/constant/orderMethod.title"
import formatIsoStringDate from "../../../../../shared/utils/helpers/formatIsoStringDate"
import Container from "../../../../../ui/Container"
import Text from "../../../../../ui/Typography/Text"
import { CustomOrderPaymentMethodTitle } from "../../titles/orderPaymentMethod.title"
import calculateElapsedTime from "../../utils/calculate-elapsed-time.util"
import { OrderInformationSkeleton } from "../OrderDetail.skeleton"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import moment from "moment-timezone"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type OrderInformationProps = {
  loading?: boolean
}

export const OrderInformation: React.FC<OrderInformationProps> = ({
  loading,
}) => {
  const intl = useIntl()
  const { watch } = useFormContext<OrderDetailModalForm>()

  const orderActions = watch("orderSummary.orderActions")
  const orderDetail = watch("orderDetail")

  if (loading) {
    return <OrderInformationSkeleton />
  }

  return (
    <Container role="order-information-section">
      <StyledContentSection>
        <Text weight="bold" color="Neutral6" size="xl">
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.order.information.title",
            defaultMessage: "Order Information",
          })}
        </Text>
      </StyledContentSection>

      <StyledOrderInformationContainer>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.date",
              defaultMessage: "Date",
            })}
          </Text>
          <Text size="s" weight="bold">
            {orderDetail?.createdAt
              ? formatIsoStringDate(orderDetail?.createdAt)
              : "-"}
          </Text>
        </Container>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.hour",
              defaultMessage: "Hour",
            })}
          </Text>
          <Text size="s" weight="bold">
            {orderDetail?.createdAt
              ? moment(orderDetail?.createdAt).format(isoStringHoursFormat)
              : "-"}
          </Text>
        </Container>
      </StyledOrderInformationContainer>

      <StyledOrderInformationContainer>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.location",
              defaultMessage: "Location",
            })}
          </Text>
          <Text size="s" weight="bold">
            {orderDetail?.location?.name ?? "_"}
          </Text>
        </Container>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.method",
              defaultMessage: "Order Method",
            })}
          </Text>
          <Text size="s" weight="bold">
            {orderDetail?.orderMethod
              ? intl.formatMessage({
                  id: CustomOrderMethodTitle[orderDetail.orderMethod].id,
                  defaultMessage:
                    CustomOrderMethodTitle[orderDetail.orderMethod]
                      .defaultMessage,
                })
              : "-"}
          </Text>
        </Container>
      </StyledOrderInformationContainer>

      <StyledOrderInformationContainer>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.total.time.queue",
              defaultMessage: "Total Time in Queue",
            })}
          </Text>
          <Text size="s" weight="bold">
            {orderActions
              ? calculateElapsedTime(orderActions, orderDetail?.expiresAt)
              : "-"}
          </Text>
        </Container>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.total.preparation.time",
              defaultMessage: "Total Preparation Time",
            })}
          </Text>
          <Text size="s" weight="bold">
            -
          </Text>
        </Container>
      </StyledOrderInformationContainer>

      <StyledOrderInformationContainer>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.total.elapsed.time",
              defaultMessage: "Total Elapsed Time",
            })}
          </Text>
          <Text size="s" weight="bold">
            -
          </Text>
        </Container>
        <Container width="50%" display="flex" flexDirection="column" gap="8px">
          <Text color="Neutral6" size="m">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.order.information.payment.method",
              defaultMessage: "Payment Method",
            })}
          </Text>
          <Text size="s" weight="bold">
            {orderDetail?.paymentMethodType
              ? intl.formatMessage({
                  id: CustomOrderPaymentMethodTitle[
                    orderDetail.paymentMethodType
                  ].id,
                  defaultMessage:
                    CustomOrderPaymentMethodTitle[orderDetail.paymentMethodType]
                      .defaultMessage,
                })
              : "-"}
          </Text>
        </Container>
      </StyledOrderInformationContainer>
    </Container>
  )
}

const StyledOrderInformationContainer = styled.div`
  display: flex;
  padding-bottom: 24px;
`

const StyledContentSection = styled.div`
  padding-bottom: 24px;
`
