import { useFilters } from "../../../shared/contexts/FilterProvider"
import { SummaryReport } from "../../../shared/graphql/generated/types"
import paths from "../../../shared/routes/paths"
import { YYYY_MM_DD } from "../../../shared/utils/constant/dateFormats"
import safeNumber from "../../../shared/utils/helpers/safeNumber"
import Container from "../../../ui/Container"
import { showGraphqlErrors } from "../../../ui/ErrorList"
import Content from "../../../ui/Layouts/MainLayout/Content"
import PageWrapper from "../../../ui/Layouts/PageWrapper"
import { useGetSummaryReportQuery } from "./Graphql/getSummaryReport.generated"
import ReportsHeader from "./ReportsHeader"
import { ReportsTables } from "./ReportsTables/ReportsTables"
import { useFlags } from "launchdarkly-react-client-sdk"
import get from "lodash/get"
import moment from "moment-timezone"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"

export const LegacyReports: React.FC = () => {
  const { dates, getDatesQueryFilters, updateDateFilters } = useFilters()
  const [summaryReport, setSummaryReport] = useState<SummaryReport>()
  const getDatesQueryFilter = getDatesQueryFilters()
  const { apEnableReports } = useFlags()
  const { replace } = useHistory()
  if (apEnableReports) {
    replace({ pathname: paths.restaurants.reports })
  }

  const { loading } = useGetSummaryReportQuery({
    variables: {
      startDate: moment(getDatesQueryFilter.startDate)
        .set("hour", safeNumber("00"))
        .set("minute", safeNumber("00"))
        .toISOString(true),
      endDate: moment(getDatesQueryFilter.endDate)
        .set("hour", safeNumber("23"))
        .set("minute", safeNumber("59"))
        .toISOString(true),
    },
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const data = get(response, "getSummaryReport")

      if (data) {
        setSummaryReport(data)
      }
    },
    onError: showGraphqlErrors,
  })

  const setDefaultDate = useCallback(() => {
    const date = [
      moment(getDatesQueryFilter.startDate).format(YYYY_MM_DD),
      moment(getDatesQueryFilter.endDate).format(YYYY_MM_DD),
    ]

    updateDateFilters({ "start-date": date[0], "end-date": date[1] })
  }, [
    getDatesQueryFilter.endDate,
    getDatesQueryFilter.startDate,
    updateDateFilters,
  ])

  useEffect(() => {
    if (!dates["start-date"] && !dates["end-date"]) {
      setDefaultDate()
    }
  }, [dates, setDefaultDate])

  return (
    <Content header={<ReportsHeader />}>
      <PageWrapper>
        <StickyContent>
          <>
            {/* Todo: Hide Graphs */}
            {/* <ReportsLegend summaryReport={summaryReport.salesSummaryDetail} loading={loading} /> */}

            <ReportsTables
              salesSummary={summaryReport?.salesSummaryDetail}
              salesByPaymentType={summaryReport?.salesByPaymentType}
              customerSupportDetail={summaryReport?.customerSupportDetail}
              loading={loading}
            />
          </>
        </StickyContent>
      </PageWrapper>
    </Content>
  )
}

const StickyContent = styled(Container)`
  height: calc(100vh - 48px);
  padding: 24px 36px;
  position: relative;
  overflow-y: auto;
`
