import paths from "../../shared/routes/paths"
import Button from "../../ui/Button"
import Container from "../../ui/Container"
import { Result } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"

export const NotFound: React.FC = () => {
  const intl = useIntl()

  return (
    <Container centered fullScreen>
      <Result
        status="404"
        title={intl.formatMessage({
          id: "generic.not.found.404",
          defaultMessage: "404",
        })}
        subTitle={intl.formatMessage({
          id: "generic.not.found.message",
          defaultMessage: "Sorry, the page you visited does not exist.",
        })}
        extra={
          <Link to={paths.root}>
            <Container centered>
              <Button
                title={intl.formatMessage({
                  id: "generic.error.page.button",
                  defaultMessage: "Back Home",
                })}
              />
            </Container>
          </Link>
        }
      />
    </Container>
  )
}
