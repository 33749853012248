import { device } from "../../../../../../shared/breakpoints/responsive"
import { toFormat } from "../../../../../../shared/utils/currency/toFormat.dinero"
import Container from "../../../../../../ui/Container"
import { InputNumber } from "../../../../../../ui/Inputs"
import HighlightedText from "../../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../../ui/Typography/Text"
import notification from "../../../../../../ui/notification"
import type { PriceFormFields } from "../../interfaces/hookforms.interfaces"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type handleChangeParams = {
  event: number | null
  onChange: (...event: unknown[]) => void
}

type ComboPriceFormProps = {
  disablePriceChange?: boolean
  fullPrice?: number
  priceOverride?: number
}

const ComboPriceForm: React.FC<ComboPriceFormProps> = (props) => {
  const { disablePriceChange = false, fullPrice = 0, priceOverride = 0 } = props
  const intl = useIntl()
  const { control, setValue } = useFormContext<PriceFormFields>()

  const handleOverrideReset = () => {
    const willChangePriceOverride = priceOverride !== fullPrice
    setValue("priceOverride", fullPrice, {
      shouldDirty: willChangePriceOverride,
    })
    setValue("discountPercentage", 0, { shouldDirty: willChangePriceOverride })
  }

  const handlePriceOverrideChange = ({
    event,
    onChange,
  }: handleChangeParams) => {
    const override = event ?? 0
    const discountPercentage = 100 - (override * 100) / fullPrice

    if (discountPercentage < 0) {
      return notification({
        key: "lsm.combo.price.override.error",
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.menu.items.forms.combo.price.form.override.error.description",
          defaultMessage: "Price override should be less than combo full price",
        }),
        title: intl.formatMessage({
          id: "restaurants.menu.items.forms.combo.price.form.override.error.title",
          defaultMessage: "Can't set price override",
        }),
      })
    }
    onChange(event)
    setValue("discountPercentage", Number(discountPercentage.toFixed(2)))
  }

  const handleDiscountChange = ({ event, onChange }: handleChangeParams) => {
    const discountPercentage = event ?? 0

    if (discountPercentage < 0 || discountPercentage > 100) {
      return notification({
        key: "lsm.combo.price.discount.percentage.error",
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.menu.items.forms.combo.price.form.discount.percentage.error.description",
          defaultMessage:
            "Discount percentage should be a number between 0 and 100",
        }),
        title: intl.formatMessage({
          id: "restaurants.menu.items.forms.combo.price.form.discount.percentage.error.title",
          defaultMessage: "Can't set discount percentage",
        }),
      })
    }

    const override = ((100 - discountPercentage) / 100) * fullPrice
    onChange(event)
    setValue("priceOverride", Number(override.toFixed(2)))
  }

  return (
    <>
      <RowContainer>
        <Text size="m" weight="regular" color="Neutral8">
          {intl.formatMessage({
            id: "restaurants.menu.items.forms.combo.price.form.full.price.row",
            defaultMessage: "Combo Items Full Price",
          })}
        </Text>
        <Text size="m" weight="regular" color="Neutral8">
          {toFormat(fullPrice)}
        </Text>
      </RowContainer>

      <Container display="flex" flexDirection="column" gap="8px">
        <Text size="m" weight="regular" color="Neutral8">
          {intl.formatMessage({
            id: "restaurants.menu.items.forms.combo.price.form.price.label",
            defaultMessage: "Price",
          })}
        </Text>
        <RowContainer>
          <Text size="m" weight="regular" color="Neutral8">
            {intl.formatMessage({
              id: "restaurants.menu.items.forms.combo.price.form.set.price.row",
              defaultMessage: "Set Combo Price",
            })}
          </Text>
          <Container display="flex" gap="8px">
            <Controller
              control={control}
              name="priceOverride"
              render={({ field: { onChange, ...rest } }) => (
                <StyleInputNumber
                  {...rest}
                  onChange={(event) =>
                    handlePriceOverrideChange({ event, onChange })
                  }
                  prefix={intl.formatMessage({
                    id: "restaurants.menu.items.forms.combo.price.form.price.symbol",
                    defaultMessage: "$",
                  })}
                  precision={2}
                  disabled={disablePriceChange}
                  bordered
                />
              )}
            />
            <Controller
              control={control}
              name="discountPercentage"
              render={({ field: { onChange, ...rest } }) => (
                <StyleInputNumber
                  {...rest}
                  onChange={(event) =>
                    handleDiscountChange({ event, onChange })
                  }
                  prefix={intl.formatMessage({
                    id: "restaurants.menu.items.forms.combo.price.form.discount.prefix",
                    defaultMessage: "Disc",
                  })}
                  suffix="%"
                  precision={2}
                  disabled={disablePriceChange}
                  bordered
                />
              )}
            />
          </Container>
        </RowContainer>
        <Container
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
        >
          <HighlightedText
            size="m"
            weight="bold"
            cursor="pointer"
            onClick={handleOverrideReset}
          >
            {intl.formatMessage({
              id: "restaurants.menu.items.forms.combo.price.form.reset.price.label",
              defaultMessage: "Reset price",
            })}
          </HighlightedText>
          <Text size="m" weight="regular" color="Neutral8">
            {`(${toFormat(fullPrice)})`}
          </Text>
        </Container>
      </Container>
    </>
  )
}

export default ComboPriceForm

const RowContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.Neutral4};
  border-radius: 4px;
`

const StyleInputNumber = styled(InputNumber)`
  width: 120px;

  @media ${device.sm} {
    width: auto;
  }
`
