import logoCrmbIcon from "../../../../shared/assets/images/logo_icon.png"
import logoCrmb from "../../../../shared/assets/images/logo_white.png"
import { device } from "../../../../shared/breakpoints/responsive"
import { getImageSource } from "../../../../shared/utils/helpers/getImageSource"
import Container from "../../../../ui/Container"
import Icon from "../../../../ui/Icon"
import { StyledAvatar } from "../../../../ui/ImageIcon/ImageIcon"
import Spacer from "../../../../ui/Spacer"
import Text from "../../../../ui/Typography/Text"
import type { RcFile } from "antd/lib/upload"
import classNames from "classnames"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type RestaurantCardProps = {
  image?: string
  urlIdentifier: string
  restaurantName: string
  locationName: string
  brandColor: string
  logo?: RcFile | string
}

const RestaurantCard: React.FC<RestaurantCardProps> = (props) => {
  const intl = useIntl()
  const {
    restaurantName,
    locationName,
    urlIdentifier = "",
    brandColor,
    logo,
  } = props

  const [rotate, setRotate] = useState(false)

  const cardFront = document.querySelector("#card-front") as HTMLElement
  const cardBack = document.querySelector("#card-back") as HTMLElement

  const handleMousemove = (event: MouseEvent) => {
    const { x, y } = cardFront.getBoundingClientRect()

    const xPosition = `${event.clientX - x}`
    const yPosition = `${event.clientY - y}`

    cardFront.style.setProperty("--x", xPosition)
    cardFront.style.setProperty("--y", yPosition)
    cardBack.style.setProperty("--x", xPosition)
    cardBack.style.setProperty("--y", yPosition)
  }

  const handleMouseleave = () => {
    cardFront?.removeEventListener("mousemove", handleMousemove)
    cardBack?.removeEventListener("mousemove", handleMousemove)
  }

  cardFront?.addEventListener("mousemove", handleMousemove)
  cardFront?.removeEventListener("mouseleave", handleMouseleave)
  cardBack?.addEventListener("mousemove", handleMousemove)
  cardBack?.removeEventListener("mouseleave", handleMouseleave)

  const handleFlip = () => {
    setRotate((prev) => !prev)
  }

  return (
    <CardContainer width="384px" height="208px">
      <CardBox $color={brandColor} className={classNames({ flip: rotate })}>
        <CardFront id="card-front" onClick={handleFlip}>
          <Container className="box-background" />
          <Container className="box-content">
            <Container display="flex" gap="32px" width="100%">
              <LogoContainer>
                {logo && (
                  <StyledAvatar
                    size={104}
                    shape="square"
                    src={getImageSource(logo)}
                    color="Neutral1"
                  />
                )}
              </LogoContainer>
              <Container width="calc(100% - 136px)">
                <Text size="l" weight="bold" color="Neutral0" ellipsis>
                  {restaurantName
                    ? restaurantName
                    : intl.formatMessage({
                        defaultMessage: "Restaurant Name",
                        id: "onboarding.restaurant.card.default.restaurant.name.label",
                      })}
                </Text>
                <Spacer size={8} />
                <Text size="m" weight="regular" color="Neutral0" ellipsis>
                  {intl.formatMessage({
                    id: "onboarding.forms.restaurant.form.url.prefix",
                    defaultMessage: "crmb.app/",
                  }) + urlIdentifier}
                </Text>
                <Spacer size={16} />
                <Container display="flex" gap="4px" alignItems="center">
                  <Icon remixiconClass="ri-map-pin-2-line" color="Neutral0" />
                  <Text size="s" weight="bold" color="Neutral0" ellipsis>
                    {locationName
                      ? locationName
                      : intl.formatMessage({
                          defaultMessage: "Location Name",
                          id: "onboarding.restaurant.card.default.location.name.label",
                        })}
                  </Text>
                </Container>
              </Container>
            </Container>
            <LogoCrmb src={logoCrmb} />
          </Container>
        </CardFront>
        <CardBack position="absolute" id="card-back" onClick={handleFlip}>
          <img src={logoCrmbIcon} width="80px" />
        </CardBack>
      </CardBox>
    </CardContainer>
  )
}

export default RestaurantCard

const CardContainer = styled(Container)`
  perspective: 1000px;

  @media ${device.sm} {
    width: 300px;
  }
`

const CardFront = styled(Container)`
  position: relative;
  .box-background,
  .box-content {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    position: absolute;
    border-radius: 16px;
  }

  .box-background {
    left: 0;
    bottom: 0;
    isolation: isolate;
    box-shadow: 8px 10px 16px rgba(0, 0, 0, 0.05);
  }

  .box-content {
    right: 0;
    top: 0;
    background: linear-gradient(
      100.56deg,
      rgba(255, 255, 255, 0.51) 0%,
      rgba(255, 255, 255, 0) 121.99%
    );
    filter: drop-shadow(8px 10px 16px rgba(0, 0, 0, 0.05));
    backdrop-filter: blur(6px);
    padding: 32px 24px;
  }
`

const CardBack = styled(Container)`
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 8px 10px 16px rgba(0, 0, 0, 0.05);
`

const CardBox = styled(Container)<{ $color: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;

  ${CardFront} {
    .box-background {
      background: ${({ theme, $color }) =>
        $color
          ? `${$color}`
          : `linear-gradient(68.33deg, ${theme.colors.Primary6} 0%, ${theme.colors.Primary5}) 100%;`};
    }
  }

  ${CardBack} {
    background: ${({ theme, $color }) =>
      $color ? $color : `${theme.colors.Primary5}`};
  }

  ${CardFront},${CardBack} {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: calc(var(--y, 0) * 1px - 70px);
      left: calc(var(--x, 0) * 1px - 70px);
      width: 140px;
      height: 140px;
      background: radial-gradient(
        ${({ theme }) => theme.colors.Neutral0},
        #3984ff00 80%
      );
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover::after {
      opacity: 0.07;
    }
  }

  &.flip {
    transform: rotateY(180deg);
  }
`

const LogoContainer = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eff0f6;
  background: ${({ theme }) => theme.colors.Neutral0};
`

const LogoCrmb = styled.img`
  width: 50px;
  position: absolute;
  left: 24px;
  bottom: 18px;
`
