import yup from "../../../../../shared/yup"
import { FieldTypeEnumItem } from "../interfaces/hookforms.interfaces"
import {
  MAX_GREATER_THAN_MODIFIERS,
  MAX_LOWER_THAN_INCLUDED_MODIFIERS,
} from "../utils/constants"
import { yupResolver } from "@hookform/resolvers/yup"

const ItemFormSchema = yup.object().shape({
  name: yup.string().required("Name it"),
  variants: yup
    .array()
    .min(1, "Add least one variant")
    .of(
      yup
        .object()
        .shape({
          name: yup.string().required("Name the variant"),
          price: yup.number().min(0).required("Set a price for the variant"),
          calories: yup.number().min(0).max(5000).nullable(),
          variantModifierGroups: yup.array().of(
            yup.object().shape({
              fieldType: yup
                .mixed<FieldTypeEnumItem>()
                .oneOf(Object.values(FieldTypeEnumItem))
                .required(),
              minSelectedOptions: yup
                .number()
                .min(0)
                .max(
                  yup.ref("maxSelectedOptions"),
                  "Min can not be greater than max"
                )
                .required(),
              maxSelectedOptions: yup
                .number()
                .min(0)
                .required()
                .test(
                  MAX_GREATER_THAN_MODIFIERS,
                  `Max can't be greater than the amount of modifiers`,
                  (value, { parent, createError }) => {
                    if (
                      parent?.fieldType === FieldTypeEnumItem.CHECKBOX &&
                      value &&
                      value > parent.variantModifiers.length
                    )
                      return createError({
                        message: `Max can't be greater than the amount of modifiers`,
                      })

                    return true
                  }
                ),
              variantModifiers: yup
                .array()
                .of(
                  yup.object().shape({
                    calories: yup.number().min(0).required(),
                    price: yup.number().min(0).required(),
                  })
                )
                .test(
                  MAX_LOWER_THAN_INCLUDED_MODIFIERS,
                  "Max can't be lower than amount of included modifiers",
                  (value, { parent, createError, path }) => {
                    const quantityOfIncludedModifiers =
                      value?.reduce(
                        (acumulator, current) =>
                          current.isIncluded ? acumulator + 1 : acumulator,
                        0
                      ) ?? 0
                    if (
                      parent?.maxSelectedOptions < quantityOfIncludedModifiers
                    ) {
                      return createError({
                        message:
                          "Max can't be lower than the amount of included modifiers",
                        path: path.replace(
                          "variantModifiers",
                          "maxSelectedOptions"
                        ),
                      })
                    }
                    return true
                  }
                ),
            })
          ),
        })
        .required()
    ),
})

export const ItemFormResolver = yupResolver(ItemFormSchema)
