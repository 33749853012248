import Checkbox from "../../../../../../ui/Checkbox"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import Divider from "../../../../../../ui/Divider"
import Select, { OptionSelect } from "../../../../../../ui/Select"
import Tag from "../../../../../../ui/Tag"
import { Header } from "../Components"
import { SELECT_LIST_HEIGHT } from "../constants"
import { useCollapseOpen } from "../hooks/useCollapseOpen"
import { useLocationAvailability } from "./hooks/useLocationAvailability"
import { useIntl } from "react-intl"

export const LocationAvailability = () => {
  const intl = useIntl()

  const { handleCollapseChange, open } = useCollapseOpen()

  const {
    locations,
    loading,
    onLocationSelected,
    selectedLocations,
    onAllLocationsClick,
    handleSearchLocations,
    onScrollToFetchMore,
    search,
    isEditMode,
  } = useLocationAvailability()

  const headerTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.location.availability.header.title",
    defaultMessage: "Location Availability",
  })

  const selectTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.location.availability.select.title",
    defaultMessage: "Select Location",
  })

  const selectedLocationsQuantity = selectedLocations?.length

  const applyAllLocations = selectedLocationsQuantity === 0

  const allOptionsKey = "all-locations"

  const allOptionsLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.location.availability.all.locations.label",
    defaultMessage: "All locations",
  })

  const singularLocationLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.location.availability.singular.location.label",
    defaultMessage: "Location",
  })

  const pluralLocationLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.location.availability.plural.location.label",
    defaultMessage: "Locations",
  })

  const locationLabel =
    selectedLocationsQuantity > 1 ? pluralLocationLabel : singularLocationLabel

  const tagLabel = applyAllLocations
    ? allOptionsLabel
    : `${selectedLocationsQuantity} ${locationLabel}`

  return (
    <Collapse
      contentBorderColor="Neutral4"
      removeHeaderBottomBorder
      contentBordered
      classId="discount-rule-location-collapse"
      onChange={handleCollapseChange}
    >
      <CollapsePanel
        key="discount-rule-location-panel"
        header={
          <Header title={headerTitle}>{!open && <Tag>{tagLabel}</Tag>}</Header>
        }
      >
        <Select
          withCheckbox
          allowSearch
          searchValue={search}
          disabled={isEditMode}
          label={selectTitle}
          mode="multiple"
          optionFilterProp="label"
          loading={loading}
          listHeight={SELECT_LIST_HEIGHT}
          onSearch={handleSearchLocations}
          options={locations}
          value={
            applyAllLocations
              ? [allOptionsKey]
              : selectedLocations?.map((location) => location.uuid)
          }
          onChangeWithCheckbox={onLocationSelected}
          dropdownHeader={
            <OptionSelect
              className="option-select-checkbox no-background"
              key={allOptionsKey}
              value={allOptionsKey}
              label={allOptionsLabel}
            >
              <Container
                padding="4px 12px"
                onClick={onAllLocationsClick}
                className="option-select-checkbox-selected"
              >
                <Checkbox
                  classId="select-all-locations-checkbox"
                  content={allOptionsLabel}
                  checked={applyAllLocations}
                  indeterminate={!applyAllLocations}
                />
              </Container>
              <Divider verticalMargin="4px" />
            </OptionSelect>
          }
          closableTags={!applyAllLocations && !isEditMode}
          onPopupScroll={onScrollToFetchMore}
        />
      </CollapsePanel>
    </Collapse>
  )
}
