import type { StepsType } from "../../interfaces"
import { StepStateEnum } from "../../interfaces"

export type IndicatorsStateType = {
  id: StepsType
  step1: StepStateEnum
  step2: StepStateEnum
  step3: StepStateEnum
}

export const indicatorsStates: IndicatorsStateType[] = [
  {
    id: 1,
    step1: StepStateEnum.CURRENT,
    step2: StepStateEnum.DONE,
    step3: StepStateEnum.DONE,
  },
  {
    id: 2,
    step1: StepStateEnum.NEXT,
    step2: StepStateEnum.CURRENT,
    step3: StepStateEnum.DONE,
  },
  {
    id: 3,
    step1: StepStateEnum.NEXT,
    step2: StepStateEnum.NEXT,
    step3: StepStateEnum.CURRENT,
  },
]
