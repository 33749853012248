import { Skeleton } from "antd"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"

type ReportsSkeletonProps = {
  rows?: number
}

export const ReportsSkeleton: React.FC<ReportsSkeletonProps> = ({
  rows = 2,
}) => {
  return (
    <StyledContainer role="row-skeleton">
      {range(rows).map((key) => (
        <StyledSkeletonWrapper key={key}>
          <Skeleton
            title={false}
            paragraph={{ rows: 1, width: "100%" }}
            active
          />
        </StyledSkeletonWrapper>
      ))}
    </StyledContainer>
  )
}

const StyledSkeletonWrapper = styled.div`
  ${({ theme }) => `
    padding: 16px;
    border-bottom: 1px solid var(--neutral-4, ${theme.colors.Neutral4});

    &:first-child {
      background: ${theme.colors.Neutral8};
    }

    &:last-child {
      border: none;
    }

    .ant-skeleton-paragraph {
      margin: 0;
      & > li {
        margin: 0;
        height: 22px;
      }
    }
  `}
`

const StyledContainer = styled.div`
  padding: 0 16px;
`
