import Button from "../../../../ui/Button"
import Container from "../../../../ui/Container"
import React from "react"
import { useIntl } from "react-intl"

type StepperHandlerProps = {
  onNext: () => void
  lastStep?: boolean
  onPrevious?: () => void
  onFinish?: () => void
  loading?: boolean
}

const StepperHandler: React.FC<StepperHandlerProps> = ({
  lastStep,
  onNext,
  onPrevious,
  loading = false,
}) => {
  const intl = useIntl()

  return (
    <Container display="flex" gap="16px" justifyContent="flex-end">
      {onPrevious && (
        <Button
          hierarchy="secondary"
          onClick={onPrevious}
          title={intl.formatMessage({
            defaultMessage: "Previous",
            id: "onboarding.steps.footer.previous.button",
          })}
        />
      )}
      {onNext && (
        <Button
          loading={loading}
          onClick={onNext}
          title={
            lastStep
              ? intl.formatMessage({
                  defaultMessage: "Finish",
                  id: "onboarding.steps.footer.finish.button",
                })
              : intl.formatMessage({
                  defaultMessage: "Next",
                  id: "onboarding.steps.footer.next.button",
                })
          }
        />
      )}
    </Container>
  )
}

export default StepperHandler
