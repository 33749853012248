import type { Action } from "../../shared/contexts/actions"

export enum ActionTypes {
  AddAllRestaurants = "addAllRestaurants",
  CreateRestaurant = "createRestaurant",
  SetCurrentState = "setCurrentState",
  ClearRedirect = "clearRedirect",
}

export interface AddAllRestaurantsAction
  extends Action<ActionTypes.AddAllRestaurants> {
  payload: {
    uuid: string
    name: string
    urlIdentifier: string
    isCateringActive: boolean
  }[]
}
export interface CreateRestaurantAction
  extends Action<ActionTypes.CreateRestaurant> {
  payload: {
    uuid: string
    name: string
    urlIdentifier: string
    isCateringActive: boolean
  }
}

export interface SetCurrentState extends Action<ActionTypes.SetCurrentState> {
  payload: {
    restaurantUUID: string
    locationUUID?: string
    isCateringActive?: boolean
    refirectToRoot?: boolean
    refirectToSettings?: boolean
    timezone?: string
  }
}

export interface ClearRedirect extends Action<ActionTypes.ClearRedirect> {
  payload: undefined
}
