import { getApolloError } from "../utils/tests/apollo/apolloError.mock"
import type { ApolloError } from "@apollo/client"
import { useEffect, useState } from "react"

type useQueryMockOptions = {
  skip?: boolean
  variables: Record<string, unknown>
  onCompleted?: () => void
  onError?: () => void
  context?: unknown
}

type useQueryMockUtils<T> = {
  data: T
  showError?: boolean
  loadingTime?: number
}

type useQueryResult<T> = {
  data: T | undefined
  error: ApolloError | undefined
  loading: boolean
  refetch: () => void
}

export const useQueryMock = <T>(
  utils?: useQueryMockUtils<T>,
  params?: useQueryMockOptions
): useQueryResult<T> => {
  const [loading, setLoading] = useState(() => (params?.skip ? false : true))
  const [error, setError] = useState<ApolloError | undefined>(undefined)
  const [data, setData] = useState<useQueryMockUtils<T>["data"] | undefined>(
    undefined
  )

  const loadingHelper = () => {
    setTimeout(() => {
      if (utils?.showError)
        setError(getApolloError("Error on fetching the data"))

      if (params?.skip) return setLoading(false)

      setData(utils?.data)
      setLoading(false)
    }, utils?.loadingTime ?? 1000)
  }

  const refetch = () => {
    loadingHelper()
  }

  useEffect(() => {
    loadingHelper()
  }, [])

  return { data, loading, error, refetch }
}
