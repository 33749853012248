import Text from "../../../../ui/Typography/Text"
import { Tag } from "antd"
import type { CheckableTagProps as AntCheckableTagProps } from "antd/lib/tag/CheckableTag"
import classNames from "classnames"
import React from "react"
import styled, { css } from "styled-components"

const { CheckableTag: CheckableTagAntd } = Tag

export type CheckableTagProps = AntCheckableTagProps & {
  title: string
  value: string
  onclick?: (value: string) => void
}

const CheckableTag: React.FC<CheckableTagProps> = ({
  title,
  checked,
  onclick,
  value,
  ...props
}) => {
  const onTagClick = () => onclick?.(value)

  return (
    <CheckableTagContainer
      {...props}
      onClick={onTagClick}
      checked={checked}
      className={classNames("checked", checked)}
    >
      <Text
        size="m"
        weight={checked ? "bold" : "regular"}
        color={checked ? "Primary5" : "Neutral6"}
      >
        {title}
      </Text>
    </CheckableTagContainer>
  )
}

export default CheckableTag

const CheckableTagContainer = styled(CheckableTagAntd)<{ checked: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 32px;
  margin: 0;
  border-radius: 8px;

  background: ${({ theme, checked }) =>
    checked ? theme.colors.Primary1 : theme.colors.Neutral0};
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.colors.Primary5 : theme.colors.Neutral4};

  ${({ checked, theme }) =>
    !checked &&
    css`
      :hover {
        background: ${theme.colors.Neutral0};
        border: 1px solid ${theme.colors.Primary4};
      }
      :hover div span {
        color: ${theme.colors.Primary5};
      }
    `};
`
