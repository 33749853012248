import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnpublishItemMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type UnpublishItemMutation = { __typename?: 'Mutation', unpublishItem: { __typename?: 'RBasicEntityData', name: string, uuid: string } };


export const UnpublishItemDocument = gql`
    mutation unpublishItem($uuid: ID!) {
  unpublishItem(uuid: $uuid) {
    name
    uuid
  }
}
    `;
export type UnpublishItemMutationFn = Apollo.MutationFunction<UnpublishItemMutation, UnpublishItemMutationVariables>;

/**
 * __useUnpublishItemMutation__
 *
 * To run a mutation, you first call `useUnpublishItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishItemMutation, { data, loading, error }] = useUnpublishItemMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnpublishItemMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishItemMutation, UnpublishItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishItemMutation, UnpublishItemMutationVariables>(UnpublishItemDocument, options);
      }
export type UnpublishItemMutationHookResult = ReturnType<typeof useUnpublishItemMutation>;
export type UnpublishItemMutationResult = Apollo.MutationResult<UnpublishItemMutation>;
export type UnpublishItemMutationOptions = Apollo.BaseMutationOptions<UnpublishItemMutation, UnpublishItemMutationVariables>;