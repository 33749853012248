import {
  OrderStatusEnum,
  PaymentServiceTypeEnum,
} from "../../../../../shared/graphql/generated/types"
import { CURRENCY_CONFIG } from "../../../../../shared/utils/constant"
import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import { MantineCard } from "../../../../../ui/MantineCard"
import { useCalculateCateringPaymentAmountQuery } from "../../Graphql/calculateCateringPaymentAmount.generated"
import { parseCateringPaymentAmounts } from "../utils/parse-calculate-catering-payment-amount"
import { Center, Group, Stack, Table, Text } from "@mantine/core"
import { useIntl } from "react-intl"

interface ReceiptProps {
  uuid: string
  status: OrderStatusEnum
  items: { name?: string; quantity?: number; totalPrice?: number }[]
  deposit: number
}

const validStatus = new Set([
  OrderStatusEnum.IN_REVISION,
  OrderStatusEnum.OPEN,
  OrderStatusEnum.PAYMENT_PENDING,
  OrderStatusEnum.PENDING,
  OrderStatusEnum.PENDING_REASSIGN,
  OrderStatusEnum.PLACED,
  OrderStatusEnum.PREPARING,
  OrderStatusEnum.READY,
])

export const Receipt = ({ items, uuid, deposit, status }: ReceiptProps) => {
  const intl = useIntl()

  const { data } = useCalculateCateringPaymentAmountQuery({
    variables: {
      order: { uuid },
      serviceTypes: PaymentServiceTypeEnum.PAYMENTS,
    },
    skip: !validStatus.has(status),
  })

  const cateringAmounts = parseCateringPaymentAmounts(data)

  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.receipt.title",
        defaultMessage: "Receipt",
      })}
    >
      <Table bg="gray.0" verticalSpacing="lg" horizontalSpacing="md">
        <tbody>
          <tr>
            <td>
              <Stack spacing={4}>
                {items?.map((item, index) => (
                  <Group key={index} position="apart">
                    <Group spacing={10}>
                      <Text>{item.quantity}</Text>
                      <Text>{item.name}</Text>
                    </Group>
                    <Text>{toFormat(item.totalPrice ?? 0)}</Text>
                  </Group>
                ))}
              </Stack>
            </td>
          </tr>
          <tr>
            <td>
              <Stack spacing={8}>
                <Group position="apart">
                  <Text>
                    {intl.formatMessage({
                      id: "restaurants.catering.detail.card.table.body.subtotal.label",
                      defaultMessage: "Subtotal",
                    })}
                  </Text>
                  <Text>
                    {intl.formatNumber(
                      cateringAmounts.subtotal,
                      CURRENCY_CONFIG
                    )}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>
                    {intl.formatMessage({
                      id: "restaurants.catering.detail.card.table.body.taxes.label",
                      defaultMessage: "Taxes",
                    })}
                  </Text>
                  <Text>
                    {intl.formatNumber(cateringAmounts.taxes, CURRENCY_CONFIG)}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>
                    {intl.formatMessage({
                      id: "restaurants.catering.detail.card.table.body.catering.fee.label",
                      defaultMessage: "Catering fee",
                    })}
                  </Text>
                  <Text>
                    {intl.formatNumber(
                      cateringAmounts.cateringFee,
                      CURRENCY_CONFIG
                    )}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>
                    {intl.formatMessage({
                      id: "restaurants.catering.detail.card.table.body.service.fee.label",
                      defaultMessage: "Service fee",
                    })}
                  </Text>
                  <Text>
                    {intl.formatNumber(
                      cateringAmounts.serviceFee,
                      CURRENCY_CONFIG
                    )}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text fw={600}>
                    {intl.formatMessage({
                      id: "restaurants.catering.detail.card.table.body.total.amount.label",
                      defaultMessage: "Total Amount",
                    })}
                  </Text>
                  <Text fw={600}>
                    {intl.formatNumber(cateringAmounts.total, CURRENCY_CONFIG)}
                  </Text>
                </Group>
              </Stack>
            </td>
          </tr>
          <tr>
            <td>
              <Group p={16} bg="gray.1" position="apart">
                <Text fw={600}>
                  {intl.formatMessage({
                    id: "restaurants.catering.detail.card.table.body.total.deposit.label",
                    defaultMessage: "Deposit",
                  })}
                </Text>
                <Text fw={600}>
                  {intl.formatNumber(deposit, CURRENCY_CONFIG)}
                </Text>
              </Group>
            </td>
          </tr>
          <tr hidden={cateringAmounts.remainingBalance === 0}>
            <td>
              <Stack spacing={16}>
                <Group p={16} bg="gray.1" position="apart">
                  <Text c="gray.7">
                    {intl.formatMessage({
                      id: "restaurants.catering.detail.card.table.body.total.remaining.balance.label",
                      defaultMessage: "Remaining balance",
                    })}
                  </Text>
                  <Text c="gray.7">
                    {intl.formatNumber(
                      cateringAmounts.remainingBalance,
                      CURRENCY_CONFIG
                    )}
                  </Text>
                </Group>
                <Center>
                  <Text c="gray.6">
                    {intl.formatMessage(
                      {
                        id: "restaurants.catering.detail.card.table.body.total.remaining.balance.description",
                        defaultMessage:
                          "The remaining balance of {remainingBalance, number, ::currency/USD} is not final and might be subject to changes requested by the customer",
                      },
                      {
                        remainingBalance: cateringAmounts.remainingBalance,
                      }
                    )}
                  </Text>
                </Center>
              </Stack>
            </td>
          </tr>
        </tbody>
      </Table>
    </MantineCard>
  )
}
