import type { IngredientModel } from "../../../../shared/graphql/generated/types"
import notification from "../../../../ui/notification"
import type { CreateIngredientMutation } from "../GraphQL/createIngredient.generated"
import { useCreateIngredientMutation } from "../GraphQL/createIngredient.generated"
import { useDeleteIngredientMutation } from "../GraphQL/deleteIngredient.generated"
import { useUpdateIngredientMutation } from "../GraphQL/updateIngredient.generated"
import type {
  IIngredient,
  IUpdateIngredienForm,
} from "../ModifierGroupForm/ModifierEditModal/hookforms.interfaces"
import {
  createIngredientInCache,
  deleteIngredientInCache,
  updateIngredientInCache,
} from "../utils/cache-ingredients/ingredients-cache-config"
import { useIntl } from "react-intl"

export const useIngredients = () => {
  const intl = useIntl()
  const editedModifierNotification = () =>
    notification({
      title: intl.formatMessage({
        id: "restaurants.modifiers.group.form.modifier.create.ingredient.success.title",
        defaultMessage: "Success",
      }),
      description: intl.formatMessage({
        id: "restaurants.modifiers.group.form.modifier.create.ingredient.success.description",
        defaultMessage: "Modifier edited",
      }),
      type: "success",
    })

  const [createIngredientMutation, ingredientCreated] =
    useCreateIngredientMutation({
      onCompleted: (result) => {
        createIngredientInCache({
          data: result.createIngredient as IngredientModel,
          variables: {},
        })
      },
    })

  const [updateIngredientMutation, ingredientUpdated] =
    useUpdateIngredientMutation({
      onCompleted: (result) => {
        updateIngredientInCache({
          data: result.updateIngredient as IngredientModel,
          variables: {},
        })
      },
    })

  const [deleteIngredientMutation, ingredientDeleted] =
    useDeleteIngredientMutation({
      onCompleted: (result) => {
        deleteIngredientInCache({
          data: result.deleteIngredient as IngredientModel,
          variables: {},
        })
      },
    })

  const createIngredient = async (
    name: string,
    onSuccess: (result: CreateIngredientMutation) => void,
    onError: (error: Error) => void
  ) => {
    try {
      const { data } = await createIngredientMutation({
        variables: {
          input: {
            name,
          },
        },
      })

      if (data?.createIngredient.uuid) {
        notification({
          title: intl.formatMessage({
            id: "restaurants.modifiers.group.form.modifier.create.ingredient.success.title",
            defaultMessage: "Success",
          }),
          description: intl.formatMessage({
            id: "restaurants.modifiers.group.form.modifier.create.ingredient.success.description",
            defaultMessage: "Modifier created",
          }),
          type: "success",
        })
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const updateIngredient = async (
    ingredient: IUpdateIngredienForm,
    onSuccess: (result: IIngredient) => void,
    onError: (error: Error) => void
  ) => {
    try {
      const { data } = await updateIngredientMutation({
        variables: {
          input: ingredient,
        },
      })

      if (data?.updateIngredient.uuid) {
        editedModifierNotification()
        onSuccess(data.updateIngredient)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const deleteIngredient = async (
    uuid: string,
    onSuccess: (result: IIngredient) => void,
    onError: (error: Error) => void
  ) => {
    try {
      const { data } = await deleteIngredientMutation({
        variables: {
          uuid,
        },
      })

      if (data?.deleteIngredient.uuid) {
        editedModifierNotification()
        onSuccess(data.deleteIngredient)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  return {
    createIngredient,
    ingredientCreated,
    updateIngredient,
    ingredientUpdated,
    deleteIngredient,
    ingredientDeleted,
  }
}
