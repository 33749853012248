import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllModifiersByRestaurantQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllModifiersByRestaurantQuery = { __typename?: 'Query', getAllIngredientsByRestaurant: { __typename?: 'PaginatedIngredientModel', hasNextPage: boolean, endCursor?: string | null, results?: Array<{ __typename?: 'IngredientModel', uuid: string, name: string, description?: string | null, imagePath?: string | null, iconName?: string | null, tags?: Array<string> | null, isMain: boolean, attachment?: { __typename?: 'AttachmentModel', uuid: string, ext: Types.FileExtensionEnum, fileName: string, contentType: Types.ContentTypeEnum, signedUrl: string } | null }> | null } };


export const GetAllModifiersByRestaurantDocument = gql`
    query getAllModifiersByRestaurant($after: String, $name: String, $take: Int) {
  getAllIngredientsByRestaurant(after: $after, name: $name, take: $take) {
    results {
      uuid
      name
      description
      imagePath
      iconName
      tags
      isMain
      attachment {
        uuid
        ext
        fileName
        contentType
        signedUrl
      }
    }
    hasNextPage
    endCursor
  }
}
    `;

/**
 * __useGetAllModifiersByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetAllModifiersByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModifiersByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllModifiersByRestaurantQuery({
 *   variables: {
 *      after: // value for 'after'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetAllModifiersByRestaurantQuery(baseOptions?: Apollo.QueryHookOptions<GetAllModifiersByRestaurantQuery, GetAllModifiersByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllModifiersByRestaurantQuery, GetAllModifiersByRestaurantQueryVariables>(GetAllModifiersByRestaurantDocument, options);
      }
export function useGetAllModifiersByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllModifiersByRestaurantQuery, GetAllModifiersByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllModifiersByRestaurantQuery, GetAllModifiersByRestaurantQueryVariables>(GetAllModifiersByRestaurantDocument, options);
        }
export type GetAllModifiersByRestaurantQueryHookResult = ReturnType<typeof useGetAllModifiersByRestaurantQuery>;
export type GetAllModifiersByRestaurantLazyQueryHookResult = ReturnType<typeof useGetAllModifiersByRestaurantLazyQuery>;
export type GetAllModifiersByRestaurantQueryResult = Apollo.QueryResult<GetAllModifiersByRestaurantQuery, GetAllModifiersByRestaurantQueryVariables>;