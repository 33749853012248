import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnpublishMenuMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type UnpublishMenuMutation = { __typename?: 'Mutation', unpublishMenu: { __typename?: 'RUnpublishMenu', uuid: string } };


export const UnpublishMenuDocument = gql`
    mutation unpublishMenu($uuid: ID!) {
  unpublishMenu(uuid: $uuid) {
    uuid
  }
}
    `;
export type UnpublishMenuMutationFn = Apollo.MutationFunction<UnpublishMenuMutation, UnpublishMenuMutationVariables>;

/**
 * __useUnpublishMenuMutation__
 *
 * To run a mutation, you first call `useUnpublishMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishMenuMutation, { data, loading, error }] = useUnpublishMenuMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnpublishMenuMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishMenuMutation, UnpublishMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishMenuMutation, UnpublishMenuMutationVariables>(UnpublishMenuDocument, options);
      }
export type UnpublishMenuMutationHookResult = ReturnType<typeof useUnpublishMenuMutation>;
export type UnpublishMenuMutationResult = Apollo.MutationResult<UnpublishMenuMutation>;
export type UnpublishMenuMutationOptions = Apollo.BaseMutationOptions<UnpublishMenuMutation, UnpublishMenuMutationVariables>;