import { useModal } from "../../../../../shared/hooks/useModal"
import Container from "../../../../../ui/Container"
import DropDownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import type { ModifierActionsType } from "../../interfaces"
import DeleteDraftModifierGroupModal from "../modals/DeleteDraftModifierGroupModal"
import DeleteModifierGroupModal from "../modals/DeleteModifierGroupModal"
import PublishModifierGroupModal from "../modals/PublishModifierGroupModal"
import UnpublishModifierGroupModal from "../modals/UnpublishModifierGroupModal"
import { Menu } from "antd"
import React, { Suspense } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type DropdownModifiersGroupsProps = {
  actions: Array<ModifierActionsType>
  modifierGroupUUID: string
  isCorporate?: boolean
  onOk?: () => void
}

const DropdownModifiersGroups: React.FC<DropdownModifiersGroupsProps> = (
  props
) => {
  const { actions, modifierGroupUUID, isCorporate, onOk } = props
  const intl = useIntl()

  const allowPublish = actions.includes("PUBLISH")
  const allowUnpublish = actions.includes("UNPUBLISH")
  const allowDelete = actions.includes("DELETE")
  const allowDeleteDraft = actions.includes("DELETE_DRAFT")
  const disableUnpublish = actions.includes("DISABLE_UNPUBLISH")

  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const [
    deleteModalVisible,
    showDeleteConfirmation,
    dismissDeleteConfirmation,
  ] = useModal()
  const [
    deleteDraftModalVisible,
    showDeleteDraftConfirmation,
    dismissDeleteDraftConfirmation,
  ] = useModal()
  const [
    unpublishModalVisible,
    showUnpublishConfirmation,
    dismissUnpublishConfirmation,
  ] = useModal()

  const onPublishOk = () => {
    dismissPublishConfirmation()
    onOk?.()
  }

  const onDeleteOk = () => {
    dismissDeleteConfirmation()
    onOk?.()
  }

  const onDeleteDraftOk = () => {
    dismissDeleteDraftConfirmation()
    onOk?.()
  }

  const onDeleteDraftClick = () => {
    if (modifierGroupUUID) return showDeleteDraftConfirmation()

    notification({
      title: intl.formatMessage({
        id: "restaurants.modifiers.use.modifiers.delete.modifier.success.title",
        defaultMessage: "Modifier deleted",
      }),
      description: intl.formatMessage({
        id: "restaurants.modifiers.use.modifiers.delete.modifier.success.description",
        defaultMessage: "Your modifier was deleted",
      }),
      type: "success",
    })
  }

  const onUnpublishOk = () => {
    dismissUnpublishConfirmation()
    onOk?.()
  }

  const onClickDropdownContainer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  return (
    <StyleDropdown
      className="dropdown-actions"
      display="flex"
      justifyContent="flex-end"
      onClick={onClickDropdownContainer}
    >
      <DropDownAction
        placement="bottomRight"
        align={{ offset: [0, 13] }}
        disabled={!modifierGroupUUID}
        overlay={
          <Container>
            <Menu role="modifiers-group-more-options" selectedKeys={[]}>
              <Menu.Item
                key="dropdown-item-publish-to-locations"
                icon={
                  <Icon
                    remixiconClass="ri-arrow-up-circle-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowPublish}
                onClick={showPublishConfirmation}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.modifiers.dropdown.publish.modifier.group.to.locations.option",
                    defaultMessage: "Publish",
                  })}
                </Text>
              </Menu.Item>

              <Menu.Item
                key="dropdown-item-unpublish-to-locations"
                icon={
                  <Icon
                    remixiconClass="ri-arrow-down-circle-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowUnpublish}
                disabled={disableUnpublish}
                onClick={showUnpublishConfirmation}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.modifiers.dropdown.unpublish.modifier.group.to.locations.option",
                    defaultMessage: "Unpublish",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                key="dropdown-item-delete"
                icon={
                  <Icon
                    remixiconClass="ri-delete-bin-7-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowDelete}
                onClick={showDeleteConfirmation}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.modifiers.dropdown.delete.modifier.option",
                    defaultMessage: "Delete",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                onClick={onDeleteDraftClick}
                key="dropdown-item-delete-draft"
                icon={
                  <Icon
                    remixiconClass="ri-delete-bin-7-line"
                    color="Neutral8"
                  />
                }
                hidden={!allowDeleteDraft}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.modifiers.dropdown.delete.modifier.option",
                    defaultMessage: "Delete",
                  })}
                </Text>
              </Menu.Item>
            </Menu>
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-line"
          size={24}
          color={modifierGroupUUID ? "Primary5" : "Neutral5"}
          cursor="pointer"
        />
      </DropDownAction>
      <Suspense fallback={null}>
        {publishModalVisible && (
          <PublishModifierGroupModal
            modifierGroupUUID={modifierGroupUUID}
            onOk={onPublishOk}
            onCancel={dismissPublishConfirmation}
            isCorporate={isCorporate}
          />
        )}
        {deleteModalVisible && (
          <DeleteModifierGroupModal
            modifierGroupUUID={modifierGroupUUID}
            onOk={onDeleteOk}
            onCancel={dismissDeleteConfirmation}
          />
        )}
        {deleteDraftModalVisible && (
          <DeleteDraftModifierGroupModal
            modifierGroupUUID={modifierGroupUUID}
            onOk={onDeleteDraftOk}
            onCancel={dismissDeleteDraftConfirmation}
          />
        )}
        {unpublishModalVisible && (
          <UnpublishModifierGroupModal
            modifierGroupUUID={modifierGroupUUID}
            onOk={onUnpublishOk}
            onCancel={dismissUnpublishConfirmation}
            isCorporate={isCorporate}
          />
        )}
      </Suspense>
    </StyleDropdown>
  )
}

export default DropdownModifiersGroups

const StyleDropdown = styled(Container)`
  .anticon[disabled] {
    cursor: not-allowed;
  }
`
