import type {
  GetMenuListQuery,
  GetMenuListQueryVariables,
} from "../../../../../GraphQL/Queries/getMenuList.generated"
import { graphqlClient } from "../../../../../shared/graphql/client"
import {
  MenuList,
  MenuStatusEnum,
  MenuTypeEnum,
} from "../../../../../shared/graphql/generated/types"
import type { SaveMenuCacheType } from "./interfaces"
import { readGetMenuListQueryCache } from "./menus-cache-read"
import writeGetMenuListQueryCache from "./menus-cache-write"

const cache = graphqlClient.cache

//INFO: This method validates whether or not the menu exists in the cache and accordingly adds the menu to the cache or updates it.
export const saveMenu = (
  data: SaveMenuCacheType,
  variables: GetMenuListQueryVariables
) => {
  const menuListQuery = readGetMenuListQueryCache({
    cache,
    variables,
  })

  const menus = menuListQuery?.getMenuList.results

  if (menus) {
    const menuListUpdated = menus.map((menu) =>
      data.uuid === menu.uuid ? { ...menu, ...data } : menu
    )
    const menuExists = menuListUpdated.some(
      (updatedMenu) => updatedMenu.uuid === data.uuid
    )

    if (!menuExists) {
      menuListUpdated.push({
        applyToAllLocations: data.applyToAllLocations ?? true,
        isVisible: data.isVisible ?? true,
        combosCount: data.combosCount ?? 0,
        itemsCount: data.itemsCount ?? 0,
        hasSnapshot: data.hasSnapshot ?? false,
        isMain: data.isMain ?? false,
        menuType: data.menuType ?? MenuTypeEnum.FOOD,
        name: data.name ?? "",
        status: data.status ?? MenuStatusEnum.DRAFT,
        uuid: data.uuid,
      })
    }

    const query: GetMenuListQuery = {
      getMenuList: {
        ...menuListQuery.getMenuList,
        results: menuListUpdated,
      },
    }

    writeGetMenuListQueryCache({ cache, query, variables })
  }
}

//INFO: This method removes the menu from the cache
export const deleteMenu = (
  data: { uuid: string },
  variables: GetMenuListQueryVariables
) => {
  const menuListQuery = readGetMenuListQueryCache({
    cache,
    variables,
  })

  const menus = menuListQuery?.getMenuList.results

  if (menus) {
    const filteredMenus = menus.filter((menu) => menu.uuid !== data.uuid)

    const query: GetMenuListQuery = {
      getMenuList: {
        ...menuListQuery.getMenuList,
        results: filteredMenus,
      },
    }

    writeGetMenuListQueryCache({ cache, query, variables })
  }
}

//INFO: This method reorder the menu list from the cache
export const reorderMenuList = (
  data: MenuList[],
  variables: GetMenuListQueryVariables
) => {
  const menuListQuery = readGetMenuListQueryCache({
    cache,
    variables,
  })

  const menus = menuListQuery?.getMenuList.results

  if (menus) {
    const query: GetMenuListQuery = {
      getMenuList: {
        ...menuListQuery.getMenuList,
        results: data,
      },
    }

    writeGetMenuListQueryCache({ cache, query, variables })
  }
}
