import { EMPTY_STRING } from "../../../../../../shared/utils/constant/values"
import Select from "../../../../../../ui/Select"
import Spacer from "../../../../../../ui/Spacer"
import { validationsTitle } from "../../GenericForm/validations.title"
import { SELECT_LIST_HEIGHT } from "../constants"
import { useSpecificCustomer } from "./hooks/useSpecificCustomer"
import { useIntl } from "react-intl"

export const SpecificCustomer = () => {
  const {
    users,
    onUserSelected,
    selectedUsers,
    search,
    isEditMode,
    handleSearchUsers,
    onScrollToFetchMore,
    errorMessage,
  } = useSpecificCustomer()

  const intl = useIntl()

  const selectTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.eligible.customer.specific.select.title",
    defaultMessage: "Choose Specific Customer(s)",
  })

  return (
    <>
      <Select
        allowSearch
        hasError={!!errorMessage}
        helperText={
          errorMessage
            ? intl.formatMessage(validationsTitle[errorMessage])
            : EMPTY_STRING
        }
        disabled={isEditMode}
        closableTags={!isEditMode}
        options={users}
        searchValue={search}
        mode="multiple"
        optionFilterProp="label"
        onChange={onUserSelected}
        onPopupScroll={onScrollToFetchMore}
        onSearch={handleSearchUsers}
        label={selectTitle}
        listHeight={SELECT_LIST_HEIGHT}
        value={selectedUsers?.map((user) => user.uuid)}
      />
      {errorMessage && <Spacer size={26} />}
    </>
  )
}
