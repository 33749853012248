import { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"
import { CustomOrderStatusTitle } from "../../../../../shared/titles/orderStatus.title"
import OrderStatusBadge from "../../OrderStatusBadge"
import OrdersFilterContent from "../OrdersFilterContent"
import React, { useState } from "react"
import { useIntl } from "react-intl"

interface FilterByOrderStatusProps {
  filterLabel: string
  clearFilterOptions: () => void
}

const HIDDEN_STATUSES = new Set([
  OrderStatusEnum.CHECK_OUT,
  OrderStatusEnum.PAYMENT_PENDING,
])

export const FilterByOrderStatus: React.FC<FilterByOrderStatusProps> = ({
  filterLabel,
  clearFilterOptions,
}) => {
  const intl = useIntl()

  const [filteredOrderStatus, setFilteredOrderStatus] = useState<string[]>(
    Object.keys(CustomOrderStatusTitle)
  )
  const [orderStatusName, setCustomerName] = useState<string | undefined>()

  const searchOrderStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredOrderStatusName = event.target.value

    if (filteredOrderStatusName) {
      const results = Object.keys(CustomOrderStatusTitle).filter(
        (orderStatus) => {
          return orderStatus
            .toLocaleLowerCase()
            .includes(filteredOrderStatusName.toLowerCase())
        }
      )
      setFilteredOrderStatus(results)
    } else {
      setFilteredOrderStatus(Object.keys(CustomOrderStatusTitle))
    }
    setCustomerName(filteredOrderStatusName)
  }

  return (
    <OrdersFilterContent
      filterLabel={filterLabel}
      searchTerm={orderStatusName}
      onSearchTermChange={searchOrderStatus}
      filterKey="order-status"
      results={filteredOrderStatus
        .filter((status) => !HIDDEN_STATUSES.has(status as OrderStatusEnum))
        .map((status) => ({
          value: status,
          render: (
            <OrderStatusBadge
              status={status as keyof typeof CustomOrderStatusTitle}
            />
          ),
          name: intl.formatMessage(
            CustomOrderStatusTitle[
              status as keyof typeof CustomOrderStatusTitle
            ]
          ),
        }))}
      clearSearch={clearFilterOptions}
    />
  )
}
