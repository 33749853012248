import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveWhiteLabelConfigMutationVariables = Types.Exact<{
  data: Types.AddWhiteLabelConfigInput;
}>;


export type SaveWhiteLabelConfigMutation = { __typename?: 'Mutation', createOrUpdateWhiteLabelConfigToRestaurant: { __typename?: 'RestaurantWhiteLabelConfigModel', uuid: string, whiteLabelConfig: any, attachment?: { __typename?: 'RestaurantAttachmentModel', uuid: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string, type: string } | null } };


export const SaveWhiteLabelConfigDocument = gql`
    mutation saveWhiteLabelConfig($data: AddWhiteLabelConfigInput!) {
  createOrUpdateWhiteLabelConfigToRestaurant(data: $data) {
    uuid
    whiteLabelConfig
    attachment {
      uuid
      contentType
      ext
      fileName
      signedUrl
      type
    }
  }
}
    `;
export type SaveWhiteLabelConfigMutationFn = Apollo.MutationFunction<SaveWhiteLabelConfigMutation, SaveWhiteLabelConfigMutationVariables>;

/**
 * __useSaveWhiteLabelConfigMutation__
 *
 * To run a mutation, you first call `useSaveWhiteLabelConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWhiteLabelConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWhiteLabelConfigMutation, { data, loading, error }] = useSaveWhiteLabelConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveWhiteLabelConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveWhiteLabelConfigMutation, SaveWhiteLabelConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWhiteLabelConfigMutation, SaveWhiteLabelConfigMutationVariables>(SaveWhiteLabelConfigDocument, options);
      }
export type SaveWhiteLabelConfigMutationHookResult = ReturnType<typeof useSaveWhiteLabelConfigMutation>;
export type SaveWhiteLabelConfigMutationResult = Apollo.MutationResult<SaveWhiteLabelConfigMutation>;
export type SaveWhiteLabelConfigMutationOptions = Apollo.BaseMutationOptions<SaveWhiteLabelConfigMutation, SaveWhiteLabelConfigMutationVariables>;