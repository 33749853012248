import OrderDetailModal from "./OrderDetailModal"
import React from "react"
import { useParams } from "react-router-dom"

export interface IOrderDetailParams {
  orderUUID: string
}

export const OrderDetail: React.FC = () => {
  const { orderUUID } = useParams<IOrderDetailParams>()

  return <OrderDetailModal orderUUID={orderUUID} />
}
