import type { RuuidInput } from "../../../../../shared/graphql/generated/types"
import { storedBreadcrumbs } from "../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../shared/routes/paths"
import { breadcrumbs } from "../../../../../ui/Breadcrumb"
import Breadcrumb from "../../../../../ui/Breadcrumb/Breadcrumb"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import { ModalFull } from "../../../../../ui/ModalFull/ModalFull"
import Spacer from "../../../../../ui/Spacer"
import { formNotification } from "../../../../../ui/notification"
import type { ILocationsParams } from "../../../../Settings/Locations/hookforms.interfaces"
import CorporateDetailHeader from "../../../components/CorporateDetailHeader/CorporateDetailHeader"
import type { GetComboDetailQuery } from "../GraphQL/getComboDetail.generated"
import ComboPriceForm from "../forms/ComboPriceForm/ComboPriceForm"
import { COMBO_FILTER } from "../forms/SingleItemForm/utils/constants"
import ComboInfoDetailForm from "../forms/corporateView/ComboInfoDetailForm"
import ComboItemsDetailForm from "../forms/corporateView/ComboItemsDetailForm"
import { CorporateComboFormResolver } from "../forms/corporateView/CorporateComboForm.yup"
import { useCombos } from "../hooks/useCombos"
import type { ICorporateComboForm } from "../interfaces/hookforms.interfaces"
import omit from "lodash/omit"
import React from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt, useHistory, useParams } from "react-router-dom"

type CorporateComboDetailProps = {
  combo: GetComboDetailQuery["getComboDetail"]
}

const CorporateComboDetail: React.FC<CorporateComboDetailProps> = ({
  combo,
}) => {
  const intl = useIntl()
  const { saveCorporateCombo, corporateComboSaved } = useCombos({})
  const {
    uuid,
    isSoldOut,
    isVisible,
    updatedAt,
    publishedAt,
    menus,
    snapshotUpdatedAt,
  } = combo
  const { locationUUID } = useParams<ILocationsParams>()
  const { restaurants } = paths
  const { push } = useHistory()
  const fullPrice = combo.fullPrice ?? 0
  const currentMenus = menus?.filter((menu) => !menu.isMain) ?? []

  const pageName =
    combo.name ??
    intl.formatMessage({
      id: "restaurants.menu.items.corporate.combo.detail.page.name.default",
      defaultMessage: "Corporate Menu Item",
    })

  const formMethods = useForm<ICorporateComboForm>({
    mode: "all",
    resolver: CorporateComboFormResolver,
    defaultValues: {
      uuid,
      updatedAt: updatedAt ?? snapshotUpdatedAt,
      isSoldOut: isSoldOut ?? false,
      isVisible: isVisible ?? true,
      name: combo.name,
      menus:
        menus?.filter((menu) => !menu.isMain).map((menu) => menu.uuid) ?? [],
      discountPercentage: combo.discountPercentage ?? 0,
      priceOverride: combo.priceOverride ?? 0,
    },
  })

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty },
  } = formMethods

  const [itemUpdatedAt, priceOverride] = useWatch({
    control,
    name: ["updatedAt", "priceOverride"],
  })

  const redirectToList = () => {
    const path =
      restaurants.locations.itemsByLocation(locationUUID) + COMBO_FILTER

    reset({}, { keepValues: true })

    if (storedBreadcrumbs().length === 1) {
      push(path)
    }
  }

  const onSave = () => {
    handleSubmit(
      async (formData) => {
        const input = omit(formData, "updatedAt")
        const menusUUIDs: RuuidInput[] | undefined = input.menus?.map(
          (menu) => ({
            uuid: menu,
          })
        )

        await saveCorporateCombo(
          { ...input, menus: menusUUIDs },
          () => {
            setValue("updatedAt", new Date())
            reset({}, { keepValues: true })
          },
          showGraphqlErrors
        )

        redirectToList()
      },
      (error) => {
        const priceErrorMessage = intl.formatMessage({
          id: "restaurants.menu.items.corporate.combo.detail.page.notification.input.error.message",
          defaultMessage: "Complete all the values",
        })
        const discountPercentageError = error?.discountPercentage
          ? { message: priceErrorMessage }
          : undefined
        const priceOverrideError = error?.priceOverride
          ? { message: priceErrorMessage }
          : undefined

        formNotification({
          key: "lsm-corporate-combo-detail-error",
          header: intl.formatMessage({
            id: "restaurants.menu.items.corporate.combo.detail.page.notification.error.header",
            defaultMessage: "You can’t save this corporate combo",
          }),
          title: intl.formatMessage({
            id: "restaurants.menu.items.corporate.combo.detail.page.notification.error.title",
            defaultMessage: "To save a corporate combo you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(priceOverrideError && { priceOverride: priceOverrideError }),
            ...(discountPercentageError && {
              discountPercentage: discountPercentageError,
            }),
          },
        })
      }
    )()
  }

  return (
    <ModalFull
      title={
        <CorporateDetailHeader
          enablePreview={true}
          showPreview={true}
          loadingSave={corporateComboSaved.loading}
          enableSave={isDirty}
          lastSavedAt={itemUpdatedAt}
          lastPublishedAt={publishedAt}
          onSave={onSave}
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.corporateItemDetail}
            pageName={pageName}
          />
        </CorporateDetailHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ComboInfoDetailForm combo={combo} currentMenus={currentMenus} />
        <ComboItemsDetailForm combo={combo} />
        <Spacer size={24} />
        <ComboPriceForm fullPrice={fullPrice} priceOverride={priceOverride} />
      </FormProvider>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </ModalFull>
  )
}

export default CorporateComboDetail
