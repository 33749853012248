import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import { requestState } from "../../../shared/hooks/useAsync"
import { useAsync } from "../../../shared/hooks/useAsync"
import paths from "../../../shared/routes/paths"
import storage from "../../../shared/utils/storage"
import Container from "../../../ui/Container"
import Loader from "../../../ui/Loader"
import type { TokenResponseType } from "../api"
import { validateMagicLink, validateMemberInviteLink } from "../api"
import queryString from "query-string"
import React, { useCallback, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

export const ValidateToken: React.FC = () => {
  const { general } = paths
  const { search, pathname } = useLocation()
  const token = queryString.parse(search)?.t?.toString() || ""
  const history = useHistory()
  const { initRestaurants } = useGeneralContext()

  const validateLink = useCallback(() => {
    if (pathname === general.validate) {
      return validateMagicLink({
        token,
      })
    } else if (pathname === general.validateMember) {
      return validateMemberInviteLink({ token })
    }

    return Promise.reject()
  }, [general.validate, general.validateMember, pathname, token])

  const { execute, status, error } = useAsync(() => validateLink())

  const validateLinkToken = useCallback(async () => {
    const result: TokenResponseType = await execute()

    if (result) {
      await initRestaurants(result)
      history.push(paths.restaurants.root)
    } else {
      storage.clear()
      window.location.pathname = paths.guest.loginError
    }
  }, [execute, history, initRestaurants])

  useEffect(() => {
    if (error) {
      history.push(paths.guest.loginError)
    }
  }, [error, history])

  useEffect(() => {
    if (!!token && status === requestState.IDLE) {
      validateLinkToken()
    }
  }, [status, token, validateLinkToken])

  return (
    <Container centered fullScreen>
      <Loader size="large" />
    </Container>
  )
}
