import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TimeEntriesByLocationEmployeeQueryVariables = Types.Exact<{
  endDate: Types.Scalars['String'];
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type TimeEntriesByLocationEmployeeQuery = { __typename?: 'Query', timeEntriesByLocationEmployee: { __typename?: 'EmployeeTimeEntriesReport', notDistributedTips: number, employeeTimeEntries: Array<{ __typename?: 'LocationEmployeeTimeEntry', deactivatedAt?: any | null, firstName: string, lastName: string, payRate?: number | null, totalAmount: number, totalDurationInSeconds: number, totalTips: number, uuid: string, attachment?: { __typename?: 'AttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string, uuid: string } | null, role: { __typename?: 'RoleInfo', name: string, roleCode: Types.EmployeeRoleCodeEnum, uuid: string }, timeEntries: Array<{ __typename?: 'BasicTimeEntry', amount: number, day: Types.WeekDaysEnum, duration: number, endDate?: any | null, notes?: string | null, startDate: any, tips: number, uuid: string, modifiedStartDate?: any | null, modifiedEndDate?: any | null, isRunning: boolean }> }>, payrollReport: { __typename?: 'PayrollReport', employeesWorked: number, tipsReceived: number, totalCost: number, totalDuration: number }, refundedInvoices: Array<{ __typename?: 'RefundedInvoice', totalTip: number, type: Types.RefundTypeEnum }> } };


export const TimeEntriesByLocationEmployeeDocument = gql`
    query timeEntriesByLocationEmployee($endDate: String!, $startDate: String) {
  timeEntriesByLocationEmployee(endDate: $endDate, startDate: $startDate) {
    employeeTimeEntries {
      attachment {
        contentType
        ext
        fileName
        signedUrl
        uuid
      }
      deactivatedAt
      firstName
      lastName
      payRate
      role {
        name
        roleCode
        uuid
      }
      timeEntries {
        amount
        day
        duration
        endDate
        notes
        startDate
        tips
        uuid
        modifiedStartDate
        modifiedEndDate
        isRunning
      }
      totalAmount
      totalDurationInSeconds
      totalTips
      uuid
    }
    notDistributedTips
    payrollReport {
      employeesWorked
      tipsReceived
      totalCost
      totalDuration
    }
    refundedInvoices {
      totalTip
      type
    }
  }
}
    `;

/**
 * __useTimeEntriesByLocationEmployeeQuery__
 *
 * To run a query within a React component, call `useTimeEntriesByLocationEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntriesByLocationEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntriesByLocationEmployeeQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useTimeEntriesByLocationEmployeeQuery(baseOptions: Apollo.QueryHookOptions<TimeEntriesByLocationEmployeeQuery, TimeEntriesByLocationEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeEntriesByLocationEmployeeQuery, TimeEntriesByLocationEmployeeQueryVariables>(TimeEntriesByLocationEmployeeDocument, options);
      }
export function useTimeEntriesByLocationEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeEntriesByLocationEmployeeQuery, TimeEntriesByLocationEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeEntriesByLocationEmployeeQuery, TimeEntriesByLocationEmployeeQueryVariables>(TimeEntriesByLocationEmployeeDocument, options);
        }
export type TimeEntriesByLocationEmployeeQueryHookResult = ReturnType<typeof useTimeEntriesByLocationEmployeeQuery>;
export type TimeEntriesByLocationEmployeeLazyQueryHookResult = ReturnType<typeof useTimeEntriesByLocationEmployeeLazyQuery>;
export type TimeEntriesByLocationEmployeeQueryResult = Apollo.QueryResult<TimeEntriesByLocationEmployeeQuery, TimeEntriesByLocationEmployeeQueryVariables>;