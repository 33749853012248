import { IntlMessage } from "../../../../shared/types"
import Badge from "../../../../ui/Badge"
import Container from "../../../../ui/Container"
import Text from "../../../../ui/Typography/Text"
import React from "react"
import { useIntl } from "react-intl"
import { IBaseThemeColors } from "styled-components"

export enum StatusBadgeEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

interface StatusContent {
  color: keyof IBaseThemeColors
  label: IntlMessage
}

type Status = Record<keyof typeof StatusBadgeEnum, StatusContent>

const statusData: Status = {
  DRAFT: {
    color: "Neutral5",
    label: {
      id: "restaurants.components.status.badge.draft.text",
      defaultMessage: "Draft",
    },
  },
  PUBLISHED: {
    color: "Success5",
    label: {
      id: "restaurants.components.status.badge.published.text",
      defaultMessage: "Published",
    },
  },
  UNPUBLISHED: {
    color: "Neutral5",
    label: {
      id: "restaurants.components.status.badge.published.text",
      defaultMessage: "Unpublished",
    },
  },
}

interface StatusBadgeProps {
  status: keyof typeof StatusBadgeEnum
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status = "DRAFT" }) => {
  const intl = useIntl()

  return (
    <Container
      aria-label="badge-component"
      display="flex"
      flexDirection="row"
      gap="8px"
    >
      <Text size="s">
        <Badge color={statusData[status].color} />
        {intl.formatMessage(statusData[status].label)}
      </Text>
    </Container>
  )
}

export default StatusBadge
