import { useGetItemListQuery } from "../../GraphQL/Queries/getItemList.generated"
import { getComboListMock } from "../../shared/mock/getComboList.mock"
import { showGraphqlErrors } from "../../ui/ErrorList"
import { useGeneralContext } from "../contexts/StoreProvider"
import { useQueryMock } from "../mock/useQuery.mock"
import get from "lodash/get"

export enum MenuItemsEnum {
  items = "items",
  combos = "combos",
}

export const useMenuItems = (options: Array<`${MenuItemsEnum}`>) => {
  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const GetItems = () => {
    const { data: itemsData } = useGetItemListQuery({
      variables: {
        take: 1,
        isPublished: true,
      },
      skip: !currentRestaurantUUID,
      onError: showGraphqlErrors,
      fetchPolicy: "cache-and-network",
    })

    return get(itemsData, "getItemList.results", [])
  }

  // TODO: change query mock to new real query
  const GetCombos = () => {
    const { data: combosData } = useQueryMock(
      { data: getComboListMock },
      {
        variables: { name: "" },
      }
    )

    return get(combosData, "getCombos.results", [])
  }

  return {
    hasItems: options.includes("items") && GetItems(),
    hasCombos: options.includes("combos") && GetCombos(),
  }
}
