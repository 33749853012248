import yup from "../../../../shared/yup"
import { IDiscountForm } from "../interfaces/hookforms.interfaces"
import {
  CODE_NAME,
  MAX_INPUT_VALUE,
  PERCENT_OR_AMOUNT_NAME,
} from "./GenericForm/constants"
import {
  CODE_EXISTS_ERROR_ID,
  CODE_INPUT_DESCRIPTION_ID,
  MAX_VALUE_100_ERROR_ID,
  MAX_VALUE_INPUT_ERROR_ID,
  MORE_THAN_ZERO_ERROR_ID,
  REQUIRED_FIELD_ERROR_ID,
} from "./GenericForm/validations.title"
import { TypeEnum } from "./RadioGroupOptions/typeOptions"
import { AppliesToSchema } from "./Rules/AppliesTo/AppliesToRule.yup"
import { DateAndTimeSchema } from "./Rules/DateAndTimeRule/DateAndTimeRule.yup"
import { EligibleCustomerSchema } from "./Rules/EligibleCustomer/EligibleCustomer.yup"
import { UsageLimitSchema } from "./Rules/UsageLimit/UsageLimitRule.yup"
import { yupResolver } from "@hookform/resolvers/yup"

const MAX_PERCENTAGE_VALUE = 100

const DiscountFormSchema = yup.object().shape({
  codeExists: yup.boolean(),
  code: yup
    .string()
    .min(4, CODE_INPUT_DESCRIPTION_ID)
    .required(CODE_INPUT_DESCRIPTION_ID)
    .matches(/^[\dA-Za-z]*$/, CODE_INPUT_DESCRIPTION_ID)
    .test("ifCodeExists", (value, context) => {
      const parent = context.parent as { codeExists: boolean }

      if (parent.codeExists) {
        throw new yup.ValidationError(CODE_EXISTS_ERROR_ID, value, CODE_NAME)
      }

      return true
    }),
  percentOrAmount: yup
    .number()
    .moreThan(0, MORE_THAN_ZERO_ERROR_ID)
    .nullable()
    .required(REQUIRED_FIELD_ERROR_ID)
    .max(MAX_INPUT_VALUE, MAX_VALUE_INPUT_ERROR_ID)
    .test("ifPercentageIsMoreThanOneHundred", (value, fields) => {
      const { type } = fields.parent as IDiscountForm

      if (
        value &&
        type === TypeEnum.PERCENTAGE &&
        value > MAX_PERCENTAGE_VALUE
      ) {
        throw new yup.ValidationError(
          MAX_VALUE_100_ERROR_ID,
          value,
          PERCENT_OR_AMOUNT_NAME
        )
      }

      return true
    }),
  rules: yup.object().shape({
    dateAndTime: DateAndTimeSchema,
    appliesTo: AppliesToSchema,
    usageLimit: UsageLimitSchema,
    eligibleCustomer: EligibleCustomerSchema,
  }),
})

export const discountFormResolver = yupResolver(DiscountFormSchema)
