import DoorDashLogo from "../../../../../shared/assets/images/DoorDash_logo.png"
import onFleetLogo from "../../../../../shared/assets/images/Onfleet_logo.png"
import type { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import { IntlMessage } from "../../../../../shared/types"

type IDeliveryProviderService = {
  [key in DeliveryIntegrationEnum]: {
    logo: string
    apiKeyLink: string
    goToButton: IntlMessage
    termnsAndConditionsLink: string
    termnsAndConditions: IntlMessage
    termnsAndConditionsLabel: IntlMessage
    title: IntlMessage
    docs: IntlMessage
  }
}

export const DeliveryProviders: IDeliveryProviderService = {
  ONFLEET: {
    logo: onFleetLogo,
    apiKeyLink: "https://www.onfleet.com/",
    goToButton: {
      id: "restaurants.orders.order.detail.order.delivery.service.go.onfleet",
      defaultMessage: "Go to Onfleet",
    },
    termnsAndConditionsLink: "https://www.onfleet.com/",
    termnsAndConditions: {
      id: "restaurants.orders.order.detail.order.delivery.service.terms.conditions.description.onfleet",
      defaultMessage:
        "Sed ut perspiciatis, unde omnis iste natus error accusantium doloremque laudantium OnFleet.",
    },
    termnsAndConditionsLabel: {
      id: "restaurants.orders.order.detail.order.delivery.service.terms.conditions.onfleet",
      defaultMessage: "Terms and Conditions of OnFleet: ",
    },
    title: {
      id: "restaurants.orders.order.delivery.service.onfleet",
      defaultMessage: "OnFleet",
    },
    docs: {
      id: "settings.restaurant.delivery.integration.add.delivery.integration.form.api.key.description.onfleet",
      defaultMessage: "OnFleet Docs",
    },
  },
  DOORDASH: {
    logo: DoorDashLogo,
    apiKeyLink: "https://www.doordash.com/",
    goToButton: {
      id: "restaurants.orders.order.detail.order.delivery.service.go.doordash",
      defaultMessage: "Go to Doordash",
    },
    termnsAndConditionsLink: "https://www.doordash.com/",
    termnsAndConditions: {
      id: "restaurants.orders.order.detail.order.delivery.service.terms.conditions.description.doordash",
      defaultMessage:
        "Sed ut perspiciatis, unde omnis iste natus error accusantium doloremque laudantium Doordash.",
    },
    termnsAndConditionsLabel: {
      id: "restaurants.orders.order.detail.order.delivery.service.terms.conditions.doordash",
      defaultMessage: "Terms and Conditions of DoorDash: ",
    },
    title: {
      id: "restaurants.orders.order.delivery.service.doordash",
      defaultMessage: "Doordash",
    },
    docs: {
      id: "settings.restaurant.delivery.integration.add.delivery.integration.form.api.key.description.doordash",
      defaultMessage: "Doordash Docs",
    },
  },
}
