import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeesQueryVariables = Types.Exact<{
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EmployeesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'EmployeeWithLastTimeEntry', uuid: string, phone: string, payRate?: number | null, allowTip: boolean, lastName: string, firstName: string, deactivatedAt?: any | null, code: string, role: { __typename?: 'RoleInfo', uuid: string, roleCode: Types.EmployeeRoleCodeEnum, name: string }, lastTimeEntry?: { __typename?: 'LastTimeEntry', uuid: string, startDate: any } | null, attachment?: { __typename?: 'AttachmentModel', uuid: string, signedUrl: string } | null }> };


export const EmployeesDocument = gql`
    query Employees($isActive: Boolean) {
  employees(isActive: $isActive) {
    uuid
    role {
      uuid
      roleCode
      name
    }
    phone
    payRate
    lastTimeEntry {
      uuid
      startDate
    }
    allowTip
    lastName
    firstName
    deactivatedAt
    code
    attachment {
      uuid
      signedUrl
    }
  }
}
    `;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;