import {
  ALL_DAY_VALUE,
  datePickerFormat,
} from "../../../../../../shared/utils/constant/dateFormats"
import Tag from "../../../../../../ui/Tag"
import { DateRule } from "../../../interfaces/dateRule.interfaces"
import moment from "moment-timezone"
import { useIntl } from "react-intl"

interface HeaderDateTagProps {
  date?: DateRule
}

export const HeaderDateTag = ({ date }: HeaderDateTagProps) => {
  const intl = useIntl()

  if (!date?.date || !date?.hour?.meridium || !date?.hour?.time) {
    return null
  }

  const isAllDay = date.hour.time === ALL_DAY_VALUE
  const formattedDate = moment(date.date).format(datePickerFormat)
  const timeOrAllDay = isAllDay
    ? intl.formatMessage({
        id: "restaurants.discounts.forms.rules.date.and.time.header.is.all.day",
        defaultMessage: "All Day",
      })
    : date.hour.time

  return (
    <Tag borderStyle="solid">
      {formattedDate} | {timeOrAllDay} {isAllDay ? null : date.hour.meridium}
    </Tag>
  )
}
