import type { AttachmentModel } from "../graphql/generated/types"
import type { RcFile } from "antd/lib/upload/interface"
import { useState } from "react"

export const useFileBlobUrl = <
  TAttachmentModel extends { imageFile?: RcFile },
  TAttachment = AttachmentModel
>() => {
  const [fileBlobUrl, setFileBlobUrl] = useState<string | null>(null)

  const createFileBlobUrl = async (attachment?: TAttachmentModel) => {
    return new Promise((resolve) => {
      if (attachment && attachment.imageFile) {
        const blobUrl = URL.createObjectURL(attachment.imageFile)

        setFileBlobUrl(blobUrl)
      }

      resolve(true)
    })
  }

  const createAttachmentObject = (attachment?: TAttachment | null) => {
    if (fileBlobUrl && attachment) {
      const signedUrl = fileBlobUrl

      setFileBlobUrl(null)

      return {
        ...attachment,
        signedUrl,
      }
    }

    if (attachment) {
      return attachment
    }

    return null
  }

  return [fileBlobUrl, createFileBlobUrl, createAttachmentObject] as const
}
