import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRestaurantAttachmentMutationVariables = Types.Exact<{
  data: Types.DeleteAttachmentInput;
}>;


export type DeleteRestaurantAttachmentMutation = { __typename?: 'Mutation', deleteRestaurantAttachment: { __typename?: 'DeletedRestaurantAttachmentModel', uuid: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, type: string } };


export const DeleteRestaurantAttachmentDocument = gql`
    mutation deleteRestaurantAttachment($data: DeleteAttachmentInput!) {
  deleteRestaurantAttachment(data: $data) {
    uuid
    contentType
    ext
    fileName
    type
  }
}
    `;
export type DeleteRestaurantAttachmentMutationFn = Apollo.MutationFunction<DeleteRestaurantAttachmentMutation, DeleteRestaurantAttachmentMutationVariables>;

/**
 * __useDeleteRestaurantAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantAttachmentMutation, { data, loading, error }] = useDeleteRestaurantAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteRestaurantAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestaurantAttachmentMutation, DeleteRestaurantAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRestaurantAttachmentMutation, DeleteRestaurantAttachmentMutationVariables>(DeleteRestaurantAttachmentDocument, options);
      }
export type DeleteRestaurantAttachmentMutationHookResult = ReturnType<typeof useDeleteRestaurantAttachmentMutation>;
export type DeleteRestaurantAttachmentMutationResult = Apollo.MutationResult<DeleteRestaurantAttachmentMutation>;
export type DeleteRestaurantAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteRestaurantAttachmentMutation, DeleteRestaurantAttachmentMutationVariables>;