import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Select, { SelectDropdownFooter } from "../../../../../ui/Select"
import { useIngredients } from "../../hooks/useIngredients"
import type { IngredientType } from "../hookforms.interfaces"
import { IngredientResolver } from "./IngredientResolver.yup"
import type { IUpdateIngredienForm } from "./hookforms.interfaces"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type Props = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  ingredientItem: IngredientType
  isCorporate: boolean
}

export const ModifierEditModal: React.FC<Props> = ({
  onClose,
  ingredientItem,
  isCorporate,
  ...props
}) => {
  const intl = useIntl()

  const [category, setCategory] = useState<string>()

  const { updateIngredient, ingredientUpdated } = useIngredients()

  const formMethods = useForm<IUpdateIngredienForm>({
    mode: "onChange",
    resolver: IngredientResolver,
    defaultValues: { uuid: ingredientItem.uuid, name: ingredientItem.name },
  })

  const {
    control,
    getValues,
    setValue,
    formState: { isDirty, isValid, errors },
  } = formMethods

  const listCategories = ingredientItem.tags?.map((categoryTag: string) => {
    return { value: categoryTag, label: categoryTag }
  })

  const onUpdateIngredient = async () => {
    const formData = getValues()

    await updateIngredient(
      formData,
      (result) => {
        if (result.uuid) {
          onClose()
        }
      },
      showGraphqlErrors
    )
  }

  const clearCategory = () => setCategory(undefined)

  const onCreateCategory = () => {
    if (!category) {
      return
    }

    setValue("categoryName", category)
    clearCategory()
    onUpdateIngredient()
  }

  const handleSearch = (name: string) => {
    setCategory(name.charAt(0).toUpperCase() + name.slice(1))
  }

  const renderDropdown = (dropdown: React.ReactElement) => {
    const found = dropdown.props?.options?.some?.(
      (option: { label: string }) => option.label === category
    )

    return (
      <>
        {dropdown}
        {!found && category && (
          <SelectDropdownFooter
            searchValue={category}
            onButtonClick={onCreateCategory}
          />
        )}
      </>
    )
  }

  return (
    <Modal
      {...props}
      onCancel={onClose}
      footer={
        <Container display="flex" justifyContent="flex-end" gap="8px">
          <Button
            title={intl.formatMessage({
              id: "restaurants.modifiers.group.form.modifier.edit.modal.button.cancel",
              defaultMessage: "Cancel",
            })}
            hierarchy="secondary"
            onClick={onClose}
          />

          <Button
            title={intl.formatMessage({
              id: "restaurants.modifiers.group.form.modifier.edit.modal.button.ok",
              defaultMessage: "Ok",
            })}
            onClick={onUpdateIngredient}
            disabled={!isDirty || !isValid}
          />
        </Container>
      }
      title={intl.formatMessage({
        id: "restaurants.modifiers.group.form.modifier.edit.modal.title",
        defaultMessage: "Edit Modifier",
      })}
      destroyOnClose
    >
      <Container display="flex" flexDirection="column" gap="16px">
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              label={intl.formatMessage({
                id: "restaurants.modifiers.group.form.modifier.edit.modal.input.name.label",
                defaultMessage: "Modifier Name",
              })}
              requirement="required"
              className="edit-ingredient-input"
              hasError={!!errors.name}
              loading={ingredientUpdated.loading}
              capitalizeFirstLetter
            />
          )}
        />
        {!isCorporate && (
          <Controller
            control={control}
            name="categoryName"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                options={listCategories}
                label={intl.formatMessage({
                  id: "restaurants.modifiers.group.form.modifier.edit.modal.select.category.label",
                  defaultMessage: "Category",
                })}
                placeholder={intl.formatMessage({
                  id: "restaurants.modifiers.group.form.modifier.edit.modal.select.placeholder",
                  defaultMessage: "Add Modifier",
                })}
                hasError={!!errors?.categoryName}
                onChange={onChange}
                dropdownRender={renderDropdown}
                onSearch={handleSearch}
                optionFilterProp="label"
                autoClearSearchValue
                hideSearchIcon
                allowSearch
              />
            )}
          />
        )}
      </Container>
    </Modal>
  )
}
