import { useGetModifierGroupDetailQuery } from "../../../../GraphQL/Queries/getModifierGroupDetail.generated"
import emptyIcon from "../../../../shared/assets/icons/empty_icon.svg"
import Button from "../../../../ui/Button"
import EmptyState from "../../../../ui/EmptyState"
import ModalFull, { ModalFullSkeleton } from "../../../../ui/ModalFull"
import ErrorPage from "../../../ErrorPage"
import CorporateModifierDetail from "../CorporateModifierDetail/CorporateModifierDetail"
import EditModifier from "./EditModifier"
import get from "lodash/get"
import React from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

type ModifierContainerProps = {
  isCorporate: boolean
}

export const EditModifierContainer: React.FC<ModifierContainerProps> = ({
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { modifierUUID } = useParams<{ modifierUUID: string }>()
  const history = useHistory()

  const { data, loading, error } = useGetModifierGroupDetailQuery({
    variables: { uuid: modifierUUID },
    fetchPolicy: "network-only",
  })

  const isMain = get(data, "getModifierGroupDetail.isMain")
  const modifierGroup = get(data, "getModifierGroupDetail")

  const onClickHandler = () => {
    history.goBack()
  }

  if (loading) return <ModalFullSkeleton />

  if (error) return <ErrorPage error={error} />

  if (!modifierGroup)
    return (
      <ModalFull visible>
        <EmptyState
          title={intl.formatMessage({
            id: "restaurants.modifiers.edit.modifier.container.empty.title",
            defaultMessage: "Modifier not found",
          })}
          button={
            <StyledButton
              hierarchy="secondary"
              onClick={onClickHandler}
              title="Go Back"
            />
          }
          image={emptyIcon}
          fullScreen
        />
      </ModalFull>
    )

  if (!isCorporate && isMain)
    return <CorporateModifierDetail modifierGroup={modifierGroup} />

  return (
    <EditModifier modifierGroup={modifierGroup} isCorporate={isCorporate} />
  )
}

export const EditModifierContainerLocation = () => (
  <EditModifierContainer isCorporate={false} />
)

const StyledButton = styled(Button)`
  margin-top: 24px;
`
