import { useTimeEntriesContext } from "../../TimeEntriesContext"
import TimeEntryForm from "../../TimeEntryForm"
import { Modal, Stack, Title } from "@mantine/core"
import { useIntl } from "react-intl"

interface CreateEntryModalProps {
  opened: boolean
  onClose: () => void
}

export const CreateEntryModal = (props: CreateEntryModalProps) => {
  const { opened, onClose } = props
  const intl = useIntl()
  const timeEntries = useTimeEntriesContext()

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={
        <Title order={4}>
          {intl.formatMessage({
            id: "restaurant.locations.time.entries.modal.title",
            defaultMessage: "Add time entry",
          })}
        </Title>
      }
    >
      <Stack spacing={24}>
        <TimeEntryForm
          employeeEntries={timeEntries.entries}
          onSubmit={onClose}
        />
      </Stack>
    </Modal>
  )
}
