import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllLocationsByUserQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  restaurantUUID: Types.Scalars['ID'];
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  userUUID: Types.Scalars['ID'];
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetAllLocationsByUserQuery = { __typename?: 'Query', getAllLocationsByUser: { __typename?: 'PaginatedLocationModel', endCursor?: string | null, hasNextPage: boolean, results?: Array<{ __typename?: 'LocationModel', uuid: string, address: string, latitude?: string | null, longitude?: string | null, name: string, orderTypes: Array<Types.OrderMethodEnum>, timezone?: string | null, attachment?: { __typename?: 'AttachmentModel', uuid: string, fileName: string, signedUrl: string } | null, bankingAccount?: { __typename?: 'BankingAccountModel', uuid: string, isActive: boolean, name: string, stHasExternalAccount: boolean, stIsConnected: boolean } | null }> | null } };


export const GetAllLocationsByUserDocument = gql`
    query getAllLocationsByUser($after: String, $locationUUID: ID, $name: String, $restaurantUUID: ID!, $take: Int, $userUUID: ID!, $isActive: Boolean) {
  getAllLocationsByUser(
    after: $after
    name: $name
    locationUUID: $locationUUID
    restaurantUUID: $restaurantUUID
    take: $take
    userUUID: $userUUID
    isActive: $isActive
  ) {
    results {
      uuid
      address
      attachment {
        uuid
        fileName
        signedUrl
      }
      bankingAccount {
        uuid
        isActive
        name
        stHasExternalAccount
        stIsConnected
      }
      latitude
      longitude
      name
      orderTypes
      timezone
    }
    endCursor
    hasNextPage
  }
}
    `;

/**
 * __useGetAllLocationsByUserQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsByUserQuery({
 *   variables: {
 *      after: // value for 'after'
 *      locationUUID: // value for 'locationUUID'
 *      name: // value for 'name'
 *      restaurantUUID: // value for 'restaurantUUID'
 *      take: // value for 'take'
 *      userUUID: // value for 'userUUID'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetAllLocationsByUserQuery(baseOptions: Apollo.QueryHookOptions<GetAllLocationsByUserQuery, GetAllLocationsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsByUserQuery, GetAllLocationsByUserQueryVariables>(GetAllLocationsByUserDocument, options);
      }
export function useGetAllLocationsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsByUserQuery, GetAllLocationsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsByUserQuery, GetAllLocationsByUserQueryVariables>(GetAllLocationsByUserDocument, options);
        }
export type GetAllLocationsByUserQueryHookResult = ReturnType<typeof useGetAllLocationsByUserQuery>;
export type GetAllLocationsByUserLazyQueryHookResult = ReturnType<typeof useGetAllLocationsByUserLazyQuery>;
export type GetAllLocationsByUserQueryResult = Apollo.QueryResult<GetAllLocationsByUserQuery, GetAllLocationsByUserQueryVariables>;