class UuidSessionHelper {
  private static instance: UuidSessionHelper
  private locationUUID = ""
  private restaurantUUID = ""
  private authInProgress = false
  private retries = 0

  constructor() {
    if (UuidSessionHelper.instance) {
      return UuidSessionHelper.instance
    }
    UuidSessionHelper.instance = this
  }

  setLocationUUID = (uuid: string) => {
    this.locationUUID = uuid
  }
  setRestaurantUUID = (uuid: string) => {
    this.restaurantUUID = uuid
  }
  setAuthInProgress = (value: boolean) => {
    this.authInProgress = value
  }
  incrementRetriesCount = () => {
    this.retries++
  }
  resetAuthTries = () => {
    this.retries = 0
  }

  getLocationUUID = () => this.locationUUID
  getRestaurantUUID = () => this.restaurantUUID
  getAuthInProgress = () => this.authInProgress
  getRetriesCount = () => this.retries
}

export const sessionHelper = new UuidSessionHelper()
