import { TAB_SEARCH_FILTER_NAME } from "../../../../../shared/utils/constant/searchParams"
import AddComboButton from "../../Combos/components/AddComboButton"
import AddItemButton from "../../Items/components/AddItemButton"
import { menuItemsTabsEnum } from "../MenuItemsTabs/utils/MenuItemsTabsEnum"
import React, { useMemo } from "react"
import { useLocation } from "react-router-dom"

type AddMenuItemsButtonProps = {
  isCorporate?: boolean
}

export const AddMenuItemsButton: React.FC<AddMenuItemsButtonProps> = ({
  isCorporate = true,
}) => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const tab = searchParams.get(TAB_SEARCH_FILTER_NAME)
  const combosSelected = tab === menuItemsTabsEnum.COMBOS

  return combosSelected ? (
    <AddComboButton isCorporate={isCorporate} />
  ) : (
    <AddItemButton isCorporate={isCorporate} />
  )
}

export const AddLocationMenuItemsButton = () => (
  <AddMenuItemsButton isCorporate={false} />
)
