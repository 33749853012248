import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import React from "react"
import { useIntl } from "react-intl"

type DeleteModifierGroupModalProps = {
  onConfirm: () => void
  onCancel: () => void
}

export const DeleteModifierGroupModal: React.FC<DeleteModifierGroupModalProps> =
  ({ onCancel, onConfirm }) => {
    const intl = useIntl()

    return (
      <ConfirmationModal
        wrapClassName="non__full__modal__wrap"
        title={intl.formatMessage({
          id: "restaurants.menu.items.forms.modifier.group.modal.remove.title",
          defaultMessage: "Delete Modifier Group",
        })}
        subtitle={intl.formatMessage({
          id: "restaurants.menu.items.forms.modifier.group.modal.remove.subtitle",
          defaultMessage: "Are you sure to delete this Modifier Group?",
        })}
        onConfirm={onConfirm}
        onCancel={onCancel}
        type="danger"
        cancelButtonText={intl.formatMessage({
          id: "restaurants.menu.items.forms.modifier.group.modal.cancel.button.text",
          defaultMessage: "Cancel",
        })}
        confirmButtonText={intl.formatMessage({
          id: "restaurants.menu.items.forms.modifier.group.modal.confirm.button.text",
          defaultMessage: "Delete",
        })}
        centered
        visible
      />
    )
  }
