import { EligibleCustomerRuleTypeEnum } from "../interfaces/eligibleCustomer.interface"

interface GetEligibleCustomerType {
  users: unknown[] | undefined
  lifetimeSpend: null | number | undefined
}

export const getEligibleCustomerType = (args: GetEligibleCustomerType) => {
  const { users, lifetimeSpend } = args
  if (users?.length === 0 && lifetimeSpend === null) {
    return EligibleCustomerRuleTypeEnum.EVERYONE
  }

  if (users && users?.length > 0) {
    return EligibleCustomerRuleTypeEnum.SPECIFIC
  }

  return EligibleCustomerRuleTypeEnum.SPEND
}
