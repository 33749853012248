import { useSendRefundPaymentMutation } from "../../../../../../GraphQL/Queries/sendRefundPayment.mutation.generated"
import { PaymentMethodTypeEnum } from "../../../../../../shared/graphql/generated/types"
import { toFormat } from "../../../../../../shared/utils/currency/toFormat.dinero"
import { roundUp } from "../../../../../../shared/utils/helpers/roundUp"
import {
  GetCateringOrderDetailsDocument,
  useGetCateringOrderDetailsQuery,
} from "../../../Graphql/getCateringOrderDetail.generated"
import { RefundParamsForm } from "../../../hookform/hookforms.interface"
import { CateringRefundResolver } from "../../../hookform/schemas"
import { CateringParams } from "../../../types"
import styles from "./RefundCateringModal.module.css"
import {
  Button,
  Group,
  Input,
  Modal,
  NumberInput,
  Stack,
  Table,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core"
import * as Sentry from "@sentry/react"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { sum } from "lodash"
import { useMemo } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

interface RefundCateringModalProps {
  opened: boolean
  onClose: () => void
}
const NUMBER_STEP = 0.01
export const RefundCateringModal = ({
  opened,
  onClose,
}: RefundCateringModalProps) => {
  const intl = useIntl()

  const { orderUUID, locationUUID } = useParams<CateringParams>()

  const [sendRefundPayment] = useSendRefundPaymentMutation()

  const { data } = useGetCateringOrderDetailsQuery({
    variables: {
      orderUUID,
      ...(!!locationUUID && { locationUUID }),
    },
    fetchPolicy: "cache-only",
  })

  const deposits = data?.getOrderDetails.deposits

  const deposit = useMemo(
    () => sum(deposits?.map((depositItem) => depositItem.amount)),
    [deposits]
  )

  const cardDeposit = useMemo(
    () =>
      sum(
        deposits
          ?.filter((item) => item.type === PaymentMethodTypeEnum.CARD)
          ?.map((depositItem) => depositItem.amount)
      ),
    [deposits]
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
    watch,
    register,
  } = useForm<RefundParamsForm>({
    resolver: CateringRefundResolver,
    context: {
      maxRefundCard: cardDeposit ?? 0,
      maxRefundCash: deposit ?? 0,
    },
    mode: "all",
  })

  const cashRefundWatcher = watch("cashRefund")
  const cardRefundWatcher = watch("cardRefund")

  const cardDifference = cardDeposit - (cashRefundWatcher ?? 0)
  const cashDifference = deposit - (cardRefundWatcher ?? 0)

  const maxRefundableWithCard =
    cardDifference < 0 ? 0 : roundUp(Number(cardDifference))

  const maxRefundableWithCash =
    cashDifference < 0 ? 0 : roundUp(Number(cashDifference))

  const cateringOrderDetail = data?.getOrderDetails

  const cateringLocationUUID = data?.getOrderDetails.location.uuid

  const onSubmit: SubmitHandler<RefundParamsForm> = async (formData) => {
    if (!cateringLocationUUID) {
      return
    }
    try {
      const result = await sendRefundPayment({
        variables: {
          data: {
            amountToRefund: formData.cardRefund ?? 0,
            cashToRefund: formData.cashRefund ?? 0,
            comment: formData.note,
            tipToRefund: 0,
            storeCashToRefund: 0,
            order: { uuid: orderUUID },
            location: { uuid: cateringLocationUUID },
          },
        },
        refetchQueries: [GetCateringOrderDetailsDocument],
      })
      if (result.errors) {
        throw new Error(result.errors[0].message)
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      title={intl.formatMessage({
        id: "restaurants.catering.danger.zone.refund.modal.title",
        defaultMessage: "Refund Order",
      })}
      centered
      opened={opened}
      onClose={() => {
        onClose()
        reset()
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={24}>
          <Textarea
            {...register("note", { required: true })}
            classNames={{
              label: styles["refund-order-label"],
            }}
            placeholder={intl.formatMessage({
              id: "restaurants.catering.danger.zone.refund.modal.textarea.placeholder",
              defaultMessage: "Type your note here",
            })}
            label={intl.formatMessage({
              id: "restaurants.catering.danger.zone.refund.modal.textarea.label",
              defaultMessage: "Note",
            })}
            description={intl.formatMessage({
              id: "restaurants.catering.danger.zone.refund.modal.textarea.description",
              defaultMessage:
                "Leave a comment here with more information about what happened.",
            })}
            withAsterisk
          />
          <Stack>
            <Table withBorder verticalSpacing="sm">
              <tbody>
                {cateringOrderDetail?.orderDetails.map((cateringDetail) => (
                  <tr key={cateringDetail.item?.uuid}>
                    <td>
                      <Group position="apart" px={24}>
                        <Group spacing={16}>
                          <Text>x{cateringDetail.item?.quantity}</Text>
                          <Text>{cateringDetail.item?.name}</Text>
                        </Group>
                        <Text>
                          {toFormat(cateringDetail.item?.totalPrice ?? 0)}
                        </Text>
                      </Group>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <Group position="apart" px={24}>
                      <Text>
                        {intl.formatMessage({
                          id: "restaurants.catering.danger.zone.refund.modal.table.row.sub.total",
                          defaultMessage: "Subtotal",
                        })}
                      </Text>
                      <Text>
                        {toFormat(cateringOrderDetail?.subTotal ?? 0)}
                      </Text>
                    </Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Group position="apart" px={24}>
                      <Text>
                        {intl.formatMessage({
                          id: "restaurants.catering.danger.zone.refund.modal.table.row.taxes",
                          defaultMessage: "Taxes",
                        })}
                      </Text>
                      <Text>
                        {toFormat(cateringOrderDetail?.totalTaxes ?? 0)}
                      </Text>
                    </Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Group position="apart" px={24}>
                      <Text>
                        {intl.formatMessage({
                          id: "restaurants.catering.danger.zone.refund.modal.table.row.deposit",
                          defaultMessage: "Deposit",
                        })}
                      </Text>
                      <Text>
                        {intl.formatNumber(deposit, {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Text>
                    </Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Group position="apart" px={24}>
                      <Text>
                        {intl.formatMessage({
                          id: "restaurants.catering.danger.zone.refund.modal.table.row.service.fee",
                          defaultMessage: "Service Fee",
                        })}
                      </Text>
                      <Text>
                        {toFormat(
                          cateringOrderDetail?.invoice?.serviceFee ?? 0
                        )}
                      </Text>
                    </Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Group position="apart" px={24}>
                      <Text size="lg" fw={600}>
                        {intl.formatMessage({
                          id: "restaurants.catering.danger.zone.refund.modal.table.row.total",
                          defaultMessage: "Total",
                        })}
                      </Text>
                      <Text size="lg" fw={600}>
                        {toFormat(cateringOrderDetail?.total ?? 0)}
                      </Text>
                    </Group>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Stack>
          <Stack spacing={10}>
            <Controller
              control={control}
              name="cardRefund"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberInput
                  min={0}
                  disabled={cashRefundWatcher == deposit}
                  precision={2}
                  value={value}
                  step={NUMBER_STEP}
                  error={error?.message}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  label={intl.formatMessage({
                    id: "restaurants.catering.danger.zone.refund.modal.refund.with.card.input.label",
                    defaultMessage: "Refund with card",
                  })}
                  description={intl.formatMessage(
                    {
                      id: "restaurants.catering.danger.zone.refund.modal.refund.with.card.input.description",
                      defaultMessage:
                        "Max refundable amount {refundableAmount, number, ::currency/USD}",
                    },
                    {
                      refundableAmount: maxRefundableWithCard,
                    }
                  )}
                  labelProps={{ style: { fontWeight: 600 } }}
                  hideControls
                  type="number"
                  icon={<IconCurrencyDollar size={16} />}
                />
              )}
            />
            <Controller
              control={control}
              name="cashRefund"
              rules={{ min: 0 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberInput
                  min={0}
                  precision={2}
                  value={value}
                  step={NUMBER_STEP}
                  error={error?.message}
                  disabled={cardRefundWatcher == deposit}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  label={intl.formatMessage({
                    id: "restaurants.catering.danger.zone.refund.modal.refund.with.cash.input.label",
                    defaultMessage: "Refund with cash",
                  })}
                  description={intl.formatMessage(
                    {
                      id: "restaurants.catering.danger.zone.refund.modal.refund.with.cash.input.description",
                      defaultMessage:
                        "Max refundable amount {refundableAmount, number, ::currency/USD}",
                    },
                    {
                      refundableAmount: maxRefundableWithCash,
                    }
                  )}
                  labelProps={{ style: { fontWeight: 600 } }}
                  hideControls
                  type="number"
                  icon={<IconCurrencyDollar size={16} />}
                />
              )}
            />
          </Stack>
          <Stack spacing={8} align="flex-end">
            <Input.Wrapper
              w="100%"
              label={intl.formatMessage({
                id: "restaurants.catering.danger.zone.refund.modal.confirm.refund.label",
                defaultMessage: "Confirm Refund",
              })}
              description={intl.formatMessage({
                id: "restaurants.catering.danger.zone.refund.modal.confirm.refund.description",
                defaultMessage:
                  "Please write “Refund” below to confirm this operation",
              })}
              required
              classNames={{
                label: styles["refund-order-label"],
              }}
            >
              <TextInput {...register("confirmation")} />
            </Input.Wrapper>
            <Button type="submit" disabled={!isValid}>
              {intl.formatMessage({
                id: "restaurants.catering.danger.zone.refund.modal.button",
                defaultMessage: "Refund",
              })}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Modal>
  )
}
