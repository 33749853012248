import Checkbox from "../../../../../../ui/Checkbox"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import Icon from "../../../../../../ui/Icon"
import Spacer from "../../../../../../ui/Spacer"
import Tag from "../../../../../../ui/Tag"
import Text from "../../../../../../ui/Typography/Text"
import { ErrorBadge, Header } from "../Components"
import { ENTIRE_ORDER_NAME } from "../constants"
import { useCollapseOpen } from "../hooks/useCollapseOpen"
import { CombosSelector } from "./CombosSelector"
import { ItemsSelector } from "./ItemsSelector"
import { useAppliesTo } from "./hooks/useAppliesTo"
import { validationsTitle } from "./validation.title"
import { useIntl } from "react-intl"

export const AppliesTo = () => {
  const intl = useIntl()

  const { handleValidationCollapse, collapseKey, showError } = useCollapseOpen()

  const {
    error,
    items,
    combos,
    onCheckboxChange,
    allCombos,
    allItems,
    entireOrder,
    trigger,
    isEditMode,
  } = useAppliesTo()

  const hasError = !!error && showError

  const headerTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.applies.to.header.title",
    defaultMessage: "Applies To",
  })

  const checkboxLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.applies.to.checkbox.label",
    defaultMessage: "Entire Order",
  })

  const pluralItem = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.applies.to.items.tag.label",
      defaultMessage: "{items} {isItemPlural, plural, =0 {Item} other {Items}}",
    },
    { isItemPlural: Number(items?.length > 1), items: items?.length }
  )

  const pluralCombos = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.applies.to.combos.tag.label",
      defaultMessage:
        "{combos} {isComboPlural, plural, =0 {Combo} other {Combos}}",
    },
    { isComboPlural: Number(combos?.length > 1), combos: combos?.length }
  )

  const entireOrderLabel = entireOrder
    ? intl.formatMessage({
        id: "restaurants.discounts.forms.rules.applies.to.entire.order.tag.label",
        defaultMessage: "Entire Order",
      })
    : undefined

  const itemsQuantity = items?.length > 0 ? pluralItem : undefined
  const combosQuantity = combos?.length > 0 ? pluralCombos : undefined

  const itemsLabel = allItems
    ? intl.formatMessage({
        id: "restaurants.discounts.forms.rules.applies.to.all.items.tag.label",
        defaultMessage: "All Items",
      })
    : itemsQuantity

  const combosLabel = allCombos
    ? intl.formatMessage({
        id: "restaurants.discounts.forms.rules.applies.to.all.combos.tag.label",
        defaultMessage: "All Combos",
      })
    : combosQuantity

  const handleOnCollapseChange = async (collapseIds: string[] | string) => {
    const isValid = await trigger(ENTIRE_ORDER_NAME)
    handleValidationCollapse(collapseIds, isValid.valueOf())
  }

  return (
    <Collapse
      contentBorderColor="Neutral4"
      removeHeaderBottomBorder
      contentBordered
      classId="discount-rule-applies-to-collapse"
      onChange={handleOnCollapseChange}
      activeKey={collapseKey}
    >
      <CollapsePanel
        key="discount-rule-applies-to-panel"
        header={
          <Header title={headerTitle}>
            {!collapseKey && (
              <Container display="flex" gap="8px">
                {entireOrderLabel && <Tag>{entireOrderLabel}</Tag>}
                {itemsLabel && !entireOrderLabel && <Tag>{itemsLabel}</Tag>}
                {combosLabel && !entireOrderLabel && <Tag>{combosLabel}</Tag>}
              </Container>
            )}
          </Header>
        }
        extra={<ErrorBadge hidden={!hasError} />}
      >
        <Checkbox
          disabled={isEditMode}
          content={checkboxLabel}
          checked={entireOrder}
          classId="discount-rule-applies-to"
          onChange={onCheckboxChange}
        />
        {!entireOrder && (
          <>
            <Spacer size={40} />
            {hasError && (
              <Container display="flex" alignItems="center" gap="8px">
                <Icon
                  remixiconClass="ri-error-warning-line"
                  color="Danger5"
                  size={16}
                />
                <Text size="s" color="Danger5">
                  {intl.formatMessage(validationsTitle[error])}
                </Text>
                <Spacer size={16} />
              </Container>
            )}
            <ItemsSelector />
            <Spacer size={40} />
            <CombosSelector />
          </>
        )}
      </CollapsePanel>
    </Collapse>
  )
}
