import type CSS from "csstype"
import get from "lodash/get"
import React from "react"
import type { IBaseThemeColors } from "styled-components"
import { css } from "styled-components"
import styled from "styled-components"

type SvgIconProps = {
  svg: React.ReactNode
  color?: keyof IBaseThemeColors
  cursor?: CSS.Property.Cursor
  width?: number
  height?: number
  onClick?: React.MouseEventHandler<HTMLInputElement>
}

export const SvgIcon: React.FC<SvgIconProps> = ({ svg, ...props }) => {
  return <StyledSvgIcon {...props}>{svg}</StyledSvgIcon>
}

type StyledSvgIconProps = Omit<SvgIconProps, "svg" | "onClick">

const StyledSvgIcon = styled.div<StyledSvgIconProps>`
  display: flex;

  ${({ color, theme }) =>
    color &&
    css`
      path {
        fill: ${get(theme, `colors['${color}']`)};
      }
    `}

  ${({ width }) =>
    width &&
    css`
      svg {
        width: ${width}px;
      }
    `}

    ${({ height }) =>
    height &&
    css`
      svg {
        width: ${height}px;
      }
    `}

  ${({ cursor }) =>
    cursor &&
    css`
      &:hover {
        cursor: ${cursor};
      }
    `}
`
