import { useGetModifierGroupDetailLazyQuery } from "../../../../../../GraphQL/Queries/getModifierGroupDetail.generated"
import { SortingByMenuEnum } from "../../../../../../shared/graphql/generated/types"
import { updateGetModifierListQuery } from "../../../../../../shared/graphql/updateQuery/updateGetModiferGroupList"
import { useModal } from "../../../../../../shared/hooks/useModal"
import { canFetchMore } from "../../../../../../shared/utils/helpers/canFetchMore"
import { reorderArray } from "../../../../../../shared/utils/helpers/reorderArray"
import {
  sortArrayAlphabetically,
  sortingItemComponentsOptions,
} from "../../../../../../shared/utils/helpers/sortArrayByMethod"
import { CardSkeleton } from "../../../../../../ui/Card/Card.skeleton"
import Checkbox from "../../../../../../ui/Checkbox"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import DraggableList from "../../../../../../ui/DraggableList"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import Icon from "../../../../../../ui/Icon"
import { InputNumber } from "../../../../../../ui/Inputs"
import Select, { OptionSelect } from "../../../../../../ui/Select"
import { onPopupScroll } from "../../../../../../ui/Select/helpers/onPopupScroll"
import Spacer from "../../../../../../ui/Spacer"
import Text from "../../../../../../ui/Typography/Text"
import { useGetModifierGroupListQuery } from "../../../../Modifiers/GraphQL/getModiferGroupList.generated"
import GenericSortBy from "../../../../components/GenericSortBy/GenericSortBy"
import OwnershipTag from "../../../../components/OwnershipTag"
import SoldOutIcon from "../../../../components/SoldOutIcon"
import type { IItemForm } from "../../interfaces/hookforms.interfaces"
import { FieldTypeEnumItem } from "../../interfaces/hookforms.interfaces"
import { MAX_LOWER_THAN_INCLUDED_MODIFIERS } from "../../utils/constants"
import CollapseModifierGroupHeader from "./CollapseModifierGroupHeader"
import DeleteModifierGroupModal from "./DeleteModifierGroupModal"
import { HEADER_DATA } from "./utils/constants"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { debounce } from "lodash"
import get from "lodash/get"
import React, { useCallback, useEffect, useState } from "react"
import { DropResult } from "react-beautiful-dnd"
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type ModifierGroupsFormType = {
  variantIndex: number
}

export const ModifierGroupsForm: React.FC<ModifierGroupsFormType> = ({
  variantIndex,
}) => {
  const intl = useIntl()

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useFormContext<IItemForm>()

  const {
    data,
    loading: modifierGroupsLoading,
    refetch,
    fetchMore,
  } = useGetModifierGroupListQuery({
    variables: { isPublished: true },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    fetchPolicy: "network-only",
  })
  const [searchValue, setSearchValue] = useState<string>()
  const [idToDelete, setIdToDelete] = useState<string | undefined>()
  const [
    removeModalVisible,
    showRemoveConfirmation,
    dismissRemoveConfirmation,
  ] = useModal()

  const {
    fields: modifierGroupsFields,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    name: `variants.${variantIndex}.variantModifierGroups`,
  })

  const [
    variant,
    variantName,
    variantModifierGroups,
    isCorporateItem,
    sortModifierGroupsBy,
  ] = useWatch({
    name: [
      `variants.${variantIndex}`,
      `variants.${variantIndex}.name`,
      `variants.${variantIndex}.variantModifierGroups`,
      "isMain",
      `variants.${variantIndex}.sortModifierGroupsBy`,
    ],
    control: control,
  })

  const modifierGroupsError = get(
    errors,
    `variants.${variantIndex}.variantModifierGroups`
  )

  const [fetchModifierGroupDetail, { loading: modifierGroupDetailLoading }] =
    useGetModifierGroupDetailLazyQuery({
      onCompleted: (result) => {
        const { getModifierGroupDetail } = result
        const { name, uuid, modifiers, isMain } = getModifierGroupDetail

        const variantModifiers = modifiers.map((modifier) => ({
          calories: 0,
          price: 0,
          isSoldOut: modifier.isSoldOut ?? false,
          isVisible: modifier.isVisible ?? true,
          modifier: {
            uuid: `${modifier.uuid}`,
            ingredient: {
              uuid: modifier.ingredient.uuid,
              name: modifier.ingredient.name,
            },
          },
        }))

        const modifierGroupWasDeletedIndex = modifierGroupsFields.findIndex(
          (modifierGroup) => modifierGroup.modifierGroup.uuid === uuid
        )

        if (modifierGroupWasDeletedIndex >= 0) {
          update(modifierGroupWasDeletedIndex, {
            ...modifierGroupsFields[modifierGroupWasDeletedIndex],
            deletedAt: undefined,
          })

          return
        }

        append({
          modifierGroup: { name, uuid, isMain },
          variantModifiers: variantModifiers,
          fieldType: FieldTypeEnumItem.RADIO,
          minSelectedOptions: 0,
          maxSelectedOptions: 1,
          tempId: Date.now().toString(),
        })
      },
    })

  const hasNextPage = data?.getModifierGroupList?.hasNextPage ?? false
  const endCursor = data?.getModifierGroupList?.endCursor ?? null
  const canFetchMoreModifierGroups = canFetchMore(
    modifierGroupsLoading,
    hasNextPage,
    endCursor
  )

  const fetchMoreModifierGroups = useCallback(async () => {
    try {
      await fetchMore({
        variables: { isPublished: true, after: endCursor },
        updateQuery: updateGetModifierListQuery,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor, fetchMore])

  const onRemoveConfirm = (id?: string) => {
    if (id === undefined) return

    const index = modifierGroupsFields.findIndex(
      (modifierGroup) => modifierGroup.uuid === id || modifierGroup.id === id
    )

    if (index < 0) return

    if (variant.variantModifierGroups?.[index].uuid) {
      update(index, { ...modifierGroupsFields[index], deletedAt: new Date() })
    } else {
      remove(index)
    }
    dismissRemoveConfirmation()
  }

  const onRemoveClickHandler = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    id?: string
  ) => {
    event.stopPropagation()
    showRemoveConfirmation()

    id && setIdToDelete(id)
  }

  const onSearchModifiers = React.useMemo(() => {
    const onSearch = async (value: string) => {
      try {
        await refetch({
          isPublished: true,
          name: value ? value : undefined,
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    return debounce(onSearch, 600)
  }, [refetch])

  const onSelectClear = () => {
    setSearchValue(undefined)
    onSearchModifiers("")
  }

  const onSearch = (value: string) => {
    setSearchValue(value)

    if (value !== "") {
      onSearchModifiers(value)
    }

    if (value === "") {
      onSelectClear()
    }
  }

  const clearSearchValue = () => setSearchValue(undefined)

  const disabledCustomSorting =
    sortModifierGroupsBy !== SortingByMenuEnum.CUSTOM

  const handleIncludedCheckboxChange = (
    modifierGroupIndex: number,
    event: CheckboxChangeEvent,
    onChange: (event: CheckboxChangeEvent) => void
  ) => {
    onChange(event)
    const { variantModifiers, maxSelectedOptions = 0 } = getValues(
      `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}`
    )
    const quantityIncluded =
      variantModifiers?.reduce(
        (acumulator, current) =>
          current.isIncluded ? acumulator + 1 : acumulator,
        0
      ) ?? 0

    if (quantityIncluded > maxSelectedOptions) {
      setError(
        `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.maxSelectedOptions`,
        { type: MAX_LOWER_THAN_INCLUDED_MODIFIERS }
      )
    } else {
      clearErrors(
        `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.maxSelectedOptions`
      )
    }
  }

  const onChangeSorting = (selectedValue: SortingByMenuEnum) => {
    setValue(`variants.${variantIndex}.sortModifierGroupsBy`, selectedValue, {
      shouldDirty: true,
    })

    if (
      !variantModifierGroups ||
      variantModifierGroups.length === 0 ||
      selectedValue === SortingByMenuEnum.CUSTOM
    ) {
      return variantModifierGroups
    }

    const sortedArray = sortArrayAlphabetically({
      array: variantModifierGroups,
      key: "modifierGroup",
      subkey: "name",
      ascending: selectedValue === SortingByMenuEnum.NAME_ASC,
    })

    setValue(`variants.${variantIndex}.variantModifierGroups`, sortedArray)
  }

  const modifierGroupsDropdownList = data?.getModifierGroupList?.results?.map(
    (group) => ({
      value: group.uuid,
      label: group.name,
      isMain: group.isMain,
    })
  )
  const onDragEndHandler = (dropResult: DropResult) => {
    const reorderedModifierGroups = reorderArray(
      modifierGroupsFields,
      dropResult.source.index,
      dropResult.destination?.index ?? 0
    )

    if (!reorderedModifierGroups) return

    setValue(
      `variants.${variantIndex}.variantModifierGroups`,
      reorderedModifierGroups,
      {
        shouldDirty: true,
      }
    )
  }

  useEffect(() => {
    onChangeSorting(sortModifierGroupsBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container margin="0px 40px">
      <Container display="flex" alignItems="center">
        <Select
          wrapperWidth="100%"
          disabled={!variantName}
          loading={modifierGroupsLoading}
          placeholder={
            <Container
              display="flex"
              alignItems="center"
              gap="5px"
              margin="22px 0px 0px 0px"
            >
              <Icon remixiconClass="ri-search-line" color="Neutral5" />
              <Text>
                {intl.formatMessage({
                  id: "restaurants.menu.items.forms.variants.form.select.modifier.group.placeholder",
                  defaultMessage: "Add Modifiers Groups",
                })}
              </Text>
            </Container>
          }
          options={modifierGroupsDropdownList}
          searchValue={searchValue}
          optionFilterProp="label"
          onSearch={onSearch}
          listHeight={150}
          value={searchValue}
          autoClearSearchValue
          onClear={clearSearchValue}
          onChange={async (modifierGroupUUID) => {
            await fetchModifierGroupDetail({
              variables: { uuid: modifierGroupUUID as unknown as string },
            })
          }}
          allowSearch
          hideSearchIcon
          optionLabelProp="children"
          onPopupScroll={(event) =>
            onPopupScroll(
              event,
              canFetchMoreModifierGroups,
              fetchMoreModifierGroups
            )
          }
        >
          {modifierGroupsDropdownList?.map((group, index) => (
            <OptionSelect
              key={group.value}
              value={group.value}
              label={group.label}
            >
              <Container
                key={index}
                display="flex"
                whiteSpace="normal"
                flexWrap="wrap"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Container maxWidth="50%">{group.label}</Container>
                {group.isMain && !isCorporateItem && (
                  <OwnershipTag key={group.value} isMain={group.isMain} />
                )}
              </Container>
            </OptionSelect>
          ))}
        </Select>
        {modifierGroupsFields.length > 0 && (
          <GenericSortBy
            onChangeSorting={onChangeSorting}
            sortingOptions={sortingItemComponentsOptions}
            value={SortingByMenuEnum[sortModifierGroupsBy]}
          />
        )}
      </Container>

      <Spacer size={20} />

      <DraggableList
        droppableId="modifierGroupsFields"
        onDragEnd={onDragEndHandler}
        data={modifierGroupsFields}
        isDragDisabled={disabledCustomSorting}
        renderContent={(modifierGroup, modifierGroupIndex) => {
          if (modifierGroup.deletedAt) return null

          const isCorporateModifierGroup = modifierGroup.modifierGroup?.isMain

          return (
            <Collapse
              key={`${modifierGroup.uuid}-${modifierGroup.tempId}-${modifierGroupIndex}`}
              contentBorderColor="Neutral4"
              classId="menu-items-variants-form-collapse"
              removeHeaderBottomBorder
              contentBordered
            >
              <CollapsePanel
                extra={
                  <Container
                    right="-24px"
                    margin="4px 0px"
                    position="absolute"
                    visibleOnHover
                  >
                    <Icon
                      remixiconClass="ri-close-line"
                      cursor="pointer"
                      onClick={(event) =>
                        onRemoveClickHandler(
                          event,
                          modifierGroup.uuid || modifierGroup.id
                        )
                      }
                    />
                  </Container>
                }
                key={modifierGroup.id}
                header={
                  <CollapseModifierGroupHeader
                    modifierGroupIndex={modifierGroupIndex}
                    variantIndex={variantIndex}
                  />
                }
              >
                <StyledGridContainer
                  $isCorporateItem={isCorporateItem}
                  $isCorporateModifierGroup={isCorporateModifierGroup}
                >
                  {HEADER_DATA.map((header) => (
                    <Container
                      key={header.id}
                      justifySelf={header.justifySelf}
                      alignSelf={header.alignSelf}
                    >
                      <Text size="s" color="Neutral5">
                        {intl.formatMessage({
                          id: header.id,
                          defaultMessage: header.defaultMessage,
                        })}
                      </Text>
                    </Container>
                  ))}
                </StyledGridContainer>
                {modifierGroup.variantModifiers?.map(
                  (variantModifier, modifierIndex) => {
                    const modifiersError = get(
                      modifierGroupsError,
                      `${modifierGroupIndex}.variantModifiers.${modifierIndex}`
                    )

                    return (
                      <StyledGridContainer
                        $isCorporateModifierGroup={isCorporateModifierGroup}
                        $isCorporateItem={isCorporateItem}
                        key={variantModifier.uuid}
                      >
                        <Text>{variantModifier.modifier?.ingredient.name}</Text>
                        <Controller
                          control={control}
                          name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers.${modifierIndex}.calories`}
                          render={({ field }) => (
                            <InputNumber
                              {...field}
                              maxWidth="96px"
                              min={0}
                              hasError={!!get(modifiersError, "calories")}
                              errorMessage={get(
                                modifiersError,
                                "calories.message"
                              )}
                              placeholder="0000"
                              prefix={intl.formatMessage({
                                id: "generic.prefix.calories",
                                defaultMessage: "Cal",
                              })}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers.${modifierIndex}.price`}
                          render={({ field }) => (
                            <InputNumber
                              {...field}
                              min={0}
                              hasError={!!get(modifiersError, "price")}
                              errorMessage={get(
                                modifiersError,
                                "price.message"
                              )}
                              placeholder="0.00"
                              step={0.01}
                              precision={2}
                              prefix={intl.formatMessage({
                                id: "generic.symbol.currency",
                                defaultMessage: "$",
                              })}
                              maxWidth="106px"
                            />
                          )}
                        />
                        <Container justifySelf="center" alignSelf="center">
                          <Controller
                            control={control}
                            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers.${modifierIndex}.isIncluded`}
                            render={({
                              field: { onChange, value, ...rest },
                            }) => (
                              <>
                                <Checkbox
                                  onChange={(event) =>
                                    handleIncludedCheckboxChange(
                                      modifierGroupIndex,
                                      event,
                                      onChange
                                    )
                                  }
                                  {...rest}
                                  checked={value}
                                  classId="modifier-required-checkbox"
                                />
                              </>
                            )}
                          />
                        </Container>
                        <Container
                          hidden={isCorporateItem}
                          justifySelf="center"
                          alignSelf="center"
                        >
                          <Controller
                            control={control}
                            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers.${modifierIndex}.isVisible`}
                            render={({ field }) => {
                              return variantModifier.modifier?.ingredient
                                .isMain ? (
                                <Icon
                                  color="Neutral5"
                                  size={24}
                                  remixiconClass={
                                    field.value
                                      ? "ri-eye-line"
                                      : "ri-eye-off-line"
                                  }
                                />
                              ) : (
                                <></>
                              )
                            }}
                          />
                        </Container>
                        <Container
                          hidden={isCorporateItem}
                          justifySelf="center"
                          alignSelf="center"
                        >
                          <Controller
                            control={control}
                            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers.${modifierIndex}.isSoldOut`}
                            render={({ field }) => (
                              <SoldOutIcon isSoldOut={!!field.value} />
                            )}
                          />
                        </Container>
                      </StyledGridContainer>
                    )
                  }
                )}
              </CollapsePanel>
            </Collapse>
          )
        }}
      />
      {modifierGroupDetailLoading && <CardSkeleton cards={1} />}
      {removeModalVisible && (
        <DeleteModifierGroupModal
          onConfirm={() => onRemoveConfirm(idToDelete)}
          onCancel={dismissRemoveConfirmation}
        />
      )}
    </Container>
  )
}

type StyledGridContainerProps = {
  $isCorporateItem: boolean
  $isCorporateModifierGroup: boolean
}

const StyledGridContainer = styled.div<StyledGridContainerProps>`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 1fr 1fr;
  justify-items: start;
  align-items: center;
  grid-gap: 16px;
  margin: 8px 40px;
`
