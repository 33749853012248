import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import type { RPublishMenuInput } from "../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import { useMenus } from "../../hooks/useMenus"
import omit from "lodash/omit"
import React from "react"
import { useIntl } from "react-intl"

interface PublishMenuModalProps {
  menuUUID: string
  menuData?: RPublishMenuInput & { labels?: Array<string> }
  onOk: () => void
  onCancel: () => void
  isCorporate?: boolean
}

const PublishMenuModal: React.FC<PublishMenuModalProps> = ({
  menuUUID,
  menuData,
  onOk,
  onCancel,
  isCorporate,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { publishMenuDraft, menuDraftPublished, publishMenu, menuPublished } =
    useMenus({ search })

  const onPublish = async () => {
    if (menuData) {
      const data = omit(menuData, ["labels"])

      return publishMenu(data, onOk, showGraphqlErrors)
    }

    await publishMenuDraft(menuUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.menus.modals.publish.menu.title",
        defaultMessage: "Publish",
      })}
      subtitle={intl.formatMessage(
        {
          id: "restaurants.menus.modals.publish.menu.subtitle",
          defaultMessage:
            "Your menu is about to become visible to {isMain,plural,=0 {customers in your location} other {your selected locations}}.",
        },
        { isMain: Number(isCorporate) }
      )}
      loading={menuDraftPublished.loading || menuPublished.loading}
      onConfirm={onPublish}
      onCancel={onCancel}
      type="info"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.menus.modals.publish.menu.confirm.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.menus.modals.publish.menu.cancel.button",
        defaultMessage: "Publish",
      })}
      visible
      centered
    />
  )
}

export default PublishMenuModal
