import { ExportCSV } from "../../components/ExportCSV"
import { FinancialCardSkeleton } from "../../components/FinancialCard/FinancialCardSkeleton"
import { ReportsTableSkeleton } from "../../components/ReportsTable/ReportsTableSkeleton"
import { Flex, Group, Stack, Title } from "@mantine/core"
import { useIntl } from "react-intl"

type LaborCostSkeletonProps = { height: number }

export const LaborCostSkeleton = (props: LaborCostSkeletonProps) => {
  const { height } = props
  const intl = useIntl()

  return (
    <Stack>
      <Group position="apart">
        <Title order={4}>
          {intl.formatMessage({
            id: "restaurants.reports.labor.cost.title",
            defaultMessage: "Labor cost",
          })}
        </Title>

        <ExportCSV type="LaborCost" disabled />
      </Group>

      <Flex gap={24}>
        <FinancialCardSkeleton fields={2} h={height} />
        <ReportsTableSkeleton />
      </Flex>
    </Stack>
  )
}
