import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveModifierGroupMutationVariables = Types.Exact<{
  input: Types.RSaveModifierGroupInput;
}>;


export type SaveModifierGroupMutation = { __typename?: 'Mutation', saveModifierGroup: { __typename?: 'RSavedModifierGroup', hasSnapshot: boolean, isMain: boolean, isPublished: boolean, name: string, uuid: string } };


export const SaveModifierGroupDocument = gql`
    mutation saveModifierGroup($input: RSaveModifierGroupInput!) {
  saveModifierGroup(input: $input) {
    hasSnapshot
    isMain
    isPublished
    name
    uuid
  }
}
    `;
export type SaveModifierGroupMutationFn = Apollo.MutationFunction<SaveModifierGroupMutation, SaveModifierGroupMutationVariables>;

/**
 * __useSaveModifierGroupMutation__
 *
 * To run a mutation, you first call `useSaveModifierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveModifierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveModifierGroupMutation, { data, loading, error }] = useSaveModifierGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveModifierGroupMutation(baseOptions?: Apollo.MutationHookOptions<SaveModifierGroupMutation, SaveModifierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveModifierGroupMutation, SaveModifierGroupMutationVariables>(SaveModifierGroupDocument, options);
      }
export type SaveModifierGroupMutationHookResult = ReturnType<typeof useSaveModifierGroupMutation>;
export type SaveModifierGroupMutationResult = Apollo.MutationResult<SaveModifierGroupMutation>;
export type SaveModifierGroupMutationOptions = Apollo.BaseMutationOptions<SaveModifierGroupMutation, SaveModifierGroupMutationVariables>;