/**
The intention of this function is to get a Date instance from primitives from another date library when using
a timezones implementation.
If a date library has a build-in method to get a Date instance, it will probably not have the right time when
parsed to local time. Useful for the Mantine date's components in V6 in which.
 */

export const dateInstanceFromPrimitives = (date: {
  year: number
  monthIndex: number
  day: number
  hours: number
  minutes: number
  seconds: number
}) => {
  const { year, monthIndex, day, hours, minutes, seconds } = date
  return new Date(year, monthIndex, day, hours, minutes, seconds)
}
