import type { RItemVariant } from "../../../../../shared/graphql/generated/types"

export const getCorporateVariantValues = (variants: Array<RItemVariant>) => {
  return variants.map((variant) => {
    const {
      uuid,
      price,
      isSoldOut,
      isVisible,
      variantModifierGroups,
      isDefault,
    } = variant

    return {
      uuid,
      price: price ?? 0,
      isSoldOut: isSoldOut ?? false,
      isVisible: isVisible ?? true,
      isDefault: isDefault ?? false,
      variantModifierGroups: variantModifierGroups.map(
        (variantModifierGroup) => {
          const { uuid: variantModifierGroupUUID, variantModifiers } =
            variantModifierGroup

          return {
            uuid: variantModifierGroupUUID,
            variantModifiers: variantModifiers.map((variantModifier) => {
              const { uuid: variantModifierUUID, price: variantModifierPrice } =
                variantModifier

              return {
                uuid: variantModifierUUID,
                price: variantModifierPrice ?? 0,
              }
            }),
          }
        }
      ),
    }
  })
}
