import {
  CateringServiceEnum,
  OrderStatusEnum,
} from "../../../../shared/graphql/generated/types"
import { GetCateringOrderDetailsQuery } from "../Graphql/getCateringOrderDetail.generated"
import { forEach } from "lodash"

type OrderDetailType = {
  name: string
  quantity: number
  totalPrice: number
}

type CateringOrderInfo = {
  uuid: string
  refundInformation?: {
    amount: number
    comment: string
    storeCash: number
  } | null
  location: { uuid: string }
  orderNumber: string
  status: OrderStatusEnum
  catering: {
    placedAt: string
    deliveryDate?: string
    eventEndDate?: string
    headCount?: number
    eventName?: string
    serviceType?: CateringServiceEnum
  }
  customer: {
    uuid: string
    phone: string
    firstName?: string
    lastName?: string
  }
  orderDetails: Array<OrderDetailType>
  subTotal: number
  total: number
  totalTaxes: number
  deposits: number
}

export const getCateringOrderInfo = (
  data: GetCateringOrderDetailsQuery["getOrderDetails"] | undefined
): CateringOrderInfo | undefined => {
  if (!data) {
    return
  }

  const products: Array<OrderDetailType> = []

  forEach(data.orderDetails, (product) => {
    if (product.item) {
      products.push({
        name: product.item.name,
        quantity: product.item.quantity,
        totalPrice: product.item.totalPrice,
      })
    }
  })

  return {
    uuid: data.uuid,
    location: { uuid: data.location.uuid },
    orderNumber: data.orderNumber,
    status: data.status,
    customer: {
      uuid: data.customer.uuid,
      phone: data.customer.phone ?? "-",
      firstName: data.customer.firstName ?? undefined,
      lastName: data.customer.lastName ?? undefined,
    },
    catering: {
      placedAt: data.createdAt,
      deliveryDate: data.deliveryDate ?? undefined,
      eventEndDate: data.catering?.eventEndDate ?? "",
      headCount: data.catering?.headCount ?? undefined,
      eventName: data.catering?.eventName ?? undefined,
      serviceType: data.catering?.serviceType ?? undefined,
    },
    orderDetails: products,
    subTotal: data.subTotal ?? 0,
    total: data.total ?? 0,
    totalTaxes: data.totalTaxes ?? 0,
    refundInformation: data.refundInformation,
    deposits: data.deposits.reduce((acc, deposit) => acc + deposit.amount, 0),
  }
}
