import {
  CancelOrderInput,
  Exact,
} from "../../../../../../../shared/graphql/generated/types"
import { LabelInformation } from "../../../../../../../ui/LabelInformation"
import { CancelOrderModal } from "../../../../../Orders/CancelOrderModal"
import { CancelCateringOrderMutation } from "../../../../Graphql/cancelCateringOrder.generated"
import {
  cancelCateringOrderCacheUpdate,
  declineCateringOrderCacheUpdate,
} from "../../../../utils/cache/order-catering-cache.util"
import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  NormalizedCacheObject,
} from "@apollo/client"
import { Button, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

interface CancelCateringProps {
  isCancelable: boolean
}
export const CancelCatering = ({ isCancelable }: CancelCateringProps) => {
  const intl = useIntl()
  const [openedRefundModal, { open, close }] = useDisclosure()
  const { orderUUID } = useParams<{ orderUUID: string }>()

  const CANCEL_MESSAGE = intl.formatMessage({
    id: "restaurants.catering.danger.zone.cancel.modal.message",
    defaultMessage: "Canceling a catering order cannot be undone.",
  })

  const cancelCateringCacheUpdaters:
    | MutationUpdaterFunction<
        CancelCateringOrderMutation,
        Exact<{
          input: CancelOrderInput
        }>,
        DefaultContext,
        ApolloCache<NormalizedCacheObject>
      >
    | undefined = (cache, result, { variables }) => {
    declineCateringOrderCacheUpdate(cache, orderUUID)
    cancelCateringOrderCacheUpdate(cache, result, {
      variables,
    })
  }

  return (
    <Stack spacing={16}>
      <LabelInformation
        label={intl.formatMessage({
          id: "restaurants.catering.danger.zone.cancel.label",
          defaultMessage: "Cancel order",
        })}
        labelWeight={600}
        description={intl.formatMessage(
          {
            id: "restaurants.catering.danger.zone.cancel.description",
            defaultMessage: `Canceling the order will refund customer the amount paid for this order. {br} The cancellation is irreversible, so be certain`,
          },
          {
            br: <br />,
          }
        )}
        descriptionSize="sm"
      />
      <Button
        onClick={open}
        variant="outline"
        color="red.6"
        disabled={!isCancelable}
      >
        {intl.formatMessage({
          id: "restaurants.catering.danger.zone.cancel.button",
          defaultMessage: "Cancel order",
        })}
      </Button>
      <CancelOrderModal
        orderUUID={orderUUID}
        cancelMessage={CANCEL_MESSAGE}
        opened={openedRefundModal}
        onClose={close}
        cacheUpdaters={cancelCateringCacheUpdaters}
      />
    </Stack>
  )
}
