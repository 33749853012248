import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteComboMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type DeleteComboMutation = { __typename?: 'Mutation', deleteCombo: { __typename?: 'RUUID', uuid: string } };


export const DeleteComboDocument = gql`
    mutation DeleteCombo($uuid: ID!) {
  deleteCombo(uuid: $uuid) {
    uuid
  }
}
    `;
export type DeleteComboMutationFn = Apollo.MutationFunction<DeleteComboMutation, DeleteComboMutationVariables>;

/**
 * __useDeleteComboMutation__
 *
 * To run a mutation, you first call `useDeleteComboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComboMutation, { data, loading, error }] = useDeleteComboMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteComboMutation(baseOptions?: Apollo.MutationHookOptions<DeleteComboMutation, DeleteComboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteComboMutation, DeleteComboMutationVariables>(DeleteComboDocument, options);
      }
export type DeleteComboMutationHookResult = ReturnType<typeof useDeleteComboMutation>;
export type DeleteComboMutationResult = Apollo.MutationResult<DeleteComboMutation>;
export type DeleteComboMutationOptions = Apollo.BaseMutationOptions<DeleteComboMutation, DeleteComboMutationVariables>;