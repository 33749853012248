import defaultPlaceholder from "../../shared/assets/icons/icon_placeholder_big.svg"
import type { UploadIconShape } from "../../styles/global/types"
import Icon from "../Icon"
import Img from "../Img"
import { Spin, Upload } from "antd"
import type { RcFile, UploadChangeParam } from "antd/lib/upload"
import type { UploadFile } from "antd/lib/upload/interface"
import classnames from "classnames"
import type CSS from "csstype"
import type { RefObject } from "react"
import React from "react"
import styled, { css } from "styled-components"

const { Dragger } = Upload

type IconProps = Omit<
  React.ComponentProps<"img">,
  "src" | "onChange" | "ref" | "loading"
>

export type UploadIconProps = IconProps & {
  url?: string
  className?: string
  accept?: string
  icon?: RcFile
  size?: number
  onChange?: (avatar?: RcFile) => void
  onChangeCallback?(image?: RcFile): void
  shape?: UploadIconShape
  loading?: boolean
  disabled?: boolean
  imageFit?: CSS.Property.ObjectFit
}

export const UploadIcon = React.forwardRef(
  (
    props: UploadIconProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const {
      url,
      icon,
      onChange,
      onChangeCallback,
      className,
      loading,
      imageFit,
      accept,
      size = 24,
      disabled = false,
      ...restProps
    } = props

    const onDraggerChange = (event: UploadChangeParam<UploadFile<unknown>>) => {
      onChange?.(event.file.originFileObj)
      onChangeCallback?.(event.file.originFileObj)
    }

    const getSrc = () => {
      try {
        return icon ? URL.createObjectURL(icon) : url
      } catch {
        return undefined
      }
    }

    return (
      <StyledUploadIcon
        className={classnames("upload-icon", className)}
        size={size}
        ref={ref}
      >
        <Dragger
          onChange={onDraggerChange}
          customRequest={() => null}
          listType="picture"
          multiple={false}
          accept={accept}
          showUploadList={false}
          disabled={disabled}
        >
          {loading && (
            <StyledSpin
              indicator={
                <Icon
                  remixiconClass="ri-loader-5-line"
                  color="Primary3"
                  size={24}
                  classes="spinning"
                />
              }
            />
          )}

          <StyledImg
            {...restProps}
            aria-label="image-icon-filled"
            src={getSrc()}
            placeholderImage={defaultPlaceholder}
            $imageFit={imageFit}
          />
        </Dragger>
      </StyledUploadIcon>
    )
  }
)

type StyledUploadIconProps = {
  size: number
}

const StyledSpin = styled(Spin)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.TransparentDark};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const StyledUploadIcon = styled.div<StyledUploadIconProps>`
  ${({ theme, size }) => css`
    &.upload-icon {
      .ant-upload {
        height: ${size}px;
        width: ${size}px;
      }

      img {
        height: ${size - 2}px;
        width: ${size - 2}px;
      }

      .ant-upload .ant-upload-btn {
        padding: 0;
      }

      .ant-upload.ant-upload-drag {
        border-radius: 4px;
        padding: 0;
        border: 1px dashed ${theme.colors.transparent};
        background: ${theme.colors.transparent};

        :hover {
          border: 1px dashed ${theme.colors["Primary3"]};
        }
      }

      .ant-upload-drag-container {
        display: flex;
        justify-content: center;
        width: ${size - 2}px;
        height: ${size - 2}px;

        .icon-placeholder {
          padding: 0;

          align-self: center;
          vertical-align: middle;
          visibility: visible;
        }
      }

      .ant-spin {
        position: absolute;
        width: 24px;
        height: 24px;
        display: flex;
        left: 0;
        vertical-align: middle;
      }
    }
  `}
`

interface StyledImgProps {
  shape?: UploadIconShape
  $imageFit?: CSS.Property.ObjectFit
}

const StyledImg = styled(Img)<StyledImgProps>`
  ${({ shape }) =>
    shape === "round" &&
    css`
      border-radius: ${({ theme }) => theme.spacing.cornerRadiusSmall};
    `}

  ${({ $imageFit }) => css`
    object-fit: ${$imageFit ?? "cover"};
  `}
`
