import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishDraftModifierGroupMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type PublishDraftModifierGroupMutation = { __typename?: 'Mutation', publishDraftModifierGroup: { __typename?: 'RBasicEntityData', name: string, uuid: string } };


export const PublishDraftModifierGroupDocument = gql`
    mutation publishDraftModifierGroup($uuid: ID!) {
  publishDraftModifierGroup(uuid: $uuid) {
    name
    uuid
  }
}
    `;
export type PublishDraftModifierGroupMutationFn = Apollo.MutationFunction<PublishDraftModifierGroupMutation, PublishDraftModifierGroupMutationVariables>;

/**
 * __usePublishDraftModifierGroupMutation__
 *
 * To run a mutation, you first call `usePublishDraftModifierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftModifierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftModifierGroupMutation, { data, loading, error }] = usePublishDraftModifierGroupMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishDraftModifierGroupMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftModifierGroupMutation, PublishDraftModifierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDraftModifierGroupMutation, PublishDraftModifierGroupMutationVariables>(PublishDraftModifierGroupDocument, options);
      }
export type PublishDraftModifierGroupMutationHookResult = ReturnType<typeof usePublishDraftModifierGroupMutation>;
export type PublishDraftModifierGroupMutationResult = Apollo.MutationResult<PublishDraftModifierGroupMutation>;
export type PublishDraftModifierGroupMutationOptions = Apollo.BaseMutationOptions<PublishDraftModifierGroupMutation, PublishDraftModifierGroupMutationVariables>;