import type { RItemVariantModifierGroup } from "../../../../../../../../../shared/graphql/generated/types"
import Container from "../../../../../../../../../ui/Container"
import Text from "../../../../../../../../../ui/Typography/Text"
import { modifierGroupOptions } from "../../../../../utils/modifier-group-select.options"
import omit from "lodash/omit"
import React from "react"
import { useIntl } from "react-intl"

type ModifierGroupDetailHeaderProps = {
  variantModifierGroup: RItemVariantModifierGroup
}

export const ModifierGroupDetailHeader: React.FC<ModifierGroupDetailHeaderProps> =
  ({ variantModifierGroup }) => {
    const intl = useIntl()
    const { modifierGroup, fieldType, minSelectedOptions, maxSelectedOptions } =
      variantModifierGroup
    const fieldTypeTitle = omit(
      modifierGroupOptions[fieldType ?? "RADIO"],
      "icon"
    )

    const minSelectedOptionPrefix = intl.formatMessage({
      id: "restaurants.menu.items.forms.corporate.view.modifier.group.header.min.prefix",
      defaultMessage: "Min",
    })

    const maxSelectedOptionPrefix = intl.formatMessage({
      id: "restaurants.menu.items.forms.corporate.view.modifier.group.header.max.prefix",
      defaultMessage: "Max",
    })

    return (
      <Container
        display="flex"
        gap="8px"
        justifyContent="space-between"
        width="100%"
      >
        <Text color="Primary5" weight="bold">
          {modifierGroup.name}
        </Text>
        <Container display="flex" gap="40px">
          <Container minWidth="160px">
            <Text color="Neutral6">{intl.formatMessage(fieldTypeTitle)}</Text>
          </Container>
          <Container minWidth="80px">
            <Text color="Neutral6">{`${minSelectedOptionPrefix}: ${minSelectedOptions}`}</Text>
          </Container>
          <Container minWidth="80px">
            <Text color="Neutral6">{`${maxSelectedOptionPrefix}: ${maxSelectedOptions}`}</Text>
          </Container>
        </Container>
      </Container>
    )
  }
