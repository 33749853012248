import { OrderActionTypeEnum } from "../../../../../../shared/graphql/generated/types"
import { MantineCard } from "../../../../../../ui/MantineCard"
import { AcceptCateringOrder } from "./AcceptCateringOrder"
import { DeclineCateringOrder } from "./DeclineCateringOrder"
import { Stack } from "@mantine/core"
import { useIntl } from "react-intl"

interface OrderManagementProps {
  actions: Set<OrderActionTypeEnum>
}

export const OrderManagement = ({ actions }: OrderManagementProps) => {
  const intl = useIntl()

  const isPlacedAndRevision = actions.has(OrderActionTypeEnum.IN_REVISION)

  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.order.management.title",
        defaultMessage: "Order management",
      })}
    >
      <Stack spacing={16}>
        <AcceptCateringOrder isPlacedAndRevision={isPlacedAndRevision} />
        <DeclineCateringOrder isPlacedAndRevision={isPlacedAndRevision} />
      </Stack>
    </MantineCard>
  )
}
