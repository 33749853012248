import { OrderActionTypeEnum } from "../../../../../shared/graphql/generated/types"
import { CacheUpdateFunction } from "../../../../Settings/Locations/Employees/types/graphql.enums"
import {
  GetOrderSummaryDocument,
  GetOrderSummaryQuery,
  GetOrderSummaryQueryVariables,
} from "../../../Orders/OrderDetail/GraphQL/getOrderSummary.generated"
import {
  AcceptCateringOrderMutation,
  AcceptCateringOrderMutationVariables,
} from "../../Graphql/acceptCateringOrder.generated"
import {
  CancelCateringOrderMutation,
  CancelCateringOrderMutationVariables,
} from "../../Graphql/cancelCateringOrder.generated"
import {
  CreateCateringCommentMutation,
  CreateCateringCommentMutationVariables,
} from "../../Graphql/createCateringComment.generated"
import {
  GetCateringOrderDetailsDocument,
  GetCateringOrderDetailsQuery,
  GetCateringOrderDetailsQueryVariables,
} from "../../Graphql/getCateringOrderDetail.generated"
import { ApolloCache, NormalizedCacheObject } from "@apollo/client"
import { datatype } from "faker/locale/en"
import moment from "moment-timezone"

export const acceptCateringOrderCacheUpdate: CacheUpdateFunction<
  AcceptCateringOrderMutation,
  AcceptCateringOrderMutationVariables
> = (cache, result) => {
  if (result.data) {
    const acceptCateringOrder = result.data.acceptCateringOrder

    cache.updateQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(
      {
        query: GetOrderSummaryDocument,
        variables: {
          uuid: acceptCateringOrder.uuid,
        },
      },
      (prevData) => {
        if (prevData) {
          const currentOrderActions = prevData.getOrderSummary.orderActions
          const nextOrderActions = [
            {
              uuid: datatype.uuid(),
              actionAt: moment().toISOString(),
              actionType: OrderActionTypeEnum.PAYMENT_SUCCEEDED,
              admin: null,
              comment: null,
            },
            {
              uuid: datatype.uuid(),
              actionAt: moment().toISOString(),
              actionType: OrderActionTypeEnum.PLACED,
              admin: null,
              comment: null,
            },
          ]

          return {
            getOrderSummary: {
              ...prevData.getOrderSummary,
              orderActions: [...nextOrderActions, ...currentOrderActions],
            },
          }
        }
      }
    )
  }
}
export const cancelCateringOrderCacheUpdate: CacheUpdateFunction<
  CancelCateringOrderMutation,
  CancelCateringOrderMutationVariables
> = (cache, result, { variables }) => {
  if (result.data && variables?.input.order.uuid) {
    cache.updateQuery<
      GetCateringOrderDetailsQuery,
      GetCateringOrderDetailsQueryVariables
    >(
      {
        query: GetCateringOrderDetailsDocument,
        variables: {
          orderUUID: variables?.input.order.uuid,
        },
      },
      (prevData) => {
        if (prevData) {
          const prevOrderDetails = prevData.getOrderDetails

          return {
            getOrderDetails: {
              ...prevOrderDetails,
              isAvailableCancel: false,
            },
          }
        }
      }
    )
  }
}

export const addCommentCateringOrderCacheUpdate: CacheUpdateFunction<
  CreateCateringCommentMutation,
  CreateCateringCommentMutationVariables
> = (cache, result, { variables }) => {
  if (result.data && variables?.input.order.uuid) {
    const resultData = result.data.createComment

    cache.updateQuery<
      GetCateringOrderDetailsQuery,
      GetCateringOrderDetailsQueryVariables
    >(
      {
        query: GetCateringOrderDetailsDocument,
        variables: {
          orderUUID: variables?.input.order.uuid,
        },
      },
      (prevData) => {
        if (prevData) {
          return {
            getOrderDetails: {
              ...prevData.getOrderDetails,
              comments: [...prevData.getOrderDetails.comments, resultData],
            },
          }
        }
      }
    )
  }
}

export const declineCateringOrderCacheUpdate = (
  cache: ApolloCache<NormalizedCacheObject>,
  orderUUID: string
) => {
  cache.updateQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(
    {
      query: GetOrderSummaryDocument,
      variables: {
        uuid: orderUUID,
      },
    },
    (prevData) => {
      if (prevData) {
        return {
          getOrderSummary: {
            ...prevData?.getOrderSummary,
            orderActions: [
              {
                uuid: datatype.uuid(),
                actionAt: moment().toISOString(),
                actionType: OrderActionTypeEnum.CANCELLED,
                admin: null,
                comment: null,
              },
              ...prevData?.getOrderSummary.orderActions,
            ],
          },
        }
      }
    }
  )
}
