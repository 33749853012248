import { DiscountStatusesEnum } from "../../../shared/graphql/generated/types"
import { updateGetDiscountsListQuery } from "../../../shared/graphql/updateQuery/updateGetDiscountsListQuery"
import paths from "../../../shared/routes/paths"
import firstLetterToUpperCase from "../../../shared/utils/helpers/firstLetterToUpperCase"
import Badge from "../../../ui/Badge"
import { Container } from "../../../ui/Container/Container"
import { Header } from "../../../ui/Header/Header"
import { PageHeader } from "../../../ui/PageHeader/PageHeader"
import BodyCell from "../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../ui/Table/EmptyStateCell/EmptyStateCell"
import Table from "../../../ui/Table/Table"
import Tooltip from "../../../ui/Tooltip"
import Text from "../../../ui/Typography/Text"
import { DropdownDiscount, EmptyStateModifiers } from "./components"
import { DiscountActions } from "./components/DiscountHeaderActions"
import { useGetListDiscountsQuery } from "./forms/graphql/getAllDiscounts.generated"
import { subStatusTitle } from "./subStatus.title"
import { corporateColumns as columns } from "./utils/columns"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

const Discounts = () => {
  const intl = useIntl()
  const { push } = useHistory()

  const {
    data,
    loading,
    refetch: discountRefetch,
    error: discountError,
    fetchMore,
  } = useGetListDiscountsQuery({
    variables: {
      //Implement filters to remove this hardcoded value
      startDate: "2022-10-16T19:17:21.216Z",
      status: [DiscountStatusesEnum.ACTIVE, DiscountStatusesEnum.INACTIVE],
    },
    fetchPolicy: "network-only",
  })

  const allDiscounts = data?.getListDiscounts?.results ?? []

  const hasNextPage = data?.getListDiscounts?.hasNextPage ?? false
  const endCursor = data?.getListDiscounts?.endCursor

  const fetchMoreDiscounts = useCallback(async () => {
    await fetchMore({
      variables: { after: endCursor },
      updateQuery: updateGetDiscountsListQuery,
    })
  }, [endCursor, fetchMore])

  const onRowClick = useCallback(
    (uuid: string) => {
      const path = paths.restaurants.editDiscounts(uuid)

      push(path)
    },
    [push]
  )

  return (
    <Container
      background="Neutral0"
      role="menus-table"
      className="menus-table"
      position="sticky"
    >
      <PageHeader>
        <Header
          title={intl.formatMessage({
            id: "restaurants.discounts.title",
            defaultMessage: "Discounts",
          })}
          actions={DiscountActions}
          actionsOverflow="visible"
        />
      </PageHeader>
      <Table
        top="48.5px"
        columns={columns}
        loading={loading}
        error={discountError}
        errorRefetch={discountRefetch}
        fetchMore={fetchMoreDiscounts}
        hasNextPage={hasNextPage}
        leftSpace={16}
        isArrayEmpty={allDiscounts.length === 0}
        emptyState={
          <EmptyStateCell colSpan={columns.length}>
            <EmptyStateModifiers />
          </EmptyStateCell>
        }
      >
        {!!allDiscounts?.length &&
          allDiscounts.map((record) => {
            return (
              <tr
                className="styled-data-table-row"
                key={record.uuid}
                onClick={() => onRowClick(record.uuid)}
              >
                <BodyCell>{`${record.code || ""}`}</BodyCell>
                <BodyCell>
                  <Container
                    display="flex"
                    gap="14px"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    {record.status === DiscountStatusesEnum.INACTIVE ? (
                      <Tooltip
                        title={intl.formatMessage(
                          record.subStatus
                            ? subStatusTitle[record.subStatus]
                            : subStatusTitle.EXPIRED
                        )}
                        placement="top"
                        visible
                      >
                        <Text size="s">
                          <Badge color="Neutral5" />
                          {firstLetterToUpperCase(record.status)}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text size="s">
                        <Badge
                          color={
                            record.status === DiscountStatusesEnum.ACTIVE
                              ? "Success5"
                              : "Neutral5"
                          }
                        />
                        {firstLetterToUpperCase(record.status)}
                      </Text>
                    )}
                  </Container>
                </BodyCell>
                <BodyCell>{`${
                  firstLetterToUpperCase(record.entryMethod) || ""
                }`}</BodyCell>
                <BodyCell textAlign="right">
                  {record.percentage
                    ? `${record.percentage}%`
                    : `$${record.fixedAmount?.toFixed(2)}`}
                </BodyCell>
                <BodyCell textAlign="right">
                  {record.rule?.timesUsed ?? "-"}
                </BodyCell>
                <BodyCell textAlign="center">
                  <DropdownDiscount
                    discountUUID={record.uuid}
                    code={record.code}
                  />
                </BodyCell>
              </tr>
            )
          })}
      </Table>
    </Container>
  )
}

export default Discounts
