import { ReactComponent as AvailableIcon } from "../../../../../shared/assets/icons/custom-available.svg"
import { ReactComponent as SoldOutIcon } from "../../../../../shared/assets/icons/custom-sold-out.svg"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { useIntl } from "react-intl"

type SoldOutButtonProps = {
  soldOut?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const AvailableButton = (props: SoldOutButtonProps) => {
  const { soldOut = false, ...rest } = props

  const intl = useIntl()

  return (
    <Button {...rest} hierarchy="secondary">
      <Container
        display="flex"
        width="100px"
        gap="7px"
        alignItems="center"
        justifyContent="center"
      >
        {soldOut ? (
          <SoldOutIcon width={21} height={21} />
        ) : (
          <AvailableIcon width={21} height={18} />
        )}
        {intl.formatMessage(
          {
            id: "restaurants.menu.item.components.sold.out.button.title",
            defaultMessage:
              "{isAvailable, select, true {Available} other {Sold Out}}",
          },
          { isAvailable: !soldOut }
        )}
      </Container>
    </Button>
  )
}
