import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderSummaryQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetOrderSummaryQuery = { __typename?: 'Query', getOrderSummary: { __typename?: 'OrderSummaryModel', uuid: string, orderNumber: string, customer: { __typename?: 'CustomerBasicModel', uuid: string, firstName?: string | null, lastName?: string | null, phone?: string | null }, orderActions: Array<{ __typename?: 'OrderActionModel', uuid: string, actionAt: any, actionType: Types.OrderActionTypeEnum, comment?: string | null, admin?: { __typename?: 'AdminBasicModel', uuid: string, firstName: string, lastName?: string | null } | null }> } };


export const GetOrderSummaryDocument = gql`
    query getOrderSummary($uuid: ID!, $locationUUID: ID) {
  getOrderSummary(orderUUID: $uuid, locationUUID: $locationUUID) {
    uuid
    customer {
      uuid
      firstName
      lastName
      phone
    }
    orderActions {
      uuid
      actionAt
      actionType
      admin {
        uuid
        firstName
        lastName
      }
      comment
    }
    orderNumber
  }
}
    `;

/**
 * __useGetOrderSummaryQuery__
 *
 * To run a query within a React component, call `useGetOrderSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSummaryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetOrderSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(GetOrderSummaryDocument, options);
      }
export function useGetOrderSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(GetOrderSummaryDocument, options);
        }
export type GetOrderSummaryQueryHookResult = ReturnType<typeof useGetOrderSummaryQuery>;
export type GetOrderSummaryLazyQueryHookResult = ReturnType<typeof useGetOrderSummaryLazyQuery>;
export type GetOrderSummaryQueryResult = Apollo.QueryResult<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>;