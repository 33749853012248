import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLocationMutationVariables = Types.Exact<{
  data: Types.UpdateLocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'LocationModel', uuid: string, name: string, address: string, latitude?: string | null, longitude?: string | null, timezone?: string | null, orderTypes: Array<Types.OrderMethodEnum>, acceptCash: boolean, estimatedPreparationTime?: number | null, isCateringActive: boolean, attachment?: { __typename?: 'AttachmentModel', uuid: string, fileName: string, signedUrl: string, ext: Types.FileExtensionEnum, contentType: Types.ContentTypeEnum } | null, bankingAccount?: { __typename?: 'BankingAccountModel', uuid: string, name: string, isActive: boolean, stIsConnected: boolean, stHasExternalAccount: boolean } | null } };


export const UpdateLocationDocument = gql`
    mutation updateLocation($data: UpdateLocationInput!) {
  updateLocation(data: $data) {
    uuid
    name
    address
    latitude
    longitude
    timezone
    orderTypes
    acceptCash
    estimatedPreparationTime
    attachment {
      uuid
      fileName
      signedUrl
      ext
      contentType
    }
    bankingAccount {
      uuid
      name
      isActive
      stIsConnected
      stHasExternalAccount
    }
    isCateringActive
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;