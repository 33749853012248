import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import useModifierGroups from "../../hooks/useModifierGroups"
import React from "react"
import { useIntl } from "react-intl"

interface UnpublishModifierGroupModalProps {
  modifierGroupUUID: string
  onOk: () => void
  onCancel: () => void
  isCorporate?: boolean
}

const UnpublishModifierGroupModal: React.FC<UnpublishModifierGroupModalProps> =
  ({ modifierGroupUUID, onOk, onCancel, isCorporate }) => {
    const intl = useIntl()
    const { getSearchQueryFilter } = useFilters()

    const search = getSearchQueryFilter()

    const { unpublishModifierGroup, modifierGroupUnpublished } =
      useModifierGroups({ search })

    const onUnpublish = async () => {
      await unpublishModifierGroup(modifierGroupUUID, onOk, showGraphqlErrors)
    }

    return (
      <ConfirmationModal
        wrapClassName="non__full__modal__wrap"
        title={intl.formatMessage({
          id: "restaurants.modifiers.unpublish.modals.unpublish.modifier.group.title",
          defaultMessage: "Unpublish modifier group",
        })}
        subtitle={intl.formatMessage(
          {
            id: "restaurants.modifiers.unpublish.modals.unpublish.modifier.group.subtitle",
            defaultMessage:
              "This modifier group will stop being visible{isCorporate, plural, =1 { to all your locations} other {}}.",
          },
          { isCorporate: Number(isCorporate) }
        )}
        loading={modifierGroupUnpublished.loading}
        onConfirm={onUnpublish}
        onCancel={onCancel}
        type="warning"
        cancelButtonText={intl.formatMessage({
          id: "restaurants.modifiers.unpublish.modals.unpublish.modifier.group.confirm.button",
          defaultMessage: "Cancel",
        })}
        confirmButtonText={intl.formatMessage({
          id: "restaurants.modifiers.unpublish.modals.unpublish.modifier.group.cancel.button",
          defaultMessage: "Unpublish",
        })}
        visible
        centered
      />
    )
  }

export default UnpublishModifierGroupModal
