import yup from "../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const OnboardingRestaurantSchema = yup.object().shape({
  name: yup.string().required(),
  url: yup.string().required(),
  squareLogo: yup.mixed().required(),
  primaryBrandColor: yup.string().notRequired(),
})

export const OnboardingRestaurantResolver = yupResolver(
  OnboardingRestaurantSchema
)
