import { Group, Skeleton, Stack } from "@mantine/core"

export const ScrollSkeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <Stack key={index} justify="flex-start" h={64} pt={12} pl={16} w="100%">
          <Group spacing={12} miw={300}>
            <Skeleton h={24} w={24} />
            <Skeleton h={40} w={40} />
            <Stack spacing={8}>
              <Skeleton h={16} w={200} animate={false} />
              <Skeleton h={18} w={120} />
            </Stack>
          </Group>
        </Stack>
      ))}
    </>
  )
}
