import paths from "../../../../../shared/routes/paths"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import type { IModalFullProps } from "../../../../../ui/ModalFull/ModalFull"
import { useGetOrderDetailsQuery } from "../GraphQL/getOrderDetails.generated"
import { useGetOrderSummaryQuery } from "../GraphQL/getOrderSummary.generated"
import OrderDetailHeader from "../OrderDetailHeader"
import OrderDetailForm from "./OrderDetailForm"
import RefundedOrderSummary from "./RefundedOrderSummary"
import type { OrderDetailModalForm } from "./hookforms.interfaces"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

type OrderDetailProps = IModalFullProps & {
  orderUUID?: string
  locationUUID?: string
  onCancel?: () => void
}

export const OrderDetailModal: React.FC<OrderDetailProps> = ({
  orderUUID = "",
  locationUUID,
  onCancel,
  ...props
}) => {
  const { goBack } = useHistory()
  const { restaurants } = paths

  const OrderDetailFormMethods = useForm<OrderDetailModalForm>()
  const { setValue } = OrderDetailFormMethods

  const { loading: loadingSummary } = useGetOrderSummaryQuery({
    variables: { uuid: orderUUID, locationUUID },
    skip: !orderUUID,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onError: showGraphqlErrors,
    onCompleted: ({ getOrderSummary: orderSummary }) => {
      setValue("orderSummary", orderSummary)
    },
  })

  const { loading: loadingDetail } = useGetOrderDetailsQuery({
    variables: { orderUUID: orderUUID, locationUUID },
    skip: !orderUUID,
    notifyOnNetworkStatusChange: true,
    onError: showGraphqlErrors,
    fetchPolicy: "no-cache",
    onCompleted: ({ getOrderDetails: orderDetail }) => {
      setValue("orderDetail", {
        paymentStatus: orderDetail.paymentStatus,
        uuid: orderDetail.uuid,
        orderNumber: orderDetail.orderNumber,
        isAvailableRefund: orderDetail.isAvailableRefund,
        isAvailableCancel: orderDetail.isAvailableCancel,
        createdAt: orderDetail.createdAt,
        expiresAt: orderDetail.expiresAt,
        deliveryIntegration: orderDetail.deliveryIntegration,
        location: orderDetail.location,
        orderMethod: orderDetail.orderMethod,
        status: orderDetail.status,
        orderDetails: orderDetail.orderDetails,
        invoice: orderDetail.invoice,
        customer: orderDetail.customer,
        notes: orderDetail.notes,
        storeCashRewarded: orderDetail.storeCashRewarded,
        refundInformation: orderDetail.refundInformation,
        total: orderDetail.total,
        paymentMethodType: orderDetail.paymentMethodType,
        subTotal: orderDetail.subTotal,
        totalTaxes: orderDetail.totalTaxes,
      })
    },
  })

  return (
    <FormProvider {...OrderDetailFormMethods}>
      <ModalFull
        {...props}
        id="order-detail-modal"
        className="order-detail-modal-wrapper"
        goBackPath={restaurants.orders}
        title={<OrderDetailHeader loading={loadingDetail} />}
        notification={<RefundedOrderSummary />}
        onCancel={onCancel ?? goBack}
        visible={!!orderUUID}
        minWidth="900px"
        destroyOnClose
        closable
      >
        <OrderDetailForm
          loadingDetail={loadingDetail}
          loadingSummary={loadingSummary}
        />
      </ModalFull>
    </FormProvider>
  )
}
