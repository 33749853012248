import { CustomOrderMethodTitle } from "../../../../../../../shared/utils/constant/orderMethod.title"
import { EMPTY_ARRAY } from "../../../../../../../shared/utils/constant/values"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import { ORDER_TYPES_NAME, SET_VALUE_OPTIONS } from "../../constants"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export const useOrderType = () => {
  const { setValue, watch, getValues } = useFormContext<IDiscountForm>()
  const intl = useIntl()

  const selectedOrderTypes = watch(ORDER_TYPES_NAME)

  const isEditMode = getValues(EDIT_MODE_NAME)

  const orderTypes = Object.keys(CustomOrderMethodTitle).map((orderType) => {
    const orderMethod =
      CustomOrderMethodTitle[orderType as keyof typeof CustomOrderMethodTitle]

    return {
      label: intl.formatMessage({
        id: orderMethod.id,
        defaultMessage: orderMethod.defaultMessage,
      }),
      value: orderType,
    }
  })

  const onOrderTypeSelected = (options: string[]) => {
    const orderTypesSelected = orderTypes
      .filter((orderType) => options.includes(orderType.value))
      .map((orderType) => orderType.value)

    setValue(ORDER_TYPES_NAME, orderTypesSelected, SET_VALUE_OPTIONS)
  }

  const onAllOrderTypesClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    setValue(ORDER_TYPES_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
  }

  return {
    selectedOrderTypes,
    orderTypes,
    onOrderTypeSelected,
    onAllOrderTypesClick,
    isEditMode,
  }
}
