import { CancelPolicyInput } from "../../../../shared/graphql/generated/types"
import {
  CateringServiceEnum,
  FeeQuantityEnum,
} from "../Catering/shared/hookforms.intefaces"
import type {
  CancelPoliciesType,
  CateringFeeType,
  MinimumAmountType,
  ServicesType,
  TypeOfService,
} from "../Catering/shared/hookforms.intefaces"

export const verifyTypeServices = (services: ServicesType[]): TypeOfService => {
  const typeOfServices: TypeOfService = {
    pickup: false,
    delivery: false,
    foodTruckExperience: false,
    insideVenue: false,
  }

  for (const item of services) {
    switch (item.type) {
      case CateringServiceEnum.PICK_UP: {
        typeOfServices.pickup = true
        break
      }
      case CateringServiceEnum.DELIVERY: {
        typeOfServices.delivery = true
        break
      }
      case CateringServiceEnum.FOOD_TRUCK_EXPERIENCE: {
        typeOfServices.foodTruckExperience = true
        break
      }
      case CateringServiceEnum.INSIDE_VENUE: {
        typeOfServices.insideVenue = true
        break
      }
      default: {
        break
      }
    }
  }

  return typeOfServices
}

export const buildTypeServicesRules = (
  { delivery, pickup, foodTruckExperience, insideVenue }: TypeOfService,
  minimumAmount: MinimumAmountType,
  cateringFee: CateringFeeType
): ServicesType[] => {
  const serviceMappings = {
    delivery: {
      type: CateringServiceEnum.DELIVERY,
      apply: delivery,
      amount: minimumAmount.delivery.apply
        ? minimumAmount?.delivery?.amount
        : null,
      feeFixedAmount: cateringFee.delivery.apply
        ? cateringFee.delivery.type === FeeQuantityEnum.AMOUNT
          ? cateringFee.delivery.value
          : null
        : null,
      feePercentageAmount: cateringFee.delivery.apply
        ? cateringFee.delivery.type === FeeQuantityEnum.PERCENTAGE
          ? cateringFee.delivery.value
          : null
        : null,
    },
    pickup: {
      type: CateringServiceEnum.PICK_UP,
      apply: pickup,
      amount: minimumAmount.pickup.apply ? minimumAmount?.pickup?.amount : null,
      feeFixedAmount: cateringFee.pickup.apply
        ? cateringFee.pickup.type === FeeQuantityEnum.AMOUNT
          ? cateringFee.pickup.value
          : null
        : null,
      feePercentageAmount: cateringFee.pickup.apply
        ? cateringFee.pickup.type === FeeQuantityEnum.PERCENTAGE
          ? cateringFee.pickup.value
          : null
        : null,
    },
    foodTruckExperience: {
      type: CateringServiceEnum.FOOD_TRUCK_EXPERIENCE,
      apply: foodTruckExperience,
      amount: minimumAmount.foodTruckExperience.apply
        ? minimumAmount?.foodTruckExperience?.amount
        : null,
      feeFixedAmount: cateringFee.foodTruckExperience.apply
        ? cateringFee.foodTruckExperience.type === FeeQuantityEnum.AMOUNT
          ? cateringFee.foodTruckExperience.value
          : null
        : null,
      feePercentageAmount: cateringFee.foodTruckExperience.apply
        ? cateringFee.foodTruckExperience.type === FeeQuantityEnum.PERCENTAGE
          ? cateringFee.foodTruckExperience.value
          : null
        : null,
    },
    insideVenue: {
      type: CateringServiceEnum.INSIDE_VENUE,
      apply: insideVenue,
      amount: minimumAmount?.insideVenue.apply
        ? minimumAmount?.insideVenue?.amount
        : null,
      feeFixedAmount: cateringFee.insideVenue.apply
        ? cateringFee.insideVenue.type === FeeQuantityEnum.AMOUNT
          ? cateringFee.insideVenue.value
          : null
        : null,
      feePercentageAmount: cateringFee.insideVenue.apply
        ? cateringFee.insideVenue.type === FeeQuantityEnum.PERCENTAGE
          ? cateringFee.insideVenue.value
          : null
        : null,
    },
  }

  const servicesRules: ServicesType[] = []

  Object.values(serviceMappings).forEach(
    ({ type, apply, amount, feeFixedAmount, feePercentageAmount }) => {
      if (apply) {
        servicesRules.push({
          type,
          minimumAmount: amount ?? 0,
          feeFixedAmount: feeFixedAmount ?? null,
          feePercentage: feePercentageAmount ?? null,
        })
      }
    }
  )

  return servicesRules
}

export const cancelationPoliciesFormatted = (
  cancelPolicies: CancelPoliciesType[],
  isApply: boolean
) => {
  const cancelPoliciesData: CancelPolicyInput[] = []

  if (!isApply) return []

  cancelPolicies.forEach((item) => {
    if (!item.percentage || !item.timeBeforeEvent) return

    cancelPoliciesData.push({
      percentage: Number(item.percentage),
      timeBeforeEvent: item.timeBeforeEvent,
    })
  })

  return cancelPoliciesData
}
