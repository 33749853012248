import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModifierGroupListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  isPublished?: Types.InputMaybe<Types.Scalars['Boolean']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetModifierGroupListQuery = { __typename?: 'Query', getModifierGroupList: { __typename?: 'PaginatedModifierGroupList', endCursor?: string | null, hasNextPage: boolean, results?: Array<{ __typename?: 'RModifierGroupList', name: string, uuid: string, isMain: boolean, hasSnapshot: boolean, isPublished: boolean }> | null } };


export const GetModifierGroupListDocument = gql`
    query getModifierGroupList($after: String, $isPublished: Boolean, $name: String, $take: Int) {
  getModifierGroupList(
    after: $after
    isPublished: $isPublished
    name: $name
    take: $take
  ) {
    endCursor
    hasNextPage
    results {
      name
      uuid
      isMain
      hasSnapshot
      isPublished
    }
  }
}
    `;

/**
 * __useGetModifierGroupListQuery__
 *
 * To run a query within a React component, call `useGetModifierGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModifierGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModifierGroupListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      isPublished: // value for 'isPublished'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetModifierGroupListQuery(baseOptions?: Apollo.QueryHookOptions<GetModifierGroupListQuery, GetModifierGroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModifierGroupListQuery, GetModifierGroupListQueryVariables>(GetModifierGroupListDocument, options);
      }
export function useGetModifierGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModifierGroupListQuery, GetModifierGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModifierGroupListQuery, GetModifierGroupListQueryVariables>(GetModifierGroupListDocument, options);
        }
export type GetModifierGroupListQueryHookResult = ReturnType<typeof useGetModifierGroupListQuery>;
export type GetModifierGroupListLazyQueryHookResult = ReturnType<typeof useGetModifierGroupListLazyQuery>;
export type GetModifierGroupListQueryResult = Apollo.QueryResult<GetModifierGroupListQuery, GetModifierGroupListQueryVariables>;