import styles from "./MantineTable.module.css"
import {
  Box,
  Flex,
  Skeleton,
  Stack,
  SystemProp,
  Table,
  Text,
} from "@mantine/core"
import { CSSProperties, ReactNode } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { FormattedMessage } from "react-intl"

interface MantineTableProps {
  fetchMore: () => void
  hasNextPage: boolean
  headers: {
    title: JSX.Element
    alignment?: SystemProp<CSSProperties["textAlign"]>
  }[]
  children: ReactNode
  dataLength: number
}

export const MantineTable = ({
  fetchMore,
  hasNextPage,
  headers,
  children,
  dataLength,
}: MantineTableProps) => {
  return (
    <Box id="scrollableDiv" className={styles["scroll-area"]}>
      <InfiniteScroll
        dataLength={dataLength}
        hasMore={hasNextPage}
        next={fetchMore}
        scrollableTarget="scrollableDiv"
        loader={
          <Stack mt={10}>
            <Skeleton w="100%" height={30} />
            <Skeleton w="100%" height={30} />
          </Stack>
        }
      >
        <Table miw={1200} withBorder highlightOnHover verticalSpacing="md">
          <thead className={styles["thead"]}>
            <tr>
              {headers.map((item, index) => (
                <th key={index}>
                  <Text fw={400} ta={item.alignment}>
                    {item.title}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          {dataLength > 0 ? (
            <tbody>{children}</tbody>
          ) : (
            <tbody>
              <tr>
                <td className={styles["td-empty"]}>
                  <Flex
                    pos="absolute"
                    w="100%"
                    left={0}
                    right={0}
                    justify="center"
                  >
                    <Text size="lg" color="gray.6">
                      <FormattedMessage
                        id="restaurants.orders.empty.title"
                        defaultMessage="No catering orders found"
                      />
                    </Text>
                  </Flex>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </InfiniteScroll>
    </Box>
  )
}
