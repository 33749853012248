import type {
  GetAllModifiersByRestaurantQuery,
  GetAllModifiersByRestaurantQueryVariables,
} from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import { GetAllModifiersByRestaurantDocument } from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import type { ApolloCache } from "@apollo/client"

type ReadCacheIngredientsByRestaurantQuery = {
  cache: ApolloCache<unknown>
  variables: GetAllModifiersByRestaurantQueryVariables
}

const readIngredientsByRestaurantQueryCache = ({
  cache,
  variables,
}: ReadCacheIngredientsByRestaurantQuery) =>
  cache.readQuery<
    GetAllModifiersByRestaurantQuery,
    GetAllModifiersByRestaurantQueryVariables
  >({
    query: GetAllModifiersByRestaurantDocument,
    variables,
  })

export default readIngredientsByRestaurantQueryCache
