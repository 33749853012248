import type { GetAllLocationsByUserQuery } from "../../../GraphQL/Queries/getAllLocationsByUser.generated"
import type { LocationModel } from "../generated/types"

export const updateGetAllLocationsByUser = (
  prev: GetAllLocationsByUserQuery,
  fetchMoreResult: GetAllLocationsByUserQuery | undefined
) => {
  if (
    !fetchMoreResult ||
    prev?.getAllLocationsByUser?.endCursor ===
      fetchMoreResult?.getAllLocationsByUser?.endCursor
  ) {
    return prev
  }

  return {
    getAllLocationsByUser: {
      ...fetchMoreResult?.getAllLocationsByUser,
      results: [
        ...(prev.getAllLocationsByUser.results as Array<LocationModel>),
        ...(fetchMoreResult?.getAllLocationsByUser
          .results as Array<LocationModel>),
      ],
    },
  }
}
