import { sessionHelper } from "../classes/UuidSessionHelper"
import paths from "../routes/paths"
import { post } from "../utils/api/client"
import { endpoints } from "../utils/api/endpoints"
import storage from "../utils/storage"
import {
  getAuthToken,
  getRefreshToken,
  setAuthentication,
} from "../utils/storage/auth"
import { getAppState } from "../utils/storage/state"
import {
  FAIL_TO_FETCH_RESPONSE_ERROR,
  FORBIDDEN_RESOURCE_RESPONSE_ERROR,
  INVALID_TOKEN_RESPONSE_ERROR,
  UNAUTHORIZED_RESPONSE_ERROR,
} from "./constants"
import { priceFormatLink } from "./price-format-link"
import type { Operation } from "@apollo/client"
import {
  ApolloClient,
  InMemoryCache,
  Observable,
  createHttpLink,
  from,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { captureMessage, withScope } from "@sentry/react"
import * as Sentry from "@sentry/react"
import jwtDecode from "jwt-decode"

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()

  return {
    headers: {
      ...headers,
      ...(!!token && { authorization: `Bearer ${token}` }),
    },
  }
})

const refresh = async (refreshToken: string, operation: Operation) => {
  try {
    const appState = getAppState()

    return post({
      path: endpoints.auth.refreshToken,
      headers: { refreshtoken: refreshToken },
      data: {
        restaurant: {
          uuid: appState?.currentRestaurant.uuid,
        },
        location: appState?.selectedLocation
          ? {
              uuid: appState?.selectedLocation.uuid,
            }
          : undefined,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { accessToken, refreshToken: newRefreshToken, statusCode } = data
        checkIfShouldRedirect(statusCode as number)

        const oldHeaders = operation.getContext().headers

        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
          },
        })

        return setAuthentication(accessToken, newRefreshToken)
      })
  } catch (error) {
    Sentry.captureException(error, { extra: { refreshToken: refreshToken } })
    if (error instanceof Error) console.log(error.message, error.stack)
    throw error
  }
}

const checkLogin = async (operation: Operation) => {
  try {
    const refreshToken = getRefreshToken()
    await refresh(refreshToken, operation)
  } catch (error) {
    if (error instanceof Error) console.log(error.message, error.stack)
    throw error
  }
}

const errorLink = onError(
  ({ graphQLErrors, forward, operation, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (
          err.message === UNAUTHORIZED_RESPONSE_ERROR ||
          err.message === INVALID_TOKEN_RESPONSE_ERROR ||
          err.message === FORBIDDEN_RESOURCE_RESPONSE_ERROR
        ) {
          return new Observable((observer) => {
            checkLogin(operation)
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }
                return forward(operation).subscribe(subscriber)
              })
              .catch((error) => {
                if (error instanceof Error)
                  console.error(error.message, error.stack)
              })
          })
        }
      }
    }

    if (networkError && networkError.message === FAIL_TO_FETCH_RESPONSE_ERROR) {
      return new Observable((observer) => {
        checkLogin(operation)
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            }
            return forward(operation).subscribe(subscriber)
          })
          .catch((error) => {
            if (error instanceof Error)
              console.error(error.message, error.stack)
          })
      })
    }
  }
)

const sentryErrorLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    if (!graphQLErrors && !networkError) return

    withScope((scope) => {
      scope.setTag("ApiArch", "GraphQL")
      scope.setTransactionName(operation.operationName)
      scope.setContext("apolloGraphQLOperation", {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      })

      if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
          captureMessage(graphQLError.message, {
            level: "error",
            contexts: {
              apolloGraphQLError: {
                path: graphQLError.path,
                name: graphQLError.name,
                message: graphQLError.message,
                extensions: graphQLError.extensions,
              },
            },
          })
        })
      }

      if (networkError) {
        captureMessage(networkError.message, {
          level: "error",
          contexts: {
            apolloNetworkError: {
              error: networkError,
              name: networkError.name,
              message: networkError.message,
            },
          },
        })
      }
    })
  }
)

const authMiddleware = setContext((_operation, { headers }) => {
  const authToken = getAuthToken()
  const decoded =
    jwtDecode<{ restaurantUUID?: string; locationUUID?: string }>(authToken)

  const currentLocationUUID = decoded.locationUUID
  const currentRestaurantUUID = decoded.restaurantUUID

  const contextLocationUUID = sessionHelper.getLocationUUID()
  const contextRestaurantUUID = sessionHelper.getRestaurantUUID()

  if (sessionHelper.getAuthInProgress()) {
    throw new Error("Auth in progress")
  }

  if (
    !currentLocationUUID &&
    !contextLocationUUID &&
    currentRestaurantUUID === contextRestaurantUUID
  ) {
    return { headers }
  }

  if (
    currentLocationUUID !== contextLocationUUID ||
    currentRestaurantUUID !== contextRestaurantUUID
  ) {
    sessionHelper.setAuthInProgress(true)
    const token = getRefreshToken()

    return post({
      path: endpoints.auth.refreshToken,
      headers: { refreshtoken: token },
      data: {
        ...(!!contextLocationUUID && {
          location: { uuid: contextLocationUUID },
        }),
        ...(!!contextRestaurantUUID && {
          restaurant: { uuid: contextRestaurantUUID },
        }),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode >= 400) {
          return {
            headers,
          }
        }
        const { accessToken, refreshToken } = data
        setAuthentication(accessToken, refreshToken)
        sessionHelper.setAuthInProgress(false)

        return {
          headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
          },
        }
      })
      .catch((error) =>
        Sentry.captureException(error, {
          extra: { refreshToken: token },
        })
      )
  }
  return { headers }
})

export const graphqlClient = new ApolloClient({
  link: from([
    new RetryLink(),
    sentryErrorLink,
    priceFormatLink,
    errorLink,
    authLink,
    authMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          getAllMenusByRestaurant: {
            merge: false,
          },
          getVariantsByItem: {
            merge: false,
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
  },
})

const checkIfShouldRedirect = (statusCode: number) => {
  const authTries = sessionHelper.getRetriesCount()

  if (authTries > 1) {
    window.location.replace(paths.guest.loginError)
    storage.clear()
    return
  }

  if (statusCode >= 400) {
    sessionHelper.incrementRetriesCount()
  } else {
    sessionHelper.resetAuthTries()
  }
}
