import { Moment } from "moment-timezone"

export const momentInstanceToPrimitives = (momentDate: Moment) => {
  const year = momentDate.year()
  const monthIndex = momentDate.month()
  const day = momentDate.date()
  const hours = momentDate.hours()
  const minutes = momentDate.minutes()
  const seconds = momentDate.seconds()

  return { year, monthIndex, day, hours, minutes, seconds }
}
