import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const ModifierGroupSchema = yup.object().shape({
  name: yup
    .string()
    .default("Untitled Category")
    .required("Name the modifier group"),
  modifiers: yup
    .array()
    .min(1, "Add at least one modifier")
    .of(
      yup.object().shape({
        ingredient: yup.object().shape({
          uuid: yup.string().required(),
        }),
      })
    ),
})

export const ModifierGroupResolver = yupResolver(ModifierGroupSchema)
