import type { TextAlignType } from "../../../../styles/global/types"

interface MenuColumnsType {
  title: string
  width: string
  id?: string
  textAlign?: TextAlignType
}

export const locationColumns: MenuColumnsType[] = [
  {
    id: "restaurants.locations.datatable.header.name",
    title: "Name",
    width: "30%",
  },
  {
    id: "restaurants.locations.datatable.header.type",
    title: "Menu Type",
    width: "20%",
  },
  {
    id: "restaurants.locations.datatable.header.items.count",
    title: "Items",
    width: "10%",
    textAlign: "right",
  },
  {
    id: "restaurants.locations.datatable.header.combos.count",
    title: "Combos",
    width: "10%",
    textAlign: "right",
  },
  {
    id: "restaurants.locations.datatable.header.status",
    title: "Status",
    width: "10%",
  },
  {
    title: "",
    width: "5%",
  },
  {
    id: "restaurants.locations.datatable.header.ownership",
    title: "Ownership",
    width: "10%",
  },
  {
    title: "",
    width: "5%",
  },
  {
    title: "",
    width: "5%",
  },
]

export const corporateColumns: MenuColumnsType[] = [
  {
    title: "",
    width: "44px",
  },
  {
    id: "restaurants.menus.datatable.header.name",
    title: "Name",
    width: "25%",
  },
  {
    id: "restaurants.menus.datatable.header.type",
    title: "Type",
    width: "25%",
  },
  {
    id: "restaurants.menus.datatable.header.items",
    title: "Items",
    textAlign: "right",
    width: "13%",
  },
  {
    id: "restaurants.menus.datatable.header.combos",
    title: "Combos",
    textAlign: "right",
    width: "13%",
  },
  {
    id: "restaurants.menus.datatable.header.status",
    title: "Status",
    width: "16%",
  },
  {
    title: "",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
]
