import { Comparisson } from "../../Comparisson"
import { NumberType } from "../../Comparisson/Comparisson"
import { Group, Text } from "@mantine/core"
import { useIntl } from "react-intl"

type FinancialInfoRowProps = {
  valueType: NumberType
  label: string
  value: number
  dif: number
  type: "normal" | "satisfaction"
}

export const FinancialInfoRow = (props: FinancialInfoRowProps) => {
  const { label, dif, valueType, value, type } = props
  const intl = useIntl()

  const currencySymbol = intl.formatMessage({
    id: "generic.symbol.currency",
    defaultMessage: "$",
  })

  return (
    <Group position="apart" mah={18} noWrap>
      <Text size="md">{label}</Text>
      <Group spacing={0}>
        <Text size="md" mr={8}>
          {currencySymbol}
          {Math.round((value || 0) * 100) / 100}
        </Text>
        <Comparisson
          valueType={valueType}
          size="md"
          value={dif || 0}
          type={type}
        />
      </Group>
    </Group>
  )
}
