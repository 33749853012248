import { useGeneralContext } from "../../contexts/StoreProvider"
import RedirectRoute from "../RedirectRoute"
import React from "react"
import type { RouteProps } from "react-router"
import { Route } from "react-router"

export type GeneralRouteProps = RouteProps & {
  homePath: string
}

export const GuestRoute: React.FC<GeneralRouteProps> = ({
  children,
  ...props
}) => {
  const {
    state: {
      auth: { isAuthenticated },
    },
  } = useGeneralContext()

  if (isAuthenticated) {
    return <RedirectRoute {...props} pathname={props.homePath} />
  }

  return <Route {...props}>{children}</Route>
}
