import type { GetModifierGroupListQuery } from "../../../pages/Restaurants/Modifiers/GraphQL/getModiferGroupList.generated"
import type { RModifierGroupList } from "../generated/types"

export const updateGetModifierListQuery = (
  prev: GetModifierGroupListQuery,
  { fetchMoreResult }: { fetchMoreResult?: GetModifierGroupListQuery }
) => {
  if (
    !fetchMoreResult ||
    prev?.getModifierGroupList.endCursor ===
      fetchMoreResult?.getModifierGroupList?.endCursor
  ) {
    return prev
  }

  return {
    getModifierGroupList: {
      ...fetchMoreResult.getModifierGroupList,
      results: [
        ...(prev.getModifierGroupList.results as RModifierGroupList[]),
        ...(fetchMoreResult.getModifierGroupList
          .results as RModifierGroupList[]),
      ],
    },
  }
}
