import { useGeneralContext } from "../../shared/contexts/StoreProvider"
import Button from "../../ui/Button"
import Container from "../../ui/Container"
import { Result } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"

interface IAccessDeniedProps {
  fullScreen?: boolean
}

export const AccessDenied: React.FC<IAccessDeniedProps> = ({
  fullScreen = true,
}) => {
  const intl = useIntl()

  const { getMainRoot } = useGeneralContext()

  const { root } = getMainRoot()

  return (
    <Container position="relative" fullScreen={fullScreen} centered>
      <Result
        status="404"
        title={intl.formatMessage({
          id: "generic.access.denied.title",
          defaultMessage: "Access Denied",
        })}
        subTitle={intl.formatMessage({
          id: "generic.access.denied.message",
          defaultMessage: "Insuficcient permissions to see this information",
        })}
        extra={
          <Link to={root}>
            <Container centered>
              <Button
                title={intl.formatMessage({
                  id: "generic.error.page.button",
                  defaultMessage: "Back Home",
                })}
              />
            </Container>
          </Link>
        }
      />
    </Container>
  )
}
