import * as Types from '../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOnboardingCompletedMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type SetOnboardingCompletedMutation = { __typename?: 'Mutation', setOnboardingCompleted: { __typename?: 'AdminOnboardingModel', isOnboardingCompleted: any } };


export const SetOnboardingCompletedDocument = gql`
    mutation setOnboardingCompleted($uuid: ID!) {
  setOnboardingCompleted(uuid: $uuid) {
    isOnboardingCompleted
  }
}
    `;
export type SetOnboardingCompletedMutationFn = Apollo.MutationFunction<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>;

/**
 * __useSetOnboardingCompletedMutation__
 *
 * To run a mutation, you first call `useSetOnboardingCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOnboardingCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOnboardingCompletedMutation, { data, loading, error }] = useSetOnboardingCompletedMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSetOnboardingCompletedMutation(baseOptions?: Apollo.MutationHookOptions<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>(SetOnboardingCompletedDocument, options);
      }
export type SetOnboardingCompletedMutationHookResult = ReturnType<typeof useSetOnboardingCompletedMutation>;
export type SetOnboardingCompletedMutationResult = Apollo.MutationResult<SetOnboardingCompletedMutation>;
export type SetOnboardingCompletedMutationOptions = Apollo.BaseMutationOptions<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>;