import { StatusBadgeEnum } from "./StatusBadge"

interface GetStatusArgs {
  isPublished: boolean
  hasSnapshot: boolean
  uuidExists?: boolean
}

export const getStatus = (args: GetStatusArgs) => {
  const { isPublished, hasSnapshot, uuidExists } = args

  if (!uuidExists) {
    return StatusBadgeEnum.DRAFT
  }

  if (!isPublished) {
    return hasSnapshot ? StatusBadgeEnum.DRAFT : StatusBadgeEnum.UNPUBLISHED
  }

  return StatusBadgeEnum.PUBLISHED
}
