import { DiscountsEntryMethodsEnum } from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import {
  ALL_DAY_VALUE,
  YYYY_MM_DD,
} from "../../../../shared/utils/constant/dateFormats"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import ModalFull from "../../../../ui/ModalFull"
import { MeridiemEnum } from "../../../../ui/TimePickerSelect"
import GenericForm from "../forms/GenericForm"
import { CODE_NAME } from "../forms/GenericForm/constants"
import { TypeEnum } from "../forms/RadioGroupOptions/typeOptions"
import { discountFormResolver } from "../forms/discountForm.yup"
import { useCreateDiscountMutation } from "../forms/graphql/createDiscountByRestaurant.generated"
import { GetListDiscountsDocument } from "../forms/graphql/getAllDiscounts.generated"
import { EligibleCustomerRuleTypeEnum } from "../interfaces/eligibleCustomer.interface"
import type { IDiscountForm } from "../interfaces/hookforms.interfaces"
import { generateTimeFromForm } from "../utils/generateTimeFromForm"
import { getOrderTypes } from "../utils/getOrderTypes"
import { get } from "lodash"
import moment from "moment-timezone"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

export const CreateDiscount: React.FC = () => {
  const intl = useIntl()

  const history = useHistory()
  const { restaurants } = paths

  const formMethods = useForm<IDiscountForm>({
    resolver: discountFormResolver,
    mode: "onChange",
    defaultValues: {
      code: "",
      type: TypeEnum.PERCENTAGE,
      percentOrAmount: undefined,
      entryMethod: DiscountsEntryMethodsEnum.MANUAL,
      stacking: false,
      rules: {
        dateAndTime: {
          startDate: {
            date: moment().format(YYYY_MM_DD),
            hour: { time: ALL_DAY_VALUE, meridium: MeridiemEnum.AM },
          },
        },
        locationAvailability: { locations: [] },
        orderType: { orderTypes: [] },
        appliesTo: {
          allItems: true,
          allCombos: true,
          items: [],
          combos: [],
          entireOrder: true,
        },
        usageLimit: {
          limitedTo: null,
          limitPerCustomer: 1,
        },
        eligibleCustomer: {
          users: [],
          spend: null,
          type: EligibleCustomerRuleTypeEnum.EVERYONE,
        },
      },
    },
  })

  const { reset, setFocus } = formMethods

  const [createDiscount, { loading }] = useCreateDiscountMutation({
    refetchQueries: [GetListDiscountsDocument],
  })

  const onSaveDiscount = async (formData: IDiscountForm) => {
    try {
      const startDate = generateTimeFromForm({
        date: formData.rules.dateAndTime.startDate.date,
        hour: formData.rules.dateAndTime.startDate.hour,
      })

      const endDate =
        formData.rules.dateAndTime.endDate?.date &&
        generateTimeFromForm({
          date: formData.rules.dateAndTime.endDate?.date,
          hour: formData.rules.dateAndTime.endDate?.hour,
        })

      if (!startDate)
        throw new Error(
          intl.formatMessage({
            id: "generic.start.date.required",
            defaultMessage: "Start date is required",
          })
        )

      const orderTypes = getOrderTypes(formData)

      const createdDiscount = await createDiscount({
        variables: {
          input: {
            code: formData.code,
            entryMethod: formData.entryMethod,
            percentage:
              formData.type === TypeEnum.PERCENTAGE
                ? formData.percentOrAmount
                : null,
            fixedAmount:
              formData.type === TypeEnum.FIXED_AMOUNT
                ? formData.percentOrAmount
                : null,
            rule: {
              allCombos: formData.rules.appliesTo.allCombos,
              allItems: formData.rules.appliesTo.allItems,
              stackingAvailable: formData.stacking,
              users: formData.rules.eligibleCustomer.users.map(
                (user) => user.uuid
              ),
              lifetimeSpend: formData.rules.eligibleCustomer.spend,
              startDate: startDate?.toISOString(),
              endDate: endDate ? endDate?.toISOString() : null,
              locations: formData.rules.locationAvailability.locations.map(
                (location) => location.uuid
              ),
              items: formData.rules.appliesTo.items.map((item) => item.uuid),
              combos: formData.rules.appliesTo.combos.map(
                (combo) => combo.uuid
              ),
              orderTypes,
              limitPerCustomer: formData.rules.usageLimit.limitPerCustomer,
              generatedQuantity: formData.rules.usageLimit.limitedTo,
            },
          },
        },
      })

      const discountUUID = get(createdDiscount, "data.createDiscount.uuid")

      if (discountUUID) {
        reset()

        return history.push(restaurants.discounts)
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const closeModal = () => {
    return history.push(restaurants.discounts)
  }

  useEffect(() => {
    setFocus(CODE_NAME)
  }, [setFocus])

  return (
    <ModalFull
      visible
      closable
      onCancel={closeModal}
      title={intl.formatMessage({
        id: "restaurants.discount.header.title",
        defaultMessage: "New Discount",
      })}
    >
      <FormProvider {...formMethods}>
        <GenericForm loading={loading} onSubmit={onSaveDiscount} />
      </FormProvider>
    </ModalFull>
  )
}
