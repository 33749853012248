import { useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"

export const useSearchParams = () => {
  const { search } = useLocation()
  const { push, replace } = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const getParam = (param: string) => searchParams.get(param)

  const setParam = (type: "push" | "replace", param: string, value: string) => {
    searchParams.set(param, value)
    const locate = type === "replace" ? replace : push
    locate({ search: searchParams.toString() })
  }

  const deleteParam = (param: string) => {
    searchParams.delete(param)
    replace({ search: searchParams.toString() })
  }

  return { getParam, setParam, deleteParam }
}
