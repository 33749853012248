import { useTimeEntriesContext } from "../../TimeEntriesContext"
import TimeEntryForm from "../../TimeEntryForm"
import { TimeEntryType } from "../../types/employee.type"
import { Modal, Stack, Title } from "@mantine/core"
import { useIntl } from "react-intl"

interface EditEntryModalProps {
  opened: boolean
  onClose: () => void
  entryData: TimeEntryType
}

export const EditEntryModal = (props: EditEntryModalProps) => {
  const { opened, onClose, entryData } = props
  const intl = useIntl()
  const timeEntries = useTimeEntriesContext()

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={
        <Title order={4}>
          {intl.formatMessage({
            id: "restaurant.locations.time.entries.modal.title",
            defaultMessage: "Edit time entry",
          })}
        </Title>
      }
    >
      <Stack spacing={24}>
        <TimeEntryForm
          employeeEntries={timeEntries.entries}
          onSubmit={onClose}
          timeEntry={entryData}
        />
      </Stack>
    </Modal>
  )
}
