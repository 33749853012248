import { IntlMessage } from "../../../../shared/types"

export const DATE_ERROR_ID = "generic.error.date"
export const FUTURE_DATE_ERROR_ID = "generic.error.future.date"
export const ENTRY_OVERLAP_ERROR_ID = "generic.error.entry.overlap"
export const INCONSISTENT_DATES_ERROR_ID = "generic.error.inconsistent.dates"
export const REQUIRED_VALUE_ERROR_ID = "generic.error.required.value"
export const REQUIRED_PAY_RATE_ERROR_ID =
  "restaurant.time.entries.time.entry.form.pay.rate.error.message"

export const errorMessages: Record<string, IntlMessage> = {
  [ENTRY_OVERLAP_ERROR_ID]: {
    id: "restaurant.time.entries.time.entry.form.entry.overlap.error.message",
    defaultMessage: "This date overlaps with another time entry",
  },
  [INCONSISTENT_DATES_ERROR_ID]: {
    id: "restaurant.time.entries.time.entry.form.inconsistent.dates.error.message",
    defaultMessage: "The end date must be after the start date",
  },
  [FUTURE_DATE_ERROR_ID]: {
    id: "restaurant.time.entries.time.entry.form.future.dates.error.message",
    defaultMessage: "The date cannot be greater than or equal to now.",
  },
  [REQUIRED_VALUE_ERROR_ID]: {
    id: REQUIRED_VALUE_ERROR_ID,
    defaultMessage: "This field is required",
  },
  [REQUIRED_PAY_RATE_ERROR_ID]: {
    id: REQUIRED_PAY_RATE_ERROR_ID,
    defaultMessage: "This user does not have a pay rate set.",
  },
}
