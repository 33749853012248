import { useFilters } from "../../../../../shared/contexts/FilterProvider"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Text from "../../../../../ui/Typography/Text"
import { useDeleteModifierModalGetModifierQuery } from "../../GraphQL/DeleteModifierModalGetModifier.generated"
import useModifierGroups from "../../hooks/useModifierGroups"
import get from "lodash/get"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

interface DeleteModifierGroupModalProps {
  modifierGroupUUID: string
  onOk: () => void
  onCancel: () => void
}

const DeleteModifierGroupModal: React.FC<DeleteModifierGroupModalProps> = ({
  modifierGroupUUID,
  onOk,
  onCancel,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { deleteModifierGroup, modifierGroupDeleted } = useModifierGroups({
    search,
  })

  const { data } = useDeleteModifierModalGetModifierQuery({
    variables: { uuid: modifierGroupUUID },
  })

  const groupName: string = get(data, "getModifierGroupDetail.name", "")

  const onDelete = async () => {
    await deleteModifierGroup(modifierGroupUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.modifier.group.title",
        defaultMessage: "Delete modifier group",
      })}
      subtitle={
        <FormattedMessage
          id="restaurants.modifiers.modals.delete.modifier.group.subtitle"
          defaultMessage='This modifier group is currently published. If you delete it, you’ll affect all the items that contain "{groupName}" group. {newLine}'
          values={{
            groupName: groupName,
            newLine: (
              <Text>
                {intl.formatMessage({
                  id: "restaurants.modifiers.modals.delete.modifier.group.subtitle.new.line",
                  defaultMessage: "This action cannot be undone.",
                })}
              </Text>
            ),
          }}
        />
      }
      loading={modifierGroupDeleted.loading}
      onConfirm={onDelete}
      onCancel={onCancel}
      type="danger"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.modifier.group.cancel.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.modifiers.modals.delete.modifier.group.confirm.button",
        defaultMessage: "Delete",
      })}
      centered
      visible
    />
  )
}

export default DeleteModifierGroupModal
