import type { RModifierDetail } from "../../../../../../shared/graphql/generated/types"
import Button from "../../../../../../ui/Button"
import { Container } from "../../../../../../ui/Container/Container"
import { Text } from "../../../../../../ui/Typography/Text/Text"
import SoldOutIcon from "../../../../components/SoldOutIcon"
import { StyledRowContainer } from "./styled"

interface Props {
  modifier: RModifierDetail
  index: number
  updateIngredients(ingredient: RModifierDetail): void
}

const IngredientRow = ({ modifier, index, updateIngredients }: Props) => {
  const changeVisibility = () => {
    updateIngredients({ ...modifier, isVisible: !modifier.isVisible })
  }

  const changeAvailability = () => {
    updateIngredients({ ...modifier, isSoldOut: !modifier.isSoldOut })
  }

  if (!modifier) return null

  return (
    <Container key={modifier.uuid}>
      <StyledRowContainer
        id={`modifier-${index}`}
        className={`modifier-${index}`}
        key={index}
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="40px"
      >
        <Container width="100%" background="Neutral1" padding="4px 12px">
          <Text>{modifier.ingredient.name}</Text>
        </Container>
        <Container display="flex" alignItems="center" gap="24px">
          <Button
            type="button"
            hierarchy="tertiary"
            onClick={changeVisibility}
            leadingIcon={
              modifier.isVisible ?? true ? "ri-eye-line" : "ri-eye-off-line"
            }
            shape="square"
            data-testid="visible-icon"
          />
          <Button
            type="button"
            hierarchy="tertiary"
            onClick={changeAvailability}
            shape="square"
            size="default"
          >
            <SoldOutIcon
              isSoldOut={!!modifier.isSoldOut}
              color="Primary5"
              cursor="pointer"
            />
          </Button>
        </Container>
      </StyledRowContainer>
    </Container>
  )
}

export default IngredientRow
