import { useGoogleMapsContext } from "../../shared/contexts/GoogleMapsProvider"
import { useModal } from "../../shared/hooks/useModal"
import Button from "../../ui/Button"
import Container from "../../ui/Container"
import Modal from "../../ui/Modal"
import Spinner from "../../ui/Spinner"
import Tooltip from "../../ui/Tooltip"
import {
  DEFAULT_COORDINATES,
  DEFAULT_ZOOM,
  fullScreenMapOptions,
  lockedMapOptions,
} from "./constants"
import { GoogleMap } from "@react-google-maps/api"
import { useCallback, useRef } from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

type LocationMapProps = {
  coordinatesSetters: {
    setLatitude: (latitude: number) => void
    setLongitude: (longitude: number) => void
  }
  position: { lat?: string; lng?: string }
  validateAddress: () => Promise<boolean>
}

const LocationMap = (props: LocationMapProps) => {
  const {
    coordinatesSetters: { setLatitude, setLongitude },
    validateAddress,
    position: {
      lat: latitude = DEFAULT_COORDINATES.lat,
      lng: longitude = DEFAULT_COORDINATES.lng,
    },
  } = props
  const { isLoaded } = useGoogleMapsContext()
  const mapRef = useRef<google.maps.Map>()
  const [visibleMapModal, showMapModal, closeMapModal] = useModal()
  const intl = useIntl()
  const adjustPinText = intl.formatMessage({
    defaultMessage: "Adjust Pin",
    id: "location.map.adjust.pin.label",
  })

  const handleLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
  }, [])

  const handleSave = () => {
    closeMapModal()
    const newPosition = mapRef.current?.getCenter()?.toJSON()
    if (newPosition?.lat && newPosition.lng) {
      setLatitude(newPosition.lat)
      setLongitude(newPosition.lng)
    }
  }

  const handleAdjustClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    const validAddress = await validateAddress()
    validAddress && showMapModal()
  }

  if (!isLoaded) {
    return <Spinner />
  }

  return (
    <Container position="relative">
      <GoogleMap
        options={lockedMapOptions}
        mapContainerStyle={{
          width: "100%",
          height: "200px",
          borderRadius: "16px",
        }}
        center={{ lat: Number(latitude), lng: Number(longitude) }}
        zoom={DEFAULT_ZOOM}
      />
      <MapMarker />
      <AdjustPinButton
        title={adjustPinText}
        hierarchy="secondary"
        size="default"
        onClick={handleAdjustClick}
      />
      <Modal
        visible={visibleMapModal}
        title={adjustPinText}
        onCancel={closeMapModal}
        onOk={handleSave}
      >
        <Container position="relative">
          {isLoaded && (
            <>
              <GoogleMap
                options={fullScreenMapOptions}
                mapContainerStyle={{
                  width: "100%",
                  height: "54vh",
                  borderRadius: "16px",
                }}
                center={{ lat: Number(latitude), lng: Number(longitude) }}
                onLoad={handleLoad}
                zoom={DEFAULT_ZOOM}
              />
              <MapMarker isModalMarker />
              <StyledTooltip
                title={intl.formatMessage({
                  defaultMessage:
                    "You can move the pin to adjust the precise location for this address.",
                  id: "location.map.modal.tooltip.label",
                })}
                trigger="hover"
                defaultVisible
              />
            </>
          )}
        </Container>
      </Modal>
    </Container>
  )
}

export default LocationMap

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 50%;
  margin-top: -25px;
  right: 0;
  left: 0;
`

const MapMarker = styled.div<{ isModalMarker?: boolean }>`
  position: absolute;
  pointer-events: none;
  border-radius: 50% 50% 50% 10%;
  border: 4px solid ${({ theme }) => theme.colors.Primary5};
  transform: rotate(-45deg);
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  ${({ isModalMarker = false }) => css`
    bottom: ${isModalMarker ? "36px" : "25px"};
    width: ${isModalMarker ? "28px" : "20px"};
    height: ${isModalMarker ? "28px" : "20px"}; ;
  `}

  &::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: ${({ theme }) => theme.colors.Primary5};
  }
`

const AdjustPinButton = styled(Button)`
  position: absolute;
  bottom: 56px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
`
