import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishComboMutationVariables = Types.Exact<{
  input: Types.RPublishComboInput;
}>;


export type PublishComboMutation = { __typename?: 'Mutation', publishCombo: { __typename?: 'RPublishedCombo', hasSnapshot: boolean, isMain: boolean, isPublished: boolean, isVisible?: boolean | null, name: string, uuid: string } };


export const PublishComboDocument = gql`
    mutation PublishCombo($input: RPublishComboInput!) {
  publishCombo(input: $input) {
    hasSnapshot
    isMain
    isPublished
    isVisible
    name
    uuid
  }
}
    `;
export type PublishComboMutationFn = Apollo.MutationFunction<PublishComboMutation, PublishComboMutationVariables>;

/**
 * __usePublishComboMutation__
 *
 * To run a mutation, you first call `usePublishComboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishComboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishComboMutation, { data, loading, error }] = usePublishComboMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishComboMutation(baseOptions?: Apollo.MutationHookOptions<PublishComboMutation, PublishComboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishComboMutation, PublishComboMutationVariables>(PublishComboDocument, options);
      }
export type PublishComboMutationHookResult = ReturnType<typeof usePublishComboMutation>;
export type PublishComboMutationResult = Apollo.MutationResult<PublishComboMutation>;
export type PublishComboMutationOptions = Apollo.BaseMutationOptions<PublishComboMutation, PublishComboMutationVariables>;