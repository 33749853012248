import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRestaurantAttachmentMutationVariables = Types.Exact<{
  data: Types.CreateRestaurantAttachmentInput;
  restaurantUUID: Types.Scalars['ID'];
}>;


export type CreateRestaurantAttachmentMutation = { __typename?: 'Mutation', createRestaurantAttachment: { __typename?: 'RestaurantAttachmentModel', uuid: string, ext: Types.FileExtensionEnum, type: string, fileName: string, signedUrl: string, contentType: Types.ContentTypeEnum } };


export const CreateRestaurantAttachmentDocument = gql`
    mutation createRestaurantAttachment($data: CreateRestaurantAttachmentInput!, $restaurantUUID: ID!) {
  createRestaurantAttachment(data: $data, restaurantUUID: $restaurantUUID) {
    uuid
    ext
    type
    fileName
    signedUrl
    contentType
  }
}
    `;
export type CreateRestaurantAttachmentMutationFn = Apollo.MutationFunction<CreateRestaurantAttachmentMutation, CreateRestaurantAttachmentMutationVariables>;

/**
 * __useCreateRestaurantAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantAttachmentMutation, { data, loading, error }] = useCreateRestaurantAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      restaurantUUID: // value for 'restaurantUUID'
 *   },
 * });
 */
export function useCreateRestaurantAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestaurantAttachmentMutation, CreateRestaurantAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRestaurantAttachmentMutation, CreateRestaurantAttachmentMutationVariables>(CreateRestaurantAttachmentDocument, options);
      }
export type CreateRestaurantAttachmentMutationHookResult = ReturnType<typeof useCreateRestaurantAttachmentMutation>;
export type CreateRestaurantAttachmentMutationResult = Apollo.MutationResult<CreateRestaurantAttachmentMutation>;
export type CreateRestaurantAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateRestaurantAttachmentMutation, CreateRestaurantAttachmentMutationVariables>;