import {
  CancelOrderInput,
  Exact,
} from "../../../../shared/graphql/generated/types"
import notification from "../../../../ui/notification"
import {
  CancelCateringOrderMutation,
  useCancelCateringOrderMutation,
} from "../../Catering/Graphql/cancelCateringOrder.generated"
import { CancelCateringParamsForm } from "../../Catering/hookform/hookforms.interface"
import { CancelCateringOrderResolver } from "../../Catering/hookform/schemas"
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  InternalRefetchQueriesInclude,
  MutationUpdaterFunction,
  NormalizedCacheObject,
} from "@apollo/client"
import {
  Button,
  Group,
  Input,
  Modal,
  ModalProps,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core"
import { SubmitHandler, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

interface CancelOrderModalProps {
  opened: boolean
  onClose: () => void
  cancelMessage?: string
  orderUUID: string
  modalProps?: Partial<ModalProps>
  refetchQueries?:
    | InternalRefetchQueriesInclude
    | ((
        result: FetchResult<CancelCateringOrderMutation>
      ) => InternalRefetchQueriesInclude)
    | undefined
  cacheUpdaters?:
    | MutationUpdaterFunction<
        CancelCateringOrderMutation,
        Exact<{
          input: CancelOrderInput
        }>,
        DefaultContext,
        ApolloCache<NormalizedCacheObject>
      >
    | undefined
}

export const CancelOrderModal = ({
  opened,
  onClose,
  cancelMessage,
  orderUUID,
  modalProps,
  refetchQueries,
  cacheUpdaters,
}: CancelOrderModalProps) => {
  const intl = useIntl()
  const DEFAULT_MESSAGE = intl.formatMessage({
    id: "restaurants.detail.cancel.order.modal.default.message",
    defaultMessage: "Canceling an order cannot be undone.",
  })

  const {
    reset,
    formState: { isValid, isDirty },
    register,
    handleSubmit,
  } = useForm<CancelCateringParamsForm>({
    resolver: CancelCateringOrderResolver,
    mode: "all",
  })
  const [cancelCateringOrder, { loading }] = useCancelCateringOrderMutation()

  const handleCancelOrder: SubmitHandler<CancelCateringParamsForm> = async (
    formData
  ) => {
    const mutationInput = {
      order: {
        uuid: orderUUID,
      },
      comment: formData.note,
    }
    try {
      await cancelCateringOrder({
        variables: {
          input: mutationInput,
        },
        update: (cache: ApolloCache<NormalizedCacheObject>, result) => {
          if (result.data) {
            cacheUpdaters?.(cache, result, {
              variables: {
                input: mutationInput,
              },
            })
          }
        },
        onCompleted: () => onClose(),
        //In case refetch for queries not supporting cache
        ...(refetchQueries
          ? {
              refetchQueries,
            }
          : {}),
      })
    } catch {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.detail.cancel.order.modal.error.message",
          defaultMessage:
            "Something went wrong. Please try again. If the problem persists, contact support.",
        }),
      })
      onClose()
      reset()
    }
  }

  return (
    <Modal
      {...modalProps}
      centered
      opened={opened}
      onClose={() => {
        onClose()
        reset()
      }}
      title={intl.formatMessage({
        id: "restaurants.detail.cancel.order.modal.title",
        defaultMessage: "Cancel order",
      })}
    >
      <form onSubmit={handleSubmit(handleCancelOrder)}>
        <Stack spacing={16}>
          <Text>{cancelMessage || DEFAULT_MESSAGE}</Text>
          <Textarea
            {...register("note")}
            label={intl.formatMessage({
              id: "restaurants.detail.cancel.order.modal.reason",
              defaultMessage: "Canceling order reason",
            })}
            withAsterisk
          />
          <Input.Wrapper
            w="100%"
            label={intl.formatMessage(
              {
                id: "restaurants.detail.cancel.order.modal.confirm.cancel.label",
                defaultMessage: "Write {cancel} to confirm this operation",
              },
              {
                cancel: (
                  <Text component="span" c={"red.6"} fw={"bold"}>
                    {intl.formatMessage({
                      id: "restaurants.detail.cancel.order.modal.confirm.cancel.label.text",
                      defaultMessage: "Cancel",
                    })}
                  </Text>
                ),
              }
            )}
            required
          >
            <TextInput {...register("confirmation")} />
          </Input.Wrapper>
          <Group position="right">
            <Button
              color="red.6"
              disabled={!isDirty || !isValid}
              loading={loading}
              type="submit"
            >
              {intl.formatMessage({
                id: "restaurants.detail.cancel.order.modal.cancel.button",
                defaultMessage: "Cancel order",
              })}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
