import { OrderPaymentStatusEnum } from "../../shared/graphql/generated/types"
import { IntlMessage } from "../../shared/types"
import { Group, MantineThemeColors, useMantineTheme } from "@mantine/core"
import { IconPoint } from "@tabler/icons-react"
import { useIntl } from "react-intl"

const paymentStatusColorMapping = (
  color: MantineThemeColors
): Record<OrderPaymentStatusEnum, string> => {
  return {
    [OrderPaymentStatusEnum.AWAITING]: color.gray[3],
    [OrderPaymentStatusEnum.FAILED]: color.gray[3],
    [OrderPaymentStatusEnum.NONE]: color.gray[3],
    [OrderPaymentStatusEnum.PARTIAL_PAID]: color.gray[3],
    [OrderPaymentStatusEnum.PENDING_CAPTURE]: color.gray[3],
    [OrderPaymentStatusEnum.REFUNDED]: color.gray[3],
    [OrderPaymentStatusEnum.SUCCEEDED]: color.green[5],
    [OrderPaymentStatusEnum.CANCELLED]: color.gray[3],
  }
}

const paymentStatusLabelMapping: Record<OrderPaymentStatusEnum, IntlMessage> = {
  AWAITING: {
    id: "order.payment.status.awaiting",
    defaultMessage: "Awaiting",
  },
  CANCELLED: {
    id: "order.payment.status.cancelled",
    defaultMessage: "Cancelled",
  },
  FAILED: {
    id: "order.payment.status.failed",
    defaultMessage: "Failed",
  },
  NONE: {
    id: "order.payment.status.none",
    defaultMessage: "No Payment",
  },
  PARTIAL_PAID: {
    id: "order.payment.status.partial.paid",
    defaultMessage: "Partial paid",
  },
  PENDING_CAPTURE: {
    id: "order.payment.status.pending.capture",
    defaultMessage: "Pending capture",
  },
  REFUNDED: {
    id: "order.payment.status.refunded",
    defaultMessage: "Refunded",
  },
  SUCCEEDED: {
    id: "order.payment.status.succeeded",
    defaultMessage: "Paid",
  },
}

interface Props {
  status: OrderPaymentStatusEnum
}

export const PaymentStatus = ({ status }: Props) => {
  const intl = useIntl()
  const theme = useMantineTheme()
  const pointColor = paymentStatusColorMapping(theme.colors)[status]

  return (
    <Group noWrap spacing="xs">
      <IconPoint size={16} color={pointColor} fill={pointColor} />
      {intl.formatMessage(paymentStatusLabelMapping[status])}
    </Group>
  )
}
