import styles from "./Table.module.css"
import { Table as MantineTable } from "@mantine/core"
import { ReactNode } from "react"

type ReportsTableProps = {
  children: ReactNode
}

export const Table = (props: ReportsTableProps) => {
  const { children } = props

  return (
    <MantineTable withBorder display="block" className={styles.table}>
      {children}
    </MantineTable>
  )
}

export const TableHeader = ({ children }: { children: ReactNode }) => {
  return <thead className={styles["table-header"]}>{children}</thead>
}

export const TableRow = ({ children }: { children: React.ReactNode }) => {
  return <tr>{children}</tr>
}

export const TableCellHeader = ({
  colSpan,
  children,
}: {
  colSpan?: number
  children: React.ReactNode
}) => {
  return <th colSpan={colSpan}>{children}</th>
}

export const TableCell = ({ children }: { children: React.ReactNode }) => {
  return <td width="100%">{children}</td>
}

export const TableBody = ({ children }: { children: React.ReactNode }) => {
  return <tbody>{children}</tbody>
}
