import { useGeneralContext } from "../../shared/contexts/StoreProvider"
import momentTz from "moment-timezone"
import { useEffect } from "react"

export const TimezoneSetter = () => {
  const {
    state: {
      selectedLocation: { timezone },
    },
  } = useGeneralContext()

  useEffect(() => {
    //Set default timezone if timezone is set
    if (timezone) {
      momentTz.tz.setDefault(timezone)
      return
    }

    //Set default timezone to browser timezone
    //if timezone is not set
    momentTz.tz.setDefault()
  }, [timezone])

  return null
}
