import type { StepStateEnum } from "../../interfaces"
import { StateIndicatorProperties } from "./config"
import React from "react"
import styled, { css } from "styled-components"

type StepIndicatorProps = {
  state: `${StepStateEnum}`
  number: 1 | 2 | 3
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ number, state }) => {
  return (
    <Ellipse state={state}>
      {StateIndicatorProperties[state].content(number)}
    </Ellipse>
  )
}

export default StepIndicator

const Ellipse = styled.div<{ state: `${StepStateEnum}` }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  ${({ state, theme }) => css`
    background-color: ${theme.colors[StateIndicatorProperties[state].color]};
  `}
`
