import Container from "../../../../ui/Container"
import { AddDiscountButton } from "./AddDiscountButton"

export const DiscountActions = () => {
  return (
    <Container width="100%" display="flex" alignItems="center" gap="24px">
      <AddDiscountButton />
    </Container>
  )
}
