import { Skeleton, Stack } from "@mantine/core"

export const CateringTableSkeleton = () => {
  return (
    <Stack>
      <Skeleton w="100%" h={40} />
      <Skeleton w="100%" h={40} />
      <Skeleton w="100%" h={40} />
      <Skeleton w="100%" h={40} />
    </Stack>
  )
}
