import * as Types from '../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishRestaurantMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type PublishRestaurantMutation = { __typename?: 'Mutation', publishRestaurant: { __typename?: 'RestaurantBasicModel', name: string, uuid: string } };


export const PublishRestaurantDocument = gql`
    mutation publishRestaurant($uuid: ID!) {
  publishRestaurant(uuid: $uuid) {
    name
    uuid
  }
}
    `;
export type PublishRestaurantMutationFn = Apollo.MutationFunction<PublishRestaurantMutation, PublishRestaurantMutationVariables>;

/**
 * __usePublishRestaurantMutation__
 *
 * To run a mutation, you first call `usePublishRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishRestaurantMutation, { data, loading, error }] = usePublishRestaurantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishRestaurantMutation(baseOptions?: Apollo.MutationHookOptions<PublishRestaurantMutation, PublishRestaurantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishRestaurantMutation, PublishRestaurantMutationVariables>(PublishRestaurantDocument, options);
      }
export type PublishRestaurantMutationHookResult = ReturnType<typeof usePublishRestaurantMutation>;
export type PublishRestaurantMutationResult = Apollo.MutationResult<PublishRestaurantMutation>;
export type PublishRestaurantMutationOptions = Apollo.BaseMutationOptions<PublishRestaurantMutation, PublishRestaurantMutationVariables>;