import { GetOneDiscountQuery } from "../forms/graphql/getOneDiscount.generated"

export const getUsageLimit = (
  getOneDiscount: GetOneDiscountQuery["getOneDiscount"]
) => {
  const limitedTo = getOneDiscount.rule?.generatedQuantity ?? null
  const limitPerCustomer = getOneDiscount.rule?.limitPerCustomer ?? null

  return { limitedTo, limitPerCustomer }
}
