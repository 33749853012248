import type {
  RPublishComboInput,
  RSaveComboInput,
} from "../../../../../shared/graphql/generated/types"
import { useModal } from "../../../../../shared/hooks/useModal"
import paths from "../../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import { formNotification } from "../../../../../ui/notification"
import ComboModalHeader from "../../../components/ModalFullDetailHeader"
import DropdownCombo from "../components/DropdownCombo"
import PublishComboModal from "../components/modals/PublishComboModal"
import ComboCreationForm from "../forms/ComboCreationForm"
import { ComboFormResolver } from "../forms/ComboForm.yup"
import ComboForm from "../forms/ComboInfoForm"
import { COMBO_FILTER } from "../forms/SingleItemForm/utils/constants"
import { useCombos } from "../hooks/useCombos"
import type { IComboForm } from "../interfaces/hookforms.interfaces"
import { comboFormDataToInput } from "../utils/combo-form-to-input"
import React, { useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type CreateComboProps = {
  isCorporate?: boolean
}

const CreateCombo: React.FC<CreateComboProps> = ({ isCorporate = true }) => {
  const intl = useIntl()
  const { restaurants } = paths
  const uploadingImageState = useState(false)
  const [uploadingImage] = uploadingImageState
  const { saveCombo, comboSaved } = useCombos({})
  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()

  const [savedComboName, setSavedComboName] = useState<string>(() =>
    intl.formatMessage({
      id: "restaurants.menu.items.create.combo.title",
      defaultMessage: "New Combo",
    })
  )

  const breadcrumbPath = isCorporate
    ? breadcrumbs.createCombo
    : breadcrumbs.createCorporateCombo

  const formMethods = useForm<IComboForm>({
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      image: undefined,
      menus: [],
      comboItemGroups: [{ uuid: "", name: "", comboItems: [] }],
      isPublished: false,
      isSoldOut: false,
      isMain: isCorporate,
      discountPercentage: 0,
      priceOverride: 0,
      closeForm: false,
    },
    resolver: ComboFormResolver,
  })

  const {
    control,
    getValues,
    setValue,
    clearErrors,
    trigger,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = formMethods

  const [lastSavedAt, lastPublishedAt, hasSnapshot, comboUUID] = useWatch({
    name: ["lastSavedAt", "lastPublishedAt", "hasSnapshot", "uuid"],
    control: control,
  })

  const onSave = async () => {
    const formData = getValues()
    clearErrors("comboItemGroups")
    const isValidName = await trigger("name")

    if (!isValidName) {
      const nameErrorMessage = intl.formatMessage({
        id: "restaurants.menu.items.create.combo.publish.notification.name.error.message",
        defaultMessage: "Name the combo",
      })
      const nameError = errors.name ? { message: nameErrorMessage } : undefined

      return formNotification({
        key: "lsm.create.combo.save",
        header: intl.formatMessage({
          id: "restaurants.menu.items.create.combo.save.notification.error.header",
          defaultMessage: "You can't save this combo",
        }),
        title: intl.formatMessage({
          id: "restaurants.menu.items.create.combo.save.notification.error.title",
          defaultMessage: "To save a combo you need to:",
        }),
        type: "error",
        error: { ...errors, ...(nameError && { name: nameError }) },
      })
    }

    await saveCombo(
      comboFormDataToInput(formData) as RSaveComboInput,
      (result) => {
        setSavedComboName(result.saveCombo.name)
        setValue("uuid", result.saveCombo.uuid)
        setValue("lastSavedAt", new Date())
        setValue("hasSnapshot", true)

        reset({}, { keepValues: true })
      },
      showGraphqlErrors
    )

    return
  }

  const onPublish = () => {
    handleSubmit(
      () => showPublishConfirmation(),
      (error) => {
        const nameErrorMessage = intl.formatMessage({
          id: "restaurants.menu.items.create.combo.publish.notification.name.error.message",
          defaultMessage: "Name the combo",
        })
        const nameError = error.name ? { message: nameErrorMessage } : undefined

        const cmbItmGrpErrorMessage = intl.formatMessage({
          id: "restaurants.menu.items.create.combo.publish.notification.combo.item.group.error.message",
          defaultMessage: "At least add one combo item",
        })
        const errorCmbItmGrps = error.comboItemGroups
          ? { message: cmbItmGrpErrorMessage }
          : undefined

        formNotification({
          key: "lsm.create.combo.publish",
          header: intl.formatMessage({
            id: "restaurants.menu.items.create.combo.publish.notification.error.header",
            defaultMessage: "You can't publish this combo",
          }),
          title: intl.formatMessage({
            id: "restaurants.menu.items.create.combo.publish.notification.error.title",
            defaultMessage: "To publish a combo you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(nameError && { name: nameError }),
            ...(errorCmbItmGrps && { comboItemGroups: errorCmbItmGrps }),
          },
        })
      }
    )()
  }

  const publishCallback = () => {
    dismissPublishConfirmation()
    onClose()
  }

  const onClose = () => {
    setValue("closeForm", true)
    reset({}, { keepDirty: false, keepValues: true })
  }

  const getComboData = () => {
    if (isDirty) {
      return comboFormDataToInput(getValues())
    }
  }

  return (
    <ModalFull
      goBackPath={restaurants.items + COMBO_FILTER}
      headerColor="Neutral2"
      title={
        <ComboModalHeader
          onSave={onSave}
          onPublish={onPublish}
          lastSavedAt={lastSavedAt}
          lastPublishedAt={lastPublishedAt}
          enablePreview={!!comboUUID && !!lastSavedAt}
          enablePublish={(hasSnapshot || isDirty) && !uploadingImage}
          enableSave={isDirty && !uploadingImage}
          actions={
            <DropdownCombo
              onOk={onClose}
              isCorporate={isCorporate}
              comboUUID={comboUUID}
              actions={["DELETE_DRAFT", "UNPUBLISH", "DISABLE_UNPUBLISH"]}
            />
          }
          loadingPublish={false}
          loadingSave={comboSaved.loading}
        >
          <Breadcrumb breadcrumbs={breadcrumbPath} pageName={savedComboName} />
        </ComboModalHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ComboForm uploadingImageState={uploadingImageState} />
        <ComboCreationForm isCorporate={isCorporate} />
      </FormProvider>
      {publishModalVisible && (
        <PublishComboModal
          comboUUID={comboUUID}
          comboData={getComboData() as RPublishComboInput}
          onOk={publishCallback}
          onCancel={dismissPublishConfirmation}
        />
      )}
    </ModalFull>
  )
}

export default CreateCombo
