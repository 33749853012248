interface EmployeeInfo {
  name: string
  uuid: string
}

export enum TimeBeforeEventEnum {
  _24H = "_24H",
  _48H = "_48H",
  _72H = "_72H",
}

export enum CateringServiceEnum {
  DELIVERY = "DELIVERY",
  FOOD_TRUCK_EXPERIENCE = "FOOD_TRUCK_EXPERIENCE",
  INSIDE_VENUE = "INSIDE_VENUE",
  PICK_UP = "PICK_UP",
}

export enum OrderMethodAcceptanceEnum {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export enum FeeQuantityEnum {
  AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export type EmployeeType = {
  value: string
  label: string
}

export const mappingCateringService: Record<CateringServiceEnum, string> = {
  [CateringServiceEnum.PICK_UP]: "pickup",
  [CateringServiceEnum.DELIVERY]: "delivery",
  [CateringServiceEnum.FOOD_TRUCK_EXPERIENCE]: "foodTruckExperience",
  [CateringServiceEnum.INSIDE_VENUE]: "insideVenue",
}

export type PrePaymentType = {
  amount?: number | null
  percentage?: number | null
}
export type TypeOfService = {
  pickup: boolean
  delivery: boolean
  foodTruckExperience: boolean
  insideVenue: boolean
}

export type ServicesType = {
  feeFixedAmount?: number | null
  feePercentage?: number | null
  minimumAmount?: number | null
  type: CateringServiceEnum
}

export type CancelPoliciesType = {
  percentage?: number
  timeBeforeEvent?: TimeBeforeEventEnum
}

export type MinimumAmountType = {
  delivery: {
    apply: boolean
    amount?: number
  }
  pickup: {
    apply: boolean
    amount?: number
  }
  foodTruckExperience: {
    apply: boolean
    amount?: number
  }
  insideVenue: {
    apply: boolean
    amount?: number
  }
}

export type CateringFeeType = {
  delivery: {
    apply: boolean
    value?: number
    type: FeeQuantityEnum
  }
  pickup: {
    apply: boolean
    value?: number
    type: FeeQuantityEnum
  }
  foodTruckExperience: {
    apply: boolean
    value?: number
    type: FeeQuantityEnum
  }
  insideVenue: {
    apply: boolean
    value?: number
    type: FeeQuantityEnum
  }
}

export interface CateringForm {
  isActive: boolean
  employees?: Array<EmployeeInfo>
  selectedEmployees?: Array<string>
  acceptanceMethod: OrderMethodAcceptanceEnum
  rules: {
    typeOfService: TypeOfService
    orderScheduling: {
      daysInAdvance: number
    }
    minimumAmount: MinimumAmountType
    cateringFee: {
      delivery: { apply: boolean; value?: number; type: FeeQuantityEnum }
      pickup: { apply: boolean; value?: number; type: FeeQuantityEnum }
      foodTruckExperience: {
        apply: boolean
        value?: number
        type: FeeQuantityEnum
      }
      insideVenue: { apply: boolean; value?: number; type: FeeQuantityEnum }
    }
    cancelPolicy: {
      isApply?: boolean
      cancelPolicies: CancelPoliciesType[]
    }
    prePayment: {
      paymentMethod: FeeQuantityEnum
      paymentValue?: number
    }
  }
}
