import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRestaurantAttachmentMutationVariables = Types.Exact<{
  data: Types.UpdateRestaurantAttachmentInput;
  restaurantUUID: Types.Scalars['ID'];
}>;


export type UpdateRestaurantAttachmentMutation = { __typename?: 'Mutation', updateRestaurantAttachment: { __typename?: 'RestaurantAttachmentModel', uuid: string, fileName: string, signedUrl: string, ext: Types.FileExtensionEnum, contentType: Types.ContentTypeEnum, type: string } };


export const UpdateRestaurantAttachmentDocument = gql`
    mutation updateRestaurantAttachment($data: UpdateRestaurantAttachmentInput!, $restaurantUUID: ID!) {
  updateRestaurantAttachment(data: $data, restaurantUUID: $restaurantUUID) {
    uuid
    fileName
    signedUrl
    ext
    contentType
    type
  }
}
    `;
export type UpdateRestaurantAttachmentMutationFn = Apollo.MutationFunction<UpdateRestaurantAttachmentMutation, UpdateRestaurantAttachmentMutationVariables>;

/**
 * __useUpdateRestaurantAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantAttachmentMutation, { data, loading, error }] = useUpdateRestaurantAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      restaurantUUID: // value for 'restaurantUUID'
 *   },
 * });
 */
export function useUpdateRestaurantAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestaurantAttachmentMutation, UpdateRestaurantAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRestaurantAttachmentMutation, UpdateRestaurantAttachmentMutationVariables>(UpdateRestaurantAttachmentDocument, options);
      }
export type UpdateRestaurantAttachmentMutationHookResult = ReturnType<typeof useUpdateRestaurantAttachmentMutation>;
export type UpdateRestaurantAttachmentMutationResult = Apollo.MutationResult<UpdateRestaurantAttachmentMutation>;
export type UpdateRestaurantAttachmentMutationOptions = Apollo.BaseMutationOptions<UpdateRestaurantAttachmentMutation, UpdateRestaurantAttachmentMutationVariables>;