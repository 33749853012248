import type { GetMenuListQuery } from "../../../../../../../GraphQL/Queries/getMenuList.generated"
import { useGetMenuListQuery } from "../../../../../../../GraphQL/Queries/getMenuList.generated"
import imageDefault from "../../../../../../../shared/assets/images/pattern.png"
import { useGeneralContext } from "../../../../../../../shared/contexts/StoreProvider"
import { MenuStatusEnum } from "../../../../../../../shared/graphql/generated/types"
import type { RBasicMenuDetail } from "../../../../../../../shared/graphql/generated/types"
import { updateGetMenuListQuery } from "../../../../../../../shared/graphql/updateQuery/updateGetMenuListQuery"
import { canFetchMore } from "../../../../../../../shared/utils/helpers/canFetchMore"
import Button from "../../../../../../../ui/Button"
import Container from "../../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import Img from "../../../../../../../ui/Img"
import Select from "../../../../../../../ui/Select"
import { onPopupScroll } from "../../../../../../../ui/Select/helpers/onPopupScroll"
import Spacer from "../../../../../../../ui/Spacer"
import Tag from "../../../../../../../ui/Tag"
import Tooltip from "../../../../../../../ui/Tooltip"
import Text from "../../../../../../../ui/Typography/Text"
import Title from "../../../../../../../ui/Typography/Title"
import OwnershipTag from "../../../../../components/OwnershipTag"
import StatusBadge from "../../../../../components/StatusBadge"
import { StatusBadgeEnum } from "../../../../../components/StatusBadge/StatusBadge"
import AvailableButton from "../../../../components/AvailableButton"
import type { GetComboDetailQuery } from "../../../GraphQL/getComboDetail.generated"
import type { ICorporateComboForm } from "../../../interfaces/hookforms.interfaces"
import { debounce, get } from "lodash"
import React, { useCallback, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type ComboInfoDetailFormProps = {
  combo: GetComboDetailQuery["getComboDetail"]
  currentMenus?: Array<RBasicMenuDetail>
}

const ComboInfoDetailForm: React.FC<ComboInfoDetailFormProps> = ({
  combo,
  currentMenus,
}) => {
  const intl = useIntl()

  const { control } = useFormContext<ICorporateComboForm>()

  const [searchValue, setSearchValue] = useState<string>()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const {
    data,
    loading,
    refetch: refetchMenus,
    fetchMore,
  } = useGetMenuListQuery({
    variables: { status: MenuStatusEnum.PUBLISHED, isMain: false },
    skip: !currentRestaurantUUID,
    fetchPolicy: "network-only",
  })

  const menus =
    data?.getMenuList.results?.map((menu) => ({
      value: menu.uuid,
      label: menu.name,
    })) || []

  currentMenus?.map((currentMenu) => {
    if (!menus.map((menu) => menu.value).includes(currentMenu.uuid))
      menus?.push({ value: currentMenu.uuid, label: currentMenu.name })
  })

  const hasNextPage = get(data, "getMenuList.hasNextPage", false)
  const endCursor = get(data, "getMenuList.endCursor", null)
  const canFetchMoreMenus = canFetchMore(loading, hasNextPage, endCursor)

  const onSearchMenus = React.useMemo(() => {
    const onSearch = async (value: string) => {
      try {
        await refetchMenus({
          name: value || "",
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    return debounce(onSearch, 600)
  }, [refetchMenus])

  const onSelectClear = () => {
    setSearchValue(undefined)
    onSearchMenus("")
  }

  const onSearch = (value: string) => {
    setSearchValue(value)

    if (value !== "") {
      onSearchMenus(value)
    }

    if (value === "") {
      onSelectClear()
    }
  }

  const fetchMoreMenus = useCallback(async () => {
    await fetchMore({
      variables: { after: endCursor },
      updateQuery: (prev: GetMenuListQuery, { fetchMoreResult }) =>
        updateGetMenuListQuery(prev, fetchMoreResult),
    })
  }, [endCursor, fetchMore])

  const corporateMenus = combo.menus.filter((menu) => menu.isMain)

  return (
    <>
      <Container
        display="flex"
        justifyContent="space-between"
        gap="12px"
        alignItems="center"
      >
        <Title weight="bold" size="s">
          {combo.name}
        </Title>

        <Container display="flex" gap="16px" alignItems="center">
          <Controller
            control={control}
            name="isVisible"
            render={({ field: { value, onChange } }) => {
              const isVisible = value ?? true

              return (
                <Button
                  type="button"
                  hierarchy="tertiary"
                  onClick={() => onChange(!isVisible)}
                  leadingIcon={isVisible ? "ri-eye-line" : "ri-eye-off-line"}
                  shape="square"
                  size="large"
                />
              )
            }}
          />

          <Controller
            control={control}
            name="isSoldOut"
            render={({ field: { value, onChange } }) => {
              const isSoldOut = value ?? false

              return (
                <AvailableButton
                  onClick={() => onChange(!isSoldOut)}
                  soldOut={isSoldOut}
                />
              )
            }}
          />
        </Container>
      </Container>

      <Spacer size={12} />
      <Container>
        <Tooltip
          title={intl.formatMessage({
            id: "restaurants.menu.items.forms.corporate.view.combo.detail.tooltip",
            defaultMessage: "Visible to customers",
          })}
          placement="bottom"
        >
          <StatusBadge
            status={
              combo.isPublished
                ? StatusBadgeEnum.PUBLISHED
                : StatusBadgeEnum.DRAFT
            }
          />
        </Tooltip>
      </Container>
      <Spacer size={8} />
      <OwnershipTag isMain={combo.isMain} />
      <Spacer size={64} />
      <Text>
        {intl.formatMessage({
          id: "restaurants.menu.items.forms.corporate.view.combo.detail.image.title",
          defaultMessage: "Image",
        })}
      </Text>
      <Spacer size={20} />
      {combo.attachment?.signedUrl ? (
        <Img
          width={128}
          height={128}
          src={combo.attachment?.signedUrl}
          placeholderImage={imageDefault}
        />
      ) : (
        <StyledPlaceholder width="128px" height="128px" image={imageDefault} />
      )}
      <Spacer size={64} />
      <Text>
        {intl.formatMessage({
          id: "restaurants.menu.items.forms.corporate.view.combo.detail.description.title",
          defaultMessage: "Description",
        })}
      </Text>
      <Spacer size={8} />
      {combo.description ? (
        <Text color="Neutral6">{combo.description}</Text>
      ) : (
        <>-</>
      )}
      <Spacer size={64} />
      <Text>
        {intl.formatMessage({
          id: "restaurants.menu.items.forms.corporate.view.combo.detail.menu.title",
          defaultMessage: "Corporate Category(s)",
        })}
      </Text>
      <Spacer size={8} />
      {corporateMenus.length > 0 ? (
        <Container display="flex" flexWrap="wrap" gap="4px">
          {corporateMenus.map((menu) => (
            <Tag type="ghost" key={menu.uuid}>
              {menu.name}
            </Tag>
          ))}
        </Container>
      ) : (
        <>-</>
      )}
      <Spacer size={64} />
      <Controller
        control={control}
        name="menus"
        render={({ field: { value, onChange } }) => (
          <Select
            loading={loading}
            tooltipTitle={intl.formatMessage({
              defaultMessage:
                "Help your customers to find quickly this Item by classifying it by Categories",
              id: "restaurants.menu.items.forms.corporate.view.combo.menus.select.tooltip.title",
            })}
            label={intl.formatMessage({
              defaultMessage: "Location Categories(s)",
              id: "restaurants.menu.items.forms.corporate.view.combo.menus.select.label",
            })}
            mode="multiple"
            optionFilterProp="label"
            searchValue={searchValue}
            onSearch={onSearch}
            value={value}
            onChange={onChange}
            options={menus}
            listHeight={150}
            onPopupScroll={(e) =>
              onPopupScroll(e, canFetchMoreMenus, fetchMoreMenus)
            }
            allowSearch
          />
        )}
      />
      <Spacer size={64} />
    </>
  )
}

export default ComboInfoDetailForm

const StyledPlaceholder = styled.div<{
  image: string
  width?: string
  height?: string
}>(
  ({ theme, image, width, height }) => `
  background-image: url(${image});
  width: ${width || "100%"};
  height: ${height || "100%"};
  border-radius: 4px;
  border: 1px solid ${theme.colors["Neutral3"]};
`
)
