import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSingleLocationQueryVariables = Types.Exact<{
  locationUUID: Types.Scalars['ID'];
}>;


export type GetSingleLocationQuery = { __typename?: 'Query', getSingleLocation: { __typename?: 'LocationWithSchedulesModel', uuid: string, name: string, address: string, latitude?: string | null, longitude?: string | null, orderTypes: Array<Types.OrderMethodEnum>, acceptCash: boolean, estimatedPreparationTime?: number | null, timezone?: string | null, deletedAt?: any | null, isActive: boolean, phone?: string | null, attachment?: { __typename?: 'AttachmentModel', uuid: string, fileName: string, signedUrl: string } | null, bankingAccount?: { __typename?: 'BankingAccountModel', uuid: string, name: string, stIsConnected: boolean, isActive: boolean } | null, schedules: Array<{ __typename?: 'ScheduleDetailsModel', uuid: string, name: string, archivedAt?: any | null, scheduleDetail: Array<{ __typename?: 'ScheduleDetailModel', day: string, endDate: any, startDate: any }> }> } };


export const GetSingleLocationDocument = gql`
    query getSingleLocation($locationUUID: ID!) {
  getSingleLocation(locationUUID: $locationUUID) {
    uuid
    name
    address
    latitude
    longitude
    orderTypes
    acceptCash
    attachment {
      uuid
      fileName
      signedUrl
    }
    bankingAccount {
      uuid
      name
      stIsConnected
      isActive
    }
    schedules {
      uuid
      name
      archivedAt
      scheduleDetail {
        day
        endDate
        startDate
      }
    }
    estimatedPreparationTime
    timezone
    deletedAt
    isActive
    phone
  }
}
    `;

/**
 * __useGetSingleLocationQuery__
 *
 * To run a query within a React component, call `useGetSingleLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleLocationQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetSingleLocationQuery(baseOptions: Apollo.QueryHookOptions<GetSingleLocationQuery, GetSingleLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleLocationQuery, GetSingleLocationQueryVariables>(GetSingleLocationDocument, options);
      }
export function useGetSingleLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleLocationQuery, GetSingleLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleLocationQuery, GetSingleLocationQueryVariables>(GetSingleLocationDocument, options);
        }
export type GetSingleLocationQueryHookResult = ReturnType<typeof useGetSingleLocationQuery>;
export type GetSingleLocationLazyQueryHookResult = ReturnType<typeof useGetSingleLocationLazyQuery>;
export type GetSingleLocationQueryResult = Apollo.QueryResult<GetSingleLocationQuery, GetSingleLocationQueryVariables>;