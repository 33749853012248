import type { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"

type FlowFilterType = {
  key: keyof typeof OrderStatusEnum
  value: `${OrderStatusEnum}`[]
}[]

// In case new status flow is needed, modify the object below
export const FlowFilter: FlowFilterType = [
  { key: "PENDING", value: ["PREPARING"] },
  { key: "PREPARING", value: ["READY"] },
  { key: "READY", value: ["COMPLETED"] },
]

export const StatusArray = FlowFilter.map((item) => item.key)
