import { toFormat } from "../../../../shared/utils/currency/toFormat.dinero"
import Container from "../../../../ui/Container"
import Text from "../../../../ui/Typography/Text"

type RecordType = {
  name: string
  amount: number
}

const reportsColumns = <T extends RecordType>() => {
  const columns = [
    {
      title: "",
      dataIndex: "name",
      render: (name: string) => (
        <Text
          size="m"
          className="ellipsis"
          weight={
            name === "Gross Sales" ||
            name === "Total Collected" ||
            name == "Total Rewards"
              ? "bold"
              : "regular"
          }
          color="Neutral8"
        >
          {name}
        </Text>
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (record: T) => {
        return (
          <Container display="flex" justifyContent="flex-end">
            <Text
              size="m"
              className="ellipsis"
              weight={
                record.name === "Gross Sales" ||
                record.name === "Total Collected" ||
                record.name == "Total Rewards"
                  ? "bold"
                  : "regular"
              }
              color="Neutral8"
            >
              {toFormat(record.amount)}
            </Text>
          </Container>
        )
      },
    },
  ]

  return [columns]
}

export default reportsColumns
