import { GetItemListDocument } from "../../../../../GraphQL/Queries/getItemList.generated"
import type {
  RPublishItemInput,
  RSaveCorporateItemInput,
  RSaveItemInput,
  UpdateItemAttachmentInput,
} from "../../../../../shared/graphql/generated/types"
import { ItemProgressStatusEnum } from "../../../../../shared/graphql/generated/types"
import { putFileWithSignedUrl } from "../../../../../shared/utils/api/client"
import getImageFilename from "../../../../../shared/utils/helpers/getImageFilename"
import getImageMetadata from "../../../../../shared/utils/helpers/getImageMetadata"
import notification from "../../../../../ui/notification"
import type { SaveCorporateItemByLocationMutation } from "../../GraphQL/saveCorporateItemByLocation.generated"
import { useSaveCorporateItemByLocationMutation } from "../../GraphQL/saveCorporateItemByLocation.generated"
import type { CreateTempItemAttachmentMutation } from "../GraphQL/createTempItemAttachment.generated"
import { useCreateTempItemAttachmentMutation } from "../GraphQL/createTempItemAttachment.generated"
import type { CreateVariantAttachmentMutation } from "../GraphQL/createVariantAttachment.generated"
import { useCreateVariantAttachmentMutation } from "../GraphQL/createVariantAttachment.generated"
import type { DeleteItemMutation } from "../GraphQL/deleteItem.generated"
import { useDeleteItemMutation } from "../GraphQL/deleteItem.generated"
import type { DeleteItemAttachmentMutation } from "../GraphQL/deleteItemAttachment.generated"
import { useDeleteItemAttachmentMutation } from "../GraphQL/deleteItemAttachment.generated"
import { useDuplicateItemMutation } from "../GraphQL/duplicateItem.generated"
import type { PublishDraftItemMutation } from "../GraphQL/publishDraftItem.generated"
import { usePublishDraftItemMutation } from "../GraphQL/publishDraftItem.generated"
import type { PublishItemMutation } from "../GraphQL/publishItem.generated"
import { usePublishItemMutation } from "../GraphQL/publishItem.generated"
import type { SaveItemMutation } from "../GraphQL/saveItem.generated"
import { useSaveItemMutation } from "../GraphQL/saveItem.generated"
import type { UnpublishItemMutation } from "../GraphQL/unpublishItem.generated"
import { useUnpublishItemMutation } from "../GraphQL/unpublishItem.generated"
import type { UpdateItemAttachmentMutation } from "../GraphQL/updateItemAttachment.generated"
import { useUpdateItemAttachmentMutation } from "../GraphQL/updateItemAttachment.generated"
import { DuplicateItemInput } from "../interfaces/items.interfaces"
import {
  deleteItemFromCache,
  saveItemInCache,
} from "../utils/cache-items/items-cache-config"
import { ApolloError } from "@apollo/client"
import { omit } from "lodash"
import { useIntl } from "react-intl"

type UseItemsProps = {
  search?: string
}

export const useItems = ({ search }: UseItemsProps) => {
  const intl = useIntl()

  const [createTempItemAttachmentMutation] =
    useCreateTempItemAttachmentMutation()

  const createItemAttachment = async (
    itemUUID: string,
    file: File,
    onOk?: (result: CreateTempItemAttachmentMutation) => void,
    onError?: (error: Error) => void
  ) => {
    const fileToUpload = file

    if (fileToUpload) {
      const image = {
        ...getImageMetadata(fileToUpload.type),
        fileName: getImageFilename(fileToUpload.name),
      }

      try {
        const { data } = await createTempItemAttachmentMutation({
          variables: {
            input: {
              fileName: image.fileName,
              contentType: image.contentType,
              ext: image.ext,
            },
          },
        })

        if (data?.createTempItemAttachment.uuid) {
          await putFileWithSignedUrl(
            data.createTempItemAttachment.signedUrl,
            fileToUpload
          )
          onOk?.(data)
        }
      } catch (error) {
        notification({
          description: intl.formatMessage({
            id: "generic.error.image.message",
            defaultMessage: "Your image was unable to save.",
          }),
          type: "error",
        })
        onError?.(error as Error)
      }
    }
  }

  const [deleteItemAttachmentMutation] = useDeleteItemAttachmentMutation()

  const deleteItemAttachment = async (
    itemUUID: string,
    attachmentUUID: string,
    onOk?: (result: DeleteItemAttachmentMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await deleteItemAttachmentMutation({
        variables: { data: { itemUUID, uuid: attachmentUUID } },
      })

      if (data?.deleteItemAttachment.uuid) {
        onOk?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [updateItemAttachmentMutation] = useUpdateItemAttachmentMutation()

  const updateItemAttachment = async (
    input: UpdateItemAttachmentInput,
    file: File,
    onSuccess?: (result: UpdateItemAttachmentMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await updateItemAttachmentMutation({
        variables: { data: omit(input, "signedUrl") },
      })

      if (data?.updateItemAttachment.uuid) {
        try {
          await putFileWithSignedUrl(data.updateItemAttachment.signedUrl, file)
          onSuccess?.(data)
        } catch {
          notification({
            description: intl.formatMessage({
              id: "generic.error.image.message",
              defaultMessage: "Your image was unable to save.",
            }),
            type: "error",
          })
        }
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [createVariantAttachmentMutation] = useCreateVariantAttachmentMutation()

  const createItemVariantAttachment = async (
    file: File,
    onOk?: (result: CreateVariantAttachmentMutation) => void,
    onError?: (error: Error) => void
  ) => {
    const fileToUpload = file

    if (fileToUpload) {
      const image = {
        ...getImageMetadata(fileToUpload.type),
        fileName: getImageFilename(fileToUpload.name),
      }

      try {
        const { data } = await createVariantAttachmentMutation({
          variables: {
            input: {
              fileName: image.fileName,
              contentType: image.contentType,
              ext: image.ext,
            },
          },
        })

        if (data?.createTempVariantAttachment.uuid) {
          await putFileWithSignedUrl(
            data.createTempVariantAttachment.signedUrl,
            fileToUpload
          )
          onOk?.(data)
        }
      } catch (error) {
        notification({
          description: intl.formatMessage({
            id: "generic.error.image.message",
            defaultMessage: "Your image was unable to save.",
          }),
          type: "error",
        })
        onError?.(error as Error)
      }
    }
  }

  const [publishItemMutation] = usePublishItemMutation({
    onCompleted: (result) => {
      const { name, isPublished, hasSnapshot, uuid, isVisible, isMain } =
        result.publishItem

      saveItemInCache({
        data: {
          uuid,
          name,
          hasSnapshot,
          isMain,
          isVisible: isVisible ?? true,
          status: isPublished
            ? ItemProgressStatusEnum.PUBLISHED
            : ItemProgressStatusEnum.DRAFT,
        },
        variables: { name: search },
      })
    },
  })

  const publishItem = async (
    input: RPublishItemInput,
    onSuccess?: (result: PublishItemMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await publishItemMutation({ variables: { input } })

      if (data?.publishItem.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishDraftItemMutation] = usePublishDraftItemMutation({
    onCompleted: (result) => {
      saveItemInCache({
        data: {
          uuid: result.publishDraftItem.uuid,
          status: ItemProgressStatusEnum.PUBLISHED,
          hasSnapshot: false,
        },
        variables: { name: search },
      })
    },
  })

  const publishDraftItem = async (
    uuid: string,
    onSuccess?: (result: PublishDraftItemMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      if (!uuid) {
        throw new Error(
          intl.formatMessage({
            id: "restaurants.menu.items.use.items.publish.draft.item.error.message",
            defaultMessage: "item UUID is required to publish a item draft",
          })
        )
      }

      const { data } = await publishDraftItemMutation({
        variables: { uuid },
      })

      if (data?.publishDraftItem.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [saveItemMutation, itemSaved] = useSaveItemMutation({
    onCompleted: (result) => {
      const { name, isPublished, hasSnapshot, uuid, isVisible, isMain } =
        result.saveItem

      saveItemInCache({
        data: {
          uuid,
          name,
          hasSnapshot,
          isMain,
          isVisible: isVisible ?? true,
          status: isPublished
            ? ItemProgressStatusEnum.PUBLISHED
            : ItemProgressStatusEnum.DRAFT,
        },
        variables: {},
      })
    },
  })

  const saveItem = async (
    input: RSaveItemInput,
    onSuccess?: (result: SaveItemMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await saveItemMutation({
        variables: { input },
      })

      if (data?.saveItem.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [deleteItemMutation, itemDeleted] = useDeleteItemMutation({
    onCompleted: (result) => {
      deleteItemFromCache({
        uuid: result.deleteItem.uuid,
        variables: { name: search },
      })
    },
  })

  const deleteItem = async (
    uuid: string,
    onSuccess?: (result: DeleteItemMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await deleteItemMutation({
        variables: { uuid },
      })

      if (data?.deleteItem.uuid) {
        notification({
          title: intl.formatMessage({
            id: "restaurants.menu.items.use.items.delete.item.success.title",
            defaultMessage: "Menu item deleted",
          }),
          description: intl.formatMessage({
            id: "restaurants.menu.items.use.items.delete.item.success.description",
            defaultMessage: "Your menu item was deleted",
          }),
          type: "success",
        })
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [unpublishItemMutation, itemUnpublished] = useUnpublishItemMutation({
    onCompleted: (result) => {
      saveItemInCache({
        data: {
          uuid: result.unpublishItem.uuid,
          status: ItemProgressStatusEnum.UNPUBLISHED,
        },
        variables: { name: search },
      })
    },
  })

  const unpublishItem = async (
    uuid: string,
    onSuccess?: (result: UnpublishItemMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await unpublishItemMutation({
        variables: { uuid },
      })

      if (data?.unpublishItem.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [saveCorporateItemByLocationMutation, corporateItemSaved] =
    useSaveCorporateItemByLocationMutation({
      onCompleted: (result) => {
        saveItemInCache({
          data: {
            uuid: result.saveCorporateItemByLocation.uuid,
            isVisible: result.saveCorporateItemByLocation.isVisible,
          },
          variables: { name: search },
        })
      },
    })

  const saveCorporateItem = async (
    input: RSaveCorporateItemInput,
    onSuccess?: (result: SaveCorporateItemByLocationMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await saveCorporateItemByLocationMutation({
        variables: { input },
      })

      if (data?.saveCorporateItemByLocation.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [duplicateItemMutation, duplicatedItem] = useDuplicateItemMutation({
    refetchQueries: [
      {
        query: GetItemListDocument,
        variables: { name: search },
      },
    ],
  })

  const duplicateItem = async (
    input: DuplicateItemInput,
    onSuccess: () => void,
    onError: (error: Error) => void
  ) => {
    const { uuid, ...rest } = input
    try {
      const { data } = await duplicateItemMutation({
        variables: {
          data: {
            item: { uuid },
            ...rest,
          },
        },
      })
      if (data?.duplicateItem.uuid) {
        onSuccess?.()
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        onError?.(error)
      }
    }
  }
  return {
    publishItem,
    publishDraftItem,
    saveItem,
    itemSaved,
    deleteItem,
    itemDeleted,
    unpublishItem,
    itemUnpublished,
    createItemAttachment,
    deleteItemAttachment,
    updateItemAttachment,
    saveCorporateItem,
    corporateItemSaved,
    createItemVariantAttachment,
    duplicateItem,
    duplicatedItem,
  }
}
