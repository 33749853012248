import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnpublishComboMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type UnpublishComboMutation = { __typename?: 'Mutation', unpublishCombo: { __typename?: 'RBasicEntityData', name: string, uuid: string } };


export const UnpublishComboDocument = gql`
    mutation UnpublishCombo($uuid: ID!) {
  unpublishCombo(uuid: $uuid) {
    name
    uuid
  }
}
    `;
export type UnpublishComboMutationFn = Apollo.MutationFunction<UnpublishComboMutation, UnpublishComboMutationVariables>;

/**
 * __useUnpublishComboMutation__
 *
 * To run a mutation, you first call `useUnpublishComboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishComboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishComboMutation, { data, loading, error }] = useUnpublishComboMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnpublishComboMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishComboMutation, UnpublishComboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishComboMutation, UnpublishComboMutationVariables>(UnpublishComboDocument, options);
      }
export type UnpublishComboMutationHookResult = ReturnType<typeof useUnpublishComboMutation>;
export type UnpublishComboMutationResult = Apollo.MutationResult<UnpublishComboMutation>;
export type UnpublishComboMutationOptions = Apollo.BaseMutationOptions<UnpublishComboMutation, UnpublishComboMutationVariables>;