import { useSearchParams } from "../../../../shared/hooks/useSearchParams"

const viewModeParam = "view-mode"

export const viewModeType = (viewMode: string) => {
  const { setParam } = useSearchParams()

  if (!viewMode) {
    setParam("replace", viewModeParam, "TABLE")
    return "TABLE"
  }

  return viewMode
}
