import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { RestaurantByUserModel } from "../../../../../shared/graphql/generated/types"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs"
import { GetAllRestaurantsByUserDocument } from "../../../../../ui/Layouts/MainLayout/Sidebar/DropdownMenu/GraphQL/getAllRestaurantsByUser.generated"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Spacer from "../../../../../ui/Spacer"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import { ActionTypes } from "../../../../Restaurants/actions"
import { RestaurantDeleteResolver } from "./DeleteRestaurantForm.yup"
import { useDeleteRestaurantMutation } from "./GraphQL/deleteRestaurant.generated"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"

type DeleteRestaurantModalProps = Omit<IModalProps, "onCancel"> & {
  restaurantName?: string
  onClose: () => void
}

export const DeleteRestaurantModal: React.FC<DeleteRestaurantModalProps> = ({
  restaurantName,
  onClose,
  ...props
}) => {
  const intl = useIntl()
  const { push } = useHistory()

  const methods = useForm({
    mode: "all",
    resolver: RestaurantDeleteResolver,
    defaultValues: { word: "" },
  })

  const {
    control,
    formState: { errors, isValid },
  } = methods

  const {
    state: {
      restaurants,
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: adminUUID },
      },
    },
    dispatch,
  } = useGeneralContext()

  const [deleteRestaurant, { loading }] = useDeleteRestaurantMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          getAllRestaurantsByUser(prev) {
            const newListRestaurants = prev.filter(
              (restaurant: RestaurantByUserModel) =>
                restaurant.uuid !== data?.deleteRestaurant.uuid
            )
            dispatch({
              type: ActionTypes.AddAllRestaurants,
              payload: [...newListRestaurants],
            })

            dispatch({
              type: ActionTypes.SetCurrentState,
              payload: {
                restaurantUUID: newListRestaurants[0],
              },
            })

            return newListRestaurants
          },
        },
      })
    },
    refetchQueries: [GetAllRestaurantsByUserDocument],
    onCompleted: () => {
      if (restaurants.length === 0) {
        return null
      }

      dispatch({
        type: ActionTypes.SetCurrentState,
        payload: {
          restaurantUUID: restaurants[0].uuid,
          refirectToRoot: true,
        },
      })
    },
  })

  const onDeleteLocation = async () => {
    try {
      await deleteRestaurant({
        variables: {
          data: {
            restaurantUUID,
            adminUUID,
          },
        },
      })

      notification({
        type: "success",
        description: intl.formatMessage({
          id: "restaurants.home.delete.modal.message",
          defaultMessage: "Restaurant sucessfully deleted!",
        }),
      })
      onClose()
      push(paths.restaurants.root)
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <Modal
      {...props}
      onCancel={onClose}
      title={intl.formatMessage({
        id: "restaurants.home.delete.modal.title",
        defaultMessage: "Delete Restaurant",
      })}
      footer={
        <Container display="flex" justifyContent="flex-end" gap="8px">
          <Button
            title={intl.formatMessage({
              id: "restaurants.home.delete.modal.cancel.button",
              defaultMessage: "Cancel",
            })}
            hierarchy="secondary"
            disabled={loading}
            onClick={onClose}
            display="danger"
          />
          <Button
            disabled={!isValid}
            loading={loading}
            title={intl.formatMessage({
              id: "restaurants.home.delete.modal.save.button",
              defaultMessage: "OK",
            })}
            onClick={onDeleteLocation}
            display="danger"
          />
        </Container>
      }
      destroyOnClose
    >
      <Text size="s">
        <FormattedMessage
          id="restaurants.home.delete.modal.description"
          defaultMessage="This is a dangerous action because you’re about to delete {restaurantName} restaurant and all its information related. You can’t undone this action. If you’re pretty sure about this please write {deleteTitle} in text box to confirm this operation."
          values={{
            restaurantName: (
              <HighlightedText size="m">{restaurantName}</HighlightedText>
            ),
            deleteTitle: (
              <HighlightedText size="m" color="Danger4">
                {intl.formatMessage({
                  id: "restaurants.home.delete.modal.description.word",
                  defaultMessage: "DELETE",
                })}
              </HighlightedText>
            ),
          }}
        />
      </Text>
      <Spacer size={24} />
      <Controller
        control={control}
        name="word"
        render={({ field }) => (
          <Input
            {...field}
            requirement="required"
            aria-label="word"
            hasError={!!errors.word}
            helperText={errors.word?.message}
            onChange={(event) =>
              field.onChange((field.value = event.target.value.toUpperCase()))
            }
          />
        )}
      />
    </Modal>
  )
}
