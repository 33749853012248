import type {
  GetComboListQuery,
  GetComboListQueryVariables,
} from "../../../../../../GraphQL/Queries/getComboList.generated"
import { GetComboListDocument } from "../../../../../../GraphQL/Queries/getComboList.generated"
import type { ApolloCache } from "@apollo/client"

type ReadCacheGetComboListQuery = {
  cache: ApolloCache<unknown>
  variables: GetComboListQueryVariables
}

const readGetComboListQueryCache = ({
  cache,
  variables,
}: ReadCacheGetComboListQuery) =>
  cache.readQuery<GetComboListQuery, GetComboListQueryVariables>({
    query: GetComboListDocument,
    variables,
  })

export default readGetComboListQueryCache
