import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import { Comparisson } from "../../../Reports/components/Comparisson"
import { NumberType } from "../../../Reports/components/Comparisson/Comparisson"
import {
  Card,
  Group,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { TablerIconsProps } from "@tabler/icons-react"
import { FormattedMessage, useIntl } from "react-intl"

type StatisticCardProps = {
  title: string
  icon: React.ComponentType<TablerIconsProps>
  numberType: Exclude<NumberType, "percentage">
  value: number
  diff: number
}

const SECONDS_IN_AN_HOUR = 3600

type FormatObject = Record<
  Exclude<NumberType, "percentage">,
  (num: number) => string
>

export const StatisticsCard = (props: StatisticCardProps) => {
  const { title, value, diff, icon, numberType } = props
  const intl = useIntl()
  const { colors } = useMantineTheme()

  const Icon = icon

  const format: FormatObject = {
    money: (num) => `${toFormat(num)}`,
    quantity: (num) => `${Math.round(num)}`,
    hours: (num) =>
      intl.formatMessage(
        {
          id: "restaurants.payroll.cards.hours.label",
          defaultMessage: "{num} hrs",
        },
        { num: (num / SECONDS_IN_AN_HOUR).toFixed(2) }
      ),
  }

  return (
    <Card mih={168} h="100%" w="100%" withBorder>
      <Stack spacing={32} h="100%" justify="space-between">
        <Group position="apart" align="flex-start" noWrap>
          <Text size="sm" c="gray.6" fw={600} lineClamp={2} title={title}>
            {title}
          </Text>
          <Icon size={12} color={colors.gray[6]} style={{ minWidth: 12 }} />
        </Group>
        <Stack spacing={5}>
          <Group spacing={4} align="baseline">
            <Title order={3}>{format[numberType](value)}</Title>
            {diff && (
              <Comparisson
                value={
                  numberType === "hours"
                    ? Math.round((diff / SECONDS_IN_AN_HOUR) * 100) / 100
                    : diff
                }
                valueType={numberType}
              />
            )}
          </Group>
          {diff ? (
            <Text size="sm" c="gray.6">
              <FormattedMessage
                defaultMessage="Compared to previous month"
                id="restaurants.payroll.cards.comparisson.label"
              />
            </Text>
          ) : (
            <Space h={34} />
          )}
        </Stack>
      </Stack>
    </Card>
  )
}
