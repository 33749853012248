import * as Types from '../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRestaurantSnapshotQueryVariables = Types.Exact<{
  userUUID: Types.Scalars['ID'];
}>;


export type GetRestaurantSnapshotQuery = { __typename?: 'Query', getRestaurantSnapshot?: { __typename?: 'DraftRestaurant', uuid: string, snapshot: { __typename?: 'RestaurantSnapshot', currentStep?: number | null, locationsQuantity?: string | null, name?: string | null, urlIdentifier?: string | null, brandColor?: string | null, notes?: string | null, logos?: Array<{ __typename?: 'RestaurantAttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, type: string, uuid: string, signedUrl: string }> | null, menuAttachments?: Array<{ __typename?: 'AttachmentSnapshot', contentType: Types.AttachmentContentTypeEnum, ext: Types.AttachmentExtensionEnum, fileName: string, uuid: string }> | null, location?: { __typename?: 'RestaurantLocation', address?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, city?: string | null, latitude?: string | null, longitude?: string | null, name?: string | null, postalCode?: string | null, orderTypes?: Array<Types.OrderMethodEnum> | null, attachment?: { __typename?: 'RestaurantAttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, type: string } | null } | null } } | null };


export const GetRestaurantSnapshotDocument = gql`
    query getRestaurantSnapshot($userUUID: ID!) {
  getRestaurantSnapshot(userUUID: $userUUID) {
    uuid
    snapshot {
      currentStep
      locationsQuantity
      name
      urlIdentifier
      brandColor
      logos {
        contentType
        ext
        fileName
        type
        uuid
        signedUrl
      }
      menuAttachments {
        contentType
        ext
        fileName
        uuid
      }
      notes
      location {
        address
        addressLine1
        addressLine2
        state
        attachment {
          contentType
          ext
          fileName
          type
        }
        city
        latitude
        longitude
        name
        postalCode
        orderTypes
      }
    }
  }
}
    `;

/**
 * __useGetRestaurantSnapshotQuery__
 *
 * To run a query within a React component, call `useGetRestaurantSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantSnapshotQuery({
 *   variables: {
 *      userUUID: // value for 'userUUID'
 *   },
 * });
 */
export function useGetRestaurantSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetRestaurantSnapshotQuery, GetRestaurantSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRestaurantSnapshotQuery, GetRestaurantSnapshotQueryVariables>(GetRestaurantSnapshotDocument, options);
      }
export function useGetRestaurantSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantSnapshotQuery, GetRestaurantSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRestaurantSnapshotQuery, GetRestaurantSnapshotQueryVariables>(GetRestaurantSnapshotDocument, options);
        }
export type GetRestaurantSnapshotQueryHookResult = ReturnType<typeof useGetRestaurantSnapshotQuery>;
export type GetRestaurantSnapshotLazyQueryHookResult = ReturnType<typeof useGetRestaurantSnapshotLazyQuery>;
export type GetRestaurantSnapshotQueryResult = Apollo.QueryResult<GetRestaurantSnapshotQuery, GetRestaurantSnapshotQueryVariables>;