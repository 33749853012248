import { IntlFormattersType } from "../../../../../../shared/types"
import {
  REQUIRED_FIELD_ERROR_ID,
  validationsTitle as genericValidationsTitle,
} from "../../GenericForm/validations.title"

export const validationsTitle: IntlFormattersType = {
  "restaurants.discounts.forms.generic.rules.select.later.end.date.message": {
    id: "restaurants.discounts.forms.generic.rules.select.later.end.date.message",
    defaultMessage: "Select an end date later than start date",
  },
  "restaurants.discounts.forms.generic.rules.required.date.message": {
    id: "restaurants.discounts.forms.generic.rules.required.date.message ",
    defaultMessage: "Required value",
  },
  [REQUIRED_FIELD_ERROR_ID]: genericValidationsTitle[REQUIRED_FIELD_ERROR_ID],
}
