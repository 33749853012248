import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeclineCateringOrderMutationVariables = Types.Exact<{
  order: Types.RuuidInput;
}>;


export type DeclineCateringOrderMutation = { __typename?: 'Mutation', declineCateringOrder: { __typename?: 'Message', message: string } };


export const DeclineCateringOrderDocument = gql`
    mutation declineCateringOrder($order: RUUIDInput!) {
  declineCateringOrder(order: $order) {
    message
  }
}
    `;
export type DeclineCateringOrderMutationFn = Apollo.MutationFunction<DeclineCateringOrderMutation, DeclineCateringOrderMutationVariables>;

/**
 * __useDeclineCateringOrderMutation__
 *
 * To run a mutation, you first call `useDeclineCateringOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineCateringOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineCateringOrderMutation, { data, loading, error }] = useDeclineCateringOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDeclineCateringOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeclineCateringOrderMutation, DeclineCateringOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineCateringOrderMutation, DeclineCateringOrderMutationVariables>(DeclineCateringOrderDocument, options);
      }
export type DeclineCateringOrderMutationHookResult = ReturnType<typeof useDeclineCateringOrderMutation>;
export type DeclineCateringOrderMutationResult = Apollo.MutationResult<DeclineCateringOrderMutation>;
export type DeclineCateringOrderMutationOptions = Apollo.BaseMutationOptions<DeclineCateringOrderMutation, DeclineCateringOrderMutationVariables>;