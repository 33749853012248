import SwipeableSteps from "../../../pages/Onboarding/components/SwipeableSteps"
import { Catering } from "../../../pages/Restaurants/Catering"
import { CateringDetail } from "../../../pages/Restaurants/Catering/CateringDetail"
import Customers from "../../../pages/Restaurants/Customers"
import CustomerDetail from "../../../pages/Restaurants/Customers/CustomerDetail"
import Discounts from "../../../pages/Restaurants/Discounts"
import { CreateDiscount } from "../../../pages/Restaurants/Discounts/CreateDiscount/CreateDiscount"
import { EditDiscount } from "../../../pages/Restaurants/Discounts/EditDiscount/EditDiscount"
import LegacyReports from "../../../pages/Restaurants/LegacyReports"
import MenuItems, {
  LocationMenuItems,
} from "../../../pages/Restaurants/MenuItems"
import CreateCombo from "../../../pages/Restaurants/MenuItems/Combos/CreateCombo"
// import { RestrictedRoutes } from '../RestrictedRoutes/RestrictedRoutes'
import {
  EditComboContainer,
  EditCorporateComboContainer,
} from "../../../pages/Restaurants/MenuItems/Combos/EditCombo/EditComboContainer"
import CreateItem from "../../../pages/Restaurants/MenuItems/Items/CreateItem"
import {
  EditCorporateItemContainer,
  EditItemContainer,
} from "../../../pages/Restaurants/MenuItems/Items/EditItem/EditItemContainer"
import AddMenuItemsButton, {
  AddLocationMenuItemsButton,
} from "../../../pages/Restaurants/MenuItems/components/AddMenuItemsButton"
import MenuItemsTabs from "../../../pages/Restaurants/MenuItems/components/MenuItemsTabs"
import Menu from "../../../pages/Restaurants/Menus"
import AddMenuButton, {
  AddCorporateMenusButton,
} from "../../../pages/Restaurants/Menus/AddMenusButton"
import CreateMenu from "../../../pages/Restaurants/Menus/CreateMenu"
import EditMenuContainer, {
  EditMenuContainerLocation,
} from "../../../pages/Restaurants/Menus/EditMenu/EditMenuContainer"
import SelectSortBy from "../../../pages/Restaurants/Menus/components/SelectSortBy"
import Modifiers from "../../../pages/Restaurants/Modifiers"
import AddModifiersButton, {
  AddCorporateModifiersButton,
} from "../../../pages/Restaurants/Modifiers/AddModifiersButton"
import CreateModifier from "../../../pages/Restaurants/Modifiers/CreateModifier"
import {
  EditModifierContainer,
  EditModifierContainerLocation,
} from "../../../pages/Restaurants/Modifiers/EditModifier/EditModifierContainer"
import Orders from "../../../pages/Restaurants/Orders"
import OrderDetail from "../../../pages/Restaurants/Orders/OrderDetail"
import Reports from "../../../pages/Restaurants/Reports"
import { TimeEntries } from "../../../pages/Restaurants/TimeEntries"
import AddLocationButton from "../../../pages/Settings/Locations/CreateLocation/AddLocationButton"
import EditRestaurant from "../../../pages/Settings/Restaurant/Home/EditRestaurant"
import { withHeader } from "../../../ui/Header"
import MainLayout from "../../../ui/Layouts/MainLayout"
import AppSwitch from "../AppSwitch"
import paths from "../paths"
import { useFlags } from "launchdarkly-react-client-sdk"
import React from "react"
import { useIntl } from "react-intl"
import { Route } from "react-router"

const { restaurants } = paths

export const RestaurantRoutes: React.FC = () => {
  const { combos } = useFlags()

  const intl = useIntl()

  return (
    <MainLayout>
      <AppSwitch>
        <Route
          exact
          path={restaurants.locations.ordersByLocation()}
          component={Orders}
        />
        <Route
          exact
          path={restaurants.locations.cateringByLocation()}
          component={Catering}
        />
        <Route
          exact
          path={restaurants.locations.cateringByLocationDetail()}
          component={CateringDetail}
        />
        <Route
          exact
          path={restaurants.locations.customersByLocation()}
          component={Customers}
        />
        <Route
          exact
          path={restaurants.locations.reportsByLocation()}
          component={Reports}
        />
        <Route
          exact
          path={restaurants.locations.customerDetailByLocation()}
          component={CustomerDetail}
        />
        <Route
          exact
          path={restaurants.locations.menusByLocation()}
          component={withHeader(<Menu />, {
            title: intl.formatMessage({
              id: "restaurants.locations.menus.title",
              defaultMessage: "Categories",
            }),
            actions: AddMenuButton,
            search: true,
          })}
        />
        <Route
          exact
          path={restaurants.locations.createMenu()}
          render={() => <CreateMenu isCorporate={false} />}
        />
        <Route
          exact
          path={restaurants.locations.editMenuByLocation()}
          component={EditMenuContainerLocation}
        />
        <Route
          exact
          path={restaurants.locations.itemsByLocation()}
          component={withHeader(<LocationMenuItems />, {
            title: intl.formatMessage({
              id: "restaurants.locations.items.title",
              defaultMessage: "Items",
            }),
            actions: AddLocationMenuItemsButton,
            search: true,
            ...(combos && { extra: MenuItemsTabs }),
          })}
        />
        <Route
          exact
          path={restaurants.locations.editItemByLocation()}
          component={EditCorporateItemContainer}
        />
        <Route
          exact
          path={restaurants.locations.editComboByLocation()}
          component={EditCorporateComboContainer}
        />
        <Route
          exact
          path={restaurants.locations.payrollByLocation()}
          component={TimeEntries}
        />
        {/* TODO: move this to a separated restaurant and locations scope */}
        {/* <RestrictedRoutes> */}
        <Route exact path={restaurants.create} component={SwipeableSteps} />
        <Route
          exact
          path={restaurants.locations.modifiersByLocation()}
          component={withHeader(<Modifiers />, {
            title: intl.formatMessage({
              id: "restaurants.modifiers.group.title",
              defaultMessage: "Modifiers",
            }),
            actions: AddModifiersButton,
            search: true,
          })}
        />
        <Route
          exact
          path={restaurants.locations.createModifiersByLocation()}
          render={() => <CreateModifier isCorporate={false} />}
        />
        <Route
          exact
          path={restaurants.locations.editModifiersByLocation()}
          component={EditModifierContainerLocation}
        />
        <Route
          exact
          path={restaurants.locations.createProductByLocation()}
          render={() => <CreateItem isCorporate={false} />}
        />
        <Route
          exact
          path={restaurants.locations.createComboByLocation()}
          render={() => <CreateCombo isCorporate={false} />}
        />

        <Route
          exact
          path={restaurants.root}
          component={withHeader(<EditRestaurant />, {
            title: intl.formatMessage({
              id: "restaurants.home.title",
              defaultMessage: "Home",
            }),
            actions: AddLocationButton,
          })}
        />

        <Route
          exact
          path={restaurants.items}
          component={withHeader(<MenuItems />, {
            title: intl.formatMessage({
              id: "restaurants.items.title",
              defaultMessage: "Items",
            }),
            search: true,
            actions: AddMenuItemsButton,
            ...(combos && { extra: MenuItemsTabs }),
          })}
        />
        <Route
          exact
          path={restaurants.modifiers}
          component={withHeader(<Modifiers isCorporate />, {
            title: intl.formatMessage({
              id: "restaurants.modifiers.group.title",
              defaultMessage: "Modifiers",
            }),
            actions: AddCorporateModifiersButton,
            search: true,
          })}
        />
        <Route
          exact
          path={restaurants.createModifiers}
          component={CreateModifier}
        />
        <Route
          exact
          path={restaurants.editModifier()}
          component={EditModifierContainer}
        />
        <Route exact path={restaurants.createProduct} component={CreateItem} />
        <Route
          exact
          path={restaurants.editProduct()}
          component={EditItemContainer}
        />
        <Route exact path={restaurants.createCombo} component={CreateCombo} />
        <Route
          exact
          path={restaurants.editCombo()}
          component={EditComboContainer}
        />
        <Route
          exact
          path={restaurants.menu}
          component={withHeader(<Menu isCorporate />, {
            title: intl.formatMessage({
              id: "restaurants.menus.title",
              defaultMessage: "Categories",
            }),
            actions: AddCorporateMenusButton,
            search: true,
            sort: SelectSortBy,
          })}
        />
        <Route exact path={restaurants.discounts} component={Discounts} />
        <Route exact path={restaurants.createMenu} component={CreateMenu} />
        <Route
          exact
          path={restaurants.editMenu()}
          component={EditMenuContainer}
        />
        <Route exact path={restaurants.orders} component={Orders} />
        <Route exact path={restaurants.catering} component={Catering} />

        <Route exact path={restaurants.customers} component={Customers} />
        {/* TODO: remove  route below when cleaning apEnableReports flag (also from paths)  */}
        <Route exact path={restaurants.sales} component={LegacyReports} />
        <Route exact path={restaurants.reports} component={Reports} />
        <Route
          exact
          path={restaurants.createDiscounts}
          component={CreateDiscount}
        />
        <Route
          exact
          path={restaurants.editDiscounts()}
          component={EditDiscount}
        />
        <Route exact path={restaurants.orderDetail()} component={OrderDetail} />
        <Route
          exact
          path={restaurants.cateringDetail()}
          component={CateringDetail}
        />
        <Route
          exact
          path={restaurants.customerDetail()}
          component={CustomerDetail}
        />
        {/* </RestrictedRoutes> */}
      </AppSwitch>
    </MainLayout>
  )
}
