import Container from "../../../../../../../ui/Container"
import Icon from "../../../../../../../ui/Icon"
import { InputNumber } from "../../../../../../../ui/Inputs"
import Select, {
  OptionSelect,
  SelectOptionWithIcon,
} from "../../../../../../../ui/Select"
import Tooltip from "../../../../../../../ui/Tooltip"
import Text from "../../../../../../../ui/Typography/Text"
import { notification } from "../../../../../../../ui/notification/notification"
import OwnershipTag from "../../../../../components/OwnershipTag"
import SelectOptionExample from "../../../components/SelectOptionExample"
import type { IItemForm } from "../../../interfaces/hookforms.interfaces"
import { FieldTypeEnumItem } from "../../../interfaces/hookforms.interfaces"
import { modifierGroupOptions } from "../../../utils/modifier-group-select.options"
import get from "lodash/get"
import React, { useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type CollapseModifierGroupHeaderProps = {
  variantIndex: number
  modifierGroupIndex: number
}

export const CollapseModifierGroupHeader: React.FC<CollapseModifierGroupHeaderProps> =
  ({ variantIndex, modifierGroupIndex }) => {
    const intl = useIntl()
    const [selectExampleOption, setSelectExampleOption] = useState<string>()

    const {
      control,
      setValue,
      resetField,
      trigger,
      formState: { errors },
    } = useFormContext<IItemForm>()

    const [selectedType, isCorporateItem, modifiers] = useWatch({
      name: [
        `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.fieldType`,
        "isMain",
        `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers`,
      ],
      control: control,
    })

    const radioOptionSelected = selectedType === FieldTypeEnumItem.RADIO
    const modifierGroupError = get(
      errors,
      `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}`
    )

    const onMouseEnterSelectOption = (value: string) => {
      setSelectExampleOption(value)
    }

    const onMouseLeaveSelectOption = () => {
      setSelectExampleOption(undefined)
    }

    const onChangeSelectType = (value: string) => {
      const errorInMaxOrMin = errors?.variants?.some?.((variantError) =>
        variantError?.variantModifierGroups?.some?.(
          (modifierGroup) =>
            modifierGroup?.maxSelectedOptions ||
            modifierGroup?.minSelectedOptions
        )
      )

      if (value === FieldTypeEnumItem.RADIO || errorInMaxOrMin) {
        setValue(
          `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.isRequired`,
          false,
          {
            shouldValidate: true,
          }
        )
        modifiers?.forEach((modifier, index) => {
          if (modifier.isIncluded) {
            setValue(
              `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.variantModifiers.${index}.isIncluded`,
              false
            )
            notification({
              key: "select-type-change-warning",
              type: "warning",
              title: intl.formatMessage({
                id: "restaurants.menu.items.forms.variants.form.select.type.change.warning.title",
                defaultMessage: "Included modifiers unchecked",
              }),
              description: intl.formatMessage({
                id: "restaurants.menu.items.forms.variants.form.select.type.change.warning.description",
                defaultMessage: "Please reselect if needed.",
              }),
            })
          }
        })

        resetField(
          `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.minSelectedOptions`,
          {
            defaultValue: 0,
          }
        )

        resetField(
          `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.maxSelectedOptions`,
          {
            defaultValue: 1,
          }
        )
      }
    }

    const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
    }

    return (
      <Container
        cursor="default"
        display="flex"
        gap="20px"
        justifyContent="space-between"
        width="100%"
        onClick={stopPropagation}
      >
        <Container display="flex" alignItems="center" gap="11px">
          <Controller
            control={control}
            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.modifierGroup.name`}
            render={({ field }) => (
              <Text weight="bold" color="Primary5">
                {field.value}
              </Text>
            )}
          />
        </Container>
        <Container display="flex" alignItems="center" gap="8px">
          {!isCorporateItem && (
            <Controller
              control={control}
              name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.modifierGroup.isMain`}
              render={({ field }) => <OwnershipTag isMain={field.value} />}
            />
          )}
          <Controller
            control={control}
            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.fieldType`}
            render={({ field }) => (
              <StyledTooltip
                placement="right"
                title={
                  <SelectOptionExample selectOption={selectExampleOption} />
                }
              >
                <Container>
                  <Select
                    id={`variants-${variantIndex}-variantModifierGroups-${modifierGroupIndex}-fieldType`}
                    value={field.value}
                    hasError={!!get(modifierGroupError, "fieldType")}
                    onChange={(value) => {
                      onChangeSelectType(value)
                      field.onChange(value)
                    }}
                    width="160px"
                    placeholder={intl.formatMessage({
                      id: "restaurants.menu.items.forms.modifier.group.form.select.type.placeholder",
                      defaultMessage: "Select type",
                    })}
                  >
                    {Object.keys(FieldTypeEnumItem).map((value) => {
                      const { id, icon, defaultMessage } =
                        modifierGroupOptions[value]
                      const selectLabel = intl.formatMessage({
                        id,
                        defaultMessage,
                      })

                      return (
                        <OptionSelect
                          key={value}
                          value={value}
                          label={selectLabel}
                        >
                          <Container
                            width="100%"
                            onMouseEnter={() => onMouseEnterSelectOption(value)}
                            onMouseLeave={onMouseLeaveSelectOption}
                          >
                            <SelectOptionWithIcon>
                              <Icon
                                size={18}
                                remixiconClass={icon}
                                color="Neutral9"
                              />
                              {selectLabel}
                            </SelectOptionWithIcon>
                          </Container>
                        </OptionSelect>
                      )
                    })}
                  </Select>
                </Container>
              </StyledTooltip>
            )}
          />
          <Controller
            control={control}
            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.minSelectedOptions`}
            render={({ field }) => (
              <InputNumber
                {...field}
                onChange={(e) => {
                  const minValueToBeRequired = 1
                  field.onChange(e)

                  if (!!e && e >= minValueToBeRequired) {
                    return setValue(
                      `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.isRequired`,
                      true
                    )
                  }
                  setValue(
                    `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.isRequired`,
                    false
                  )
                }}
                maxWidth="85px"
                placeholder="0"
                errorMessage={get(
                  modifierGroupError,
                  "minSelectedOptions.message"
                )}
                hasError={!!get(modifierGroupError, "minSelectedOptions")}
                prefix={intl.formatMessage({
                  id: "restaurants.menu.items.forms.modifier.group.form.input.min.prefix",
                  defaultMessage: "Min",
                })}
              />
            )}
          />
          <Controller
            control={control}
            name={`variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.maxSelectedOptions`}
            render={({ field }) => (
              <InputNumber
                {...field}
                maxWidth="85px"
                onChange={(value) => {
                  field.onChange(value)
                  trigger(
                    `variants.${variantIndex}.variantModifierGroups.${modifierGroupIndex}.minSelectedOptions`
                  )
                }}
                disabled={radioOptionSelected}
                errorMessage={get(
                  modifierGroupError,
                  "maxSelectedOptions.message"
                )}
                hasError={!!get(modifierGroupError, "maxSelectedOptions")}
                prefix={intl.formatMessage({
                  id: "restaurants.menu.items.forms.modifier.group.form.input.max.prefix",
                  defaultMessage: "Max",
                })}
              />
            )}
          />
        </Container>
      </Container>
    )
  }

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-arrow {
    visibility: hidden;
  }

  .ant-tooltip-inner {
    min-width: 0px;
    min-height: 0px;
    padding: 0px;
  }
`
