import type { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"
import type { GetOrdersByRestaurantQueryVariables } from "../../GraphQL/getOrdersByRestaurant.generated"
import type { GetOrderDetailsQueryVariables } from "../../OrderDetail/GraphQL/getOrderDetails.generated"
import { updateOrder, updateOrderDetail } from "./orders-cache-methods"

const updateOrdersToCache = ({
  data,
  variables,
}: {
  data: { uuid: string; status: OrderStatusEnum }
  variables: GetOrdersByRestaurantQueryVariables
}) => {
  updateOrder(data, variables)
}

const updateOrderDetailToCache = ({
  data,
  variables,
}: {
  data: { uuid: string; status: OrderStatusEnum }
  variables: GetOrderDetailsQueryVariables
}) => {
  updateOrderDetail(data, variables)
}

export { updateOrdersToCache, updateOrderDetailToCache }
