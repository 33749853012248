import {
  ItemProgressStatusEnum,
  SortingByMenuEnum,
} from "../../../../../shared/graphql/generated/types"
import { useModal } from "../../../../../shared/hooks/useModal"
import paths from "../../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import { formNotification } from "../../../../../ui/notification"
import ItemModalHeader from "../../../components/ModalFullDetailHeader"
import DropdownItem from "../components/DropdownItem"
import PublishItemModal from "../components/modals/PublishItemModal"
import { ItemFormResolver } from "../forms/ItemForm.yup"
import ItemForm from "../forms/ItemInfoForm"
import VariantsForm from "../forms/VariantsForm"
import { useItems } from "../hooks/useItems"
import type { IItemForm } from "../interfaces/hookforms.interfaces"
import {
  MAX_GREATER_THAN_MODIFIERS,
  MAX_LOWER_THAN_INCLUDED_MODIFIERS,
} from "../utils/constants"
import { formDataToInput } from "../utils/formDataToInput"
import { ItemFormError } from "../utils/notifications.titles"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type CreateItemProps = {
  isCorporate?: boolean
}

export const CreateItem: React.FC<CreateItemProps> = ({
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { saveItem, itemSaved } = useItems({})
  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()
  const [fieldsWithError, setFieldsWithError] = useState<Array<string>>([])

  const [savedItemName, setSavedItemName] = useState<string>(() =>
    intl.formatMessage({
      id: "restaurants.menu.items.create.item.title",
      defaultMessage: "New Menu Item",
    })
  )

  const breadcrumbPath = isCorporate
    ? breadcrumbs.createItem
    : breadcrumbs.createCorporateItem

  const { restaurants } = paths

  const formMethods = useForm<IItemForm>({
    mode: "all",
    resolver: ItemFormResolver,
    defaultValues: {
      name: "",
      description: "",
      status: ItemProgressStatusEnum.DRAFT,
      image: undefined,
      menus: [],
      hasInitialData: false,
      isPublished: false,
      isSoldOut: false,
      isMain: isCorporate,
      serves: undefined,
      variants: [
        {
          name: "",
          isDefault: true,
          price: undefined,
          calories: undefined,
          sku: undefined,
          image: undefined,
          isSoldOut: false,
          sortModifierGroupsBy: SortingByMenuEnum.NAME_ASC,
        },
      ],
      closeForm: false,
      sortVariantsBy: SortingByMenuEnum.NAME_ASC,
    },
  })

  const {
    control,
    setFocus,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { isDirty, errors },
  } = formMethods

  const [lastSavedAt, lastPublishedAt, hasSnapshot, itemUUID] = useWatch({
    name: ["lastSavedAt", "lastPublishedAt", "hasSnapshot", "uuid"],
    control: control,
  })

  const [imagesUploading, setImagesUploading] = useState(0)
  const notUploadingImages = imagesUploading === 0
  const enabledPreview = !!itemUUID && !!lastSavedAt

  const onSave = async () => {
    const formData = getValues()

    clearErrors("variants")
    const isValidName = await trigger("name")

    if (!isValidName) {
      const nameError = errors?.name
        ? { message: intl.formatMessage(ItemFormError.NAME) }
        : undefined

      return formNotification({
        key: "lsm.create.item.save",
        header: intl.formatMessage({
          id: "restaurants.menu.items.create.item.save.notification.error.header",
          defaultMessage: "You can’t save this menu item",
        }),
        title: intl.formatMessage({
          id: "restaurants.menu.items.create.item.save.notification.error.title",
          defaultMessage: "To save a menu item you need to:",
        }),
        type: "error",
        error: { ...errors, ...(nameError && { name: nameError }) },
      })
    }

    await saveItem(
      formDataToInput(formData),
      (result) => {
        setSavedItemName(result.saveItem.name)
        setValue("uuid", result.saveItem.uuid)
        setValue("lastSavedAt", new Date())
        setValue("hasSnapshot", true)

        reset({}, { keepValues: true })
      },
      showGraphqlErrors
    )
  }

  const onPublish = () => {
    handleSubmit(
      () => {
        showPublishConfirmation()
      },
      (error) => {
        const variantErrors = Object.assign({}, error.variants)
        const errorArray = Object.keys(variantErrors) ?? []
        setFieldsWithError(errorArray)

        const modifierGroupIndex = Object.keys(
          Object.assign(
            {},
            variantErrors[Number(errorArray[0])]?.variantModifierGroups
          )
        )[0]

        if (modifierGroupIndex) {
          const selectID = `#variants-${errorArray[0]}-variantModifierGroups-${modifierGroupIndex}-fieldType`
          const select = document.querySelector(selectID)

          setTimeout(() => {
            select?.scrollIntoView({ behavior: "smooth" })
          }, 500)
        }

        const nameError = error?.name
          ? { message: intl.formatMessage(ItemFormError.NAME) }
          : undefined

        const variantError = error?.variants
          ? { message: intl.formatMessage(ItemFormError.VARIANT) }
          : undefined

        let includedModifierError: { message: string } | undefined
        let maxModifiersError: { message: string } | undefined

        error?.variants?.forEach?.((variant) =>
          variant?.variantModifierGroups?.forEach?.((modifierGroup) => {
            if (
              modifierGroup?.maxSelectedOptions?.type ===
              MAX_LOWER_THAN_INCLUDED_MODIFIERS
            ) {
              includedModifierError = {
                message: intl.formatMessage(ItemFormError.INCLUDED_MODIFIERS),
              }
            }
            if (
              modifierGroup?.maxSelectedOptions?.type ===
              MAX_GREATER_THAN_MODIFIERS
            ) {
              maxModifiersError = {
                message: intl.formatMessage(ItemFormError.MAX_MODIFIERS),
              }
            }
          })
        )

        formNotification({
          key: "lsm.create.item.publish",
          header: intl.formatMessage({
            id: "restaurants.menu.items.create.item.publish.notification.error.header",
            defaultMessage: "You can’t publish this menu item",
          }),
          title: intl.formatMessage({
            id: "restaurants.menu.items.create.item.publish.notification.error.title",
            defaultMessage: "To publish a menu item you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(nameError && { name: nameError }),
            ...(!includedModifierError &&
              !maxModifiersError &&
              variantError && { variants: variantError }),
            ...(maxModifiersError && {
              maxModifiersError: maxModifiersError,
            }),
            ...(includedModifierError && {
              maxSelectedOptions: includedModifierError,
            }),
          },
        })
      }
    )()
  }

  const publishCallback = () => {
    dismissPublishConfirmation()

    onClose()
  }

  const onClose = () => {
    setValue("closeForm", true)
    reset({}, { keepDirty: false, keepValues: true })
  }

  const getItemData = () => {
    if (isDirty) {
      const data = formDataToInput(getValues())

      return data
    }

    return undefined
  }

  useEffect(() => {
    setFocus("name")
  }, [setFocus])

  return (
    <ModalFull
      goBackPath={restaurants.items}
      headerColor="Neutral2"
      title={
        <ItemModalHeader
          onSave={onSave}
          onPublish={onPublish}
          lastSavedAt={lastSavedAt}
          lastPublishedAt={lastPublishedAt}
          enablePreview={enabledPreview}
          enablePublish={(hasSnapshot || isDirty) && notUploadingImages}
          enableSave={isDirty && notUploadingImages}
          loadingPublish={false}
          loadingSave={itemSaved.loading}
          actions={
            <DropdownItem
              onOk={onClose}
              isCorporate={isCorporate}
              itemUUID={itemUUID}
              actions={["DELETE_DRAFT", "UNPUBLISH", "DISABLE_UNPUBLISH"]}
            />
          }
        >
          <Breadcrumb breadcrumbs={breadcrumbPath} pageName={savedItemName} />
        </ItemModalHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <ItemForm setImagesUploading={setImagesUploading} />
        <VariantsForm
          isCorporate={isCorporate}
          fieldsWithError={fieldsWithError}
          setImagesUploading={setImagesUploading}
        />
      </FormProvider>
      {publishModalVisible && (
        <PublishItemModal
          itemUUID={itemUUID}
          itemData={getItemData()}
          onOk={publishCallback}
          onCancel={dismissPublishConfirmation}
        />
      )}
    </ModalFull>
  )
}
