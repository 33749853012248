import { SortingByMenuEnum } from "../../../../shared/graphql/generated/types"
import { SortingOptionsType } from "../../../../shared/utils/helpers/sortArrayByMethod"
import Container from "../../../../ui/Container"
import Icon from "../../../../ui/Icon"
import Select from "../../../../ui/Select"
import Text from "../../../../ui/Typography/Text"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type GenericSortByProps = {
  onChangeSorting: (selected: SortingByMenuEnum) => void
  value: SortingByMenuEnum
  sortingOptions: Array<SortingOptionsType>
}

const GenericSortBy: React.FC<GenericSortByProps> = (props) => {
  const { onChangeSorting, value, sortingOptions } = props
  const intl = useIntl()

  return (
    <SelectSortContainer>
      <Text size="m" textAlign="center">
        {intl.formatMessage({
          id: "generic.sort.by",
          defaultMessage: "Sort by",
        })}
      </Text>
      <Select
        value={value}
        optionLabelProp="label"
        options={sortingOptions.map((option) => ({
          value: option.value,
          label: intl.formatMessage(option.label),
          extra: (
            <Icon remixiconClass="ri-check-line" size={16} color={"Neutral8"} />
          ),
        }))}
        onChange={onChangeSorting}
        width="128px"
      />
    </SelectSortContainer>
  )
}

export default GenericSortBy

const SelectSortContainer = styled(Container)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  min-width: 192px;
  margin-left: 32px;

  .ant-select-item-option i {
    opacity: 0;
  }

  .ant-select-item-option-selected {
    span {
      font-weight: 900;
    }

    i {
      opacity: 1;
    }
  }
`
