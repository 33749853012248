export const menuItemsOptions = [
  {
    labelId: "restaurants.menu.items.tabs.options.combos",
    label: "Items",
    value: "items",
  },
  {
    labelId: "restaurants.menu.items.tabs.options.combos",
    label: "Combos",
    value: "combos",
  },
]
