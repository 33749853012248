import imageDefault from "../../../../../../../../../shared/assets/icons/image-2-line.svg"
import type { RItemVariant } from "../../../../../../../../../shared/graphql/generated/types"
import Button from "../../../../../../../../../ui/Button"
import Container from "../../../../../../../../../ui/Container"
import Img from "../../../../../../../../../ui/Img"
import { InputNumber } from "../../../../../../../../../ui/Inputs"
import Text from "../../../../../../../../../ui/Typography/Text"
import SoldOutIcon from "../../../../../../../components/SoldOutIcon"
import type { ICorporateItemForm } from "../../../../../interfaces/hookforms.interfaces"
import get from "lodash/get"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

type VariantDetailCollapseHeaderProps = {
  variant: RItemVariant
  variantIndex: number
}

export const VariantDetailCollapseHeader: React.FC<VariantDetailCollapseHeaderProps> =
  ({ variant, variantIndex }) => {
    const intl = useIntl()

    const {
      control,
      formState: { errors },
    } = useFormContext<ICorporateItemForm>()

    const caloriesPrefix = intl.formatMessage({
      id: "generic.prefix.calories",
      defaultMessage: "Cal",
    })
    const skuPrefix = intl.formatMessage({
      id: "generic.prefix.sku",
      defaultMessage: "SKU",
    })
    const variantErrors = get(errors, `variants.${variantIndex}`)

    const defaultTitle = intl.formatMessage({
      id: "restaurants.menu.items.corporate.item.detail.default.title",
      defaultMessage: "Default",
    })

    const onClickDropdownContainer = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation()
    }

    return (
      <Container display="flex" width="100%" justifyContent="space-between">
        <Container display="flex" gap="11px" alignItems="center">
          <Img
            width={24}
            height={24}
            src={variant.attachment?.signedUrl}
            placeholderImage={imageDefault}
          />
          <Text color="Neutral6">{variant?.name}</Text>
        </Container>
        <Container
          display="flex"
          gap="12px"
          alignItems="center"
          onClick={onClickDropdownContainer}
        >
          {variant.isDefault && <Text color="Neutral8">{defaultTitle}</Text>}
          <Text color="Neutral6">{`${caloriesPrefix} ${
            variant?.calories || 0
          }`}</Text>

          <Controller
            control={control}
            name={`variants.${variantIndex}.price`}
            render={({ field }) => (
              <InputNumber
                {...field}
                maxWidth="96px"
                hasError={!!get(variantErrors, "price")}
                errorMessage={get(variantErrors, "price.message")}
                placeholder="0.00"
                step={0.01}
                precision={2}
                prefix={intl.formatMessage({
                  id: "generic.symbol.currency",
                  defaultMessage: "$",
                })}
              />
            )}
          />

          <Text color="Neutral6">{`${skuPrefix} ${
            variant?.sku || "0000"
          }`}</Text>

          <Controller
            control={control}
            name={`variants.${variantIndex}.isVisible`}
            render={({ field }) => (
              <Button
                type="button"
                hierarchy="tertiary"
                onClick={() => field.onChange(!field.value)}
                leadingIcon={field.value ? "ri-eye-line" : "ri-eye-off-line"}
                shape="square"
              />
            )}
          />
          <Controller
            control={control}
            name={`variants.${variantIndex}.isSoldOut`}
            render={({ field }) => {
              const isSoldOut = field.value ?? false

              return (
                <Button
                  type="button"
                  hierarchy="tertiary"
                  onClick={() => field.onChange(!field.value)}
                  shape="square"
                >
                  <SoldOutIcon isSoldOut={isSoldOut} color="Primary5" />
                </Button>
              )
            }}
          />
        </Container>
      </Container>
    )
  }
