import type { RItemDetail } from "../../../../../../../shared/graphql/generated/types"
import Collapse, { CollapsePanel } from "../../../../../../../ui/Collapse"
import Spacer from "../../../../../../../ui/Spacer"
import Text from "../../../../../../../ui/Typography/Text"
import ModifierGroupDetailForm from "../ModifierGroupDetailForm"
import VariantDetailCollapseHeader from "./collapse/VariantDetailCollapseHeader"
import React from "react"
import { useIntl } from "react-intl"

type VariantDetailFormProps = {
  item: RItemDetail
}

export const VariantDetailForm: React.FC<VariantDetailFormProps> = ({
  item,
}) => {
  const intl = useIntl()

  return (
    <>
      <Text>
        {intl.formatMessage({
          id: "restaurants.menu.items.forms.corporate.view.item.detail.variants.title",
          defaultMessage: "Variants",
        })}
      </Text>
      <Spacer size={24} />

      {item.variants.map((variant, variantIndex) => {
        const expandable = !!variant.variantModifierGroups?.length
        const collapsible = expandable ? undefined : "disabled"

        return (
          <Collapse
            key={variant.uuid}
            contentBorderColor="Neutral4"
            classId="menu-items-variants-form-collapse"
            removeHeaderBottomBorder
            contentBordered
          >
            <CollapsePanel
              key="variants-form-panel"
              showArrow={expandable}
              collapsible={collapsible}
              header={
                <VariantDetailCollapseHeader
                  variant={variant}
                  variantIndex={variantIndex}
                />
              }
            >
              {variant.variantModifierGroups.map(
                (variantModifierGroup, variantModifierGroupIndex) => (
                  <ModifierGroupDetailForm
                    key={variantModifierGroup.uuid}
                    variantModifierGroup={variantModifierGroup}
                    variantIndex={variantIndex}
                    variantModifierGroupIndex={variantModifierGroupIndex}
                  />
                )
              )}
            </CollapsePanel>
          </Collapse>
        )
      })}
    </>
  )
}
