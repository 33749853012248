import { ReactComponent as AvailableSvg } from "../../../../shared/assets/icons/custom-available.svg"
import { ReactComponent as SoldOutSvg } from "../../../../shared/assets/icons/custom-sold-out.svg"
import SvgIcon from "../../../../ui/SvgIcon"
import React from "react"
import type { IBaseThemeColors } from "styled-components"

type SoldOutIconProps = {
  isSoldOut: boolean
  color?: keyof IBaseThemeColors
  onClick?: () => void
  cursor?: string
}

export const SoldOutIcon: React.FC<SoldOutIconProps> = ({
  color = "Neutral5",
  isSoldOut,
  onClick,
  cursor = "auto",
}) => {
  return (
    <SvgIcon
      color={color}
      svg={
        isSoldOut ? (
          <SoldOutSvg
            data-testid="sold-out-icon"
            onClick={onClick}
            width={21}
            height={21}
            cursor={cursor}
          />
        ) : (
          <AvailableSvg
            data-testid="available-icon"
            onClick={onClick}
            width={21}
            height={18}
            cursor={cursor}
          />
        )
      }
    />
  )
}
