import { LabelInformation } from "../../../../../../../ui/LabelInformation"
import { RefundCateringModal } from "../../../Modals/RefundCateringModal"
import { Button, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useIntl } from "react-intl"

export const RefundCatering = () => {
  const intl = useIntl()

  const [openedRefundModal, { open, close }] = useDisclosure()

  return (
    <Stack spacing={16}>
      <LabelInformation
        label={intl.formatMessage({
          id: "restaurants.catering.danger.zone.refund.label",
          defaultMessage: "Refund order",
        })}
        labelWeight={600}
        description={intl.formatMessage(
          {
            id: "restaurants.catering.danger.zone.refund.description",
            defaultMessage:
              "Refunding an order reimburses the customer fully. Once processed, it cannot be reversed. {br} Ensure your decision is final.",
          },
          {
            br: <br />,
          }
        )}
        descriptionSize="sm"
      />
      <Button variant="outline" color="red.6" onClick={open}>
        {intl.formatMessage({
          id: "restaurants.catering.danger.zone.refund.button",
          defaultMessage: "Refund order",
        })}
      </Button>
      <RefundCateringModal opened={openedRefundModal} onClose={close} />
    </Stack>
  )
}
