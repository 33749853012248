import ScrollSkeleton from "../ScrollSkeleton"
import { Skeleton, Stack } from "@mantine/core"

export const TableSkeleton = () => {
  return (
    <Stack spacing={10}>
      <Skeleton w="100%" height={30} />
      <ScrollSkeleton />
    </Stack>
  )
}
