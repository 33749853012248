import { SortingByMenuEnum } from "../../../../../../shared/graphql/generated/types"
import { reorderArray } from "../../../../../../shared/utils/helpers/reorderArray"
import {
  sortArrayAlphabetically,
  sortingItemComponentsOptions,
} from "../../../../../../shared/utils/helpers/sortArrayByMethod"
import AddButton from "../../../../../../ui/AddButton"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import DraggableList from "../../../../../../ui/DraggableList"
import Spacer from "../../../../../../ui/Spacer"
import Text from "../../../../../../ui/Typography/Text"
import GenericSortBy from "../../../../components/GenericSortBy/GenericSortBy"
import type {
  IItemForm,
  VariantType,
} from "../../interfaces/hookforms.interfaces"
import ModifierGroupsForm from "../ModifierGroupsForm"
import CollapseVariantHeader from "./CollapseVariantHeader"
import DropdownVariant from "./DropdownVariant"
import React, { useEffect, useState } from "react"
import { DropResult } from "react-beautiful-dnd"
import { useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface VariantFormProps {
  fieldsWithError: Array<string>
  isCorporate?: boolean
  setImagesUploading: React.Dispatch<React.SetStateAction<number>>
}

export const VariantsForm = ({
  fieldsWithError,
  setImagesUploading,
  isCorporate = true,
}: VariantFormProps) => {
  const intl = useIntl()
  const { control, setValue, clearErrors } = useFormContext<IItemForm>()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "variants",
  })
  const [activeCollapsePanels, setActiveCollapsePanels] = useState<
    Array<string> | string
  >([])

  const [defaultVariantId, variants, sortVariantsBy] = useWatch({
    name: ["defaultVariantId", "variants", "sortVariantsBy"],
    control: control,
  })

  const disableCustomSorting = sortVariantsBy !== SortingByMenuEnum.CUSTOM

  const onAddVariantClick = () => {
    append({
      name: "",
      sku: "",
      calories: null,
      price: null,
      isDefault: false,
      tempId: Date.now().toString(),
      sortModifierGroupsBy: SortingByMenuEnum.NAME_ASC,
    })
  }

  const appendVariant = (variant: VariantType) => {
    append(variant)
  }

  const disableDropdown = (variantId: string, variantIndex: number) => {
    if (variantIndex === 0 && !defaultVariantId) return true

    return defaultVariantId === variantId
  }

  const deleteVariant = (variantId: string, variantIndex: number) => {
    const index = fields.findIndex((item) => {
      return item.uuid === variantId || item.id === variantId
    })
    const variant = fields[variantIndex]

    if (!variant.uuid || !variant.name) return remove(variantIndex)

    if (index < 0 || index !== variantIndex) return

    update(variantIndex, { ...variant, deletedAt: new Date() })
  }

  const onChangeSorting = (selectedValue: SortingByMenuEnum) => {
    setValue("sortVariantsBy", selectedValue, { shouldDirty: true })
    if (selectedValue === SortingByMenuEnum.CUSTOM) {
      return variants
    }

    const sortedArray = sortArrayAlphabetically({
      array: variants,
      key: "name",
      ascending: selectedValue === SortingByMenuEnum.NAME_ASC,
    })

    setActiveCollapsePanels([])
    clearErrors("variants")
    setValue("variants", sortedArray)
  }

  const onDragEndHandler = (dropResult: DropResult) => {
    const reordered = reorderArray(
      variants,
      dropResult.source.index,
      dropResult.destination?.index ?? 0
    )

    if (!reordered) return

    setActiveCollapsePanels([])
    clearErrors("variants")
    setValue("variants", reordered, {
      shouldDirty: true,
    })
  }

  useEffect(() => {
    if (fieldsWithError.length > 0) {
      setActiveCollapsePanels((prev) => [
        ...new Set([...prev, ...fieldsWithError]),
      ])
    }
  }, [fieldsWithError])

  useEffect(() => {
    onChangeSorting(sortVariantsBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap="8px"
      >
        <Container style={{ marginRight: "auto" }}>
          <Text>
            {intl.formatMessage({
              id: "restaurants.menu.items.forms.variants.form.section.title",
              defaultMessage: "Variants",
            })}
          </Text>
        </Container>
        <GenericSortBy
          sortingOptions={sortingItemComponentsOptions}
          onChangeSorting={onChangeSorting}
          value={SortingByMenuEnum[sortVariantsBy]}
        />
        <AddButton onClick={onAddVariantClick} />
      </Container>
      <Spacer size={24} />
      <DraggableList
        key="variants"
        droppableId="variants"
        onDragEnd={onDragEndHandler}
        data={[...fields]}
        isDragDisabled={disableCustomSorting}
        renderContent={(variant, variantIndex) => {
          if (variant.deletedAt) return null

          const vrnt = variants[variantIndex]

          const hasData =
            !!vrnt?.uuid ||
            !!vrnt?.name ||
            !!vrnt?.price ||
            !!vrnt?.calories ||
            !!vrnt?.sku ||
            !!vrnt?.variantModifierGroups?.length

          return (
            <StyledCollapse
              key={`${variant.uuid}-${variant.tempId}-${variantIndex}`}
              contentBorderColor="Neutral4"
              classId="menu-items-variants-form-collapse"
              removeHeaderBottomBorder
              contentBordered
              activeKey={activeCollapsePanels}
              onChange={(newActives) => {
                setActiveCollapsePanels([...new Set(newActives)])
              }}
            >
              <CollapsePanel
                key={variantIndex}
                extra={
                  <Container
                    position="absolute"
                    right="-30px"
                    visibleOnHover
                    data-testid="variants-extra-actions"
                  >
                    <DropdownVariant
                      disable={disableDropdown(
                        variant.uuid ?? `${variantIndex}`,
                        variantIndex
                      )}
                      onOk={() =>
                        deleteVariant(
                          variant.uuid ?? `${variantIndex}`,
                          variantIndex
                        )
                      }
                      hasData={hasData}
                    />
                  </Container>
                }
                header={
                  <CollapseVariantHeader
                    key={`${variant.uuid}-${variantIndex}`}
                    appendVariant={appendVariant}
                    variantIndex={variantIndex}
                    variantId={variant.uuid ?? `${variantIndex}`}
                    isCorporate={isCorporate}
                    setImagesUploading={setImagesUploading}
                  />
                }
              >
                <ModifierGroupsForm variantIndex={variantIndex} />
              </CollapsePanel>
            </StyledCollapse>
          )
        }}
      />
      <Spacer size={60} />
    </>
  )
}

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    &:hover {
      .default-variant-action {
        visibility: visible;
      }
    }

    .default-variant-action {
      visibility: hidden;
    }
  }
`
