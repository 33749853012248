import { device } from "../../../../shared/breakpoints/responsive"
import { useOnboardingContext } from "../../../../shared/contexts/OnboardingProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import {
  AttachmentDirectoryEnum,
  AttachmentTypeEnum,
} from "../../../../shared/graphql/generated/types"
import type {
  ContentTypeEnum,
  FileExtensionEnum,
  TempAttachmentInput,
} from "../../../../shared/graphql/generated/types"
import type { ContextLogoType } from "../../../../shared/types"
import { putFileWithSignedUrl } from "../../../../shared/utils/api/client"
import { colors } from "../../../../styles/global/themes/mainTheme"
import Container from "../../../../ui/Container"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import InputLabel from "../../../../ui/InputLabel"
import Input from "../../../../ui/Inputs"
import Spacer from "../../../../ui/Spacer"
import Text from "../../../../ui/Typography/Text"
import UploadAvatar from "../../../../ui/UploadAvatar"
import ColorPicker from "../../../Settings/Restaurant/WhiteLabelApplication/ColorPicker"
import { useCreateTempRestaurantAttachmentMutation } from "../../GraphQL/CreateTempRestaurantAttachment.generated"
import { useUpsertRestaurantSnapshotMutation } from "../../GraphQL/UpsertRestaurantSnapshot.generated"
import { useGetUrlIdentifierLazyQuery } from "../../GraphQL/getUrlIdentifier.generated"
import StepperHandler from "../../components/StepperHandler"
import StepsFooter from "../../components/StepsFooter"
import { IndicatorsStateType } from "../../components/StepsFooter/config"
import { StepsType } from "../../interfaces"
import { OnboardingRestaurantResolver } from "./OnboardingRestaurantForm.yup"
import formatUrlIdentifier from "./utils/formatUrlIdentifier"
import type { RestaurantFormType } from "./utils/hookforms.interfaces"
import type { RcFile } from "antd/lib/upload"
import React, { useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type OnboardingRestaurantFormProps = {
  onNext: () => void
  stepFooter: StepsType
  indicators: IndicatorsStateType[]
}

const OnboardingRestaurantForm: React.FC<OnboardingRestaurantFormProps> = ({
  onNext,
  stepFooter,
  indicators,
}) => {
  const intl = useIntl()
  const [createTempAttachment] = useCreateTempRestaurantAttachmentMutation()
  const [loadingNext, setLoadingNext] = useState(false)

  const {
    updateOnboardingState,
    onboarding: {
      brandColor,
      name,
      urlIdentifier,
      currentStep,
      locationsQuantity,
      menuAttachments,
      location,
      notes,
      logos = [],
    },
    upsertLogo,
    addUuidToLogo,
  } = useOnboardingContext()

  const [upsertRestaurantSnapshot] = useUpsertRestaurantSnapshotMutation({
    onError: (e) => showGraphqlErrors(e),
  })

  const {
    state: {
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const squareLogo = logos?.find(
    (logo) => logo.type === AttachmentTypeEnum.LOGO
  )?.file

  const [getUrlIdentifier] = useGetUrlIdentifierLazyQuery()

  const defaultValues: RestaurantFormType = {
    brandColor: brandColor ? brandColor : colors.Primary5,
    name: name,
    url: urlIdentifier,
    squareLogo,
  }

  const restaurantFormMethods = useForm<RestaurantFormType>({
    resolver: OnboardingRestaurantResolver,
    mode: "all",
    defaultValues,
  })

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isDirty, dirtyFields, touchedFields },
    setError,
  } = restaurantFormMethods

  const nameWatcher = watch("name")
  const urlWatcher = watch("url")

  const squareLogoError = errors.squareLogo?.message

  const getLogosToUpload = () => {
    const squareLogoFromContext = logos.find(
      (logo) => logo.type === AttachmentTypeEnum.LOGO
    )
    const logosToUpload: Array<ContextLogoType> = []

    if (dirtyFields.squareLogo && squareLogoFromContext) {
      logosToUpload.push(squareLogoFromContext)
    }

    return logosToUpload
  }

  const uploadAttachments = async (logosToUpload: ContextLogoType[]) => {
    const result = await createTempAttachment({
      variables: {
        uuid: userUUID,
        input: {
          directory: AttachmentDirectoryEnum.RESTAURANT,
          attachments: logosToUpload.map(
            (logo) =>
              ({
                contentType: logo.contentType,
                ext: logo.ext,
                fileName: logo.fileName,
                type: logo.type,
              } as TempAttachmentInput)
          ),
        },
      },
    })
    const tempAttachments = result.data?.createTempRestaurantAttachment

    if (!tempAttachments?.length) {
      throw new Error(
        intl.formatMessage({
          id: "generic.error.images.server.response.message",
          defaultMessage:
            "Couldn't retrieve server data to upload images. Try again later.",
        })
      )
    }

    try {
      // NOTE: This uploads are done one after the other, because the bucket doesn't allow them to be done in parallel
      for (const tempAttachment of tempAttachments) {
        await putFileWithSignedUrl(
          tempAttachment?.signedUrl,
          logos.find((logo) => logo.type === tempAttachment.type)
            ?.file as RcFile
        )
        addUuidToLogo(
          tempAttachment.type as AttachmentTypeEnum,
          tempAttachment.uuid
        )
      }
    } catch {
      throw new Error(
        intl.formatMessage({
          id: "generic.error.image.message",
          defaultMessage: "Your image was unable to save.",
        })
      )
    }
  }

  const updateRestaurantSnapshot = async (data: RestaurantFormType) => {
    const restaurantFormData = getRestaurantData(data, logos, currentStep)

    const snapshotUpsert = await upsertRestaurantSnapshot({
      variables: {
        data: {
          snapshot: {
            ...restaurantFormData,
            ...(locationsQuantity && {
              locationsQuantity: locationsQuantity,
            }),
            ...(menuAttachments && { menuAttachments: menuAttachments }),
            ...(notes && { notes: notes }),
            ...(location && {
              location: {
                ...(location.address && { address: location.address }),
                ...(location.addressLine1 && {
                  addressLine1: location.addressLine1,
                }),
                ...(location.addressLine2 && {
                  addressLine2: location.addressLine2,
                }),
                ...(location.city && { city: location.city }),
                ...(location.latitude && { latitude: location.latitude }),
                ...(location.longitude && { longitude: location.longitude }),
                ...(location.name && { name: location.name }),
                ...(location.postalCode && {
                  postalCode: location.postalCode,
                }),
                ...(location.postalCode && { state: location.postalCode }),
              },
            }),
          },
          userUUID,
        },
      },
    })

    return !snapshotUpsert.errors
  }

  const getRestaurantData = (
    data: RestaurantFormType,
    allLogos: ContextLogoType[],
    step: number | undefined
  ) => {
    const logosArray = allLogos?.map((logo) => {
      if (!logo.uuid) {
        throw new Error(
          intl.formatMessage({
            id: "generic.error.try.again.message",
            defaultMessage: "Something went wrong. Try again later.",
          })
        )
      }

      return {
        contentType: logo.contentType as ContentTypeEnum,
        ext: logo.ext as FileExtensionEnum,
        fileName: logo.fileName,
        type: logo.type,
        uuid: logo.uuid,
      }
    })

    const restaurantFormData = {
      brandColor: data.brandColor,
      name: data.name,
      urlIdentifier: data.url,
      currentStep: step ? (step > 2 ? step : 2) : 2,
      logos: logosArray,
    }

    return restaurantFormData
  }

  const checkUrlExist = async (url: string, userUuID: string) => {
    const { data } = await getUrlIdentifier({
      variables: {
        urlIdentifier: url,
        userUuid: userUuID,
      },
    })

    return data?.getUrlIdentifier.isUsed
  }

  const onFormSubmit = async (data: RestaurantFormType) => {
    const urlExist = await checkUrlExist(data.url, userUUID)

    if (urlExist) {
      setError("url", {
        type: "required",
        message: intl.formatMessage({
          id: "onboarding.restaurant.url.error",
          defaultMessage:
            "URL already in use. Please select a different one for your restaurant.",
        }),
      })
      return
    }

    if (!isDirty && currentStep) {
      return onNext()
    }

    try {
      setLoadingNext(true)

      const logosToUpload = getLogosToUpload()

      if (logosToUpload.length > 0) {
        await uploadAttachments(logosToUpload)
      }

      const updateSuccessful = await updateRestaurantSnapshot(data)

      if (updateSuccessful) {
        const restaurantFormData = getRestaurantData(data, logos, currentStep)
        updateOnboardingState(restaurantFormData)
        reset({}, { keepValues: true })
        onNext()
      }
    } catch (error) {
      showGraphqlErrors(error)
    }

    setLoadingNext(false)
  }

  const setSquareLogo = (file?: RcFile) => {
    setValue("squareLogo", file, { shouldDirty: true, shouldValidate: true })
    upsertLogo(AttachmentTypeEnum.LOGO, file)
  }

  const onNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (T: string) => void
  ) => {
    onChange(event.target.value)
    updateOnboardingState({ name: event.target.value })
  }

  const onUrlIdentifierChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (T: string) => void
  ) => {
    onChange(event.target.value)
    updateOnboardingState({ urlIdentifier: event.target.value })
  }

  const onColorChange = (color: string, onChange: (T: string) => void) => {
    onChange(color)
    updateOnboardingState({ brandColor: color })
  }

  useEffect(() => {
    const formattedName = formatUrlIdentifier(nameWatcher)
    setValue("url", formattedName)
    updateOnboardingState({ urlIdentifier: formattedName })

    if (defaultValues.name || touchedFields.name || dirtyFields.name)
      trigger("url")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameWatcher, setValue, trigger])

  useEffect(() => {
    const formattedName = formatUrlIdentifier(urlWatcher)
    setValue("url", formatUrlIdentifier(formattedName))
    updateOnboardingState({ urlIdentifier: formattedName })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlWatcher, setValue])

  return (
    <FormProvider {...restaurantFormMethods}>
      <Container
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Container>
          <Container display="flex">
            <Container display="flex" flexDirection="column">
              <Container display="flex" gap="16px" alignItems="flex-end">
                <Container>
                  <UploadAvatar
                    avatar={squareLogo}
                    onChange={setSquareLogo}
                    validateSquareLogo
                    label={intl.formatMessage({
                      id: "onboarding.forms.restaurant.form.logo.label",
                      defaultMessage: "Logo",
                    })}
                    requirement="required"
                  />
                  <Spacer size={4} />
                  <Text
                    size={squareLogoError ? "m" : "s"}
                    weight="regular"
                    color={squareLogoError ? "Danger5" : "Neutral5"}
                  >
                    {squareLogoError ??
                      intl.formatMessage({
                        id: "onboarding.forms.restaurant.form.square.logo.info.label",
                        defaultMessage: "Square (1024x1024)",
                      })}
                  </Text>
                </Container>
              </Container>
            </Container>
          </Container>
          <Spacer size={16} />
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, ...rest } }) => (
              <Input
                {...rest}
                onChange={(event) => onNameChange(event, onChange)}
                label={intl.formatMessage({
                  id: "onboarding.forms.restaurant.form.name.label",
                  defaultMessage: "Restaurant Name",
                })}
                requirement="required"
                hasError={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Spacer size={40} />
          <Controller
            name="url"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                onChange={(event) => onUrlIdentifierChange(event, onChange)}
                {...rest}
                label={intl.formatMessage({
                  id: "onboarding.forms.restaurant.form.url.label",
                  defaultMessage: "Restaurant URL",
                })}
                prefix={intl.formatMessage({
                  id: "onboarding.forms.restaurant.form.url.prefix",
                  defaultMessage: "storefront.crmb.co/",
                })}
                hasError={!!errors.url}
                helperText={errors.url?.message}
                requirement="required"
              />
            )}
          />
          <Spacer size={40} />
          <InputLabel
            label={intl.formatMessage({
              id: "onboarding.forms.restaurant.form.brand.color.label",
              defaultMessage: "Brand color",
            })}
            requirement="optional"
          />
          <Controller
            name="brandColor"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                color={value}
                initColor={colors.Primary5}
                onChange={(color) => onColorChange(color, onChange)}
                title={intl.formatMessage({
                  id: "onboarding.forms.restaurant.form.brand.color.label",
                  defaultMessage: "Brand Color",
                })}
                text
              />
            )}
          />
        </Container>
        <StyledSteps>
          <StepFooterContent>
            <StepsFooter indicatorsStates={indicators} step={stepFooter} />
          </StepFooterContent>
          <StepperHandler
            onNext={handleSubmit(onFormSubmit)}
            loading={loadingNext}
          />
        </StyledSteps>
      </Container>
    </FormProvider>
  )
}

export default OnboardingRestaurantForm

const StyledSteps = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${device.lg} {
    justify-content: space-between;
    margin-top: 32px;
  }
`

const StepFooterContent = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`
