import Container from "../../../../ui/Container"
import Divider from "../../../../ui/Divider"
import Filter from "../../../../ui/Header/Filter"
import Icon from "../../../../ui/Icon"
import Spacer from "../../../../ui/Spacer"
import Text from "../../../../ui/Typography/Text"
import type { IOrdersParams } from "../hookforms.interfaces"
import FilterByCustomer from "./FilterByCustomer"
import FilterByDeliveryMethod from "./FilterByDeliveryMethod"
import FilterByLocation from "./FilterByLocation"
import FilterByOrderStatus from "./FilterByOrderStatus"
import FilterByPaymentMethod from "./FilterByPaymentMethod"
import { OrderFilterList } from "./OrdersFilterContent"
import { Modal } from "antd"
import { useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

export enum OrderFilterTypeEnum {
  customer = "customer",
  location = "location",
  "order-status" = "order-status",
  "report-group-by" = "report-group-by",
  "delivery-method" = "delivery-method",
  "payment-method" = "payment-method",
}

interface IFilter {
  id: string
  name: string
  defaultMessage: string
  type: `${OrderFilterTypeEnum}`
  label?: string
}

const filters: IFilter[] = [
  {
    id: "status",
    name: "restaurants.orders.filter.by.status",
    defaultMessage: "Order Status",
    type: OrderFilterTypeEnum["order-status"],
  },
  {
    id: "delivery-method",
    name: "restaurants.orders.filter.by.delivery.method",
    defaultMessage: "Delivery Method",
    type: OrderFilterTypeEnum["delivery-method"],
  },
  {
    id: "customer",
    name: "restaurants.orders.filter.by.customer",
    defaultMessage: "Customer",
    type: OrderFilterTypeEnum.customer,
  },
  {
    id: "location",
    name: "restaurants.orders.filter.by.location",
    defaultMessage: "Location",
    type: OrderFilterTypeEnum.location,
  },
  {
    id: "payment-method",
    name: "restaurants.orders.filter.by.payment.method",
    defaultMessage: "Payment Method",
    type: OrderFilterTypeEnum["payment-method"],
  },
]

export const OrdersFilter = () => {
  const intl = useIntl()
  const { locationUUID } = useParams<IOrdersParams>()
  const [displayModal, setDisplayModal] = useState<boolean>(false)
  const [filterType, setFilterType] = useState<
    `${OrderFilterTypeEnum}` | undefined
  >()

  const clearFilterOptions = () => {
    setFilterType(undefined)
  }

  const showFilterModal = () => {
    setDisplayModal(true)
  }

  const onCloseFilterModal = () => {
    setDisplayModal(false)
    setFilterType(undefined)
  }

  let content = <div />

  switch (filterType) {
    case OrderFilterTypeEnum["order-status"]: {
      {
        content = (
          <FilterByOrderStatus
            filterLabel={intl.formatMessage({
              id: "restaurants.orders.filter.by.order.status.label",
              defaultMessage: "Order Status",
            })}
            clearFilterOptions={clearFilterOptions}
          />
        )
      }

      break
    }

    case OrderFilterTypeEnum["delivery-method"]: {
      {
        content = (
          <FilterByDeliveryMethod
            filterLabel={intl.formatMessage({
              id: "restaurants.orders.filter.by.delivery.method.label",
              defaultMessage: "Delivery Method",
            })}
            clearFilterOptions={clearFilterOptions}
          />
        )
      }

      break
    }

    case OrderFilterTypeEnum.customer: {
      {
        content = (
          <FilterByCustomer
            filterLabel={intl.formatMessage({
              id: "restaurants.orders.filter.by.customer.label",
              defaultMessage: "Customer",
            })}
            clearFilterOptions={clearFilterOptions}
          />
        )
      }

      break
    }

    case OrderFilterTypeEnum.location: {
      {
        content = (
          <FilterByLocation
            filterLabel={intl.formatMessage({
              id: "restaurants.orders.filter.by.location.label",
              defaultMessage: "Location",
            })}
            clearFilterOptions={clearFilterOptions}
          />
        )
      }

      break
    }

    case OrderFilterTypeEnum["payment-method"]: {
      {
        content = (
          <FilterByPaymentMethod
            filterLabel={intl.formatMessage({
              id: "restaurants.orders.filter.by.payment.method.label",
              defaultMessage: "Payment Method",
            })}
            clearFilterOptions={clearFilterOptions}
          />
        )
      }

      break
    }

    default: {
      break
    }
  }

  return (
    <>
      <Filter onFilterClick={showFilterModal} />
      <StyledModal
        footer={null}
        visible={displayModal}
        onCancel={onCloseFilterModal}
        closable={false}
        centered
      >
        {!filterType && (
          <>
            <StyledHeader>
              <Text color="Neutral5" size="m">
                {intl.formatMessage({
                  id: "components.filter.header.title",
                  defaultMessage: "Filter by...",
                })}
              </Text>
            </StyledHeader>

            <Divider verticalMargin="0px" />

            <Spacer size={16} />

            <OrderFilterList>
              <StyledModalBody display="flex" flexDirection="column" gap="16px">
                {filters
                  .filter((filtr) =>
                    locationUUID ? filtr.id !== "location" : filtr
                  )
                  .map((filtr) => (
                    <Container
                      key={filtr.id}
                      onClick={() => setFilterType(filtr.type)}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="18px"
                    >
                      <Icon
                        remixiconClass="ri-search-line"
                        color="Neutral5"
                        size={16}
                      />
                      <Text size="s">
                        {intl.formatMessage({
                          id: filtr.name,
                          defaultMessage: filtr.defaultMessage,
                        })}
                      </Text>
                    </Container>
                  ))}
              </StyledModalBody>
            </OrderFilterList>
          </>
        )}
        {content}
      </StyledModal>
    </>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-body {
    padding: 0px;
  }
`

const StyledHeader = styled.div`
  padding: 8px;
`

const StyledModalBody = styled(Container)`
  max-height: 400px;
  min-height: 200px;
`
