import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSortingFieldMutationVariables = Types.Exact<{
  input: Types.SortFieldInput;
}>;


export type UpdateSortingFieldMutation = { __typename?: 'Mutation', updateSortingField: { __typename?: 'SortingFieldMenu', uuid?: string | null, sortingType?: Types.SortingByMenuEnum | null, sortBy: { __typename?: 'SortingByMenu', createdAt?: string | null, itemOrder?: string | null } } };


export const UpdateSortingFieldDocument = gql`
    mutation updateSortingField($input: SortFieldInput!) {
  updateSortingField(input: $input) {
    uuid
    sortingType
    sortBy {
      createdAt
      itemOrder
    }
  }
}
    `;
export type UpdateSortingFieldMutationFn = Apollo.MutationFunction<UpdateSortingFieldMutation, UpdateSortingFieldMutationVariables>;

/**
 * __useUpdateSortingFieldMutation__
 *
 * To run a mutation, you first call `useUpdateSortingFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSortingFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSortingFieldMutation, { data, loading, error }] = useUpdateSortingFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSortingFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSortingFieldMutation, UpdateSortingFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSortingFieldMutation, UpdateSortingFieldMutationVariables>(UpdateSortingFieldDocument, options);
      }
export type UpdateSortingFieldMutationHookResult = ReturnType<typeof useUpdateSortingFieldMutation>;
export type UpdateSortingFieldMutationResult = Apollo.MutationResult<UpdateSortingFieldMutation>;
export type UpdateSortingFieldMutationOptions = Apollo.BaseMutationOptions<UpdateSortingFieldMutation, UpdateSortingFieldMutationVariables>;