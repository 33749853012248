import type { GetItemListQueryVariables } from "../../../../../../GraphQL/Queries/getItemList.generated"
import type { SaveItemCacheType } from "./interfaces"
import { deleteItem, saveItem } from "./items-cache-methods"

const saveItemInCache = ({
  data,
  variables,
}: {
  data: SaveItemCacheType
  variables: GetItemListQueryVariables
}) => {
  saveItem(data, variables)
}

const deleteItemFromCache = ({
  uuid,
  variables,
}: {
  uuid: string
  variables: GetItemListQueryVariables
}) => {
  deleteItem(uuid, variables)
}

export { saveItemInCache, deleteItemFromCache }
