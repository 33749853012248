import { LifeTimeSpend, SpecificCustomer } from "."
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Select from "../../../../../../ui/Select"
import Spacer from "../../../../../../ui/Spacer"
import Tag from "../../../../../../ui/Tag"
import { EligibleCustomerRuleTypeEnum } from "../../../interfaces/eligibleCustomer.interface"
import { ErrorBadge, Header } from "../Components"
import { SELECT_LIST_HEIGHT } from "../constants"
import { useCollapseOpen } from "../hooks/useCollapseOpen"
import { eligibleCustomerOptionsTitle } from "./eligibleCustomerOptions.title"
import { useEligibleCustomer } from "./hooks/useEligibleCustomer"
import { useIntl } from "react-intl"

export const EligibleCustomer = () => {
  const intl = useIntl()
  const { handleCollapseChange, open } = useCollapseOpen()

  const { users, spend, hasError, isEditMode, type, onEligibleCustomerChange } =
    useEligibleCustomer()

  const headerTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.eligible.customer.header.title",
    defaultMessage: "Eligible Customer(s)",
  })

  const customerTagLabel = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.eligible.customer.customers.option.tag.label",
      defaultMessage:
        "{customers} {customers, plural, one {Customer} other {Customers}}",
    },
    { customers: users?.length }
  )

  const currencySymbol = intl.formatMessage({
    id: "generic.symbol.currency",
    defaultMessage: "$",
  })

  const bySpendTitle = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.eligible.customer.by.spend.option.tag.label",
      defaultMessage: "By Spending {currencySymbol}{amount}",
    },
    { amount: spend, currencySymbol }
  )

  const everyoneLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.eligible.customer.tag.label",
    defaultMessage: "Everyone",
  })

  const selectTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.eligible.customer.select.title",
    defaultMessage: "Select Customers",
  })

  const options = Object.keys(eligibleCustomerOptionsTitle).map((orderType) => {
    const orderMethod = eligibleCustomerOptionsTitle[orderType]

    return {
      label: intl.formatMessage({
        id: orderMethod.id,
        defaultMessage: orderMethod.defaultMessage,
      }),
      value: orderType,
    }
  })

  return (
    <Collapse
      contentBorderColor="Neutral4"
      removeHeaderBottomBorder
      contentBordered
      classId="discount-rule-eligible-customer-collapse"
      onChange={handleCollapseChange}
    >
      <CollapsePanel
        key="discount-rule-eligible-customer-panel"
        extra={<ErrorBadge hidden={!hasError} />}
        header={
          <Header title={headerTitle}>
            {!open && (
              <Tag>
                {customerLabel(type, {
                  everyone: everyoneLabel,
                  specific: customerTagLabel,
                  bySpend: bySpendTitle,
                })}
              </Tag>
            )}
          </Header>
        }
      >
        <Select
          disabled={isEditMode}
          onChange={onEligibleCustomerChange}
          label={selectTitle}
          listHeight={SELECT_LIST_HEIGHT}
          options={options}
          value={type}
        />
        <Spacer
          size={40}
          hidden={type === EligibleCustomerRuleTypeEnum.EVERYONE}
        />
        {type === EligibleCustomerRuleTypeEnum.SPECIFIC && <SpecificCustomer />}
        {type === EligibleCustomerRuleTypeEnum.SPEND && <LifeTimeSpend />}
      </CollapsePanel>
    </Collapse>
  )
}

const customerLabel = (
  selectionType: EligibleCustomerRuleTypeEnum,
  label: { everyone: string; specific: string; bySpend: string }
) => {
  const { everyone, specific, bySpend } = label

  if (selectionType === EligibleCustomerRuleTypeEnum.EVERYONE) return everyone
  if (selectionType === EligibleCustomerRuleTypeEnum.SPECIFIC) return specific

  return bySpend
}
