import type {
  GetOrdersByRestaurantQuery,
  GetOrdersByRestaurantQueryVariables,
} from "../../GraphQL/getOrdersByRestaurant.generated"
import { GetOrdersByRestaurantDocument } from "../../GraphQL/getOrdersByRestaurant.generated"
import type {
  GetOrderDetailsQuery,
  GetOrderDetailsQueryVariables,
} from "../../OrderDetail/GraphQL/getOrderDetails.generated"
import { GetOrderDetailsDocument } from "../../OrderDetail/GraphQL/getOrderDetails.generated"
import { ApolloCache } from "@apollo/client"

type WriteCacheOrdersByRestaurantQuery = {
  cache: ApolloCache<unknown>
  query: GetOrdersByRestaurantQuery
  variables: GetOrdersByRestaurantQueryVariables
}

type WriteCacheOrderDetailsQuery = {
  cache: ApolloCache<unknown>
  query: GetOrderDetailsQuery
  variables: GetOrderDetailsQueryVariables
}

const writeOrdersByRestaurantQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheOrdersByRestaurantQuery) =>
  cache.writeQuery<
    GetOrdersByRestaurantQuery,
    GetOrdersByRestaurantQueryVariables
  >({
    query: GetOrdersByRestaurantDocument,
    data: query,
    variables,
  })

const writeOrderDetailsQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheOrderDetailsQuery) =>
  cache.writeQuery<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>({
    query: GetOrderDetailsDocument,
    data: query,
    variables,
  })

export { writeOrdersByRestaurantQueryCache, writeOrderDetailsQueryCache }
