import type { GetMenuListQueryVariables } from "../../../../../GraphQL/Queries/getMenuList.generated"
import { MenuList } from "../../../../../shared/graphql/generated/types"
import type { SaveMenuCacheType } from "./interfaces"
import { deleteMenu, reorderMenuList, saveMenu } from "./menus-cache-methods"

const saveMenuInCache = ({
  data,
  variables,
}: {
  data: SaveMenuCacheType
  variables: GetMenuListQueryVariables
}) => {
  saveMenu(data, variables)
}

const deleteMenuFromCache = ({
  data,
  variables,
}: {
  data: { uuid: string }
  variables: GetMenuListQueryVariables
}) => {
  deleteMenu(data, variables)
}

const reorderMenuListFromCache = ({
  data,
  variables,
}: {
  data: MenuList[]
  variables: GetMenuListQueryVariables
}) => {
  reorderMenuList(data, variables)
}

export { saveMenuInCache, deleteMenuFromCache, reorderMenuListFromCache }
