import React, { createContext, useCallback, useState } from "react"

export interface RestaurantCompletionStepsState {
  mainLocationUUID: string | null
  hasHoursOfOperationSetupCompleted: boolean
  hasMenuSetupCompleted: boolean
  hasItemsCombosSetupCompleted: boolean
  hasBankAccountSetupCompleted: boolean
  hasBankAccountConnected: boolean
}

interface RestaurantCompletionStepsContextType
  extends RestaurantCompletionStepsState {
  updateRestaurantCompletionSteps: (
    steps: Partial<RestaurantCompletionStepsState>
  ) => void
}

const restaurantCompletionStepsInitialState: RestaurantCompletionStepsState = {
  mainLocationUUID: null,
  hasHoursOfOperationSetupCompleted: false,
  hasMenuSetupCompleted: false,
  hasItemsCombosSetupCompleted: false,
  hasBankAccountSetupCompleted: false,
  hasBankAccountConnected: false,
}

const RestaurantCompletionStepsContext =
  createContext<RestaurantCompletionStepsContextType | null>(null)

export const RestaurantCompletionStepsProvider: React.FC = ({ children }) => {
  const [restaurantCompletionStepsState, setRestaurantCompletionStepsState] =
    useState(restaurantCompletionStepsInitialState)

  const updateRestaurantCompletionSteps = useCallback(
    (steps: Partial<RestaurantCompletionStepsState>) => {
      setRestaurantCompletionStepsState((prevState) => ({
        ...prevState,
        ...steps,
      }))
    },
    []
  )

  return (
    <RestaurantCompletionStepsContext.Provider
      value={{
        ...restaurantCompletionStepsState,
        updateRestaurantCompletionSteps,
      }}
    >
      {children}
    </RestaurantCompletionStepsContext.Provider>
  )
}

export const useRestaurantCompletionStepsContext = () => {
  const context = React.useContext(RestaurantCompletionStepsContext)

  if (context === null) {
    throw new Error(
      "useRestaurantCompletionStepsContext must be used within a RestaurantCompletionStepsProvider"
    )
  }

  return context
}
