import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Icon from "../../../../../ui/Icon"
import Select, {
  OptionSelect,
  SelectDropdownFooter,
} from "../../../../../ui/Select"
import OwnershipTag from "../../../components/OwnershipTag"
import { useGetAllModifiersByRestaurantQuery } from "../../GraphQL/getAllIngredientsByRestaurant.generated"
import { useIngredients } from "../../hooks/useIngredients"
import { updateIngredientListToCache } from "../../utils/cache-ingredients/ingredients-cache-config"
import ModifierEditModal from "../ModifierEditModal"
import type {
  IModifierGroupForm,
  IngredientType,
} from "../hookforms.interfaces"
import { debounce, get, uniqBy } from "lodash"
import React, { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

export type ModifierSelectProps = {
  modifierIndex: number
  setIngredient: (modifierIndex: number, ingredient: IngredientType) => void
}

export const ModifierSelect: React.FC<ModifierSelectProps> = ({
  modifierIndex,
  setIngredient,
}) => {
  const intl = useIntl()

  const {
    state: { currentRestaurant },
  } = useGeneralContext()

  const [newModifier, setNewModifier] = useState<string>()
  const [openSelectModifier, setOpenSelectModifier] = useState<
    boolean | undefined
  >()
  const [editData, setEditData] = useState<IngredientType>()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IModifierGroupForm>()

  const isCorporate = watch("isMain")
  const defaultIngredient = watch(`modifiers.${modifierIndex}.ingredient.uuid`)
  const selectedModifierName = watch(
    `modifiers.${modifierIndex}.ingredient.name`
  )
  const { createIngredient, ingredientCreated } = useIngredients()

  const { data: modifiersData, refetch } = useGetAllModifiersByRestaurantQuery({
    skip: !currentRestaurant.uuid,
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    onCompleted: (result) => {
      updateIngredientListToCache({
        data: result.getAllIngredientsByRestaurant,
        variables: {},
      })
    },
  })

  const setModifierSelectListVisible = () => {
    setOpenSelectModifier(true)
  }

  const setModifierSelectListHidden = () => {
    setOpenSelectModifier(false)
  }

  const clearNewModifier = () => setNewModifier(undefined)

  const onCreateModifier = async () => {
    if (!newModifier) {
      return
    }

    try {
      await createIngredient(
        newModifier,
        (result) => {
          setValue(
            `modifiers.${modifierIndex}.ingredient`,
            result.createIngredient,
            {
              shouldValidate: true,
            }
          )
        },
        showGraphqlErrors
      )
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const setOpenModalEditModifier = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ingredientItem: IngredientType
  ) => {
    event.stopPropagation()

    if (ingredientItem) {
      setEditData(ingredientItem)
    }
  }

  const onCloseModalEditModifier = () => {
    setEditData(undefined)
  }

  const searchIngredients = debounce(async (name: string) => {
    if (!name) {
      clearNewModifier()

      return refetch({ name: undefined })
    }

    await refetch({ name })
    setNewModifier(name.replace(/^./, name[0].toUpperCase()))
  }, 600)

  const getMergedIngredients = () => {
    const modifiersResult = get(
      modifiersData,
      "getAllIngredientsByRestaurant.results",
      []
    )

    if (defaultIngredient) {
      return uniqBy(
        [
          ...modifiersResult,
          { uuid: defaultIngredient, name: selectedModifierName },
        ],
        "uuid"
      )
    }

    return modifiersResult
  }

  const ingredients = getMergedIngredients()

  const renderDropdown = (dropdown: React.ReactElement) => {
    const found = dropdown.props?.options?.some?.(
      (option: { label: string }) => option.label === newModifier
    )

    return (
      <div className="dropdown-container">
        {dropdown}
        {!found && newModifier && (
          <SelectDropdownFooter
            searchValue={newModifier}
            onButtonClick={onCreateModifier}
            buttonLoading={ingredientCreated.loading}
          />
        )}
      </div>
    )
  }

  const handleChange = (uuid: string) => {
    const ingredient = ingredients.find(
      (ingredientType: IngredientType) => ingredientType.uuid === uuid
    )

    setIngredient(modifierIndex, ingredient)
    setModifierSelectListHidden()
  }

  return (
    <StyleBoxSelectModifier>
      <Controller
        name={`modifiers.${modifierIndex}.ingredient.uuid`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value ? value : undefined}
            loading={ingredientCreated.loading}
            placeholder={intl.formatMessage({
              id: "restaurants.modifiers.group.form.modifier.select.placeholder",
              defaultMessage: "Add Modifier",
            })}
            requirement="required"
            hasError={!!errors?.modifiers?.[modifierIndex]?.uuid}
            helperText={get(errors, "uuid.message")}
            onSearch={searchIngredients}
            onClear={clearNewModifier}
            onChange={(uuid: string) => {
              onChange(uuid)
              handleChange(uuid)
            }}
            open={openSelectModifier}
            onFocus={setModifierSelectListVisible}
            onBlur={setModifierSelectListHidden}
            dropdownRender={renderDropdown}
            listItemHeight={5}
            listHeight={160}
            dropdownAlign={false}
            optionFilterProp="label"
            optionLabelProp="children"
            autoClearSearchValue
            hideSearchIcon
            allowSearch
          >
            {ingredients?.map(
              ({ name, uuid, isMain, tags }: IngredientType) => {
                return (
                  <OptionSelect key={uuid} value={uuid} label={name}>
                    <Container
                      display="flex"
                      whiteSpace="normal"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="12px"
                    >
                      <Container
                        maxWidth="calc(100% - 124px)"
                        className="option-text"
                        title={name}
                      >
                        {name}
                      </Container>
                      <Container display="flex" alignItems="center">
                        {!isCorporate && isMain && (
                          <Container margin="0 10px 0 0">
                            <OwnershipTag isMain={isMain} />
                          </Container>
                        )}
                        <Container
                          visibility={
                            isCorporate
                              ? "visible"
                              : isMain
                              ? "hidden"
                              : "visible"
                          }
                          className="option-edit"
                        >
                          <Icon
                            remixiconClass="ri-pencil-line"
                            color="Neutral8"
                            onClick={(event) =>
                              setOpenModalEditModifier(event, {
                                name,
                                uuid,
                                tags,
                              })
                            }
                            classes={
                              isCorporate
                                ? "option-select-edit"
                                : isMain
                                ? ""
                                : "option-select-edit"
                            }
                          />
                        </Container>
                      </Container>
                    </Container>
                  </OptionSelect>
                )
              }
            )}
          </Select>
        )}
      />
      {!!editData?.uuid && (
        <ModifierEditModal
          visible={!!editData}
          onClose={onCloseModalEditModifier}
          ingredientItem={editData}
          isCorporate={isCorporate}
        />
      )}
    </StyleBoxSelectModifier>
  )
}

const StyleBoxSelectModifier = styled(Container)`
  .option-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .option-select-edit {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all;
  }

  .ant-select-item-option:hover {
    .option-select-edit {
      visibility: visible;
      opacity: 1;
    }
  }
  .ant-select-selection-item {
    .option-edit {
      display: none;
    }
  }
`
