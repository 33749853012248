import notification from "../../../../../../ui/notification"
import { useDeclineCateringOrderMutation } from "../../../Graphql/declineCateringOrder.generated"
import { declineCateringOrderCacheUpdate } from "../../../utils/cache/order-catering-cache.util"
import { Button, Group, Modal, Stack, Text } from "@mantine/core"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

interface DeclineOrderModalProps {
  opened: boolean
  onClose: () => void
}

export const DeclineOrderModal = ({
  opened,
  onClose,
}: DeclineOrderModalProps) => {
  const intl = useIntl()

  const { orderUUID } = useParams<{ orderUUID: string }>()

  const [declineCateringOrder, { loading }] = useDeclineCateringOrderMutation()

  const handleDeclineOrder = async () => {
    try {
      await declineCateringOrder({
        variables: {
          order: {
            uuid: orderUUID,
          },
        },
        update: (cache, result) => {
          if (result.data) {
            declineCateringOrderCacheUpdate(cache, orderUUID)
          }
        },
        onCompleted: () => onClose(),
      })
    } catch {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.catering.decline.order.modal.error.message",
          defaultMessage:
            "Something went wrong. Please try again. If the problem persists, contact support.",
        }),
      })
      onClose()
    }
  }

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={intl.formatMessage({
        id: "restaurants.catering.decline.order.modal.title",
        defaultMessage: "Decline order",
      })}
    >
      <Stack spacing={8}>
        <Text>
          {intl.formatMessage({
            id: "restaurants.catering.decline.order.modal.description",
            defaultMessage:
              "Declining this order will result in corporate reassigning it to a different location. This action cannot be undone, so please be certain.",
          })}
        </Text>
        <Group position="right">
          <Button variant="outline" color="gray.6" onClick={onClose}>
            {intl.formatMessage({
              id: "restaurants.catering.decline.order.modal.cancel.button",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            color="yellow.6"
            loading={loading}
            onClick={handleDeclineOrder}
          >
            {intl.formatMessage({
              id: "restaurants.catering.decline.order.modal.decline.button",
              defaultMessage: "Decline order",
            })}
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
