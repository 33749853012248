import { IntlMessage } from "../../../../../shared/types"
import { Button, Group, Modal, Text, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconDownload } from "@tabler/icons-react"
import { useState } from "react"
import { useIntl } from "react-intl"

enum ReportExportEnum {
  FINANCIAL = "Financial",
  LABOR_COST = "LaborCost",
  CUSTOMER_SATISFACTION = "CustomerSatisfaction",
  PAYOUTS = "Payouts",
  FTL_FINANCIAL = "FTLFinancial",
}

const ModalDescription: Record<`${ReportExportEnum}`, IntlMessage> = {
  FTLFinancial: {
    id: "restaurants.reports.export.csv.modal.customer.ftl.financial.description",
    defaultMessage:
      "All transactions report will be exported as a spreadsheet document.",
  },
  CustomerSatisfaction: {
    id: "restaurants.reports.export.csv.modal.customer.satisfaction.description",
    defaultMessage:
      "Customer satisfaction report will be exported as a CSV table.",
  },
  Financial: {
    id: "restaurants.reports.export.csv.modal.financial.description",
    defaultMessage: "Financial report will be exported as a CSV table.",
  },
  LaborCost: {
    id: "restaurants.reports.export.csv.modal.labor.cost.description",
    defaultMessage: "Labor cost report will be exported as a CSV table.",
  },
  Payouts: {
    id: "restaurants.reports.export.csv.modal.labor.cost.description",
    defaultMessage: "Payouts report will be exported as a CSV table.",
  },
}

type ExportCSVProps = {
  type: `${ReportExportEnum}`
  label?: string
  onClick?: () => Promise<void>
  disabled?: boolean
}

export const ExportCSV = (props: ExportCSVProps) => {
  const { onClick, type, label, disabled = false } = props
  const intl = useIntl()
  const [opened, { close, open }] = useDisclosure()
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    try {
      await onClick?.()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      close()
    }
  }

  return (
    <>
      <Button
        variant="outline"
        disabled={disabled}
        size="sm"
        onClick={open}
        h={32}
        leftIcon={<IconDownload size={16} />}
      >
        <Text weight={400} ml={8}>
          {label ||
            intl.formatMessage({
              id: "restaurants.reports.export.csv.button.label",
              defaultMessage: "Export CSV",
            })}
        </Text>
      </Button>
      <Modal
        centered
        size="md"
        opened={opened}
        onClose={close}
        title={
          <Title size="h4">
            {intl.formatMessage({
              id: "restaurants.reports.export.csv.modal.title",
              defaultMessage: "Export your report",
            })}
          </Title>
        }
      >
        <Text size="sm" color="gray.7">
          {intl.formatMessage(ModalDescription[type])}
        </Text>
        <Group position="right">
          <Button mt={8} w={87} h={40} onClick={handleClick} loading={loading}>
            <Text weight={400} size="md">
              {!loading &&
                intl.formatMessage({
                  id: "restaurants.reports.export.csv.modal.export.button.label",
                  defaultMessage: "Export",
                })}
            </Text>
          </Button>
        </Group>
      </Modal>
    </>
  )
}
