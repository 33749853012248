import { EMPTY_ARRAY } from "../../../../../../../shared/utils/constant/values"
import { EligibleCustomerRuleTypeEnum } from "../../../../interfaces/eligibleCustomer.interface"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME,
  ELIGIBLE_CUSTOMER_TYPE_FIELD_NAME,
  ELIGIBLE_CUSTOMER_USERS_FIELD_NAME,
  SET_VALUE_OPTIONS,
} from "../../constants"
import { useFormContext, useWatch } from "react-hook-form"

export const useEligibleCustomer = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IDiscountForm>()

  const { spend: spendError, users: usersError } =
    errors.rules?.eligibleCustomer ?? {}

  const hasError = spendError?.message || !!usersError?.message

  const isEditMode = getValues(EDIT_MODE_NAME)

  const [type, users, spend] = useWatch({
    control,
    name: [
      ELIGIBLE_CUSTOMER_TYPE_FIELD_NAME,
      ELIGIBLE_CUSTOMER_USERS_FIELD_NAME,
      ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME,
    ],
  })

  const onEligibleCustomerChange = (value: EligibleCustomerRuleTypeEnum) => {
    setValue(ELIGIBLE_CUSTOMER_TYPE_FIELD_NAME, value, SET_VALUE_OPTIONS)
    setValue(ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME, null, SET_VALUE_OPTIONS)
    setValue(ELIGIBLE_CUSTOMER_USERS_FIELD_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
  }

  return {
    hasError,
    isEditMode,
    type,
    users,
    spend: spend ?? 0,
    onEligibleCustomerChange,
  }
}
