import React, { useEffect } from "react"

export const usePortal = (id: string) => {
  const div = React.useRef(document.createElement("div"))

  useEffect(() => {
    let modalContainer = document.querySelector(`#${id}`)

    if (!modalContainer) {
      modalContainer = document.createElement("div")
      modalContainer.setAttribute("id", "modal-root")
      document.body.append(modalContainer)
    }
    modalContainer.append(div.current)
    const refValue = div.current

    return () => {
      if (refValue) {
        refValue.remove()
      }
    }
  }, [id])

  return div.current
}
