import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateDiscountCodeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateDiscountCodeQuery = { __typename?: 'Query', generateDiscountCode: string };


export const GenerateDiscountCodeDocument = gql`
    query generateDiscountCode {
  generateDiscountCode
}
    `;

/**
 * __useGenerateDiscountCodeQuery__
 *
 * To run a query within a React component, call `useGenerateDiscountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateDiscountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateDiscountCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateDiscountCodeQuery(baseOptions?: Apollo.QueryHookOptions<GenerateDiscountCodeQuery, GenerateDiscountCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateDiscountCodeQuery, GenerateDiscountCodeQueryVariables>(GenerateDiscountCodeDocument, options);
      }
export function useGenerateDiscountCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateDiscountCodeQuery, GenerateDiscountCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateDiscountCodeQuery, GenerateDiscountCodeQueryVariables>(GenerateDiscountCodeDocument, options);
        }
export type GenerateDiscountCodeQueryHookResult = ReturnType<typeof useGenerateDiscountCodeQuery>;
export type GenerateDiscountCodeLazyQueryHookResult = ReturnType<typeof useGenerateDiscountCodeLazyQuery>;
export type GenerateDiscountCodeQueryResult = Apollo.QueryResult<GenerateDiscountCodeQuery, GenerateDiscountCodeQueryVariables>;