import defaultBackground from "../../shared/assets/images/bg.jpg"
import { StyledBackground } from "./Background.styled"
import React from "react"

type BackgroundProps = {
  containerProps?: Record<string, unknown>
  imgProps?: React.ComponentProps<"img">
  source?: string
}

/**
 * Background will take the size of the screen not the container that holds it
 * will take a z-index or -1 to let the content over itself
 */
export const Background: React.FC<BackgroundProps> = (props) => {
  const { containerProps, imgProps, source } = props

  return (
    <StyledBackground {...containerProps}>
      <img src={source ?? defaultBackground} {...imgProps} />
    </StyledBackground>
  )
}
