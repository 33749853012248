export const CustomMenuCategoryTypeTitle = {
  BEVERAGE: {
    id: "restaurants.menu.categories.beverage",
    defaultMessage: "Beverage",
  },
  FOOD: {
    id: "restaurants.menu.categories.food",
    defaultMessage: "Food",
  },
  RETAIL: {
    id: "restaurants.menu.categories.retail",
    defaultMessage: "Retail",
  },
  CATERING: {
    id: "restaurants.menu.categories.catering",
    defaultMessage: "Catering",
  },
}
