import type { OrderActionModel } from "../../../../../../shared/graphql/generated/types"
import { MMM_Do_YYYY_h_mm_ss_a } from "../../../../../../shared/utils/constant/dateFormats"
import Button from "../../../../../../ui/Button"
import Container from "../../../../../../ui/Container"
import type { IModalProps } from "../../../../../../ui/Modal"
import Modal from "../../../../../../ui/Modal"
import Spacer from "../../../../../../ui/Spacer"
import Text from "../../../../../../ui/Typography/Text"
import moment from "moment-timezone"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type OrderTimelineCommentProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  orderAction: OrderActionModel
}

export const OrderTimelineCommentModal: React.FC<OrderTimelineCommentProps> = ({
  orderAction,
  onClose,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Modal
      {...props}
      onCancel={onClose}
      title={intl.formatMessage({
        id: "restaurants.orders.order.status.comment",
        defaultMessage: "Comment",
      })}
      closable={false}
      footer={
        <Container display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            aria-label="button-modal-close"
            title={intl.formatMessage({
              id: "restaurants.orders.order.timeline.action.modal.ok",
              defaultMessage: "Ok",
            })}
            onClick={onClose}
          />
        </Container>
      }
      destroyOnClose
      shouldCloseOnClickOutside
    >
      <StyledMainContainer>
        <Text size="l" color="Neutral9">
          {orderAction.admin?.firstName ??
            intl.formatMessage({
              id: "restaurants.orders.order.timeline.action.modal.user",
              defaultMessage: "User",
            })}
        </Text>

        <Spacer size={8} />

        <Text size="m" color="Neutral7">
          {orderAction.comment ?? "-"}
        </Text>

        <Spacer size={8} />

        <Text size="s" color="Neutral6" textAlign="right">
          {moment(orderAction.actionAt).format(MMM_Do_YYYY_h_mm_ss_a)}
        </Text>
      </StyledMainContainer>
    </Modal>
  )
}

const StyledMainContainer = styled(Container)`
  padding: 0px 16px;
`
