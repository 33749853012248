import type {
  ContentTypeEnum,
  FileExtensionEnum,
  ItemProgressStatusEnum,
  SortingByMenuEnum,
} from "../../../../../shared/graphql/generated/types"
import type { RcFile } from "antd/lib/upload"

export enum FieldTypeEnumItem {
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
  COUNTER = "COUNTER",
}

export enum UploadingImageEnum {
  ITEM = "ITEM",
  VARIANT = "VARIANT",
}

interface IngredientType {
  uuid: string
  name: string
  isMain?: boolean
}

interface ModifierType {
  uuid: string
  ingredient: IngredientType
}

interface VariantModifierType {
  uuid?: string
  price?: number
  calories?: number
  isIncluded?: boolean
  isVisible?: boolean
  isSoldOut?: boolean
  modifier?: ModifierType
  deletedAt?: Date
}

interface modifierGroupType {
  uuid: string
  name: string
  isMain: boolean
}

export interface VariantModifierGroupType {
  uuid?: string
  tempId?: string
  minSelectedOptions?: number
  maxSelectedOptions?: number
  fieldType?: FieldTypeEnumItem
  modifierGroup: modifierGroupType
  variantModifiers?: VariantModifierType[]
  isRequired?: boolean
  deletedAt?: Date
  createdAt?: Date
}

interface AttachmentType {
  uuid: string
  ext: FileExtensionEnum
  fileName: string
  contentType: ContentTypeEnum
  signedUrl: string
}

export interface VariantType {
  uuid?: string
  tempId?: string
  name?: string
  price?: number | null
  sku?: string
  image?: RcFile
  calories?: number | null
  isDefault: boolean
  attachment?: AttachmentType
  isSoldOut?: boolean
  deletedAt?: Date
  variantModifierGroups?: Array<VariantModifierGroupType>
  createdAt?: Date
  sortModifierGroupsBy: SortingByMenuEnum
}

export interface IItemForm {
  uuid: string
  image?: RcFile | string
  name: string
  isMain: boolean
  status: ItemProgressStatusEnum
  lastPublishedAt: Date
  lastSavedAt: Date
  deletedAt?: Date
  hasSnapshot: boolean
  isPublished: boolean
  isSoldOut?: boolean
  defaultVariantId: string
  attachment?: AttachmentType | undefined | null
  description: string
  menus: Array<string>
  variants: Array<VariantType>
  sortVariantsBy: SortingByMenuEnum
  hasInitialData: boolean
  closeForm: boolean
  serves?: number
}

export interface ICorporateVariantModifier {
  uuid: string
  price: number
}
export interface ICorporateModifierGroup {
  uuid: string
  variantModifiers: Array<ICorporateVariantModifier>
}
export interface ICorporateVariant {
  uuid: string
  price: number
  isVisible: boolean
  isSoldOut: boolean
  isDefault: boolean
  variantModifierGroups: Array<ICorporateModifierGroup>
}
export interface ICorporateItemForm {
  uuid: string
  isSoldOut: boolean
  updatedAt: Date
  isVisible: boolean
  variants: Array<ICorporateVariant>
  menus: Array<string>
}
