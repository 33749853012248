import Icon from "../../../../ui/Icon"
import Text from "../../../../ui/Typography/Text"
import { StepStateEnum } from "../../interfaces"
import React from "react"
import type { IBaseThemeColors } from "styled-components"

interface CustomStepState {
  color: keyof Pick<IBaseThemeColors, "Success2" | "Neutral4" | "Neutral3">
  content: (() => React.ReactNode) | ((n: number) => React.ReactNode)
}

type StepStateTypes = `${StepStateEnum}`

type IStateIndicatorProperties = { [key in StepStateTypes]: CustomStepState }

export const StateIndicatorProperties: IStateIndicatorProperties = {
  [StepStateEnum.DONE]: {
    color: "Success2",
    content: () => (
      <Icon size={24} remixiconClass="ri-check-line" color="Success5" />
    ),
  },
  [StepStateEnum.CURRENT]: {
    color: "Neutral4",
    content: (num: number) => (
      <Text size="m" weight="bold" color="Neutral7">
        {num}
      </Text>
    ),
  },
  [StepStateEnum.NEXT]: {
    color: "Neutral3",
    content: (num: number) => (
      <Text size="m" weight="regular" color="Neutral5">
        {num}
      </Text>
    ),
  },
}
