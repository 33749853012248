import { updateGetUsersQuery } from "../../../../../../../shared/graphql/updateQuery/updateGetUsersQuery"
import {
  DEBOUNCE_MILLISECONDS,
  EMPTY_STRING,
  WHITE_SPACE,
} from "../../../../../../../shared/utils/constant/values"
import { canFetchMore } from "../../../../../../../shared/utils/helpers/canFetchMore"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { onPopupScroll } from "../../../../../../../ui/Select/helpers/onPopupScroll"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { customerNameFormatter } from "../../../../utils/customerNameFormatter"
import { isValidUser } from "../../../../utils/isUser"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  ELIGIBLE_CUSTOMER_USERS_FIELD_NAME,
  SET_VALUE_OPTIONS,
  TAKE_QUANTITY,
} from "../../constants"
import { useGetUsersQuery } from "../graphql/getUsers.generated"
import debounce from "lodash/debounce"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"

export const useSpecificCustomer = () => {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IDiscountForm>()

  const isEditMode = getValues(EDIT_MODE_NAME)

  const {
    data,
    refetch: refetchUsers,
    loading,
    fetchMore,
  } = useGetUsersQuery({
    variables: { take: TAKE_QUANTITY },
    skip: isEditMode,
    fetchPolicy: "network-only",
  })

  const [search, setSearch] = useState<string | undefined>()

  const errorMessage = errors?.rules?.eligibleCustomer?.users?.message

  const selectedUsers = watch(ELIGIBLE_CUSTOMER_USERS_FIELD_NAME)

  const hasNextPage = data?.getUsers?.hasNextPage ?? false
  const endCursor = data?.getUsers?.endCursor
  const canFetchMoreUsers = canFetchMore(loading, hasNextPage, endCursor)

  const fetchMoreUsers = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          after: endCursor,
          take: TAKE_QUANTITY,
        },
        updateQuery: updateGetUsersQuery,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor])

  const users =
    data?.getUsers.results?.filter(isValidUser).map((user) => {
      const { lastName, firstName, phone, uuid } = user

      const userFullName = customerNameFormatter({
        lastName,
        firstName,
        phone,
      })

      return {
        value: uuid,
        label: userFullName,
      }
    }) ?? []

  selectedUsers?.forEach((currentUser) => {
    if (!users?.map((user) => user.value).includes(currentUser.uuid))
      users?.push({
        value: currentUser.uuid,
        label: currentUser.label,
      })
  })

  const onUserSelected = (userOptions: string[]) => {
    if (search) {
      setSearch(EMPTY_STRING)
      searchUsers(EMPTY_STRING)
    }

    const formattedUsers = users
      ?.filter((user) => userOptions.includes(user.value))
      .map((filteredUser) => ({
        uuid: filteredUser.value,
        label: filteredUser.label,
      }))

    if (!formattedUsers) return

    setValue(
      ELIGIBLE_CUSTOMER_USERS_FIELD_NAME,
      formattedUsers,
      SET_VALUE_OPTIONS
    )
  }

  const searchUsers = async (user: string) => {
    try {
      await refetchUsers({
        search: user === EMPTY_STRING ? undefined : user,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onDebounceSearchUsers = useMemo(() => {
    return debounce(searchUsers, DEBOUNCE_MILLISECONDS)
  }, [])

  const handleSearchUsers = (user: string) => {
    if (user === WHITE_SPACE) return

    setSearch(user)

    if (!user) {
      onDebounceSearchUsers.cancel()
      searchUsers(user)
      return
    }

    onDebounceSearchUsers(user)
  }
  const onScrollToFetchMore = (
    scrollEvent: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    onPopupScroll(scrollEvent, canFetchMoreUsers, fetchMoreUsers)
  }

  useEffect(() => {
    return () => onDebounceSearchUsers.cancel()
  }, [])

  return {
    users,
    onUserSelected,
    selectedUsers,
    search,
    handleSearchUsers,
    onScrollToFetchMore,
    isEditMode,
    errorMessage,
  }
}
