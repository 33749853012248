import type { GetComboListQueryVariables } from "../../../../../../GraphQL/Queries/getComboList.generated"
import { deleteCombo, saveCombo } from "./combos-cache-methods"
import type { SaveComboCacheType } from "./interfaces"

const saveComboInCache = ({
  data,
  variables,
}: {
  data: SaveComboCacheType
  variables: GetComboListQueryVariables
}) => {
  saveCombo(data, variables)
}

const deleteComboFromCache = ({
  uuid,
  variables,
}: {
  uuid: string
  variables: GetComboListQueryVariables
}) => {
  deleteCombo(uuid, variables)
}

export { saveComboInCache, deleteComboFromCache }
