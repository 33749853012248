export const locationColumns = [
  {
    id: "restaurants.locations.modifiers.datatable.header.name",
    title: "Name",
    width: "28%",
  },
  {
    id: "restaurants.locations.modifiers.datatable.header.status",
    title: "Status",
    width: "12%",
  },
  {
    title: "",
    width: "5%",
  },
  {
    id: "restaurants.locations.modifiers.datatable.header.ownership",
    title: "Ownership",
    width: "10%",
  },
  {
    title: "",
    width: "5%",
  },
]

export const corporateColumns = [
  {
    id: "restaurants.modifiers.datatable.header.name",
    title: "Name",
    width: "80%",
  },

  {
    id: "restaurants.modifiers.datatable.header.status",
    title: "Status",
    width: "20%",
  },
  {
    title: "",
    width: "44px",
  },
  {
    title: "",
    width: "44px",
  },
]
