import * as Types from '../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModifierGroupDetailQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetModifierGroupDetailQuery = { __typename?: 'Query', getModifierGroupDetail: { __typename?: 'RModifierGroupDetail', uuid: string, name: string, isMain: boolean, updatedAt: any, snapshotUpdatedAt?: any | null, publishedAt?: any | null, isPublished: boolean, hasSnapshot: boolean, modifiers: Array<{ __typename?: 'RModifierDetail', uuid: string, isSoldOut?: boolean | null, isVisible?: boolean | null, ingredient: { __typename?: 'RIngredientDetail', uuid: string, name: string, isMain: boolean } }> } };


export const GetModifierGroupDetailDocument = gql`
    query getModifierGroupDetail($uuid: ID!) {
  getModifierGroupDetail(uuid: $uuid) {
    uuid
    name
    isMain
    updatedAt
    snapshotUpdatedAt
    publishedAt
    isPublished
    hasSnapshot
    modifiers {
      uuid
      isSoldOut
      isVisible
      ingredient {
        uuid
        name
        isMain
      }
    }
  }
}
    `;

/**
 * __useGetModifierGroupDetailQuery__
 *
 * To run a query within a React component, call `useGetModifierGroupDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModifierGroupDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModifierGroupDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetModifierGroupDetailQuery(baseOptions: Apollo.QueryHookOptions<GetModifierGroupDetailQuery, GetModifierGroupDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModifierGroupDetailQuery, GetModifierGroupDetailQueryVariables>(GetModifierGroupDetailDocument, options);
      }
export function useGetModifierGroupDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModifierGroupDetailQuery, GetModifierGroupDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModifierGroupDetailQuery, GetModifierGroupDetailQueryVariables>(GetModifierGroupDetailDocument, options);
        }
export type GetModifierGroupDetailQueryHookResult = ReturnType<typeof useGetModifierGroupDetailQuery>;
export type GetModifierGroupDetailLazyQueryHookResult = ReturnType<typeof useGetModifierGroupDetailLazyQuery>;
export type GetModifierGroupDetailQueryResult = Apollo.QueryResult<GetModifierGroupDetailQuery, GetModifierGroupDetailQueryVariables>;