import { EmployeeRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import { DateQueryParam } from "../../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../../shared/hooks/useSearchParams"
import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import { roundUp } from "../../../../../shared/utils/helpers/roundUp"
import { EmployeeRole } from "../../../../Settings/Locations/Employees/types/graphql.enums"
import { lettersName } from "../../../../Settings/Locations/Employees/utils/letters-name"
import { EmployeeTimeEntryType } from "../../types/employee.type"
import { getFullName } from "../../utils/full-name.util"
import { isSomeEnum } from "../../utils/is-some-enum.util"
import { formatTimerDuration } from "../../utils/time-entry-date-format"
import Tag from "../Tag"
import { TagEnum } from "../Tag/config"
import TagTimer from "../TagTimer"
import styles from "./RowEmployee.module.css"
import {
  ActionIcon,
  Avatar,
  Badge,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react"
import moment from "moment-timezone"

interface RowEmployeeProps {
  employee: EmployeeTimeEntryType
  openIndex: number | null
  index: number | null
  setOpenIndex: (index: number | null) => void
}

export const RowEmployee = (props: RowEmployeeProps) => {
  const { employee, openIndex, index, setOpenIndex } = props
  const mantineTheme = useMantineTheme()
  const { getParam } = useSearchParams()
  const endDateParam = getParam(DateQueryParam.end)
  const dateIsToday = moment(endDateParam).isSame(moment(), "day")
  const isAnyEntryRunning =
    employee.timeEntries.some((t) => t.isRunning) && dateIsToday
  const timePassed = formatTimerDuration(employee.totalDurationInSeconds)

  const toggleAccordion = () => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const validRole = isSomeEnum(EmployeeRoleNameEnum)

  const totalAmount = () => {
    if (employee.totalAmount > 0)
      return (
        Math.round((employee.totalAmount + Number.EPSILON) * 100) / 100
      ).toString()

    return "00.00"
  }

  return (
    <tr className={styles["tbody-tr"]}>
      <td width="75%" className={styles["tbody-left-td"]}>
        <Group spacing={12} miw={300}>
          <ThemeIcon size={24} bg="gray.6">
            <Title order={6}>{employee.timeEntries.length}</Title>
          </ThemeIcon>
          {employee.attachment ? (
            <Avatar src={employee.attachment.signedUrl} size={40} />
          ) : (
            <Avatar color="primary.6" size={40} variant="filled">
              {lettersName(getFullName(employee))}
            </Avatar>
          )}
          <Stack align="flex-start" spacing={8}>
            <Text size="sm" fw={600}>
              {getFullName(employee)}
            </Text>
            {validRole(employee.role.name) && (
              <Badge bg="gray.0" radius="sm" color="gray.6">
                {EmployeeRole[employee.role.name]}
              </Badge>
            )}
          </Stack>
        </Group>
      </td>
      <td width="10%" className={styles["tbody-td"]}>
        {isAnyEntryRunning ? (
          <TagTimer
            kind="bySeconds"
            secondsPassed={employee.totalDurationInSeconds}
          />
        ) : (
          <Tag type="TIME" text={timePassed} />
        )}
      </td>
      <td>
        <Tag type={TagEnum.TIP} text={toFormat(roundUp(employee.totalTips))} />
      </td>
      <td width="10%" className={styles["tbody-td"]}>
        <Tag type={TagEnum.AMOUNT} text={totalAmount()} />
      </td>
      <td width="5%" className={styles["tbody-right-td"]}>
        {employee.timeEntries.length > 0 && (
          <ActionIcon w={20} onClick={toggleAccordion}>
            {openIndex === index ? (
              <IconChevronDown size={24} color={mantineTheme.colors.gray[6]} />
            ) : (
              <IconChevronRight size={24} color={mantineTheme.colors.gray[6]} />
            )}
          </ActionIcon>
        )}
      </td>
    </tr>
  )
}
