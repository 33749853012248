import emptyIcon from "../../../../shared/assets/icons/discount-empty-icon.svg"
import { useFilters } from "../../../../shared/contexts/FilterProvider"
import EmptyState from "../../../../ui/EmptyState"
import { AddDiscountButton } from "./"
import { useIntl } from "react-intl"

export const EmptyStateModifiers = () => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  return (
    <EmptyState
      title={intl.formatMessage(
        {
          id: "restaurants.discounts.empty.title",
          defaultMessage: "You don’t have any discounts yet",
        },
        {
          hasSearch: Number(!!search),
          search,
        }
      )}
      description={intl.formatMessage(
        {
          id: "restaurants.discounts.empty.description",
          defaultMessage: "Let’s start by creating your first discount",
        },
        {
          hasSearch: Number(!!search),
        }
      )}
      button={<AddDiscountButton createButton />}
      image={emptyIcon}
      fullScreen
    />
  )
}
