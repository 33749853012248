import AddButton from "../../../../../ui/AddButton"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import Icon from "../../../../../ui/Icon"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import { SoldOutIcon } from "../../../components/SoldOutIcon/SoldOutIcon"
import ModifierSelect from "../ModifierSelect"
import type {
  IModifierGroupForm,
  IngredientType,
  ModifierType,
} from "../hookforms.interfaces"
import React, { useCallback } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export const ModifiersForm: React.FC = () => {
  const intl = useIntl()
  const { control, setValue, watch } = useFormContext<IModifierGroupForm>()

  const {
    append,
    fields: modifiersList,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "modifiers",
  })

  const isCorporate = watch("isMain")

  const onAddModifier = useCallback(() => {
    append({
      uuid: null,
      ingredient: { uuid: "", name: undefined, isMain: false },
      deletedAt: null,
      isVisible: true,
      isSoldOut: false,
    })
  }, [append])

  const removeModifier = (modifier: ModifierType, modifierIndex: number) => {
    const prev = watch("modifiersDelete") ?? []

    if (modifier.ingredient.uuid && modifier.uuid) {
      setValue("modifiersDelete", [
        ...prev,
        { ...modifier, deletedAt: Date.now() },
      ])
    }

    remove(modifierIndex)
  }

  const changeAvailability = (modifierIndex: number) => {
    const prev = watch(`modifiers.${modifierIndex}`)
    update(modifierIndex, { ...prev, isSoldOut: !prev.isSoldOut })
  }

  const changeVisibility = (modifierIndex: number) => {
    const prev = watch(`modifiers.${modifierIndex}`)
    update(modifierIndex, { ...prev, isVisible: !prev.isVisible })
  }

  const setIngredient = (modifierIndex: number, ingredient: IngredientType) => {
    const prev = watch(`modifiers.${modifierIndex}`)

    update(modifierIndex, {
      ...prev,
      uuid: prev.uuid,
      ingredient: { ...prev.ingredient, isMain: ingredient.isMain },
    })
  }

  return (
    <Container display="flex" flexDirection="column" gap="48px 0px">
      <Container>
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.modifiers.group.form.modifier.form.title",
              defaultMessage: "Modifiers",
            })}
          </Text>
          <AddButton onClick={onAddModifier} />
        </Container>

        <Text size="s">
          {intl.formatMessage({
            id: "restaurants.modifiers.group.form.modifier.form.description",
            defaultMessage: "Add modifiers to your modifier group",
          })}
        </Text>
      </Container>

      <Container display="flex" flexDirection="column" gap="28px 0">
        {modifiersList.map((modifier, modifierIndex) => {
          return (
            <Container key={modifier.id}>
              <StyledRowContainer
                id={`modifier-${modifierIndex}`}
                className={`modifier-${modifierIndex}`}
                key={modifierIndex}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="64px"
              >
                <Container className="modifier-item" width="100%">
                  <ModifierSelect
                    modifierIndex={modifierIndex}
                    setIngredient={setIngredient}
                  />
                </Container>
                <Container display="flex" gap="24px" alignItems="center">
                  {!isCorporate && (
                    <>
                      <Button
                        type="button"
                        hierarchy="tertiary"
                        onClick={() => changeVisibility(modifierIndex)}
                        leadingIcon={
                          modifier.isVisible ?? true
                            ? "ri-eye-line"
                            : "ri-eye-off-line"
                        }
                        shape="square"
                        visibility={modifier.ingredient.isMain}
                      />

                      <Button
                        type="button"
                        hierarchy="tertiary"
                        onClick={() => changeAvailability(modifierIndex)}
                        shape="square"
                        visibility={
                          !!watch(`modifiers.${modifierIndex}.ingredient.uuid`)
                        }
                      >
                        <SoldOutIcon
                          isSoldOut={modifier.isSoldOut ?? false}
                          color="Primary5"
                          cursor="pointer"
                        />
                      </Button>
                    </>
                  )}
                  <Button
                    type="button"
                    hierarchy="tertiary"
                    onClick={() => removeModifier(modifier, modifierIndex)}
                    shape="square"
                    className="remove-row-button"
                  >
                    <Icon
                      remixiconClass="ri-close-line"
                      size={24}
                      cursor="pointer"
                      color="Primary5"
                    />
                  </Button>
                </Container>
              </StyledRowContainer>
            </Container>
          )
        })}
      </Container>

      <Spacer size={4} />

      <Container display="flex" gap="16px">
        <Container minWidth="18px" />
        <Container minWidth="28px" className="hidden" />
      </Container>
    </Container>
  )
}

interface StyledRowContainerProps {
  id: string
}

const StyledRowContainer = styled(Container)<StyledRowContainerProps>`
  ${({ id }) => css`
    &.${id} {
      .remove-row-button {
        transition: 0.4s all;
        visibility: hidden;
        opacity: 0;
        &:focus-visible {
          visibility: hidden;
          opacity: 0;
          transition: 0.5s al;
        }
      }

      &:hover {
        .remove-row-button {
          opacity: 1;
          visibility: visible;
        }
        &:focus-visible {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  `}
`
