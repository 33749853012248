import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelCateringOrderMutationVariables = Types.Exact<{
  input: Types.CancelOrderInput;
}>;


export type CancelCateringOrderMutation = { __typename?: 'Mutation', cancelOrder: { __typename?: 'CancelledOrder', status: Types.OrderStatusEnum, uuid: string, paymentStatus: Types.OrderPaymentStatusEnum, paymentMethodType?: Types.PaymentMethodTypeEnum | null } };


export const CancelCateringOrderDocument = gql`
    mutation cancelCateringOrder($input: CancelOrderInput!) {
  cancelOrder(input: $input) {
    status
    uuid
    paymentStatus
    paymentMethodType
  }
}
    `;
export type CancelCateringOrderMutationFn = Apollo.MutationFunction<CancelCateringOrderMutation, CancelCateringOrderMutationVariables>;

/**
 * __useCancelCateringOrderMutation__
 *
 * To run a mutation, you first call `useCancelCateringOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCateringOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCateringOrderMutation, { data, loading, error }] = useCancelCateringOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCateringOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelCateringOrderMutation, CancelCateringOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCateringOrderMutation, CancelCateringOrderMutationVariables>(CancelCateringOrderDocument, options);
      }
export type CancelCateringOrderMutationHookResult = ReturnType<typeof useCancelCateringOrderMutation>;
export type CancelCateringOrderMutationResult = Apollo.MutationResult<CancelCateringOrderMutation>;
export type CancelCateringOrderMutationOptions = Apollo.BaseMutationOptions<CancelCateringOrderMutation, CancelCateringOrderMutationVariables>;