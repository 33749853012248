import Container from "../../../../ui/Container"
import Header from "../../../../ui/Header"
import FilterByDate from "../../../../ui/Header/Filter/FilterByDate"
import Text from "../../../../ui/Typography/Text"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const ReportsHeader = () => {
  const intl = useIntl()

  return (
    <Header
      title={
        <Container
          display="flex"
          alignItems="center"
          gap="8px"
          className="reports-header-title"
        >
          <Text size="m">
            {intl.formatMessage({
              id: "restaurants.reports.title",
              defaultMessage: "Sales",
            })}
          </Text>
        </Container>
      }
      actionsOverflow="visible"
      filter={() => (
        <StyledContainer
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          className="reports-header-calendar"
        >
          <FilterByDate />
        </StyledContainer>
      )}
    />
  )
}

const StyledContainer = styled(Container)`
  margin-left: auto;
`
