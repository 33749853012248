import { useFilters } from "../../../../shared/contexts/FilterProvider"
import {
  RMenuDetail,
  RPublishMenuInput,
  SortingByMenuEnum,
} from "../../../../shared/graphql/generated/types"
import { MenuStatusEnum } from "../../../../shared/graphql/generated/types"
import notification from "../../../../ui/notification"
import type {
  DeleteMenuMutation,
  DeleteMenuMutationVariables,
} from "../GraphQL/deleteMenu.generated"
import { useDeleteMenuMutation } from "../GraphQL/deleteMenu.generated"
import { useGetMenuDetailQuery } from "../GraphQL/getMenuDetail.generated"
import type { HideOrShowMenuMutation } from "../GraphQL/hideOrShowMenu.generated"
import { useHideOrShowMenuMutation } from "../GraphQL/hideOrShowMenu.generated"
import type { PublishDraftMenuMutation } from "../GraphQL/publishDraftMenu.generated"
import { usePublishDraftMenuMutation } from "../GraphQL/publishDraftMenu.generated"
import type { PublishMenuMutation } from "../GraphQL/publishMenu.generated"
import { usePublishMenuMutation } from "../GraphQL/publishMenu.generated"
import type { SaveMenuMutation } from "../GraphQL/saveMenu.generated"
import { useSaveMenuMutation } from "../GraphQL/saveMenu.generated"
import type { UnpublishMenuMutation } from "../GraphQL/unpublishMenu.generated"
import { useUnpublishMenuMutation } from "../GraphQL/unpublishMenu.generated"
import type { IMenuForm } from "../MenuForm/hookforms.interfaces"
import {
  deleteMenuFromCache,
  saveMenuInCache,
} from "../utils/cache-menus/menus-cache-config"
import { omit } from "lodash"
import { useState } from "react"
import { useIntl } from "react-intl"

type UseMenusProps = {
  search?: string
}

export const useMenus = ({ search }: UseMenusProps) => {
  const intl = useIntl()

  const { sort } = useFilters()

  const [loadingGetMenuDetail, setLoadingGetMenuDetail] =
    useState<boolean>(false)

  const { refetch: refetchGetMenuDetail } = useGetMenuDetailQuery({
    skip: true,
  })

  const [saveMenuMutation, menuSaved] = useSaveMenuMutation({
    onCompleted: (result: SaveMenuMutation) => {
      const {
        uuid,
        applyToAllLocations,
        combos,
        hasSnapshot,
        isMain,
        items,
        menuType,
        name,
        status,
      } = result.saveMenu

      const savedMenu = {
        uuid,
        applyToAllLocations,
        combosCount: combos.length,
        hasSnapshot,
        isMain,
        itemsCount: items.length,
        menuType,
        name,
        status,
      }

      saveMenuInCache({
        data: savedMenu,
        variables: {
          name: search,
          sort: SortingByMenuEnum[sort["sorting-by"]],
        },
      })
    },
  })

  const saveMenu = async (
    formData: IMenuForm,
    onSuccess?: (result: SaveMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    const {
      applyToAllLocations,
      isMain,
      menuType,
      name,
      items,
      combos,
      locations,
      uuid,
      sortItemsBy,
    } = formData
    const itemsToSave = items?.map((item) => ({ uuid: item.uuid }))
    const combosToSave = combos?.map((combo) => ({ uuid: combo.uuid }))
    const locationsToSave = locations?.map((location) => ({ uuid: location }))

    try {
      const { data } = await saveMenuMutation({
        variables: {
          input: {
            uuid,
            name,
            applyToAllLocations,
            isMain,
            menuType,
            itemSortBy: SortingByMenuEnum[sortItemsBy],
            locations: applyToAllLocations ? undefined : locationsToSave,
            items: itemsToSave,
            combos: combosToSave,
          },
        },
      })

      if (data?.saveMenu.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishMenuMutation, menuPublished] = usePublishMenuMutation({
    onCompleted: (result) => {
      saveMenuInCache({
        data: omit(result.publishMenu, "__typename"),
        variables: {
          name: search,
          sort: SortingByMenuEnum[sort["sorting-by"]],
        },
      })
    },
  })

  const publishMenu = async (
    publishData: RPublishMenuInput,
    onSuccess?: (result: PublishMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await publishMenuMutation({
        variables: {
          input: {
            ...publishData,
            locations: publishData.locations?.length
              ? publishData.locations
              : undefined,
          },
        },
      })

      if (data?.publishMenu.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [publishMenuDraftMutation, menuDraftPublished] =
    usePublishDraftMenuMutation({
      onCompleted: (result) => {
        saveMenuInCache({
          data: {
            uuid: result.publishDraftMenu.uuid,
            status: MenuStatusEnum.PUBLISHED,
            hasSnapshot: false,
          },
          variables: {
            name: search,
            sort: SortingByMenuEnum[sort["sorting-by"]],
          },
        })
      },
    })

  const publishMenuDraft = async (
    menuUUID?: string,
    onSuccess?: (result: PublishDraftMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      if (!menuUUID) {
        throw new Error(
          intl.formatMessage({
            id: "restaurants.menus.use.menus.publish.menu.error.message",
            defaultMessage: "Menu UUID is required to publish a menu draft",
          })
        )
      }

      const { data } = await publishMenuDraftMutation({
        variables: { uuid: menuUUID },
      })

      if (data?.publishDraftMenu.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [deleteMenuMutation, menuDeleted] = useDeleteMenuMutation({
    onCompleted: (result: DeleteMenuMutation) => {
      deleteMenuFromCache({
        data: { uuid: result.deleteMenu.uuid },
        variables: {
          name: search,
          sort: SortingByMenuEnum[sort["sorting-by"]],
        },
      })
    },
  })

  const deleteMenu = async (
    uuid: DeleteMenuMutationVariables["uuid"],
    onSuccess?: (result: DeleteMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await deleteMenuMutation({
        variables: { uuid },
      })

      if (data?.deleteMenu.uuid) {
        notification({
          title: intl.formatMessage({
            id: "restaurants.menus.use.menus.delete.menu.success.title",
            defaultMessage: "Category deleted",
          }),
          description: intl.formatMessage({
            id: "restaurants.menus.use.menus.delete.menu.success.description",
            defaultMessage: "Your Category was deleted",
          }),
          type: "success",
        })
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const [unpublishMenuMutation, menuUnpublished] = useUnpublishMenuMutation({
    onCompleted: (result) => {
      saveMenuInCache({
        data: {
          uuid: result.unpublishMenu.uuid,
          hasSnapshot: false,
          status: MenuStatusEnum.DRAFT,
        },
        variables: {
          name: search,
          sort: SortingByMenuEnum[sort["sorting-by"]],
        },
      })
    },
  })

  const unpublishMenu = async (
    menuUUID: string,
    onSuccess?: (result: UnpublishMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await unpublishMenuMutation({
        variables: { uuid: menuUUID },
      })

      if (data?.unpublishMenu.uuid) {
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  const getMenuDetail = async (
    menuUUID: string,
    onSuccess?: (result: RMenuDetail) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      setLoadingGetMenuDetail(true)
      const response = await refetchGetMenuDetail({ uuid: menuUUID })

      const data = response.data.getMenuDetail

      if (data) {
        onSuccess?.(data)
        setLoadingGetMenuDetail(false)
      }
    } catch (error) {
      onError?.(error as Error)
      setLoadingGetMenuDetail(false)
    }
  }

  const [showOrHideMenuMutation, menuHiddenOrShown] =
    useHideOrShowMenuMutation()

  const showOrHideMenu = async (
    menuUUID: string,
    isVisible: boolean,
    onSuccess?: (result: HideOrShowMenuMutation) => void,
    onError?: (error: Error) => void
  ) => {
    try {
      const { data } = await showOrHideMenuMutation({
        variables: { uuid: menuUUID },
      })

      if (data?.hideOrShowMenu.uuid) {
        notification({
          title: intl.formatMessage(
            {
              id: "restaurants.menus.use.menus.hide.or.show.menu.success.title",
              defaultMessage:
                "Menu {isVisible,plural,=0 {Visible} other {Hidden}}",
            },
            { isVisible: Number(isVisible) }
          ),
          description: intl.formatMessage(
            {
              id: "restaurants.menus.use.menus.hide.or.show.menu.success.description",
              defaultMessage:
                "This corporate menu is {isVisible,plural,=0 {now visible} other {no longer visible}} to customers in your location.",
            },
            { isVisible: Number(isVisible) }
          ),
          type: "success",
        })
        onSuccess?.(data)
      }
    } catch (error) {
      onError?.(error as Error)
    }
  }

  return {
    saveMenu,
    menuSaved,
    publishMenu,
    menuPublished,
    publishMenuDraft,
    menuDraftPublished,
    deleteMenu,
    menuDeleted,
    unpublishMenu,
    menuUnpublished,
    getMenuDetail,
    loadingGetMenuDetail,
    showOrHideMenu,
    menuHiddenOrShown,
  }
}
