import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRestaurantWhiteLabelConfigQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
}>;


export type GetRestaurantWhiteLabelConfigQuery = { __typename?: 'Query', getRestaurantWhiteLabelConfig: { __typename?: 'RestaurantConfigsModel', uuid: string, whiteLabelConfig?: any | null, attachment?: { __typename?: 'RestaurantAttachmentModel', uuid: string, contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string, type: string } | null } };


export const GetRestaurantWhiteLabelConfigDocument = gql`
    query getRestaurantWhiteLabelConfig($restaurantUUID: ID!) {
  getRestaurantWhiteLabelConfig(restaurantUUID: $restaurantUUID, platform: IOS) {
    uuid
    whiteLabelConfig
    attachment {
      uuid
      contentType
      ext
      fileName
      signedUrl
      type
    }
  }
}
    `;

/**
 * __useGetRestaurantWhiteLabelConfigQuery__
 *
 * To run a query within a React component, call `useGetRestaurantWhiteLabelConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantWhiteLabelConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantWhiteLabelConfigQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *   },
 * });
 */
export function useGetRestaurantWhiteLabelConfigQuery(baseOptions: Apollo.QueryHookOptions<GetRestaurantWhiteLabelConfigQuery, GetRestaurantWhiteLabelConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRestaurantWhiteLabelConfigQuery, GetRestaurantWhiteLabelConfigQueryVariables>(GetRestaurantWhiteLabelConfigDocument, options);
      }
export function useGetRestaurantWhiteLabelConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantWhiteLabelConfigQuery, GetRestaurantWhiteLabelConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRestaurantWhiteLabelConfigQuery, GetRestaurantWhiteLabelConfigQueryVariables>(GetRestaurantWhiteLabelConfigDocument, options);
        }
export type GetRestaurantWhiteLabelConfigQueryHookResult = ReturnType<typeof useGetRestaurantWhiteLabelConfigQuery>;
export type GetRestaurantWhiteLabelConfigLazyQueryHookResult = ReturnType<typeof useGetRestaurantWhiteLabelConfigLazyQuery>;
export type GetRestaurantWhiteLabelConfigQueryResult = Apollo.QueryResult<GetRestaurantWhiteLabelConfigQuery, GetRestaurantWhiteLabelConfigQueryVariables>;