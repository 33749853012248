import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import { InputNumber } from "../../../../../../ui/Inputs"
import Spacer from "../../../../../../ui/Spacer"
import Tag from "../../../../../../ui/Tag"
import { IDiscountForm } from "../../../interfaces/hookforms.interfaces"
import { ErrorBadge, Header } from "../Components"
import {
  LIMIT_PER_CUSTOMER_NAME,
  LIMIT_TO_NAME,
  MAX_INPUT_LENGTH,
} from "../constants"
import { useCollapseOpen } from "../hooks/useCollapseOpen"
import { validationsTitle } from "./validation.title"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export const UsageLimit = () => {
  const { open, handleCollapseChange } = useCollapseOpen()

  const intl = useIntl()

  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<IDiscountForm>()

  const limitPerCustomerErrorMessage =
    errors.rules?.usageLimit?.limitPerCustomer?.message
  const limitToErrorMessage = errors.rules?.usageLimit?.limitedTo?.message

  const hasLimitPerCustomerError = !!limitPerCustomerErrorMessage
  const hasLimitToError = !!limitToErrorMessage

  const limitedTo = safeNumber(watch(LIMIT_TO_NAME))
  const limitPerCustomer = safeNumber(watch(LIMIT_PER_CUSTOMER_NAME))

  const limitedToTagLabel = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.usage.limit.to.label",
      defaultMessage: "{count, plural, =0 {Unlimited} other {{count}}} Uses",
    },
    { count: limitedTo }
  )

  const limitPerCustomerTagLabel = intl.formatMessage(
    {
      id: "restaurants.discounts.forms.rules.usage.limit.per.customer.label",
      defaultMessage:
        "{count, plural, =0 {Unlimited} other {{count}}} Per Customer",
    },
    { count: limitPerCustomer }
  )

  const headerTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.usage.limit.header.title",
    defaultMessage: "Usage Limit",
  })

  const numberOnlyLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.usage.limit.helper.text",
    defaultMessage: "Only numbers are allowed",
  })

  const limitPerCustomerHelperText = hasLimitPerCustomerError
    ? intl.formatMessage(validationsTitle[limitPerCustomerErrorMessage])
    : numberOnlyLabel

  const limitedToHelperText = hasLimitToError
    ? intl.formatMessage(validationsTitle[limitToErrorMessage])
    : numberOnlyLabel

  const limitedToInputLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.usage.limit.to.input.label",
    defaultMessage: "Limited To",
  })

  const limitPerCustomerInputLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.usage.limit.per.customer.input.label",
    defaultMessage: "Limit Per Customer",
  })

  const unlimitedPlaceholder = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.usage.limit.to.placeholder",
    defaultMessage: "Unlimited",
  })

  const usesLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.usage.limit.uses.label",
    defaultMessage: "Uses",
  })

  return (
    <Collapse
      contentBorderColor="Neutral4"
      removeHeaderBottomBorder
      contentBordered
      classId="discount-rule-usage-limit-collapse"
      onChange={handleCollapseChange}
    >
      <CollapsePanel
        key="discount-rule-usage-limit-panel"
        header={
          <Header title={headerTitle}>
            {!open && (
              <Container display="flex" gap="8px">
                {limitedToTagLabel && <Tag>{limitedToTagLabel}</Tag>}
                {limitPerCustomerTagLabel && (
                  <Tag>{limitPerCustomerTagLabel}</Tag>
                )}
              </Container>
            )}
          </Header>
        }
        extra={<ErrorBadge hidden={!hasLimitPerCustomerError} />}
      >
        <Container display="flex" flexDirection="row" width="100%" gap="16px">
          <Container width="100%">
            <Controller
              control={control}
              name={LIMIT_TO_NAME}
              render={({ field }) => (
                <InputNumber
                  maxLength={MAX_INPUT_LENGTH}
                  wrapHelperText={true}
                  value={field.value || undefined}
                  hasError={hasLimitToError}
                  onChange={(value) => {
                    field.onChange(value === 0 ? null : value)
                    trigger(LIMIT_PER_CUSTOMER_NAME)
                  }}
                  label={limitedToInputLabel}
                  placeholder={unlimitedPlaceholder}
                  suffix={usesLabel}
                  helperText={limitedToHelperText}
                />
              )}
            />
          </Container>
          <Container width="100%">
            <Controller
              control={control}
              name={LIMIT_PER_CUSTOMER_NAME}
              render={({ field }) => (
                <InputNumber
                  maxLength={MAX_INPUT_LENGTH}
                  wrapHelperText={true}
                  value={field.value || undefined}
                  hasError={hasLimitPerCustomerError}
                  placeholder={unlimitedPlaceholder}
                  label={limitPerCustomerInputLabel}
                  helperText={limitPerCustomerHelperText}
                  onChange={(value) => {
                    field.onChange(value === 0 ? null : value)
                  }}
                />
              )}
            />
          </Container>
        </Container>
        <Spacer size={30} />
      </CollapsePanel>
    </Collapse>
  )
}
