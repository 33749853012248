import type { TextColor } from "../../../../../../ui/Typography/Text"
import type { IBaseThemeFontWeighProps, IBody } from "styled-components"

type TextProps = {
  size: keyof IBody
  color: TextColor
  weight: keyof IBaseThemeFontWeighProps
}

export const config = {
  comboItemName: (isCorporate: boolean) => {
    if (isCorporate) {
      return { size: "s", weight: "regular", color: "Neutral5" } as TextProps
    }

    if (!isCorporate) {
      return { size: "m", weight: "bold", color: "Primary5" } as TextProps
    }
  },
}
