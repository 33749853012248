import { Box, Group, Text } from "@mantine/core"
import { useMemo } from "react"
import { useIntl } from "react-intl"

type RuleChipProps = {
  data: Array<string>
  disabled?: boolean
  maxLabelDisplay?: number
}
const SEPARATOR = " • "

export const RuleChip = (props: RuleChipProps) => {
  const { data, disabled = false, maxLabelDisplay = 3 } = props
  const intl = useIntl()
  const extra = intl.formatMessage(
    {
      id: "restaurants.settings.location.catering.rules.chip.more.label",
      defaultMessage: "{number} more",
    },
    {
      number: data.length - maxLabelDisplay + 1,
    }
  )
  const title = data.join(SEPARATOR)

  const label = useMemo(() => {
    const isLongArray = data.length > maxLabelDisplay
    const entries = data.slice(
      0,
      isLongArray ? maxLabelDisplay - 1 : data.length
    )

    let labelString = entries.join(SEPARATOR)
    isLongArray && (labelString += SEPARATOR)

    const labelText = (
      <Text size="xs" weight={700} lineClamp={1}>
        {labelString}
      </Text>
    )

    const extraText = isLongArray && (
      <Text size="xs" lineClamp={1}>
        {extra}
      </Text>
    )

    return (
      <>
        {labelText}
        {extraText}
      </>
    )
  }, [data, extra, maxLabelDisplay])

  return (
    <Box
      h={24}
      title={title}
      bg={disabled ? "gray.2" : "primary.0"}
      c={disabled ? "gray.6" : "primary.6"}
      style={{ borderRadius: 4 }}
      display="flex"
      px={16}
    >
      <Group spacing={4} noWrap>
        {label}
      </Group>
    </Box>
  )
}
