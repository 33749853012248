import Login from "../../../pages/Login"
import LoginConfirmation from "../../../pages/Login/Confirmation"
import GoogleCallback from "../../../pages/Login/GoogleCallback"
import LoginError from "../../../pages/Login/LoginError"
import { LoginProvider } from "../../contexts/LoginProvider"
import { useGeneralContext } from "../../contexts/StoreProvider"
import AppSwitch from "../AppSwitch"
import paths from "../paths"
import { GuestRoute } from "./GuestRoute"
import React from "react"
import { Route } from "react-router-dom"

export const GuestRoutes: React.FC = () => {
  const { getMainRoot } = useGeneralContext()

  const { root } = getMainRoot()

  return (
    <GuestRoute homePath={root} path={paths.guest.login}>
      <AppSwitch>
        <LoginProvider>
          <Route exact path={paths.guest.login} component={Login} />
          <Route
            exact
            path={paths.guest.loginConfirmation}
            component={LoginConfirmation}
          />
          <Route
            exact
            path={paths.guest.loginMemberConfirmation}
            component={LoginConfirmation}
          />

          <Route exact path={paths.guest.loginError} component={LoginError} />
          <Route
            exact
            path={paths.guest.loginCallback}
            component={GoogleCallback}
          />
        </LoginProvider>
      </AppSwitch>
    </GuestRoute>
  )
}
