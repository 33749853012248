import { Card, Grid, Group, Skeleton, Stack } from "@mantine/core"

export const StatisticsCardSkeleton = () => {
  return (
    <Grid.Col xs={6} sm={3}>
      <Card mih={168} h="100%" w="100%" withBorder>
        <Stack spacing={48} h="100%">
          <Group position="apart" align="flex-start" noWrap>
            <Skeleton w={80} h={16} />
            <Skeleton w={16} h={16} />
          </Group>
          <Stack spacing={5}>
            <Group spacing={4} align="baseline">
              <Skeleton w={80} h={32} />
            </Group>
          </Stack>
        </Stack>
      </Card>
    </Grid.Col>
  )
}
