import type { RItemVariantModifierGroup } from "../../../../../../../shared/graphql/generated/types"
import Collapse, { CollapsePanel } from "../../../../../../../ui/Collapse"
import Container from "../../../../../../../ui/Container"
import { Text } from "../../../../../../../ui/Typography/Text/Text"
import { HEADER_DATA } from "../../ModifierGroupsForm/utils/constants"
import ModifierGroupDetailContent from "./collapse/ModifierGroupDetailContent"
import ModifierGroupDetailHeader from "./collapse/ModifierGroupDetailHeader"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type ModifierGroupDetailFormProps = {
  variantModifierGroup: RItemVariantModifierGroup
  variantIndex: number
  variantModifierGroupIndex: number
}

export const ModifierGroupDetailForm: React.FC<ModifierGroupDetailFormProps> =
  ({ variantModifierGroup, variantIndex, variantModifierGroupIndex }) => {
    const intl = useIntl()

    return (
      <Collapse
        key={variantModifierGroup.uuid}
        contentBorderColor="Neutral4"
        classId="menu-items-variants-form-collapse"
        removeHeaderBottomBorder
        contentBordered
      >
        <CollapsePanel
          key="variants-form-panel"
          header={
            <ModifierGroupDetailHeader
              variantModifierGroup={variantModifierGroup}
            />
          }
        >
          <>
            <StyledGridContainer>
              {HEADER_DATA.map((header) => (
                <Container
                  key={header.id}
                  justifySelf={header.justifySelf}
                  alignSelf={header.alignSelf}
                >
                  <Text size="s" color="Neutral5">
                    {intl.formatMessage({
                      id: header.id,
                      defaultMessage: header.defaultMessage,
                    })}
                  </Text>
                </Container>
              ))}
            </StyledGridContainer>
            {variantModifierGroup.variantModifiers.map(
              (variantModifier, variantModifierIndex) => (
                <ModifierGroupDetailContent
                  key={variantModifier.uuid}
                  variantModifier={variantModifier}
                  variantIndex={variantIndex}
                  variantModifierGroupIndex={variantModifierGroupIndex}
                  variantModifierIndex={variantModifierIndex}
                />
              )
            )}
          </>
        </CollapsePanel>
      </Collapse>
    )
  }

const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 64px;
  justify-items: start;
  align-items: center;
  grid-gap: 16px;
  margin: 8px 48px 8px;
`
