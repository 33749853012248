import type { GetModifierGroupListQueryVariables } from "../../GraphQL/getModiferGroupList.generated"
import type { SaveModifierGroupCacheType } from "./interfaces"
import {
  deleteModifierGroup,
  saveModifierGroup,
} from "./modifier-group-cache-methods"

const saveModifierGroupInCache = ({
  data,
  variables,
}: {
  data: SaveModifierGroupCacheType
  variables: GetModifierGroupListQueryVariables
}) => saveModifierGroup(data, variables)

const deleteModifierGroupFromCache = ({
  data,
  variables,
}: {
  data: { uuid: string }
  variables: GetModifierGroupListQueryVariables
}) => deleteModifierGroup(data, variables)
export { saveModifierGroupInCache, deleteModifierGroupFromCache }
