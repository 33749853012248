import { LabelInformation } from "../../../../../../../ui/LabelInformation"
import { DeclineOrderModal } from "../../../Modals/DeclineOrderModal"
import { Button, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useIntl } from "react-intl"

interface DeclineCateringOrderProps {
  isPlacedAndRevision: boolean
}

export const DeclineCateringOrder = ({
  isPlacedAndRevision,
}: DeclineCateringOrderProps) => {
  const intl = useIntl()

  const [openedDeclineOrder, { open, close }] = useDisclosure()

  return (
    <Stack spacing={16}>
      <LabelInformation
        label={intl.formatMessage({
          id: "restaurants.catering.detail.card.decline.order.label",
          defaultMessage: "Decline order",
        })}
        labelWeight={600}
        description={intl.formatMessage(
          {
            id: "restaurants.catering.detail.card.order.management.description",
            defaultMessage:
              "If you are enable to handle the order, decline it. Corporate will then assign it to an alternative location. {br} Please be certain before proceeding.",
          },
          {
            br: <br />,
          }
        )}
        descriptionSize="sm"
      />
      <Button
        variant="outline"
        color="yellow.6"
        onClick={open}
        disabled={!isPlacedAndRevision}
      >
        {intl.formatMessage({
          id: "restaurants.catering.detail.card.order.management.decline.order.button",
          defaultMessage: "Decline order",
        })}
      </Button>
      <DeclineOrderModal opened={openedDeclineOrder} onClose={close} />
    </Stack>
  )
}
