import { parsePhoneNumber } from "libphonenumber-js"

//This function was taken from the BE and is used to format the phone number
//the reason is that we have a missing field in the getOneDiscount for the formatted phone number
//we should remove this function once the BE is fixed
//https://linear.app/ravn/issue/API-627/[task][apiv1]-replace-phonenumber-by-formatted-phone-number

export const formatCustomerPhone = (phone: string) => {
  try {
    const phoneNumber = parsePhoneNumber(phone)
    const countryCode = phoneNumber.formatInternational().split(" ")[0]
    const phoneInNationalFormat = phoneNumber
      .formatNational()
      .replaceAll(" ", "-")

    return `${countryCode} ${phoneInNationalFormat}`
  } catch {
    return phone
  }
}
