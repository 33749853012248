import Collapse, { CollapsePanel } from "../../../../../../ui/Collapse"
import Container from "../../../../../../ui/Container"
import Icon from "../../../../../../ui/Icon"
import { StyledAvatar } from "../../../../../../ui/ImageIcon/ImageIcon"
import { InputNumber } from "../../../../../../ui/Inputs"
import HighlightedText from "../../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../../ui/Typography/Text"
import OwnershipTag from "../../../../components/OwnershipTag"
import SoldOutIcon from "../../../../components/SoldOutIcon"
import { config } from "../../forms/SingleItemForm/config"
import { HEADER_DATA } from "../../forms/SingleItemForm/utils/constants"
import type { ComboItemType } from "../../interfaces/hookforms.interfaces"
import classNames from "classnames"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type SingleItemCollapse = {
  title: string
  isCurrentDefault: boolean
  comboItem: ComboItemType
  isDetail?: boolean
  isCorporate: boolean
  onDefaultClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const SingleItemCollapse: React.FC<SingleItemCollapse> = (props) => {
  const {
    isCorporate,
    title,
    isCurrentDefault,
    comboItem,
    onDefaultClick,
    isDetail = false,
  } = props
  const intl = useIntl()

  const defaultLabel = intl.formatMessage({
    id: "restaurants.menu.items.forms.combo.form.default.text",
    defaultMessage: "Default",
  })

  return (
    <ItemContainer padding="0 24px">
      <StyledCollapse
        classId="menu-items-combos-combo-item"
        defaultActiveKey={
          isDetail ? "menu-items-combos-combo-item-panel" : undefined
        }
      >
        <CollapsePanel
          showArrow={false}
          key="menu-items-combos-combo-item-panel"
          header={
            <HeaderContainer
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Text
                size={config.comboItemName(isCorporate)?.size}
                weight={config.comboItemName(isCorporate)?.weight}
                color={config.comboItemName(isCorporate)?.color}
                ellipsis
                title={title}
              >
                {title}
              </Text>
              <Container display="flex" alignItems="center" gap="16px">
                {isDetail ? (
                  <>
                    {isCurrentDefault && (
                      <Text size="m" weight="regular" color="Neutral8">
                        {defaultLabel}
                      </Text>
                    )}
                  </>
                ) : (
                  <HighlightedText
                    size="m"
                    weight="bold"
                    cursor="pointer"
                    onClick={onDefaultClick}
                    className={classNames({
                      "default-combo-item": !isCurrentDefault,
                    })}
                  >
                    {defaultLabel}
                  </HighlightedText>
                )}
                {!isDetail && !isCorporate && (
                  <OwnershipTag isMain={!!comboItem.item.isMain} />
                )}
                <InputNumber
                  width="96px"
                  prefix={intl.formatMessage({
                    id: "generic.symbol.currency",
                    defaultMessage: "$",
                  })}
                  disabled
                  value={
                    comboItem.item.variants?.find(
                      (variant) => variant.isDefault
                    )?.price ?? 0
                  }
                />
                {!isCorporate && (
                  <>
                    <Icon
                      remixiconClass={
                        comboItem?.item.isVisible === null ||
                        comboItem?.item.isVisible
                          ? "ri-eye-line"
                          : "ri-eye-off-line"
                      }
                      size={24}
                      color="Neutral5"
                    />
                    <SoldOutIcon isSoldOut={!!comboItem?.item.isSoldOut} />
                  </>
                )}
              </Container>
            </HeaderContainer>
          }
        >
          <GridHeader includeIcons={false}>
            {HEADER_DATA.map((header) => (
              <Container key={header.id} justifySelf={header.justifySelf}>
                <Text size="s" color="Neutral5">
                  {header.id
                    ? intl.formatMessage({
                        id: header.id,
                        defaultMessage: header.defaultMessage,
                      })
                    : ""}
                </Text>
              </Container>
            ))}
          </GridHeader>
          {
            <Container
              display="flex"
              flexDirection="column"
              gap="20px"
              padding="0 0 8px 0"
            >
              {comboItem.item.variants?.map((variant, index) => (
                <GridContainer key={index} includeIcons={!isCorporate}>
                  <Container display="flex" gap="8px" alignItems="center">
                    {variant.attachment?.signedUrl ? (
                      <StyledAvatar
                        src={variant.attachment?.signedUrl}
                        size={20}
                        shape="square"
                      />
                    ) : (
                      <Icon
                        remixiconClass="ri-image-line"
                        color="Neutral5"
                        size={16}
                      />
                    )}
                    <Text size="s" weight="regular" color="Neutral8">
                      {variant.name}
                    </Text>
                  </Container>
                  {!isCorporate && (
                    <Text
                      size="m"
                      weight="regular"
                      color="Neutral8"
                      hidden={!variant.isDefault}
                    >
                      {defaultLabel}
                    </Text>
                  )}
                  <InputNumber
                    prefix={intl.formatMessage({
                      id: "generic.symbol.currency",
                      defaultMessage: "$",
                    })}
                    value={variant.extraPrice}
                    width="96px"
                    disabled
                  />
                  {isCorporate && (
                    <Text
                      size="m"
                      weight="bold"
                      color="Neutral5"
                      hidden={!variant.isDefault}
                    >
                      {defaultLabel}
                    </Text>
                  )}
                  {!isCorporate && (
                    <>
                      <Icon
                        remixiconClass={
                          variant.isVisible || variant.isVisible === null
                            ? "ri-eye-line"
                            : "ri-eye-off-line"
                        }
                        size={24}
                        color="Neutral5"
                      />
                      <SoldOutIcon isSoldOut={!!variant.isSoldOut} />
                    </>
                  )}
                </GridContainer>
              ))}
            </Container>
          }
        </CollapsePanel>
      </StyledCollapse>
    </ItemContainer>
  )
}

export default SingleItemCollapse

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    &:hover {
      .default-combo-item {
        visibility: visible;
      }
    }

    .default-combo-item {
      visibility: hidden;
    }
  }
`

const ItemContainer = styled(Container)`
  .ant-collapse-header {
    padding: 0 !important;
    border: none !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`

const GridContainer = styled(Container)<{ includeIcons: boolean }>`
  display: grid;
  grid-template-columns: ${({ includeIcons }) =>
    includeIcons ? "1fr 64px 96px auto auto" : "1fr 96px 64px"};
  justify-items: start;
  align-items: center;
  grid-gap: 16px;
  margin: 0 16px;
`

const GridHeader = styled(GridContainer)`
  margin-top: 8px;
  margin-bottom: 12px;
`

const HeaderContainer = styled(Container)`
  height: 48px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.Neutral4};
  padding: 0 16px;
  padding-right: -16px;
`
