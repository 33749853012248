import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import { roundUp } from "../../../../../shared/utils/helpers/roundUp"
import { DeleteEntryModal } from "../../Modals"
import { EditEntryModal } from "../../Modals/EditEntryModal/EditEntryModal"
import { TimeEntryType } from "../../types/employee.type"
import {
  dateRangeHours,
  formatMonthAndDay,
  formatTimerDuration,
} from "../../utils/time-entry-date-format"
import Tag from "../Tag"
import { TagEnum } from "../Tag/config"
import TagTimer from "../TagTimer"
import styles from "./RowDetails.module.css"
import { ActionIcon, Flex, Menu, Text, useMantineTheme } from "@mantine/core"
import {
  IconAlertCircle,
  IconDots,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react"
import { useState } from "react"
import { useIntl } from "react-intl"

interface RowDetailsProps {
  timeEntry: TimeEntryType
}

const TIMER_RUNNING_ALERT_INTL = {
  id: "restaurant.time.entries.list.employees.details.timer.running.alert",
  defaultMessage: "This amount will be re-calculated once the timer stops",
}

const AlertIcon = () => {
  const mantineTheme = useMantineTheme()

  return <IconAlertCircle size={14} color={mantineTheme.colors.yellow[6]} />
}

export const RowDetails = (props: RowDetailsProps) => {
  const { timeEntry } = props
  const intl = useIntl()
  const mantineTheme = useMantineTheme()
  const [openMenu, setOpenMenu] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const isTimerRunning = timeEntry.isRunning
  const timeRunningLabel = isTimerRunning
    ? {
        label: intl.formatMessage(TIMER_RUNNING_ALERT_INTL),
      }
    : undefined

  return (
    <tr className={styles["details-tr"]}>
      <td className={styles["tbody-left-td"]}>
        <Flex gap={8} align="center">
          <Text size="sm" fw={600}>
            {formatMonthAndDay(timeEntry.startDate)}
          </Text>
          <Text size="sm">
            {dateRangeHours(timeEntry.startDate, timeEntry.endDate)}
          </Text>
          <Text size="xs">{timeEntry.notes}</Text>
        </Flex>
      </td>
      <td className={styles["tbody-td"]}>
        {isTimerRunning ? (
          <TagTimer kind="byDate" startDate={timeEntry.startDate} />
        ) : (
          <Tag
            type={TagEnum.TIP}
            text={formatTimerDuration(timeEntry.duration)}
          />
        )}
      </td>

      <td className={styles["tbody-td"]}>
        <Tag
          type={TagEnum.TIP}
          text={toFormat(roundUp(timeEntry.tips))}
          tooltip={timeRunningLabel}
        >
          {isTimerRunning ? <AlertIcon /> : null}
        </Tag>
      </td>

      <td className={styles["tbody-td"]}>
        <Tag
          type={TagEnum.AMOUNT}
          text={roundUp(timeEntry.amount).toString()}
          tooltip={timeRunningLabel}
        >
          {isTimerRunning ? <AlertIcon /> : null}
        </Tag>
      </td>
      <td className={styles["tbody-right-td"]}>
        <Menu
          width={160}
          opened={openMenu}
          onChange={setOpenMenu}
          offset={1}
          position={"bottom-end" ?? "top-end"}
          withArrow
        >
          <Menu.Target>
            <ActionIcon>
              <IconDots size={24} color={mantineTheme.colors.gray[6]} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconEdit size={12} />}
              onClick={() => {
                setOpenEditModal(true)
                setOpenMenu(!openMenu)
              }}
            >
              <Text size="xs">
                {intl.formatMessage({
                  id: "restaurant.time.entries.list.employees.details.menu.edit",
                  defaultMessage: "Edit",
                })}
              </Text>
            </Menu.Item>
            <Menu.Item
              icon={<IconTrash color={mantineTheme.colors.red[6]} size={12} />}
              onClick={() => {
                setOpenDeleteModal(true)
                setOpenMenu(!openMenu)
              }}
            >
              <Text size="xs" c="red.6">
                {intl.formatMessage({
                  id: "restaurant.time.entries.list.employees.details.menu.delete",
                  defaultMessage: "Delete",
                })}
              </Text>
            </Menu.Item>
          </Menu.Dropdown>
          {openDeleteModal && (
            <DeleteEntryModal
              opened={openDeleteModal}
              onClose={() => {
                setOpenDeleteModal(false)
              }}
              deleteData={{
                employeeUUID: timeEntry.employee?.uuid ?? "",
                entryUUID: timeEntry.uuid,
              }}
            />
          )}
          <EditEntryModal
            opened={openEditModal}
            onClose={() => setOpenEditModal(false)}
            entryData={timeEntry}
          />
        </Menu>
      </td>
    </tr>
  )
}
