import styles from "./RuleCollapse.module.css"
import {
  Box,
  Collapse,
  Flex,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  IconAlertCircle,
  IconChevronRight,
  IconChevronUp,
} from "@tabler/icons-react"
import classNames from "classnames"
import { ReactNode } from "react"

type RuleCollapseProps = {
  label: string
  children: React.ReactNode
  disabled?: boolean
  rightSection?: ReactNode
  error?: boolean
}

export const RuleCollapse = (props: RuleCollapseProps) => {
  const {
    children,
    label,
    rightSection,
    disabled = false,
    error = false,
  } = props
  const { colors } = useMantineTheme()
  const [opened, { toggle }] = useDisclosure(false)
  const showRightSection = rightSection && (!opened || disabled)
  const IconChevron = opened ? IconChevronUp : IconChevronRight

  return (
    <Flex gap={4} pos="relative" align="center">
      {error && !opened && (
        <IconAlertCircle
          color={colors.red[6]}
          className={styles["alert-icon"]}
        />
      )}
      <Box
        w="100%"
        bg="gray.0"
        display="flex"
        style={{ borderRadius: 8, flexDirection: "column" }}
      >
        <Group
          position="apart"
          w="100%"
          mih={56}
          p={16}
          noWrap
          onClick={() => !disabled && toggle()}
          className={classNames({ pointer: !disabled })}
        >
          <Text size="sm" weight={600}>
            {label}
          </Text>
          <Group pr={20} noWrap>
            {showRightSection && rightSection}
            <IconChevron size={16} color={colors.gray[6]} />
          </Group>
        </Group>
        <Collapse in={opened && !disabled} pb={16}>
          <Stack mx={16}>{children}</Stack>
        </Collapse>
      </Box>
    </Flex>
  )
}
