import { device } from "../../../../shared/breakpoints/responsive"
import { useOnboardingContext } from "../../../../shared/contexts/OnboardingProvider"
import { AttachmentTypeEnum } from "../../../../shared/graphql/generated/types"
import Container from "../../../../ui/Container"
import Spacer from "../../../../ui/Spacer"
import Text from "../../../../ui/Typography/Text"
import Title from "../../../../ui/Typography/Title"
import type { StepsType } from "../../interfaces"
import RestaurantCard from "../RestaurantCard"
import StepsFooter from "../StepsFooter"
import { indicatorsStates } from "../StepsFooter/config"
import { StepsInfo } from "./config"
import React from "react"
import styled, { css } from "styled-components"

type LeftSideViewProps = {
  step: StepsType
  allowOnboarding: boolean
}

const LeftSideView: React.FC<LeftSideViewProps> = ({
  step,
  allowOnboarding,
}) => {
  const {
    onboarding: {
      name: restaurantName,
      urlIdentifier,
      brandColor,
      logos = [],
      location: { name: locationName = "" },
    },
  } = useOnboardingContext()

  const indicators = allowOnboarding
    ? indicatorsStates.slice(0, 2)
    : indicatorsStates

  return (
    <Container display="flex" flexDirection="column" height="100%">
      <Container minHeight="152px">
        <StyledTitle size="s" weight="bold">
          {StepsInfo[step - 1].title}
        </StyledTitle>
        <Spacer size={8} />
        <StyledText size="m" weight="regular">
          {StepsInfo[step - 1].description}
        </StyledText>
      </Container>
      <Container
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <RestaurantCard
          restaurantName={restaurantName}
          locationName={locationName}
          urlIdentifier={urlIdentifier}
          brandColor={brandColor}
          logo={
            logos.find((logo) => logo.type === AttachmentTypeEnum.LOGO)?.file
          }
        />
      </Container>
      <StepFooterContainer>
        <StepsFooter indicatorsStates={indicators} step={step} />
      </StepFooterContainer>
    </Container>
  )
}

export default LeftSideView

const StyledTitle = styled(Title)`
  ${({ theme }) => {
    const font = theme.text.title

    return css`
      font-size: ${font.s.bold.fontSize}px;

      @media ${device.sm} {
        font-size: ${font.xs.bold.fontSize}px;
        line-height: ${font.xs.bold.lineHeight}px;
      }
    `
  }}
`

const StyledText = styled(Text)`
  ${({ theme }) => {
    const font = theme.text.body

    return css`
      font-size: ${font.m.regular.fontSize}px;

      @media ${device.sm} {
        font-size: ${font.s.regular.fontSize}px;
        line-height: ${font.s.regular.lineHeight}px;
      }
    `
  }}
`
const StepFooterContainer = styled.div`
  display: block;

  @media ${device.lg} {
    display: none;
  }
`
