import yup from "../../../../shared/yup"
import { emptyNumberCleanup } from "../utils/yup-utils"
import { yupResolver } from "@hookform/resolvers/yup"
import { createIntl, createIntlCache } from "react-intl"
import { NumberSchema } from "yup"

const cache = createIntlCache()

const intl = createIntl(
  {
    locale: "en",
    messages: {},
  },
  cache
)

const refundRegex = new RegExp(
  intl.formatMessage({
    id: "restaurants.catering.refund.order.modal.confirm.refund.label.confirmation",
    defaultMessage: "Refund",
  })
)
const cancelRegex = new RegExp(
  intl.formatMessage({
    id: "restaurants.catering.cancel.order.modal.confirm.cancel.label.text",
    defaultMessage: "Cancel",
  })
)

export const CateringRefundSchema = yup.object().shape(
  {
    note: yup.string().required(),
    cardRefund: yup
      .number()
      .transform(emptyNumberCleanup)
      .min(0)
      .when(["$maxRefundCard", "cashRefund"], (...values: Array<unknown>) => {
        const [maxRefundCard, cashRefund, schema] = values as [
          number,
          number,
          NumberSchema
        ]
        if (maxRefundCard) {
          const difference = Number(maxRefundCard - (cashRefund ?? 0))
          const maxRefundableWithCard =
            difference < 0 ? 0 : Number(difference.toFixed(2))
          const maxRefundMessage = intl.formatMessage(
            {
              id: "restaurants.catering.danger.zone.refund.modal.refund.with.card.input.description",
              defaultMessage:
                "Max refundable amount {refundableAmount, number, ::currency/USD}",
            },
            {
              refundableAmount: maxRefundableWithCard,
            }
          )
          return cashRefund
            ? schema.max(maxRefundableWithCard, maxRefundMessage)
            : schema
                .max(maxRefundableWithCard, maxRefundMessage)
                .required()
                .moreThan(0)
        }
        return schema
      }),
    cashRefund: yup
      .number()
      .transform(emptyNumberCleanup)
      .min(0)
      .when(["$maxRefundCash", "cardRefund"], (...values: Array<unknown>) => {
        const [maxRefundCash, cardRefund, schema] = values as [
          number,
          number,
          NumberSchema
        ]
        if (maxRefundCash) {
          const difference = Number(maxRefundCash - (cardRefund ?? 0))
          const maxRefundableWithCash =
            difference < 0 ? 0 : Number(difference.toFixed(2))
          const maxRefundMessage = intl.formatMessage(
            {
              id: "restaurants.catering.danger.zone.refund.modal.refund.with.cash.input.description",
              defaultMessage:
                "Max refundable amount {refundableAmount, number, ::currency/USD}",
            },
            {
              refundableAmount: maxRefundableWithCash,
            }
          )
          return cardRefund
            ? schema.max(maxRefundableWithCash, maxRefundMessage)
            : schema
                .max(maxRefundableWithCash, maxRefundMessage)
                .required()
                .moreThan(0)
        }
        return schema
      }),
    confirmation: yup.string().required().matches(refundRegex),
  },
  [["cardRefund", "cashRefund"]]
)

export const CancelCateringOrderSchema = yup.object().shape({
  note: yup.string().required(),
  confirmation: yup.string().required().matches(cancelRegex),
})

export const CreateCateringCommentSchema = yup.object().shape({
  comment: yup.string().required(),
})
export const CateringRefundResolver = yupResolver(CateringRefundSchema)

export const CancelCateringOrderResolver = yupResolver(
  CancelCateringOrderSchema
)
export const CreateCateringCommentResolver = yupResolver(
  CreateCateringCommentSchema
)
