import { toFormat } from "../../../../../shared/utils/currency/toFormat.dinero"
import Text from "../../../../../ui/Typography/Text"
import type { ICustomerInformation } from "./hookforms.interfaces"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const CustomerInformation: React.FC<ICustomerInformation> = ({
  customerInformation,
}) => {
  const intl = useIntl()

  return (
    <StyledContainerRow role="customer-information-section">
      <StyledContainerCol>
        <StyledCustomerDataContainer>
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.customers.customer.detail.information.name",
              defaultMessage: "Customer Name",
            })}
          </Text>
          <Text size="m">{customerInformation?.firstName || "-"}</Text>
        </StyledCustomerDataContainer>
        <StyledCustomerDataContainer>
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.customers.customer.detail.information.phone",
              defaultMessage: "Phone Number",
            })}
          </Text>
          <Text size="m">{customerInformation?.formattedPhone || "-"}</Text>
        </StyledCustomerDataContainer>
        <StyledCustomerDataContainer>
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.customers.customer.detail.information.locations",
              defaultMessage: "Locations",
            })}
          </Text>
          <Text size="m">
            {customerInformation?.locations
              ?.map((loca) => loca.name)
              .join(", ") || "-"}
          </Text>
        </StyledCustomerDataContainer>
      </StyledContainerCol>

      <StyledContainerCol>
        <StyledCustomerDataContainer>
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.customers.customer.detail.information.total.orders",
              defaultMessage: "Total Orders",
            })}
          </Text>
          <Text size="m">{customerInformation?.totalOrders || "-"}</Text>
        </StyledCustomerDataContainer>
        <StyledCustomerDataContainer>
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.customers.customer.detail.information.life.time.spend",
              defaultMessage: "Lifetime Spend",
            })}
          </Text>
          <Text size="m">
            {toFormat(customerInformation?.lifeTimeSpend ?? 0) || "-"}
          </Text>
        </StyledCustomerDataContainer>

        <StyledCustomerDataContainer>
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.customers.customer.detail.information.current.loyalty.pints",
              defaultMessage: "Current Loyalty Points",
            })}
          </Text>
          <Text size="m">{customerInformation?.loyaltyPoints || "-"}</Text>
        </StyledCustomerDataContainer>
      </StyledContainerCol>
    </StyledContainerRow>
  )
}

const StyledContainerRow = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const StyledContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 8px;
`

const StyledCustomerDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
`
