import Container from "../../../../../../ui/Container"
import Text from "../../../../../../ui/Typography/Text"
import React from "react"

interface HeaderProps {
  children: React.ReactNode
  title: string
}

export const Header: React.FC<HeaderProps> = ({ children, title }) => {
  return (
    <Container
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Text weight="bold">{title}</Text>
      {children}
    </Container>
  )
}
