import { IntlFormattersType } from "../../../../../../shared/types"

export const eligibleCustomerOptionsTitle: IntlFormattersType = {
  EVERYONE: {
    defaultMessage: "Everyone",
    id: "restaurants.discounts.forms.rules.eligible.customer.everyone.option",
  },
  SPECIFIC: {
    defaultMessage: "Specific Customer(s)",
    id: "restaurants.discounts.forms.rules.eligible.customer.specific.option",
  },
  SPEND: {
    defaultMessage: "Lifetime Spend Availability",
    id: "restaurants.discounts.forms.rules.eligible.customer.spend.option",
  },
}
