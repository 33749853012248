import type {
  GetItemListQuery,
  GetItemListQueryVariables,
} from "../../../../../../GraphQL/Queries/getItemList.generated"
import { GetItemListDocument } from "../../../../../../GraphQL/Queries/getItemList.generated"
import type { ApolloCache } from "@apollo/client"

type ReadCacheGetItemListQuery = {
  cache: ApolloCache<unknown>
  variables: GetItemListQueryVariables
}

const readGetItemListQueryCache = ({
  cache,
  variables,
}: ReadCacheGetItemListQuery) =>
  cache.readQuery<GetItemListQuery, GetItemListQueryVariables>({
    query: GetItemListDocument,
    variables,
  })

export default readGetItemListQueryCache
