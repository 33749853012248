import { useGetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import OrdersFilterContent from "../OrdersFilterContent"
import type { ILocationModel } from "./filterByLocation.interfaces"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useMemo, useState } from "react"

interface FilterByLocationProps {
  filterLabel: string
  clearFilterOptions: () => void
}

export const FilterByLocation: React.FC<FilterByLocationProps> = ({
  filterLabel,
  clearFilterOptions,
}) => {
  const [filteredLocations, setFilteredLocations] = useState<ILocationModel[]>()
  const [locationName, setLocationName] = useState<string | undefined>()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const { loading: isFetchingLocations, refetch } =
    useGetAllLocationsByUserQuery({
      variables: { restaurantUUID, userUUID },
      notifyOnNetworkStatusChange: true,
      returnPartialData: true,
      skip: !restaurantUUID || !userUUID,
      fetchPolicy: "no-cache",
      onCompleted: ({ getAllLocationsByUser }) => {
        setFilteredLocations(
          getAllLocationsByUser.results?.map(
            ({ uuid, name, address, orderTypes }) => {
              return {
                uuid,
                name,
                address,
                orderTypes,
              }
            }
          )
        )
      },
    })

  const searchLocation = useCallback(
    (name: string) => {
      try {
        setLocationName(name)

        refetch({
          restaurantUUID,
          userUUID,
          name: name === "" ? undefined : name,
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    },
    [restaurantUUID, userUUID, refetch]
  )

  const searchLocationsDebounce = useMemo(
    () => debounce(searchLocation, 1000),
    [searchLocation]
  )

  const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (locationName !== event.target.value) {
      searchLocationsDebounce(event.target.value)
    }
  }

  useEffect(() => {
    if (restaurantUUID) {
      refetch()
    }
  }, [restaurantUUID, refetch])

  return (
    <OrdersFilterContent
      filterLabel={filterLabel}
      onSearchTermChange={onSearchTermChange}
      filterKey="location"
      loading={isFetchingLocations}
      results={filteredLocations?.map((location) => ({
        value: location.uuid,
        name: location.name,
      }))}
      clearSearch={clearFilterOptions}
    />
  )
}
