import type { GetItemListQuery } from "../../../../GraphQL/Queries/getItemList.generated"
import { useGetItemListQuery } from "../../../../GraphQL/Queries/getItemList.generated"
import emptyIcon from "../../../../shared/assets/icons/empty_icon.svg"
import { useFilters } from "../../../../shared/contexts/FilterProvider"
import { useRestaurantCompletionStepsContext } from "../../../../shared/contexts/RestaurantCompletionStepsProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { ItemProgressStatusEnum } from "../../../../shared/graphql/generated/types"
import { updateGetItemListQuery } from "../../../../shared/graphql/updateQuery/updateGetItemListQuery"
import { useBreadcrumb } from "../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../shared/routes/paths"
import Container from "../../../../ui/Container"
import EmptyState from "../../../../ui/EmptyState"
import Icon from "../../../../ui/Icon"
import RetryPage from "../../../../ui/RetryPage"
import Table from "../../../../ui/Table"
import BodyCell from "../../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../../ui/Table/EmptyStateCell"
import type { ColumProps } from "../../../../ui/Table/Table"
import Tooltip from "../../../../ui/Tooltip"
import OwnershipTag from "../../components/OwnershipTag"
import StatusBadge from "../../components/StatusBadge"
import AddItemButton from "./components/AddItemButton"
import DropdownItem from "./components/DropdownItem"
import { itemsCorporateColumns, itemsLocationColumns } from "./utils/columns"
import get from "lodash/get"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"

type ItemsProps = {
  isCorporate?: boolean
}

const Items: React.FC<ItemsProps> = ({ isCorporate = true }) => {
  const intl = useIntl()
  const { pushBreadcrumb } = useBreadcrumb()
  const { hasItemsCombosSetupCompleted, updateRestaurantCompletionSteps } =
    useRestaurantCompletionStepsContext()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const columns: Array<ColumProps> = useMemo(
    () =>
      isCorporate
        ? itemsCorporateColumns.map((column) => ({
            ...column,
            title:
              column.title &&
              intl.formatMessage({
                id: column?.id,
                defaultMessage: column.title,
              }),
          }))
        : itemsLocationColumns.map((column) => ({
            ...column,
            title:
              column.title &&
              intl.formatMessage({
                id: column?.id,
                defaultMessage: column.title,
              }),
          })),
    [intl, isCorporate]
  )

  const { data, loading, error, refetch, fetchMore } = useGetItemListQuery({
    variables: { name: search },
    skip: !currentRestaurantUUID,
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    fetchPolicy: "network-only",
    onCompleted: ({ getItemList }) => {
      const hasPublishedMenuItems = getItemList.results?.some(
        ({ status }) => status === ItemProgressStatusEnum.PUBLISHED
      )

      if (hasItemsCombosSetupCompleted !== hasPublishedMenuItems) {
        updateRestaurantCompletionSteps({
          hasItemsCombosSetupCompleted: hasPublishedMenuItems,
        })
      }
    },
  })

  const allItems = data?.getItemList?.results ?? []
  const hasNextPage = get(data, "getItemList.hasNextPage", false)
  const endCursor = get(data, "getItemList.endCursor", undefined)

  const retry = () => refetch()

  const fetchMoreItems = useCallback(() => {
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prev: GetItemListQuery, { fetchMoreResult }) =>
        updateGetItemListQuery(prev, fetchMoreResult),
    })
  }, [endCursor, fetchMore])

  const onItemClick = (uuid: string) => {
    const path = isCorporate
      ? paths.restaurants.editProduct(uuid)
      : paths.restaurants.locations.editItemByLocation(locationUUID, uuid)

    return pushBreadcrumb(path, {
      currentPageName: intl.formatMessage({
        id: "restaurants.menu.items.list.current.page.name",
        defaultMessage: "Items",
      }),
    })
  }

  if (error) {
    return <RetryPage error={error} reload={retry} />
  }

  return (
    <Container background="Neutral0">
      <Table
        top="48px"
        columns={columns}
        loading={loading}
        error={error}
        errorRefetch={retry}
        fetchMore={fetchMoreItems}
        hasNextPage={hasNextPage}
        leftSpace={44}
        isArrayEmpty={!allItems?.length}
        emptyState={
          <EmptyStateCell colSpan={columns.length}>
            <EmptyState
              title={intl.formatMessage({
                id: "restaurants.menu.items.list.empty.title",
                defaultMessage: "You don’t have any item yet",
              })}
              description={intl.formatMessage({
                id: "restaurants.menu.items.list.empty.description",
                defaultMessage: "What would you want to do next?",
              })}
              button={<AddItemButton isCorporate={isCorporate} asButton />}
              image={emptyIcon}
              fullScreen
            />
          </EmptyStateCell>
        }
      >
        {allItems?.map((item) => {
          return (
            <tr
              className="styled-data-table-row"
              key={item.uuid}
              onClick={() => onItemClick(item.uuid)}
            >
              <BodyCell>{`${item.name || ""}`}</BodyCell>
              <BodyCell>
                <StatusBadge status={item.status} />
              </BodyCell>
              <BodyCell>
                {item.hasSnapshot &&
                item.status !== ItemProgressStatusEnum.DRAFT ? (
                  <Tooltip
                    width={175}
                    title={intl.formatMessage({
                      id: "restaurants.menu.items.list.datatable.body.tooltip",
                      defaultMessage: "This menu item has unpublished changes",
                    })}
                    placement="bottom"
                    visible
                  >
                    <Icon
                      remixiconClass="ri-error-warning-line"
                      cursor="pointer"
                      color="Warning4"
                      size={20}
                    />
                  </Tooltip>
                ) : (
                  <Container width="22px" />
                )}
              </BodyCell>
              {!isCorporate && (
                <>
                  <BodyCell>
                    <OwnershipTag isMain={item.isMain} />
                  </BodyCell>
                  <BodyCell>
                    {item.isMain && (
                      <Container
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon
                          remixiconClass={
                            item.isVisible ?? true
                              ? "ri-eye-line"
                              : "ri-eye-off-line"
                          }
                          size={24}
                          color="Neutral5"
                        />
                      </Container>
                    )}
                  </BodyCell>
                </>
              )}
              <BodyCell>
                {(isCorporate || !item.isMain) && item?.name && (
                  <DropdownItem
                    itemUUID={item.uuid}
                    itemName={item.name}
                    actions={
                      item.status === ItemProgressStatusEnum.PUBLISHED
                        ? ["UNPUBLISH", "DUPLICATE", "DELETE"]
                        : ["PUBLISH", "DUPLICATE", "DELETE_DRAFT"]
                    }
                    isCorporate={isCorporate}
                  />
                )}
              </BodyCell>
            </tr>
          )
        })}
      </Table>
    </Container>
  )
}

export default Items
