import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishModifierGroupMutationVariables = Types.Exact<{
  input: Types.RPublishModifierGroupInput;
}>;


export type PublishModifierGroupMutation = { __typename?: 'Mutation', publishModifierGroup: { __typename?: 'RPublishedModifierGroup', name: string, uuid: string, isMain: boolean, isPublished: boolean } };


export const PublishModifierGroupDocument = gql`
    mutation publishModifierGroup($input: RPublishModifierGroupInput!) {
  publishModifierGroup(input: $input) {
    name
    uuid
    isMain
    isPublished
  }
}
    `;
export type PublishModifierGroupMutationFn = Apollo.MutationFunction<PublishModifierGroupMutation, PublishModifierGroupMutationVariables>;

/**
 * __usePublishModifierGroupMutation__
 *
 * To run a mutation, you first call `usePublishModifierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishModifierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishModifierGroupMutation, { data, loading, error }] = usePublishModifierGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishModifierGroupMutation(baseOptions?: Apollo.MutationHookOptions<PublishModifierGroupMutation, PublishModifierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishModifierGroupMutation, PublishModifierGroupMutationVariables>(PublishModifierGroupDocument, options);
      }
export type PublishModifierGroupMutationHookResult = ReturnType<typeof usePublishModifierGroupMutation>;
export type PublishModifierGroupMutationResult = Apollo.MutationResult<PublishModifierGroupMutation>;
export type PublishModifierGroupMutationOptions = Apollo.BaseMutationOptions<PublishModifierGroupMutation, PublishModifierGroupMutationVariables>;