import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryIntegrationsByRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetDeliveryIntegrationsByRestaurantQuery = { __typename?: 'Query', getDeliveryIntegrationsByRestaurant: Array<{ __typename?: 'DeliveryIntegrationWithLocationsModel', uuid: string, integrationLabel: string, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, relatedLocations?: Array<{ __typename?: 'LocationWithDeliveryDefaultModel', uuid: string, name: string, isDefault: boolean }> | null }> };


export const GetDeliveryIntegrationsByRestaurantDocument = gql`
    query getDeliveryIntegrationsByRestaurant($restaurantUUID: ID!, $locationUUID: ID) {
  getDeliveryIntegrationsByRestaurant(
    restaurantUUID: $restaurantUUID
    locationUUID: $locationUUID
  ) {
    uuid
    integrationLabel
    integrationName
    isActive
    issues
    relatedLocations {
      uuid
      name
      isDefault
    }
  }
}
    `;

/**
 * __useGetDeliveryIntegrationsByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetDeliveryIntegrationsByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryIntegrationsByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryIntegrationsByRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetDeliveryIntegrationsByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryIntegrationsByRestaurantQuery, GetDeliveryIntegrationsByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryIntegrationsByRestaurantQuery, GetDeliveryIntegrationsByRestaurantQueryVariables>(GetDeliveryIntegrationsByRestaurantDocument, options);
      }
export function useGetDeliveryIntegrationsByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryIntegrationsByRestaurantQuery, GetDeliveryIntegrationsByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryIntegrationsByRestaurantQuery, GetDeliveryIntegrationsByRestaurantQueryVariables>(GetDeliveryIntegrationsByRestaurantDocument, options);
        }
export type GetDeliveryIntegrationsByRestaurantQueryHookResult = ReturnType<typeof useGetDeliveryIntegrationsByRestaurantQuery>;
export type GetDeliveryIntegrationsByRestaurantLazyQueryHookResult = ReturnType<typeof useGetDeliveryIntegrationsByRestaurantLazyQuery>;
export type GetDeliveryIntegrationsByRestaurantQueryResult = Apollo.QueryResult<GetDeliveryIntegrationsByRestaurantQuery, GetDeliveryIntegrationsByRestaurantQueryVariables>;