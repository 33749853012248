import { useFilters } from "../../../../../../../shared/contexts/FilterProvider"
import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { useCombos } from "../../../hooks/useCombos"
import React from "react"
import { useIntl } from "react-intl"

interface UnpublishComboModalProps {
  comboUUID: string
  onOk: () => void
  onCancel: () => void
  isCorporate?: boolean
}

export const UnpublishComboModal: React.FC<UnpublishComboModalProps> = ({
  comboUUID,
  onOk,
  onCancel,
  isCorporate,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { unpublishCombo, comboUnpublished } = useCombos({
    search,
  })

  const onUnpublish = async () => {
    await unpublishCombo(comboUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      wrapClassName="non__full__modal__wrap"
      title={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.unpublish.combo.title",
        defaultMessage: "Unpublish combo",
      })}
      subtitle={intl.formatMessage(
        {
          id: "restaurants.menu.items.combos.components.modals.unpublish.combo.subtitle",
          defaultMessage:
            "This combo will stop being visible to {isCorporate, plural, =1 {all your locations} other {customers}}. ",
        },
        { isCorporate: Number(isCorporate) }
      )}
      loading={comboUnpublished.loading}
      onConfirm={onUnpublish}
      onCancel={onCancel}
      type="warning"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.unpublish.combo.confirm.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.unpublish.combo.cancel.button",
        defaultMessage: "Unpublish",
      })}
      visible
      centered
    />
  )
}
