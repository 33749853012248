import Background from "../../../components/Background"
import iconLogo from "../../../shared/assets/images/color_logo_icon.png"
import { useLoginContext } from "../../../shared/contexts/LoginProvider"
import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import { requestState, useAsync } from "../../../shared/hooks/useAsync"
import paths from "../../../shared/routes/paths"
import regexOnlyNumber from "../../../shared/utils/helpers/regexOnlyNumbers"
import Button from "../../../ui/Button"
import { NotificationErrorStyles } from "../../../ui/ErrorList"
import Form from "../../../ui/Form"
import ImageIcon from "../../../ui/ImageIcon"
import Input from "../../../ui/Inputs"
import QuestionButton from "../../../ui/QuestionButton"
import HighlightedText from "../../../ui/Typography/HighlightedText"
import Text from "../../../ui/Typography/Text"
import Title from "../../../ui/Typography/Title"
import Slider from "../../../ui/animations/Slider"
import type { TokenResponseType } from "../api"
import { validateCode, validateMemberCode } from "../api"
import type { Input as AntdInput } from "antd"
import { notification } from "antd"
import range from "lodash/range"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Link, useLocation } from "react-router-dom"
import styled, { css } from "styled-components"

interface ILoginConfirmationFields {
  confirmationCode: string
}

export const Confirmation: React.FC = () => {
  const { guest } = paths
  const { initRestaurants } = useGeneralContext()

  const { handleSubmit, register, unregister, watch, setValue } =
    useForm<ILoginConfirmationFields>({
      defaultValues: { confirmationCode: "" },
    })
  const [showCodeInput, setShowCodeInput] = useState(false)

  const {
    state: { email },
  } = useLoginContext()
  const inputRef = useRef<AntdInput>()

  const intl = useIntl()

  const { search, pathname } = useLocation()
  const token = queryString.parse(search)?.c?.toString() || ""

  const confirmationCodeWatcher = watch("confirmationCode") || ""
  const confirmationCodeArray = range(5)
  const confirmationCodeLength = confirmationCodeArray.length
  const codeValues = confirmationCodeWatcher.split("")
  const codeValuesLength = codeValues.length
  const hideInput = codeValuesLength === confirmationCodeLength
  const selectedIndex =
    codeValuesLength < confirmationCodeLength
      ? codeValuesLength
      : confirmationCodeLength - 1

  const validateInviteCode = useCallback(() => {
    if (pathname === guest.loginConfirmation) {
      return validateCode({
        code: confirmationCodeWatcher,
      })
    } else if (pathname === guest.loginMemberConfirmation) {
      return validateMemberCode({
        code: confirmationCodeWatcher,
      })
    }

    return Promise.reject()
  }, [
    confirmationCodeWatcher,
    guest.loginConfirmation,
    guest.loginMemberConfirmation,
    pathname,
  ])

  const { execute, status, error } = useAsync(() => validateInviteCode())

  const displayCodeEntry = () => {
    setShowCodeInput(true)
  }

  const onSubmitCode = async () => {
    if (confirmationCodeWatcher) {
      const result: TokenResponseType = await execute()

      if (result) {
        await initRestaurants(result)
      }
    }
  }

  const onClickInputWrapper = () => {
    inputRef.current?.focus()
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event

    if (confirmationCodeWatcher.length >= confirmationCodeLength) return

    if (!value || regexOnlyNumber(value)) {
      setValue(
        "confirmationCode",
        (confirmationCodeWatcher + value).slice(0, confirmationCodeLength)
      )
    }
  }

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace") {
      setValue("confirmationCode", confirmationCodeWatcher.slice(0, -1))
    }
  }

  useEffect(() => {
    const loadParameterCode = () => {
      if (
        regexOnlyNumber(token) &&
        token.split("").length === confirmationCodeLength
      ) {
        setValue("confirmationCode", token)
        setShowCodeInput(true)
      }
    }
    loadParameterCode()
  }, [confirmationCodeLength, setValue, token])

  useEffect(() => {
    register("confirmationCode")

    return () => {
      unregister("confirmationCode")
    }
  }, [register, unregister])

  useEffect(() => {
    if (showCodeInput) {
      onClickInputWrapper()
    }
  }, [showCodeInput])

  useEffect(() => {
    if (error && status == requestState.FULFILLED) {
      notification.error({
        message: "Invalid code",
        description: "Please try again or resend your code",
        style: NotificationErrorStyles,
      })
    }
  }, [error, status])

  return (
    <StyledLoginConfirmation>
      <Background />
      {email ? (
        <React.Fragment>
          <ImageIcon
            imagefit="contain"
            src={iconLogo}
            subtitle={
              <Title size="s" weight="bold">
                <FormattedMessage
                  id="login.confirmation.title"
                  defaultMessage="Check your email"
                />
              </Title>
            }
          />
          <div className="message-wrapper">
            <Text size="l" textAlign="center">
              <div>
                <FormattedMessage
                  id="login.confirmation.message.send"
                  defaultMessage="We've sent you a temporary login link."
                />
              </div>
              <div>
                <FormattedMessage
                  id="login.confirmation.message.verification"
                  defaultMessage="Please enter your verification code"
                />{" "}
                <HighlightedText weight="bold" size="l" color="Neutral8">
                  {email}
                </HighlightedText>
              </div>
            </Text>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ImageIcon
            src={iconLogo}
            subtitle={
              <Title size="s" weight="bold">
                {intl.formatMessage({
                  id: "login.confirmation.title",
                  defaultMessage: "Check your email",
                })}
              </Title>
            }
          />
          <div className="message-wrapper">
            <Text size="l">
              <div>
                {intl.formatMessage({
                  id: "login.confirmation.message.verification",
                  defaultMessage: "Please enter your verification code",
                })}
              </div>
            </Text>
          </div>
        </React.Fragment>
      )}
      <StyledLoginConfirmationFormWrapper>
        <Form onSubmit={handleSubmit(onSubmitCode)} withButton={false}>
          <Slider
            isOpen={showCodeInput}
            setIsOpen={displayCodeEntry}
            offsetY={-62}
          >
            <StyledInputWrapper
              className="input-wrapper"
              onClick={onClickInputWrapper}
              selectedIndex={selectedIndex}
              hideInput={hideInput}
            >
              {confirmationCodeArray.map((digit) => (
                <StyledCodeDigitCard
                  data-testid={`code-value-${digit}`}
                  key={digit}
                >
                  {codeValues[digit]}
                </StyledCodeDigitCard>
              ))}

              <Input
                data-testid="code-confirmation-input"
                size="large"
                className="login-input"
                inputWrapperClassName="login-input-wrapper"
                value={
                  selectedIndex === confirmationCodeLength - 1
                    ? codeValues[confirmationCodeLength - 1]
                    : ""
                }
                ref={inputRef}
                onChange={onInputChange}
                onKeyUp={onKeyUp}
              />
            </StyledInputWrapper>

            <Button
              className="submit-button"
              size="large"
              hidden={process.env.NODE_ENV !== "development"}
              disabled={
                showCodeInput &&
                !(confirmationCodeWatcher.length === confirmationCodeLength)
              }
              hierarchy={showCodeInput ? "primary" : "secondary"}
              type={showCodeInput ? "submit" : "button"}
              loading={status === requestState.LOADING}
              width="360px"
              title={
                showCodeInput
                  ? intl.formatMessage({
                      id: "login.confirmation.button.code",
                      defaultMessage: "Submit code",
                    })
                  : intl.formatMessage({
                      id: "login.confirmation.button.manually",
                      defaultMessage: "Enter code manually",
                    })
              }
            />

            <Link to={paths.guest.login}>
              <Button
                className="back-button"
                hierarchy="tertiary"
                size="large"
                width="360px"
                title={intl.formatMessage({
                  id: "login.confirmation.back",
                  defaultMessage: "Back to Login",
                })}
              />
            </Link>
          </Slider>
        </Form>
      </StyledLoginConfirmationFormWrapper>
      <QuestionButton />
    </StyledLoginConfirmation>
  )
}

const StyledLoginConfirmation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .message-wrapper {
    margin-top: 24px;
  }
`

const StyledLoginConfirmationFormWrapper = styled.div`
  margin-top: 24px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-wrapper {
      height: 40px;
    }

    .submit-button,
    .back-button {
      margin-top: 24px;
    }
  }
`

const StyledCodeDigitCard = styled.div`
  width: 56px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ theme }) =>
    css`
      border: 1px solid ${theme.colors["Neutral4"]};
      background-color: ${theme.colors["Neutral1"]};
    `}

  &:not(:first-child) {
    margin-left: 20px;
  }
`

const StyledInputWrapper = styled.div<{
  selectedIndex: number
  hideInput: boolean
}>`
  position: relative;
  display: flex;

  .input-wrapper {
    position: absolute;
    width: 56px;
    top: 0;
    bottom: 0;
    left: ${({ selectedIndex }) =>
      `${selectedIndex * 56 + selectedIndex * 20}px`};
  }

  & .login-input {
    opacity: ${({ hideInput }) => (hideInput ? 0 : 1)};
    text-align: center;
  }

  .login-input-wrapper {
    position: absolute;
  }
`
