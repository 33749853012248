import type {
  GetComboListQuery,
  GetComboListQueryVariables,
} from "../../../../../../GraphQL/Queries/getComboList.generated"
import { graphqlClient } from "../../../../../../shared/graphql/client"
import readGetComboListQueryCache from "./combos-cache-read"
import writeGetComboListQueryCache from "./combos-cache-write"
import type { SaveComboCacheType } from "./interfaces"

const cache = graphqlClient.cache

//INFO: This method validates whether or not the combo exists in the cache and accordingly adds the combo to the cache or updates it.
export const saveCombo = (
  data: SaveComboCacheType,
  variables: GetComboListQueryVariables
) => {
  const comboListQuery = readGetComboListQueryCache({
    cache,
    variables,
  })

  const combos = comboListQuery?.getComboList.results

  if (combos) {
    const comboListUpdated = combos.map((combo) =>
      data.uuid === combo.uuid ? { ...combo, ...data } : combo
    )
    const comboExists = comboListUpdated.some(
      (updateCombo) => updateCombo.uuid === data.uuid
    )

    if (!comboExists) {
      comboListUpdated.push({
        uuid: data.uuid,
        name: data.name,
        isVisible: data.isVisible,
        hasSnapshot: data.hasSnapshot ?? false,
        isMain: data.isMain ?? false,
        isPublished: data.isPublished ?? false,
      })
    }

    const query: GetComboListQuery = {
      getComboList: {
        ...comboListQuery?.getComboList,
        results: comboListUpdated,
      },
    }

    writeGetComboListQueryCache({ cache, query, variables })
  }
}

//INFO: This method removes the combo from the cache
export const deleteCombo = (
  comboUUID: string,
  variables: GetComboListQueryVariables
) => {
  const comboListQuery = readGetComboListQueryCache({
    cache,
    variables,
  })

  const combos = comboListQuery?.getComboList.results

  if (combos) {
    const filteredCombos = combos.filter((combo) => combo.uuid !== comboUUID)

    const query: GetComboListQuery = {
      getComboList: {
        ...comboListQuery.getComboList,
        results: filteredCombos,
      },
    }
    writeGetComboListQueryCache({ cache, query, variables })
  }
}
