import { useFilters } from "../../../../../../../shared/contexts/FilterProvider"
import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { useCombos } from "../../../hooks/useCombos"
import React from "react"
import { useIntl } from "react-intl"

type DeleteDraftComboModalProps = {
  comboUUID: string
  onOk: () => void
  onCancel: () => void
}

export const DeleteDraftComboModal: React.FC<DeleteDraftComboModalProps> = ({
  comboUUID,
  onOk,
  onCancel,
}) => {
  const intl = useIntl()
  const { getSearchQueryFilter } = useFilters()

  const search = getSearchQueryFilter()

  const { deleteCombo, comboDeleted } = useCombos({ search })

  const onDelete = async () => {
    deleteCombo(comboUUID, onOk, showGraphqlErrors)
  }

  return (
    <ConfirmationModal
      centered
      wrapClassName="non__full__modal__wrap"
      visible
      title={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.draft.combo.title",
        defaultMessage: "Delete combo",
      })}
      subtitle={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.draft.combo.subtitle",
        defaultMessage: "This action cannot be undone.",
      })}
      loading={comboDeleted.loading}
      onConfirm={onDelete}
      onCancel={onCancel}
      type="danger"
      cancelButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.draft.combo.cancel.button",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={intl.formatMessage({
        id: "restaurants.menu.items.combos.components.modals.delete.draft.combo.confirm.button",
        defaultMessage: "Delete",
      })}
    />
  )
}
