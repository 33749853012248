const lookup: Array<{ value: number; symbol: string }> = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "k" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "G" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
]

export const formatQuantity = (
  num: number,
  digits: number,
  withNegativeSymbol = false
) => {
  const isNegative = num < 0
  const value = Math.abs(num)
  const prefixSymbol = withNegativeSymbol && isNegative ? "-" : ""
  const regexp = /\.0+$|\.\d*[1-9]0+$/
  const item = [...lookup].reverse().find((itm) => value >= itm.value)

  if (!item) return "0"
  const fixedNumber = (value / item.value).toFixed(digits).replace(regexp, "")
  return `${prefixSymbol}${fixedNumber}${item.symbol}`
}
