import type { RItemVariantModifier } from "../../../../../../../../../shared/graphql/generated/types"
import Container from "../../../../../../../../../ui/Container"
import Icon from "../../../../../../../../../ui/Icon"
import { InputNumber } from "../../../../../../../../../ui/Inputs"
import Text from "../../../../../../../../../ui/Typography/Text"
import SoldOutIcon from "../../../../../../../components/SoldOutIcon"
import type { ICorporateItemForm } from "../../../../../interfaces/hookforms.interfaces"
import get from "lodash/get"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type ModifierGroupDetailContentProps = {
  variantModifier: RItemVariantModifier
  variantIndex: number
  variantModifierGroupIndex: number
  variantModifierIndex: number
}

export const ModifierGroupDetailContent: React.FC<ModifierGroupDetailContentProps> =
  ({
    variantModifier,
    variantIndex,
    variantModifierGroupIndex,
    variantModifierIndex,
  }) => {
    const intl = useIntl()

    const {
      control,
      formState: { errors },
    } = useFormContext<ICorporateItemForm>()

    const modifierGroupsError = get(
      errors,
      `variants.${variantIndex}.variantModifierGroups`
    )

    const modifiersError = get(
      modifierGroupsError,
      `${variantModifierGroupIndex}.variantModifiers.${variantModifierIndex}`
    )

    return (
      <>
        <StyledGridContainer key={variantModifier.uuid}>
          <Text color="Neutral5">
            {variantModifier.modifier?.ingredient.name}
          </Text>
          <Text color="Neutral5">{variantModifier.calories}</Text>
          <Controller
            control={control}
            name={`variants.${variantIndex}.variantModifierGroups.${variantModifierGroupIndex}.variantModifiers.${variantModifierIndex}.price`}
            render={({ field }) => (
              <InputNumber
                {...field}
                min={0}
                placeholder="0.00"
                step={0.01}
                hasError={!!get(modifiersError, "price")}
                errorMessage={get(modifiersError, "price.message")}
                precision={2}
                prefix={intl.formatMessage({
                  id: "generic.symbol.currency",
                  defaultMessage: "$",
                })}
                maxWidth="106px"
              />
            )}
          />
          <Container display="flex" justifySelf="center" alignSelf="center">
            {variantModifier.isIncluded ? (
              <Icon color="Neutral5" size={24} remixiconClass="ri-check-line" />
            ) : (
              <></>
            )}
          </Container>
          <Container
            display="flex"
            alignItems="center"
            gap="16px"
            justifyContent="flex-end"
          >
            <Icon
              color="Neutral5"
              size={24}
              remixiconClass={
                variantModifier.isVisible ?? true
                  ? "ri-eye-line"
                  : "ri-eye-off-line"
              }
            />
            <Container
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width="24px"
              height="24px"
            >
              <SoldOutIcon isSoldOut={variantModifier.isSoldOut ?? false} />
            </Container>
          </Container>
        </StyledGridContainer>
      </>
    )
  }

const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 64px;
  justify-items: start;
  align-items: center;
  grid-gap: 16px;
  margin: 8px 48px;
`
