import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import { useIntl } from "react-intl"

interface DiscountRules {
  children?: React.ReactNode
}

export const DiscountRules: React.FC<DiscountRules> = ({
  children,
}: DiscountRules) => {
  const intl = useIntl()
  return (
    <>
      <Text size="m">
        {intl.formatMessage({
          id: "restaurants.discount.forms.generic.rules.title",
          defaultMessage: "Rules",
        })}
      </Text>
      <Text size="s" color="Neutral8">
        {intl.formatMessage({
          id: "restaurants.discount.forms.generic.rules.description",
          defaultMessage:
            "Rules are optional, they help to customize your discount requirements",
        })}
      </Text>
      <Spacer size={24} />
      {children}
    </>
  )
}
