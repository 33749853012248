import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type {
  AttachmentModel,
  RestaurantAttachmentModel,
  RestaurantByUserModel,
} from "../../../../../shared/graphql/generated/types"
import { AttachmentTypeEnum } from "../../../../../shared/graphql/generated/types"
import { useFileBlobUrl } from "../../../../../shared/hooks/useFileBlobUrl"
import paths from "../../../../../shared/routes/paths"
import { putFileWithSignedUrl } from "../../../../../shared/utils/api/client"
import { getImageFile } from "../../../../../shared/utils/helpers/getImageFile"
import getImageFilename from "../../../../../shared/utils/helpers/getImageFilename"
import getImageMetadata from "../../../../../shared/utils/helpers/getImageMetadata"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import CopyToClipboard from "../../../../../ui/CopyToClipboard"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import InputLabel from "../../../../../ui/InputLabel"
import Input from "../../../../../ui/Inputs/Input"
import DetailPage from "../../../../../ui/Layouts/MainLayout/DetailPage"
import { useGetAllRestaurantsByUserQuery } from "../../../../../ui/Layouts/MainLayout/Sidebar/DropdownMenu/GraphQL/getAllRestaurantsByUser.generated"
import PageTitle from "../../../../../ui/PageTitle"
import Radio from "../../../../../ui/Radio"
import Spacer from "../../../../../ui/Spacer"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../ui/Typography/Text"
import UploadAvatar from "../../../../../ui/UploadAvatar"
import notification from "../../../../../ui/notification"
import { ActionTypes } from "../../../../Restaurants/actions"
import { useGetRestaurantWhiteLabelConfigQuery } from "../../WhiteLabelApplication/Graphql/getRestaurantWhiteLabelConfig.generated"
import RestaurantDeleteModal from "../DeleteRestaurantModal"
import { EditRestaurantResolver } from "./EditRestaurant.yup"
import { useCreateRestaurantAttachmentMutation } from "./GraphQL/createRestaurantAttachment.generated"
import { useDeleteRestaurantAttachmentMutation } from "./GraphQL/deleteRestaurantAttachment.generated"
import { useUpdateRestaurantMutation } from "./GraphQL/updateRestaurant.generated"
import { useUpdateRestaurantAttachmentMutation } from "./GraphQL/updateRestaurantAttachment.generated"
import TechInfo from "./TechInfo"
import type {
  IEditRestaurantForm,
  IEditRestaurantHandleSubmit,
  IEditRestaurantLogoHandleSubmit,
} from "./hookforms.interfaces"
import type { RcFile } from "antd/lib/upload"
import { useFlags } from "launchdarkly-react-client-sdk"
import find from "lodash/find"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"
import styled, { css } from "styled-components"

type AttachmentTypeEnumWithOutAppLogo = Exclude<
  AttachmentTypeEnum,
  AttachmentTypeEnum.APP_LOGO
>

type objectToUpdateCacheType = {
  COVER: {
    createFileBlob: (
      attachment?: { imageFile?: RcFile | undefined } | undefined
    ) => Promise<unknown>
    createAttachmentObj: (
      attachment?: AttachmentModel | null | undefined
    ) => AttachmentModel | null
  }
  SF_COVER: {
    createFileBlob: (
      attachment?: { imageFile?: RcFile | undefined } | undefined
    ) => Promise<unknown>
    createAttachmentObj: (
      attachment?: AttachmentModel | null | undefined
    ) => AttachmentModel | null
  }
  LOGO: {
    createFileBlob: (
      attachment?: { imageFile?: RcFile | undefined } | undefined
    ) => Promise<unknown>
    createAttachmentObj: (
      attachment?: AttachmentModel | null | undefined
    ) => AttachmentModel | null
  }
  MENU: {
    createFileBlob: (
      attachment?: { imageFile?: RcFile | undefined } | undefined
    ) => Promise<unknown>
    createAttachmentObj: (
      attachment?: AttachmentModel | null | undefined
    ) => AttachmentModel | null
  }
}

type WhiteLabelConfig = {
  uuid?: string | null
  ios?: string | null
  android?: string | null
}

type WhiteLabelConfigData = {
  bundleAndroidId?: string
  bundleAppleId?: string
}

export const EditRestaurant = () => {
  const intl = useIntl()
  const { customerFee } = useFlags()
  const { push } = useHistory()
  const [showTechInfo, setShowTechInfo] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [whiteLabelConfig, setWhiteLabelConfig] = useState<WhiteLabelConfig>({
    uuid: null,
    ios: null,
    android: null,
  })

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<IEditRestaurantForm>({
    mode: "onBlur",
    resolver: EditRestaurantResolver,
  })

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth,
    },
    dispatch,
  } = useGeneralContext()

  const [restaurant, setRestaurant] = useState<
    RestaurantByUserModel | undefined
  >()

  const pathNameStoreFront = process.env.REACT_APP_STOREFRONT_URL?.replace(
    /\/$/,
    ""
  )
  const urlStoreFront =
    pathNameStoreFront && restaurant
      ? `${pathNameStoreFront}/${restaurant.urlIdentifier}`
      : undefined

  const { loading } = useGetRestaurantWhiteLabelConfigQuery({
    variables: {
      restaurantUUID: restaurantUUID,
    },
    skip: !restaurantUUID,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getRestaurantWhiteLabelConfig }) => {
      const { uuid, whiteLabelConfig: whiteLabelConfigData } =
        getRestaurantWhiteLabelConfig

      const whiteLabel = (
        whiteLabelConfigData ? JSON.parse(whiteLabelConfigData) : {}
      ) as WhiteLabelConfigData

      setWhiteLabelConfig({
        uuid: uuid,
        ios: whiteLabel.bundleAppleId,
        android: whiteLabel.bundleAndroidId,
      })
    },
  })

  useGetAllRestaurantsByUserQuery({
    variables: {
      userUUID: auth.admin.uuid,
    },
    skip: !restaurantUUID,
    onError: showGraphqlErrors,
    onCompleted: async ({ getAllRestaurantsByUser }) => {
      try {
        setRestaurant(
          getAllRestaurantsByUser.find(({ uuid }) => uuid === restaurantUUID)
        )

        const allRestaurants = getAllRestaurantsByUser.find(
          ({ uuid }) => uuid === restaurantUUID
        )
        const findLogo = find(allRestaurants?.attachments, [
          "type",
          AttachmentTypeEnum.LOGO,
        ]) as AttachmentModel
        const findCover = find(allRestaurants?.attachments, [
          "type",
          AttachmentTypeEnum.COVER,
        ]) as AttachmentModel
        const findSfCover = find(allRestaurants?.attachments, [
          "type",
          AttachmentTypeEnum.SF_COVER,
        ]) as AttachmentModel

        let logo
        let cover
        let sfCover

        if (findLogo) {
          logo = (await getImageFile(findLogo)) as RcFile
        }

        if (findCover) {
          cover = (await getImageFile(findCover ? findCover : {})) as RcFile
        }

        if (findSfCover) {
          sfCover = (await getImageFile(
            findSfCover ? findSfCover : {}
          )) as RcFile
        }

        reset({
          name: allRestaurants?.name,
          logo: logo,
          cover: cover,
          sfCover: sfCover,
          passFeeToCustomer: allRestaurants?.passFeeToCustomer,
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    },
  })

  const [updateRestaurant, updateRestaurantState] = useUpdateRestaurantMutation(
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            getAllRestaurantsByUser: (prev: RestaurantByUserModel[]) => {
              if (data) {
                const restaurantOldData = prev.find(
                  (restaurantData) =>
                    restaurantData.uuid === data.updateRestaurant.uuid
                )

                const restaurantUpdated: RestaurantByUserModel = {
                  isTestMode: restaurantOldData?.isTestMode ?? true,
                  attachments: data.updateRestaurant.attachments,
                  locationsQuantity: undefined,
                  name: data.updateRestaurant.name,
                  priceCategory: undefined,
                  urlIdentifier: data.updateRestaurant.urlIdentifier,
                  uuid: data.updateRestaurant.uuid,
                  passFeeToCustomer: !!data.updateRestaurant.passFeeToCustomer,
                  isCateringActive: data.updateRestaurant.isCateringActive,
                }
                const previous = prev

                const newList = previous?.map((current) => {
                  if (current.uuid === data?.updateRestaurant.uuid) {
                    return { ...restaurantUpdated }
                  }

                  return current
                })

                dispatch({
                  type: ActionTypes.AddAllRestaurants,
                  payload: [...newList],
                })

                dispatch({
                  type: ActionTypes.SetCurrentState,
                  payload: {
                    restaurantUUID: restaurantUpdated.uuid,
                  },
                })
              }
            },
          },
        })
      },
    }
  )

  const [, createFileBlobUrl, createAttachmentObject] = useFileBlobUrl()
  const [, createFileBlobUrl3, createAttachmentObject3] = useFileBlobUrl()
  const [, createFileBlobUrl4, createAttachmentObject4] = useFileBlobUrl()
  const [, createFileBlobUrl5, createAttachmentObject5] = useFileBlobUrl()

  const objectToUpdateCache: objectToUpdateCacheType = {
    LOGO: {
      createFileBlob: createFileBlobUrl,
      createAttachmentObj: createAttachmentObject,
    },
    COVER: {
      createFileBlob: createFileBlobUrl3,
      createAttachmentObj: createAttachmentObject3,
    },
    MENU: {
      createFileBlob: createFileBlobUrl4,
      createAttachmentObj: createAttachmentObject4,
    },
    SF_COVER: {
      createFileBlob: createFileBlobUrl5,
      createAttachmentObj: createAttachmentObject5,
    },
  }

  const [deleteRestaurantAttachment, deleteRestaurantAttachmentState] =
    useDeleteRestaurantAttachmentMutation({
      update(cache, response) {
        cache.modify({
          fields: {
            getAllRestaurantsByUser: (prev) => {
              const data = response.data?.deleteRestaurantAttachment

              if (data) {
                const previous = prev as Array<RestaurantByUserModel>

                const newList: RestaurantByUserModel[] = previous.map(
                  (current) => {
                    if (current.uuid === restaurant?.uuid) {
                      const updatedAttachments = current?.attachments?.filter(
                        (attachment) => attachment.uuid !== data.uuid
                      )

                      const restaurantUpdated: RestaurantByUserModel = {
                        ...restaurant,
                        attachments: updatedAttachments,
                      }

                      return { ...restaurantUpdated }
                    }

                    return current
                  }
                )

                return [...newList]
              }
            },
          },
        })
      },
    })

  const [updateRestaurantAttachment, updateRestaurantAttachmentState] =
    useUpdateRestaurantAttachmentMutation({
      update(cache, response) {
        cache.modify({
          fields: {
            getAllRestaurantsByUser: (prev) => {
              const data = response.data?.updateRestaurantAttachment

              if (data) {
                const previous = prev as Array<RestaurantByUserModel>
                const { type, ...getData } = data

                const newList: RestaurantByUserModel[] = previous.map(
                  (current) => {
                    if (current.uuid === restaurant?.uuid) {
                      const updatedAttachments = current?.attachments?.map(
                        (attachment) => {
                          if (attachment.uuid === data.uuid) {
                            const createObject = objectToUpdateCache[
                              type as keyof objectToUpdateCacheType
                            ].createAttachmentObj({
                              uuid: getData.uuid,
                              contentType: getData.contentType,
                              ext: getData.ext,
                              fileName: getData.fileName,
                              signedUrl: getData.signedUrl,
                            })

                            if (createObject) {
                              return {
                                ...createObject,
                                type,
                                __typename: data.__typename,
                              }
                            }
                          }

                          return attachment
                        }
                      )

                      const restaurantUpdated: RestaurantByUserModel = {
                        ...restaurant,
                        attachments: updatedAttachments,
                      }

                      return { ...restaurantUpdated }
                    }

                    return current
                  }
                )

                return [...newList]
              }
            },
          },
        })
      },
    })

  const [createRestaurantAttachment, createRestaurantAttachmentState] =
    useCreateRestaurantAttachmentMutation({
      update(cache, response) {
        cache.modify({
          fields: {
            getAllRestaurantsByUser: (prev) => {
              const data = response.data?.createRestaurantAttachment

              if (data) {
                const previous = prev as Array<RestaurantByUserModel>
                const { type, ...getData } = data

                const newList: RestaurantByUserModel[] = previous?.map(
                  (current) => {
                    if (current.uuid === restaurant?.uuid) {
                      const objectCache = objectToUpdateCache[
                        type as keyof objectToUpdateCacheType
                      ].createAttachmentObj({
                        uuid: getData.uuid,
                        contentType: getData.contentType,
                        ext: getData.ext,
                        fileName: getData.fileName,
                        signedUrl: getData.signedUrl,
                      })

                      let add: RestaurantAttachmentModel

                      if (objectCache) {
                        add = {
                          ...objectCache,
                          type: data.type,
                          __typename: data.__typename,
                        }

                        const attachmentsNew = current?.attachments
                          ? [...current.attachments, add]
                          : [add]

                        const restaurantUpdated: RestaurantByUserModel = {
                          ...restaurant,
                          attachments: attachmentsNew,
                        }

                        return { ...restaurantUpdated }
                      }
                    }

                    return current
                  }
                )

                return [...newList]
              }
            },
          },
        })
      },
    })

  const onUpdateRestaurantAttachment = async (
    uuid: string,
    type: AttachmentTypeEnum,
    image: RcFile
  ) => {
    return updateRestaurantAttachment({
      variables: {
        restaurantUUID,
        data: {
          ...getImageMetadata(image.type),
          uuid,
          type,
          fileName: getImageFilename(image.name),
        },
      },
    })
  }

  const onCreateRestaurantAttachment = async (
    type: AttachmentTypeEnum,
    image: RcFile
  ) => {
    return createRestaurantAttachment({
      variables: {
        restaurantUUID,
        data: {
          ...getImageMetadata(image.type),
          type,
          fileName: getImageFilename(image.name),
        },
      },
    })
  }

  const onDeleteRestaurantAttachment = async (attachmentUUID: string) => {
    return deleteRestaurantAttachment({
      variables: {
        data: {
          attachmentUUID,
          restaurantUUID,
        },
      },
    })
  }

  const createOrUpdateAttachment = async (
    inputLogo: RcFile,
    type: `${AttachmentTypeEnumWithOutAppLogo}`,
    logo?: RestaurantAttachmentModel
  ) => {
    let logoUrl: string | undefined

    if (logo?.uuid) {
      const attachmentUpdated = await onUpdateRestaurantAttachment(
        logo?.uuid,
        AttachmentTypeEnum[type as keyof typeof AttachmentTypeEnum],
        inputLogo
      )
      logoUrl = attachmentUpdated?.data?.updateRestaurantAttachment?.signedUrl
    } else {
      const attachmentCreated = await onCreateRestaurantAttachment(
        AttachmentTypeEnum[type as keyof typeof AttachmentTypeEnum],
        inputLogo
      )

      logoUrl = attachmentCreated?.data?.createRestaurantAttachment?.signedUrl
    }

    if (logoUrl) {
      try {
        await putFileWithSignedUrl(logoUrl, inputLogo)
      } catch {
        notification({
          description: intl.formatMessage({
            id: "generic.error.image.message",
            defaultMessage: "Your image was unable to save.",
          }),
          type: "error",
        })
      }
    }
  }

  const createOrUpdateLogoSquare = async (
    input?: IEditRestaurantLogoHandleSubmit
  ) => {
    const logo = find(restaurant?.attachments, [
      "type",
      AttachmentTypeEnum.LOGO,
    ])

    if (input?.logo) {
      await createOrUpdateAttachment(input.logo, AttachmentTypeEnum.LOGO, logo)
    } else {
      if (logo) {
        onDeleteRestaurantAttachment(logo.uuid)
      }
    }
  }

  interface CreateOrUpdateCover {
    file?: RcFile
    fileType: `${AttachmentTypeEnum.COVER}` | `${AttachmentTypeEnum.SF_COVER}`
  }

  const createOrUpdateCover = async (args: CreateOrUpdateCover) => {
    const { file, fileType } = args

    const cover = find(restaurant?.attachments, ["type", fileType])

    if (file) {
      await createOrUpdateAttachment(file, fileType, cover)
    } else {
      if (cover) {
        onDeleteRestaurantAttachment(cover.uuid)
      }
    }
  }

  const onUpdateRestaurant = async (input: {
    name?: string
    passFeeToCustomer?: boolean
  }) => {
    await updateRestaurant({
      variables: {
        data: {
          restaurantUUID,
          ...input,
        },
      },
    })
  }

  const onSaveInformation = async (input: IEditRestaurantHandleSubmit) => {
    try {
      const covers: CreateOrUpdateCover[] = []

      if (dirtyFields.sfCover) {
        covers.push({ file: input.sfCover, fileType: "SF_COVER" })
      }

      if (dirtyFields.cover) {
        covers.push({ file: input.cover, fileType: "COVER" })
      }

      if (dirtyFields.logo) {
        await createOrUpdateLogoSquare(input)
      }

      await Promise.all(covers.map(async (cover) => createOrUpdateCover(cover)))

      if (dirtyFields.name) {
        await onUpdateRestaurant({ name: input.name })
      }

      if (dirtyFields.passFeeToCustomer) {
        await onUpdateRestaurant({ passFeeToCustomer: input.passFeeToCustomer })
      }

      notification({
        description: intl.formatMessage({
          id: "restaurants.home.restaurant.info.edit.information.success.message",
          defaultMessage: "Restaurant Information Updated",
        }),
        type: "success",
      })
      push(paths.settings.restaurant.restaurantInfo)
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onClose = () => {
    setOpenModalDelete(false)
  }

  return (
    <DetailPage role="content-layout">
      <div>
        <PageTitle
          border
          actionButton={
            <Button
              transparent
              hierarchy="tertiary"
              disabled={loading}
              loading={loading}
              onClick={() => setShowTechInfo((prev) => !prev)}
            >
              {showTechInfo
                ? intl.formatMessage({
                    id: "restaurants.home.restaurant.info.button.label.hide",
                    defaultMessage: "Hide tech info",
                  })
                : intl.formatMessage({
                    id: "restaurants.home.restaurant.info.button.label.view",
                    defaultMessage: "View tech info",
                  })}
            </Button>
          }
          title={intl.formatMessage({
            id: "restaurants.home.restaurant.info.title",
            defaultMessage: "Restaurant Info",
          })}
          description={
            <FormattedMessage
              id="restaurants.home.restaurant.info.description"
              defaultMessage="Here you can see all the information of {name}"
              values={{
                name: (
                  <HighlightedText color="Neutral9" size="s">
                    {restaurant?.name}
                  </HighlightedText>
                ),
              }}
            />
          }
        />
        <Spacer size={16} />
        <InputLabel
          label={intl.formatMessage({
            id: "restaurants.home.restaurant.info.logo.label",
            defaultMessage: "Logo",
          })}
        />
        <StyledContentInformation role="form" aria-label="restaurant-edit-form">
          <ContainerContent>
            <Text size="s">
              {intl.formatMessage({
                id: "onboarding.restaurant.form.logo.square.label",
                defaultMessage: "Square (1024 x 1024 px)",
              })}
            </Text>
            <Controller
              name="logo"
              control={control}
              render={({ field: { value, onChange } }) => (
                <UploadAvatar
                  avatar={value}
                  onChange={(avatar) => {
                    const actualAvatar = value
                    onChange(avatar)
                    !!actualAvatar && !avatar && onChange(actualAvatar)
                  }}
                  onDelete={onChange}
                  hint={intl.formatMessage({
                    id: "components.upload.images.hint",
                    defaultMessage: "Upload",
                  })}
                  height={128}
                  width={128}
                  validateSquareLogo
                  deletable
                />
              )}
            />
          </ContainerContent>
          <StyledContentOther>
            <InputLabel
              label={intl.formatMessage({
                id: "restaurants.home.restaurant.info.cover.label",
                defaultMessage: "Cover Image",
              })}
            />
            <Controller
              name="cover"
              control={control}
              render={({ field: { value, onChange } }) => (
                <UploadAvatar
                  cropperAspect="vertical_rectangle"
                  avatar={value}
                  onChange={onChange}
                  onDelete={onChange}
                  hint={intl.formatMessage({
                    id: "components.upload.images.hint",
                    defaultMessage: "Upload",
                  })}
                  className="cover-image-update"
                  width={327}
                  height={208}
                  imageFit="cover"
                  cropper
                  deletable
                />
              )}
            />
          </StyledContentOther>
          <StyledContentOther>
            <InputLabel
              label={intl.formatMessage({
                id: "restaurants.home.restaurant.info.sf.cover.label",
                defaultMessage: "Storefront Cover Image",
              })}
            />
            <Controller
              name="sfCover"
              control={control}
              render={({ field: { value, onChange } }) => (
                <UploadAvatar
                  cropperAspect="horizontal_rectangle"
                  avatar={value}
                  onChange={onChange}
                  onDelete={onChange}
                  hint={intl.formatMessage({
                    id: "components.upload.images.hint",
                    defaultMessage: "Upload",
                  })}
                  className="sf-cover-image-update"
                  width={433}
                  height={120}
                  imageFit="cover"
                  cropper
                  deletable
                />
              )}
            />
          </StyledContentOther>
          <Spacer size={64} />
          <ContainerContent>
            <div>
              <Text size="l" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.home.restaurant.info.name.label",
                  defaultMessage: "Restaurant Name",
                })}
              </Text>
              <Spacer size={4} />
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    hasError={!!errors.name}
                    helperText={errors.name?.message}
                    aria-label="name"
                  />
                )}
              />
            </div>
            {urlStoreFront && restaurant && (
              <div>
                <Text size="l" weight="bold">
                  {intl.formatMessage({
                    id: "restaurants.home.restaurant.info.url.label",
                    defaultMessage: "Restaurant URL",
                  })}
                </Text>
                <Spacer size={4} />
                <Container display="flex" alignItems="center" gap="8px">
                  <StyledRestaurantUrl
                    href={urlStoreFront}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text size="m" weight="regular">
                      <span className="pathname">
                        {pathNameStoreFront?.replace(/^(https?:\/\/)?/, "")}
                      </span>
                      <span className="url-identifier">{`/${restaurant.urlIdentifier}`}</span>
                    </Text>
                  </StyledRestaurantUrl>
                  <CopyToClipboard
                    content={urlStoreFront}
                    copyTextLabel={intl.formatMessage({
                      id: "generic.copy.link.to.clipboard",
                      defaultMessage: "Copy URL to Clipboard",
                    })}
                    copiedTextLabel={intl.formatMessage({
                      id: "generic.link.copied.to.clipboard",
                      defaultMessage: "URL Copied",
                    })}
                    sizeIcon={21}
                  />
                </Container>
              </div>
            )}
            {customerFee && (
              <div>
                <Text size="l" weight="bold">
                  {intl.formatMessage({
                    id: "restaurants.home.restaurant.info.fees.label",
                    defaultMessage: "Service Fees Configuration",
                  })}
                </Text>
                <Spacer size={4} />
                <Controller
                  control={control}
                  name="passFeeToCustomer"
                  render={({ field }) => {
                    const { value, onChange } = field

                    return (
                      <ContainerContent>
                        <Radio
                          $classId="radio"
                          content="Paid by the restaurant"
                          checked={value === false}
                          onChange={() => onChange(false)}
                        />
                        <Radio
                          $classId="radio"
                          content="Paid by the customer"
                          checked={value === true}
                          onChange={() => onChange(true)}
                        />
                      </ContainerContent>
                    )
                  }}
                />
              </div>
            )}
          </ContainerContent>
          <StyledContentButton>
            <Button
              type="submit"
              aria-label="save"
              title={intl.formatMessage({
                id: "restaurants.home.restaurant.button.save.label",
                defaultMessage: "Update",
              })}
              hierarchy="secondary"
              onClick={handleSubmit(onSaveInformation)}
              loading={
                updateRestaurantState.loading ||
                updateRestaurantAttachmentState.loading ||
                createRestaurantAttachmentState.loading ||
                deleteRestaurantAttachmentState.loading
              }
            />
          </StyledContentButton>
        </StyledContentInformation>
        {showTechInfo && (
          <>
            <Spacer size={48} />
            <TechInfo whiteLabelConfig={whiteLabelConfig} />
          </>
        )}
      </div>
      <RestaurantDeleteModal
        restaurantName={restaurant?.name}
        visible={openModalDelete}
        onClose={onClose}
        closable={false}
      />
    </DetailPage>
  )
}

const StyledContentOther = styled.div`
  margin-top: 16px;
`

const StyledContentInformation = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledContentButton = styled.div`
  margin-top: 48px;
`

const ContainerContent = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledRestaurantUrl = styled.a`
  ${({ theme }) => css`
    span {
      .pathname {
        color: ${theme.colors.TransparentDark};
      }
      .url-identifier {
        color: ${theme.colors["Neutral8"]};
      }
    }
  `}
`
