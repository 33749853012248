import paths from "../../../../../shared/routes/paths"
import Container from "../../../../../ui/Container"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../ui/Typography/Text"
import type { IOrdersParams } from "../../hookforms.interfaces"
import { OrderCustomerInformationSkeleton } from "../OrderDetail.skeleton"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

type OrderCustomerInformationProps = {
  loading?: boolean
}

export const OrderCustomerInformation: React.FC<OrderCustomerInformationProps> =
  ({ loading }) => {
    const intl = useIntl()
    const { push } = useHistory()
    const { locationUUID } = useParams<IOrdersParams>()

    const { watch } = useFormContext<OrderDetailModalForm>()
    const customerInformation = watch("orderDetail.customer")

    const goToCustomer = () => {
      locationUUID
        ? push(
            paths.restaurants.locations.customerDetailByLocation(
              locationUUID,
              customerInformation?.uuid
            )
          )
        : push(paths.restaurants.customerDetail(customerInformation?.uuid))
    }

    if (loading) {
      return <OrderCustomerInformationSkeleton />
    }

    if (!customerInformation?.uuid) {
      return null
    }

    return (
      <Container role="customer-information-section">
        <StyledContentSection>
          <Text color="Neutral6" weight="bold" size="xl">
            {intl.formatMessage({
              id: "restaurants.orders.order.detail.customer.information.section.header",
              defaultMessage: "Customer",
            })}
          </Text>
        </StyledContentSection>

        <Container display="flex">
          <Container
            display="flex"
            flexDirection="column"
            gap="8px"
            width="50%"
          >
            <Text color="Neutral6" size="m">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.customer.information.name",
                defaultMessage: "Customer Name",
              })}
            </Text>
            {
              <Container display="flex" gap="8px">
                <Text size="s" weight="bold">
                  {customerInformation?.firstName ?? "-"}{" "}
                  {customerInformation?.lastName ?? ""}
                </Text>
                {
                  //TODO: Replace with LinkButton
                }
                <HighlightedText cursor="pointer" onClick={goToCustomer}>
                  {intl.formatMessage({
                    id: "restaurants.orders.order.detail.customer.information.name.info",
                    defaultMessage: "View info",
                  })}
                </HighlightedText>
              </Container>
            }
          </Container>
          <Container
            display="flex"
            flexDirection="column"
            gap="8px"
            width="50%"
          >
            <Text color="Neutral6" size="m">
              {intl.formatMessage({
                id: "restaurants.orders.order.detail.customer.information.phone",
                defaultMessage: "Phone Number",
              })}
            </Text>
            <Text size="s" weight="bold">
              {customerInformation?.phone || "-"}
            </Text>
          </Container>
        </Container>
      </Container>
    )
  }

const StyledContentSection = styled.div`
  padding-bottom: 16px;
`
