import styles from "./TableContentEmptyState.module.css"
import { Center, Container, Text } from "@mantine/core"
import { useIntl } from "react-intl"

type TableContentEmptyStateProps = { length: number }

export const TableContentEmptyState = (props: TableContentEmptyStateProps) => {
  const { length } = props
  const intl = useIntl()

  return (
    <>
      <tr>
        {Array.from({ length: length }).map((_, index) => (
          <td width="100%" key={index} className={styles["empty-td"]}>
            <Container miw={100} bg="cyan" />
          </td>
        ))}
      </tr>
      <tr>
        <td colSpan={length} height="174px">
          <Center>
            {
              <Text size="xl" c="gray.6">
                {intl.formatMessage({
                  id: "restaurant.reports.table.empty.state",
                  defaultMessage: "No entries available.",
                })}
              </Text>
            }
          </Center>
        </td>
      </tr>
    </>
  )
}
