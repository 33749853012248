import {
  RMenuDetail,
  RuuidInput,
  SortingByMenuEnum,
} from "../../../../shared/graphql/generated/types"
import { MenuStatusEnum } from "../../../../shared/graphql/generated/types"
import { useModal } from "../../../../shared/hooks/useModal"
import paths from "../../../../shared/routes/paths"
import Breadcrumb, { breadcrumbs } from "../../../../ui/Breadcrumb"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import ModalFull from "../../../../ui/ModalFull"
import { formNotification } from "../../../../ui/notification"
import type { ILocationsParams } from "../../../Settings/Locations/hookforms.interfaces"
import MenuDetailHeader from "../../components/ModalFullDetailHeader"
import MenuForm, { MenuResolver } from "../MenuForm"
import type { IMenuForm } from "../MenuForm/hookforms.interfaces"
import DropdownMenus from "../components/DropdownMenus"
import PublishMenuModal from "../components/modals/PublishMenuModal"
import { useMenus } from "../hooks/useMenus"
import { LocationMenuError } from "../utils/notifications.titles"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

type EditMenuProps = {
  isCorporate?: boolean
  menu: RMenuDetail
}

const EditMenu = ({ isCorporate = true, menu }: EditMenuProps) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ILocationsParams>()
  const { restaurants } = paths
  const { push } = useHistory()
  const [
    publishModalVisible,
    showPublishConfirmation,
    dismissPublishConfirmation,
  ] = useModal()

  const [savedMenuName, setSavedMenuName] = useState<string>()
  const currentLocations = menu.locations || []

  const {
    saveMenu,
    menuSaved: { loading: loadingMenuSaved },
    menuPublished: { loading: loadingPublished },
    menuDraftPublished: { loading: loadingDraftPublished },
  } = useMenus({})

  const formMethods = useForm<IMenuForm>({
    mode: "all",
    resolver: MenuResolver,
    defaultValues: {
      isMain: isCorporate,
      uuid: menu.uuid,
      isPublished: !!menu.publishedAt,
      name: menu.name,
      menuType: menu.menuType,
      lastPublishedAt: menu.publishedAt,
      lastSavedAt: menu.updatedAt,
      hasSnapshot: menu.hasSnapshot,
      items: menu.items,
      combos: menu.combos,
      sortItemsBy: menu.itemSortBy ?? SortingByMenuEnum.DATE_ASC,
      applyToAllLocations: menu.applyToAllLocations ?? true,
      locations: isCorporate
        ? menu.locations?.map((location) => location.uuid)
        : [locationUUID],
    },
  })

  const {
    watch,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    clearErrors,
    formState: { isDirty, errors },
  } = formMethods

  const watchMenuUUID = watch("uuid") ?? ""
  const lastPublishedAt = watch("lastPublishedAt") ?? null
  const lastSavedAt = watch("lastSavedAt") ?? null
  const enabledPreview = !!watchMenuUUID && !!lastSavedAt
  const hasSnapshot = watch("hasSnapshot") ?? false

  const onSave = async () => {
    const formData = getValues()
    clearErrors("items")
    const isValidName = await trigger("name")

    if (!isValidName) {
      const nameError = errors?.name
        ? { message: intl.formatMessage(LocationMenuError.NAME) }
        : undefined

      return formNotification({
        key: "lsm.edit.menu.save",
        header: intl.formatMessage({
          id: "restaurants.menus.edit.menu.save.notification.error.header",
          defaultMessage: "You can’t save this category",
        }),
        title: intl.formatMessage({
          id: "restaurants.menus.edit.menu.save.notification.error.title",
          defaultMessage: "To save a category you need to:",
        }),
        type: "error",
        error: { ...errors, ...(nameError && { name: nameError }) },
      })
    }

    await saveMenu(
      formData,
      (result) => {
        setSavedMenuName(result.saveMenu.name)
        setValue("lastSavedAt", new Date())
        setValue("lastPublishedAt", result.saveMenu.publishedAt)
        setValue("uuid", result?.saveMenu?.uuid)
        setValue("hasSnapshot", result?.saveMenu?.hasSnapshot)
        reset({}, { keepValues: true })
      },
      showGraphqlErrors
    )
  }

  const publishCallback = () => {
    dismissPublishConfirmation()
    setValue("lastPublishedAt", new Date())
    setValue("lastSavedAt", null)
    setValue("isPublished", true)
    setValue("isReadyToPublish", false)
    setValue("hasSnapshot", false)
    reset({}, { keepValues: true })

    setSavedMenuName(getValues("name"))

    onClose()
  }

  const onPublish = () => {
    setValue("isReadyToPublish", true)

    handleSubmit(
      () => {
        showPublishConfirmation()
      },
      (error) => {
        const nameError = error?.name
          ? { message: intl.formatMessage(LocationMenuError.NAME) }
          : undefined

        formNotification({
          key: "lsm.edit.menu.publish",
          header: intl.formatMessage({
            id: "restaurants.menus.edit.menu.publish.notification.error.header",
            defaultMessage: "You can’t publish this category",
          }),
          title: intl.formatMessage({
            id: "restaurants.menus.edit.menu.publish.notification.error.title",
            defaultMessage: "To publish a category you need to:",
          }),
          type: "error",
          error: {
            ...error,
            ...(nameError && { name: nameError }),
          },
        })
      }
    )()

    setValue("isReadyToPublish", false)
  }

  const onClose = () => {
    const path = isCorporate
      ? restaurants.menu
      : restaurants.locations.menusByLocation(locationUUID)

    reset({}, { keepValues: true })

    push(path)
  }

  const getMenuData = () => {
    if (isDirty) {
      const data = getValues()

      const items: RuuidInput[] | undefined = data.items?.map((item) => {
        return {
          uuid: item.uuid,
        }
      })

      const combos: RuuidInput[] | undefined = data.combos?.map((combo) => {
        return {
          uuid: combo.uuid,
        }
      })

      const result = {
        applyToAllLocations: data.applyToAllLocations,
        locations: data.applyToAllLocations
          ? undefined
          : data?.locations?.map((location) => {
              return { uuid: location }
            }),
        items: items,
        combos: combos,
        isMain: data.isMain,
        menuType: data.menuType,
        name: data.name,
        uuid: data.uuid,
        labels: getValues("locationLabels"),
        itemSortBy: SortingByMenuEnum[data.sortItemsBy],
      }

      return result
    }

    return undefined
  }

  return (
    <ModalFull
      id="edit-menu-modal"
      goBackPath={restaurants.menu}
      headerColor="Neutral2"
      afterClose={onClose}
      title={
        <MenuDetailHeader
          onSave={onSave}
          onPublish={onPublish}
          lastSavedAt={lastSavedAt}
          lastPublishedAt={lastPublishedAt}
          enablePreview={enabledPreview}
          enablePublish={hasSnapshot || !lastPublishedAt || isDirty}
          enableSave={isDirty && !!watchMenuUUID}
          loadingPublish={loadingPublished || loadingDraftPublished}
          loadingSave={loadingMenuSaved}
          actions={
            <DropdownMenus
              onOk={onClose}
              isCorporate={isCorporate}
              menuUUID={menu.uuid}
              actions={
                menu.status === MenuStatusEnum.DRAFT
                  ? ["DELETE_DRAFT", "UNPUBLISH", "DISABLE_UNPUBLISH"]
                  : ["DELETE", "UNPUBLISH"]
              }
            />
          }
        >
          <Breadcrumb
            breadcrumbs={breadcrumbs.editMenu}
            pageName={savedMenuName ?? menu.name}
          />
        </MenuDetailHeader>
      }
      visible
    >
      <FormProvider {...formMethods}>
        <MenuForm currentLocations={currentLocations} />
      </FormProvider>
      {publishModalVisible && (
        <PublishMenuModal
          menuUUID={menu.uuid}
          menuData={getMenuData()}
          onOk={publishCallback}
          onCancel={dismissPublishConfirmation}
          isCorporate={isCorporate}
        />
      )}
    </ModalFull>
  )
}

export default EditMenu
