import { OrderStatusEnum } from "../../../../../shared/graphql/generated/types"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import { CancelOrderModal } from "../../CancelOrderModal"
import { RefundOrderModal } from "../../RefundOrderModal"
import { GetOrderDetailsDocument } from "../GraphQL/getOrderDetails.generated"
import { GetOrderSummaryDocument } from "../GraphQL/getOrderSummary.generated"
import OrderActionContainer from "../OrderActionContainer"
import OrderActionDivider from "../OrderActionDivider"
import type {
  IOrderDetailForm,
  OrderDetailModalForm,
} from "../OrderDetailModal/hookforms.interfaces"
import { useDisclosure } from "@mantine/hooks"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export const DangerZone: React.FC = () => {
  const intl = useIntl()

  const [showRefundOrder, setShowRefundOrder] = useState<boolean>(false)

  const { watch } = useFormContext<OrderDetailModalForm>()
  const orderDetails = watch("orderDetail")

  const orderUUID = orderDetails?.uuid

  const [openedCancelModal, { open, close }] = useDisclosure()

  const onDisplayRefundOrder = () => {
    setShowRefundOrder(true)
  }

  const onCancelRefundOrder = () => {
    setShowRefundOrder(false)
  }

  return (
    <div role="order-danger-zone-section">
      <OrderActionDivider />
      <OrderActionContainer role="order-danger-zone-section">
        <Text size="xl" weight="bold" color="Neutral6">
          {intl.formatMessage({
            id: "restaurants.orders.order.detail.danger.zone.title",
            defaultMessage: "Danger Zone",
          })}
        </Text>
        <Spacer size={24} />
        <Container display="flex" flexDirection="column" gap="16px">
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap="64px"
          >
            <Container>
              <Text size="l" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.orders.order.detail.danger.zone.cancel.title",
                  defaultMessage: "Cancel this order",
                })}
              </Text>
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.orders.order.detail.danger.zone.delete.description",
                  defaultMessage:
                    "Once you cancel an order, there is no going back. Please be certain.",
                })}
              </Text>
            </Container>
            <Button
              role="delete-order"
              title={intl.formatMessage({
                id: "restaurants.orders.order.detail.danger.zone.delete.button.title",
                defaultMessage: "Cancel Order",
              })}
              disabled={!!orderUUID && !orderDetails?.isAvailableCancel}
              hierarchy="secondary"
              onClick={open}
              display="danger"
            />
            {orderUUID && (
              <CancelOrderModal
                modalProps={{ zIndex: 1000 }}
                opened={openedCancelModal}
                onClose={close}
                orderUUID={orderUUID}
                refetchQueries={[
                  GetOrderDetailsDocument,
                  GetOrderSummaryDocument,
                ]}
              />
            )}
          </Container>

          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap="64px"
          >
            <Container>
              <Text size="l" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.orders.order.detail.danger.zone.refund.title",
                  defaultMessage: "Refund this order",
                })}
              </Text>
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.orders.order.detail.danger.zone.refund.description",
                  defaultMessage:
                    "Once you refund an order, there is no going back. Please be certain.",
                })}
              </Text>
            </Container>
            <Button
              disabled={
                !orderDetails?.isAvailableRefund ||
                orderDetails?.status !== OrderStatusEnum.COMPLETED
              }
              onClick={onDisplayRefundOrder}
              title={intl.formatMessage({
                id: "restaurants.orders.order.detail.danger.zone.refund.button.title",
                defaultMessage: "Refund Order",
              })}
              hierarchy="secondary"
              display="danger"
            />
          </Container>
        </Container>
      </OrderActionContainer>
      {showRefundOrder && (
        <RefundOrderModal
          items={getItems(orderDetails.orderDetails)}
          invoice={getInvoice(orderDetails)}
          close={onCancelRefundOrder}
        />
      )}
    </div>
  )
}

const getItems = (details: IOrderDetailForm["orderDetails"]) => {
  const parsedItems: { name: string; price: number; quantity: number }[] = []

  details.forEach((detail) => {
    if (detail.item) {
      parsedItems.push({
        name: detail.item.name,
        price: detail.item.totalPrice,
        quantity: detail.item.quantity,
      })
    }
  })

  return parsedItems
}

const getInvoice = (
  details: IOrderDetailForm
): {
  total: number
  tip: number
  storecash: number
  orderUUID: string
  locationUUID: string
  deliveryFee: number
  serviceFees: number
  taxes: number
  subTotal: number
  discounts: number
} => {
  const {
    total,
    tip,
    storeCash,
    deliveryFee,
    serviceFee,
    totalTaxes,
    subTotal,
    discountDetails,
  } = details.invoice ?? {}
  const orderUUID = details.uuid
  const locationUUID = details.location?.uuid

  if (!locationUUID) throw new Error("Location UUID is missing")

  return {
    discounts:
      discountDetails?.reduce((acc, curr) => acc + curr.amountApplied, 0) ?? 0,
    total: total ?? 0,
    tip: tip ?? 0,
    storecash: storeCash ?? 0,
    orderUUID,
    locationUUID,
    taxes: totalTaxes ?? 0,
    deliveryFee: deliveryFee ?? 0,
    serviceFees: serviceFee ?? 0,
    subTotal: subTotal ?? 0,
  }
}
