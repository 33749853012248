import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishDraftComboMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type PublishDraftComboMutation = { __typename?: 'Mutation', publishDraftCombo: { __typename?: 'RPublishedCombo', hasSnapshot: boolean, isMain: boolean, isPublished: boolean, isVisible?: boolean | null, name: string, uuid: string } };


export const PublishDraftComboDocument = gql`
    mutation PublishDraftCombo($uuid: ID!) {
  publishDraftCombo(uuid: $uuid) {
    hasSnapshot
    isMain
    isPublished
    isVisible
    name
    uuid
  }
}
    `;
export type PublishDraftComboMutationFn = Apollo.MutationFunction<PublishDraftComboMutation, PublishDraftComboMutationVariables>;

/**
 * __usePublishDraftComboMutation__
 *
 * To run a mutation, you first call `usePublishDraftComboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftComboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftComboMutation, { data, loading, error }] = usePublishDraftComboMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishDraftComboMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftComboMutation, PublishDraftComboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDraftComboMutation, PublishDraftComboMutationVariables>(PublishDraftComboDocument, options);
      }
export type PublishDraftComboMutationHookResult = ReturnType<typeof usePublishDraftComboMutation>;
export type PublishDraftComboMutationResult = Apollo.MutationResult<PublishDraftComboMutation>;
export type PublishDraftComboMutationOptions = Apollo.BaseMutationOptions<PublishDraftComboMutation, PublishDraftComboMutationVariables>;