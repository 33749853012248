import Background from "../../../components/Background"
import grayBackground from "../../../shared/assets/images/bg-gray.svg"
import { useOnboardingContext } from "../../../shared/contexts/OnboardingProvider"
import { AttachmentTypeEnum } from "../../../shared/graphql/generated/types"
import paths from "../../../shared/routes/paths"
import Button from "../../../ui/Button"
import Container from "../../../ui/Container"
import Spacer from "../../../ui/Spacer"
import Text from "../../../ui/Typography/Text"
import Title from "../../../ui/Typography/Title"
import RestaurantCard from "../components/RestaurantCard"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

const Done = () => {
  const intl = useIntl()
  const history = useHistory()

  const {
    onboarding: {
      name: restaurantName,
      urlIdentifier,
      brandColor,
      location: { name: locationName = "" },
      logos = [],
    },
    clearOnboardingState,
  } = useOnboardingContext()

  const onStartExploring = () => {
    clearOnboardingState()

    history.push(paths.restaurants.root)
  }

  return (
    <Container centered fullScreen display="flex" flexDirection="column">
      <Background source={grayBackground} />
      <Container height="100%" display="flex" alignItems="center">
        <Container
          height="518px"
          width="449px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Title size="m" weight="bold" color="Neutral9">
              {intl.formatMessage({
                id: "onboarding.done.title.label",
                defaultMessage: "Well done!",
              })}
            </Title>
            <Spacer size={24} />
            <Text size="m" weight="regular" color="Neutral6">
              {intl.formatMessage({
                id: "onboarding.done.body.label",
                defaultMessage:
                  "Thanks for choosing Crmb. Our team will start crafting your menu and get in contact soon.",
              })}
            </Text>
            <Spacer size={16} />
          </Container>

          <RestaurantCard
            restaurantName={restaurantName}
            locationName={locationName}
            urlIdentifier={urlIdentifier}
            brandColor={brandColor}
            logo={
              logos.find((logo) => logo.type === AttachmentTypeEnum.LOGO)?.file
            }
          />
          <Button hierarchy="primary" width="400px" onClick={onStartExploring}>
            {intl.formatMessage({
              id: "onboarding.done.start.button",
              defaultMessage: "Start exploring Crmb",
            })}
          </Button>
        </Container>
      </Container>
    </Container>
  )
}

export default Done
