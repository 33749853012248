import yup from "../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const MenuSchema = yup
  .object()
  .shape({
    name: yup.string().default("Untitled Category").required("Name it"),
    locations: yup.array(),
    items: yup.array(),
    combos: yup.array(),
    isReadyToPublish: yup.boolean().validateMenuForm(),
  })
  .test({
    name: "validate that there is at least one combo or item",
    test: (values, { createError }) => {
      if (!values.items?.length && !values.combos?.length) {
        return createError({
          message: "At least add one item or combo",
          path: "itemOrCombo",
        })
      }

      return true
    },
  })

export const MenuResolver = yupResolver(MenuSchema)
