import type { GetMenuListQuery } from "../../../GraphQL/Queries/getMenuList.generated"
import type { MenuList } from "../generated/types"

export const updateGetMenuListQuery = (
  prev: GetMenuListQuery,
  fetchMoreResult: GetMenuListQuery | undefined
) => {
  if (
    !fetchMoreResult ||
    prev?.getMenuList?.endCursor === fetchMoreResult?.getMenuList?.endCursor
  ) {
    return prev
  }

  return {
    getMenuList: {
      ...fetchMoreResult.getMenuList,
      results: [
        ...(prev.getMenuList.results as Array<MenuList>),
        ...(fetchMoreResult.getMenuList.results as Array<MenuList>),
      ],
    },
  }
}
