/* eslint-disable unicorn/no-thenable */
import {
  PHONE_FORMAT_ERROR_ID,
  REQUIRED_FIELD_ERROR_ID,
} from "../../../../shared/titles/validations.title"
import { checkIfRealAddress } from "../../../../shared/utils/helpers/checkAddress"
import yup from "../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const OnboardingLocationFormSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup
    .string()
    .required(REQUIRED_FIELD_ERROR_ID)
    .validatePhoneNumber(PHONE_FORMAT_ERROR_ID),
  searchAddress: yup.object().when("autocompleteAddress", {
    is: true,
    then: yup.object().shape({
      address: yup
        .string()
        .required()
        .test(
          "correctAddressFormat",
          "Please enter a complete address that includes the street or postal code",
          (_, fields) => {
            const { addressComponents, latitude, longitude } = fields.parent

            const valid = addressComponents
              ? checkIfRealAddress([...addressComponents])
              : !!latitude && !!longitude

            return !!valid
          }
        ),
      addressComponents: yup.array(
        yup.object().shape({
          type: yup.string(),
        })
      ),
    }),
  }),
  manualAddress: yup.object().when("autocompleteAddress", {
    is: false,
    then: yup.object().shape({
      line1: yup.string().required(),
      line2: yup.string().optional(),
      state: yup.string().required(),
      city: yup.string().required(),
      zipCode: yup
        .string()
        .matches(/^\d{5}(?:[\s-]\d{4})?$/, "Invalid ZIP code")
        .required(),
    }),
  }),
  locationsQuantity: yup.string().required(),
})
export const OnboardingRestaurantResolver = yupResolver(
  OnboardingLocationFormSchema
)
