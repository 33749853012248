import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetItemDetailQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetItemDetailQuery = { __typename?: 'Query', getItemDetail: { __typename?: 'RItemDetail', uuid: string, name?: string | null, isMain: boolean, status: Types.ItemProgressStatusEnum, publishedAt?: any | null, snapshotUpdatedAt?: any | null, updatedAt: any, createdAt: any, description?: string | null, hasSnapshot: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, serves?: number | null, variantsSortBy?: Types.SortingByMenuEnum | null, attachment?: { __typename?: 'AttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string, uuid: string } | null, menus: Array<{ __typename?: 'RBasicMenuDetail', uuid: string, name: string, isMain: boolean }>, variants: Array<{ __typename?: 'RItemVariant', uuid: string, name?: string | null, sku?: string | null, price?: number | null, calories?: number | null, isDefault: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, variantModifierGroupsSortBy?: Types.SortingByMenuEnum | null, createdAt?: any | null, attachment?: { __typename?: 'AttachmentModel', uuid: string, fileName: string, contentType: Types.ContentTypeEnum, signedUrl: string, ext: Types.FileExtensionEnum } | null, variantModifierGroups: Array<{ __typename?: 'RItemVariantModifierGroup', uuid: string, fieldType?: Types.FieldTypeEnumItem | null, isRequired?: boolean | null, maxSelectedOptions?: number | null, minSelectedOptions?: number | null, createdAt?: any | null, modifierGroup: { __typename?: 'RItemModifierGroup', uuid: string, name: string, isMain: boolean }, variantModifiers: Array<{ __typename?: 'RItemVariantModifier', uuid: string, price?: number | null, calories?: number | null, isIncluded: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, modifier: { __typename?: 'RItemModifier', uuid: string, ingredient: { __typename?: 'RItemIngredient', uuid: string, name: string, isMain: boolean } } }> }> }> } };


export const GetItemDetailDocument = gql`
    query getItemDetail($uuid: ID!) {
  getItemDetail(uuid: $uuid) {
    uuid
    name
    isMain
    status
    publishedAt
    snapshotUpdatedAt
    updatedAt
    createdAt
    description
    hasSnapshot
    isSoldOut
    isVisible
    attachment {
      contentType
      ext
      fileName
      signedUrl
      uuid
    }
    menus {
      uuid
      name
      isMain
    }
    serves
    variantsSortBy
    variants {
      uuid
      name
      sku
      price
      calories
      isDefault
      isSoldOut
      isVisible
      variantModifierGroupsSortBy
      createdAt
      attachment {
        uuid
        fileName
        contentType
        signedUrl
        ext
      }
      variantModifierGroups {
        uuid
        fieldType
        isRequired
        maxSelectedOptions
        minSelectedOptions
        createdAt
        modifierGroup {
          uuid
          name
          isMain
        }
        variantModifiers {
          uuid
          price
          calories
          isIncluded
          isSoldOut
          isVisible
          modifier {
            uuid
            ingredient {
              uuid
              name
              isMain
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetItemDetailQuery__
 *
 * To run a query within a React component, call `useGetItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetItemDetailQuery(baseOptions: Apollo.QueryHookOptions<GetItemDetailQuery, GetItemDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemDetailQuery, GetItemDetailQueryVariables>(GetItemDetailDocument, options);
      }
export function useGetItemDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemDetailQuery, GetItemDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemDetailQuery, GetItemDetailQueryVariables>(GetItemDetailDocument, options);
        }
export type GetItemDetailQueryHookResult = ReturnType<typeof useGetItemDetailQuery>;
export type GetItemDetailLazyQueryHookResult = ReturnType<typeof useGetItemDetailLazyQuery>;
export type GetItemDetailQueryResult = Apollo.QueryResult<GetItemDetailQuery, GetItemDetailQueryVariables>;