import { FieldTypeEnumItem } from "../../../../../shared/graphql/generated/types"
import type { IItemForm } from "../interfaces/hookforms.interfaces"
import omit from "lodash/omit"

export const formDataToInput = (formData: IItemForm) => {
  const {
    uuid,
    name,
    description,
    menus,
    isMain,
    attachment,
    variants,
    isSoldOut,
    sortVariantsBy,
    serves,
  } = formData

  const menusInput = menus?.map((menu) => ({ uuid: menu }))

  const variantsInput = variants?.map((variant) => {
    const variantModifierGroups = variant.variantModifierGroups?.map(
      (variantModifierGroup) => {
        const variantModifiers = variantModifierGroup.variantModifiers?.map(
          (variantModifier) => {
            const {
              calories,
              isIncluded,
              modifier,
              price,
              uuid: variantModifierUUID,
              deletedAt,
            } = variantModifier

            return {
              uuid: variantModifierUUID,
              price: price ?? 0,
              modifier: { uuid: modifier?.uuid ?? "" },
              isIncluded: isIncluded ?? false,
              calories,
              deletedAt,
            }
          }
        )

        const {
          fieldType,
          minSelectedOptions,
          maxSelectedOptions,
          modifierGroup,
          uuid: variantModifierGroupUUID,
          isRequired,
          deletedAt,
        } = variantModifierGroup

        return {
          uuid: variantModifierGroupUUID,
          fieldType: fieldType ?? FieldTypeEnumItem.CHECKBOX,
          minSelectedOptions: minSelectedOptions ?? 0,
          isRequired: isRequired ?? false,
          maxSelectedOptions: maxSelectedOptions ?? 0,
          modifierGroup: { uuid: modifierGroup.uuid },
          variantModifiers: variantModifiers ?? [],
          deletedAt,
        }
      }
    )

    const {
      uuid: variantUUID,
      sku,
      isDefault,
      attachment: variantAttachment,
      calories,
      name: variantName,
      isSoldOut: variantIsSoldOut,
      price,
      deletedAt,
      sortModifierGroupsBy,
    } = variant

    return {
      attachment: variantAttachment
        ? omit(variantAttachment, "signedUrl")
        : undefined,
      calories,
      deletedAt,
      uuid: variantUUID,
      sku: sku || undefined,
      isDefault,
      name: variantName ?? "",
      isSoldOut: variantIsSoldOut ?? false,
      price: price ?? 0,
      variantModifierGroups: variantModifierGroups ?? [],
      variantModifierGroupsSortBy: sortModifierGroupsBy,
    }
  })

  const data = {
    uuid,
    name,
    isMain,
    isSoldOut,
    description: description || undefined,
    menus: menusInput,
    attachment: attachment ? omit(attachment, "signedUrl") : undefined,
    variants: variantsInput,
    variantsSortBy: sortVariantsBy,
    ...(serves && { serves: Number(serves) }),
  }

  return data
}
