import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import { useGetAllCustomersByRestaurantQuery } from "../../../Customers/graphql/getAllCustomersByRestaurant.generated"
import type { ICustomersTableModel } from "../../../Customers/interfaces/customers.interfaces"
import OrdersFilterContent from "../OrdersFilterContent"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

interface FilterByCustomerProps {
  filterLabel: string
  clearFilterOptions: () => void
}

export const FilterByCustomer: React.FC<FilterByCustomerProps> = ({
  filterLabel,
  clearFilterOptions,
}) => {
  const intl = useIntl()

  const [filteredCustomers, setFilteredCustomers] =
    useState<ICustomersTableModel[]>()
  const [customerName, setCustomerName] = useState<string | undefined>()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { loading: isFetchingCustomerData, refetch } =
    useGetAllCustomersByRestaurantQuery({
      variables: {
        restaurantUUID,
      },
      notifyOnNetworkStatusChange: true,
      returnPartialData: true,
      skip: !restaurantUUID,
      fetchPolicy: "no-cache",
      onCompleted: ({ getAllCustomersByRestaurant }) => {
        setFilteredCustomers(
          getAllCustomersByRestaurant.results?.map(
            ({
              userUUID,
              email,
              firstName,
              formattedPhone,
              lastName,
              phone,
              locations,
            }) => {
              return {
                email,
                firstName,
                formattedPhone,
                lastName,
                locations,
                phone,
                userUUID,
              }
            }
          )
        )
      },
    })

  const searchLocation = useCallback(
    (name: string) => {
      try {
        setCustomerName(name)

        refetch({
          restaurantUUID,
          name: name === "" ? undefined : name,
          phone: name === "" ? undefined : name,
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    },
    [restaurantUUID, refetch]
  )

  const searchLocationsDebounce = useMemo(
    () => debounce(searchLocation, 1000),
    [searchLocation]
  )

  const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerName !== event.target.value) {
      searchLocationsDebounce(event.target.value)
    }
  }

  useEffect(() => {
    if (restaurantUUID) {
      refetch()
    }
  }, [restaurantUUID, refetch])

  return (
    <OrdersFilterContent
      filterLabel={filterLabel}
      onSearchTermChange={onSearchTermChange}
      filterKey="customer"
      loading={isFetchingCustomerData}
      results={filteredCustomers?.map((customer) => {
        const { firstName, lastName, phone, userUUID } = customer

        const customerName2 =
          firstName || lastName
            ? `${firstName ?? ""} ${lastName ?? ""}`
            : intl.formatMessage({
                id: "restaurants.orders.filter.by.customer.unnamed.customer.label",
                defaultMessage: "Unnamed customer",
              })

        const formattedCustomer = `${customerName2} (${phone})`

        return {
          value: userUUID,
          name: formattedCustomer,
        }
      })}
      clearSearch={clearFilterOptions}
    />
  )
}
