import { useEffect } from "react"

export const useResize = (onChange: () => void) => {
  useEffect(() => {
    window.addEventListener("resize", onChange, true)

    return () => {
      window.removeEventListener("resize", onChange)
    }
  }, [onChange])
}
