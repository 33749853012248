import { Card, Divider, Group, Skeleton, Stack, Text } from "@mantine/core"
import { useIntl } from "react-intl"

type FinancialCardSkeletonProps = { h: number; fields: number }

export const FinancialCardSkeleton = (props: FinancialCardSkeletonProps) => {
  const { h, fields } = props
  const intl = useIntl()

  return (
    <Card withBorder miw={284} h={h} radius={8}>
      <Stack spacing={16}>
        <Group position="apart">
          <Text size="sm" weight={600} color="gray.6" lh="16px">
            {intl.formatMessage({
              id: "restaurants.reports.labor.cost.summary.label",
              defaultMessage: "SUMMARY",
            })}
          </Text>
        </Group>

        <Stack spacing={0}>
          <Group spacing={8} h={32}>
            <Skeleton h={24} w={70} />
            <Skeleton h={16} w={40} />
          </Group>
          <Text size="md" color="gray.6" h={18}>
            {intl.formatMessage({
              id: "restaurants.reports.labor.cost.comparison.label",
              defaultMessage: "Compared to previous period",
            })}
          </Text>
        </Stack>

        <Divider />

        {Array.from({ length: fields }).map((field, index) => (
          <Group spacing={8} key={index} position="apart">
            <Skeleton h={20} w={70} />
            <Skeleton h={20} w={40} />
          </Group>
        ))}
      </Stack>
    </Card>
  )
}
