import { device } from "../../../../shared/breakpoints/responsive"
import { useOnboardingContext } from "../../../../shared/contexts/OnboardingProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import type {
  TempAttachmentInput,
  UpdateRestaurantAttachmentInput,
} from "../../../../shared/graphql/generated/types"
import {
  AttachmentDirectoryEnum,
  AttachmentTypeEnum,
} from "../../../../shared/graphql/generated/types"
import { putFileWithSignedUrl } from "../../../../shared/utils/api/client"
import getImageFilename from "../../../../shared/utils/helpers/getImageFilename"
import { Container } from "../../../../ui/Container/Container"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import FileDragger from "../../../../ui/FileDragger"
import InputLabel from "../../../../ui/InputLabel"
import TextArea from "../../../../ui/Inputs/TextArea"
import { useCreateTempRestaurantAttachmentMutation } from "../../GraphQL/CreateTempRestaurantAttachment.generated"
import { useUpsertRestaurantSnapshotMutation } from "../../GraphQL/UpsertRestaurantSnapshot.generated"
import ConfirmationModalMenuForm from "../../components/ConfirmationModal"
import StepperHandler from "../../components/StepperHandler"
import StepsFooter from "../../components/StepsFooter"
import { IndicatorsStateType } from "../../components/StepsFooter/config"
import { StepsType } from "../../interfaces"
import { MAX_MENU_FILES, MAX_MENU_FILE_SIZE } from "./utils/constants"
import { getFileMetadata } from "./utils/get-file-metadata.util"
import type { RestaurantMenuFormType } from "./utils/hookforms.interfaces"
import type { RcFile } from "antd/lib/upload"
import React, { useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

type OnboardingLocationFormProps = {
  onPrevious: () => void
  onNext: () => void
  stepFooter: StepsType
  indicators: IndicatorsStateType[]
}

const OnboardingMenuForm: React.FC<OnboardingLocationFormProps> = ({
  onPrevious,
  onNext,
  stepFooter,
  indicators,
}) => {
  const intl = useIntl()
  const [createTempAttachments] = useCreateTempRestaurantAttachmentMutation({
    onError: (e) => showGraphqlErrors(e),
  })
  const [upsertRestaurantSnapshot] = useUpsertRestaurantSnapshotMutation()
  const { onboarding, updateOnboardingState } = useOnboardingContext()

  const {
    state: {
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const locationFormMethods = useForm<RestaurantMenuFormType>({
    defaultValues: {
      details: onboarding.notes,
      restaurantMenuAttachments: onboarding.menuAttachments,
    },
  })

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = locationFormMethods

  const onFormSubmit = async (data: RestaurantMenuFormType, pass?: boolean) => {
    if (!isDirty) {
      return onNext()
    }

    if (!!data.details && !data.restaurantMenuAttachments?.length && !pass) {
      return setOpenModal(true)
    }

    try {
      updateOnboardingState({ loading: true })

      let attachmentArray: TempAttachmentInput[] = []

      if (getValues("restaurantMenuAttachments")?.length) {
        const attachments = getValues("restaurantMenuAttachments").map(
          (uploadedFile) => {
            if (!uploadedFile.type) {
              throw new Error(
                intl.formatMessage({
                  id: "generic.error.try.again.message",
                  defaultMessage: "Something went wrong. Try again later.",
                })
              )
            }

            return {
              ...getFileMetadata(uploadedFile.type),
              fileName: getImageFilename(uploadedFile.name),
              type: AttachmentTypeEnum.MENU,
              fileId: uploadedFile.uid,
            } as TempAttachmentInput
          }
        )

        const result = await createTempAttachments({
          variables: {
            input: {
              attachments,
              directory: AttachmentDirectoryEnum.RESTAURANT,
            },
            uuid: userUUID,
          },
        })
        attachmentArray = attachments
        const tempAttachments = result.data?.createTempRestaurantAttachment

        if (!tempAttachments?.length) {
          throw new Error(
            intl.formatMessage({
              id: "generic.error.images.server.response.message",
              defaultMessage:
                "Couldn't retrieve server data to upload images. Try again later.",
            })
          )
        }

        for (const attachment of tempAttachments) {
          const uploadedFile = getValues("restaurantMenuAttachments").find(
            (file) => file.uid === attachment.fileId
          )

          if (!uploadedFile) {
            throw new Error(
              intl.formatMessage({
                id: "generic.error.try.again.message",
                defaultMessage: "Something went wrong. Try again later.",
              })
            )
          }

          await putFileWithSignedUrl(
            attachment.signedUrl,
            uploadedFile as RcFile
          )
        }
      }

      await upsertRestaurantSnapshot({
        variables: {
          data: {
            snapshot: {
              notes: data.details || undefined,
              ...(attachmentArray.length > 0 && {
                menuAttachments: attachmentArray.map((attachment) => {
                  if (!attachment.fileId) {
                    throw new Error(
                      intl.formatMessage({
                        id: "generic.error.attachment.format.message",
                        defaultMessage:
                          "Attachment has wrong format. Please contact support.",
                      })
                    )
                  }

                  return {
                    contentType: attachment.contentType,
                    ext: attachment.ext,
                    fileName: attachment.fileName,
                    type: attachment.type,
                    uuid: attachment.fileId,
                  }
                }),
              }),
              brandColor: onboarding.brandColor,
              currentStep: onboarding.currentStep,
              locationsQuantity: onboarding.locationsQuantity,
              ...(location && {
                location: {
                  ...(onboarding.location.address && {
                    address: onboarding.location.address,
                  }),
                  ...(onboarding.location.addressLine1 && {
                    addressLine1: onboarding.location.addressLine1,
                  }),
                  ...(onboarding.location.addressLine2 && {
                    addressLine2: onboarding.location.addressLine2,
                  }),
                  ...(onboarding.location.city && {
                    city: onboarding.location.city,
                  }),
                  ...(onboarding.location.latitude && {
                    latitude: onboarding.location.latitude,
                  }),
                  ...(onboarding.location.longitude && {
                    longitude: onboarding.location.longitude,
                  }),
                  ...(onboarding.location.name && {
                    name: onboarding.location.name,
                  }),
                  ...(onboarding.location.postalCode && {
                    postalCode: onboarding.location.postalCode,
                  }),
                  ...(onboarding.location.postalCode && {
                    state: onboarding.location.postalCode,
                  }),
                },
              }),
              logos: onboarding.logos?.map((logo) => ({
                contentType: logo.contentType,
                ext: logo.ext,
                fileName: logo.fileName,
                type: logo.type,
                uuid: logo.uuid,
              })) as UpdateRestaurantAttachmentInput[],
              name: onboarding.name,
              urlIdentifier: onboarding.urlIdentifier,
            },
            userUUID,
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
    updateOnboardingState({ loading: false })

    onNext()
  }
  const onClose = () => {
    setOpenModal(false)
  }

  return (
    <>
      <FormProvider {...locationFormMethods}>
        <Container
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <StyledMenuForm display="flex" flexDirection="column" gap="16px">
            <Container>
              <InputLabel
                label={intl.formatMessage({
                  id: "onboarding.forms.menu.form.upload.label",
                  defaultMessage: "Upload your restaurant menu",
                })}
                requirement="optional"
              />
              <Controller
                name="restaurantMenuAttachments"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FileDragger
                    maxFileQuantity={MAX_MENU_FILES}
                    maxSize={MAX_MENU_FILE_SIZE}
                    onChange={onChange}
                    value={value}
                    acceptedMimeTypes={[
                      "image/jpg",
                      "image/jpeg",
                      "application/pdf",
                    ]}
                  />
                )}
              />
            </Container>
            <Controller
              name="details"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextArea
                  label={intl.formatMessage({
                    id: "onboarding.forms.menu.form.details.label",
                    defaultMessage: "Details",
                  })}
                  requirement="optional"
                  value={value}
                  onChange={onChange}
                  commentCharactersLimit={100}
                  helperText={intl.formatMessage({
                    id: "onboarding.forms.menu.form.helper.text",
                    defaultMessage:
                      "Add a description related to the files that you uploaded",
                  })}
                />
              )}
            />
          </StyledMenuForm>
          <StyledSteps>
            <StepFooterContent>
              <StepsFooter indicatorsStates={indicators} step={stepFooter} />
            </StepFooterContent>
            <StepperHandler
              onPrevious={onPrevious}
              onNext={handleSubmit((data) => onFormSubmit(data))}
              loading={onboarding.loading}
              lastStep
            />
          </StyledSteps>
        </Container>
      </FormProvider>

      <ConfirmationModalMenuForm
        visible={openModal}
        onCancel={onClose}
        onConfirm={handleSubmit((data) => onFormSubmit(data, true))}
      />
    </>
  )
}

export default OnboardingMenuForm

const StyledSteps = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${device.lg} {
    justify-content: space-between;
    margin-top: 32px;
  }
`

const StepFooterContent = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`

const StyledMenuForm = styled(Container)`
  ${({ theme }) => css`
    .ant-upload-drag {
      border-color: ${theme.colors.Neutral4};
      border-radius: 4px;
      background: ${theme.colors.Neutral2};
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: ${theme.colors.Primary5};
    }
    .ant-upload-list-item {
      font-size: 15px;
      height: 32px;

      .ant-upload-list-item-name {
        line-height: 24px;
      }
      &:hover .ant-upload-list-item-info {
        background: ${theme.colors.Neutral2};
      }
    }
    .ant-upload-list-item-info {
      padding: 4px 8px;
      .ant-upload-text-icon .anticon {
        color: ${theme.colors.Neutral5};
      }
    }
    .ant-progress-inner {
      background: ${theme.colors.Neutral2};
    }
    .ant-progress-bg {
      background: ${theme.colors.Primary5};
    }
    .ant-upload-list-item-error {
      .ant-upload-list-item-name,
      .ant-upload-list-item-card-actions .anticon,
      .ant-upload-text-icon > .anticon {
        color: ${theme.colors.Danger5};
      }
    }
  `}
`
