import { getLocalTimezoneId } from "../../../../shared/utils/helpers/getTimezoneId"
import Button from "../../../../ui/Button"
import Container from "../../../../ui/Container"
import Icon from "../../../../ui/Icon"
import Tooltip from "../../../../ui/Tooltip"
import Text from "../../../../ui/Typography/Text"
import moment from "moment-timezone"
import React from "react"
import { useIntl } from "react-intl"

type CorporateDetailHeaderProps = {
  lastSavedAt: Date
  lastPublishedAt: Date
  loadingSave: boolean
  enableSave: boolean
  enablePreview?: boolean
  showPreview?: boolean
  onSave: () => void
}

const CorporateDetailHeader: React.FC<CorporateDetailHeaderProps> = ({
  showPreview = false,
  loadingSave,
  enableSave,
  lastSavedAt,
  lastPublishedAt,
  enablePreview,
  onSave,
  children,
}) => {
  const intl = useIntl()

  const handlePreviewClick = () => {
    return
  }

  return (
    <>
      {children}
      <Container display="flex" alignItems="center" gap="16px">
        <Tooltip
          placement="bottom"
          title={
            <Container display="flex" flexDirection="column">
              <div hidden={!lastSavedAt}>
                {intl.formatMessage(
                  {
                    defaultMessage: "Last saved {savedOn}",
                    id: "restaurants.menus.header.last.save.tooltip",
                  },
                  {
                    savedOn: moment
                      .tz(lastSavedAt, getLocalTimezoneId())
                      .calendar(),
                  }
                )}
              </div>

              <div hidden={!lastPublishedAt}>
                {intl.formatMessage(
                  {
                    defaultMessage: "Last published {publishedOn}",
                    id: "restaurants.menus.header.last.published.tooltip",
                  },
                  { publishedOn: moment(lastPublishedAt).calendar() }
                )}
              </div>
            </Container>
          }
        >
          <Text size="m" weight="regular" color="Neutral5">
            {intl.formatMessage(
              {
                defaultMessage: "Last saved {lastSave}",
                id: "restaurants.locations.menus.corporate.header.last.save.label",
              },
              {
                lastSave: moment
                  .tz(lastSavedAt, getLocalTimezoneId())
                  .fromNow(),
              }
            )}
          </Text>
        </Tooltip>
        <Container hidden={!showPreview}>
          {enablePreview ? (
            <Icon
              remixiconClass="ri-computer-line"
              size={24}
              color={"Primary5"}
              onClick={handlePreviewClick}
              cursor="pointer"
            />
          ) : (
            <Icon
              remixiconClass="ri-computer-line"
              size={24}
              color="Neutral5"
            />
          )}
        </Container>
        <Button
          hierarchy="secondary"
          title={intl.formatMessage({
            id: "restaurants.locations.corporate.menus.header.save.button",
            defaultMessage: "Save",
          })}
          onClick={onSave}
          disabled={!enableSave}
          loading={loadingSave}
        />
      </Container>
    </>
  )
}

export default CorporateDetailHeader
