import { CalculateCateringPaymentAmountQuery } from "../../Graphql/calculateCateringPaymentAmount.generated"

export const parseCateringPaymentAmounts = (
  data?: CalculateCateringPaymentAmountQuery
) => {
  const {
    subtotal = 0,
    taxes = 0,
    total = 0,
    cateringFee = 0,
    serviceFee = 0,
  } = data?.calculateCateringPaymentAmount.payment?.general ?? {}
  const remainingAmounts =
    data?.calculateCateringPaymentAmount.payment?.remaining.total ?? 0

  return {
    subtotal,
    taxes,
    total,
    cateringFee,
    serviceFee,
    remainingBalance: remainingAmounts,
  }
}
