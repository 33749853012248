import styles from "./SelectLabel.module.css"
import { Box, MultiSelectValueProps, Text } from "@mantine/core"
import { IconX } from "@tabler/icons-react"

type SelectLabelProps = MultiSelectValueProps & { value: string }

export const SelectLabel = (props: SelectLabelProps) => {
  const { label, onRemove, ...others } = props

  return (
    <div {...others}>
      <Box className={styles["label-box"]}>
        <Text size="md" color="gray.7">
          {label}
        </Text>
        <IconX
          onMouseDown={onRemove}
          size={20}
          className={styles["close-button"]}
          tabIndex={-1}
        />
      </Box>
    </div>
  )
}
