import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import type { IOrdersParams } from "../../hookforms.interfaces"
import { useAddCommentToOrderMutation } from "../GraphQL/addCommentToOrder.generated"
import { GetOrderSummaryDocument } from "../GraphQL/getOrderSummary.generated"
import OrderActionContainer from "../OrderActionContainer"
import type { OrderDetailModalForm } from "../OrderDetailModal/hookforms.interfaces"
import { OrderCommentResolver } from "./OrderComment.yup"
import type { IAddOrderCommentForm } from "./hookforms.interfaces"
import React, { useEffect } from "react"
import { Controller, useForm, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type OrderCommentProps = {
  commentCharactersLimit?: number
}

export const OrderComment: React.FC<OrderCommentProps> = ({
  commentCharactersLimit = 200,
  ...props
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<IOrdersParams>()

  const { watch } = useFormContext<OrderDetailModalForm>()

  const orderUUID = watch("orderDetail.uuid")

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
  } = useForm<IAddOrderCommentForm>({
    resolver: OrderCommentResolver,
    mode: "onSubmit",
  })

  const [addCommentMutation, { loading }] = useAddCommentToOrderMutation()

  const onSubmitForm = async ({ comment }: IAddOrderCommentForm) => {
    try {
      await addCommentMutation({
        variables: { data: { orderUUID, comment, locationUUID } },
        refetchQueries: [GetOrderSummaryDocument],
      })

      reset()

      notification({
        description: intl.formatMessage({
          id: "restaurants.orders.order.detail.new.comment.message.success",
          defaultMessage: "Your comment was successfully added",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  useEffect(() => {
    return () => {
      clearErrors()
    }
  }, [clearErrors, orderUUID])

  return (
    <OrderActionContainer {...props} role="order-comment-section">
      <Text size="xl" weight="bold" color="Neutral6">
        {intl.formatMessage({
          id: "restaurants.orders.order.detail.new.comment.title",
          defaultMessage: "New Comment",
        })}
      </Text>

      <Spacer size={16} />

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Container display="flex" justifyContent="space-between" gap="24px">
          <Container width="100%" height="60px">
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  aria-label="comment"
                  type="text"
                  hasError={!!errors?.comment}
                  helperText={errors?.comment?.message}
                  commentCharactersLimit={commentCharactersLimit}
                  wrapHelperText
                />
              )}
            />
          </Container>

          <Button
            type="submit"
            title={intl.formatMessage({
              id: "restaurants.orders.order.detail.new.comment.button",
              defaultMessage: "Add",
            })}
            hierarchy="secondary"
            loading={loading}
          />
        </Container>
      </form>
    </OrderActionContainer>
  )
}
