import { ActionTypes } from "../../pages/Restaurants/actions"
import { useGeneralContext } from "../../shared/contexts/StoreProvider"
import { useGetAllRestaurantsByUserQuery } from "../../ui/Layouts/MainLayout/Sidebar/DropdownMenu/GraphQL/getAllRestaurantsByUser.generated"

export const UpdateRestaurants = () => {
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      selectedLocation: { uuid: locationUUID },
      auth,
    },
    dispatch,
  } = useGeneralContext()

  useGetAllRestaurantsByUserQuery({
    variables: {
      userUUID: auth.admin.uuid,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const restaurants = data.getAllRestaurantsByUser.reverse()
      dispatch({ type: ActionTypes.AddAllRestaurants, payload: restaurants })
      dispatch({
        type: ActionTypes.SetCurrentState,
        payload: {
          restaurantUUID: restaurantUUID,
          locationUUID: locationUUID,
        },
      })
    },
  })

  return null
}
