import { DateRangeEnum } from "../../../../shared/v2/utils/dateRange.enum"

export const includedStatuses = [
  DateRangeEnum.CUSTOM,
  DateRangeEnum.LAST_WEEK,
  DateRangeEnum.LAST_YEAR,
  DateRangeEnum.PREVIOUS_30_DAYS,
  DateRangeEnum.THIS_MONTH,
  DateRangeEnum.LAST_MONTH,
  DateRangeEnum.THIS_WEEK,
  DateRangeEnum.THIS_YEAR,
  DateRangeEnum.TODAY,
  DateRangeEnum.YESTERDAY,
]
