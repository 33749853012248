import yup from "../../../../shared/yup"
import { EmployeeTimeEntry } from "../TimeEntriesContext"
import { convertToDate } from "../utils/convert-to-iso-string.util"
import {
  DATE_ERROR_ID,
  ENTRY_OVERLAP_ERROR_ID,
  FUTURE_DATE_ERROR_ID,
  INCONSISTENT_DATES_ERROR_ID,
  REQUIRED_PAY_RATE_ERROR_ID,
  REQUIRED_VALUE_ERROR_ID,
} from "./errors.title"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment-timezone"
import { ValidationError } from "yup"

const TimeEntryFormSchema = yup
  .object({
    uuid: yup.string().optional(),
    userUUID: yup.string().required(REQUIRED_VALUE_ERROR_ID),
    startDate: yup.string().required(REQUIRED_VALUE_ERROR_ID),
    endDate: yup.string().required(REQUIRED_VALUE_ERROR_ID),
    startTime: yup.string().required(REQUIRED_VALUE_ERROR_ID),
    endTime: yup.string().required(REQUIRED_VALUE_ERROR_ID),
    payRate: yup
      .number()
      .nullable(true)
      .test(REQUIRED_PAY_RATE_ERROR_ID, (value) => {
        if (!value)
          return new ValidationError(
            REQUIRED_PAY_RATE_ERROR_ID,
            true,
            "payRate"
          )

        return true
      }),
    notes: yup.string().optional(),
  })
  .test(DATE_ERROR_ID, "Invalid date, please try again.", (value, context) => {
    if (!value.userUUID) return false

    const { endDate, endTime, startDate, startTime, uuid, userUUID } = value

    if (!(endDate && endTime && startDate && startTime)) {
      return false
    }

    const errors: ValidationError[] = []
    const { employeeEntries } = context.options.context as {
      employeeEntries: EmployeeTimeEntry | undefined
    }

    const datesValidation = getDateErrors({
      end: {
        date: endDate,
        time: endTime,
      },
      start: {
        date: startDate,
        time: startTime,
      },
    })

    if (datesValidation) {
      errors.push(datesValidation)
    }

    const validationOverlaps = getOverlapErrors({
      fields: {
        uuid,
        userUUID,
        startDate,
        endDate,
        startTime,
        endTime,
      },
      employeeEntries,
    })

    if (validationOverlaps) {
      errors.push(validationOverlaps)
    }

    return errors.length > 0 ? errors[0] : true
  })

export const TimeEntryFormResolver = yupResolver(TimeEntryFormSchema)

interface ValidateArgs {
  fields: {
    uuid?: string
    userUUID: string
    startDate: string
    endDate: string
    startTime: string
    endTime: string
  }
  employeeEntries?: EmployeeTimeEntry
}

const getOverlapErrors = (args: ValidateArgs): undefined | ValidationError => {
  const { fields, employeeEntries } = args
  const fieldsWithErrors: string[] = []

  const newEntryEndDate = convertToDate(fields.endDate, fields.endTime)
  const newEntryStartDate = convertToDate(fields.startDate, fields.startTime)

  employeeEntries?.[fields.userUUID]?.forEach((entry) => {
    if (entry.uuid === fields.uuid) {
      return
    }

    const entryStart = moment(entry.startDate)
    const entryEnd = moment(entry.endDate)

    const isStartDateOverlapping =
      newEntryStartDate.isSameOrAfter(entryStart) &&
      newEntryStartDate.isSameOrBefore(entryEnd)

    const isEndDateOverlapping =
      newEntryEndDate.isSameOrAfter(entryStart) &&
      newEntryEndDate.isSameOrBefore(entryEnd)

    const isEntryOverlapping =
      entryStart.isSameOrAfter(newEntryStartDate) &&
      entryStart.isSameOrBefore(newEntryEndDate) &&
      entryEnd.isSameOrAfter(newEntryStartDate) &&
      entryEnd.isSameOrBefore(newEntryEndDate)

    isEntryOverlapping &&
      fieldsWithErrors.push("startDate", "endDate", "startTime", "endTime")
    isStartDateOverlapping && fieldsWithErrors.push("startDate", "startTime")
    isEndDateOverlapping && fieldsWithErrors.push("endDate", "endTime")
  })

  if (fieldsWithErrors.length > 0) {
    const errors: yup.ValidationError[] = []

    fieldsWithErrors.forEach((field) => {
      errors.push(
        new ValidationError(ENTRY_OVERLAP_ERROR_ID, true, field, "entryOverlap")
      )
    })

    return new ValidationError(errors)
  }
}

interface ValidateDateArgs {
  end: { date: string; time: string }
  start: { date: string; time: string }
}

const getDateErrors = (args: ValidateDateArgs): undefined | ValidationError => {
  const { end, start } = args

  const newEntryEndDate = convertToDate(end.date, end.time)
  const newEntryStartDate = convertToDate(start.date, start.time)
  const now = moment()
  const errors: ValidationError[] = []

  if (newEntryStartDate.isSameOrAfter(newEntryEndDate)) {
    errors.push(
      new ValidationError(INCONSISTENT_DATES_ERROR_ID, true, "endDate"),
      new ValidationError(INCONSISTENT_DATES_ERROR_ID, true, "endTime")
    )
  }

  if (newEntryStartDate.isSameOrAfter(now)) {
    errors.push(
      new ValidationError(FUTURE_DATE_ERROR_ID, true, "startDate"),
      new ValidationError(FUTURE_DATE_ERROR_ID, true, "startTime")
    )
  }

  if (newEntryEndDate.isSameOrAfter(now)) {
    errors.push(
      new ValidationError(FUTURE_DATE_ERROR_ID, true, "endDate"),
      new ValidationError(FUTURE_DATE_ERROR_ID, true, "endTime")
    )
  }

  if (errors.length > 0) return new ValidationError(errors)
}
