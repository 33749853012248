import { useDateRange } from "../../../shared/hooks/useDateRange"
import {
  FIRST_HOUR,
  FIRST_MINUTE,
  FIRST_SECOND,
  LAST_HOUR,
  LAST_MINUTE,
  LAST_SECOND,
} from "../../../shared/utils/constant/values"
import { dateInstanceFromPrimitives } from "../../../shared/utils/helpers/date-instance-from-primitives"
import { momentInstanceToPrimitives } from "../../../shared/utils/helpers/moment-instance-to-primitives"
import {
  DateRangeEnum,
  DateRangeStatus,
} from "../../../shared/v2/utils/dateRange.enum"
import styles from "./CalendarPickerModal.module.css"
import { dateRanges } from "./utils/date-ranges.util"
import { includedStatuses } from "./utils/include-and-exclude-status"
import { Button, Flex, Group, Modal, Stack } from "@mantine/core"
import { DatePicker } from "@mantine/dates"
import moment, { Moment } from "moment-timezone"
import { useIntl } from "react-intl"

interface ButtonProp {
  status: DateRangeEnum
  ranges: {
    startDate: Moment | null
    endDate: Moment | null
  }
}

const sidebarButtons: ButtonProp[] = [
  {
    status: DateRangeEnum.TODAY,
    ranges: dateRanges.today(),
  },
  {
    status: DateRangeEnum.YESTERDAY,
    ranges: dateRanges.yesterday(),
  },
  {
    status: DateRangeEnum.THIS_WEEK,
    ranges: dateRanges.thisWeek(),
  },
  {
    status: DateRangeEnum.LAST_WEEK,
    ranges: dateRanges.lastWeek(),
  },
  {
    status: DateRangeEnum.THIS_MONTH,
    ranges: dateRanges.thisMonth(),
  },
  {
    status: DateRangeEnum.LAST_MONTH,
    ranges: dateRanges.lastMonth(),
  },
  {
    status: DateRangeEnum.PREVIOUS_30_DAYS,
    ranges: dateRanges.prev30days(),
  },
  {
    status: DateRangeEnum.THIS_YEAR,
    ranges: dateRanges.thisYear(),
  },
  {
    status: DateRangeEnum.LAST_YEAR,
    ranges: dateRanges.lastYear(),
  },
  {
    status: DateRangeEnum.CUSTOM,
    ranges: {
      startDate: null,
      endDate: null,
    },
  },
]

interface CalendarPickerModalProps {
  opened: boolean
  onClose: () => void
  externalStatus?: DateRangeEnum[]
}

export const CalendarPickerModal = (props: CalendarPickerModalProps) => {
  const { opened, externalStatus, onClose } = props
  const intl = useIntl()

  const { dates, rangeType, updateDateRangeFilters, updateRangeTypeFilter } =
    useDateRange()

  const calendarDayClassNames = [styles["date-picker-day"]]

  const filterButtons = (
    buttons: ButtonProp[],
    statusesToInclude: DateRangeEnum[]
  ): ButtonProp[] => {
    return buttons.filter((item) => statusesToInclude.includes(item.status))
  }

  const filteredButtons: ButtonProp[] = filterButtons(
    sidebarButtons,
    externalStatus ?? includedStatuses
  )

  const handleRangeEnumChange = ({
    status,
    start,
    end,
  }: {
    status: DateRangeEnum
    start: Moment | null
    end: Moment | null
  }) => {
    if (!status) return

    if (end?.isSameOrAfter(moment(), "day")) {
      end = moment()
    }

    updateDateRangeFilters({
      startDate: start,
      endDate: end,
    })
    updateRangeTypeFilter(status)
  }

  const handleRangeDates = ([startDate, endDate]: [
    Date | null,
    Date | null
  ]) => {
    const start = startDate
      ? moment([
          startDate?.getFullYear(),
          startDate?.getMonth(),
          startDate?.getDate(),
          FIRST_HOUR,
          FIRST_MINUTE,
          FIRST_SECOND,
        ])
      : null

    const end = endDate
      ? moment([
          endDate?.getFullYear(),
          endDate?.getMonth(),
          endDate?.getDate(),
          LAST_HOUR,
          LAST_MINUTE,
          LAST_SECOND,
        ])
      : null

    if (end === null) {
      updateDateRangeFilters({ startDate: start, endDate: end })
      updateRangeTypeFilter(DateRangeEnum.CUSTOM)
      return
    }
    updateDateRangeFilters({ startDate: start, endDate: end })

    if (startDate && endDate) {
      onClose()
    }
  }

  return (
    <Modal
      size="xl"
      yOffset={55}
      opened={opened}
      onClose={onClose}
      overlayProps={{
        color: "transparent",
        opacity: 0,
      }}
      classNames={{
        inner: styles["modal-inner"],
        header: styles["modal-header"],
        body: styles["modal-body"],
      }}
    >
      <Flex gap={0}>
        <Stack px={8} py={16} spacing={4} className={styles["stack-content"]}>
          {filteredButtons.map((button, index) => (
            <Button
              key={index}
              fw={400}
              h={32}
              c={rangeType === button.status ? "white" : "gray.9"}
              variant={rangeType === button.status ? "filled" : "white"}
              className={styles["displayDate-picker-button"]}
              onClick={() =>
                handleRangeEnumChange({
                  status: button.status,
                  start: button.ranges.startDate,
                  end: button.ranges.endDate,
                })
              }
            >
              {intl.formatMessage(DateRangeStatus[button.status])}
            </Button>
          ))}
        </Stack>
        <Group w="100%" position="center">
          <DatePicker
            type="range"
            allowSingleDateInRange
            numberOfColumns={2}
            p={16}
            maxDate={new Date()}
            firstDayOfWeek={0}
            value={[
              dates.startDate
                ? dateInstanceFromPrimitives(
                    momentInstanceToPrimitives(dates.startDate)
                  )
                : null,
              dates.endDate
                ? dateInstanceFromPrimitives(
                    momentInstanceToPrimitives(dates.endDate)
                  )
                : null,
            ]}
            onChange={handleRangeDates}
            classNames={{
              day: calendarDayClassNames.join(" ").trimEnd(),
            }}
          />
        </Group>
      </Flex>
    </Modal>
  )
}
