import Done from "../../../pages/Onboarding/Done"
import SwipeableSteps from "../../../pages/Onboarding/components/SwipeableSteps"
import { OnboardingProvider } from "../../contexts/OnboardingProvider"
import { RestaurantCompletionStepsProvider } from "../../contexts/RestaurantCompletionStepsProvider"
import { AppSwitch } from "../AppSwitch/AppSwitch"
import { RestaurantRoutes } from "../RestaurantRoutes/RestaurantRoutes"
import SettingsRoutes from "../SettingsRoutes"
import paths from "../paths"
import { OnboardingRoute } from "./OnboardingRoute"
import { ProtectedOnboardingRoute } from "./ProtectedOnboardingRoute"
import { ProtectedRoute } from "./ProtectedRoute"
import React from "react"
import { Redirect } from "react-router-dom"

export const ProtectedRoutes: React.FC = () => {
  return (
    <ProtectedRoute authenticationPath={paths.guest.login}>
      <OnboardingProvider>
        <RestaurantCompletionStepsProvider>
          <AppSwitch>
            <OnboardingRoute
              exact
              path={paths.onboarding.root}
              component={SwipeableSteps}
            />
            <ProtectedOnboardingRoute
              exact
              path={paths.onboarding.done}
              component={Done}
            />
            <ProtectedOnboardingRoute
              exact
              path={paths.root}
              render={() => <Redirect to={paths.restaurants.root} />}
            />
            <ProtectedOnboardingRoute
              path={[paths.restaurants.root, paths.locations.root]}
              component={RestaurantRoutes}
            />
            <ProtectedOnboardingRoute
              path={paths.settings.root}
              component={SettingsRoutes}
            />
          </AppSwitch>
        </RestaurantCompletionStepsProvider>
      </OnboardingProvider>
    </ProtectedRoute>
  )
}
