import ItemCard from "../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { RMenuDetail } from "../../../../../shared/graphql/generated/types"
import { useBreadcrumb } from "../../../../../shared/hooks/useBreadcrumb"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import Spacer from "../../../../../ui/Spacer"
import Tooltip from "../../../../../ui/Tooltip"
import Text from "../../../../../ui/Typography/Text"
import Title from "../../../../../ui/Typography/Title"
import OwnershipTag from "../../../components/OwnershipTag"
import MenusStatusBadge from "../../../components/StatusBadge"
import CardVisibility from "../../components/CardVisibility"
import { CustomMenuCategoryTypeTitle } from "../../titles/menuType.title"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { Prompt } from "react-router-dom"

type MenuDetailProps = {
  menu: RMenuDetail
  hidden: boolean
  isDirty: boolean
  onHideClick: () => void
}

const MenuDetail: React.FC<MenuDetailProps> = ({
  menu,
  hidden,
  isDirty,
  onHideClick,
}) => {
  const intl = useIntl()

  const { combos } = useFlags()

  const {
    state: {
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  const { pushBreadcrumb } = useBreadcrumb()

  const {
    restaurants: {
      locations: { editItemByLocation },
    },
  } = paths

  const onClickItem = useCallback(
    (itemUUID: string) => {
      pushBreadcrumb(editItemByLocation(locationUUID, itemUUID), {
        currentPageName: menu.name,
      })
    },
    [editItemByLocation, locationUUID, menu.name, pushBreadcrumb]
  )

  return (
    <>
      <Container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="16px"
      >
        <Title weight="bold" size="s">
          {menu.name}
        </Title>

        <Button
          type="button"
          hierarchy="tertiary"
          onClick={onHideClick}
          leadingIcon={hidden ? "ri-eye-line" : "ri-eye-off-line"}
          shape="square"
          size="large"
        />
      </Container>

      <Spacer size={12} />

      <Container>
        <Tooltip
          title={intl.formatMessage({
            id: "restaurants.locations.menus.corporate.menu.published.tooltip",
            defaultMessage: "Visible to customers",
          })}
          placement="bottom"
        >
          <MenusStatusBadge status={menu.status} />
        </Tooltip>
      </Container>
      <Spacer size={8} />
      <Container display="flex" flexDirection="column" gap="64px">
        <OwnershipTag isMain={menu.isMain} />
        <Container display="flex" flexDirection="column" gap="12px">
          <Text weight="bold">
            {intl.formatMessage({
              id: "restaurants.locations.menus.corporate.menu.type",
              defaultMessage: "Type",
            })}
          </Text>
          <Text>
            {intl.formatMessage(CustomMenuCategoryTypeTitle[menu.menuType])}
          </Text>
        </Container>

        {menu.items.length > 0 && (
          <Container display="flex" flexDirection="column" gap="16px">
            <Text weight="bold">
              {intl.formatMessage({
                id: "restaurants.locations.menus.corporate.menu.items",
                defaultMessage: "Items",
              })}
            </Text>
            <Container>
              {menu.items.map((item) => {
                return (
                  <ItemCard
                    key={item.uuid}
                    src={item?.attachment?.signedUrl}
                    title={item.name}
                    imageFit="contain"
                    onClick={() => onClickItem(item.uuid)}
                  >
                    <CardVisibility
                      isSoldOut={item.isSoldOut ?? false}
                      isVisible={item.isVisible ?? true}
                    />
                  </ItemCard>
                )
              })}
            </Container>
          </Container>
        )}

        {combos && menu.combos.length > 0 && (
          <Container display="flex" flexDirection="column" gap="16px">
            <Text weight="bold">
              {intl.formatMessage({
                id: "restaurants.locations.menus.corporate.menu.combos",
                defaultMessage: "Combos",
              })}
            </Text>
            <Container>
              {menu.combos.map((combo) => {
                return (
                  <ItemCard
                    key={combo.uuid}
                    src={combo?.attachment?.signedUrl}
                    title={combo.name}
                    imageFit="contain"
                  >
                    <CardVisibility
                      isSoldOut={!!combo.isSoldOut ?? false}
                      isVisible={combo.isVisible ?? true}
                    />
                  </ItemCard>
                )
              })}
            </Container>
          </Container>
        )}
      </Container>
      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You’ll lose your progress and your changes won’t be saved.",
        })}
      />
    </>
  )
}

export default MenuDetail
