import { ActionTypes as LoginActionTypes } from "../../pages/Login/actions"
import type { TokenResponseType } from "../../pages/Login/api"
import { ActionTypes } from "../../pages/Restaurants/actions"
import { showGraphqlErrors } from "../../ui/ErrorList"
import { useGetAllRestaurantsByUserQuery } from "../../ui/Layouts/MainLayout/Sidebar/DropdownMenu/GraphQL/getAllRestaurantsByUser.generated"
import storage from "../utils/storage"
import { setAuthentication } from "../utils/storage/auth"
import type { AppState, ReducerActions } from "./appReducer"
import { appReducer, appStateKey, initialAppState } from "./appReducer"
import React, { useContext, useEffect, useReducer } from "react"
import { useHistory } from "react-router-dom"

type Dispatch = (action: ReducerActions) => void

export const GeneralContext = React.createContext<{
  state: AppState
  dispatch: Dispatch
  initRestaurants: (data: TokenResponseType) => Promise<void>
  getSettingsRoot: () => { path: string }
  getMainRoot: () => { root: string }
} | null>(null)

export const StoreProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(appReducer, initialAppState)

  const { refetch } = useGetAllRestaurantsByUserQuery({
    skip: true,
    onError: showGraphqlErrors,
  })

  const getMainRoot = () => {
    const data = state.currentRestaurantUserRole
    const location = state.selectedLocation

    // Location Scope
    if (
      (data?.name === "RESTAURANT_MANAGER" ||
        data?.name === "RESTAURANT_EMPLOYEE") &&
      !!location.uuid
    ) {
      return {
        root: location.root,
      }
    }

    // Restaurant Scope
    return { root: state.currentRestaurant.root }
  }

  const getSettingsRoot = () => {
    const data = state.currentRestaurantUserRole
    const location = state.selectedLocation

    // Location Scope
    if (
      (data?.name === "RESTAURANT_MANAGER" ||
        data?.name === "RESTAURANT_EMPLOYEE") &&
      !!location.uuid
    ) {
      return {
        path: location.settings,
      }
    }

    // Restaurant Scope
    return {
      path: state?.currentRestaurant.settings,
    }
  }

  const initRestaurants = async ({
    refreshToken,
    accessToken,
    admin,
  }: TokenResponseType) => {
    if (accessToken) {
      setAuthentication(accessToken, refreshToken)
      dispatch({ type: LoginActionTypes.Login, payload: { admin } })
    }

    const {
      data: { getAllRestaurantsByUser },
    } = await refetch({ userUUID: admin.uuid })
    let restaurants = getAllRestaurantsByUser

    try {
      restaurants = [...getAllRestaurantsByUser].reverse()
    } catch {}

    dispatch({ type: ActionTypes.AddAllRestaurants, payload: restaurants })

    const restaurant = restaurants?.[0]

    if (!restaurant) {
      return
    }

    dispatch({
      type: ActionTypes.SetCurrentState,
      payload: { restaurantUUID: restaurant.uuid },
    })
  }

  useEffect(() => {
    if (state.auth.admin.uuid) {
      storage.set(appStateKey, state)
    }
  }, [state])

  useEffect(
    () => {
      if (
        state.currentRestaurant.uuid &&
        !!state.currentRestaurant.refirectToRoot
      ) {
        const { root } = getMainRoot()

        history.replace(root)
        dispatch({ type: ActionTypes.ClearRedirect, payload: undefined })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.currentRestaurant.uuid]
  )

  return (
    <GeneralContext.Provider
      value={{
        state,
        dispatch,
        initRestaurants,
        getSettingsRoot,
        getMainRoot,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

export const useGeneralContext = () => {
  const context = useContext(GeneralContext)

  if (context === null) {
    throw new Error("useGeneralContext must be used within a StoreProvider")
  }

  return context
}
