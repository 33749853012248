import type {
  GetModifierGroupListQuery,
  GetModifierGroupListQueryVariables,
} from "../../GraphQL/getModiferGroupList.generated"
import { GetModifierGroupListDocument } from "../../GraphQL/getModiferGroupList.generated"
import type { ApolloCache } from "@apollo/client"

type WriteCacheGetModifierGroupListQuery = {
  cache: ApolloCache<unknown>
  query: GetModifierGroupListQuery
  variables: GetModifierGroupListQueryVariables
}

const writeGetModifierGroupListQueryCache = ({
  cache,
  query,
  variables,
}: WriteCacheGetModifierGroupListQuery) =>
  cache.writeQuery<
    GetModifierGroupListQuery,
    GetModifierGroupListQueryVariables
  >({
    query: GetModifierGroupListDocument,
    data: query,
    variables,
  })

export default writeGetModifierGroupListQueryCache
