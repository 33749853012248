import MultipleSelectImage from "../../../../../../shared/assets/images/multiple-example.svg"
import RangeSelectImage from "../../../../../../shared/assets/images/range-example.svg"
import SingleSelectImage from "../../../../../../shared/assets/images/single-example.svg"
import { IntlMessage } from "../../../../../../shared/types"
import Container from "../../../../../../ui/Container"
import Text from "../../../../../../ui/Typography/Text"
import React from "react"
import { useIntl } from "react-intl"

type SelectOptionsExample = {
  selectOption: string | undefined
}

export const SelectOptionExample: React.FC<SelectOptionsExample> = ({
  selectOption,
}) => {
  const intl = useIntl()

  const options: {
    [key: string]: { img: string } & IntlMessage
  } = {
    RADIO: {
      img: SingleSelectImage,
      id: "restaurants.menu.items.forms.select.options.example.tooltip.radio",
      defaultMessage: "Choose only one",
    },
    CHECKBOX: {
      img: MultipleSelectImage,
      id: "restaurants.menu.items.forms.select.options.example.tooltip.checkbox",
      defaultMessage: "Optional Choices",
    },
    COUNTER: {
      img: RangeSelectImage,
      id: "restaurants.menu.items.forms.select.options.example.tooltip.counter",
      defaultMessage: "Range Choices",
    },
  }

  if (!selectOption) return null

  const { id, defaultMessage, img } = options[selectOption]

  return (
    <Container padding="16px" display="flex" gap="8px" flexDirection="column">
      <img src={img} />
      <Text color="Neutral0">{intl.formatMessage({ id, defaultMessage })}</Text>
    </Container>
  )
}
