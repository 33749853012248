import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddCommentToOrderMutationVariables = Types.Exact<{
  data: Types.AddCommentToOrderInput;
}>;


export type AddCommentToOrderMutation = { __typename?: 'Mutation', addCommentToOrder: { __typename?: 'OrderActionModel', uuid: string, actionAt: any, actionType: Types.OrderActionTypeEnum, comment?: string | null, admin?: { __typename?: 'AdminBasicModel', uuid: string, firstName: string, lastName?: string | null } | null } };


export const AddCommentToOrderDocument = gql`
    mutation addCommentToOrder($data: AddCommentToOrderInput!) {
  addCommentToOrder(data: $data) {
    uuid
    actionAt
    actionType
    admin {
      uuid
      firstName
      lastName
    }
    comment
  }
}
    `;
export type AddCommentToOrderMutationFn = Apollo.MutationFunction<AddCommentToOrderMutation, AddCommentToOrderMutationVariables>;

/**
 * __useAddCommentToOrderMutation__
 *
 * To run a mutation, you first call `useAddCommentToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToOrderMutation, { data, loading, error }] = useAddCommentToOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCommentToOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToOrderMutation, AddCommentToOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentToOrderMutation, AddCommentToOrderMutationVariables>(AddCommentToOrderDocument, options);
      }
export type AddCommentToOrderMutationHookResult = ReturnType<typeof useAddCommentToOrderMutation>;
export type AddCommentToOrderMutationResult = Apollo.MutationResult<AddCommentToOrderMutation>;
export type AddCommentToOrderMutationOptions = Apollo.BaseMutationOptions<AddCommentToOrderMutation, AddCommentToOrderMutationVariables>;