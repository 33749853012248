import emptyIcon from "../../../../../shared/assets/icons/empty_icon.svg"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import EmptyState from "../../../../../ui/EmptyState"
import ModalFull, { ModalFullSkeleton } from "../../../../../ui/ModalFull"
import ErrorPage from "../../../../ErrorPage"
import CorporateComboDetail from "../CorporateComboDetail"
import { useGetComboDetailQuery } from "../GraphQL/getComboDetail.generated"
import { EditCombo } from "./EditCombo"
import get from "lodash/get"
import React from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

type EditContainerProps = {
  isCorporate: boolean
}

export const EditComboContainer: React.FC<EditContainerProps> = ({
  isCorporate = true,
}) => {
  const intl = useIntl()
  const { comboUUID } = useParams<{ comboUUID: string }>()
  const history = useHistory()

  const { data, loading, error } = useGetComboDetailQuery({
    variables: { uuid: comboUUID },
    fetchPolicy: "network-only",
  })

  const isMain = get(data, "getComboDetail.isMain")
  const combo = get(data, "getComboDetail")

  const onClickHandler = () => history.goBack()

  if (loading) return <ModalFullSkeleton />

  if (error) return <ErrorPage error={error} />

  if (!combo) {
    return (
      <ModalFull headerColor="Neutral2" visible>
        <EmptyState
          title={intl.formatMessage({
            id: "restaurants.menu.items.edit.combo.container.empty.title",
            defaultMessage: "Combo not found",
          })}
          button={
            <Container margin="24px 0px 0px 0px">
              <Button
                hierarchy="secondary"
                onClick={onClickHandler}
                title={intl.formatMessage({
                  id: "restaurants.menu.items.edit.combo.container.back.title",
                  defaultMessage: "Go Back",
                })}
              />
            </Container>
          }
          image={emptyIcon}
          fullScreen
        />
      </ModalFull>
    )
  }

  if (!isCorporate && isMain) return <CorporateComboDetail combo={combo} />

  return <EditCombo combo={combo} isCorporate={isCorporate} />
}

export const EditCorporateComboContainer = () => (
  <EditComboContainer isCorporate={false} />
)
