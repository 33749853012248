import styled, { css } from "styled-components"

export const OrderActionDivider = () => {
  return <StyledActionDivider />
}

const StyledActionDivider = styled.div`
  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.colors.Neutral4};
    width: 100%;
  `}
`
