import { sessionHelper } from "../../shared/classes/UuidSessionHelper"
import { useGeneralContext } from "../../shared/contexts/StoreProvider"

const UuidSyncComponent = () => {
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      selectedLocation: { uuid: locationUUID },
    },
  } = useGeneralContext()

  sessionHelper.setRestaurantUUID(restaurantUUID)
  sessionHelper.setLocationUUID(locationUUID)

  return null
}
export default UuidSyncComponent
