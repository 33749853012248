export const MIN_ZOOM = 17
export const DEFAULT_ZOOM = 17
export const DEFAULT_GOOGLE_LOCATION_REDIRECT =
  "https://www.google.com/maps/search/?api=1&query="
export const DEFAULT_COORDINATES: google.maps.LatLngLiteral = {
  lat: 40.378_07,
  lng: -111.811_31,
}
export const lockedMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  scrollwheel: false,
  streetViewControl: false,
  maxZoom: 17,
  minZoom: 17,
  gestureHandling: "none",
  panControl: false,
}
export const fullScreenMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  scrollwheel: true,
  streetViewControl: false,
  minZoom: MIN_ZOOM,
}
