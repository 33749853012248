import {
  GetComboListQuery,
  useGetComboListQuery,
} from "../../../../../../../GraphQL/Queries/getComboList.generated"
import { updateGetComboListQuery } from "../../../../../../../shared/graphql/updateQuery/updateGetComboListQuery"
import {
  DEBOUNCE_MILLISECONDS,
  EMPTY_ARRAY,
  EMPTY_STRING,
  WHITE_SPACE,
} from "../../../../../../../shared/utils/constant/values"
import { canFetchMore } from "../../../../../../../shared/utils/helpers/canFetchMore"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import { onPopupScroll } from "../../../../../../../ui/Select/helpers/onPopupScroll"
import { IDiscountForm } from "../../../../interfaces/hookforms.interfaces"
import { EDIT_MODE_NAME } from "../../../GenericForm/constants"
import {
  APPLY_ALL_COMBOS_NAME,
  COMBOS_FIELD_NAME,
  ENTIRE_ORDER_NAME,
  SET_VALUE_OPTIONS,
  TAKE_QUANTITY,
} from "../../constants"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

export const useCombosSelector = () => {
  const [search, setSearch] = useState<string | undefined>()

  const { watch, setValue, trigger, getValues } =
    useFormContext<IDiscountForm>()

  const isEditMode = getValues(EDIT_MODE_NAME)

  const {
    data,
    loading,
    refetch: refetchCombos,
    fetchMore,
  } = useGetComboListQuery({
    variables: { take: TAKE_QUANTITY },
    skip: isEditMode,
    fetchPolicy: "cache-and-network",
  })

  const selectedCombos = watch(COMBOS_FIELD_NAME)
  const applyToAllCombos = watch(APPLY_ALL_COMBOS_NAME)

  const hasNextPage = data?.getComboList?.hasNextPage ?? false
  const endCursor = data?.getComboList?.endCursor
  const canFetchMoreCombos = canFetchMore(loading, hasNextPage, endCursor)

  const combos =
    data?.getComboList?.results?.map((combo) => ({
      value: combo.uuid,
      label: combo.name,
    })) ?? []

  selectedCombos?.forEach((currentCombo) => {
    if (!combos?.map((combo) => combo.value).includes(currentCombo.uuid))
      combos?.push({
        value: currentCombo.uuid,
        label: currentCombo.name,
      })
  })

  const onComboSelected = (comboOption: string[]) => {
    const formattedCombos = combos
      ?.filter((combo) => comboOption.includes(combo.value))
      .map((filteredCombo) => ({
        uuid: filteredCombo.value,
        name: filteredCombo.label,
      }))

    if (!formattedCombos) return

    setValue(COMBOS_FIELD_NAME, formattedCombos, SET_VALUE_OPTIONS)
    setValue(APPLY_ALL_COMBOS_NAME, false, SET_VALUE_OPTIONS)
    trigger(ENTIRE_ORDER_NAME)
  }

  const searchCombos = async (comboName: string) => {
    try {
      await refetchCombos({
        name: comboName === EMPTY_STRING ? undefined : comboName,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onAllCombosClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
    event.preventDefault()

    const isChecked = applyToAllCombos

    if (!isChecked) {
      setValue(COMBOS_FIELD_NAME, EMPTY_ARRAY, SET_VALUE_OPTIONS)
    }

    setSearch(EMPTY_STRING)
    setValue(APPLY_ALL_COMBOS_NAME, !isChecked, SET_VALUE_OPTIONS)
    searchCombos(EMPTY_STRING)
    trigger(ENTIRE_ORDER_NAME)
  }

  const onDebounceSearchCombos = React.useMemo(() => {
    return debounce(searchCombos, DEBOUNCE_MILLISECONDS)
  }, [])

  const handleSearchCombos = (comboName: string) => {
    if (comboName === WHITE_SPACE) return

    setSearch(comboName)

    if (!comboName) {
      onDebounceSearchCombos.cancel()
      searchCombos(comboName)
      return
    }
    onDebounceSearchCombos(comboName)
  }

  const fetchMoreCombos = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          after: endCursor,
          take: TAKE_QUANTITY,
        },
        updateQuery: (prev: GetComboListQuery, { fetchMoreResult }) =>
          updateGetComboListQuery(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor])

  const onScrollToFetchMore = (
    scrollEvent: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    onPopupScroll(scrollEvent, canFetchMoreCombos, fetchMoreCombos)
  }

  useEffect(() => {
    return () => {
      onDebounceSearchCombos.cancel()
    }
  }, [])

  return {
    combos,
    loading,
    onComboSelected,
    onAllCombosClick,
    handleSearchCombos,
    onScrollToFetchMore,
    search,
    selectedCombos,
    applyToAllCombos,
    isEditMode,
  }
}
