import type { OrderActionModel } from "../../../../shared/graphql/generated/types"
import { OrderActionTypeEnum } from "../../../../shared/graphql/generated/types"
import get from "lodash/get"

const calculateElapsedTime = (
  orderActions: OrderActionModel[] | null | undefined,
  expiresAt: string
) => {
  try {
    if (!orderActions?.length) {
      return 0
    }

    const firstAction = get(orderActions, "0.actionAt")
    const lastAction = get(orderActions, `${orderActions.length - 1}.actionAt`)

    if (!firstAction || !lastAction) {
      return 0
    }

    const actionType = orderActions[orderActions?.length - 1].actionType
    const createdAt = new Date(firstAction)

    if (
      actionType === OrderActionTypeEnum.CANCELLED ||
      actionType === OrderActionTypeEnum.CLOSED ||
      actionType === OrderActionTypeEnum.COMPLETED
    ) {
      const finishedAt = new Date(lastAction)
      const elapsedTime = finishedAt.getTime() - createdAt.getTime()

      return new Date(elapsedTime).toISOString().slice(11, 19)
    }

    const now = new Date()
    const expiresAtDate = new Date(expiresAt)
    const elapsedTimeExpires = expiresAtDate.getTime() - now.getTime()

    if (elapsedTimeExpires < 0) {
      const elapsedTime = expiresAtDate.getTime() - createdAt.getTime()

      return new Date(elapsedTime).toISOString().slice(11, 19)
    }

    const elapsedTime = now.getTime() - createdAt.getTime()

    return new Date(elapsedTime).toISOString().slice(11, 19)
  } catch {
    return 0
  }
}

export default calculateElapsedTime
