import { END_TIME, START_TIME } from "../../../../shared/utils/constant/values"
import { useEmployeesQuery } from "../../../Settings/Locations/Employees/GraphQL/employees.generated"
import {
  TimeEntriesByLocationEmployeeDocument,
  useTimeEntriesByLocationEmployeeLazyQuery,
} from "../Graphql/timeEntriesByLocationEmployee.generated"
import { useUpsertTimeEntryMutation } from "../Graphql/upsertTimeEntry.generated"
import { EmployeeTimeEntry, useTimeEntriesContext } from "../TimeEntriesContext"
import { TimeEntryType } from "../types/employee.type"
import { convertToDate } from "../utils/convert-to-iso-string.util"
import { get24hoursTimeFormat } from "../utils/get-24-hours-time-format.util"
import { parseEmployeesTimeEntry } from "../utils/parse-employees-time-entry.util"
import styles from "./TimeEntryForm.module.css"
import { TimeEntryFormResolver } from "./TimeEntryFormResolver.yup"
import { errorMessages } from "./errors.title"
import { TimeEntryFormType } from "./hookforms.interfaces"
import {
  Button,
  Flex,
  Grid,
  Select,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from "@mantine/core"
import { DatePickerInput, TimeInput } from "@mantine/dates"
import { notifications } from "@mantine/notifications"
import { IconCalendarEvent } from "@tabler/icons-react"
import moment from "moment-timezone"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type TimeEntryFormProps = {
  onSubmit: () => void
  timeEntry?: TimeEntryType
  employeeEntries: EmployeeTimeEntry
}

export const TimeEntryForm = ({
  onSubmit,
  timeEntry,
  employeeEntries,
}: TimeEntryFormProps) => {
  const intl = useIntl()
  const mantineTheme = useMantineTheme()
  const isEditForm = !!timeEntry
  const { updateData } = useTimeEntriesContext()

  const formMethods = useForm<TimeEntryFormType>({
    context: { employeeEntries },
    resolver: TimeEntryFormResolver,
    defaultValues: {
      ...(isEditForm && {
        uuid: timeEntry.uuid,
        userUUID: timeEntry.employee?.uuid,
        startDate: timeEntry.startDate,
        startTime: get24hoursTimeFormat(timeEntry.startDate),
        ...(timeEntry.endDate && {
          endDate: timeEntry.endDate,
          endTime: get24hoursTimeFormat(timeEntry.endDate),
        }),
        payRate: timeEntry.employee?.payRate,
        notes: timeEntry.notes ?? "",
      }),
    },
  })

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors, isDirty },
  } = formMethods

  const { data } = useEmployeesQuery({
    variables: { isActive: true },
    fetchPolicy: "network-only",
  })

  const [getTimeEntriesQuery, { loading: timeEntriesLoading }] =
    useTimeEntriesByLocationEmployeeLazyQuery({
      fetchPolicy: "network-only",
    })
  const [upsertTimeEntry, { loading: upsertLoading }] =
    useUpsertTimeEntryMutation()

  const loading = timeEntriesLoading || upsertLoading

  const getTimeEntries = async ({
    startDate,
    endDate,
  }: {
    startDate: string
    endDate: string
  }) => {
    const result = await getTimeEntriesQuery({
      variables: {
        endDate,
        startDate,
      },
    })

    const timeEntries =
      result.data?.timeEntriesByLocationEmployee.employeeTimeEntries

    if (!timeEntries) return

    updateData(parseEmployeesTimeEntry(timeEntries))
    triggerInputValidation()
  }

  const handleUpsertEntry = async (formData: TimeEntryFormType) => {
    if (!isDirty) {
      return onSubmit()
    }
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      notes,
      userUUID: uuid,
    } = formData
    const startDateAndTime = convertToDate(startDate, startTime)
    const endDateAndTime = convertToDate(endDate, endTime)

    upsertTimeEntry({
      variables: {
        input: {
          ...(isEditForm && { uuid: timeEntry.uuid }),
          employee: { uuid },
          startDate: startDateAndTime.toISOString(),
          endDate: endDateAndTime.toISOString(),
          notes: notes || null,
        },
      },
      onCompleted: () => onSubmit(),
      onError: () =>
        notifications.show({
          title: intl.formatMessage({
            id: "generic.error.try.again.message",
            defaultMessage: "Something went wrong. Try again later.",
          }),
          message: "If the problem persists, please contact support.",
          color: "red",
          withBorder: true,
        }),
      refetchQueries: [TimeEntriesByLocationEmployeeDocument],
    })
  }

  const handleEntrySubmit = async () => {
    const startDate = getValues("startDate")
    const endDate = getValues("endDate")

    if (startDate && endDate) {
      await getTimeEntries({
        startDate: convertToDate(startDate, START_TIME).toISOString(),
        endDate: convertToDate(endDate, END_TIME).toISOString(),
      })
    }

    handleSubmit(handleUpsertEntry)()
  }

  const triggerInputValidation = () => {
    trigger("endTime")
    trigger("endDate")
    trigger("startDate")
    trigger("startTime")
  }

  return (
    <Stack spacing={16}>
      <Controller
        name="userUUID"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            error={
              !!errors.userUUID ||
              (errors.payRate?.message
                ? intl.formatMessage(errorMessages[errors.payRate?.message])
                : undefined)
            }
            classNames={{
              label: styles["form-label"],
            }}
            label={
              <Text color="gray.9" fw={600}>
                {intl.formatMessage({
                  id: "restaurant.time.entries.time.entry.form.select.label",
                  defaultMessage: "User",
                })}
              </Text>
            }
            searchable
            required
            disabled={isEditForm}
            onChange={(value) => {
              field.onChange(value)
              const payRate = data?.employees.find(
                (e) => e.uuid === value
              )?.payRate

              setValue("payRate", payRate ?? null, { shouldValidate: true })
            }}
            data={
              data?.employees.map((e) => ({
                value: e.uuid,
                label: `${e.firstName} ${e.lastName}`,
              })) ?? []
            }
          />
        )}
      />
      <Grid>
        <Grid.Col span={6}>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePickerInput
                {...field}
                value={field.value ? parseDateForDatePicker(field.value) : null}
                onChange={(date) => {
                  if (!date) return

                  const seconds = 0
                  const minutes = 0
                  const hours = 0
                  const day = date.getDate()
                  const month = date.getMonth()
                  const year = date.getFullYear()

                  field.onChange(
                    moment([
                      year,
                      month,
                      day,
                      hours,
                      minutes,
                      seconds,
                    ]).toISOString()
                  )
                  triggerInputValidation()
                }}
                error={
                  errors.startDate?.message
                    ? intl.formatMessage(
                        errorMessages[errors.startDate?.message]
                      )
                    : undefined
                }
                rightSection={
                  <IconCalendarEvent
                    size={20}
                    color={mantineTheme.colors.gray[6]}
                  />
                }
                classNames={{
                  label: styles["form-label"],
                }}
                label={
                  <Text color="gray.9" fw={600}>
                    {intl.formatMessage({
                      id: "restaurant.time.entries.time.entry.form.input.start.date",
                      defaultMessage: "Start Date",
                    })}
                  </Text>
                }
                withAsterisk
                popoverProps={{ zIndex: 1000, withinPortal: true }}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            name="startTime"
            control={control}
            render={({ field }) => (
              <TimeInput
                {...field}
                onChange={(time) => {
                  field.onChange(time)
                  triggerInputValidation()
                }}
                error={!!errors.startTime}
                classNames={{
                  label: styles["form-label"],
                }}
                label={
                  <Text color="gray.9" fw={600}>
                    {intl.formatMessage({
                      id: "restaurant.time.entries.time.entry.form.input.start.time.label",
                      defaultMessage: "Start Time",
                    })}
                  </Text>
                }
                required
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePickerInput
                {...field}
                value={field.value ? parseDateForDatePicker(field.value) : null}
                error={
                  errors.endDate?.message
                    ? intl.formatMessage(errorMessages[errors.endDate?.message])
                    : undefined
                }
                onChange={(date) => {
                  if (!date) return

                  const seconds = 0
                  const minutes = 0
                  const hours = 0
                  const day = date.getDate()
                  const month = date.getMonth()
                  const year = date.getFullYear()

                  field.onChange(
                    moment([
                      year,
                      month,
                      day,
                      hours,
                      minutes,
                      seconds,
                    ]).toISOString()
                  )

                  triggerInputValidation()
                }}
                rightSection={
                  <IconCalendarEvent
                    size={20}
                    color={mantineTheme.colors.gray[6]}
                  />
                }
                classNames={{
                  label: styles["form-label"],
                }}
                label={
                  <Text color="gray.9" fw={600}>
                    {intl.formatMessage({
                      id: "restaurant.time.entries.time.entry.form.input.end.date",
                      defaultMessage: "End Date",
                    })}
                  </Text>
                }
                withAsterisk
                popoverProps={{ zIndex: 1000, withinPortal: true }}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            name="endTime"
            control={control}
            render={({ field }) => (
              <TimeInput
                {...field}
                error={!!errors.endTime}
                classNames={{
                  label: styles["form-label"],
                }}
                onChange={(time) => {
                  field.onChange(time)
                  triggerInputValidation()
                }}
                label={
                  <Text color="gray.9" fw={600}>
                    {intl.formatMessage({
                      id: "restaurant.time.entries.time.entry.form.input.end.time.label",
                      defaultMessage: "End Time",
                    })}
                  </Text>
                }
                required
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Controller
        name="notes"
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            classNames={{
              label: styles["form-label"],
            }}
            label={
              <Text color="gray.9" fw={600}>
                {intl.formatMessage({
                  id: "restaurant.time.entries.time.entry.form.textarea.note",
                  defaultMessage: "Note",
                })}
              </Text>
            }
          />
        )}
      />
      <Flex justify="flex-end">
        <Button
          loading={loading}
          variant="filled"
          color="primary.6"
          w={75}
          h={40}
          onClick={handleEntrySubmit}
        >
          {!loading &&
            intl.formatMessage({
              id: "restaurant.time.entries.time.entry.form.submit",
              defaultMessage: "Save",
            })}
        </Button>
      </Flex>
    </Stack>
  )
}

const parseDateForDatePicker = (val: string) => {
  const hours = 0
  const minutes = 0
  const seconds = 0
  const day = moment(val).date()
  const month = moment(val).month()
  const year = moment(val).year()
  const date = new Date(year, month, day, hours, minutes, seconds)
  return date
}
