import { Timeline } from "antd"
import styled, { css } from "styled-components"

export const StyledTimeline = styled(Timeline)`
  ${({ theme }) => css`
    .ant-timeline-item-head {
      background-color: ${theme.colors.Secondary6};
      border-color: ${theme.colors.Secondary6};
      margin-top: 5px;
      width: 8px;
      height: 8px;
    }
    .ant-timeline-item-tail {
      border-left: 2px solid ${theme.colors.Secondary4};
      height: calc(100% - 3px);
      top: 0px;
      left: 3px;
      margin-top: 18px;
    }
  `}
`
