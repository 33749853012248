import {
  ALL_DAY_VALUE,
  HH,
  isoStringHoursFormat,
  mm,
} from "../../../../shared/utils/constant/dateFormats"
import { DAY_START_HOUR } from "../../../../ui/CustomTimePicker/constants"
import { MeridiemEnum } from "../../../../ui/TimePickerSelect"
import { DateRule } from "../interfaces/dateRule.interfaces"
import moment from "moment-timezone"

export const generateTimeFromForm = (input: DateRule) => {
  try {
    const { date, hour } = input

    let time: string | undefined

    if (hour?.time === ALL_DAY_VALUE) {
      time = `${DAY_START_HOUR} ${MeridiemEnum.AM}`
    }

    if (hour.time && hour.meridium) {
      time = `${hour.time} ${hour.meridium}`
    }

    if (!time) {
      throw new Error("Time is not defined")
    }

    const fullTime = moment(time, isoStringHoursFormat)

    return moment(date).set({
      hour: Number(fullTime.format(HH)),
      minute: Number(fullTime.format(mm)),
      second: 0,
      millisecond: 0,
    })
  } catch (error) {
    if (error instanceof Error) {
      console.error(error)
    }
  }
}
