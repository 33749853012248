import { SystemProp } from "@mantine/core"
import { CSSProperties } from "react"
import { FormattedMessage } from "react-intl"

type TableHeader = {
  title: JSX.Element
  alignment?: SystemProp<CSSProperties["textAlign"]>
}

export const tableHeaders: TableHeader[] = [
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.number"
        defaultMessage="Order number"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.method"
        defaultMessage="Order method"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.status"
        defaultMessage="Status"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.payment.status"
        defaultMessage="Payment status"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.customer"
        defaultMessage="Customer"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.placed"
        defaultMessage="Order placed"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.event.date"
        defaultMessage="Event date"
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.headcount"
        defaultMessage="Headcount"
      />
    ),
    alignment: "end",
  },
  {
    title: (
      <FormattedMessage
        id="restaurants.orders.table.header.catering.order.total"
        defaultMessage="Total"
      />
    ),
    alignment: "end",
  },
]
