import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCateringCommentMutationVariables = Types.Exact<{
  input: Types.CreateCommentInput;
}>;


export type CreateCateringCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'OrderComment', content: string, createdAt: any, uuid: string } };


export const CreateCateringCommentDocument = gql`
    mutation createCateringComment($input: CreateCommentInput!) {
  createComment(data: $input) {
    content
    createdAt
    uuid
  }
}
    `;
export type CreateCateringCommentMutationFn = Apollo.MutationFunction<CreateCateringCommentMutation, CreateCateringCommentMutationVariables>;

/**
 * __useCreateCateringCommentMutation__
 *
 * To run a mutation, you first call `useCreateCateringCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCateringCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCateringCommentMutation, { data, loading, error }] = useCreateCateringCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCateringCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCateringCommentMutation, CreateCateringCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCateringCommentMutation, CreateCateringCommentMutationVariables>(CreateCateringCommentDocument, options);
      }
export type CreateCateringCommentMutationHookResult = ReturnType<typeof useCreateCateringCommentMutation>;
export type CreateCateringCommentMutationResult = Apollo.MutationResult<CreateCateringCommentMutation>;
export type CreateCateringCommentMutationOptions = Apollo.BaseMutationOptions<CreateCateringCommentMutation, CreateCateringCommentMutationVariables>;