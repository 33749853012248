import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const DeleteRestaurantSchema = yup.object().shape({
  word: yup
    .string()
    .required()
    .test({
      name: "validate loyalty",
      test: (values, { createError }) => {
        if (values !== "DELETE") {
          return createError({
            message: "Please write DELETE to confirm this operation",
            path: "word",
          })
        }

        return true
      },
    }),
})

export const RestaurantDeleteResolver = yupResolver(DeleteRestaurantSchema)
