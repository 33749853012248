import { DiscountSubStatusEnum } from "../../../shared/graphql/generated/types"

export const subStatusTitle = {
  [DiscountSubStatusEnum.EXPIRED]: {
    id: "restaurants.discounts.expired",
    defaultMessage: "Expired",
  },
  [DiscountSubStatusEnum.MANUALLY_DISABLED]: {
    id: "restaurants.discounts.manually.disabled",
    defaultMessage: "Manually Disabled",
  },
  [DiscountSubStatusEnum.ORDER_TYPE_UNAVAILABLE]: {
    id: "restaurants.discounts.order.type.unavailable",
    defaultMessage: "Order Type Unavailable",
  },
  [DiscountSubStatusEnum.USAGE_LIMIT_REACHED]: {
    id: "restaurants.discounts.usage.limit.reached",
    defaultMessage: "Usage Limit Reached",
  },
}
