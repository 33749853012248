import { useGoogleMapsContext } from "../../shared/contexts/GoogleMapsProvider"
import {
  ADDRESS_TYPES_REGEX,
  validateGoogleAddress,
} from "../../shared/utils/helpers/checkAddress"
import { InputFieldSkeleton } from "../../ui/Inputs"
import type { InputProps } from "../../ui/Inputs/Input/Input"
import Select, { OptionSelect } from "../../ui/Select"
import notification from "../../ui/notification"
import React, { useState } from "react"
import type { FieldError } from "react-hook-form"
import { useIntl } from "react-intl"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import styled from "styled-components"

export interface IAddressComponents {
  types: Array<string>
  long_name: string
  short_name: string
}

export interface IAddress {
  address?: string
  latitude?: string
  longitude?: string
  addressComponents?: Array<IAddressComponents>
}

interface IAutocompleteAddress {
  name: string
  searchTypes?: string[]
  inputProps: Omit<InputProps, "value"> & {
    value: string | undefined
    maxTagTextLength?: number
  }
  error: FieldError | undefined
  onSelect?: (data: IAddress) => void
  validateAddress?: () => void
}

export const AutocompleteAddress: React.FC<IAutocompleteAddress> = (props) => {
  const {
    searchTypes = ["address"],
    onSelect,
    name,
    error,
    validateAddress,
    inputProps: {
      helperText,
      value,
      disabled,
      onBlur,
      placeholder,
      maxTagTextLength,
      requirement,
      label,
      ...inputProps
    },
  } = props
  delete inputProps.onChange

  const intl = useIntl()

  const [openSelectAddress, setOpenSelectAddress] = useState<
    boolean | undefined
  >()
  const [selecting, setSelecting] = useState<boolean>(false)
  const [searchedAddress, setSearchedAddress] = useState<string | undefined>()

  const setAddressSelectVisible = () => setOpenSelectAddress(true)
  const setAddressSelectHidden = () => setOpenSelectAddress(false)

  const { isLoaded } = useGoogleMapsContext()

  const setAddressInfo = (result: { place_id: string }) => {
    const request = {
      placeId: result.place_id,
      fields: [
        "name",
        "photos",
        "address_components",
        "formatted_address",
        "geometry",
        "place_id",
      ],
    }

    const callback = (
      place: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && !!place) {
        const address = place.formatted_address
        const latitude = place.geometry?.location?.lat().toString()
        const longitude = place.geometry?.location?.lng().toString()

        onSelect?.({
          address,
          latitude,
          longitude,
          addressComponents:
            place.address_components &&
            place.address_components?.map(
              ({ types, long_name, short_name }) => ({
                types: types,
                long_name: long_name,
                short_name: short_name,
              })
            ),
        })
        validateAddress?.()
      }
    }

    /* global google*/
    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    )
    service.getDetails(request, callback)
  }

  const handleSelect = async (address: string) => {
    try {
      setSelecting(true)
      const geocodeResponse = await geocodeByAddress(address)

      if (!!geocodeResponse && !!geocodeResponse[0]) {
        setAddressInfo(geocodeResponse[0])
      } else {
        onSelect?.({ address, addressComponents: undefined })
        validateAddress?.()
      }
    } catch {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.location.form.address.google.error.message",
          defaultMessage: "Network issue: The address could not be retrieved",
        }),
      })
      onSelect?.({ address: undefined, addressComponents: undefined })
    } finally {
      setSelecting(false)
    }
  }

  const handleChange = async (address: string) => {
    setAddressSelectVisible()
    setSearchedAddress(address)
  }

  return (
    <>
      {isLoaded ? (
        <PlacesAutocomplete
          value={searchedAddress ?? ""}
          searchOptions={{
            types: searchTypes,
            componentRestrictions: { country: "us" },
          }}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            const { onChange, ...rest } = {
              ...getInputProps({
                placeholder,
                name,
                disabled,
                onBlur,
              }),
            }

            const onSearch = (input: string) => {
              onChange({ target: { value: input } })
            }

            const onDeselect = () => {
              setSearchedAddress(undefined),
                onSelect?.({ address: undefined, addressComponents: undefined })
            }

            return (
              <StyledSelect>
                <Select
                  value={value ? [value] : undefined}
                  placeholder={rest.placeholder}
                  label={label}
                  onSearch={onSearch}
                  onDeselect={onDeselect}
                  onSelect={(selectedValue, option) => {
                    const { onClick } = getSuggestionItemProps(
                      option["aria-extra"]
                    )
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onClick({ target: { value: selectedValue } } as any)
                    setAddressSelectHidden()
                    setSearchedAddress(undefined)
                  }}
                  mode="multiple"
                  aria-label="auto-complete-address"
                  helperText={suggestions.length > 0 ? "" : helperText}
                  onBlur={() => (
                    setSearchedAddress(undefined), setAddressSelectHidden()
                  )}
                  maxTagTextLength={maxTagTextLength}
                  hasError={!!error?.message}
                  onFocus={setAddressSelectVisible}
                  open={openSelectAddress}
                  loading={loading || selecting}
                  disabled={!isLoaded}
                  requirement={requirement}
                  wrapHelperText
                  notFoundContent={null}
                >
                  {suggestions.map((suggestion) => {
                    const isValidSuggestion = validateGoogleAddress(
                      ADDRESS_TYPES_REGEX,
                      suggestion
                    )
                    return (
                      isValidSuggestion && (
                        <OptionSelect
                          key={suggestion.placeId}
                          value={suggestion.description}
                          aria-extra={suggestion}
                        >
                          {suggestion.description}
                        </OptionSelect>
                      )
                    )
                  })}
                </Select>
              </StyledSelect>
            )
          }}
        </PlacesAutocomplete>
      ) : (
        <InputFieldSkeleton size="default" width="100%" />
      )}
    </>
  )
}

const StyledSelect = styled.div`
  .ant-select-selection-item {
    width: inherit;
  }
`
