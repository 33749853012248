import Button from "../../../../../../ui/Button"
import Container from "../../../../../../ui/Container"
import Text from "../../../../../../ui/Typography/Text"
import React from "react"
import type { ColorResult } from "react-color"
import { ChromePicker } from "react-color"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

interface IColorPickerContentProps {
  color: string | undefined
  setColor: React.Dispatch<React.SetStateAction<string>>
  onOk: () => void
  onClose: () => void
  title: string
}

export const ColorPickerContent: React.FC<IColorPickerContentProps> = ({
  color,
  setColor,
  onOk,
  onClose,
  title,
}) => {
  const intl = useIntl()

  const generateHex8 = (colorResult: ColorResult) => {
    const hex = colorResult.hex.slice(1)

    const alpha = Math.round((colorResult.rgb.a || 0) * 255)
      .toString(16)
      .padStart(2, "0")

    return `#${hex}${alpha}`
  }

  const handleChange = (colorResult: ColorResult) => {
    const hex8 = generateHex8(colorResult)
    setColor(hex8)
  }

  return (
    <StyledColorPickerContainer
      display="flex"
      flexDirection="column"
      width="368px"
      gap="24px"
    >
      <StyledHeader>
        <Text size="m" weight="bold">
          {title}
        </Text>
      </StyledHeader>
      <Container display="flex" alignItems="center" flexDirection="column">
        <StyledColorPicker color={color} onChangeComplete={handleChange} />
      </Container>

      <StyledFooter
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        gap="8px"
      >
        <Button
          title={intl.formatMessage({
            id: "components.modal.cancel.button",
            defaultMessage: "Cancel",
          })}
          hierarchy="secondary"
          onClick={onClose}
        />
        <Button
          title={intl.formatMessage({
            id: "components.modal.apply.button",
            defaultMessage: "Apply",
          })}
          onClick={onOk}
        />
      </StyledFooter>
    </StyledColorPickerContainer>
  )
}

const StyledColorPickerContainer = styled(Container)`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    position: absolute;
    top: -60px;
    left: 100px;
    z-index: 2;
    max-height: 575.62px;
    box-shadow: ${theme.shadows.elevationMedium};
    border-radius: 4px;
  `}
`

const StyledHeader = styled(Container)`
  ${({ theme }) => css`
    border-bottom: ${theme.colors["Neutral8"]};
    box-shadow: inset 0px -1px 0px ${theme.colors.white};
    padding: 20px 24px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  `}
`

const StyledFooter = styled(Container)`
  ${({ theme }) => css`
    padding: 16px;
    height: 72px;
    background: ${theme.colors.white};
    box-shadow: inset ${theme.shadows.elevationLow};
  `}
`

const StyledColorPicker = styled(ChromePicker)`
  width: 320px !important;
  box-shadow: none !important;

  > div > div > div > div > div {
    > input {
      height: 30px !important;
      text-transform: uppercase !important;
      font-size: 13px !important;
      box-shadow: none !important;
      border: 1px solid
        ${({ theme }) => `${theme.colors["Primary4"]} !important`};

      :focus {
        outline-color: ${({ theme }) => theme.colors["Primary5"]};
      }
    }

    > label {
      color: ${({ theme }) => `${theme.colors.Neutral8} !important`};
    }
  }
`
