import React from "react"
import { FormattedMessage } from "react-intl"

type StepInfoProperties = {
  title: React.ReactNode
  description: React.ReactNode
}

type StepsInfoType = Array<StepInfoProperties>

export const StepsInfo: StepsInfoType = [
  {
    title: (
      <FormattedMessage
        id="onboarding.steps.step.one.title"
        defaultMessage="Basic Information"
      />
    ),
    description: (
      <FormattedMessage
        id="onboarding.steps.step.one.description"
        defaultMessage="We’ll use your logo and color to help with your branding."
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="onboarding.steps.step.two.title"
        defaultMessage="Location Information"
      />
    ),
    description: (
      <FormattedMessage
        id="onboarding.steps.step.two.description"
        defaultMessage="Add information about one of your restaurants physical locations. Don’t worry, you can add more later."
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id="onboarding.steps.step.three.title"
        defaultMessage="Upload your menu"
      />
    ),
    description: (
      <FormattedMessage
        id="onboarding.steps.step.three.description"
        defaultMessage="If you’re still on a paper menu, or have another digital file, we’ll create your menu for you."
      />
    ),
  },
]
