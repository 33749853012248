import yup from "../../../../../../shared/yup"

export const DateAndTimeSchema = yup.object().shape({
  addEndTime: yup.bool(),
  startDate: yup
    .object()
    .dateAndTimeRange()
    .shape({
      date: yup.string(),
      hour: yup.object().shape({
        time: yup.string(),
        meridium: yup.string(),
      }),
    }),
  endDate: yup
    .object()
    .dateAndTimeRange()
    .shape({
      date: yup.string(),
      hour: yup.object().shape({
        time: yup.string(),
        meridium: yup.string(),
      }),
    }),
})
