export const CustomOrderPaymentMethodTitle = {
  CASH: {
    id: "restaurants.orders.order.payment.method.cash",
    defaultMessage: "Cash",
  },
  CARD: {
    id: "restaurants.orders.order.payment.method.card",
    defaultMessage: "Card",
  },
  FREE_OF_CHARGE: {
    id: "restaurants.orders.order.payment.method.free_of_charge",
    defaultMessage: "Free of charge",
  },
}
