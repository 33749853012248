import paths from "../../../../../shared/routes/paths"
import { LabelInformation } from "../../../../../ui/LabelInformation"
import { MantineCard } from "../../../../../ui/MantineCard"
import { Button, Flex, Grid } from "@mantine/core"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

interface ContactInformationProps {
  customer: {
    phone: string
    uuid: string
    firstName?: string
    lastName?: string
  }
  locationUUID?: string
}

export const ContactInformation = ({
  customer,
  locationUUID,
}: ContactInformationProps) => {
  const intl = useIntl()
  const { push } = useHistory()

  const goToCustomer = () => {
    locationUUID
      ? push(
          paths.restaurants.locations.customerDetailByLocation(
            locationUUID,
            customer?.uuid
          )
        )
      : push(paths.restaurants.customerDetail(customer?.uuid))
  }

  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.contact.information.title",
        defaultMessage: "Contact information",
      })}
    >
      <Grid>
        {customer.firstName || customer.lastName ? (
          <Grid.Col span={6}>
            <Flex align={"end"}>
              <LabelInformation
                label={intl.formatMessage({
                  id: "restaurants.catering.detail.card.contact.information.customer.name.label",
                  defaultMessage: "Customer name",
                })}
                description={`${customer.firstName} ${customer.lastName ?? ""}`}
              />
              <Button
                variant="subtle"
                size="xs"
                h={"min-content"}
                py={2}
                px={4}
                fw={"normal"}
                onClick={goToCustomer}
              >
                {intl.formatMessage({
                  id: "restaurants.catering.detail.card.contact.information.customer.view.more",
                  defaultMessage: "View more",
                })}
              </Button>
            </Flex>
          </Grid.Col>
        ) : null}

        <Grid.Col span={6}>
          <LabelInformation
            label={intl.formatMessage({
              id: "restaurants.catering.detail.card.contact.information.phone.number.label",
              defaultMessage: "Phone number",
            })}
            description={customer.phone}
          />
        </Grid.Col>
      </Grid>
    </MantineCard>
  )
}
