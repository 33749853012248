export enum TypeEnum {
  PERCENTAGE = "PERCENTAGE",
  FIXED_AMOUNT = "FIXED_AMOUNT",
}

const typeOptions = [
  {
    value: TypeEnum.PERCENTAGE,
    labelId:
      "restaurants.discount.forms.generic.type.options.button.percentage.title",
    defaultMessage: "Percentage",
  },
  {
    value: TypeEnum.FIXED_AMOUNT,
    labelId:
      "restaurants.discount.forms.generic.type.options.button.fixed.title",
    defaultMessage: "Fixed Amount",
  },
]
export default typeOptions
