import Checkbox from "../../../../../../ui/Checkbox"
import Container from "../../../../../../ui/Container"
import Divider from "../../../../../../ui/Divider"
import Select, { OptionSelect } from "../../../../../../ui/Select"
import { SELECT_LIST_HEIGHT } from "../constants"
import { useItemSelector } from "./hooks/useItemsSelector"
import { useIntl } from "react-intl"

export const ItemsSelector = () => {
  const intl = useIntl()

  const {
    onAllItemsClick,
    onItemSelected,
    onScrollToFetchMore,
    handleSearchItems,
    loading,
    items,
    search,
    selectedItems,
    applyToAllItems,
    isEditMode,
  } = useItemSelector()

  const selectTitle = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.applies.to.items.select.title",
    defaultMessage: "Items",
  })

  const allOptionsLabel = intl.formatMessage({
    id: "restaurants.discounts.forms.rules.applies.to.ll.items.label",
    defaultMessage: "All Items",
  })

  const allOptionsKey = "all-items"

  return (
    <Select
      disabled={isEditMode}
      withCheckbox
      allowSearch
      searchValue={search}
      label={selectTitle}
      mode="multiple"
      optionFilterProp="label"
      loading={loading}
      listHeight={SELECT_LIST_HEIGHT}
      onSearch={handleSearchItems}
      options={items}
      closableTags={!isEditMode}
      value={
        applyToAllItems
          ? [allOptionsKey]
          : selectedItems?.map((item) => item.uuid)
      }
      onChangeWithCheckbox={onItemSelected}
      dropdownHeader={
        <OptionSelect
          className="option-select-checkbox no-background"
          key={allOptionsKey}
          value={allOptionsKey}
          label={allOptionsLabel}
        >
          <Container
            padding="4px 12px"
            onClick={onAllItemsClick}
            className="option-select-checkbox-selected"
          >
            <Checkbox
              classId="select-all-items-checkbox"
              content={allOptionsLabel}
              checked={applyToAllItems}
              indeterminate={!applyToAllItems}
            />
          </Container>
          <Divider verticalMargin="4px" />
        </OptionSelect>
      }
      onPopupScroll={onScrollToFetchMore}
    />
  )
}
