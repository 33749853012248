import { OrderActionTypeEnum } from "../../../../../../shared/graphql/generated/types"
import { MantineCard } from "../../../../../../ui/MantineCard"
import { mappingActionTypes } from "../../../utils/action-types"
import { Group, Text } from "@mantine/core"
import { IconCircle } from "@tabler/icons-react"
import { useIntl } from "react-intl"

interface StatusProps {
  orderActions: {
    actionType: OrderActionTypeEnum
    actionAt: string
  }[]
}

export const Status = ({ orderActions }: StatusProps) => {
  const intl = useIntl()

  const status = orderActions[0].actionType
  return (
    <MantineCard
      title={intl.formatMessage({
        id: "restaurants.catering.detail.card.status.title",
        defaultMessage: "Status",
      })}
    >
      <Group spacing={8}>
        {mappingActionTypes[status].icon && (
          <IconCircle color="#FCC419" fill="#FCC419" size={8} />
        )}
        <Text size="sm" c={mappingActionTypes[status].color}>
          {intl.formatMessage({
            id: "restaurants.catering.detail.card.status.label",
            defaultMessage: mappingActionTypes[status].defaultMessage,
          })}
        </Text>
      </Group>
    </MantineCard>
  )
}
