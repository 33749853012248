import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetComboDetailQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetComboDetailQuery = { __typename?: 'Query', getComboDetail: { __typename?: 'RComboDetail', createdAt?: any | null, description?: string | null, discountPercentage?: number | null, fullPrice?: number | null, hasSnapshot?: boolean | null, isMain: boolean, isPublished?: boolean | null, isSoldOut?: boolean | null, isVisible?: boolean | null, name: string, priceOverride?: number | null, publishedAt?: any | null, snapshotUpdatedAt?: any | null, updatedAt?: any | null, uuid: string, comboItemGroups: Array<{ __typename?: 'RComboItemGroupDetail', name?: string | null, uuid?: string | null, comboItems: Array<{ __typename?: 'RComboItemDetail', isDefault: boolean, uuid: string, item: { __typename?: 'RItemComboDetail', isMain: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name: string, uuid: string, variants: Array<{ __typename?: 'RVariantComboDetail', extraPrice: number, isDefault: boolean, isSoldOut?: boolean | null, isVisible?: boolean | null, name: string, price: number, uuid: string, attachment?: { __typename?: 'AttachmentModel', signedUrl: string } | null }> } }> }>, attachment?: { __typename?: 'AttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, signedUrl: string, uuid: string } | null, menus: Array<{ __typename?: 'RBasicMenuDetail', isMain: boolean, name: string, uuid: string }> } };


export const GetComboDetailDocument = gql`
    query getComboDetail($uuid: ID!) {
  getComboDetail(uuid: $uuid) {
    comboItemGroups {
      comboItems {
        isDefault
        item {
          isMain
          isSoldOut
          isVisible
          name
          uuid
          variants {
            attachment {
              signedUrl
            }
            extraPrice
            isDefault
            isSoldOut
            isVisible
            name
            price
            uuid
          }
        }
        uuid
      }
      name
      uuid
    }
    attachment {
      contentType
      ext
      fileName
      signedUrl
      uuid
    }
    createdAt
    description
    discountPercentage
    fullPrice
    hasSnapshot
    isMain
    isPublished
    isSoldOut
    isVisible
    menus {
      isMain
      name
      uuid
    }
    name
    priceOverride
    publishedAt
    snapshotUpdatedAt
    updatedAt
    uuid
  }
}
    `;

/**
 * __useGetComboDetailQuery__
 *
 * To run a query within a React component, call `useGetComboDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetComboDetailQuery(baseOptions: Apollo.QueryHookOptions<GetComboDetailQuery, GetComboDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComboDetailQuery, GetComboDetailQueryVariables>(GetComboDetailDocument, options);
      }
export function useGetComboDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComboDetailQuery, GetComboDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComboDetailQuery, GetComboDetailQueryVariables>(GetComboDetailDocument, options);
        }
export type GetComboDetailQueryHookResult = ReturnType<typeof useGetComboDetailQuery>;
export type GetComboDetailLazyQueryHookResult = ReturnType<typeof useGetComboDetailLazyQuery>;
export type GetComboDetailQueryResult = Apollo.QueryResult<GetComboDetailQuery, GetComboDetailQueryVariables>;