import mainTheme from "./mainTheme"
import type { ILayoutTheme } from "styled-components"

const lightTheme: ILayoutTheme = {
  ...mainTheme,
  colors: {
    ...mainTheme.colors,
  },
}

export default lightTheme
