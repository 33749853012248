export const corporateTitle = {
  id: "restaurants.menu.items.forms.item.form.published.corporate.tooltip",
  defaultMessage:
    "{isPublished,plural,=0 {Hidden from locations} other {Visible to locations}}",
}

export const locationTitle = {
  id: "restaurants.menu.items.forms.item.form.published.location.tooltip",
  defaultMessage:
    "{isPublished,plural,=0 {Hidden from customers} other {Visible to customers}}",
}
