export const getFileTypes = (types: string[]): string[] => {
  return types.map((type) => `.${type.split("/")[1]}`)
}

export const getHumanizedFileTypes = (types: string[]): string => {
  const typesArray = getFileTypes(types).map((type) =>
    type.replace(".", "").toUpperCase()
  )

  if (typesArray.length === 1) {
    return typesArray[0]
  } else if (typesArray.length > 1) {
    return `${typesArray.slice(0, -1).join(", ")} and ${typesArray.at(-1)}`
  } else {
    return ""
  }
}
