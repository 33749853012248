import { Grid, Skeleton, Stack } from "@mantine/core"

export const CateringDetailSkeleton = () => {
  return (
    <Stack spacing={24}>
      <Skeleton h={60} w="100%" />
      <Grid gutter={24}>
        <Grid.Col lg={8}>
          <Stack spacing={24}>
            <Skeleton h={400} w="100%" />
            <Skeleton h={100} w="100%" />
            <Skeleton h={400} w="100%" />
          </Stack>
        </Grid.Col>
        <Grid.Col lg={4}>
          <Stack spacing={24}>
            <Skeleton h={70} w="100%" />
            <Skeleton h={200} w="100%" />
            <Skeleton h={200} w="100%" />
            <Skeleton h={200} w="100%" />
          </Stack>
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
