import yup from "../../../../../../shared/yup"
import { EligibleCustomerRuleTypeEnum } from "../../../interfaces/eligibleCustomer.interface"
import { MAX_INPUT_VALUE } from "../../GenericForm/constants"
import {
  MAX_VALUE_INPUT_ERROR_ID,
  MORE_THAN_ZERO_ERROR_ID,
  REQUIRED_FIELD_ERROR_ID,
} from "../../GenericForm/validations.title"
import {
  ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME,
  ELIGIBLE_CUSTOMER_USERS_FIELD_NAME,
} from "../constants"

export const EligibleCustomerSchema = yup
  .object()
  .shape({
    type: yup
      .mixed<EligibleCustomerRuleTypeEnum>()
      .oneOf(Object.values(EligibleCustomerRuleTypeEnum)),
    users: yup.array(),
    spend: yup
      .number()
      .nullable()
      .moreThan(0, MORE_THAN_ZERO_ERROR_ID)
      .max(MAX_INPUT_VALUE, MAX_VALUE_INPUT_ERROR_ID),
  })
  .test("ifTypeRequiresValuesOnFields", (value) => {
    const { type, users, spend } = value

    if (type === EligibleCustomerRuleTypeEnum.SPECIFIC && users?.length === 0) {
      return new yup.ValidationError(
        REQUIRED_FIELD_ERROR_ID,
        value,
        ELIGIBLE_CUSTOMER_USERS_FIELD_NAME
      )
    }

    if (type === EligibleCustomerRuleTypeEnum.SPEND && !spend) {
      return new yup.ValidationError(
        REQUIRED_FIELD_ERROR_ID,
        value,
        ELIGIBLE_CUSTOMER_SPEND_FIELD_NAME
      )
    }

    return true
  })
