import { useDeleteTimeEntryMutation } from "../../Graphql/deleteTimeEntry.generated"
import { TimeEntriesByLocationEmployeeDocument } from "../../Graphql/timeEntriesByLocationEmployee.generated"
import styles from "./DeleteEntryModal.module.css"
import { Button, Flex, Modal, Text, Title } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useIntl } from "react-intl"

interface DeleteEntryModalProps {
  opened: boolean
  onClose: () => void
  deleteData: { employeeUUID: string; entryUUID: string }
}

export const DeleteEntryModal = (props: DeleteEntryModalProps) => {
  const {
    opened,
    onClose,
    deleteData: { entryUUID, employeeUUID },
  } = props
  const intl = useIntl()
  const [deleteEntry, { loading }] = useDeleteTimeEntryMutation()

  const handleDeleteEntry = () => {
    deleteEntry({
      variables: {
        input: { uuid: entryUUID, employee: { uuid: employeeUUID } },
      },
      onCompleted: () => {
        onClose()
      },
      onError: (error) => {
        notifications.show({
          title: intl.formatMessage({
            id: "generic.error.try.again.message",
            defaultMessage: "Something went wrong. Try again later.",
          }),
          message: error.message,
          color: "red",
          withBorder: true,
        })
      },
      refetchQueries: [TimeEntriesByLocationEmployeeDocument],
    })
  }

  return (
    <Modal
      size="xs"
      centered
      opened={opened}
      onClose={onClose}
      classNames={{
        header: styles["modal-header"],
      }}
      title={
        <Title order={4}>
          {intl.formatMessage({
            id: "restaurant.time.entries.details.delete.entry.modal.title",
            defaultMessage: "Delete",
          })}
        </Title>
      }
    >
      <Text size="sm" c="gray.7" py={8}>
        {intl.formatMessage({
          id: "restaurant.time.entries.details.delete.entry.modal.text",
          defaultMessage: "Are you sure want to delete entry?",
        })}
      </Text>
      <Flex gap={16} justify="flex-end">
        <Button color="gray.4" variant="outline" onClick={onClose}>
          <Text c="gray.8">
            {intl.formatMessage({
              id: "restaurant.time.entries.details.delete.entry.modal.cancel.button",
              defaultMessage: "Cancel",
            })}
          </Text>
        </Button>
        <Button
          color="red.6"
          onClick={handleDeleteEntry}
          loading={loading}
          w={125}
        >
          {!loading &&
            intl.formatMessage({
              id: "restaurant.time.entries.details.delete.entry.modal.delete.button",
              defaultMessage: "Delete entry",
            })}
        </Button>
      </Flex>
    </Modal>
  )
}
