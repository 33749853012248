import * as Types from '../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertRestaurantSnapshotMutationVariables = Types.Exact<{
  data: Types.CreateOrUpdateRestaurantSnapshotInput;
}>;


export type UpsertRestaurantSnapshotMutation = { __typename?: 'Mutation', createOrUpdateRestaurantSnapshot: { __typename?: 'DraftRestaurant', uuid: string, snapshot: { __typename?: 'RestaurantSnapshot', currentStep?: number | null, locationsQuantity?: string | null, name?: string | null, brandColor?: string | null, notes?: string | null, urlIdentifier?: string | null, logos?: Array<{ __typename?: 'RestaurantAttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, type: string, uuid: string }> | null, menuAttachments?: Array<{ __typename?: 'AttachmentSnapshot', contentType: Types.AttachmentContentTypeEnum, ext: Types.AttachmentExtensionEnum, fileName: string, uuid: string }> | null, location?: { __typename?: 'RestaurantLocation', address?: string | null, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, latitude?: string | null, longitude?: string | null, name?: string | null, postalCode?: string | null, orderTypes?: Array<Types.OrderMethodEnum> | null, attachment?: { __typename?: 'RestaurantAttachmentModel', contentType: Types.ContentTypeEnum, ext: Types.FileExtensionEnum, fileName: string, type: string } | null } | null } } };


export const UpsertRestaurantSnapshotDocument = gql`
    mutation upsertRestaurantSnapshot($data: CreateOrUpdateRestaurantSnapshotInput!) {
  createOrUpdateRestaurantSnapshot(data: $data) {
    snapshot {
      currentStep
      locationsQuantity
      name
      brandColor
      logos {
        contentType
        ext
        fileName
        type
        uuid
      }
      menuAttachments {
        contentType
        ext
        fileName
        uuid
      }
      notes
      urlIdentifier
      location {
        address
        addressLine1
        addressLine2
        attachment {
          contentType
          ext
          fileName
          type
        }
        city
        state
        latitude
        longitude
        name
        postalCode
        orderTypes
      }
    }
    uuid
  }
}
    `;
export type UpsertRestaurantSnapshotMutationFn = Apollo.MutationFunction<UpsertRestaurantSnapshotMutation, UpsertRestaurantSnapshotMutationVariables>;

/**
 * __useUpsertRestaurantSnapshotMutation__
 *
 * To run a mutation, you first call `useUpsertRestaurantSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRestaurantSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRestaurantSnapshotMutation, { data, loading, error }] = useUpsertRestaurantSnapshotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertRestaurantSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRestaurantSnapshotMutation, UpsertRestaurantSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRestaurantSnapshotMutation, UpsertRestaurantSnapshotMutationVariables>(UpsertRestaurantSnapshotDocument, options);
      }
export type UpsertRestaurantSnapshotMutationHookResult = ReturnType<typeof useUpsertRestaurantSnapshotMutation>;
export type UpsertRestaurantSnapshotMutationResult = Apollo.MutationResult<UpsertRestaurantSnapshotMutation>;
export type UpsertRestaurantSnapshotMutationOptions = Apollo.BaseMutationOptions<UpsertRestaurantSnapshotMutation, UpsertRestaurantSnapshotMutationVariables>;