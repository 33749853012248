import type { LocationFormType } from "./hookforms.interfaces"

export const formatAddress: (
  location: LocationFormType["manualAddress"]
) => string = (location) => {
  const street = location.line2
    ? `${location.line1}, ${location.line2}`
    : location.line1

  return `${street}, ${location.city}, ${location.state} ${location.zipCode}`
}
