export interface EligibleCustomerRule {
  type: EligibleCustomerRuleTypeEnum
  users: User[]
  spend: number | null
}

interface User {
  label: string
  uuid: string
}

export enum EligibleCustomerRuleTypeEnum {
  EVERYONE = "EVERYONE",
  SPECIFIC = "SPECIFIC",
  SPEND = "SPEND",
}
