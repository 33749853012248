import storage from "../utils/storage"
import { useCallback, useState } from "react"

export const usePersistedState = <T>(key: string, initialValue: T) => {
  const getInitialValue = useCallback(() => {
    try {
      const storedState = storage.get<T>(key)

      return storedState || initialValue
    } catch {
      return initialValue
    }
  }, [key, initialValue])

  const [storedValue, setStoredValue] = useState<T>(getInitialValue())

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      const stateToPersist =
        value instanceof Function ? value(getInitialValue()) : value
      setStoredValue(stateToPersist)
      storage.set(key, stateToPersist)
    },
    [key, getInitialValue]
  )

  return [storedValue, setValue] as const
}
